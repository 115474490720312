export enum MyTellcoDocumentType {
  PENSION_AGREEMENT = 'PENSION_AGREEMENT',
  TERMS_OF_USE = 'TERMS_OF_USE',
  GENERAL_TERMS_AND_CONDITIONS = 'GENERAL_TERMS_AND_CONDITIONS',
  ARTICLES_OF_FOUNDATION = 'ARTICLES_OF_FOUNDATION',
  PENSION_FUND_REGULATIONS = 'PENSION_FUND_REGULATIONS',
  INVESTMENT_REGULATIONS = 'INVESTMENT_REGULATIONS',
  FEES_AND_CHARGES_POLICY = 'FEES_AND_CHARGES_POLICY',
  CHANGE_OF_BENEFICARIES = 'CHANGE_OF_BENEFICARIES',
  DE_FACTO_PARTNERSHIP_DECLARATION = 'DE_FACTO_PARTNERSHIP_DECLARATION',
  ORGANISATIONAL_REGULATIONS = 'ORGANISATIONAL_REGULATIONS',
  PAYOUT_WEF = 'PAYOUT_WEF',
  PAYOUT_PAYMENT_APPLICATION = 'PAYOUT_PAYMENT_APPLICATION',
  RISK_IN_TRADING = 'RISK_IN_TRADING',
}
