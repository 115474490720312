export const FR = {
  1468: 'Cheyres',
  1470: 'Estavayer-le-Lac',
  1473: 'Font',
  1474: 'Châbles FR',
  1475: 'Autavaux',
  1482: 'Cugy FR',
  1483: 'Montet (Broye)',
  1484: 'Aumont',
  1485: 'Nuvilly',
  1486: 'Vuissens',
  1489: 'Murist',
  1527: 'Villeneuve FR',
  1528: 'Surpierre',
  1529: 'Cheiry',
  1532: 'Fétigny',
  1533: 'Ménières',
  1541: 'Bussy FR',
  1542: 'Rueyres-les-Prés',
  1544: 'Gletterens',
  1553: 'Châtonnaye',
  1563: 'Dompierre FR',
  1564: 'Domdidier',
  1566: 'St-Aubin FR',
  1567: 'Delley',
  1568: 'Portalban',
  1583: 'Villarepos',
  1609: 'St-Martin FR',
  1611: 'Le Crêt-p-Semsales',
  1614: 'Granges (Veveyse)',
  1615: 'Bossonnens',
  1616: 'Attalens',
  1617: 'Remaufens',
  1618: 'Châtel-St-Denis',
  1619: 'Les Paccots',
  1623: 'Semsales',
  1624: 'La Verrerie',
  1625: 'Sâles (Gruyère)',
  1626: 'Romanens',
  1627: 'Vaulruz',
  1628: 'Vuadens',
  1630: 'Bulle',
  1631: 'Bulle',
  1632: 'Riaz',
  1633: 'Vuippens',
  1634: 'La Roche FR',
  1635: 'La Tour-de-Trême',
  1636: 'Broc',
  1637: 'Charmey (Gruyère)',
  1638: 'Morlon',
  1642: 'Sorens',
  1643: 'Gumefens',
  1644: 'Avry-devant-Pont',
  1645: 'Le Bry',
  1646: 'Echarlens',
  1647: 'Corbières',
  1648: 'Hauteville',
  1649: 'Pont-la-Ville',
  1651: 'Villarvolard',
  1652: 'Botterens',
  1653: 'Châtel-Montsalvens',
  1654: 'Cerniat FR',
  1656: 'Im Fang',
  1661: 'Pâquier-Montbarry',
  1663: 'Moléson-s-Gruyères',
  1665: 'Estavannens',
  1666: 'Grandvillard',
  1667: 'Enney',
  1669: 'Neirivue',
  1670: 'Ursy',
  1673: 'Auboranges',
  1674: 'Vuarmarens',
  1675: 'Vauderens',
  1676: 'Chavannes-Forts',
  1677: 'Prez-vers-Siviriez',
  1678: 'Siviriez',
  1679: 'Villaraboud',
  1680: 'Romont FR',
  1681: 'Billens',
  1684: 'Mézières FR',
  1685: 'Villariaz',
  1686: 'Grangettes-Romont',
  1687: 'Estévenens',
  1688: 'Sommentier',
  1689: 'Châtelard-p-Romont',
  1690: 'Villaz-St-Pierre',
  1691: 'Villarimboud',
  1692: 'Massonnens',
  1694: 'Orsonnens',
  1695: 'Villarlod',
  1696: 'Vuisternens-Ogoz',
  1697: 'La Joux FR',
  1699: 'Bouloz',
  1700: 'Fribourg',
  1701: 'Fribourg',
  1702: 'Fribourg',
  1704: 'Fribourg',
  1705: 'Fribourg',
  1707: 'Fribourg',
  1708: 'Fribourg',
  1709: 'Fribourg',
  1712: 'Tafers',
  1713: 'St. Antoni',
  1714: 'Heitenried',
  1715: 'Alterswil FR',
  1716: 'Schwarzsee',
  1717: 'St. Ursen',
  1718: 'Rechthalten',
  1719: 'Brünisried',
  1720: 'Corminboeuf',
  1721: 'Misery-Courtion',
  1722: 'Bourguillon',
  1723: 'Marly',
  1724: 'Bonnefontaine',
  1725: 'Posieux',
  1726: 'Farvagny',
  1727: 'Corpataux-Magneden',
  1728: 'Rossens FR',
  1730: 'Ecuvillens',
  1731: 'Ependes FR',
  1732: 'Arconciel',
  1733: 'Treyvaux',
  1734: 'Tentlingen',
  1735: 'Giffers',
  1736: 'St. Silvester',
  1737: 'Plasselb',
  1740: 'Neyruz FR',
  1741: 'Cottens FR',
  1742: 'Autigny',
  1744: 'Chénens',
  1745: 'Lentigny',
  1746: 'Prez-vers-Noréaz',
  1747: 'Corserey',
  1748: 'Torny-le-Grand',
  1749: 'Middes',
  1752: 'Villars-sur-Glâne',
  1753: 'Matran',
  1754: 'Rosé',
  1756: 'Onnens FR',
  1757: 'Noréaz',
  1762: 'Givisiez',
  1763: 'Granges-Paccot',
  1772: 'Grolley',
  1773: 'Léchelles',
  1774: 'Cousset',
  1775: 'Mannens',
  1776: 'Montagny-la-Ville',
  1782: 'Belfaux',
  1783: 'Pensier',
  1784: 'Courtepin',
  1785: 'Cressier FR',
  1786: 'Sugiez',
  1787: 'Môtier (Vully)',
  1788: 'Praz (Vully)',
  1789: 'Lugnorre',
  1791: 'Courtaman',
  1792: 'Cordast',
  1793: 'Jeuss',
  1794: 'Salvenach',
  1795: 'Courlevon',
  1796: 'Courgevaux',
  3175: 'Flamatt',
  3178: 'Bösingen',
  3182: 'Ueberstorf',
  3184: 'Wünnewil',
  3185: 'Schmitten FR',
  3186: 'Düdingen',
  3210: 'Kerzers',
  3212: 'Gurmels',
  3213: 'Liebistorf',
  3214: 'Ulmiz',
  3215: 'Gempenach',
  3216: 'Ried b. Kerzers',
  3280: 'Murten',
  3284: 'Fräschels',
  3285: 'Galmiz',
  3286: 'Muntelier',
}
