import { FC } from 'react'
import { IconProps } from './types'

export const AbsenceIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.05444 2.5C7.54342 2.5 7.12816 2.91446 7.12816 3.42698V3.77856H6.87991H6.40038C5.35158 3.77856 4.49983 4.62887 4.5 5.67989L4.50017 7.14884C4.50022 10.2214 6.76232 12.7633 9.71069 13.2098L9.71085 17.4014C9.71087 19.6474 11.4993 21.5 13.7442 21.5C15.9371 21.5 17.6944 19.7323 17.7747 17.557C18.7898 17.1136 19.5 16.1005 19.5 14.9207C19.5 13.3322 18.2126 12.0454 16.6257 12.0454C15.0388 12.0454 13.7513 13.3322 13.7513 14.9207C13.7513 16.2603 14.6669 17.3852 15.9054 17.7049C15.7607 18.8136 14.8344 19.646 13.7442 19.646C12.5567 19.646 11.5634 18.6581 11.5634 17.4013L11.5632 13.2103C14.513 12.7648 16.7765 10.2223 16.7765 7.14873V5.67939C16.7765 4.6285 15.9248 3.77856 14.8762 3.77856H14.2399V3.42698C14.2399 2.91446 13.8246 2.5 13.3136 2.5C12.8026 2.5 12.3873 2.91446 12.3873 3.42698V5.76492C12.3873 6.27743 12.8026 6.6919 13.3136 6.6919C13.8246 6.6919 14.2399 6.27743 14.2399 5.76492V5.63252H14.8762C14.9034 5.63252 14.924 5.65419 14.924 5.67939V7.14873C14.924 9.50869 13.0073 11.4255 10.6383 11.4255C8.26945 11.4255 6.35273 9.50859 6.35273 7.14863L6.35256 5.73343L6.45889 5.63252H7.12816V5.76492C7.12816 6.27743 7.54342 6.6919 8.05444 6.6919C8.56545 6.6919 8.98071 6.27743 8.98071 5.76492V3.42698C8.98071 2.91446 8.56545 2.5 8.05444 2.5ZM15.6039 14.9207C15.6039 14.3572 16.0608 13.8994 16.6257 13.8994C17.1905 13.8994 17.6474 14.3572 17.6474 14.9207C17.6474 15.4842 17.1905 15.942 16.6257 15.942C16.0608 15.942 15.6039 15.4842 15.6039 14.9207Z"
        fill="#3A85F8"
      />
    </svg>
  )
}
