import { RadioSelectDropdownProps } from './types'
import { SelectField, TranslateSimple } from '@eplix/ui'
import clsx from 'clsx'
import { useMemo } from 'react'

export const RadioSelectDropdown = ({
  value,
  label,
  buttonLabel,
  data,
  onChange,
  className,
  inputClassName,
}: RadioSelectDropdownProps) => {
  return (
    <SelectField
      className="max-w-44 max-h-8"
      name="radio_dropdown"
      value={value}
      label={label}
      buttonLabel={buttonLabel}
      onChange={() => {}}
      optionsProps={{ className: 'h-fit' }}
      buttonLabelClassName="typo-regular-200"
      borderBoxClassName="min-h-8"
    >
      <div className="flex flex-col gap-4 py-2">
        {useMemo(() => {
          return data.map((option, index) => {
            return (
              <div
                key={index}
                className={clsx(
                  'flex gap-2 items-center ml-4 cursor-pointer hover:text-darkblue typo-regular-200',
                  className,
                )}
              >
                <input
                  type="radio"
                  id={`${option.value}`}
                  name={label ?? buttonLabel}
                  value={`${option.value}`}
                  className={clsx(
                    'min-w-5 min-h-5 cursor-pointer',
                    inputClassName,
                  )}
                  defaultChecked={value === option.value}
                  onClick={() => onChange(option.value)}
                />
                <label
                  htmlFor={`${option.value}`}
                  className="cursor-pointer w-full"
                >
                  <TranslateSimple label={option.text} />
                </label>
              </div>
            )
          })
        }, [
          buttonLabel,
          className,
          data,
          inputClassName,
          label,
          onChange,
          value,
        ])}
      </div>
    </SelectField>
  )
}
