import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'

import classes from './greeting.module.scss'

export const Greeting = forwardRef<
  HTMLElement,
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>(({ children, className, ...props }, ref) => {
  return (
    <section {...props} className={clsx(classes.greeting, className)} ref={ref}>
      {children}
    </section>
  )
})
