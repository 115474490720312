import React, { HTMLAttributes } from 'react'
import { Paper } from '@mui/material'
import { LoadingButton } from '@eplix/ui'

interface CustomPaperProps extends HTMLAttributes<HTMLElement> {
  isLoading?: boolean
}

export const CustomPaper: React.FC<CustomPaperProps> = ({
  isLoading,
  children,
  ...props
}) => {
  const paperStyle = {
    background: '#f4f7fa',
    marginTop: '4px',
    marginBottom: '-20px',
  }

  return (
    <Paper {...props} sx={paperStyle}>
      {isLoading ? (
        <LoadingButton as="div" name="loader" loading={true} />
      ) : null}
      {children}
    </Paper>
  )
}
