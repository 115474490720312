import { FC } from 'react'
import { ClientRouterContext } from '@/framework/client-router'
import { useClientPath } from '../hooks/use-client-path'
import { useRouter } from 'next/router'

export const ClientRouter: FC<{ queryKey?: string }> = ({
  queryKey,
  children,
}) => {
  const router = useRouter()
  const currentPath = useClientPath(router, queryKey)
  return (
    <ClientRouterContext.Provider value={currentPath}>
      {children}
    </ClientRouterContext.Provider>
  )
}
