import React from 'react'

interface LegendProps {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  payload?: Array<Record<string, string | any>>
  colors: Array<Record<string, string>>
}

/**
 * Render custom PieChart (recharts) Legend
 * @param payload
 * @returns Data for legend below the piechart
 * (the same color, item value and percent)
 */
export const PieChartLegend = ({ payload, colors }: LegendProps) => {
  return (
    <>
      {payload && (
        <ul>
          {payload.map(
            (data, index) =>
              Number(data.payload.percent) > 0 && (
                <li key={`data-${data.value}`} className="flex items-center">
                  <div
                    style={{ background: colors[index].start }}
                    className="w-2.5 h-2.5 mr-2"
                  ></div>
                  {data.value} : {Number(data.payload.percent).toFixed(2)}%
                </li>
              ),
          )}
        </ul>
      )}
    </>
  )
}
