'use client'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React, { ReactElement } from 'react'
import { Mandant, WageDetail } from '@/api'

/**
 * A (x, y) ordered pair
 * @dataKey property name "dataKey" is a placeholder name in order to support multiple lines
 * Example where a and b properties will be mapped to y axis, and month will be mapped to x axis.
 * Instead of a,b, etc, any meaningful name can be used as long as it is unique for each line:
 *[
 *  { month: 'Jan', a: 2 },
 *  { month: 'Feb', a: 4 },
 *  { month: 'Mar', a: 6, b: 6 },
 *  { month: 'Apr', b: 4 },
 *  { month: 'May', b: 2 },
 *  { month: 'Jun', b: 4 },
 *  { month: 'Jul', b: 6 },
 *]
 */
interface DataConfig {
  [dataKey: string]: number | string | undefined | WageDetail[] | Mandant
}

/**
 * Each line has individual configuration in order to support different behavior
 * @dataKey maps to a "y" property name
 * @name name of the series displayed on hover tooltip
 * @color color of the line
 * @width width of the line
 * @dot if true, (x, y) pairs will be marked on chart as dots
 * @type type of line, solid or dash
 * @fillAreaColor color area below line
 */
interface LineConfig {
  dataKey: string
  name: string
  color?: string
  width?: number
  dot?: boolean
  type?: string
  fillAreaColor?: string
}

type Lines = Array<LineConfig>

type DataValues = Array<DataConfig>

/**
 * @xAxisDataKey maps property from data values to x axis
 * @xAxisTicks optional, array of values that will be displayed on x axis, if none are provided all values will be displayed
 */
interface LineChartConfig {
  data: DataValues
  lines: Lines
  xAxisDataKey: string
  xAxisTicks: Array<string | number> | undefined
  className?: string
  height?: number
  width?: number | string
  composedChartMargin?: {
    top: number
    right: number
    left: number
    bottom: number
  }
}

const tickStyleConfig = {
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0.05em',
  color: '#6c7177',
}

const lineStyleConfig = {
  strokeWidth: 1,
  stroke: '#c1c4c7',
}

const createLines = (lines: Lines) => {
  return lines.map(
    ({
      dataKey,
      name,
      color = '#66b4ec',
      width = 2,
      dot = false,
      type = 'solid',
      fillAreaColor,
    }) => {
      const area = fillAreaColor ? (
        <Area
          dataKey={dataKey}
          strokeWidth={0}
          fillOpacity={1}
          fill={`url(#gradient_${dataKey})`}
          legendType="none"
          tooltipType="none"
        />
      ) : null

      return (
        <React.Fragment key={`line_wrapper_${dataKey}`}>
          <Line
            name={name}
            dataKey={dataKey}
            stroke={color}
            strokeWidth={width}
            dot={dot}
            strokeDasharray={type === 'dash' ? '3 3' : ''}
          />
          {area}
        </React.Fragment>
      )
    },
  )
}

const createGradientForArea = (
  lines: Lines,
): ReactElement<Array<SVGLinearGradientElement> | null> => {
  return (
    <>
      {lines.map(({ dataKey, fillAreaColor }) =>
        fillAreaColor ? (
          <linearGradient
            key={`gradient_${dataKey}`}
            id={`gradient_${dataKey}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="5.54%" stopColor={fillAreaColor} stopOpacity={0.2} />
            <stop offset="94.46%" stopColor={fillAreaColor} stopOpacity={0} />
          </linearGradient>
        ) : null,
      )}
    </>
  )
}

export const LineChart = ({
  data,
  lines,
  xAxisDataKey,
  xAxisTicks,
  className,
  height = 350,
  width,
  composedChartMargin,
}: LineChartConfig) => {
  return (
    <ResponsiveContainer height={height} width={width} className={className}>
      <ComposedChart
        data={data}
        margin={
          composedChartMargin ?? { top: 5, right: 0, left: 20, bottom: 25 }
        }
      >
        <defs>{createGradientForArea(lines)}</defs>
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={true}
          ticks={xAxisTicks}
          tickCount={12}
          tick={{ ...tickStyleConfig }}
          {...lineStyleConfig}
        />
        <YAxis
          axisLine={true}
          orientation={'right'}
          domain={['dataMin', 'dataMax']}
          tickLine={true}
          scale="linear"
          tick={{ ...tickStyleConfig }}
          tickCount={3}
          {...lineStyleConfig}
        />
        <CartesianGrid
          horizontal={true}
          vertical={false}
          {...lineStyleConfig}
          fillOpacity={1}
        />
        <Tooltip />
        {createLines(lines)}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
