import { ErrorResponse, FieldError } from '@/api'
import { FormikErrors, FormikProps } from 'formik/dist/types'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface ApiResponseErrors<T> {
  apiErrorMessage: string
  formFieldErrors: FormikErrors<T>
}

const translateMessage = (
  t: TFunction,
  labelPrefix: string,
  labelCode = 'General',
) => {
  const label = labelPrefix ? `${labelPrefix}.${labelCode}` : labelCode
  const errorMessage = t(`${label}`, labelCode)
  if (errorMessage && errorMessage !== labelCode) {
    return errorMessage
  }
  return labelCode
}

const handleResponseErrors = <T>(
  t: TFunction,
  errorResponse: ErrorResponse,
  labelPrefix?: string,
  shouldUseLabelPrefix = false,
): ApiResponseErrors<T> => {
  const { errors, message } = errorResponse || { errors: [], message: '' }
  const hasFormFieldErrors = errors && errors?.length > 0
  const shouldShowApiError = !hasFormFieldErrors && message

  const api: ApiResponseErrors<T> = {
    apiErrorMessage: shouldShowApiError
      ? translateMessage(
          t,
          shouldUseLabelPrefix ? (labelPrefix as string) : 'common:Error',
          message,
        )
      : '',
    formFieldErrors: {},
  }

  if (hasFormFieldErrors) {
    const formFieldErrors: Record<string, string> = {}

    errors.forEach((error: FieldError) => {
      if (error && error.field) {
        const errorMessage = translateMessage(
          t,
          labelPrefix ?? '',
          error.message ?? '',
        )
        if (error.field === '__general__') {
          api.apiErrorMessage =
            translateMessage(t, 'common:Error', error.message) ??
            api.apiErrorMessage
        } else {
          formFieldErrors[error.field as string] = errorMessage
        }
      }
    })
    api.formFieldErrors = formFieldErrors as FormikErrors<T>
  }

  return api
}

export const useApiErrorHandler = <T>(
  errorResponse: ErrorResponse,
  form: FormikProps<T> | null,
  labelPrefix?: string,
  shouldUseLabelPrefix = false,
) => {
  const { t } = useTranslation()
  const { apiErrorMessage, formFieldErrors } = useMemo(
    () =>
      handleResponseErrors<T>(
        t,
        errorResponse,
        labelPrefix,
        shouldUseLabelPrefix,
      ),
    [t, errorResponse, labelPrefix, shouldUseLabelPrefix],
  )

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    form && form.setErrors(formFieldErrors)
  }, [formFieldErrors])

  return apiErrorMessage
}
