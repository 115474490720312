import { useEffect, useState, forwardRef, SVGProps, ForwardedRef } from 'react'
import clsx from 'clsx'

import classes from './loader.module.scss'

interface ProgressBarProps extends SVGProps<SVGSVGElement> {
  size: number
  progress: number
  strokeWidth: number
}

export type SvgCircleProps = SVGProps<SVGCircleElement>

export const SvgLoader = forwardRef(
  (
    {
      size,
      progress,
      strokeWidth,
      children,
      className,
      ...props
    }: ProgressBarProps,
    ref: ForwardedRef<SVGCircleElement>,
  ) => {
    const [offset, setOffset] = useState(0)

    const center = size / 2
    const radius = size / 2 - strokeWidth / 2
    const circumference = 2 * Math.PI * radius

    useEffect(() => {
      const progressOffset = ((100 - progress) / 100) * circumference
      setOffset(progressOffset)
    }, [setOffset, progress, circumference, offset])

    return (
      <svg
        role="progressbar"
        aria-busy="true"
        aria-live="assertive"
        aria-valuemin={0}
        aria-valuenow={progress}
        aria-valuemax={100}
        className={clsx(classes.svg, className)}
        width={size}
        height={size}
        {...props}
      >
        <circle
          className={classes.svgCircleBg}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className={classes.svgCircle}
          ref={ref}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        {children}
      </svg>
    )
  },
)
