import { Button, DownloadIcon } from '@eplix/ui'
import { useDataSubmit } from '@/common'
import { useEffect } from 'react'
import { displayFile } from '@/framework/files/display-file'
import { FileTypeEnum } from '@/common/enums/FileType'
import { useTranslate } from '@eplix/ui/translate/hooks/use-translate'
import clsx from 'clsx'
import { DownloadDocumentsButtonProps } from './types'
import { getAllUserDocuments } from '@/features/user-account/lib/user-account-client'

export const DownloadDocumentsButton = ({
  className,
}: DownloadDocumentsButtonProps) => {
  const { submit: getUserDocuments, result } = useDataSubmit({
    fetcher: getAllUserDocuments,
  })

  const documentLabel = useTranslate({
    label: 'USM-UserAccount:DeleteUser.Documents',
  })

  useEffect(() => {
    if (result) {
      displayFile(result, FileTypeEnum.ZIP, documentLabel)
    }
  }, [documentLabel, result])

  return (
    <Button
      name="download_documents"
      variant="outlined"
      startIcon={<DownloadIcon className="min-w-6 min-h-6" />}
      text="USM-UserAccount:DeleteUser.DocumentsButton"
      className={clsx('w-fit mt-3', className)}
      onClick={getUserDocuments}
    />
  )
}
