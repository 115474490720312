export const JU = {
  2336: 'Les Bois',
  2338: 'Les Emibois',
  2340: 'Le Noirmont',
  2345: 'Les Breuleux',
  2350: 'Saignelégier',
  2353: 'Les Pommerats',
  2354: 'Goumois',
  2360: 'Le Bémont',
  2362: 'Montfaucon',
  2363: 'Les Enfers',
  2364: 'St-Brais',
  2714: 'Le Prédame',
  2718: 'Fornet-Dessus',
  2800: 'Delémont',
  2802: 'Develier',
  2803: 'Bourrignon',
  2805: 'Soyhières',
  2806: 'Mettembert',
  2807: 'Pleigne',
  2812: 'Movelier',
  2813: 'Ederswiler',
  2822: 'Courroux',
  2823: 'Courcelon',
  2824: 'Vicques',
  2825: 'Courchapoix',
  2826: 'Corban',
  2827: 'Mervelier',
  2828: 'Montsevelier',
  2829: 'Vermes',
  2830: 'Courrendlin',
  2832: 'Rebeuvelier',
  2842: 'Rossemaison',
  2843: 'Châtillon JU',
  2852: 'Courtételle',
  2853: 'Courfaivre',
  2854: 'Bassecourt',
  2855: 'Glovelier',
  2856: 'Boécourt',
  2857: 'Montavon',
  2863: 'Undervelier',
  2864: 'Soulce',
  2873: 'Saulcy',
  2882: 'St-Ursanne',
  2883: 'Montmelon',
  2884: 'Montenol',
  2885: 'Epauvillers',
  2886: 'Epiquerez',
  2887: 'Soubey',
  2888: 'Seleute',
  2889: 'Ocourt',
  2900: 'Porrentruy',
  2902: 'Fontenais',
  2903: 'Villars-Fontenais',
  2904: 'Bressaucourt',
  2905: 'Courtedoux',
  2906: 'Chevenez',
  2907: 'Rocourt',
  2908: 'Grandfontaine',
  2912: "Roche-d'Or",
  2914: 'Damvant',
  2915: 'Bure',
  2916: 'Fahy',
  2922: 'Courchavon',
  2923: 'Courtemaîche',
  2924: 'Montignez',
  2925: 'Buix',
  2926: 'Boncourt',
  2932: 'Coeuve',
  2933: 'Damphreux',
  2935: 'Beurnevésin',
  2942: 'Alle',
  2943: 'Vendlincourt',
  2944: 'Bonfol',
  2946: 'Miécourt',
  2947: 'Charmoille',
  2950: 'Courgenay',
  2952: 'Cornol',
  2953: 'Fregiécourt-Pleuj',
  2954: 'Asuel',
}
