import { GenderEnum, WorkingStatusEnum } from '../../common/enums'
import { AgeBoundariesEnum } from '../../common/constants/age-boundaries'
import { UtilsEnum } from '../enums/UtilsEnum'

export const getUserAgeAdequateActions = (
  userAge: number,
  gender: string,
  employerStatus: string,
) => {
  const getIsUserMale = () => gender === GenderEnum.MALE
  const getIsUserAdulthood = () => userAge >= 18
  const getRetirementYearsReqular = () =>
    getIsUserMale()
      ? AgeBoundariesEnum.MALE_REGULAR_BOUNDARY
      : AgeBoundariesEnum.FEMALE_REGULAR_BOUNDARY

  const getRetirementYearsUpperBound = () =>
    getIsUserMale()
      ? AgeBoundariesEnum.MALE_UPPER_BOUNDARY
      : AgeBoundariesEnum.FEMALE_UPPER_BOUNDARY

  const getIsUserEmployed = () =>
    employerStatus === WorkingStatusEnum.EMPLOYED ||
    employerStatus === WorkingStatusEnum.SELF_EMPLOYED

  const getIsUserEmployedOrNotDefinedStatus = () =>
    employerStatus === WorkingStatusEnum.EMPLOYED ||
    employerStatus === WorkingStatusEnum.SELF_EMPLOYED ||
    employerStatus === undefined ||
    employerStatus === UtilsEnum.UNKNOWN

  const getIsOutMaleAgeBoundary = () =>
    userAge <= AgeBoundariesEnum.MALE_UPPER_BOUNDARY &&
    userAge >= AgeBoundariesEnum.MALE_REGULAR_BOUNDARY

  const getIsOutFemaleAgeBoundary = () =>
    userAge <= AgeBoundariesEnum.FEMALE_UPPER_BOUNDARY &&
    userAge >= AgeBoundariesEnum.FEMALE_REGULAR_BOUNDARY

  const getIsAgeBetweenRegularAndUpperBoundary = () =>
    userAge >= getRetirementYearsReqular() &&
    userAge <= getRetirementYearsUpperBound()

  const getIsUserInOldAgeWithoutWorkingStatus = () => {
    return (
      userAge >= getRetirementYearsReqular() &&
      userAge < getRetirementYearsUpperBound() &&
      (employerStatus === UtilsEnum.UNKNOWN || employerStatus === null)
    )
  }

  return {
    getIsUserMale,
    getIsUserEmployed,
    getIsUserAdulthood,
    getIsOutMaleAgeBoundary,
    getIsOutFemaleAgeBoundary,
    getRetirementYearsReqular,
    getRetirementYearsUpperBound,
    getIsAgeBetweenRegularAndUpperBoundary,
    getIsUserInOldAgeWithoutWorkingStatus,
    getIsUserEmployedOrNotDefinedStatus,
  }
}
