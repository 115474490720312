import { CountryEnum } from '@/common/enums'

export const alphanumericZipCountries = [
  CountryEnum.ARGENTINA,
  CountryEnum.BRUNEI_DARUSSALAM,
  CountryEnum.CANADA,
  CountryEnum.IRELAND,
  CountryEnum.KAZAKHSTAN,
  CountryEnum.MALTA,
  CountryEnum.NETHERLANDS,
  CountryEnum.PERU,
  CountryEnum.SOMALIA,
  CountryEnum.UNITED_KINGDOM,
  CountryEnum.SWAZILAND,
]
