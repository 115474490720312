'use client'
import { DataValues } from '@/components/graphs/types'
import { Divider } from '@eframe-ui/react'
import { Translate } from '@eplix/ui'
import { memo, useCallback, useState } from 'react'
import { PieChart as PiChart, Pie, Sector, Tooltip } from 'recharts'
import classes from './charts.module.scss'

export interface PieChartConfig {
  data: DataValues
  dataKey: string
  outerRadius?: number
  innerRadius?: number
  width?: number
  height?: number
  className?: string
  showTable?: boolean
  innerTextContent?: string
  showTooltip?: boolean
  labelFormater?: (val: number) => string
}
/* eslint-disable @typescript-eslint/no-explicit-any */
const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    midAngle,
    fill,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius - 70) * cos
  const sy = cy + (outerRadius - 70) * sin
  return (
    <Sector
      cx={sx}
      cy={sy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  )
}

export const PieChart = memo(
  ({
    data,
    dataKey,
    innerRadius = 50,
    outerRadius = 75,
    width = 170,
    height = 170,
    showTable = true,
    innerTextContent,
    showTooltip = true,
    labelFormater,
  }: PieChartConfig) => {
    const [activeIndex, setActiveIndex] = useState(-1)
    const onMouseOver = useCallback((_, index) => {
      setActiveIndex(index)
    }, [])
    const onMouseLeave = useCallback(() => {
      setActiveIndex(-1)
    }, [])
    return (
      <div className={classes.wrapChart}>
        <div>
          <PiChart width={width} height={height}>
            {showTooltip && <Tooltip />}
            <foreignObject
              x="35"
              y="60"
              width="100"
              height="100"
              className="recharts-text recharts-label wrap text-center"
              textAnchor="middle"
              dominantBaseline="middle"
              alignmentBaseline="middle"
              fontSize="13"
            >
              {innerTextContent}
            </foreignObject>
            <Pie
              activeIndex={activeIndex}
              data={data}
              dataKey={dataKey}
              isAnimationActive={true}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              fill="#red"
              activeShape={renderActiveShape}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              cx="50%"
              cy="50%"
              startAngle={90}
              endAngle={-270}
            />
          </PiChart>
        </div>
        {showTable && (
          <div style={{ width: '100%' }}>
            {data.map((entry, idx) => {
              return (
                <div key={idx}>
                  <div className={classes.chartPieDetail}>
                    <div className={classes.workerColorWithName}>
                      <div
                        className={classes.circle}
                        style={{ background: `${entry.fill}` }}
                      />
                      &nbsp;
                      <Translate label={entry.name as string} />
                    </div>
                    {labelFormater
                      ? labelFormater(entry[dataKey] as number)
                      : entry[dataKey]}
                  </div>
                  <Divider className="my-3" />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  },
)
