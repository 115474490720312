import { FC } from 'react'
import { IconProps } from './types'

export const MoonIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 21.431C9.0426 21.7995 10.1645 22 11.3333 22C16.8562 22 21.3333 17.5228 21.3333 12C21.3333 6.47715 16.8562 2 11.3333 2C10.1645 2 9.0426 2.20051 8 2.56902C11.884 3.94181 14.6667 7.64594 14.6667 12C14.6667 16.3541 11.884 20.0582 8 21.431Z"
        fill="currentColor"
      />
    </svg>
  )
}
