import { DetectEdge, Placement } from '../tooltip-content'

export const getPlacementState = (
  state: Placement,
  detectEdge: DetectEdge | null,
) => {
  switch (true) {
    case state === 'top' && detectEdge?.top:
      return 'bottom'
    case state === 'bottom' && detectEdge?.bottom:
      return 'top'
    case state === 'left' && detectEdge?.left:
      return 'right'
    case state === 'right' && detectEdge?.right:
      return 'left'
    default:
      return state
  }
}
