export const OW = {
  6053: 'Alpnachstad',
  6055: 'Alpnach Dorf',
  6056: 'Kägiswil',
  6060: 'Sarnen',
  6061: 'Sarnen 1',
  6062: 'Wilen (Sarnen)',
  6063: 'Stalden (Sarnen)',
  6064: 'Kerns',
  6066: 'St. Niklausen OW',
  6067: 'Melchtal',
  6068: 'Melchsee-Frutt',
  6072: 'Sachseln',
  6073: 'Flüeli-Ranft',
  6074: 'Giswil',
  6078: 'Bürglen OW',
  6388: 'Grafenort',
  6390: 'Engelberg',
  6391: 'Engelberg',
}
