import { BellIcon, Typo } from '@eplix/ui'
import { BellNotificationsProps } from './types'

export const BellNotifications = ({
  numberOfUnreadNotifications,
}: BellNotificationsProps) => {
  return (
    <div className="h-[30px] w-7 relative">
      <BellIcon className="absolute bottom-0" />
      <div className="absolute flex items-center justify-center right-0 w-5 h-5 bg-[#FF7B72] rounded-lg border-2 border-solid border-[#deeef4]">
        <Typo variant="regular-100">{numberOfUnreadNotifications}</Typo>
      </div>
    </div>
  )
}
