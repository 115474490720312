import { DefaultPrivacyLevel } from '@datadog/browser-rum'

export const datadogConfig = {
  enabled: process.env.ENABLE_DATADOG,
  applicationId: process.env.DATADOG_APPLICATION_ID,
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  site: process.env.DATADOG_SITE,
  service: 'eplixfrontend',
  env: process.env.DATADOG_ENVIRONMENT,
  version: process.env.APPLICATION_VERSION,
  sampleRate: Number(process.env.DATADOG_SAMPLE_RATE),
  trackInteractions: Boolean(process.env.DATADOG_TRACK_INTERACTIONS),
  trackResources: process.env.DATADOG_TRACK_RESOURCES,
  trackLongTasks: process.env.DATADOG_TRACK_LONG_TASKS,
  trackUserInteractions: process.env.DATADOG_TRACK_USER_INTERACTIONS,
  trackFrustrations: Boolean(process.env.DATADOG_TRACK_FRUSTRATIONS),
  defaultPrivacyLevel: process.env
    .DATADOG_DEFAULT_PRIVACY_LEVEL as DefaultPrivacyLevel,
}
