import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'
import { LoadingDots } from '../loading-dots'
import { TranslateSimple } from '../translate'
import { getButtonActions } from './actions'
import { AmountText } from './amount-text'
import classes from './index-button.module.scss'
import { ArrayOfSubtitles, ButtonElement, IndexButtonProps } from './types'
import { Trans } from 'react-i18next'

const {
  getShouldRenderSubtitleArray,
  getIndexVariantClass,
  getLabelClass,
  getIndexIconClass,
} = getButtonActions()

export const IndexButton = forwardRef(
  <TElement extends ButtonElement = 'button'>(
    {
      as,
      title,
      name,
      isLoading = false,
      subtitle,
      labeled,
      icon,
      variant,
      display,
      className,
      resizeBig,
      children,
      amount,
      ...props
    }: IndexButtonProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    const shouldRenderSubtitleArray = getShouldRenderSubtitleArray(subtitle)
    const buttonSize = getIndexIconClass(variant)

    return createElement(
      as || 'button',
      {
        ...props,
        ref,
        'data-cy': `btn_${name}`,
        className: clsx(
          classes.indexButtonRing,
          display && classes.display,
          className,
        ),
      },
      <span
        className={clsx(
          classes.indexButtonContent,
          resizeBig ? classes.resizeBig : getIndexVariantClass(variant),
        )}
      >
        <span
          className={clsx(
            classes.indexButtonLabel,
            labeled && getLabelClass(labeled),
          )}
        />
        <span className={clsx(classes.indexButtonIcon, buttonSize)}>
          {icon}
        </span>
        <span className={classes.indexButtonText}>
          <span
            className={clsx(
              classes.indexButtonTitle,
              variant === 'big' && classes.bigText,
            )}
          >
            <TranslateSimple label={title} />
          </span>
          {isLoading && <LoadingDots />}
          {!isLoading && (
            <span
              className={clsx(
                classes.indexButtonSubtitle,
                amount && 'typo-regular-200',
              )}
            >
              {shouldRenderSubtitleArray ? (
                (subtitle as ArrayOfSubtitles)?.map((item) => {
                  return (
                    <div key={item.label} className="gap-1 typo-regular-300">
                      <TranslateSimple
                        label={item.label}
                        translationOptions={item.translationOptions}
                      />
                      {!item.translationOptions && (
                        <AmountText amount={amount ?? ''} className="mt-1" />
                      )}
                    </div>
                  )
                })
              ) : (
                <>
                  <Trans
                    i18nKey={subtitle as string}
                    components={[<br key="br" />]}
                  />
                  {amount && <AmountText amount={amount} className="mt-1" />}
                </>
              )}
            </span>
          )}
        </span>
        {children}
      </span>,
    )
  },
)
