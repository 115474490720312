import {
  createElement,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  PropsWithoutRef,
  ReactHTML,
} from 'react'
import clsx from 'clsx'

type ElementType = 'table' | 'thead' | 'tr' | 'th' | 'td' | 'tbody' | 'div'

export interface TableElementProps<TElement extends ElementType>
  extends HTMLProps<ReactHTML[TElement]> {
  as?: TElement
}

export const TableElement = forwardRef(
  <TElement extends ElementType = 'div'>(
    { as, ...props }: PropsWithoutRef<TableElementProps<TElement>>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'div',
      {
        ...props,
        className: clsx(props.className),
        ref: ref,
      },
      props.children,
    )
  },
)
