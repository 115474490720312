export const shiftX = 60
export const shiftY = 18

export const data = [
  { x: 0, y: 0 },
  { x: 5, y: 5 },
  { x: 10, y: 10 },
  { x: 15, y: 15 },
  { x: 20, y: 20 },
  { x: 25, y: 25 },
  { x: 30, y: 30 },
]
