import { useMemo, useState } from 'react'
import { StarEmptyIcon } from '@eplix/ui/icons/star-empty-icon'
import { StarFullIcon } from '@eplix/ui/icons/star-full-icon'
import clsx from 'clsx'
import { Translate } from '@eplix/ui'
import { StarRatingProps } from './types'

const starArray = [...Array(5)]

export const StarRating = ({
  label = 'common:EplixRating',
  starsStyles,
  wrapperStyles,
  rating,
  setRating,
  starSize = 40,
  labelVariant,
}: StarRatingProps) => {
  const [hover, setHover] = useState<number>()
  return (
    <div className={clsx('flex flex-col gap-3 text-center', wrapperStyles)}>
      <Translate label={label} variant={labelVariant} />
      <div className={starsStyles}>
        {useMemo(() => {
          return starArray.map((star, index) => {
            ++index
            return (
              <button
                type="button"
                key={index}
                className={clsx('pointer pr-4', index === 5 && 'pr-0')}
                onClick={() => setRating(index)}
                onMouseEnter={() => setHover(index)}
                onMouseLeave={() => setHover(rating)}
              >
                {index <= (hover as number) || index <= (rating as number) ? (
                  <StarFullIcon iconSize={starSize} />
                ) : (
                  <StarEmptyIcon
                    iconSize={starSize}
                    className="text-[#F4F7FA]"
                  />
                )}
              </button>
            )
          })
        }, [hover, rating, setRating, starSize])}
      </div>
    </div>
  )
}
