import { Accordion, AccordionGroup } from '@eplix/ui'
import clsx from 'clsx'
import classes from './filter-wrapper.module.scss'
import { useTranslation } from 'react-i18next'
import { FilterWrapperProps } from './types'

export const FilterWrapper = ({
  title,
  isFilterOpen,
  className,
  onClick,
  children,
  ...props
}: FilterWrapperProps) => {
  const { t } = useTranslation()

  return (
    <div className={clsx(classes.container, className)} {...props}>
      {isFilterOpen && (
        <AccordionGroup className="mb-0 lg:mb-3">
          <Accordion
            title={t(title ?? '')}
            asTitle="h1"
            onUpdate={onClick}
            crossIcon={true}
            className={classes.accordion}
            open={true}
          >
            {children}
          </Accordion>
        </AccordionGroup>
      )}
    </div>
  )
}
