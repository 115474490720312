import { FC } from 'react'
import { IconProps } from './types'

export const SearchIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11ZM11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C12.8488 19 14.551 18.3729 15.9057 17.3198L20.0002 21.4142L21.4144 20L17.3199 15.9056C18.3729 14.5509 19 12.8487 19 11C19 6.58172 15.4183 3 11 3Z"
        fill="currentColor"
      />
    </svg>
  )
}
