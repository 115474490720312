import {
  BoxProp,
  ColorProp,
  ElevationProp,
  LengthProp,
  TypographyProp,
  ZIndexProp,
} from './types'
import { dashToCamelCase } from '../dash-to-camel-case'

import boxPropClasses from './box-prop.module.scss'
import elevationPropClasses from './elevation-prop.module.scss'
import typographyPropClasses from './typography-prop.module.scss'
import zIndexPropClasses from './z-index-prop.module.scss'

const CSS_VARIABLES_PREFIX = 'ex'

export const getBoxCorner = <P extends BoxProp>(p: P) =>
  boxPropClasses[dashToCamelCase(p)]

export const getElevation = <P extends ElevationProp>(p: P) =>
  elevationPropClasses[dashToCamelCase(p)]

export const getTypography = <P extends TypographyProp>(p: P) =>
  typographyPropClasses[dashToCamelCase(p)]

export const getZIndex = <P extends ZIndexProp>(p: P) =>
  zIndexPropClasses[dashToCamelCase(p)]

export const getCSSVariableFromColorProp = <P extends ColorProp>(p: P) =>
  `var(--${CSS_VARIABLES_PREFIX}-rgb-${p})`

export const getCSSVariableFromLengthProp = <P extends LengthProp>(p: P) =>
  `var(--${CSS_VARIABLES_PREFIX}-${p})`
