import { FC } from 'react'
import { IconProps } from './types'

export const ChatBubbleIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C7.58175 4 4.00003 7.58172 4.00003 12C4.00003 13.3063 4.31235 14.5367 4.86552 15.6235C4.98696 15.862 5.00767 16.1394 4.92301 16.3933L3.58117 20.4189L7.6067 19.077C7.86067 18.9924 8.13798 19.0131 8.37656 19.1345C9.46328 19.6877 10.6937 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2.00003 12C2.00003 6.47715 6.47718 2 12 2C17.5229 2 22 6.47715 22 12C22 17.5228 17.5229 22 12 22C10.523 22 9.11844 21.6791 7.85442 21.1026L2.31626 22.9487C1.95692 23.0685 1.56075 22.9749 1.29292 22.7071C1.02509 22.4393 0.931566 22.0431 1.05134 21.6838L2.8974 16.1456C2.3209 14.8816 2.00003 13.4771 2.00003 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
