import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'

import classes from './table.module.scss'

export const Table = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      role="table"
      className={clsx(classes.table, className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  )
})
