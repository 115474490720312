export enum PermissionEnum {
  'ADMIN_PAGE' = 'ADMIN_PAGE',
  'CUSTOMER_SUPPORT' = 'CUSTOMER_SUPPORT',
  'MY_ABSENCE_READ' = 'MY_ABSENCE_READ',
  'MY_ABSENCE_WRITE' = 'MY_ABSENCE_WRITE',
  'MY_PENSION_ACCESS' = 'MY_PENSION_ACCESS',
  'PAYSLIPS_ALL' = 'PAYSLIPS_ALL',
  'NES_ACCESS' = 'NES_ACCESS',
  'ANY' = 'ANY',
  'WORK_AND_ABSENCE_ACCESS' = 'WORK_AND_ABSENCE_ACCESS',
}
