import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react'

export interface DefinitionListProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLDListElement>,
    HTMLDListElement
  > {
  className?: string
  children?: ReactNode
}

export const DefinitionList = forwardRef<HTMLDListElement, DefinitionListProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <dl className={className} {...props} ref={ref}>
        {children}
      </dl>
    )
  },
)
