import clsx from 'clsx'
import { forwardRef, HTMLAttributes, Ref } from 'react'

export interface SliderBarProps extends HTMLAttributes<HTMLDivElement> {
  isDisabled: boolean
}

export const SliderBar = forwardRef(
  (
    { isDisabled, className, ...rest }: SliderBarProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          'relative w-full h-4 bg-surface--1',
          !isDisabled && 'cursor-pointer',
          className,
        )}
        {...rest}
      />
    )
  },
)
