import { Loader } from '@eplix/ui'

import classes from './application-loader.module.scss'
import clsx from 'clsx'

type ApplicationLoaderProps = {
  show: boolean
  className?: string
}

export const ApplicationLoader = ({
  show,
  className,
}: ApplicationLoaderProps) => {
  return show ? (
    <div className={clsx(classes.loaderWrapper, className)}>
      <Loader />
    </div>
  ) : null
}
