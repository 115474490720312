import classes from './callout.module.scss'
import clsx from 'clsx'
import {
  createElement,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactHTML,
} from 'react'

export type CalloutContentElement = 'li' | 'div'
export interface CalloutContentProps<TElement extends CalloutContentElement>
  extends HTMLProps<ReactHTML[TElement]> {
  as?: TElement
}

export const CalloutContent = forwardRef(
  <TElement extends CalloutContentElement = 'li'>(
    { as, className, children, ...props }: CalloutContentProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'li',
      {
        ...props,
        ref,
        className: clsx(classes.calloutContent, className),
      },
      children,
    )
  },
)
