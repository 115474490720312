import { FC } from 'react'
import { IconProps } from './types'

export const IdeaIconOn: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5996 15.8007L14.7764 17.4472C14.607 17.786 14.2607 18 13.882 18H10.118C9.73926 18 9.393 17.786 9.22361 17.4472L8.40035 15.8007C6.94282 14.7061 6 12.9631 6 11C6 7.68629 8.68629 5 12 5C15.3137 5 18 7.68629 18 11C18 12.9631 17.0572 14.7061 15.5996 15.8007ZM14.0216 14.4846L14.3986 14.2015C15.3748 13.4683 16 12.3075 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 12.3075 8.62519 13.4683 9.60139 14.2015L9.97837 14.4846L10.7361 16H13.2639L14.0216 14.4846Z"
        fill="currentColor"
      />
      <path
        d="M9 20C9 19.4477 9.44771 19 10 19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H10C9.44771 21 9 20.5523 9 20Z"
        fill="currentColor"
      />
      <path
        d="M11 1V3H13V1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1Z"
        fill="currentColor"
      />
      <path
        d="M20 10L22 10C22.5523 10 23 10.4477 23 11C23 11.5523 22.5523 12 22 12L20 12V10Z"
        fill="currentColor"
      />
      <path
        d="M4 10H2C1.44772 10 1 10.4477 1 11C1 11.5523 1.44772 12 2 12H4V10Z"
        fill="currentColor"
      />
      <path
        d="M4.57129 5.98438L3.29323 4.70632C2.90271 4.31579 2.90271 3.68263 3.29323 3.2921C3.68375 2.90158 4.31692 2.90158 4.70744 3.2921L5.9855 4.57016L4.57129 5.98438Z"
        fill="currentColor"
      />
      <path
        d="M20.2776 4.70771C20.6685 4.31752 20.669 3.68436 20.2788 3.2935C19.8887 2.90264 19.2555 2.9021 18.8646 3.29229L17.5856 4.5691L18.9986 5.98453L20.2776 4.70771Z"
        fill="currentColor"
      />
      <path
        d="M14.3986 14.2015L14.0216 14.4846L13.2639 16H10.7361L9.97837 14.4846L9.60139 14.2015C8.62519 13.4683 8 12.3075 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 12.3075 15.3748 13.4683 14.3986 14.2015Z"
        fill="url(#paint0_linear_5863_57508)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5863_57508"
          x1="16"
          y1="11.5"
          x2="8"
          y2="11.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8CE3A" />
          <stop offset="1" stopColor="#FFA217" />
        </linearGradient>
      </defs>
    </svg>
  )
}
