import { ElementType, forwardRef, ReactElement, Ref } from 'react'
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '../typings/polymorphic-types'

export const forwardRefWithAs = <
  Props,
  DefaultTag extends keyof JSX.IntrinsicElements,
  AllowedTags extends keyof JSX.IntrinsicElements = keyof JSX.IntrinsicElements,
>(
  render: <T extends ElementType = DefaultTag>(
    props: PolymorphicPropsWithoutRef<Props, T, AllowedTags>,
    ref: Ref<T>,
  ) => ReactElement | null,
) =>
  forwardRef(render) as PolymorphicForwardRefExoticComponent<Props, AllowedTags>
