export const initialValues = [
  {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    shareOfLumpSum: '',
  },
  {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    shareOfLumpSum: '',
  },
  {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    shareOfLumpSum: '',
  },
]
