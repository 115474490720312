import { Button, Translate } from '@eplix/ui'
import Image from 'next/image'
import { useResetCurrentUser } from '@/framework/auth'

interface ExpiredTimeErrorProps {
  message: string
}

export const ExpiredTimeError = ({ message }: ExpiredTimeErrorProps) => {
  const resetCurrentUser = useResetCurrentUser()
  return (
    <>
      <Translate label={message} variant="regular-300" />
      <Image
        alt="Error"
        src="/img/illustrations/img_290_error.png"
        width="290"
        height="290"
      />
      <Button
        className="w-full"
        variant="outlined"
        name="forgot_password_back"
        as="a"
        href="/auth/forgot-password"
        onClick={() => resetCurrentUser()}
        text="USM-ForgotPassword:ExpiredTime.BackButton"
      />
    </>
  )
}
