import { NavigationButtonsProps } from './types'
import { Button, LoadingButton } from '@eplix/ui'
import {
  navigationClass,
  backButtonDefaultClass,
  confirmButtonDefaultClass,
} from './constants'
import clsx from 'clsx'
import Link from 'next/link'

export const NavigationButtons = ({
  onBackClick,
  onSubmit,
  isLoading = false,
  className,
  backButtonClass,
  confirmButtonClass,
  backLink,
  isBackDisabled,
  isNextDisabled,
  backLabel = 'common:BackButton',
  nextLabel = 'common:ConfirmButton',
  showNextButton = true,
  showBackButton = true,
  nextButtonType = 'submit',
  footerClassName,
}: NavigationButtonsProps) => {
  const backButtonVariant = showNextButton ? 'text' : 'outlined'

  return (
    <footer
      className={clsx(
        'z-above flex flex-col mb-0 mt-auto sticky bottom-0 bg-surface-1 !border-t-0 mx-[-24px] lg:mx-[-32px]',
        footerClassName,
      )}
    >
      <div className={clsx(navigationClass, className)}>
        {showBackButton && (
          <Link href={backLink} passHref legacyBehavior>
            <Button
              disabled={isBackDisabled}
              name="back_button"
              variant={backButtonVariant}
              onClick={onBackClick}
              type="button"
              className={clsx(backButtonDefaultClass, backButtonClass)}
              text={backLabel}
            />
          </Link>
        )}
        {showNextButton && (
          <LoadingButton
            disabled={isNextDisabled}
            name="submit_button"
            loading={isLoading}
            onClick={onSubmit}
            variant="outlined"
            type={nextButtonType}
            className={clsx(confirmButtonDefaultClass, confirmButtonClass)}
            text={nextLabel}
          />
        )}
      </div>
    </footer>
  )
}
