import { FC } from 'react'
import { IconProps } from './types'

export const SupportIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 10.6106 3.31486 9.29466 3.87711 8.11975L6.20323 10.4459C6.07067 10.9415 6 11.4625 6 12C6 12.5375 6.07067 13.0585 6.20324 13.5542L3.87713 15.8803C3.31486 14.7054 3 13.3895 3 12ZM4.96812 17.6177C5.38573 18.1398 5.86027 18.6143 6.38234 19.0319L8.52349 16.8908C7.97697 16.5016 7.49846 16.0231 7.10927 15.4766L4.96812 17.6177ZM10.4459 17.7968L8.11978 20.1229C9.29468 20.6851 10.6106 21 12 21C13.3894 21 14.7053 20.6852 15.8802 20.1229L13.5541 17.7968C13.0584 17.9293 12.5375 18 12 18C11.4625 18 10.9416 17.9293 10.4459 17.7968ZM17.6176 19.0319C18.1397 18.6143 18.6143 18.1398 19.0319 17.6177L16.8907 15.4766C16.5015 16.0231 16.023 16.5016 15.4765 16.8908L17.6176 19.0319ZM17.7968 13.5542L20.1229 15.8803C20.6851 14.7054 21 13.3895 21 12C21 10.6105 20.6851 9.29464 20.1229 8.11973L17.7968 10.4458C17.9293 10.9415 18 11.4625 18 12C18 12.5375 17.9293 13.0585 17.7968 13.5542ZM16.8907 8.52344L19.0319 6.38229C18.6143 5.86023 18.1397 5.38569 17.6177 4.96808L15.4765 7.10923C16.023 7.49842 16.5015 7.97692 16.8907 8.52344ZM12 3C13.3894 3 14.7053 3.31485 15.8802 3.8771L13.5541 6.20322C13.0584 6.07067 12.5375 6 12 6C11.4625 6 10.9416 6.07067 10.4459 6.20323L8.11976 3.87711C9.29466 3.31486 10.6106 3 12 3ZM7.10925 8.52346L4.9681 6.38231C5.38572 5.86025 5.86026 5.38571 6.38232 4.9681L8.52347 7.10925C7.97695 7.49844 7.49844 7.97694 7.10925 8.52346ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z"
        fill="currentColor"
      />
    </svg>
  )
}
