import { Typo } from '@eplix/ui'
import { ColorProp, TypographyProp } from '@eplix/ui/utils/css-helpers/types'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface TranslateProps {
  label: string
  variant?: TypographyProp
  color?: ColorProp
  className?: string
  components: JSX.Element[]
}

export const TranslateWithLink = ({
  variant = 'regular-200',
  color = 'text-secondary',
  className,
  label,
  components,
}: TranslateProps) => {
  const { t } = useTranslation()

  const translation = useMemo(
    () => (
      <Trans
        i18nKey={label}
        t={t}
        className="flex flex-row"
        components={components}
      ></Trans>
    ),
    [components, label, t],
  )

  return (
    <Typo variant={variant} color={color} className={className}>
      {translation}
    </Typo>
  )
}
