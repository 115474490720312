import { OptionModel } from '../inputs'
import { Dropdown } from '../../frontend/components/dropdown/dropdown'
import { useMemo } from 'react'

export interface MultiselectFieldProps {
  label: string
  optionsData: OptionModel[]
  onChange(value: OptionModel[]): void
  selected: OptionModel[]
  error?: boolean
  disabled?: boolean
  className?: string
  menuItemsClassName?: string
  buttonLabelClassName?: string
  borderBoxClassName?: string
}

export const MultiSelectField = ({
  className,
  label,
  optionsData,
  onChange,
  selected,
  disabled,
  buttonLabelClassName,
  borderBoxClassName,
  ...rest
}: MultiselectFieldProps) => {
  const memoizedOptions = useMemo(
    () =>
      optionsData.map(({ text: translation, value }) => ({
        label: translation,
        value,
      })),
    [optionsData],
  )

  return (
    <Dropdown
      label={label}
      name="multiselect"
      value={selected}
      disabled={disabled}
      multiple={true}
      className={className}
      onChange={onChange}
      buttonLabel={buttonLabelClassName}
      borderBoxClassName={borderBoxClassName}
      options={memoizedOptions}
      optionsProps={{ className: 'h-fit' }}
      {...rest}
    />
  )
}
