'use client'
import { AssetAllocationChart } from '@/features/portfolio/components/dashboard/asset-allocation-chart'
import { WarningIcon, Divider } from '@eframe-ui/react'
import clsx from 'clsx'
import { memo } from 'react'
import { HeaderPieChartConfig } from '@/components/graphs/types'

export const HeaderPieChart = memo(
  ({
    data,
    dataKey,
    innerRadius = 16,
    outerRadius = 20,
    paddingAngle = 2,
    size = 40,
    subtitle = '',
    isAnimationActive,
    hasError = { status: false, message: '' },
    isAssetClass = false,
  }: HeaderPieChartConfig) => {
    const value: string =
      data[0] && data[0].percent ? data[0].percent + '%' : '0%'

    return (
      <div className={'flex items-center'}>
        <div>
          <AssetAllocationChart
            size={size}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            paddingAngle={paddingAngle}
            data={data}
            dataKey={dataKey}
            value={value}
            valueStyle={'text-text-secondary typo-regular-100'}
            isAnimationActive={isAnimationActive}
          />
        </div>
        <div className="w-full pl-4">
          <div>
            <p
              className={clsx(
                isAssetClass &&
                  'text-text-primary typo-medium-300 gap-4 items-center',
                !isAssetClass &&
                  'text-text-primary typo-regular-200 gap-4 items-center',
              )}
            >
              {data[0].name}
              {hasError.status && (
                <p className="flex text-red gap-2 typo-regular-100">
                  <WarningIcon color="red" />
                  {hasError.message}
                </p>
              )}
            </p>
            <p className="text-text-secondary typo-regular-100">{subtitle}</p>
          </div>
        </div>
        <Divider />
      </div>
    )
  },
)
