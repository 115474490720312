/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Method used to calculate sum of the field values in an array of objects
 * @param array array of objects that have field with the given name
 * @param fieldName name of the field containing value that is going to be added to the sum
 * @returns 0 if no array or no fieldName is passed || sum of the field values
 */
export const sumFieldValueInObjectArray = (
  array: Array<any>,
  fieldName: string,
) => {
  if (!array || !fieldName) return 0
  return array.reduce((accumulator, item) => accumulator + item[fieldName], 0)
}
