import { FC } from 'react'
import { IconProps } from './types'

export const WalletIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9915 3.37607C19.06 3.92409 18.6713 4.42388 18.1233 4.49238L6.18567 5.98458C6.16609 5.9897 6.14687 5.99484 6.128 6H19.5C20.3284 6 21 6.67157 21 7.5V10C22.1046 10 23 10.8954 23 12V14C23 15.1046 22.1046 16 21 16V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V7.53957C2.99949 7.52647 2.99924 7.51332 2.99924 7.50011L2.99913 7.43388L2.99913 7.43388C2.99811 6.97744 2.99644 6.23942 3.38099 5.56647C3.8182 4.80135 4.61482 4.31543 5.7567 4.02996L5.81528 4.01532L17.8752 2.50783C18.4232 2.43932 18.923 2.82805 18.9915 3.37607ZM15 14C15 15.1046 15.8954 16 17 16H19V19H5V8H19V10H17C15.8954 10 15 10.8954 15 12V14ZM17 14V12H21V14H17Z"
        fill="currentColor"
      />
    </svg>
  )
}
