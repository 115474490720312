import { useMemo } from 'react'
import { RouteExp } from '../lib/route-exp'

export const useClientRoute = <TParams extends Record<string, unknown>>(
  url: string | RouteExp<TParams>,
  exact = false,
) => {
  return useMemo(() => {
    return typeof url === 'string' ? new RouteExp<TParams>(url, exact) : url
  }, [url, exact])
}
