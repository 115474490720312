import clsx from 'clsx'
import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
} from 'react'

import classes from './callout.module.scss'

export type CalloutIconProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export const CalloutIcon = forwardRef(
  ({ ...props }: CalloutIconProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        {...props}
        className={clsx(classes.calloutIcon, props.className)}
        ref={ref}
      >
        {props.children}
      </div>
    )
  },
)
