module.exports = {
  i18n: {
    defaultNS: 'common',
    defaultLocale: 'none',
    locales: ['none', 'de_CH', 'fr_CH', 'it_CH', 'en_GB'],
    keySeparator: '.',
    nsSeparator: ':',
    localeSeparator: '_',
  },
  languageLabels: {
    de_CH: 'Deutsch',
    it_CH: 'Italiano',
    en_GB: 'English',
    fr_CH: 'Français',
  },
}
