import { AuthenticationRequest, ErrorResponse } from '@/api'
import {
  userDataKey,
  useUserData,
} from '@/features/registration/hooks/client/use-add-credentials-client'
import { useDataSubmit, useHandleEnterSubmit } from '@/common'
import { startLoginRequest, useLoginForm } from '@/framework/auth'
import { LoginErrorMessage } from '@/framework/auth/components/login-error-message/login-error-message'
import { LoginFormState } from '@/framework/auth/types'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { useApiErrorHandler } from '@/framework/error-handling/hooks/use-api-error-handler'
import { useObserver } from '@/framework/hooks/use-observer'
import { areaCodeData } from '@/components/phone-number/components/phone-number'
import { PhoneNumberField } from '@/components/phone-number/phone-number-field'
import { InlineButton } from '@eframe-ui/react'
import {
  Button,
  LoadingButton,
  PasswordField,
  Translate,
  TranslateSimple,
} from '@eplix/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormEvent, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSWRConfig } from 'swr'
import { LoginFormType } from './types'

export const LoginForm = ({
  error,
  setIsLoginInProgress,
  setUserStatusState,
}: LoginFormType) => {
  const { t } = useTranslation()
  const { cache } = useSWRConfig()
  const phoneNumberRef = useRef<HTMLInputElement>(null)
  const user = useUserData()
  const router = useRouter()

  const localState = useObserver<LoginFormState>({
    refreshTokenError: error,
    enableValidation: false,
    fullNumber: '',
    countryCode: user?.areaCodeValue || areaCodeData[0],
  })

  const {
    result,
    submit: loginSubmit,
    error: loginError,
    isLoading: isRequestInProgress,
  } = useDataSubmit({
    fetcher: startLoginRequest,
  })
  const onLogin = async (values: AuthenticationRequest) => {
    cache.set(userDataKey, {
      username: values.username,
      fullNumber: localState.fullNumber,
      areaCodeValue: localState.countryCode,
    })

    setIsLoginInProgress?.(true)
    localState.refreshTokenError = null
    await router.replace('/auth/login', undefined, { shallow: true })

    await loginSubmit({
      username: localState.fullNumber,
      password: values.password,
    })
    setIsLoginInProgress?.(false)
  }

  const handleOnChangeFullNumber = (value: string) => {
    localState.fullNumber = value
  }

  const form = useLoginForm(
    {
      username: user?.username || '',
      password: '',
    },
    { enableValidation: localState.enableValidation },
    onLogin,
  )

  const apiErrorMessage = useApiErrorHandler<AuthenticationRequest>(
    (loginError || localState.refreshTokenError) as ErrorResponse,
    form,
    'USM-Login:ResponseError',
  )
  const { handleSubmit, handleChange, errors, values } = form

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    localState.enableValidation = true
    handleSubmit()
  }

  useEffect(() => {
    phoneNumberRef.current?.focus()
  }, [])

  useEffect(() => {
    setUserStatusState(
      result?.status ? result?.status : UserSessionStatusEnum.ANONYMOUS,
    )
  }, [setUserStatusState, result?.status])

  useEffect(() => {
    apiErrorMessage && setIsLoginInProgress?.(false)
  }, [apiErrorMessage, setIsLoginInProgress])

  const buttonRef = useRef<HTMLButtonElement>(null)
  useHandleEnterSubmit(buttonRef)

  const handlePasswordChange = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    event.currentTarget.value = value.replace(/\s/g, '')
    !isRequestInProgress && handleChange(event)
  }

  return (
    <>
      <Translate
        className="mb-4"
        variant="regular-600"
        label="USM-Login:LoginForm.LoginLeadQuestion"
      />
      <Translate
        className="mb-8"
        variant="regular-300"
        label="USM-Login:LoginForm.LoginLead"
      />
      <form
        className="flex flex-col gap-4 w-full"
        onSubmit={formSubmitHandler}
        noValidate
      >
        <PhoneNumberField
          forwardRef={phoneNumberRef}
          name="username"
          areaCode={areaCodeData}
          label={t('USM-Login:LoginForm.MobilePhoneLabel', 'Phone number')}
          disabled={isRequestInProgress}
          error={!!form.errors.username}
          value={form.values.username}
          setPhoneNumber={(value) => {
            return form.setFieldValue('username', value)
          }}
          setAreaCode={(v) => {
            localState.countryCode = v
          }}
          helpertext={form.errors.username}
          onChange={(v) => handleOnChangeFullNumber(v)}
        />
        <div>
          <PasswordField
            label={t('USM-Login:LoginForm.PasswordLabel', 'Password')}
            name="password"
            disabled={isRequestInProgress}
            autoComplete="current-password"
            error={!!errors.password}
            value={values.password}
            helperText={errors.password}
            onChange={handlePasswordChange}
          />
          <div className="flex justify-end max-h-5 mt-1 mb-4">
            <Link href={'/auth/forgot-password'} legacyBehavior>
              <InlineButton
                type="button"
                className="typo-regular-200 max-h-5"
                disabled={isRequestInProgress}
                data-cy="btn_forgot_password"
              >
                <TranslateSimple label="USM-Login:LoginForm.ForgotPasswordHelperText" />
              </InlineButton>
            </Link>
          </div>
          <LoginErrorMessage
            errorMessage={(loginError as ErrorResponse)?.message}
          />
          <LoadingButton
            type="submit"
            className="w-full lg:w-auto"
            loading={isRequestInProgress}
            variant="elevated"
            ref={buttonRef}
            text="USM-Login:LoginForm.SubmitLabel"
            name="login_submit"
          />
          <div className="w-full h-px bg-outline my-8" />
          <p className="text-text-secondary mb-4">
            {t(
              'USM-Login:LoginForm.SignupLead',
              "You don't have an account yet?",
            )}
          </p>
          <Link href="/registration" passHref legacyBehavior>
            <Button
              variant="outlined"
              name="signup"
              role="link"
              className="w-full lg:w-auto"
              type="button"
              disabled={isRequestInProgress}
              text="USM-Login:LoginForm.SignupLink"
            />
          </Link>
        </div>
      </form>
    </>
  )
}
