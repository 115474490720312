import { useCallback, useMemo, useRef, useState } from 'react'
import { Key, useSWRConfig } from 'swr'
import type { NextRouter } from 'next/router'
import { useRouter } from 'next/router'
import { atom, useAtom } from 'jotai'
import { getSalesPartnerPortfoliosKey } from '@/features/portfolio/hooks/client/overview/use-portfolio-settings'
import { portfolioDetailsKey } from '@/features/portfolio/hooks/client/overview/use-get-portfolio'
import { userInfoKey } from '@/features/dashboard/constants'
import {
  dataCombinationKey,
  editPortfolioInformationKey,
  pendingOrdersPredictionKey,
  portfolioOfferKey,
  portfolioReportsKey,
  portfoliosMetadataKey,
  submitPortfolioEditKey,
} from '@/framework/common/hooks/keys'
import { ErrorResponse } from '@/api'
import { asyncCall } from '@/common/helpers/async-wrapper'

export type MutationHookResult<
  TResponse = Record<string, unknown>,
  TPayload = Record<string, unknown>,
  TError = ErrorResponse,
> = [
  mutate: (payload?: TPayload, url?: string) => Promise<TResponse | void>,
  state: {
    data: TResponse | null
    errors: TError | null
    isLoading: boolean
  },
]

interface MutationHookOptions {
  emitLoadingAfter?: number
  shouldRevalidate?: boolean
}

const fetchedData = atom<object>({
  [portfolioReportsKey]: atom(null),
  [getSalesPartnerPortfoliosKey]: atom(null),
  [portfoliosMetadataKey]: atom(null),
  [portfolioDetailsKey]: atom(null),
  [editPortfolioInformationKey]: atom(null),
  [dataCombinationKey]: atom(null),
  [userInfoKey]: atom(null),
  [portfolioOfferKey]: atom(null),
  [pendingOrdersPredictionKey]: atom(null),
  [submitPortfolioEditKey]: atom(null),
})

export type FetchingResult<
  TRes = Record<string, unknown>,
  TErr = ErrorResponse,
> = {
  data: TRes | null
  errors: TErr | null
  isLoading: boolean
}

/** @deprecated Deprecated in favor of useDataFetcher */ // @ts-ignore
export const useNoCacheFetcher = (fetcher) => {
  const routerPush = useRouterPush()
  const [state, setState] = useState<FetchingResult>()

  // @ts-ignore
  const loader = useCallback(
    (payload) => {
      setState({ data: null, errors: null, isLoading: true })

      const wrapFetcher = async () => {
        let success = true
        let response = null
        let errors = null

        try {
          response = await fetcher(payload)
        } catch (e) {
          success = false
          // @ts-ignore
          if (e.status && e.status === 406) {
            errors = e

            await routerPush(
              // @ts-ignore
              `/auth/login?message=${e.message}&status=406`,
              '/auth/login',
            )
          }
        }

        const newState = {
          data: success ? response : null,
          errors: success ? null : errors,
          isLoading: false,
        } as FetchingResult

        setState(newState)
      }

      asyncCall(wrapFetcher)
    },
    [fetcher, routerPush, setState],
  )

  return [state, loader]
}

/** @deprecated Deprecated in favor of useDataFetcher */ // @ts-ignore
export const useFetcher = (fetcher, key: string) => {
  const routerPush = useRouterPush()

  const [allData] = useAtom(fetchedData)
  const [state, setState] = useAtom(allData[key as keyof typeof allData])

  // @ts-ignore
  const loader = useCallback(
    (payload) => {
      setState({ data: null, errors: null, isLoading: true })

      const wrapFetcher = async () => {
        let success = true
        let response = null
        let errors = null

        try {
          response = await fetcher(payload)
        } catch (e) {
          success = false
          // @ts-ignore
          if (e.status && e.status === 406) {
            errors = e

            await routerPush(
              // @ts-ignore
              `/auth/login?message=${e.message}&status=406`,
              '/auth/login',
            )
          }
        }

        const newState = {
          data: success ? response : null,
          errors: success ? null : errors,
          isLoading: false,
        }

        setState(newState)
      }

      asyncCall(wrapFetcher)
    },
    [fetcher, routerPush, setState],
  )

  return [state, loader]
}

/** @deprecated Deprecated in favor of useDataFetcher */
export const useMutation = <
  TResponse = Record<string, unknown>,
  TPayload = Record<string, unknown>,
  TError = ErrorResponse,
>(
  fetcher: (payload: TPayload) => Promise<TResponse>,
  key?: Key,
  { emitLoadingAfter = 1, shouldRevalidate = false }: MutationHookOptions = {},
): MutationHookResult<TResponse, TPayload, TError> => {
  const [data, setData] = useState<TResponse | null>(null)
  const [errors, setErrors] = useState<TError | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { mutate: mutateSWR } = useSWRConfig()
  const routerPush = useRouterPush()

  const mutate = useCallback(
    (payload?: TPayload): Promise<TResponse | void> => {
      const timeoutId = setTimeout(() => setIsLoading(true), emitLoadingAfter)
      return fetcher(payload ?? ({} as TPayload))
        .then((response: TResponse) => {
          setData(response)
          setErrors(null)
          if (key) {
            mutateSWR(key, response, shouldRevalidate)
          }

          return response
        })
        .catch(async (e) => {
          // status 406 means that refresh token has expired, so we need to do the reload and redirect user to auth/login
          if (e.status && e.status === 406) {
            await routerPush(
              `/auth/login?message=${e.message}&status=406`,
              '/auth/login',
            )
          } else {
            setErrors(e)
            return Promise.resolve(e) //check if this is ok
          }
        })
        .finally(() => {
          clearTimeout(timeoutId)
          setIsLoading(false)
        })
    },
    [fetcher, key, emitLoadingAfter, shouldRevalidate, mutateSWR, routerPush],
  )
  const state = useMemo(
    () => ({
      data,
      errors,
      isLoading,
    }),
    [data, errors, isLoading],
  )
  return [mutate, state]
}

// Workaround for a problem with useRouter (which causes the changes of router and re-renders when in dependency array)
export default function useRouterPush(): NextRouter['push'] {
  const router = useRouter()
  const routerRef = useRef(router)

  routerRef.current = router

  const [{ push }] = useState<Pick<NextRouter, 'push'>>({
    push: (path) => routerRef.current.push(path),
  })

  return push
}
