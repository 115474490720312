import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from './button'
import classes from './toggle-button.module.scss'
import { ToggleButtonConfig } from './types'
import { Translate } from '@eplix/ui'

const defaultConfig: ToggleButtonConfig = [
  {
    label: 'common:Yes',
    value: true,
    preselected: false,
    disabled: false,
  },
  {
    label: 'common:No',
    value: false,
    preselected: false,
    disabled: false,
  },
]

interface ToggleButtonProps {
  config?: ToggleButtonConfig
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onChange?: (value: any) => void
  className?: string
  as?: 'button' | 'a' | 'span'
  translatedLabel?: string
}

type SelectedValue = string | number | boolean | null

export const ToggleButton = ({
  config = defaultConfig,
  onChange,
  className,
  as,
  translatedLabel,
}: ToggleButtonProps) => {
  const preselectedValue = useMemo(() => {
    return config.find((item) => item.preselected)?.value
  }, [config])
  const [selectedValue, setSelectedValue] = useState<SelectedValue>(
    preselectedValue !== undefined ? preselectedValue : null,
  )
  const handleClick = useCallback(
    (value) => {
      if (selectedValue === value) {
        setSelectedValue(!selectedValue)
      } else {
        setSelectedValue(value)
      }
      onChange?.(value)
    },
    [selectedValue, onChange],
  )

  useEffect(() => {
    setSelectedValue(preselectedValue !== undefined ? preselectedValue : null)
  }, [preselectedValue])

  const memoizedButtons = useMemo(() => {
    return config.map(({ label, value, disabled, icon }) => {
      const shouldDisableButton =
        disabled || (config?.length > 1 && selectedValue === value)

      return (
        <Button
          key={String(value)}
          startIcon={icon}
          variant="outlined"
          onClick={() => handleClick(value)}
          className={selectedValue === value ? classes.selected : ''}
          as={as}
          disabled={shouldDisableButton}
          text={label}
          name="toggle"
          type={as === 'button' ? 'button' : undefined}
        />
      )
    })
  }, [config, selectedValue, handleClick, as])

  return (
    <div className="flex flex-col w-full sm:flex-row justify-between gap-4">
      <Translate
        color="text-primary"
        variant="regular-300"
        className="flex items-center"
        label={translatedLabel as string}
      />
      <div className={`${classes.btnGroup} ${className}`}>
        {memoizedButtons}
      </div>
    </div>
  )
}
