import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'

import classes from './greeting.module.scss'

export const GreetingFooter = forwardRef<
  HTMLElement,
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>(({ ...props }, ref) => {
  return (
    <footer
      {...props}
      className={clsx(classes.footer, props.className)}
      ref={ref}
    >
      {props.children}
    </footer>
  )
})
