import { ReactNode } from 'react'
import clsx from 'clsx'
import classes from './select-input.module.scss'
import menuItemClasses from '../menu-list/menu-list.module.scss'

export type SelectInputOptionGroupProps = {
  label?: string
  children: ReactNode
}

export const SelectInputOptionGroup = ({
  children,
  label,
}: SelectInputOptionGroupProps) => {
  return (
    <>
      {label ? (
        <li
          className={clsx(menuItemClasses.menuItem, classes.optionGroupTitle)}
          role={'presentation'}
          aria-hidden={'true'}
        >
          {label}
        </li>
      ) : null}
      {children}
    </>
  )
}
