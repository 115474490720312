import {
  ActionResponse,
  ChangeEmailAddressRequest,
  ChangeMobilePhoneNumberRequest,
  ChangeMobilePhoneNumberResponse,
  ChangePasswordRequest,
  ClosedPortfoliosResponse,
  CorrespondenceEmailSettingsUpdateResult,
  CorrespondenceRequest,
  CorrespondenceResponse,
  DeleteAccountRequest,
  DocumentGroup,
  EmployerInformation,
  EplixCorrespondenceEmail,
  ErrorResponse,
  PayslipsApiSetCorrespondenceEmailSettingsRequest,
  PersonalDocumentsRequest,
  PersonalDocumentsResponse,
  PersonalInformationResponse,
  ProfileFormRequest,
  ProfileLanguageFormRequest,
  ProfileResponse,
  ProfileVerifySmsCodeRequest,
  ProfileVerifySMSCodeResponse,
  PutAddressRequest,
  ResendSMSCodeRequest,
  ReuploadPersonalDocumentsRequest,
  SaveResponse,
  TaxCertificateResponse,
  UploadPersonalDocumentResponse,
} from '@/api'
import { GET_CLOSED_PORTFOLIOS } from '@/features/portfolio/api-routes/portfolio-overview'
import {
  getJSON,
  IsomorphicRequest,
  postJSON,
  putJSON,
} from '@/framework/common'

export const getEmployerOverview = async (req?: IsomorphicRequest) => {
  return getJSON<Array<EmployerInformation> | ErrorResponse>(
    '/api/user-account/employer-overview',
    req,
  )
}

export const updateInformation = async (req: ProfileFormRequest) => {
  return await putJSON<ProfileResponse | ErrorResponse, ProfileFormRequest>(
    '/api/user-account/post-user-information',
    req,
  )
}

export const updateLanguage = async (
  req: Omit<ProfileLanguageFormRequest, 'isMyTellcoCustomer'>,
) => {
  return putJSON<
    ProfileLanguageFormRequest,
    Omit<ProfileLanguageFormRequest, 'isMyTellcoCustomer'>
  >('/api/user-account/update-language', req)
}

export const getPersonalDocumentsOverview = async () => {
  return getJSON<DocumentGroup[] | ErrorResponse>(
    '/api/user-account/personal-documents-overview',
  )
}

export const getClosedPortfoliosProfile = async () => {
  return getJSON<ClosedPortfoliosResponse | ErrorResponse>(
    GET_CLOSED_PORTFOLIOS,
  )
}

export const fetchProfileDocuments = async (payload: number) => {
  return postJSON<BlobPart, number>(
    '/api/user-account/download-profile-documents',
    payload,
    {},
    'blob',
  )
}

export const fetchDocument = async (payload: string) => {
  return await postJSON<BlobPart, string>(
    '/api/user-account/fetch-document',
    payload,
    {},
    'blob',
  )
}

export const saveCorrespondence = async (
  req: Omit<CorrespondenceRequest, 'isMyTellcoCustomer'>,
) => {
  return putJSON<
    SaveResponse | ErrorResponse,
    Omit<CorrespondenceRequest, 'isMyTellcoCustomer'>
  >('/api/user-account/save-correspondence', req)
}

export const fetchRiskProfileDocument = async (payload: string) => {
  return postJSON<BlobPart, string>(
    '/api/user-account/risk-profile-documents-download',
    payload,
    {},
    'blob',
  )
}

export const resendVerificationEmail = async () => {
  return getJSON<ActionResponse | ErrorResponse>(
    '/api/user-account/resend-verification-email',
  )
}

export const resendCorrespondenceEmail = async () => {
  return getJSON<ActionResponse | ErrorResponse>(
    '/api/user-account/resend-correspondence-email',
  )
}

export const verifyProfileSmsCode = async (
  req: Omit<ProfileVerifySmsCodeRequest, 'isMyTellcoCustomer'>,
) => {
  return postJSON<
    ProfileVerifySMSCodeResponse,
    Omit<ProfileVerifySmsCodeRequest, 'isMyTellcoCustomer'>
  >('/api/user-account/profile-verify-sms-code', req)
}

export const deleteUserAccount = async (req: DeleteAccountRequest) => {
  return postJSON<ErrorResponse | ActionResponse, DeleteAccountRequest>(
    '/api/user-account/delete-user-account',
    req,
  )
}

export const getAllUserDocuments = async () => {
  return getJSON<BlobPart>(
    '/api/user-account/get-all-user-documents',
    {},
    'blob',
  )
}

export const getInformationFromDocumentBase64 = async (
  req: PersonalDocumentsRequest,
) => {
  return postJSON<
    UploadPersonalDocumentResponse | ErrorResponse,
    PersonalDocumentsRequest
  >('/api/user-account/get-information-from-document-base64', req)
}

export const completeProfileResendSmsRequest = async () => {
  return getJSON<ResendSMSCodeRequest>(
    '/api/user-account/profile-resend-sms-code',
  )
}

export const getCorrespondence = async () => {
  return getJSON<CorrespondenceResponse | ErrorResponse>(
    '/api/user-account/get-correspondence',
  )
}

export const changePhoneNumber = async (
  req: ChangeMobilePhoneNumberRequest,
) => {
  return postJSON<
    ChangeMobilePhoneNumberResponse,
    ChangeMobilePhoneNumberRequest
  >('/api/user-account/change-phone-number', req)
}

export const putChangePassword = async (req: ChangePasswordRequest) => {
  return await putJSON<SaveResponse, ChangePasswordRequest>(
    '/api/user-account/change-password',
    req,
  )
}

export const changeEmail = async (req: ChangeEmailAddressRequest) => {
  return await postJSON<SaveResponse, ChangeEmailAddressRequest>(
    '/api/user-account/change-email',
    req,
  )
}

export const getTaxCertificatesDocuments = async () => {
  return getJSON<TaxCertificateResponse | ErrorResponse>(
    '/api/user-account/get-tax-certificates-documents',
  )
}

export const fetchTaxCertificate = async (payload: number) => {
  return postJSON<BlobPart, number>(
    '/api/user-account/fetch-tax-certificate',
    payload,
    {},
    'blob',
  )
}

export const getUserDocumentInfo = async () => {
  return getJSON<PersonalDocumentsResponse | ErrorResponse>(
    '/api/user-account/get-user-document-information',
  )
}

export const reuploadPersonalDocuments = async (
  req: ReuploadPersonalDocumentsRequest,
) => {
  return postJSON<
    PersonalInformationResponse | ErrorResponse,
    ReuploadPersonalDocumentsRequest
  >('/api/user-account/reupload-personal-documents', req)
}

export const putUserAddress = async (
  req: PutAddressRequest,
  url = '/api/user-account/address',
) => {
  return putJSON<SaveResponse, PutAddressRequest>(url, req)
}

export const setCorrespondenceEmailSettings = async (
  req: Omit<
    PayslipsApiSetCorrespondenceEmailSettingsRequest,
    'acceptLanguage' | 'provider'
  >,
) => {
  return putJSON<
    CorrespondenceEmailSettingsUpdateResult | ErrorResponse,
    Omit<
      PayslipsApiSetCorrespondenceEmailSettingsRequest,
      'acceptLanguage' | 'provider'
    >
  >('/api/user-account/set-correspondence-email-settings', req)
}

export const getNeoCorrespondenceEmailSettings = async () => {
  return getJSON<EplixCorrespondenceEmail[]>(
    '/api/user-account/get-neo-correspondence-email-settings',
  )
}

export const setNeoCorrespondenceEmailSettingsPKPro = async (req: boolean) => {
  return putJSON<SaveResponse | ErrorResponse, boolean>(
    '/api/user-account/set-neo-correspondence-email-settings-pkpro',
    req,
  )
}

export const setNeoCorrespondenceEmailSettingsPKFlex = async (req: boolean) => {
  return putJSON<SaveResponse | ErrorResponse, boolean>(
    '/api/user-account/set-neo-correspondence-email-settings-pkflex',
    req,
  )
}
