import { useEnumMapper } from '@/framework/common/hooks/use-enum-mapper'
import { PersonalDocumentsHintEnum } from '@/common/enums/PersonalDocuments'

const useGetPersonalDocuments = () => {
  return useEnumMapper(PersonalDocumentsHintEnum ?? {}, 'PersonalDocumentsEnum')
}

export const getPersonalDocumentsHintActions = () => {
  return { useGetPersonalDocuments }
}
