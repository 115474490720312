export const FL = {
  9485: 'Nendeln',
  9486: 'Schaanwald',
  9487: 'Gamprin-Bendern',
  9488: 'Schellenberg',
  9489: 'Vaduz',
  9490: 'Vaduz',
  9491: 'Ruggell',
  9492: 'Eschen',
  9493: 'Mauren FL',
  9494: 'Schaan',
  9495: 'Triesen',
  9496: 'Balzers',
  9497: 'Triesenberg',
  9498: 'Planken',
}
