import { FC } from 'react'
import { IconProps } from './types'

export const SecondPillarIcon: FC<IconProps> = ({
  iconSize = 24,
  ...props
}) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5H8V19H6C5.44772 19 5 19.4477 5 20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20C19 19.4477 18.5523 19 18 19H16V5H18C18.5523 5 19 4.55228 19 4C19 3.44772 18.5523 3 18 3H6ZM14 5H10V19H14V5Z"
        fill="currentColor"
      />
    </svg>
  )
}
