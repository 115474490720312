export const SH = {
  8200: 'Schaffhausen',
  8201: 'Schaffhausen',
  8202: 'Schaffhausen',
  8203: 'Schaffhausen',
  8204: 'Schaffhausen',
  8205: 'Schaffhausen',
  8207: 'Schaffhausen',
  8208: 'Schaffhausen',
  8210: 'Schaffhausen DistB',
  8212: 'Neuhausen',
  8213: 'Neunkirch',
  8214: 'Gächlingen',
  8215: 'Hallau',
  8216: 'Oberhallau',
  8217: 'Wilchingen',
  8218: 'Osterfingen',
  8219: 'Trasadingen',
  8222: 'Beringen',
  8223: 'Guntmadingen',
  8224: 'Löhningen',
  8225: 'Siblingen',
  8226: 'Schleitheim',
  8228: 'Beggingen',
  8231: 'Hemmental',
  8232: 'Merishausen',
  8233: 'Bargen SH',
  8234: 'Stetten SH',
  8235: 'Lohn SH',
  8236: 'Opfertshofen SH',
  8239: 'Dörflingen',
  8240: 'Thayngen',
  8241: 'Barzheim',
  8242: 'Bibern SH',
  8243: 'Altdorf SH',
  8260: 'Stein am Rhein',
  8261: 'Hemishofen',
  8262: 'Ramsen',
  8263: 'Buch SH',
  8454: 'Buchberg',
  8455: 'Rüdlingen',
}
