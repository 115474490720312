import { PortfolioTypeMetadataResponse, ProfileResponse } from '@/api'
import { getUserAgeAdequateActions } from '@/common/actions/user-age-adequate-actions'
import { DisplayDateFormat } from '@/common'
import {
  getDifferenceBetweenYears,
  isDateSameOrBefore,
  parseDisplayDate,
} from '@/framework'
import { UserTypeEnum, WorkingStatusEnum } from '@/common/enums'
import { PortfolioTypeEnum } from '@/common/enums/PortfolioType'
import { ReasonToForbidOpening } from '@/common/enums/ReasonToForbidOpening'
import { Moment } from 'moment'
import { PensionAccessInformationResult } from '@/common/types/pension-types'
import { replaceUndefinedPropsWithNull } from '@/framework/common/lib/object-actions'

export const getMetadataForType = (
  metadata: Array<PortfolioTypeMetadataResponse>,
  type: PortfolioTypeEnum,
) => metadata?.find((x) => x.type === type)

export const getBothPensionAccessInformation = (
  metadata: Array<PortfolioTypeMetadataResponse>,
  information: ProfileResponse,
) => {
  const pensionAccessInformation = {
    infoFZS: { eligibleToOpen: true, eligibleToSee: true },
    info3a: { eligibleToOpen: true, eligibleToSee: true },
  } as PensionAccessInformationResult

  if (
    metadata === undefined ||
    metadata.length === 0 ||
    information === undefined
  )
    return replaceUndefinedPropsWithNull(pensionAccessInformation)

  metadata.forEach((typeMetadata) => {
    if (typeMetadata.type === PortfolioTypeEnum.PILLAR_3A) {
      pensionAccessInformation.info3a = getPensionAccessInformationFor3a(
        typeMetadata,
        information,
      )
    } else {
      pensionAccessInformation.infoFZS = getPensionAccessInformationForFZS(
        typeMetadata,
        information,
      )
    }
  })

  return replaceUndefinedPropsWithNull(pensionAccessInformation)
}

export const getPensionAccessInformation = (
  metadata?: PortfolioTypeMetadataResponse,
  information?: ProfileResponse,
) => {
  const response =
    metadata?.type === PortfolioTypeEnum.PILLAR_3A
      ? getPensionAccessInformationFor3a(metadata, information)
      : getPensionAccessInformationForFZS(metadata, information)
  return replaceUndefinedPropsWithNull(response)
}

const getPensionAccessInformationForFZS = (
  metadata?: PortfolioTypeMetadataResponse,
  information?: ProfileResponse,
) => {
  if (metadata === undefined || information === undefined)
    return { eligibleToOpen: true, eligibleToSee: true }

  const isAlkimia = information.userType === UserTypeEnum.MY_TELLCO_ALKIMIA
  const isUserAgeAdequate = isUserAgeAdequateForPortfolioOpening(information)

  const hasFzsPortfolio = metadata.openedPortfolios > 0
  const isAgeAdequateFzs = isUserAgeAdequate.forFZS

  //if user has portfolios, he should see the pension no matter the age
  return {
    eligibleToSee: (hasFzsPortfolio || isAgeAdequateFzs) && !isAlkimia,
    eligibleToOpen:
      metadata.isEligibleToOpenMore && isUserAgeAdequate.forFZS && !isAlkimia,
    reasonToForbidOpening: getReasonToForbidOpening(
      isUserAgeAdequate.forFZS,
      metadata.isEligibleToOpenMore,
    ),
  }
}

const getPensionAccessInformationFor3a = (
  metadata?: PortfolioTypeMetadataResponse,
  information?: ProfileResponse,
) => {
  if (metadata === undefined || information === undefined)
    return { eligibleToOpen: true, eligibleToSee: true }

  const isUserAgeAdequate = isUserAgeAdequateForPortfolioOpening(information)

  const has3aPortfolio = metadata.openedPortfolios > 0
  const isAgeAdequate3a = isUserAgeAdequate.for3A

  //if user has portfolios, he should see the pension no matter the age
  return {
    eligibleToOpen: metadata.isEligibleToOpenMore && isUserAgeAdequate.for3A,
    eligibleToSee: has3aPortfolio || isAgeAdequate3a,
    reasonToForbidOpening: getReasonToForbidOpening(
      isUserAgeAdequate.for3A,
      metadata.isEligibleToOpenMore,
    ),
  }
}

const getReasonToForbidOpening = (
  isUserAgeAdequateInfo: boolean,
  isEligibleToOpenMore: boolean,
) => {
  return !isUserAgeAdequateInfo
    ? ReasonToForbidOpening.TOO_OLD
    : !isEligibleToOpenMore
    ? ReasonToForbidOpening.MAX_NUMBER_EXCEEDED
    : undefined
}

const getRetirementDate = (userBirthdate: Moment, retirementYears: number) => {
  return userBirthdate
    .clone()
    .add(retirementYears, 'years')
    .format(DisplayDateFormat.YYYY_MM)
}

/**
 * Method used to check if user age is above 17 years, and below pension opening limit (64(f)/65(m)-not employed, 69(f)/70(m)-employed for 3A, and 69(f)/70(m) for FZS).
 * @param information information about user
 * @returns object containing two booleans, one for each type (3A / FZS)
 */
const isUserAgeAdequateForPortfolioOpening = (information: ProfileResponse) => {
  const { gender, birthdate, employerStatus } = information
  const userBirthdate = parseDisplayDate(birthdate as string)
  const userAge = getDifferenceBetweenYears(userBirthdate)

  const isEmployed =
    employerStatus === WorkingStatusEnum.EMPLOYED ||
    employerStatus === WorkingStatusEnum.SELF_EMPLOYED
  const {
    getIsUserAdulthood,
    getRetirementYearsReqular,
    getRetirementYearsUpperBound,
    getIsUserInOldAgeWithoutWorkingStatus,
  } = getUserAgeAdequateActions(
    userAge,
    gender as string,
    employerStatus as string,
  )

  const isUserAdulthood = getIsUserAdulthood()
  const retirementYearsRegular = getRetirementYearsReqular()
  const retirementYearsUpperBound = getRetirementYearsUpperBound()
  const isUserInOldAge = getIsUserInOldAgeWithoutWorkingStatus()

  const retirementDateUpperBound = getRetirementDate(
    userBirthdate,
    retirementYearsUpperBound,
  )
  const retirementDateRegular = getRetirementDate(
    userBirthdate,
    retirementYearsRegular,
  )

  const retirementDate3A = isEmployed
    ? retirementDateUpperBound
    : retirementDateRegular

  const isUserAgeAdequateFor3A =
    isDateSameOrBefore(retirementDate3A) && isUserAdulthood
  const isUserAgeAdequateForFZS =
    isDateSameOrBefore(retirementDateUpperBound) && isUserAdulthood

  return {
    for3A: isUserAgeAdequateFor3A || isUserInOldAge,
    forFZS: isUserAgeAdequateForFZS,
  } as UserAgeAdequacy
}

export const getInvitationFromUserType = (
  metadata: PortfolioTypeMetadataResponse[],
  information: ProfileResponse,
) => {
  return {
    userType: information.userType as string,
    hasPortfolioOverview:
      (metadata as PortfolioTypeMetadataResponse[]).filter(
        (m) => m.portfoliosMetadata?.length > 0,
      )?.length > 0,
  }
}

export type UserAgeAdequacy = {
  for3A: boolean
  forFZS: boolean
}
