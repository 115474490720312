export const detectEdges = (
  targetRect: DOMRect,
  popperRect: DOMRect,
  screenWidth: number,
  screenHeight: number,
) => {
  const spacing = 18 // this is the value of the given spike width or height it depends on placement

  const edges = {
    left: targetRect.left - popperRect.width < 0,
    right:
      targetRect.left + targetRect.width + popperRect.width + spacing >
      screenWidth,
    top: targetRect.top - (popperRect.height + spacing) < 0,
    bottom:
      targetRect.top + targetRect.height + popperRect.height + spacing >
      screenHeight,
  }

  if (edges.top && edges.bottom) {
    const isCloserToTop =
      (targetRect.top + targetRect.height + spacing) / 2 < screenHeight / 2
    edges.top = isCloserToTop
    edges.bottom = !isCloserToTop
  }

  if (edges.left && edges.right) {
    const isCloserToLeft =
      (targetRect.left + targetRect.width + spacing) / 2 < screenWidth / 2
    edges.left = isCloserToLeft
    edges.right = !isCloserToLeft
  }

  return edges
}
