import clsx from 'clsx'
import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from 'react'

import classes from './greeting.module.scss'

export interface GreetingProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  icon: ReactNode
}

export const GreetingContent = forwardRef(
  (
    { icon, children, ...props }: GreetingProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        {...props}
        className={clsx(classes.content, props.className)}
        ref={ref}
      >
        <div className={classes.imgDiv}>
          <figure className={classes.icon}>{icon}</figure>
        </div>
        {children}
      </div>
    )
  },
)
