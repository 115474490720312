import type { InitOptions as I18nInitOptions } from 'i18next'
import { i18n as config } from '@/i18next.config'
import { LocaleEnum } from '@/common/enums/Locale'

export const i18nSettings: I18nInitOptions = {
  defaultNS: config.defaultNS,
  ns: config.defaultNS,
  supportedLngs: config.locales,
  fallbackLng: LocaleEnum.DE,
  keySeparator: config.keySeparator,
  nsSeparator: config.nsSeparator,
}
