import {
  FormFieldBorderBox,
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
  TextInputProps,
  WarningIcon,
} from '@eplix/ui'
import { useId } from '@eframe-ui/react'

import ReactInputMask from 'react-input-mask'
import { memo } from 'react'

export interface MaskFieldProps extends TextInputProps {
  label: string
  name: string
  error?: boolean
  helperText?: string | JSX.Element
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
  className?: string
  mask: string | Array<string | RegExp>
  maskplaceholder: string
  disabled?: boolean
}

const getTextFieldVariant = ({ disabled, error }: MaskFieldProps) => {
  if (disabled) {
    return 'disabled'
  } else if (error) {
    return 'error'
  }
}

export const MaskedInput = memo((props: MaskFieldProps) => {
  const {
    label,
    name,
    error,
    helperText,
    startAdornment,
    endAdornment,
    className,
    mask,
    maskplaceholder,
    ...rest
  } = props
  const id = useId()
  const ariaLabelId = `label-${id}`
  return (
    <FormFieldControl
      className={className}
      variant={getTextFieldVariant(props)}
    >
      <FormFieldLabel htmlFor={ariaLabelId} label={label} />
      <FormFieldBorderBox
        style={{ backgroundColor: 'var(--ex-rgb-surface-2)' }}
      >
        <>{startAdornment}</>
        <ReactInputMask
          className="w-full"
          style={{ backgroundColor: 'var(--ex-rgb-surface-2)' }}
          mask={mask}
          data-cy={name}
          maskPlaceholder={maskplaceholder}
          {...rest}
        />
        <>
          {error && <WarningIcon style={{ color: 'var(--border-color)' }} />}
          {endAdornment}
        </>
      </FormFieldBorderBox>
      {helperText && <FormFieldHelperText helperText={helperText} />}
    </FormFieldControl>
  )
})
