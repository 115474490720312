import { Button, LoadingButton, Translate } from '@eplix/ui'
import { useConfirmPhoneForm } from '@/framework/auth'
import { useSWRConfig } from 'swr'
import {
  userDataKey,
  useUserData,
} from '@/features/registration/hooks/client/use-add-credentials-client'
import { PhoneNumberField } from '@/components/phone-number/phone-number-field'
import { areaCodeData } from '@/components/phone-number/components/phone-number'
import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { ForgotPasswordRequest } from '@/api'
import { useDataSubmit } from '@/common'
import { sendNumberRequest } from '@/framework/auth'
import { ForgotPasswordProps } from './types'
import { isEmpty } from 'lodash'

export const ForgotPasswordConfirmPhoneForm = ({
  wizardProps,
}: ForgotPasswordProps) => {
  const { cache } = useSWRConfig()
  const { submit, result, error, isLoading } = useDataSubmit({
    fetcher: sendNumberRequest,
  })
  const phoneNumberRef = useRef<HTMLInputElement>(null)
  const user = useUserData()
  const [countryCode, setCountryCode] = useState(
    user?.areaCodeValue || areaCodeData[0],
  )
  const confirmOnSubmit = async (values: ForgotPasswordRequest) => {
    cache.set(userDataKey, {
      username: values.phonenumber,
      fullNumber,
      areaCodeValue: countryCode,
    })
    await submit({ phonenumber: fullNumber })
  }

  useEffect(() => {
    if (result && isEmpty(error)) {
      wizardProps && wizardProps.handleNext()
    }
  }, [error, result, wizardProps])

  const form = useConfirmPhoneForm(
    {
      phonenumber: user?.username || '',
    },
    confirmOnSubmit,
  )
  const [fullNumber, setFullNumber] = useState('')
  const handleOnChangeFullNumber = (value: string) => {
    setFullNumber(value)
  }

  useEffect(() => {
    phoneNumberRef.current?.focus()
  }, [])

  return (
    <div className="flex flex-col gap-8 w-full">
      <Translate
        variant="regular-300"
        label="USM-ForgotPassword:ConfirmPhoneForm.TextLead"
      />
      <form
        className="flex flex-col w-full lg:items-start"
        onSubmit={form.handleSubmit}
      >
        <PhoneNumberField
          forwardRef={phoneNumberRef}
          name="phonenumber"
          areaCode={areaCodeData}
          label="USM-ForgotPassword:ConfirmPhoneForm.PhoneNumberLabel"
          disabled={isLoading}
          error={!!form.errors.phonenumber}
          value={form.values.phonenumber}
          setPhoneNumber={(value) => {
            return form.setFieldValue('phonenumber', value)
          }}
          setAreaCode={(v) => setCountryCode(v)}
          helpertext={form.errors.phonenumber}
          onChange={(v) => handleOnChangeFullNumber(v)}
          className="w-full"
        />
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          type="submit"
          className="w-full lg:w-auto mt-4"
          text="USM-ForgotPassword:ConfirmPhoneForm.ResetButton"
          name="forgot_password_reset"
        />
        <div className="w-full border-b border-outline my-8" />
        <Translate
          label="USM-ForgotPassword:ConfirmPhoneForm.CancelPasswordResetLabel"
          variant="regular-300"
        />
        <Link href="/auth/login" legacyBehavior>
          <Button
            role="link"
            variant="outlined"
            name="forgot_password_back"
            className="mt-4"
            disabled={isLoading}
            text="USM-ForgotPassword:ConfirmPhoneForm.BackButton"
          />
        </Link>
      </form>
    </div>
  )
}
