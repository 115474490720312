import {
  createElement,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactHTML,
} from 'react'
import clsx from 'clsx'

import classes from './chip.module.scss'

export type ChipElement = 'button' | 'a' | 'span'
export type ChipVariant = 'elevated' | 'outlined' | 'dashed'

export interface ChipProps<TElement extends ChipElement>
  extends HTMLProps<ReactHTML[TElement]> {
  /**
   * Controls the basic appearance of a chip. Defaults to 'elevated'.
   * @default "elevated"
   */
  variant?: ChipVariant
  /**
   * Indicates a selected chip.
   */
  selected?: boolean
  /**
   * A JSX element that, if present, will get rendered to the left the label text. Usually an icon component.
   */
  startIcon?: JSX.Element
  /**
   * Controls which DOM element is used to render the button. Obviously impacts which additional props the component
   * can take. Possible options: `'a'` and `'span'`. Defaults to `'button'`
   */
  as?: TElement
  /**
   * name will be used for custom cypress attribute `data-cy={`chip_btn_${name}`}
   */
  name: string
}

const getVariantClass = (variant?: ChipVariant) => {
  switch (variant) {
    case 'outlined':
      return classes.outlined
    case 'dashed':
      return classes.dashed
    default:
      return classes.elevated
  }
}

export const Chip = forwardRef(
  <TElement extends ChipElement = 'button'>(
    {
      as,
      variant,
      name,
      startIcon,
      selected,
      className,
      children,
      ...props
    }: ChipProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'button',
      {
        ...props,
        ['data-selected']: selected,
        'data-cy': `chip_btn_${name}`,
        ref,
        className: clsx(classes.chipBase, getVariantClass(variant), className),
      },
      <>
        {startIcon}
        <span>{children}</span>
      </>,
    )
  },
)
