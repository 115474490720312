export const Constants = {
  ThreeDots: '...',
  Slash: '/',
  Dot: '.',
  Space: ' ',
  Dash: '-',
  SeveralDashes: '-----',
  DashWithSpace: ' - ',
  Underscore: '_',
  Percentage: '%',
  Comma: ',',
  LoadNumberValue: '--',
  EmptyString: '',
  PleaseSelect: 'PLEASE_SELECT',
  YTD: 'YTD',
  ISIN: 'ISIN-Nr. ',
  EplixWebsite: 'https://eplix.ch',
  EplixWebsiteEN: 'https://eplix.ch/en',
  EplixWebsiteDE: 'https://eplix.ch/de',
  EplixWebsiteFR: 'https://eplix.ch/fr',
  EplixWebsiteIT: 'https://eplix.ch/it',
  EplixTermsOfUseEN: 'https://eplix.ch/en/service/terms-of-use',
  EplixTermsOfUseDE: 'https://eplix.ch/de/service/nutzungsbedingungen',
  EplixTermsOfUseFR: 'https://eplix.ch/fr/service/conditions-utilisation',
  EplixTermsOfUseIT: 'https://eplix.ch/it/service/termini-utilizzo',
  EplixPrivacyPolicyEN: 'https://eplix.ch/en/service/privacy-policy',
  EplixPrivacyPolicyDE: 'https://eplix.ch/de/service/datenschutz',
  EplixPrivacyPolicyFR: 'https://eplix.ch/fr/service/protection-donnees',
  EplixPrivacyPolicyIT: 'https://eplix.ch/it/service/protezione-dati',
  EdoebAdminEN: 'https://www.edoeb.admin.ch/edoeb/en/home.html',
  EdoebAdminDE: 'https://www.edoeb.admin.ch/edoeb/de/home.html',
  EdoebAdminFR: 'https://www.edoeb.admin.ch/edoeb/fr/home.html',
  EdoebAdminIT: 'https://www.edoeb.admin.ch/edoeb/it/home.html',
  EurLexEN: 'https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=en',
  EurLexDE: 'https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de',
  EurLexFR: 'https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=fr',
  EurLexIT: 'https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=it',
  DatadogCookies: 'https://datadoghq.com/legal/cookies/',
  DatadogPrivacy: 'https://datadoghq.com/legal/privacy/',
  EplixProviderEN: 'https://www.eplix.ch/en/service/provider',
  EplixProviderDE: 'https://www.eplix.ch/de/service/provider',
  EplixProviderFR: 'https://www.eplix.ch/fr/service/provider',
  EplixProviderIT: 'https://www.eplix.ch/it/service/provider',
  EplixDataProtectionMail: 'dataprotection@eplix.ch',
  EplixServiceMail: 'service.eplix.ch',
  PhoneNumberTooltip: '79 999 99 99',
}
