import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { useDataFetcher } from '@/common/hooks'
import { MainNavigation, MaintenanceBanner } from '@/components'
import { getPlannedMaintenance } from '@/features/maintenance/lib/planned-maintenance-client'
import { GlobalValueKey, useMaintenanceModeBannerHeight } from '@/framework'
import { AnimatePresence, motion } from 'framer-motion'
import { NextRouter, useRouter } from 'next/router'
import { FC, useEffect, useRef } from 'react'
import { getApplicationWrapperActions } from './actions'
import classes from './application-wrapper.module.scss'

const desktopVariants = {
  enter: () => {
    return { opacity: 0, zIndex: 0 }
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: () => {
    return { zIndex: 0, opacity: 0 }
  },
}

const useDirection = (router: NextRouter) => {
  const { pathname: currentRoute } = router
  const prevRoute = useRef<string>()
  useEffect(() => {
    prevRoute.current = currentRoute
  }, [currentRoute])
  return prevRoute.current?.length ?? 0 < currentRoute.length ? 1 : -1
}

interface UserPrefetchData {
  currentUser: { status: UserSessionStatusEnum }
}

export interface ApplicationWrapperProps {
  navAlwaysCollapsed?: boolean
  navWide?: boolean
  navDisabled?: boolean
  panelKey?: string
  prefetch?: UserPrefetchData
}

export const ApplicationWrapper: FC<ApplicationWrapperProps> = ({
  children,
  navWide,
  navDisabled,
  panelKey,
  prefetch,
  navAlwaysCollapsed,
}) => {
  const router = useRouter()
  const direction = useDirection(router)
  const [bannerHeight] = useMaintenanceModeBannerHeight()
  const style = {
    paddingTop: bannerHeight > 0 ? `${bannerHeight}px` : '0px',
  }
  const userStatus = prefetch?.currentUser?.status
  const { result: plannedMaintenance } = useDataFetcher({
    fetcher: getPlannedMaintenance,
    fetchTriggerKey: GlobalValueKey.FETCH_TRIGGER_plannedMaintenances,
  })

  const { getShowAuthUser, getShowAnonymusUser } =
    getApplicationWrapperActions()

  const notifyAuthUser =
    plannedMaintenance?.plannedMaintenances[0]?.shouldNotifyAuthUser

  const notifyAnonymousUser =
    plannedMaintenance?.plannedMaintenances[0]?.shouldNotifyAnonymousUser

  const showAuthUser = getShowAuthUser(notifyAuthUser, userStatus)

  const showAnonymusUser = getShowAnonymusUser(notifyAnonymousUser, userStatus)

  const showMaintenanceBanner = () => {
    if (notifyAnonymousUser && notifyAuthUser) return <MaintenanceBanner />
    else if (showAuthUser) return <MaintenanceBanner />
    else if (showAnonymusUser) return <MaintenanceBanner />
  }

  return (
    <div className={classes.layout}>
      {showMaintenanceBanner()}
      <div className="flex h-full" style={style}>
        <MainNavigation
          wide={navWide}
          disabled={navDisabled}
          navAlwaysCollapsed={navAlwaysCollapsed}
          userStatus={userStatus as UserSessionStatusEnum}
        />
        <main className={classes.main}>
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={panelKey || router.pathname}
              custom={direction}
              variants={desktopVariants}
              className={classes.panel}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                duration: 0.25,
                ease: 'easeInOut',
              }}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </div>
  )
}
