import { FC } from 'react'
import { IconProps } from './types'

export const LoadingIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C11 3.44772 11.4477 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C11.4477 5 11 4.55228 11 4Z"
        fill="currentColor"
      />
    </svg>
  )
}
