import { i18n } from '@/i18next.config'
import { NextApiRequest } from 'next'
import { LanguageCodeEnum } from '@/common/enums/LanguageCode'
import { LocaleEnum } from '@/common/enums/Locale'

export const LOCALE_PATTERN = new RegExp('/((?<locale>[a-z]{2}_[A-Z]{2}))(/|$)')

const languageCodeToLocaleMap = {
  [LanguageCodeEnum.DE]: LocaleEnum.DE,
  [LanguageCodeEnum.FR]: LocaleEnum.FR,
  [LanguageCodeEnum.IT]: LocaleEnum.IT,
  [LanguageCodeEnum.EN]: LocaleEnum.EN,
}
/**
 * Helper method that provides locale language code from NextApiRequest by searching for
 * an appearance of {LOCALE_PATTERN} (where languageCodes are represented by two lowercase letters and two uppercase letters with _ in between)
 * inside the request header referer.
 * If the language is not to be found, the i18n.defaultLocale is going to be returned.
 *
 * @param request NextApiRequest that contains route in its header.
 * @returns string locale code (en_??/de_??)
 */
export const getRequestLocale = (request: NextApiRequest) => {
  const referer = request?.headers?.referer // on page refresh referer is sometimes undefined. The headers does not contain referer
  if (!referer) {
    return i18n.defaultLocale
  }
  const locale = getLocaleFromUrl(referer, i18n.defaultLocale)
  if (!i18n.locales.includes(locale)) {
    console.warn(
      `Invalid locale ${locale} found in URL ${referer}. Falling back to "${i18n.defaultLocale}"`,
    )
    return i18n.defaultLocale
  }
  return locale
}

/**
 * Method used to process the referer string value and return appropriate locale
 * @param url string value of request.headers.referer
 * @param defaultLocale string value of i18n.defaultLocale
 * @returns string locale code (en/de)
 */
export const getLocaleFromUrl = (url: string, defaultLocale: string) => {
  const match = LOCALE_PATTERN.exec(url)
  return !match || !match?.groups ? defaultLocale : match.groups.locale
}

/**
 * Method used to get stripped version of request locale. The stripped version contains only the two lowercase letters before _
 * @param request
 * @returns
 */
export const getStrippedRequestLocale = (request: NextApiRequest) => {
  const locale = getRequestLocale(request)
  return stripLocale(locale)
}

export const stripLocale = (locale: string) => {
  const localeSplitArray = locale.split(i18n.localeSeparator)
  return localeSplitArray[0]
}

export const getLocaleForRequestLanguageCode = (
  languageCode: LanguageCodeEnum,
) => {
  return languageCodeToLocaleMap[languageCode]
}
