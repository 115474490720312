/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:01:17.

export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  ITALIAN = 'ITALIAN',
  GERMAN = 'GERMAN',
}
