import { FC } from 'react'
import { IconProps } from './types'

export const EditIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 ${iconSize} ${iconSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.39038L12.6095 6.99986L4.80518 14.8041C4.68016 14.9292 4.51014 14.9999 4.33333 14.9999H2C1.44772 14.9999 1 14.5521 1 13.9999V11.6665C1 11.4897 1.07024 11.3201 1.19526 11.1951L9 3.39038ZM2.33333 11.9427V13.6665H4.05719L10.7239 6.99986L9 5.276L2.33333 11.9427Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1378 2.08084C12.8775 1.8205 12.4554 1.82049 12.195 2.08082L11.6093 2.6665L13.3332 4.39036L13.9188 3.80472C14.1792 3.54438 14.1792 3.12225 13.9189 2.86189L13.1378 2.08084ZM11.2523 1.13796C12.0333 0.35698 13.2996 0.357012 14.0806 1.13803L14.8617 1.91908C15.6428 2.70016 15.6427 3.96654 14.8616 4.74758L13.3331 6.27593L9.72363 2.66645L11.2523 1.13796Z"
        fill="currentColor"
      />
    </svg>
  )
}
