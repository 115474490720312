import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react'
import classes from './definition-list.module.scss'

export interface DefinitionDescriptionProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string
  children?: ReactNode
  name?: string
}

export const DefinitionListDescription = forwardRef<
  HTMLElement,
  DefinitionDescriptionProps
>(({ className, children, name, ...props }, ref) => {
  return (
    <dd
      className={clsx(classes.definitionListDescription, className)}
      {...props}
      style={{ ...props.style }}
      ref={ref}
      data-cy={name}
    >
      {children}
    </dd>
  )
})
