import { TranslateSimple } from '@eplix/ui'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import {
  ForwardedRef,
  forwardRef,
  Fragment,
  HTMLAttributes,
  ReactNode,
} from 'react'
import { Grid } from '../grid'
import { ThemeProvider, useTheme } from '../theme-provider'
import { DialogHeader } from './dialog-header'
import { DialogOverlay } from './dialog-overlay'
import classes from './dialog.module.scss'

export type DialogVariant = 'base' | 'choice' | 'alert' | 'select'

export interface DialogProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  hasOverlay?: boolean
  closeIconText?: string
  variant?: DialogVariant
  title?: ReactNode
  onClose: (value: boolean) => void
  isOpen: boolean
}

export const Dialog = forwardRef(
  (
    {
      variant = 'base',
      hasOverlay = true,
      closeIconText,
      title,
      isOpen,
      className,
      children,
      onClose,
      ...props
    }: DialogProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [theme] = useTheme()

    return (
      <Transition
        show={isOpen}
        enter={classes.transitionEnter}
        enterFrom={classes.transitionEnterFrom}
        enterTo={classes.transitionEnterTo}
        leave={classes.transitionLeave}
        leaveFrom={classes.transitionLeaveFrom}
        leaveTo={classes.transitionLeaveTo}
        as={Fragment}
      >
        <HeadlessDialog
          onClose={onClose}
          className={clsx(className, classes.dialog, classes[variant])}
          ref={ref}
          {...props}
        >
          <ThemeProvider className={classes.contentWrap} theme={theme}>
            {hasOverlay ? <DialogOverlay /> : null}
            <Grid className={classes.grid}>
              <article className={classes.content}>
                {title ? (
                  <DialogHeader closeIconText={closeIconText} onClose={onClose}>
                    <TranslateSimple label={title as string} />
                  </DialogHeader>
                ) : null}
                {children}
              </article>
            </Grid>
          </ThemeProvider>
        </HeadlessDialog>
      </Transition>
    )
  },
)
