import { ForwardedRef, forwardRef, HTMLProps, Children } from 'react'
import clsx from 'clsx'

import classes from './filter-badge.module.scss'

export interface BadgeProps extends HTMLProps<HTMLDivElement> {
  value?: string | number
  minimized?: boolean
  addBorder?: boolean
  indicator?: boolean
  offset?: [number | string, number | string]
}

export const FilterBadge = forwardRef(
  (
    {
      value,
      minimized,
      addBorder,
      indicator,
      offset,
      className,
      children,
      ...props
    }: BadgeProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      className={clsx(
        classes.badgeBase,
        children && classes.badgeCorner,
        minimized && classes.minimized,
        addBorder && classes.addBorder,
        indicator && classes.indicator,
        value && value.toString().length > 1 && classes.multipleValues,
        className,
      )}
      ref={ref}
      {...props}
    >
      {children && Children.only(children)}
      <span
        className={classes.badgeValue}
        style={
          offset?.length && {
            right: offset[0],
            top: offset[1],
          }
        }
      >
        {!minimized && !indicator && value}
      </span>
    </div>
  ),
)
