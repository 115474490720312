import { FC } from 'react'
import { IconProps } from './types'

export const UploadIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5948 9.26006C16.2043 9.65058 15.5711 9.65058 15.1806 9.26006L12.998 7.07752L12.998 15H10.998L10.998 7.07903L8.81702 9.26006C8.4265 9.65058 7.79333 9.65058 7.40281 9.26006C7.01229 8.86953 7.01229 8.23637 7.40281 7.84584L11.2917 3.95696C11.6822 3.56643 12.3154 3.56643 12.7059 3.95696L16.5948 7.84584C16.9853 8.23637 16.9853 8.86953 16.5948 9.26006ZM6 13.3316V17.4427C6 17.5901 6.05853 17.7314 6.16272 17.8356C6.26691 17.9397 6.40821 17.9983 6.55556 17.9983H17.4444C17.5918 17.9983 17.7331 17.9397 17.8373 17.8356C17.9415 17.7314 18 17.5901 18 17.4427V13.3316H20V17.4427C20 18.1205 19.7308 18.7705 19.2515 19.2498C18.7722 19.729 18.1222 19.9983 17.4444 19.9983H6.55556C5.87778 19.9983 5.22776 19.729 4.7485 19.2498C4.26925 18.7705 4 18.1205 4 17.4427V13.3316H6Z"
        fill="currentColor"
      />
    </svg>
  )
}
