import clsx from 'clsx'
import {
  createElement,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
} from 'react'

import classes from './definition-list.module.scss'

export interface DefinitionListRowProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string
  columns?: number | string
  children?: ReactNode
}

export const DefinitionListRow: FC<DefinitionListRowProps> = ({
  columns,
  className,
  children,
  ...props
}) => {
  return createElement(
    'div',
    {
      className: clsx(classes.definitionListRow, className),
      ...props,
      style: {
        '--columns-amount': columns,
        ...props.style,
      },
    },
    children,
  )
}
