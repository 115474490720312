'use client'
import {
  Bar,
  BarChart as _BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { memo } from 'react'
import { BarChartProps } from '@/components/graphs/types'

export const BarChart = memo(
  ({
    data,
    margin,
    showLabel = true,
    barColor,
    formatter,
    labelColor = 'white',
    maxBarSize = 100,
    ...rest
  }: BarChartProps) => {
    return (
      <ResponsiveContainer>
        <_BarChart
          data={data}
          margin={{
            bottom: margin?.bottom,
            top: margin?.top,
            left: margin?.left,
            right: margin?.right,
          }}
          {...rest}
        >
          <XAxis hide={true} />
          <YAxis hide={true} />
          <Bar
            dataKey="value"
            stackId="main"
            fill={barColor}
            maxBarSize={maxBarSize}
          >
            {showLabel && (
              <LabelList
                className="p-8"
                dataKey="value"
                position="center"
                fill={labelColor}
                formatter={formatter}
              />
            )}
          </Bar>
        </_BarChart>
      </ResponsiveContainer>
    )
  },
)
