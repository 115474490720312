import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'
import clsx from 'clsx'

import classes from './table.module.scss'

export const TableRowGroup = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <div
      role="rowgroup"
      {...props}
      className={clsx(classes.rowGroup, props.className)}
      ref={ref}
    >
      {children}
    </div>
  )
})
