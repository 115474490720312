export const MONTHS_WITH_YEAR = [
  'common:MonthsWithYear.January',
  'common:MonthsWithYear.February',
  'common:MonthsWithYear.March',
  'common:MonthsWithYear.April',
  'common:MonthsWithYear.May',
  'common:MonthsWithYear.June',
  'common:MonthsWithYear.July',
  'common:MonthsWithYear.August',
  'common:MonthsWithYear.September',
  'common:MonthsWithYear.October',
  'common:MonthsWithYear.November',
  'common:MonthsWithYear.December',
]
