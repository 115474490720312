import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'
import { getTypography } from '../utils/css-helpers'
import classes from './typo.module.scss'
import { getTypoActions } from './actions'
import { TypoElement, TypoProps } from './types'

const { getTypoStyles } = getTypoActions()

export const Typo = forwardRef(
  <TElement extends TypoElement = 'div'>(
    {
      as,
      color = 'text-primary',
      variant,
      children,
      boldText = false,
      ...props
    }: TypoProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    const style = getTypoStyles(props.style, color, boldText)
    return createElement(
      as || 'div',
      {
        ...props,
        ref,
        className: clsx(
          classes.base,
          variant && getTypography(variant),
          props.className,
        ),
        style,
      },
      <>{children}</>,
    )
  },
)
