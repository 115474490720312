import { FC } from 'react'
import { IconProps } from './types'

export const EmailIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4939 6H4.50573L11.3354 12.0709C11.7143 12.4076 12.2853 12.4076 12.6642 12.0709L19.4939 6ZM3 7.33748V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V7.33715L13.9929 13.5657C12.8562 14.576 11.1434 14.576 10.0067 13.5657L3 7.33748ZM1 7C1 5.34315 2.34315 4 4 4H20C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17V7Z"
        fill="currentColor"
      />
    </svg>
  )
}
