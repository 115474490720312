export const BL = {
  2814: 'Roggenburg',
  4023: 'Basel',
  4040: 'Basel',
  4042: 'Basel PF OC',
  4101: 'Bruderholz',
  4102: 'Binningen',
  4103: 'Bottmingen',
  4104: 'Oberwil BL',
  4105: 'Biel-Benken BL',
  4106: 'Therwil',
  4107: 'Ettingen',
  4117: 'Burg im Leimental',
  4123: 'Allschwil',
  4124: 'Schönenbuch',
  4127: 'Birsfelden',
  4132: 'Muttenz',
  4133: 'Pratteln',
  4142: 'Münchenstein',
  4144: 'Arlesheim',
  4147: 'Aesch BL',
  4148: 'Pfeffingen',
  4153: 'Reinach BL',
  4202: 'Duggingen',
  4203: 'Grellingen',
  4207: 'Bretzwil',
  4222: 'Zwingen',
  4223: 'Blauen',
  4224: 'Nenzlingen',
  4225: 'Brislach',
  4242: 'Laufen',
  4243: 'Dittingen',
  4244: 'Röschenz',
  4246: 'Wahlen b. Laufen',
  4253: 'Liesberg',
  4254: 'Liesberg Dorf',
  4302: 'Augst BL',
  4304: 'Giebenach',
  4402: 'Frenkendorf',
  4410: 'Liestal',
  4411: 'Seltisberg',
  4414: 'Füllinsdorf',
  4415: 'Lausen',
  4416: 'Bubendorf',
  4417: 'Ziefen',
  4418: 'Reigoldswil',
  4419: 'Lupsingen',
  4422: 'Arisdorf',
  4423: 'Hersberg',
  4424: 'Arboldswil',
  4425: 'Titterten',
  4426: 'Lauwil',
  4431: 'Bennwil',
  4432: 'Lampenberg',
  4433: 'Ramlinsburg',
  4434: 'Hölstein',
  4435: 'Niederdorf',
  4436: 'Oberdorf BL',
  4437: 'Waldenburg',
  4438: 'Langenbruck',
  4441: 'Thürnen',
  4442: 'Diepflingen',
  4443: 'Wittinsburg',
  4444: 'Rümlingen',
  4445: 'Häfelfingen',
  4446: 'Buckten',
  4447: 'Känerkinden',
  4448: 'Läufelfingen',
  4450: 'Sissach',
  4451: 'Wintersingen',
  4452: 'Itingen',
  4453: 'Nusshof',
  4455: 'Zunzgen',
  4456: 'Tenniken',
  4457: 'Diegten',
  4458: 'Eptingen',
  4460: 'Gelterkinden',
  4461: 'Böckten',
  4462: 'Rickenbach BL',
  4463: 'Buus',
  4464: 'Maisprach',
  4465: 'Hemmiken',
  4466: 'Ormalingen',
  4467: 'Rothenfluh',
  4469: 'Anwil',
  4492: 'Tecknau',
  4493: 'Wenslingen',
  4494: 'Oltingen',
  4495: 'Zeglingen',
  4496: 'Kilchberg BL',
  4497: 'Rünenberg',
}
