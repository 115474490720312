import { ColumnType } from './types'

export const CssStyleByColumnTypeMap: Record<string, string> = {
  [ColumnType.CURRENCY]: 'max-w-32 text-left pl-2',
  [ColumnType.LONG_TEXT]: 'text-left lg:min-w-48 pr-4',
  [ColumnType.TEXT]: 'text-left',
  [ColumnType.PERCENTAGE]: 'max-w-32 text-left pl-2',
  [ColumnType.DATE]: 'text-left pr-4',
  [ColumnType.ACTION]: 'text-left',
  [ColumnType.NUMBER]: 'max-w-32 text-left pr-2',
}
