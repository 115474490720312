import { LoadingButton, PasswordField, Typo } from '@eplix/ui'
import { useTranslation } from 'react-i18next'
import classes from '../auth-forms.module.scss'
import { useAddNewPasswordForm } from '@/framework/auth'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { ErrorResponse, ResetPasswordRequest } from '@/api'
import { addNewPasswordRequest } from '@/framework/auth'
import { useApiErrorHandler } from '@/framework/error-handling/hooks/use-api-error-handler'
import { DisplayApiError } from '@/framework/error-handling/display-api-error'
import { debounce } from '@eplix/ui/utils/debounce'
import { PasswordPolicyChecklist } from '@/components/password-checklist/password-policy-checklist'
import { useValidatePasswordField } from '@/components/password-checklist/hooks/validate-password-field-hook'
import { useDataSubmit } from '@/common'
import { isEmpty } from 'lodash'

export const NewPasswordForm = () => {
  const { t } = useTranslation()
  const { submit, result, error, isLoading } = useDataSubmit({
    fetcher: addNewPasswordRequest,
  })
  const passwordRef = useRef<HTMLInputElement>(null)
  const [submitOccurred, setSubmitOccurred] = useState(false)

  useEffect(() => {
    if (result && isEmpty(error)) {
      window.location.replace('/auth/login')
    }
  }, [result, error])

  const form = useAddNewPasswordForm(
    {
      password: '',
    },
    submit,
  )
  const apiErrorMessage = useApiErrorHandler<
    Pick<ResetPasswordRequest, 'password'>
  >(error as ErrorResponse, form, 'USM-ForgotPassword:NewPasswordResponseError')

  const { password } = form.errors
  const isDirty = form.dirty

  const [tokenExpired, setTokenExpired] = useState(false)

  const tokenExpiredTimerRef = useRef(
    debounce(() => {
      setTokenExpired(true)
    }, 120000),
  )

  useEffect(() => {
    passwordRef.current?.focus()
    tokenExpiredTimerRef.current()
  }, [])

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    event.currentTarget.value = value.replace(/\s/g, '')
    form.handleChange(event)
  }

  const { helperPasswordText, showError, showHelperText } =
    useValidatePasswordField(submitOccurred, password)

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitOccurred(true)
    form.handleSubmit()
  }

  return (
    <>
      <form className={classes.form} onSubmit={formSubmitHandler} noValidate>
        <Typo variant="regular-300" color="text-secondary">
          {t(
            'USM-ForgotPassword:NewPassword.TextLead',
            'The new password can now be set.',
          )}
        </Typo>
        <PasswordField
          label={t(
            'USM-ForgotPassword:NewPassword.NewPasswordLabel',
            'New Password',
          )}
          name="password"
          disabled={isLoading}
          autoComplete="current-password"
          error={showError}
          value={form.values.password}
          helperText={showHelperText ? helperPasswordText : ''}
          onChange={handlePasswordChange}
          className={classes.newPasswordField}
        />
        <PasswordPolicyChecklist passwordErrors={password} isDirty={isDirty} />
        {(tokenExpired || apiErrorMessage) && (
          <DisplayApiError
            apiErrorMessage={apiErrorMessage || 'INVALID'}
            translationKey="USM-ForgotPassword:NewPasswordResponseError"
          />
        )}
        <LoadingButton
          loading={isLoading}
          type="submit"
          variant="outlined"
          disabled={tokenExpired}
          text="USM-ForgotPassword:NewPassword.ConfirmButton"
          name="newPassword_confirm"
        />
      </form>
    </>
  )
}
