import { DocumentTemplateEnum } from '@/common/enums/DocumentTemplate'
import { MyTellcoDocumentType } from '@/common/enums/MyTellcoDocumentType'

export const MyTellcoDocumentMap = {
  [MyTellcoDocumentType.TERMS_OF_USE]: 1,
  [MyTellcoDocumentType.GENERAL_TERMS_AND_CONDITIONS]: 2,
  [MyTellcoDocumentType.ARTICLES_OF_FOUNDATION]: 3,
  [MyTellcoDocumentType.PENSION_FUND_REGULATIONS]: 4,
  [MyTellcoDocumentType.INVESTMENT_REGULATIONS]: 5,
  [MyTellcoDocumentType.FEES_AND_CHARGES_POLICY]: 6,
  [MyTellcoDocumentType.CHANGE_OF_BENEFICARIES]: 7,
  [MyTellcoDocumentType.DE_FACTO_PARTNERSHIP_DECLARATION]: 8,
  [MyTellcoDocumentType.ORGANISATIONAL_REGULATIONS]: 9,
  [MyTellcoDocumentType.PAYOUT_WEF]: 10,
  [MyTellcoDocumentType.PAYOUT_PAYMENT_APPLICATION]: 11,
  [MyTellcoDocumentType.RISK_IN_TRADING]: 12,
  [MyTellcoDocumentType.PENSION_AGREEMENT]: 13,
}
export const OtherFormsDocumentMap = {
  [DocumentTemplateEnum.BESR]: 0,
  [DocumentTemplateEnum.PAYMENT_INSTRUCTIONS_LEFT]: 1,
  [DocumentTemplateEnum.PORTFOLIO_STATEMENT]: 2,
  [DocumentTemplateEnum.PAYMENT_INSTRUCTIONS_RIGHT]: 3,
}
