import { getCommonUserDataActions, useDataSubmit } from '@/common'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { asyncCall } from '@/common/helpers/async-wrapper'
import { Switch } from '@/components'
import { shouldShowAddressForm } from '@/features/user-personal-data/actions'
import { useShouldShowPersonalDataPage } from '@/features/user-personal-data/hooks/user-personal-data-hooks'
import {
  submitAcceptedLegalDocuments,
  useAcceptLegalDocumentsForm,
  useResetCurrentUser,
} from '@/framework/auth'
import {
  Button,
  FormFieldHelperText,
  Hint,
  LoadingButton,
  Translate,
} from '@eplix/ui'
import { useRouter } from 'next/router'
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Documents } from './documents'
import { LegalDocumentsProps } from './types'

const { getIsSwissNationalityUser, getIsCrossBorderOrSwissUser } =
  getCommonUserDataActions()

export const LegalDocuments = ({
  setEnabled,
  personalData,
  userStatusState,
  personalDocuments,
  documentsToAccept,
  setUserStatusState,
  setIsLoginInProgress,
}: LegalDocumentsProps) => {
  const router = useRouter()
  const resetCurrentUser = useResetCurrentUser()

  const [enableValidation, setEnableValidation] = useState(false)

  useEffect(() => {
    setIsLoginInProgress(false)
  }, [setIsLoginInProgress])

  const backToLogin = () => {
    resetCurrentUser().then(() => window.location.reload())
  }

  useEffect(() => {
    setEnabled(true)
  }, [router.locale, setEnabled])

  const { result, submit, isLoading } = useDataSubmit({
    fetcher: submitAcceptedLegalDocuments,
  })

  const form = useAcceptLegalDocumentsForm(
    {
      accepted: false,
    },
    { enableValidation },
    () => submit({ documents: documentsToAccept }),
  )

  const showAddressPage = shouldShowAddressForm(personalData)
  const shouldShowPersonalData = useShouldShowPersonalDataPage(personalData)

  const isSwissNationalityUser = getIsSwissNationalityUser(
    personalData?.profile?.nationality,
  )

  const isCrossBorderOrSwissUser = getIsCrossBorderOrSwissUser(
    personalData?.profile?.isCrossBorder,
    personalData?.profile?.country,
  )

  const shouldShowAddressFormForPermit =
    !isSwissNationalityUser &&
    isCrossBorderOrSwissUser &&
    !personalData?.profile?.documentCategory

  useEffect(() => {
    if (result?.success) {
      if (shouldShowPersonalData) {
        setUserStatusState(UserSessionStatusEnum.OFFLINE)
      } else if (showAddressPage) {
        setUserStatusState(UserSessionStatusEnum.WITHOUT_ADDRESS)
      } else if (shouldShowAddressFormForPermit) {
        setUserStatusState(UserSessionStatusEnum.WITHOUT_PERMIT)
      }
    }
  }, [
    result?.success,
    setUserStatusState,
    shouldShowAddressFormForPermit,
    shouldShowPersonalData,
    showAddressPage,
  ])

  const replaceToDashboard = useCallback(async () => {
    await router.replace('/dashboard')
  }, [router])

  useEffect(() => {
    if (result?.success) {
      if (
        !showAddressPage &&
        !shouldShowPersonalData &&
        !shouldShowAddressFormForPermit
      ) {
        asyncCall(replaceToDashboard)
      }
    }
  }, [
    replaceToDashboard,
    result?.success,
    shouldShowAddressFormForPermit,
    shouldShowPersonalData,
    showAddressPage,
    userStatusState,
  ])

  const { handleChange, handleSubmit, errors } = form

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setEnableValidation(true)
    handleSubmit()
  }

  const hasMyTellcoDocument = useMemo(() => {
    return !!documentsToAccept?.mytellcoDocumentTypes?.length
  }, [documentsToAccept?.mytellcoDocumentTypes?.length])

  const hasEplixDocuments = useMemo(() => {
    return !!documentsToAccept?.eplixDocumentTypes?.length
  }, [documentsToAccept?.eplixDocumentTypes?.length])

  return (
    <>
      <div>
        <form onSubmit={formSubmitHandler}>
          <Translate variant="regular-300" label="USM-Login:Documents.Title" />

          <div className="flex flex-row w-full gap-3 mt-8">
            {hasEplixDocuments && !hasMyTellcoDocument && (
              <div className="flex my-auto">
                <Switch
                  name="accepted"
                  onChange={handleChange}
                  checked={form.values.accepted}
                />
              </div>
            )}
            <Documents
              documentsToAccept={documentsToAccept}
              hasMyTellcoDocument={hasMyTellcoDocument}
              personalDocumentsInfo={personalDocuments}
            />
          </div>
          {hasMyTellcoDocument && (
            <div className="flex flex-row mt-4">
              <div className="flex my-auto">
                <Switch
                  name="accepted"
                  onChange={handleChange}
                  checked={form.values.accepted}
                />
              </div>
              <Translate
                className="ml-3"
                variant="regular-300"
                label="USM-Login:Documents.AcceptDocuments"
              />
            </div>
          )}

          {errors.accepted && (
            <FormFieldHelperText
              className="text-red"
              helperText="common:Field.MustBeChecked"
            />
          )}

          <div className="mt-8">
            <Hint
              activeMessage={
                <div className="border-x border-y px-5 py-3 mb-5 mt-2 border-outline rounded-lg">
                  <Translate
                    className="mb-1"
                    label="USM-Login:Documents.MoreInformationFirst"
                  />
                </div>
              }
              orientation="text-left"
            />
          </div>

          <div className="flex flex-row justify-between">
            <Button
              type="button"
              variant="outlined"
              name="legal_documents_back"
              onClick={backToLogin}
              className="my-4 lg:w-fit"
              text="USM-Login:Documents.BackButton"
            />
            <LoadingButton
              type="submit"
              variant="elevated"
              name="legal_documents_next"
              loading={isLoading}
              className="mt-4 lg:w-fit"
              disabled={
                !form.values.accepted ||
                userStatusState !==
                  UserSessionStatusEnum.AUTHENTICATED_WITHOUT_ACCEPTED_DOCUMENTS
              }
              text="USM-Login:Documents.NextButton"
            />
          </div>
        </form>
      </div>
    </>
  )
}
