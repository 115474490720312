import { Translate } from '@eplix/ui'
import { Divider } from '@eframe-ui/react'
import { HTMLAttributes } from 'react'
import { DropdownOptionProps } from './types'

interface FilterDropdownOptionProps {
  props: HTMLAttributes<HTMLLIElement>
  option: DropdownOptionProps
  dropdownDataLayout: (option: unknown) => JSX.Element | undefined
}

export const FilterDropdownOption = ({
  props,
  option,
  dropdownDataLayout,
}: FilterDropdownOptionProps) => (
  <li {...props} key={option.label} className="w-full cursor-pointer">
    {dropdownDataLayout
      ? dropdownDataLayout(option)
      : option.label && (
          <>
            <Translate label={option.label} />
            <Divider className="!my-2" />
          </>
        )}
  </li>
)
