/**
 * One Pattern to rule them all, One Pattern to find them, One Pattern to bring them all and in the darkness bind them.
 */
//IRA: added "new RegExp" as there was "Syntax Error: classAtom at position..."
const PATTERN_EXP = new RegExp(
  '\\/(:(?<placeholder>[\\w]+)|(?<partial>[\\w\\-]+))?',
  'gs',
)

interface MatchingGroup {
  placeholder?: string
  partial?: string
}

/**
 * The `RouteExp` (short for Route Expression) class inherits from the native `RegExp` prototype.
 * It lets you create regular expression objects that can be used to match, test against and resolve URL-formatted path strings.
 */
export class RouteExp<
  TParams extends Record<string, unknown> = Record<string, unknown>,
> extends RegExp {
  /**
   * The pattern provided to the constructor.
   */
  readonly pattern: string

  /**
   * Takes a pattern and an exact flag and returns a new `RouteExp`.
   *
   * @param pattern
   * * Should always start with a forward slash e.g. `/settings`.
   * * Annotate parts of a pattern as dynamic by giving a placeholder prefixed with a colon: `/settings/:settingsSection`.
   * @param exact
   * * Whether the pattern should only match exact URL's.
   * * Default: `false`.
   * * Example: The pattern `/settings/:settingsSection` would not match the URL `/settings/notifications/newsletter` with a truthy exact flag,
   * whereas the same pattern in lazy mode (falsy flag) will match subpaths just fine.
   */
  constructor(pattern: string, exact = false) {
    // The initial iteration pointer.
    let match: RegExpExecArray | null
    // The stack for future matches
    const parts: string[] = []
    // In a loop, check for pointer assignment
    while ((match = PATTERN_EXP.exec(pattern))) {
      // Here, we deconstruct possible group matches from the current exec result.
      // The variable names are static (see PATTERN_EXP at first line of file).
      // Also, we can safely destruct here, b/c match.groups defaults to an empty object.
      const { placeholder, partial } = match.groups as MatchingGroup
      // Append to parts:
      // If a placeholder was found, push an expression with a named alphanumeric capturing group
      // else push a static string.
      parts.push(
        !placeholder ? (partial as string) : `(?<${placeholder}>[\\w\\-]+)`,
      )
    }
    // Assemble the final RegExp:
    // - Line starts with mandatory forward slash
    // - Concat that with forward-slash-joined iteration results.
    // - If `exact` flag is truthy, add end-of-line modifier
    const routeExp = '^\\/'.concat(parts.join(`\\/`), exact ? '$' : '')

    // Feed our superclass with the final expression
    super(routeExp)
    // Assign the pattern property. We do this after `super` to be able to assign to `this`.
    this.pattern = pattern
  }

  /**
   *
   * @param url An actual URL or subpath.
   * @returns
   * * `null` if the pattern didn't match the given url.
   * * An object of shape `{ [placeholder]: <value from url> }` when the URL matched a dynamic pattern.
   * * An empty object if the pattern is static and did match.
   */
  match(url: string): TParams | null {
    const result = this.exec(url)
    return result && ({ ...result.groups } as unknown as TParams)
  }

  /**
   * @param params An object of shape `{ [placeholder]: <value> }`. To successfully resolve a `RouteExp` pattern,
   * you have to pass a key/value pair for every placeholder in your pattern.
   * @returns The resolved URL
   */
  resolve(params?: TParams) {
    let resolvedPath = this.pattern
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        resolvedPath = resolvedPath.replaceAll(`:${key}`, `${value}`)
      }
    }
    if (resolvedPath.indexOf(':') >= 0) {
      throw new Error(
        `RouteExp: Unresolved placeholder found in resolved path: "${resolvedPath}".`,
      )
    }
    return resolvedPath
  }
}
