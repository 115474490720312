import { useEffect, useState } from 'react'
import { debounce } from './debounce'
type WindowsProps = {
  width: number
  height: number
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowsProps>({
    width: 0,
    height: 0,
  } as WindowsProps)

  const debouncedResize = () =>
    debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, 100)()

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })

    // Add event listener with debounce
    window.addEventListener('resize', debouncedResize)
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedResize)
  }, [])

  return [windowSize.width, windowSize.height]
}
