import { PortfolioTypeEnum } from '@/common/enums/PortfolioType'
import { useMatchSmallAndMediumScreen } from '@/common/hooks/use-match-screen-size'
import { AccordionHeaderValues, AllocationChart } from '@/components'
import { ErrorIcon, WarningIcon } from '@eframe-ui/react'
import { Tooltip, Translate } from '@eplix/ui'
import clsx from 'clsx'
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionHeaderProps } from '../types'
import classes from './accordion-header.module.scss'

export const AccordionHeader = memo(
  ({
    show_below_asset_price_message,
    showMinimumAmountMessage,
    show_product_not_available_message,
    minimumPurchaseAmount,
    allocationChartProps,
    accordionHeaderValuesProps,
    accordionTextProps,
    hasError = false,
    variant = 'regular',
    type,
    children,
    shouldBreakContent,
    className,
    isPortfolioOverviewAccount,
    accordionOpeningCallback,
  }: AccordionHeaderProps) => {
    const { t } = useTranslation()
    const { title, secondTitle, titleStyle, subtitle, subtitleStyle } =
      accordionTextProps
    const portfolioType = type === PortfolioTypeEnum.PILLAR_3A ? '3a' : 'FZS'
    const showTooltip =
      showMinimumAmountMessage ||
      show_below_asset_price_message ||
      show_product_not_available_message

    const tooltipText = useMemo(() => {
      let text = ''
      if (show_product_not_available_message) {
        text = t('USM-Portfolio:AdaptStrategy.ProductNotAvailable')
      } else if (showMinimumAmountMessage) {
        text = t(
          'USM-Portfolio:AdaptStrategy.TooltipText',
          `The amount paid in does not correspond to the minimum investment amount of CHF ${minimumPurchaseAmount} or an equivalent amount.  The amount is invested as soon as the minimum investment amount is reached / paid in.`,
          { minimumPurchaseAmount },
        )
      } else if (show_below_asset_price_message) {
        text = t(`USM-Portfolio:AdaptStrategy.TooltipText${portfolioType}`)
      }
      return text
    }, [
      minimumPurchaseAmount,
      portfolioType,
      show_below_asset_price_message,
      showMinimumAmountMessage,
      show_product_not_available_message,
      t,
    ])

    const matchesSmallAndMediumScreen = useMatchSmallAndMediumScreen()
    return (
      <div
        className={clsx(
          classes.accordionHeaderContainer,
          shouldBreakContent && '!flex-col w-full gap-4 md:!flex-row md:gap-0',
          className,
        )}
      >
        <div
          className={allocationChartProps && classes.left}
          onClick={accordionOpeningCallback}
        >
          <AllocationChart
            size={allocationChartProps?.size}
            outerRadius={allocationChartProps?.outerRadius}
            innerRadius={allocationChartProps?.innerRadius}
            data={allocationChartProps?.data}
            dataKey={allocationChartProps?.dataKey}
            value={allocationChartProps?.value}
            valueStyle={
              allocationChartProps?.valueStyle ||
              'text-text-secondary typo-regular-100'
            }
            isAnimationActive={allocationChartProps?.isAnimationActive}
            variant={variant}
            shouldPulse={allocationChartProps?.shouldPulse}
          />
          <span className={classes.text}>
            <Translate
              color="black"
              label={title}
              variant="medium-200"
              className={clsx(
                variant === 'outlined' && 'text-text-secondary',
                titleStyle,
              )}
            />

            <Translate
              label={secondTitle}
              variant="medium-200"
              className={clsx(
                variant === 'outlined' && 'text-text-secondary',
                accordionTextProps.secondTitleStyle,
              )}
            />

            {hasError &&
              (!matchesSmallAndMediumScreen || isPortfolioOverviewAccount) && (
                <p className="flex text-red gap-2 typo-regular-100">
                  <WarningIcon color="red" />
                  {subtitle}
                </p>
              )}
            {!hasError &&
              (!matchesSmallAndMediumScreen || isPortfolioOverviewAccount) && (
                <p
                  className={clsx(
                    'text-text-secondary typo-regular-100',
                    subtitleStyle,
                    shouldBreakContent && 'mt-1',
                  )}
                >
                  {subtitle}
                </p>
              )}
          </span>
        </div>
        {(accordionHeaderValuesProps || children) && (
          <div
            className={clsx(
              classes.right,
              shouldBreakContent && 'w-full md:w-auto',
              isPortfolioOverviewAccount && '!justify-end',
            )}
          >
            {!hasError &&
              matchesSmallAndMediumScreen &&
              !isPortfolioOverviewAccount && (
                <p
                  className={clsx(
                    'text-text-secondary typo-regular-100',
                    subtitleStyle,
                    shouldBreakContent && 'mt-1',
                  )}
                >
                  {subtitle}
                </p>
              )}
            {children}
            {showTooltip && (
              <Tooltip content={tooltipText} placement={'bottom'}>
                <ErrorIcon
                  className={
                    'text-[#3A85F8] min-w-6 min-h-6 self-center mr-[9px] lg:mr-[26px]'
                  }
                />
              </Tooltip>
            )}
            {accordionHeaderValuesProps && (
              <AccordionHeaderValues
                {...accordionHeaderValuesProps}
                shouldBreakContent={shouldBreakContent}
              />
            )}
          </div>
        )}
      </div>
    )
  },
)
