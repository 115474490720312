import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import clsx from 'clsx'
import classes from './dialog.module.scss'

export type DialogFooterProps = HTMLAttributes<HTMLDivElement>

export const DialogFooter = forwardRef(
  (
    { className, children, ...props }: DialogFooterProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div ref={ref} className={clsx(classes.footer, className)} {...props}>
        {children}
      </div>
    )
  },
)
