import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react'
import classes from './definition-list.module.scss'

export interface DefinitionListHelperTextProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string
  children?: ReactNode
}

export const DefinitionListHelperText = forwardRef<
  HTMLElement,
  DefinitionListHelperTextProps
>(({ className, children, ...props }, ref) => {
  return (
    <dd
      className={clsx(classes.definitionListHelperText, className)}
      {...props}
      style={{ ...props.style }}
      ref={ref}
    >
      {children}
    </dd>
  )
})
