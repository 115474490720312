export const SO = {
  2540: 'Grenchen',
  2544: 'Bettlach',
  2545: 'Selzach',
  3253: 'Schnottwil',
  3254: 'Messen',
  3307: 'Brunnenthal',
  4108: 'Witterswil',
  4112: 'Bättwil-Flüh',
  4114: 'Hofstetten SO',
  4115: 'Mariastein',
  4116: 'Metzerlen',
  4118: 'Rodersdorf',
  4143: 'Dornach',
  4145: 'Gempen',
  4146: 'Hochwald',
  4204: 'Himmelried',
  4206: 'Seewen SO',
  4208: 'Nunningen',
  4226: 'Breitenbach',
  4227: 'Büsserach',
  4228: 'Erschwil',
  4229: 'Beinwil SO',
  4232: 'Fehren',
  4233: 'Meltingen',
  4234: 'Zullwil',
  4245: 'Kleinlützel',
  4247: 'Grindel',
  4252: 'Bärschwil',
  4412: 'Nuglar',
  4413: 'Büren SO',
  4421: 'St. Pantaleon',
  4468: 'Kienberg',
  4500: 'Solothurn',
  4501: 'Solothurn',
  4502: 'Solothurn',
  4503: 'Solothurn',
  4509: 'Solothurn',
  4512: 'Bellach',
  4513: 'Langendorf',
  4514: 'Lommiswil',
  4515: 'Weissenstein',
  4522: 'Rüttenen',
  4523: 'Niederwil SO',
  4524: 'Günsberg',
  4525: 'Balm b. Günsberg',
  4528: 'Zuchwil',
  4532: 'Feldbrunnen',
  4533: 'Riedholz',
  4534: 'Flumenthal',
  4535: 'Hubersdorf',
  4542: 'Luterbach',
  4543: 'Deitingen',
  4551: 'Derendingen',
  4552: 'Derendingen',
  4553: 'Subingen',
  4554: 'Etziken',
  4556: 'Aeschi SO',
  4557: 'Horriwil',
  4558: 'Hersiwil',
  4562: 'Biberist',
  4563: 'Gerlafingen',
  4564: 'Obergerlafingen',
  4565: 'Recherswil',
  4566: 'Kriegstetten',
  4571: 'Lüterkofen-Ichert',
  4573: 'Lohn-Ammannsegg',
  4574: 'Nennigkofen',
  4576: 'Tscheppach',
  4577: 'Hessigkofen',
  4578: 'Bibern SO',
  4579: 'Gossliwil',
  4581: 'Küttigkofen',
  4582: 'Brügglen',
  4583: 'Mühledorf SO',
  4584: 'Gächliwil',
  4585: 'Biezwil',
  4586: 'Kyburg-Buchegg',
  4587: 'Aetingen',
  4588: 'Unterramsern',
  4600: 'Olten',
  4601: 'Olten',
  4603: 'Olten',
  4609: 'Olten',
  4611: 'Härkingen SC',
  4612: 'Wangen b. Olten',
  4613: 'Rickenbach SO',
  4614: 'Hägendorf',
  4615: 'Allerheiligenberg',
  4616: 'Kappel SO',
  4617: 'Gunzgen',
  4618: 'Boningen',
  4620: 'Härkingen PZ',
  4621: 'Härkingen BZ',
  4622: 'Egerkingen',
  4623: 'Neuendorf',
  4624: 'Härkingen',
  4625: 'Oberbuchsiten',
  4626: 'Niederbuchsiten',
  4628: 'Wolfwil',
  4629: 'Fulenbach',
  4630: 'Härkingen CALL',
  4632: 'Trimbach',
  4633: 'Hauenstein',
  4634: 'Wisen SO',
  4640: 'Härkingen ST PP 1',
  4652: 'Winznau',
  4653: 'Obergösgen',
  4654: 'Lostorf',
  4655: 'Stüsslingen',
  4656: 'Starrkirch-Wil',
  4657: 'Dulliken',
  4658: 'Däniken SO',
  4702: 'Oensingen',
  4703: 'Kestenholz',
  4710: 'Balsthal',
  4712: 'Laupersdorf',
  4713: 'Matzendorf',
  4714: 'Aedermannsdorf',
  4715: 'Herbetswil',
  4716: 'Welschenrohr',
  4717: 'Mümliswil',
  4718: 'Holderbank SO',
  4719: 'Ramiswil',
  5010: 'Däniken PZ',
  5012: 'Schönenwerd',
  5013: 'Niedergösgen',
  5014: 'Gretzenbach',
  5015: 'Erlinsbach SO',
  5746: 'Walterswil SO',
}
