import {
  Button,
  FormFieldHelperText,
  LoadingButton,
  OptionModel,
  Translate,
} from '@/../ui'
import { ProfileFormRequest } from '@/api'
import { Constants, DisplayDateFormat, RequestDateFormat } from '@/common'
import { getCommonUserDataActions } from '@/common/actions'
import { CivilStatusEnum, WorkingStatusEnum } from '@/common/enums'
import { UtilsEnum } from '@/common/enums/UtilsEnum'
import { asyncCall } from '@/common/helpers/async-wrapper'
import { useDataSubmit, useSortCountriesAlphabetically } from '@/common/hooks'
import { AddressForm } from '@/components/address'
import { DateField } from '@/components/date-field'
import { Dropdown } from '@/components/dropdown'
import { Switch } from '@/components/switch'
import { useResetCurrentUser } from '@/framework/auth'
import { useEnumMapper } from '@/framework/common/hooks/use-enum-mapper'
import { currentDate, parseRequestDate } from '@/framework/date-time'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import {
  FormEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getPersonalInformationActions } from '../portfolio/components/forms/personal-information/actions'
import { useEditInformationForm } from '../user-account/hooks/form/use-edit-information-form'
import { updateInformation } from '../user-account/lib/user-account-client'
import { getUserPersonalDataActions } from './actions'
import { UserPersonalDataProps } from './types'

const {
  getHasAddress,
  getIsSwissNationalityUser,
  getIsCrossBorderOrSwissUser,
  getIsAlphanumericZipCountry,
} = getCommonUserDataActions()

export const UserPersonalData = memo(
  ({ personalData, setIsLoginInProgress }: UserPersonalDataProps) => {
    const router = useRouter()
    const resetCurrentUser = useResetCurrentUser()
    const { isMarried } = getCommonUserDataActions()
    const hasAddress = getHasAddress(personalData.profile)
    const { checkIfStatusExists } = getPersonalInformationActions()
    const { getInitialValues } = getUserPersonalDataActions()

    const initialValues = getInitialValues(personalData?.profile)
    const marriageDateDefaultLabel = 'common:MarriageDate'
    const placeholder = 'common:Placeholder'

    const { sortedCountries } = useSortCountriesAlphabetically()

    const [payload, setPayload] =
      useState<Omit<ProfileFormRequest, 'isMyTellcoCustomer'>>()
    const [enableValidation, setEnableValidation] = useState(false)
    const [
      enableAlphanumericZipValidation,
      setEnableAlphanumericZipValidation,
    ] = useState(false)

    const [marriageDateLabel, setMarriageDateLabel] = useState(
      marriageDateDefaultLabel,
    )

    const [enableMarriageDateValidation, setEnableMarriageDateValidation] =
      useState(isMarried(personalData?.profile?.maritalStatus))

    const [
      enableDocumentCategoryValidation,
      setEnableDocumentCategoryValidation,
    ] = useState(false)

    useEffect(() => {
      setIsLoginInProgress(false)
    }, [setIsLoginInProgress])

    const updateMarriageDateLabel = useCallback(
      (maritalStatus: string) => {
        if (maritalStatus === CivilStatusEnum.REGISTERED_PARTNERSHIP)
          setMarriageDateLabel('common:ValidFrom')
        else if (
          maritalStatus !== CivilStatusEnum.REGISTERED_PARTNERSHIP &&
          marriageDateLabel !== marriageDateDefaultLabel
        ) {
          setMarriageDateLabel(marriageDateDefaultLabel)
        }
      },
      [marriageDateDefaultLabel, marriageDateLabel],
    )

    const [enableWorkingStatusValidation] = useState(
      personalData.has3aPortfolio && !personalData.profile?.employerStatus,
    )

    const { result, submit, isLoading } = useDataSubmit({
      fetcher: updateInformation,
    })

    const onSubmit = async () => {
      await submit(payload as ProfileFormRequest)
    }

    const form = useEditInformationForm(initialValues, onSubmit, {
      enableValidation,
      enableMarriageDateValidation,
      enableWorkingStatusValidation,
      enableAlphanumericZipValidation,
      enableDocumentCategoryValidation,
    })

    const { handleSubmit, handleChange, errors, values, setFieldError } = form
    const replaceToDashboard = useCallback(async () => {
      if (result && isEmpty(errors)) {
        await router.replace('/dashboard')
      }
    }, [result, router, errors])

    useEffect(() => {
      updateMarriageDateLabel(values.maritalStatus as string)
      setEnableMarriageDateValidation(
        values.maritalStatus === CivilStatusEnum.REGISTERED_PARTNERSHIP ||
          values.maritalStatus === CivilStatusEnum.MARRIED,
      )

      setHideContributionTo2ndPillar(
        values.employerStatus === WorkingStatusEnum.RETIRED,
      )

      if (!enableMarriageDateValidation) {
        setFieldError('marriageDate', undefined)
      }
    }, [
      setFieldError,
      enableMarriageDateValidation,
      updateMarriageDateLabel,
      values.maritalStatus,
      values.employerStatus,
    ])

    useEffect(() => {
      asyncCall(replaceToDashboard)
    }, [replaceToDashboard])

    const [hideContributionTo2ndPillar, setHideContributionTo2ndPillar] =
      useState(false)

    const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      setEnableValidation(true)
      const { isUsCitizen, employerStatus, ...newValues } = values
      setPayload({
        ...newValues,
        employerStatus: personalData?.has3aPortfolio
          ? employerStatus
          : undefined,
        isUsCitizen: !isUsCitizen,
        marriageDate: values.marriageDate
          ? parseRequestDate(values.marriageDate, RequestDateFormat.YYYY_MM_DD)
          : undefined,
        gender: personalData.profile?.gender ?? Constants.EmptyString,
        alias: personalData.profile?.alias ?? personalData.profile?.firstName,
        street: values.street ?? Constants.EmptyString,
        streetNumber: values.streetNumber ?? Constants.EmptyString,
      })
      handleSubmit()
    }

    const workingStatus = useEnumMapper(
      WorkingStatusEnum,
      'WorkingStatusEnum',
      'common',
    )

    const civilStatus = useEnumMapper(
      CivilStatusEnum,
      'CivilStatusEnum',
      'common',
    )

    const nationalityLabel = !!values.nationality
      ? `country:${form.values.nationality}`
      : placeholder

    const handleChangeStatus = async (option: OptionModel) => {
      await form.setFieldValue('employerStatus', option.value)
      personalData.has3aPortfolio &&
        (option.value === WorkingStatusEnum.EMPLOYED ||
        option.value === WorkingStatusEnum.UNEMPLOYED
          ? await form.setFieldValue('contributionTo2ndPillar', true)
          : await form.setFieldValue('contributionTo2ndPillar', false))

      if (option.value === WorkingStatusEnum.RETIRED) {
        await form.setFieldValue('contributionTo2ndPillar', undefined)
      }
    }

    const handleDateChange = (date: Date, field: string) => {
      form.setFieldValue(
        field,
        parseRequestDate(date, DisplayDateFormat.YYYY_MM_DD),
      )
    }

    const memoizedOptionsWorkingStatus = useMemo(
      () =>
        workingStatus.map(({ value, translation }) => ({
          value,
          label: translation,
        })),
      [workingStatus],
    )

    const memoizedOptionsMaritalStatus = useMemo(
      () =>
        civilStatus.map(({ value, translation }) => ({
          value,
          label: translation,
        })),
      [civilStatus],
    )

    const memoizedOptionsCountry = useMemo(
      () =>
        sortedCountries.map(({ value, translation }) => ({
          value,
          label: translation,
        })),
      [sortedCountries],
    )

    useEffect(() => {
      const isAlphanumericZipCountry = getIsAlphanumericZipCountry(
        values.country,
      )
      setEnableAlphanumericZipValidation(isAlphanumericZipCountry)
    }, [values.country])

    const isSwissNationalityUser = getIsSwissNationalityUser(
      personalData?.profile?.nationality,
    )

    const isCrossBorderOrSwissUser = getIsCrossBorderOrSwissUser(
      values.isCrossBorder,
      values.country,
    )

    const shouldCheckPermit =
      !isSwissNationalityUser &&
      isCrossBorderOrSwissUser &&
      !personalData?.profile?.documentCategory

    useEffect(() => {
      if (shouldCheckPermit) {
        setEnableDocumentCategoryValidation(true)
      }
    }, [shouldCheckPermit])

    const backToLogin = () => {
      resetCurrentUser().then(() => window.location.reload())
    }

    return (
      <>
        <form
          className="flex flex-col w-full h-full"
          onSubmit={formSubmitHandler}
          noValidate
        >
          {(!hasAddress || shouldCheckPermit) && personalData && (
            <AddressForm
              form={form}
              isLoginFlow
              information={personalData?.profile}
            />
          )}

          {personalData.has3aPortfolio &&
            (!personalData.profile?.employerStatus ||
              personalData?.profile?.employerStatus === UtilsEnum.UNKNOWN) && (
              <Dropdown
                label="common:Employment"
                name="employerStatus"
                fieldClassName="mt-6"
                value={String(values.employerStatus)}
                onChange={handleChangeStatus}
                error={!!errors.employerStatus}
                helperText={errors.employerStatus}
                buttonLabel={
                  checkIfStatusExists(values.employerStatus)
                    ? `common:WorkingStatusEnum.${values.employerStatus}`
                    : `common:Placeholder`
                }
                optionsProps={{ className: 'h-fit' }}
                options={memoizedOptionsWorkingStatus}
              />
            )}

          {!checkIfStatusExists(personalData?.profile?.maritalStatus) && (
            <Dropdown
              name="maritalStatus"
              fieldClassName="mt-4"
              label="common:MaritalStatus"
              value={values.maritalStatus ?? Constants.EmptyString}
              onChange={(option: OptionModel) => {
                form.setFieldValue('maritalStatus', option.value)
              }}
              error={!!errors.maritalStatus}
              helperText={errors.maritalStatus}
              buttonLabel={
                checkIfStatusExists(values.maritalStatus)
                  ? `common:CivilStatusEnum.${values.maritalStatus}`
                  : `common:Placeholder`
              }
              optionsProps={{ className: 'h-fit' }}
              options={memoizedOptionsMaritalStatus}
            />
          )}

          {enableMarriageDateValidation &&
            !personalData?.profile?.marriageDate && (
              <DateField
                className="mt-8"
                name="marriageDate"
                label={marriageDateLabel}
                value={String(values.marriageDate)}
                onChange={(val: Date) => handleDateChange(val, 'marriageDate')}
                maxDate={currentDate.toDate()}
                error={!!errors.marriageDate}
                helperText={errors.marriageDate}
              />
            )}

          {!personalData?.profile?.nationality && (
            <Dropdown
              disabled={isLoading}
              label="common:Nationality"
              name="nationality"
              value={String(values.nationality)}
              onChange={(option: OptionModel) =>
                form.setFieldValue('nationality', option.value)
              }
              error={!!form.errors.nationality}
              className="mt-6"
              helperText={form.errors.nationality}
              buttonLabel={nationalityLabel}
              options={memoizedOptionsCountry}
            />
          )}

          <div className="flex flex-col mt-2">
            {personalData?.profile?.isUsCitizen === null && (
              <div className="mt-6">
                <div className="flex flex-row gap-4">
                  <Switch
                    name="isUsCitizen"
                    checked={values.isUsCitizen}
                    onChange={handleChange}
                  />
                  <Translate className="pt-1" label="common:IsUsPerson" />
                </div>

                {errors.isUsCitizen && (
                  <FormFieldHelperText
                    className="text-red"
                    helperText="common:Field.MustBeChecked"
                  />
                )}
              </div>
            )}

            <div hidden={hideContributionTo2ndPillar}>
              {personalData.has3aPortfolio &&
                personalData?.profile?.contributionTo2ndPillar === null && (
                  <div className="mt-6">
                    <div className="flex flex-row gap-4">
                      <Switch
                        name="contributionTo2ndPillar"
                        checked={values.contributionTo2ndPillar}
                        onChange={handleChange}
                      />
                      <Translate
                        className="pt-1"
                        label="common:IsSecondPillar"
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>

          <div className="flex flex-row justify-between mt-5">
            <Button
              type="button"
              variant="outlined"
              name="personal_data_back"
              onClick={backToLogin}
              className="my-4 lg:w-fit"
              text="USM-Login:Documents.BackButton"
            />
            <LoadingButton
              type="submit"
              variant="elevated"
              name="personal_data_submit"
              className="my-4 lg:w-fit"
              text="USM-Login:Documents.NextButton"
              loading={isLoading}
            />
          </div>
        </form>
      </>
    )
  },
)
