import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'

import { Loader } from '../loader'
import loaderClasses from '../loader/loader.module.scss'
import { TranslateSimple } from '../translate'
import classes from './button.module.scss'
import { ButtonElement, ButtonProps, ButtonVariant } from './types'

const getVariantClass = (variant?: ButtonVariant) => {
  switch (variant) {
    case 'outlined':
      return classes.outlined
    case 'text':
      return classes.text
    default:
      return classes.elevated
  }
}

const loadingSpinner = <Loader className={loaderClasses.loaderSmall} />

export const LoadingIconTextButton = forwardRef(
  <TElement extends ButtonElement = 'button'>(
    {
      as,
      variant = 'text',
      name,
      startIcon,
      endIcon,
      className,
      text,
      loading = false,
      loadingIcon,
      ...props
    }: ButtonProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'button',
      {
        ...props,
        ref,
        'data-cy': `loading_btn_${name}`,
        className: clsx(
          classes.buttonBase,
          getVariantClass(variant),
          startIcon
            ? classes.withStartIcon
            : endIcon
            ? classes.withEndIcon
            : 'px-8',
          className,
        ),
      },
      <>
        {startIcon}
        <TranslateSimple label={text} />
        {loading ? loadingIcon ?? loadingSpinner : endIcon}
      </>,
    )
  },
)
