/* eslint-disable @typescript-eslint/no-explicit-any */
import { Constants } from '@/common'
import { useTranslate } from '@eplix/ui/translate/hooks/use-translate'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  createFilterOptions,
} from '@mui/material'
import clsx from 'clsx'
import {
  HTMLAttributes,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { FilterDropdownInput } from './filter-input'
import { FilterDropdownOption } from './filter-option'
import { DropdownOptionProps, FilterDropdownProps } from './types'
import { debounce } from '@eplix/ui/utils/debounce'
import { CustomPaper } from '../custom-paper/custom-paper'

export const FilterDropdown = memo(
  ({
    data,
    name,
    errors,
    setValue,
    className,
    placeholder,
    onInputChange,
    selectedValue,
    setFieldValue,
    setSearchResult,
    selectFieldLabel,
    dropdownDataLayout,
    formFieldClassName,
    setHasFilterResults,
    showOtherOption = true,
    showValidationError,
    isLoading,
  }: FilterDropdownProps) => {
    const [autocompleteValue, setAutocompleteValue] = useState('')
    const [inputValue, setInputValue] = useState('')

    const dropdownOther = useTranslate({
      label: 'common:Other',
      placeholder: 'Other',
    })

    const handleInputChange = (
      event: SyntheticEvent<Element, Event>,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'input') {
        onInputChange && onInputChange(value)
        setInputValue(value)
        setAutocompleteValue(value)
      }
    }

    const getOptionLabel = useCallback(
      (option: string | DropdownOptionProps) =>
        (option as { value: string }).value,
      [],
    )

    useEffect(() => {
      setAutocompleteValue(selectedValue)
    }, [selectedValue, data, getOptionLabel])

    const handleResetFieldValue = () => {
      setAutocompleteValue(Constants.EmptyString)
      setFieldValue?.(name, Constants.EmptyString)
      setInputValue(Constants.EmptyString)
      setValue?.({ value: undefined })
    }

    const filter = createFilterOptions<DropdownOptionProps>()

    let timerId: { cancel: () => void }
    const handleDoneTyping = (e: React.KeyboardEvent<HTMLDivElement>) => {
      const timer = debounce(
        (event: React.KeyboardEvent<HTMLDivElement>) =>
          setSearchResult?.((event.target as HTMLInputElement).value),
        250,
      )

      timerId = timer(e)
    }

    useEffect(() => {
      return timerId?.cancel()
    })

    return (
      <Autocomplete
        freeSolo
        onKeyUp={handleDoneTyping}
        className={clsx('flex flex-col', className)}
        options={data}
        getOptionLabel={(option) => (option as { value: string }).value || ''}
        value={autocompleteValue}
        onChange={(e, value) => {
          setAutocompleteValue(
            ((value as DropdownOptionProps)?.value as string) ?? '',
          )
          setFieldValue?.(
            name,
            ((value as DropdownOptionProps)?.value as string) ?? '',
          )
          setValue?.(value as DropdownOptionProps)
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <FilterDropdownInput
            error={errors}
            params={params}
            label={selectFieldLabel}
            value={autocompleteValue}
            placeholder={placeholder}
            formFieldClassName={formFieldClassName}
            resetFieldValue={handleResetFieldValue}
          />
        )}
        renderOption={(
          props: HTMLAttributes<HTMLLIElement>,
          option: DropdownOptionProps,
        ) => (
          <FilterDropdownOption
            props={props}
            option={option}
            key={option.value + data.indexOf(option).toString()}
            dropdownDataLayout={dropdownDataLayout}
          />
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          if (showOtherOption) {
            filtered.push({
              value: dropdownOther,
            })
          }
          setHasFilterResults?.(!!filtered.length)
          return showValidationError ? options : filtered
        }}
        PaperComponent={(paperProps) => (
          <CustomPaper {...paperProps} isLoading={isLoading} />
        )}
      />
    )
  },
)
