import classes from './user-card.module.scss'
import clsx from 'clsx'
import { forwardRef, HTMLProps, PropsWithoutRef } from 'react'

export const UserCardContent = forwardRef<
  HTMLDivElement,
  PropsWithoutRef<HTMLProps<HTMLDivElement>>
>(({ children, className, ...props }, ref) => {
  return (
    <div
      {...props}
      className={clsx(classes.userCardContent, className)}
      ref={ref}
    >
      {children}
    </div>
  )
})
