import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'

import classes from './icon-button.module.scss'
import { ButtonElement, IconButtonProps } from './types'

export const IconButton = forwardRef(
  <TElement extends ButtonElement = 'button'>(
    {
      disabled,
      variant = 'elevated',
      size = 'medium',
      as,
      icon,
      title,
      className,
      // eslint-disable-next-line
      children,
      type = 'button',
      ...props
    }: IconButtonProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'button',
      {
        ...props,
        disabled,
        ref,
        type,
        className: clsx(
          classes.iconButton,
          classes[variant],
          classes[size],
          className,
        ),
        title,
      },
      icon,
    )
  },
)
