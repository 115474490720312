import { Translate, TranslateSimple } from '@/../ui'
import { Constants, useDocumentFetcher } from '@/common'
import { DocumentsEnum } from '@/common/enums'
import { LegalDocumentType } from '@/common/enums/LegalDocumentType'
import { MyTellcoDocumentType } from '@/common/enums/MyTellcoDocumentType'
import { MyTellcoDocumentMap } from '@/common/mappers/my-tellco-documents-map'
import { fetchDocument } from '@/features/user-account/lib/user-account-client'
import { Trans, useTranslation } from 'react-i18next'
import { DocumentsProps } from './types'
import { getLegalDocumentsActions } from './actions'
import { getPortfolioDocuments } from '@/features/portfolio/lib/portfolio-api-client'

const { getPortfolioType, getDocument } = getLegalDocumentsActions()

export const Documents = ({
  documentsToAccept,
  hasMyTellcoDocument,
  personalDocumentsInfo,
}: DocumentsProps) => {
  const { t } = useTranslation()
  const { fetchDocument: fetchDocsHandler } = useDocumentFetcher({
    fetcher: fetchDocument,
  })

  const { fetchDocument: downloadMyTellcoDocument } = useDocumentFetcher({
    fetcher: getPortfolioDocuments,
  })

  const privacyPolicy = getDocument(
    DocumentsEnum.PRIVACY_POLICY,
    LegalDocumentType.PRIVACY_POLICY,
    documentsToAccept,
    personalDocumentsInfo,
  )

  const termsOfUse = getDocument(
    DocumentsEnum.TERMS_OF_USE,
    LegalDocumentType.TERMS_OF_USE,
    documentsToAccept,
    personalDocumentsInfo,
  )

  return (
    <>
      {!hasMyTellcoDocument && (
        <div className="flex flex-wrap">
          <Translate
            className="my-auto mr-1"
            label="USM-Login:Documents.Accept"
          />
          {termsOfUse && (
            <Trans
              i18nKey="USM-Login:Documents.TermsOfUse"
              components={{
                termsOfUse: (
                  <button
                    onClick={async (event) => {
                      event.preventDefault()
                      await fetchDocsHandler(
                        termsOfUse?.url ?? Constants.EmptyString,
                      )
                    }}
                    style={{
                      color: '#3A85F8',
                      fontSize: '12px',
                    }}
                  />
                ),
              }}
            />
          )}

          {termsOfUse && privacyPolicy && (
            <Translate
              className="my-auto mx-1"
              label="USM-Login:Documents.And"
            />
          )}

          {privacyPolicy && (
            <Trans
              i18nKey="USM-Login:Documents.PrivacyPolicy"
              components={{
                privacyPolicy: (
                  <button
                    onClick={async (event) => {
                      event.preventDefault()
                      await fetchDocsHandler(
                        privacyPolicy?.url ?? Constants.EmptyString,
                      )
                    }}
                    style={{
                      color: '#3A85F8',
                      fontSize: '12px',
                      marginRight: '4px',
                    }}
                  />
                ),
              }}
            />
          )}
          <Translate className="my-auto" label="USM-Login:Documents.ForEplix" />
        </div>
      )}

      {hasMyTellcoDocument && (
        <div>
          <ul className="list-disc ml-5 marker:text-[#6c7177]">
            {!!documentsToAccept.eplixDocumentTypes?.length &&
              personalDocumentsInfo?.[0].documents.map((document, index) => {
                if (
                  document.name === termsOfUse?.name ||
                  document.name === privacyPolicy?.name
                ) {
                  return (
                    <li key={index}>
                      <button
                        onClick={async (event) => {
                          event.preventDefault()
                          await fetchDocsHandler(document.url)
                        }}
                      >
                        <div className="flex flex-wrap text-variant-300 text-left text-blue">
                          <TranslateSimple
                            label={`USM-Login:Documents.${document.name}`}
                          />
                          {Constants.Space}
                          <TranslateSimple label="USM-Login:Documents.ForEplix" />
                        </div>
                      </button>
                    </li>
                  )
                }
              })}
            {documentsToAccept.mytellcoDocumentTypes?.map((doc, index) => {
              const type = getPortfolioType(doc.portfolioType)
              return (
                <li key={index}>
                  <button
                    onClick={async (event) => {
                      event.preventDefault()
                      await downloadMyTellcoDocument({
                        url:
                          '/api/portfolio/' +
                          type +
                          '/portfolio-document-download',
                        payload: {
                          documentType:
                            MyTellcoDocumentMap[
                              doc.documentType as MyTellcoDocumentType
                            ],
                        },
                      })
                    }}
                  >
                    <div className="flex flex-wrap text-variant-300 text-left text-blue">
                      <TranslateSimple
                        label={`USM-Login:Documents.${doc.documentType}`}
                        translationOptions={{
                          portfolioType: t(`USM-Login:${doc.portfolioType}`),
                        }}
                      />
                      {Constants.Space}
                      <TranslateSimple label="USM-Login:Documents.ForMyTellco" />
                    </div>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}
