import { DrawerTemplate } from './drawer-template'
import { DocumentsEnum } from '@/common/enums'
import { AnimatePresence, motion } from 'framer-motion'

interface DrawerProps {
  closeDrawer?: () => void
  drawerDocument?: DocumentsEnum | null
  largeScreen?: boolean
  children: JSX.Element
}

export const Drawer = ({
  closeDrawer,
  drawerDocument,
  largeScreen = false,
  children,
}: DrawerProps) => (
  <AnimatePresence initial={false}>
    {drawerDocument && (
      <motion.aside
        key="drawer"
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        exit={{ x: '100%' }}
        transition={{ ease: 'easeInOut' }}
        className={`${largeScreen ? 'absolute' : 'fixed'} inset-0 text-left`}
      >
        <DrawerTemplate
          document={drawerDocument}
          onCloseClick={closeDrawer}
          className="overflow-hidden"
          mobileHeaderStyle="justify-start"
        >
          {children}
        </DrawerTemplate>
      </motion.aside>
    )}
  </AnimatePresence>
)
