import { Translate } from '@eplix/ui'
import { UploadDocumentsHintProps } from '../../types'
import { PersonalDocumentsHintEnum } from '@/common/enums/PersonalDocuments'
import { getCommonUserDataActions } from '@/common/actions'

const { getIsResidencePermit } = getCommonUserDataActions()

export const UploadDocumentsHint = ({
  documentType,
}: UploadDocumentsHintProps) => {
  const isResidencePermit = getIsResidencePermit(documentType)
  return (
    <div className="flex flex-col border border-[#c1c4c7] rounded-lg p-6 mt-1 text-left">
      <Translate
        color="text-primary"
        variant="medium-300"
        label={`common:PersonalDocumentsHint.HintContent_${documentType}.Header`}
      />
      <ul className="text-text-secondary ml-5 typo-regular-200 list-disc mt-3">
        {isResidencePermit && (
          <li>
            <Translate label="common:PersonalDocumentsHint.CreditCardFormat" />
          </li>
        )}
        <li>
          <Translate label="common:PersonalDocumentsHint.InformationVisible" />
        </li>
        <li>
          <Translate label="common:PersonalDocumentsHint.PhotosStraight" />
        </li>
        <li>
          <Translate
            label={`common:PersonalDocumentsHint.HintContent_${documentType}.ValidDocument`}
          />
        </li>
        {documentType !== PersonalDocumentsHintEnum.PASSPORT && (
          <li>
            <Translate label="common:PersonalDocumentsHint.FrontAndBack" />
          </li>
        )}
      </ul>
      <Translate
        label={`common:PersonalDocumentsHint.HintContent_${documentType}.FrontSide`}
        className="mb-2 mt-3"
      />
      <img
        src={`/images/${documentType}_front.svg`}
        alt="document_front"
        className="mb-8"
      />
      <Translate
        label={`common:PersonalDocumentsHint.HintContent_${documentType}.BackSide`}
        className="mb-2"
      />
      <img src={`/images/${documentType}_back.svg`} alt="document_back" />
    </div>
  )
}
