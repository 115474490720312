import { REGEX_EXPRESSION } from '../constants/regex-expressions'
import { roundFixed } from '../helpers/round-decimals'

export const formatMoney = (
  value: number,
  currency?: string,
  rightOrientation?: boolean,
) => {
  const moneyValue = roundFixed(value, 2).replace(
    REGEX_EXPRESSION.MONEY_FORMAT,
    `$&'`,
  )

  const formatBasedOnOrientation = rightOrientation
    ? `${moneyValue} ${currency}`
    : `${currency} ${moneyValue}`

  return currency ? formatBasedOnOrientation : moneyValue
}

export const formatMoneyNoDecimal = (
  value: string | number,
  currency?: string,
  rightOrientation?: boolean,
) => {
  const moneyValue = roundFixed(Number(value)).replace(
    REGEX_EXPRESSION.MONEY_FORMAT_NO_DECIMAL,
    `$&'`,
  )
  const formatBasedOnOrientation = rightOrientation
    ? `${moneyValue} ${currency}`
    : `${currency} ${moneyValue}`

  return currency ? formatBasedOnOrientation : moneyValue
}

export const percentageValue = (value: string | number) => `${Number(value)}%`
