import { Constants } from '@/common'

const assetProviderToLogoLabelMapper = {
  3: 'PIGUET_GALLAND',
  4: 'ALKIMIA',
  5: 'TELLCO',
} as Record<number, string>

export const getLogoLabelForAssetProvider = (
  assetProviderId: number | null,
) => {
  if (!assetProviderId) return Constants.EmptyString
  return assetProviderToLogoLabelMapper[assetProviderId]
}
