import { FC, HTMLProps } from 'react'
import clsx from 'clsx'
import classes from './overview-template.module.scss'

export const OverviewTemplate: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  onScroll,
  ...props
}) => {
  return (
    <div className={classes.overviewContainer} onScroll={onScroll}>
      <div {...props} className={clsx(classes.overview, props.className)}>
        {children}
      </div>
    </div>
  )
}
