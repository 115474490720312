import { Typo } from '@eplix/ui'
import { TranslateSimple } from './translate-simple'
import { TranslateProps } from './types'

export const Translate = ({
  as,
  label,
  variant = 'regular-200',
  color = 'text-secondary',
  disabled = false,
  className,
  boldText = false,
  translationOptions,
}: TranslateProps) => {
  return (
    <Typo
      as={as}
      color={color}
      variant={variant}
      boldText={boldText}
      className={className}
      disabled={disabled}
    >
      <TranslateSimple label={label} translationOptions={translationOptions} />
    </Typo>
  )
}
