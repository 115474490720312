export const TG = {
  8252: 'Schlatt TG',
  8253: 'Diessenhofen',
  8254: 'Basadingen',
  8255: 'Schlattingen',
  8259: 'Kaltenbach',
  8264: 'Eschenz',
  8265: 'Mammern',
  8266: 'Steckborn',
  8267: 'Berlingen',
  8268: 'Mannenbach-Salenst',
  8269: 'Fruthwilen',
  8272: 'Ermatingen',
  8273: 'Triboltingen',
  8274: 'Tägerwilen',
  8280: 'Kreuzlingen',
  8285: 'Kreuzlingen Ifolor',
  8355: 'Aadorf',
  8356: 'Ettenhausen TG',
  8357: 'Guntershausen',
  8360: 'Eschlikon TG',
  8362: 'Balterswil',
  8363: 'Bichelsee',
  8370: 'Sirnach',
  8372: 'Wiezikon b.Sirnach',
  8374: 'Oberwangen TG',
  8376: 'Fischingen',
  8500: 'Frauenfeld',
  8501: 'Frauenfeld',
  8502: 'Frauenfeld',
  8503: 'Frauenfeld',
  8505: 'Pfyn',
  8506: 'Lanzenneunforn',
  8507: 'Hörhausen',
  8508: 'Homburg',
  8510: 'Frauenfeld',
  8512: 'Thundorf',
  8514: 'Amlikon-Bissegg',
  8520: 'Frauenfeld PZ',
  8522: 'Häuslenen',
  8524: 'Uesslingen',
  8525: 'Niederneunforn',
  8526: 'Oberneunforn',
  8530: 'Frauenfeld CALL',
  8532: 'Warth',
  8535: 'Herdern',
  8536: 'Hüttwilen',
  8537: 'Nussbaumen TG',
  8540: 'Frauenfeld ST PP 1',
  8546: 'Islikon',
  8547: 'Gachnang',
  8552: 'Felben-Wellhausen',
  8553: 'Hüttlingen-Mettdf',
  8554: 'Müllheim-Wigoltgn',
  8555: 'Müllheim Dorf',
  8556: 'Wigoltingen',
  8558: 'Raperswilen',
  8560: 'Märstetten',
  8561: 'Ottoberg',
  8564: 'Lipperswil',
  8565: 'Hugelshofen',
  8566: 'Dotnacht',
  8570: 'Weinfelden',
  8572: 'Berg TG',
  8573: 'Siegershausen',
  8574: 'Lengwil-Oberhofen',
  8575: 'Bürglen TG',
  8576: 'Mauren TG',
  8577: 'Schönholzerswilen',
  8580: 'Amriswil',
  8581: 'Schocherswil',
  8582: 'Dozwil',
  8583: 'Sulgen',
  8584: 'Leimbach TG',
  8585: 'Mattwil',
  8586: 'Erlen',
  8587: 'Oberaach',
  8588: 'Zihlschlacht',
  8589: 'Sitterdorf',
  8590: 'Romanshorn',
  8592: 'Uttwil',
  8593: 'Kesswil',
  8594: 'Güttingen',
  8595: 'Altnau',
  8596: 'Scherzingen',
  8597: 'Landschlacht',
  8598: 'Bottighofen',
  8599: 'Salmsach',
  9213: 'Hauptwil',
  9214: 'Kradolf-Schönenb.',
  9215: 'Schönenberg TG',
  9216: 'Heldswil',
  9217: 'Neukirch',
  9220: 'Bischofszell',
  9223: 'Schweizersholz',
  9225: 'Wilen (Gottshaus)',
  9306: 'Freidorf TG',
  9314: 'Steinebrunn',
  9315: 'Winden',
  9320: 'Arbon',
  9322: 'Egnach',
  9325: 'Roggwil TG',
  9326: 'Horn',
  9502: 'Braunau',
  9503: 'Stehrenberg',
  9504: 'Friltschen',
  9506: 'Lommis',
  9507: 'Stettfurt',
  9508: 'Weingarten-Kalth',
  9514: 'Wuppenau',
  9515: 'Hosenruck',
  9517: 'Mettlen',
  9532: 'Rickenbach b. Wil',
  9535: 'Wilen b. Wil',
  9542: 'Münchwilen TG',
  9543: 'St. Margarethen TG',
  9545: 'Wängi',
  9546: 'Tuttwil',
  9547: 'Wittenwil',
  9548: 'Matzingen',
  9553: 'Bettwiesen',
  9554: 'Tägerschen',
  9555: 'Tobel',
  9556: 'Affeltrangen',
  9562: 'Märwil',
  9565: 'Rothenhausen',
  9573: 'Littenheid',
}
