export const ZH = {
  8000: 'Zürich',
  8001: 'Zürich',
  8002: 'Zürich',
  8003: 'Zürich',
  8004: 'Zürich',
  8005: 'Zürich',
  8006: 'Zürich',
  8008: 'Zürich',
  8010: 'Zürich',
  8011: 'Zürich Mülligen SC',
  8012: 'Zürich',
  8015: 'Zürich 15',
  8016: 'Zürich 16 Zust',
  8020: 'Zürich 1',
  8021: 'Zürich 1 Sihlpost',
  8022: 'Zürich',
  8023: 'Zürich',
  8024: 'Zürich',
  8026: 'Zürich',
  8027: 'Zürich',
  8030: 'Zürich',
  8031: 'Zürich',
  8032: 'Zürich',
  8033: 'Zürich',
  8034: 'Zürich',
  8036: 'Zürich',
  8037: 'Zürich',
  8038: 'Zürich',
  8039: 'Zürich',
  8040: 'Zürich',
  8041: 'Zürich',
  8042: 'Zürich',
  8044: 'Zürich',
  8045: 'Zürich',
  8046: 'Zürich',
  8047: 'Zürich',
  8048: 'Zürich',
  8049: 'Zürich',
  8050: 'Zürich',
  8051: 'Zürich',
  8052: 'Zürich',
  8053: 'Zürich',
  8055: 'Zürich',
  8057: 'Zürich',
  8058: 'Zürich',
  8059: 'Zürich 59 EO',
  8060: 'Zürich',
  8061: 'Zürich',
  8063: 'Zürich',
  8064: 'Zürich',
  8065: 'Zürich',
  8066: 'Zürich',
  8068: 'Zürich',
  8070: 'Zürich',
  8071: 'Zürich CS PZ',
  8074: 'Zürich Voice Pub',
  8075: 'Zürich',
  8080: 'Zürich 80',
  8081: 'Zürich',
  8085: 'Zürich Versich.',
  8086: 'Zürich R Digest',
  8087: 'Zürich',
  8088: 'Zürich',
  8090: 'Zürich',
  8091: 'Zürich',
  8092: 'Zürich',
  8093: 'Zürich',
  8096: 'Zürich IBRS local',
  8098: 'Zürich',
  8099: 'Zürich',
  8102: 'Oberengstringen',
  8103: 'Unterengstringen',
  8104: 'Weiningen ZH',
  8105: 'Regensdorf',
  8106: 'Regensdorf 2',
  8107: 'Buchs ZH',
  8108: 'Dällikon',
  8112: 'Otelfingen',
  8113: 'Boppelsen',
  8114: 'Dänikon ZH',
  8115: 'Hüttikon',
  8117: 'Fällanden',
  8118: 'Pfaffhausen',
  8121: 'Benglen',
  8122: 'Binz',
  8123: 'Ebmatingen',
  8124: 'Maur',
  8125: 'Zollikerberg',
  8126: 'Zumikon',
  8127: 'Forch',
  8130: 'Zumikon Y. Rocher',
  8132: 'Hinteregg',
  8133: 'Esslingen',
  8134: 'Adliswil',
  8135: 'Langnau am Albis',
  8136: 'Gattikon',
  8142: 'Uitikon Waldegg',
  8143: 'Uetliberg',
  8152: 'Glattbrugg',
  8153: 'Rümlang',
  8154: 'Oberglatt ZH',
  8155: 'Niederhasli',
  8156: 'Oberhasli',
  8157: 'Dielsdorf',
  8158: 'Regensberg',
  8162: 'Steinmaur',
  8164: 'Bachs',
  8165: 'Schöfflisdorf',
  8166: 'Niederweningen',
  8172: 'Niederglatt ZH',
  8173: 'Neerach',
  8174: 'Stadel',
  8175: 'Windlach',
  8180: 'Bülach',
  8181: 'Höri',
  8182: 'Hochfelden',
  8183: 'Bülach Dist Ba',
  8184: 'Bachenbülach',
  8185: 'Winkel',
  8186: 'Bülach',
  8187: 'Weiach',
  8192: 'Glattfelden',
  8193: 'Eglisau',
  8194: 'Hüntwangen',
  8195: 'Wasterkingen',
  8196: 'Wil ZH',
  8197: 'Rafz',
  8245: 'Feuerthalen',
  8246: 'Langwiesen',
  8247: 'Flurlingen',
  8248: 'Uhwiesen',
  8301: 'Glattzentrum',
  8302: 'Kloten',
  8303: 'Bassersdorf',
  8304: 'Wallisellen',
  8305: 'Dietlikon',
  8306: 'Brüttisellen',
  8307: 'Effretikon',
  8308: 'Illnau',
  8309: 'Nürensdorf',
  8310: 'Kemptthal',
  8311: 'Brütten',
  8312: 'Winterberg ZH',
  8314: 'Kyburg',
  8315: 'Lindau',
  8317: 'Tagelswangen',
  8320: 'Fehraltorf',
  8322: 'Madetswil',
  8325: 'Effretikon Dist Ba',
  8330: 'Pfäffikon ZH',
  8331: 'Auslikon',
  8332: 'Russikon',
  8335: 'Hittnau',
  8340: 'Hinwil',
  8342: 'Wernetshausen',
  8343: 'Hinwil Dist Ba',
  8344: 'Bäretswil',
  8345: 'Adetswil',
  8352: 'Elsau',
  8353: 'Elgg',
  8354: 'Hofstetten ZH',
  8400: 'Winterthur',
  8401: 'Winterthur',
  8402: 'Winterthur',
  8404: 'Winterthur',
  8405: 'Winterthur',
  8406: 'Winterthur',
  8408: 'Winterthur',
  8409: 'Winterthur',
  8410: 'Winterthur',
  8411: 'Winterthur',
  8412: 'Aesch (Neftenbach)',
  8413: 'Neftenbach',
  8414: 'Buch am Irchel',
  8415: 'Berg am Irchel',
  8416: 'Flaach',
  8418: 'Schlatt',
  8421: 'Dättlikon',
  8422: 'Pfungen',
  8423: 'Embrach-Embraport',
  8424: 'Embrach',
  8425: 'Oberembrach',
  8426: 'Lufingen',
  8427: 'Rorbas-Freienstein',
  8428: 'Teufen ZH',
  8442: 'Hettlingen',
  8444: 'Henggart',
  8447: 'Dachsen',
  8450: 'Andelfingen',
  8451: 'Kleinandelfingen',
  8452: 'Adlikon',
  8453: 'Alten',
  8457: 'Humlikon',
  8458: 'Dorf',
  8459: 'Volken',
  8460: 'Marthalen',
  8461: 'Oerlingen',
  8462: 'Rheinau',
  8463: 'Benken ZH',
  8464: 'Ellikon am Rhein',
  8465: 'Rudolfingen',
  8466: 'Trüllikon',
  8467: 'Truttikon',
  8468: 'Guntalingen',
  8471: 'Rutschwil',
  8472: 'Seuzach',
  8474: 'Dinhard',
  8475: 'Ossingen',
  8476: 'Unterstammheim',
  8477: 'Oberstammheim',
  8478: 'Thalheim',
  8479: 'Altikon',
  8482: 'Sennhof',
  8483: 'Kollbrunn',
  8484: 'Weisslingen',
  8486: 'Rikon im Tösstal',
  8487: 'Rämismühle',
  8488: 'Turbenthal',
  8489: 'Wildberg',
  8492: 'Wila',
  8493: 'Saland',
  8494: 'Bauma',
  8495: 'Schmidrüti',
  8496: 'Steg im Tösstal',
  8497: 'Fischenthal',
  8498: 'Gibswil-Ried',
  8499: 'Sternenberg',
  8523: 'Hagenbuch ZH',
  8542: 'Wiesendangen',
  8543: 'Bertschikon',
  8544: 'Attikon',
  8545: 'Rickenbach ZH',
  8548: 'Ellikon a d Thur',
  8600: 'Dübendorf',
  8602: 'Wangen ZH',
  8603: 'Schwerzenbach',
  8604: 'Volketswil',
  8605: 'Gutenswil',
  8606: 'Nänikon',
  8607: 'Aathal-Seegräben',
  8608: 'Bubikon',
  8609: 'Schwerzenbach',
  8610: 'Uster',
  8612: 'Uster 2',
  8613: 'Uster 3',
  8614: 'Bertschikon Gossau',
  8615: 'Wermatswil',
  8616: 'Riedikon',
  8617: 'Mönchaltorf',
  8618: 'Oetwil am See',
  8620: 'Wetzikon ZH',
  8621: 'Wetzikon ZH 4',
  8622: 'Wetzikon ZH',
  8623: 'Wetzikon ZH',
  8624: 'Grüt (Gossau ZH)',
  8625: 'Gossau ZH',
  8626: 'Ottikon',
  8627: 'Grüningen',
  8630: 'Rüti ZH',
  8632: 'Tann',
  8633: 'Wolfhausen',
  8634: 'Hombrechtikon',
  8635: 'Dürnten',
  8636: 'Wald ZH',
  8637: 'Laupen ZH',
  8639: 'Faltigberg',
  8700: 'Küsnacht ZH',
  8702: 'Zollikon',
  8703: 'Erlenbach ZH',
  8704: 'Herrliberg',
  8706: 'Meilen',
  8707: 'Uetikon am See',
  8708: 'Männedorf',
  8712: 'Stäfa',
  8713: 'Uerikon',
  8714: 'Feldbach',
  8800: 'Thalwil',
  8801: 'Thalwil',
  8802: 'Kilchberg ZH',
  8803: 'Rüschlikon',
  8804: 'Au ZH',
  8805: 'Richterswil',
  8810: 'Horgen',
  8812: 'Horgen',
  8813: 'Horgen',
  8815: 'Horgenberg',
  8816: 'Hirzel',
  8820: 'Wädenswil',
  8824: 'Schönenberg ZH',
  8825: 'Hütten',
  8833: 'Samstagern',
  8901: 'Urdorf',
  8902: 'Urdorf',
  8903: 'Birmensdorf ZH',
  8904: 'Aesch ZH',
  8906: 'Bonstetten',
  8907: 'Wettswil',
  8908: 'Hedingen',
  8909: 'Zwillikon',
  8910: 'Affoltern am Albis',
  8911: 'Rifferswil',
  8912: 'Obfelden',
  8913: 'Ottenbach',
  8914: 'Aeugst am Albis',
  8915: 'Hausen am Albis',
  8925: 'Ebertswil',
  8926: 'Kappel am Albis',
  8932: 'Mettmenstetten',
  8933: 'Maschwanden',
  8934: 'Knonau',
  8942: 'Oberrieden',
  8951: 'Fahrweid',
  8952: 'Schlieren',
  8953: 'Dietikon',
  8954: 'Geroldswil',
  8955: 'Oetwil a.d. Limmat',
}
