import { IconProps } from '@/components/risk-profile-chart/types'

export const LowRisk = ({ x, y }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="36"
      fill="none"
      viewBox="0 0 120 36"
      x={x}
      y={y}
    >
      <rect
        width="119"
        height="35"
        x="0.5"
        y="0.5"
        fill="#F4F7FA"
        rx="7.5"
      ></rect>
      <path
        fill="#232323"
        d="M35.092 20.704h3.828V22h-5.184v-8.4h1.356v7.104zm8.642-5.448c1.896 0 3.42 1.452 3.42 3.444 0 1.98-1.5 3.444-3.42 3.444-1.908 0-3.42-1.476-3.42-3.444 0-1.992 1.536-3.444 3.42-3.444zm0 1.296c-1.14 0-2.052.876-2.052 2.148 0 1.272.9 2.148 2.052 2.148 1.152 0 2.052-.876 2.052-2.148 0-1.272-.912-2.148-2.052-2.148zM56.678 15.4h1.356L56.15 22h-1.584l-.636-2.22-.66-3.012h-.216l-.66 3.012-.636 2.22h-1.584l-1.884-6.6h1.344l.588 2.076.768 3.096.768-3.096.588-2.076h1.632l.576 2.076.768 3.072.78-3.072.576-2.076zm10.79-.144c.168 0 .3.012.396.036v1.38c-.12-.012-.228-.024-.408-.024-1.236 0-2.184.696-2.184 2.328V22h-1.356v-6.6h1.356v1.308c.396-.924 1.152-1.452 2.196-1.452zm2.748-2.832c.516 0 .888.348.888.828 0 .492-.372.852-.888.852-.492 0-.876-.36-.876-.852 0-.48.384-.828.876-.828zM69.544 22v-6.6H70.9V22h-1.356zm6.445.144c-1.74 0-2.988-.84-3.24-2.268h1.38c.204.732.744 1.068 1.884 1.068.876 0 1.416-.264 1.416-.756 0-.552-.624-.636-1.728-.9-1.848-.456-2.628-.828-2.628-1.932 0-1.332 1.128-2.1 2.784-2.1 1.692 0 2.64.804 2.88 2.004H77.37c-.192-.54-.696-.804-1.536-.804-.876 0-1.392.3-1.392.756 0 .42.408.528 1.56.804 1.788.42 2.82.732 2.82 2.016 0 1.416-1.272 2.112-2.832 2.112zM86.957 22h-1.752l-3.108-3.048V22h-1.356v-9.24h1.356v5.496l2.856-2.856h1.74l-3.228 3.132L86.957 22z"
      ></path>
      <rect
        width="119"
        height="35"
        x="0.5"
        y="0.5"
        stroke="#C1C4C7"
        rx="7.5"
      ></rect>
    </svg>
  )
}
