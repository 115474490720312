import { Fragment } from 'react'
import SliderSection from './slider-section'
import { normalizeValueToPercentage } from './utils'

export interface RawSectionData {
  startValue: number
  endValue: number
  color: string
}

const SliderSections = ({
  sections,
  minValue,
  maxValue,
  sectionBorderColor,
}: {
  sections: RawSectionData[]
  minValue: number
  maxValue: number
  sectionBorderColor: string
}) => {
  let offset = 0

  return (
    <Fragment>
      {sections.map(({ color, endValue }, index) => {
        const newWidthPercentage =
          normalizeValueToPercentage(endValue, minValue, maxValue) - offset
        const newSection = (
          <SliderSection
            key={`slider-section-${index}`}
            color={color}
            isFirstSection={index === 0}
            isLastSection={index === sections.length - 1}
            sectionWidth={newWidthPercentage}
            offsetPercentage={offset}
            sectionBorderColor={sectionBorderColor}
          />
        )

        offset += newWidthPercentage
        return newSection
      })}
    </Fragment>
  )
}

export default SliderSections
