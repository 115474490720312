import { useEnumMapper } from '@/framework/common/hooks/use-enum-mapper'
import { CountryEnum } from '@/common/enums'

export const useSortCountriesAlphabetically = () => {
  const mappedCountryEnums = {
    countries: CountryEnum,
    switzerland: CountryEnum.SWITZERLAND,
  }
  const countries = useEnumMapper(
    mappedCountryEnums.countries ?? {},
    '',
    'country',
  )
  const switzerlandName = mappedCountryEnums.switzerland

  const unsortedCountriesCopy = [...countries]
  const neighbouringCountries = unsortedCountriesCopy.splice(0, 6)

  const otherCountries = unsortedCountriesCopy.sort((a, b) =>
    a.value.localeCompare(b.value),
  )

  const sortedCountries = neighbouringCountries.concat(otherCountries)

  return { sortedCountries, switzerlandName }
}
