export enum AssetProviderLogoEnum {
  ISHARES = 'ishares.png',
  UBS = 'ubs.png',
  AMUNDI = 'amundi.png',
  _21SHARES = '21shares.png',
  INVESCO = 'invesco.png',
  WISDOM_TREE = 'wisdomtree-logo.svg',
  ZKB = 'zkb.png',
  SPDR = 'spdr.png',
  PIGUET_GALLAND = 'piguet-galland-logo.svg',
  ALKIMIA = 'alkimia-logo.svg',
  TELLCO = 'tellco-logo.svg',
}
