import { useCallback, useEffect, useRef } from 'react'
import { useSetParentElementWidth } from './use-parent-width-hook'

export const useGetParentWidth = () => {
  const setParentElementWidth = useSetParentElementWidth()
  const ref = useRef<HTMLElement | null>(null)
  /* eslint-disable react-hooks/exhaustive-deps */
  const setRef = useCallback(async (node) => {
    await setParentElementWidth(node?.getBoundingClientRect().width)
    ref.current = node
  }, [])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    window.addEventListener('resize', () =>
      setParentElementWidth(ref.current?.getBoundingClientRect().width),
    )
    return () => {
      window.removeEventListener('resize', () =>
        setParentElementWidth(ref.current?.getBoundingClientRect().width),
      )
    }
  }, [])

  return setRef
}
