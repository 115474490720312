import { FC } from 'react'
import { IconProps } from './types'

export const ChartIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C4 3.44772 3.55228 3 3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H20C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19H4V4ZM19.7071 7.70711C20.0976 7.31658 20.0976 6.68342 19.7071 6.29289C19.3166 5.90237 18.6834 5.90237 18.2929 6.29289L14 10.5858L12.7071 9.29289C12.3166 8.90237 11.6834 8.90237 11.2929 9.29289L6.29289 14.2929C5.90237 14.6834 5.90237 15.3166 6.29289 15.7071C6.68342 16.0976 7.31658 16.0976 7.70711 15.7071L12 11.4142L13.2929 12.7071C13.6834 13.0976 14.3166 13.0976 14.7071 12.7071L19.7071 7.70711Z"
        fill="currentColor"
      />
    </svg>
  )
}
