import { Button, QuestionCircleIcon } from '@eplix/ui'
import React, { memo, useEffect } from 'react'
import { ZendeskAPI } from 'react-zendesk'
import { useIsZendeskWidgetLoaded, useToggleZendeskWidget } from '@/framework'
import { ZendeskButtonProps } from './types'
import clsx from 'clsx'
import { useRouter } from 'next/router'

export const ZendeskButton = memo(
  ({
    variant = 'text',
    className,
    color = '#3a85f8',
    text = 'common:HelpDesk',
    isCollapsed = true,
  }: ZendeskButtonProps) => {
    const router = useRouter()
    const [isZendeskWidgetLoaded] = useIsZendeskWidgetLoaded()
    const [toggle, setZendeskWidgetToggle] = useToggleZendeskWidget()

    const getText = () => {
      if (router.asPath === '/dashboard') {
        return text
      }
      return isCollapsed ? '' : text
    }

    useEffect(() => {
      if (isZendeskWidgetLoaded) {
        const state = toggle ? 'open' : 'close'
        ZendeskAPI('webWidget', state)
      }
    }, [isZendeskWidgetLoaded, toggle])

    return (
      <Button
        variant={variant}
        name="help_desk"
        startIcon={<QuestionCircleIcon color={color} />}
        text={getText()}
        className={clsx('text-primary', className)}
        onClick={() => setZendeskWidgetToggle(true)}
      />
    )
  },
)
