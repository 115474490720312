import { ColorProp } from '../utils/css-helpers/types'
import { getCSSVariableFromColorProp } from '../utils/css-helpers'
/* eslint-disable  @typescript-eslint/no-explicit-any */
const getTypoStyles = (
  propsStyles: any,
  color: ColorProp,
  boldText: boolean,
) => {
  const styles = {
    ...propsStyles,
    color: color && getCSSVariableFromColorProp(color),
  }
  if (boldText) {
    styles.fontWeight = 'bold'
  }
  return styles
}

export const getTypoActions = () => {
  return { getTypoStyles }
}
