import { FC } from 'react'
import { IconProps } from './types'

export const PillarsIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3861 1.21065C11.7472 0.929784 12.2529 0.929784 12.614 1.21065L21.614 8.21065C21.9512 8.47293 22.0846 8.92045 21.9459 9.32454C21.8073 9.72862 21.4273 10 21.0001 10H19V19H21C21.5523 19 22 19.4477 22 20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20C2 19.4477 2.44772 19 3 19H5V10H3.00006C2.57286 10 2.19283 9.72862 2.05419 9.32454C1.91554 8.92045 2.0489 8.47293 2.38612 8.21065L11.3861 1.21065ZM17 19V10H13V19H17ZM7 10H11V19H7V10ZM18.0855 8L12.0001 3.26686L5.9146 8H18.0855Z"
        fill="currentColor"
      />
    </svg>
  )
}
