import { FC } from 'react'
import { IconProps } from './types'

export const BackIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70711 4.70711C10.0976 4.31658 10.0976 3.68342 9.70711 3.29289C9.31658 2.90237 8.68342 2.90237 8.29289 3.29289L4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071C10.0976 12.3166 10.0976 11.6834 9.70711 11.2929L7.41421 9H13C15.2091 9 17 10.7909 17 13C17 15.2091 15.2091 17 13 17H6V19H13C16.3137 19 19 16.3137 19 13C19 9.68629 16.3137 7 13 7H7.41421L9.70711 4.70711Z"
        fill={props.fill ?? 'black'}
      />
    </svg>
  )
}
