import { Listbox } from '@headlessui/react'
import { ForwardedRef, forwardRef, HTMLProps, ReactNode } from 'react'
import clsx from 'clsx'

import classes from './select-input.module.scss'

export const SelectInputButton = forwardRef(
  (
    {
      ...props
    }: Omit<HTMLProps<HTMLButtonElement>, 'children'> & {
      children?: (({ open }: { open: boolean }) => ReactNode) | ReactNode
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <Listbox.Button
        ref={ref}
        {...props}
        as={undefined}
        className={clsx(classes.button, props.className)}
      >
        {props.children}
      </Listbox.Button>
    )
  },
)
