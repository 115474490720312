import {
  EmploymentInformationResponse,
  ErrorResponse,
  PayslipPeriodResponse,
  PayslipYearsResponse,
} from '@/api'
import { getJSON, postJSON } from '@/framework/common'
import { PayslipPeriodRequest, PayslipRequest } from '../types'

export const getPayslipPeriod = async (payload: PayslipPeriodRequest) => {
  return await postJSON<PayslipPeriodResponse, PayslipPeriodRequest>(
    `/api/salary/payslips/period`,
    payload,
  )
}

export const getEmploymentInformation = async () => {
  return getJSON<EmploymentInformationResponse | ErrorResponse>(
    `/api/salary/payslips/employment-information`,
  )
}

export const getPayslipYearsFilter = async (req: PayslipRequest) => {
  return postJSON<PayslipYearsResponse | ErrorResponse, PayslipRequest>(
    `/api/salary/payslips/payslip-years-filter`,
    req,
  )
}
