import { NetFlow } from '@/api'
import { formatMoney } from '@/common/formatters/money-formatter'
import { roundFixed } from '@/common/helpers/round-decimals'

export const formatPercentage = (
  percentage: number,
  numberOfDecimals: number,
) => {
  if (percentage === undefined) return 0
  if (numberOfDecimals === undefined) return percentage
  return roundFixed(percentage, numberOfDecimals) + '%'
}

export const formatPercentageNoRounding = (percentage: number) => {
  if (percentage === undefined) return 0
  const formattedNumber = Number.isInteger(percentage)
    ? roundFixed(percentage, 1)
    : percentage

  return formattedNumber + '%'
}

export const getNetInflow = (netFlow: NetFlow) => {
  if (!!netFlow) {
    return formatMoney(netFlow.deliveredIn + netFlow.payIn)
  } else return '0.00'
}
export const getNetOutflow = (netFlow: NetFlow) => {
  if (!!netFlow) {
    return formatMoney(netFlow.deliveredOut + netFlow.payOut)
  } else return '0.00'
}
