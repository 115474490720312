import moment, { Moment } from 'moment'
import 'moment/locale/de-ch'
import 'moment/locale/fr-ch'
import 'moment/locale/it-ch'
import 'moment/locale/en-gb'
import {
  DisplayDateFormat,
  RequestDateFormat,
} from '@/common/constants/date-format'
import { LocaleEnum } from '@/common/enums/Locale'
import { DateTime } from './types'
import { round } from '../../common/helpers/round-decimals'

export const convertToDate = (dateTime?: DateTime, fromFormat?: string) =>
  moment(dateTime, fromFormat)

const parse = (
  dateTime: DateTime | undefined,
  fromFormat: string,
  toFormat: string,
) => convertToDate(dateTime, fromFormat).format(toFormat)

export const START_OF_THE_MONTH = '01'
export const currentDate = moment()
export const currentYear = currentDate.year()
export const currentDay = currentDate.date()
export const currentMonth = currentDate.month() + 1 // month in a year (+1 because value goes from zero)
export const currentHour = currentDate.hour()
export const formatCurrentDate = (format: string) => currentDate.format(format)
export const getCurrentDate = (format: string) => moment().format(format)

export const getNextDay = (dateTime: DateTime) => {
  const nextDay = convertToDate(dateTime, DisplayDateFormat.YYYY_MM_DD)
    .add(1, 'days')
    .format(DisplayDateFormat.YYYY_MM_DD)
  return nextDay
}

export const currentDateLastYear = moment()
  .subtract(1, 'years')
  .format(RequestDateFormat.YYYY_MM_DD)

export const currentDate3YearsAgo = moment()
  .subtract(3, 'years')
  .format(RequestDateFormat.YYYY_MM_DD)

export const currentDate5YearsAgo = moment()
  .subtract(5, 'years')
  .format(RequestDateFormat.YYYY_MM_DD)

export const yesterdayDate = moment()
  .subtract(1, 'days')
  .format(DisplayDateFormat.DD_MM_YYYY)

export const todayDate = moment()
  .add(1, 'days')
  .format(DisplayDateFormat.DD_MM_YYYY)

export const firstDateOfCurrentYear = moment()
  .startOf('year')
  .format(RequestDateFormat.YYYY_MM_DD)

export const lastDateOfYear = moment()
  .endOf('year')
  .format(RequestDateFormat.YYYY_MM_DD)

export const addYears = (
  dateTime: DateTime,
  years: number,
  fromFormat: string,
) => {
  return moment(parse(dateTime, fromFormat, RequestDateFormat.YYYY_MM_DD)).add(
    years,
    'years',
  )
}

export const firstDayOfDecemberInCurrentYear = moment(lastDateOfYear)
  .startOf('month')
  .format(RequestDateFormat.YYYY_MM_DD)

export const firstDateOfNextMonth = moment()
  .add(1, 'month')
  .startOf('month')
  .format(RequestDateFormat.YYYY_MM_DD)

export const getDifferenceBetweenYears = (
  dateTime: DateTime,
  format = DisplayDateFormat.YYYY_MM_DD,
  precise = false,
) => currentDate.diff(convertToDate(dateTime, format), 'years', precise)

export const getYear = (
  dateTime: DateTime,
  fromFormat = RequestDateFormat.YYYY_MM_DD,
) => convertToDate(dateTime, fromFormat).year()

export const getMonth = (
  dateTime: DateTime,
  fromFormat = RequestDateFormat.YYYY_MM_DD,
) => convertToDate(dateTime, fromFormat).month() + 1

export const getMonthFromString = (dateString: string) => {
  const date = moment(dateString, RequestDateFormat.YYYY_MM_DD)
  return date.month() + 1
}

export const setGlobalDateLocale = (locale: string) => moment.locale(locale)

export const parseRequestDate = (
  dateTime: DateTime,
  toFormat = DisplayDateFormat.DD_MM_YYYY,
) => {
  return parse(dateTime, RequestDateFormat.YYYY_MM_DD, toFormat)
}

export const parseRequestDateAndTime = (
  dateTime: DateTime | undefined,
  fromFormat = RequestDateFormat.YYYY_M_DD,
  format = DisplayDateFormat.DD_MM_YYYY,
) => parse(dateTime, fromFormat, format)

export const isBeforeCurrentDate = (
  dateTime: DateTime,
  format = DisplayDateFormat.YYYY_MM_DD,
) => convertToDate(dateTime, format).isBefore(moment())

//Merge with the method above
export const isBeforeCurrentDateTime = (
  dateTime: DateTime,
  format: string = RequestDateFormat.YYYY_MM_DD_TIME_SECONDS,
) => convertToDate(dateTime, format).isBefore(moment())

export const isAfterDate = (
  dateTime: DateTime,
  format = DisplayDateFormat.YYYY_MM_DD,
  afterDate?: DateTime,
) => {
  if (afterDate) {
    const compareDate = convertToDate(afterDate, format)
    return convertToDate(dateTime, format).isAfter(compareDate, 'second')
  } else {
    return convertToDate(dateTime, format).isAfter()
  }
}

export const isBetweenDates = (
  dateTime: DateTime,
  beforeDate?: DateTime,
  afterDate?: DateTime,
) => {
  return convertToDate(dateTime, DisplayDateFormat.YYYY_MM_DD).isBetween(
    beforeDate,
    afterDate,
  )
}

export const parseDisplayDate = (
  dateTime: DateTime,
  displayDateFormat: string = DisplayDateFormat.DD_MM_YYYY,
) => convertToDate(dateTime, displayDateFormat)

export const parseRequestDateTimeForLocale = (
  dateTime: DateTime,
  locale: string = LocaleEnum.DE,
  fromFormat = RequestDateFormat.DD_MM_YYYY_TIME_SECONDS,
  toFormat = DisplayDateFormat.MMMM_YYYY,
) => convertToDate(dateTime, fromFormat).locale(locale).format(toFormat)

export const getDayFromDateResponse = (dateTime: DateTime) =>
  parse(dateTime, RequestDateFormat.YYYY_MM_DD, DisplayDateFormat.DD)

export const getTimeFromDate = (dateTime: DateTime) => {
  return moment(dateTime).format('HH:mm')
}

export const getMonthFromDateResponse = (
  dateTime: DateTime,
  format = DisplayDateFormat.MM,
) => parse(dateTime, RequestDateFormat.YYYY_MM_DD, format)

export const getYearFromDateResponse = (dateTime: DateTime) =>
  parse(dateTime, RequestDateFormat.YYYY_MM_DD, DisplayDateFormat.YEAR)

export const getYearFromStringResponse = (dateTime: string) =>
  parse(dateTime, RequestDateFormat.DD_MM_YYYY, DisplayDateFormat.YEAR)

export const getEndOfMonth = (dateTime: DateTime) => {
  const date = convertToDate(dateTime, RequestDateFormat.YYYY_MM_DD)
  return date.endOf('month').format(DisplayDateFormat.DD_MM_YYYY)
}

export const isValid = (
  dateTime: DateTime | undefined,
  format: string = RequestDateFormat.YYYY_M_DD,
) => convertToDate(dateTime, format).isValid()

export const checkDateValidityAndParse = (
  date: string,
  format = RequestDateFormat.YYYY_MM_DD,
) => (isValid(date, format) ? date : parseRequestDate(date, format))

export const getDifferenceBetweenDates = (
  firstDate: DateTime,
  secondDate: DateTime,
  dateFormat: string,
  period?: 'days' | 'months' | 'years',
  precise = false,
) => {
  const difference = convertToDate(firstDate, dateFormat).diff(
    convertToDate(secondDate, dateFormat),
    period,
    precise,
  )

  return precise ? round(difference) : difference
}

export const isDateSameOrBefore = (dateTime: string) => {
  const formattedDate = convertToDate(currentDate, DisplayDateFormat.YYYY_MM)
  return formattedDate.isSameOrBefore(dateTime)
}

export const parseApiDateAndTime = (
  dateTime: DateTime,
  fromFormat: string,
  toFormat: string,
) => parse(dateTime, fromFormat, toFormat)

export const isDateTimePeriodInPast = (
  from: DateTime,
  to: DateTime,
  format = 'DD.MM.YYYY, HH:mm',
) => {
  const startDateTime = convertToDate(from, format)
  const endDateTime = convertToDate(to, format)

  const currentTime = moment()

  return currentTime.isAfter(startDateTime) && currentTime.isAfter(endDateTime)
}

export const isSameOrAfterDay = (
  dateTimeFrom: DateTime,
  dateTimeTo: DateTime,
  format: string,
) => {
  const formattedDate1 = moment(dateTimeFrom, format)
  const formattedDate2 = moment(dateTimeTo, format)
  return formattedDate1.isSameOrAfter(formattedDate2, 'day')
}

export const arrayFormatToDate = (arrayDate: number[]) =>
  new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2])

export const getAllMonths = () => moment.months()
export const getMonthNumber = (monthName: string) => {
  return moment().month(monthName).format(DisplayDateFormat.MM)
}

export const getNumberOfDaysInMonth = (year: number, month: number) => {
  return moment(`${year}-${month}`, DisplayDateFormat.YYYY_MM).daysInMonth()
}

export const getAllMonthsShort = () => moment.monthsShort()

export const getMostRecentDate = (dates: Moment[]) => moment.max(dates)

export const compareYearAndMonth = (
  payslipDate: string,
  mostRecentDate: Moment,
) => {
  const date = convertToDate(
    parseRequestDate(payslipDate, RequestDateFormat.YYYY_MM_DD),
  )

  return (
    date.isSame(mostRecentDate, 'year') && date.isSame(mostRecentDate, 'month')
  )
}

export const getYearsInGivenInterval = (
  startYear: number,
  endYear: number = currentYear,
) => {
  const years: Array<number> = []
  for (let i = startYear; i <= endYear; i++) {
    years.push(i)
  }
  return years
}

export const getDifferenceBetweenMonths = (
  startDate: string,
  endDate: string,
) => {
  return round(convertToDate(endDate).diff(startDate, 'months', true))
}

export const firstDateGreaterThenSecond = (
  firstDate: string,
  secondDate: string,
) => {
  return moment(firstDate) > moment(secondDate)
}

export const calculateMinEndTime = (
  startDate: string,
  startTime: string,
  endDate: string,
): Date => {
  const startDateTime = new Date(startDate)
  const startTimeDate = new Date(startTime)
  startDateTime.setHours(startTimeDate.getHours(), startTimeDate.getMinutes())

  const endDateTime = new Date(endDate)
  endDateTime.setHours(0, 0, 0, 0)

  return startDateTime > endDateTime ? startDateTime : endDateTime
}
export const calculateMinStartTime = (startDate: string): Date => {
  const startDateDate = new Date(startDate)
  startDateDate.setHours(0, 0, 0, 0)
  if (startDateDate > currentDate.toDate()) {
    return startDateDate
  } else {
    return currentDate.toDate()
  }
}

export const isFirstDateSameOrBeforeSecondString = (
  first: string,
  second: string,
) => {
  return moment(first).isSameOrBefore(second)
}

export const parseDate = (date: string | undefined) => {
  return date !== undefined
    ? parseRequestDate(date, DisplayDateFormat.YYYY_MM_DD)
    : undefined
}

export const addDaysToDate = (date: string, days: number): string => {
  return moment(date, RequestDateFormat.YYYY_MM_DD)
    .add(days, 'days')
    .format(RequestDateFormat.YYYY_MM_DD)
}
