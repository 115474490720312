import { EnvironmentEnum } from '@/common/enums/EnvironmentEnum'

export const checkIsSpecificEnv = (env: string) => {
  return process.env.ENVIRONMENT === env
}

export const getIsProdEnv = () => checkIsSpecificEnv(EnvironmentEnum.PROD)
export const getIsPlayEnv = () => checkIsSpecificEnv(EnvironmentEnum.PLAY)
export const getIsDevEnv = () => checkIsSpecificEnv(EnvironmentEnum.DEV)
export const getIsDev2Env = () => checkIsSpecificEnv(EnvironmentEnum.DEV2)
export const getIsDev3Env = () => checkIsSpecificEnv(EnvironmentEnum.DEV3)
export const getIsQaEnv = () => checkIsSpecificEnv(EnvironmentEnum.QA)
export const getIsQa2Env = () => checkIsSpecificEnv(EnvironmentEnum.QA2)
