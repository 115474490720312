import { RefObject, useEffect } from 'react'

export const useHandleEnterSubmit = (
  buttonRef: RefObject<HTMLButtonElement>,
) => {
  useEffect(() => {
    const KEY_PRESSED = 'Enter'
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === KEY_PRESSED || event.key === KEY_PRESSED) {
        event.preventDefault()
        buttonRef.current?.click()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [buttonRef])
}
