import { REGEX_EXPRESSION } from '@/common/constants'

const getFormattedValue = (value: string) => {
  const cleaned = value.replace(/\s/g, '').substring(2, value.length)
  const formatted = cleaned
    .replace(REGEX_EXPRESSION.IBAN, '$1 $2 $3 $4 $5 $6')
    .trim()

  return `CH${formatted}`
}

export const getIBANFieldActions = () => ({
  getFormattedValue,
})
