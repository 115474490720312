import { ErrorResponse } from '@/api'
import { hasErrors, RequestState } from '@/common'
import useRouterPush from '@/framework/common/hooks/use-mutation'
import { displayPdf } from '@/framework/files/display-file'
import {
  GlobalValueKey,
  useGlobalValue,
  useResponseErrorMessage,
  useResponseFormFieldErrorMessages,
} from '@/framework'
import { toNumber } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

interface CommonProps<TPayload, TResponse> {
  payload?: TPayload
  fetcher: (props: TPayload) => Promise<TResponse | ErrorResponse | BlobPart>
}

interface FetcherProps<
  TPayload,
  TResponse,
  TCallback = (data: TResponse) => void,
> extends CommonProps<TPayload, TResponse> {
  enabled?: boolean
  fetchTriggerKey?: GlobalValueKey
  interval?: number | undefined
  isFile?: boolean
  callback?: TCallback
}

interface SubmitRequestState<TResult, TPayload> extends RequestState<TResult> {
  submit: (payload: TPayload) => Promise<void>
}

const NOT_ACCEPTABLE_STATUS = 406

export const useDataSubmit = <TPayload, TResponse>(
  input: CommonProps<TPayload, TResponse>,
): SubmitRequestState<TResponse, TPayload> => {
  const [response, submit] = useInternalFetcher({ ...input, enabled: false })

  const submitResponse = useMemo(
    () => ({
      ...response,
      submit,
    }),
    [response, submit],
  )

  return submitResponse
}

export const useDataFetcher = <TPayload, TResponse>(
  input: FetcherProps<TPayload, TResponse>,
): RequestState<TResponse> => {
  const [response] = useInternalFetcher(input)

  return response
}
export const useDocumentFetcher = <TPayload, TResponse>(
  input: FetcherProps<TPayload, TResponse>,
) => {
  const [response, fetchDocument] = useInternalFetcher({
    ...input,
    enabled: false,
    isFile: true,
  })

  return { response, fetchDocument }
}

const useInternalFetcher = <TPayload, TResponse>({
  fetcher,
  payload,
  enabled = true,
  isFile = false,
  callback,
  fetchTriggerKey = GlobalValueKey.NO_FETCH_TRIGGER,
  interval,
}: FetcherProps<TPayload, TResponse>): [
  RequestState<TResponse>,
  (payload: TPayload) => Promise<void>,
] => {
  const routerPush = useRouterPush()
  const [fetchTrigger] = useGlobalValue(fetchTriggerKey, false)
  const [, setResponseErrorMessage] = useResponseErrorMessage()
  const [, setResponseFormFieldErrorMessages] =
    useResponseFormFieldErrorMessages()
  const [state, setState] = useState<RequestState<TResponse>>({
    isLoading: enabled,
  })

  const handleError = useCallback(
    async (error: ErrorResponse) => {
      const { status, message, errors } = error

      if (toNumber(status) === NOT_ACCEPTABLE_STATUS) {
        await routerPush(
          `/auth/login?message=${message}&status=${NOT_ACCEPTABLE_STATUS}`,
          '/auth/login',
        )
      } else {
        setResponseErrorMessage(message)
        setResponseFormFieldErrorMessages(errors)
        setState({ isLoading: false, error })
      }
    },
    [routerPush, setResponseErrorMessage, setResponseFormFieldErrorMessages],
  )

  const doFetch = useCallback(
    async (_payload: TPayload | undefined) => {
      try {
        setState((prev) => ({ ...prev, isLoading: true }))
        setResponseErrorMessage(null)
        setResponseFormFieldErrorMessages(null)

        const response = await fetcher(_payload ?? payload ?? ({} as TPayload))

        if (hasErrors(response)) {
          await handleError(response as ErrorResponse)
        } else {
          setState({ isLoading: false, result: response as TResponse })
          isFile &&
            (callback
              ? callback(response as TResponse)
              : displayPdf(response as BlobPart))
        }
      } catch (error) {
        const { status = '500', message = '', errors = [] } = error as never
        await handleError({ status, message, errors })
      }
    },
    [
      fetcher,
      handleError,
      isFile,
      payload,
      callback,
      setResponseErrorMessage,
      setResponseFormFieldErrorMessages,
    ],
  )

  useEffect(() => {
    if (enabled) {
      ;(async () => {
        await doFetch(payload as TPayload)
      })()
    }

    if (interval) {
      const intervalId: NodeJS.Timeout = setInterval(async () => {
        await doFetch(payload as TPayload)
      }, interval)
      return () => clearInterval(intervalId)
    }
  }, [doFetch, enabled, fetchTrigger, interval, payload])

  return [state, doFetch]
}
