import { SVGProps } from 'react'

import classes from './eplix-logo.module.scss'

export const EPlixLogoShort = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41173 20.0931C7.23703 21.5198 8.77958 22.4796 10.5463 22.4796H14.524C15.842 22.4796 16.9105 23.5481 16.9105 24.8662C16.9105 26.1843 15.842 27.2528 14.524 27.2528H10.5463C5.27403 27.2528 1 22.9788 1 17.7065C1 12.4342 5.27403 8.16016 10.5463 8.16016C15.7854 8.16016 20.0388 12.3806 20.0921 17.607H20.0906C20.092 17.64 20.0926 17.6732 20.0926 17.7065C20.0926 19.0246 19.0241 20.0931 17.7061 20.0931H6.41173ZM6.41173 15.3199C7.23703 13.8932 8.77958 12.9333 10.5463 12.9333C12.3131 12.9333 13.8556 13.8932 14.6809 15.3199H6.41173Z"
        fill="#3A85F8"
        className={classes.eplixLogoBlue}
      />
    </svg>
  )
}
