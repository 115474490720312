import { FC } from 'react'
import { IconProps } from './types'

export const HelpIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12.2582 8.02489C11.7927 7.94504 11.314 8.03252 10.9068 8.27184C10.4996 8.51115 10.1902 8.88686 10.0335 9.33242C9.85018 9.8534 9.27926 10.1272 8.75827 9.94391C8.23728 9.76063 7.96351 9.18972 8.14678 8.66873C8.46025 7.77762 9.07898 7.0262 9.89339 6.54757C10.7078 6.06894 11.6653 5.89398 12.5963 6.05368C13.5274 6.21338 14.3719 6.69743 14.9802 7.4201C15.5884 8.14262 15.9214 9.05705 15.9201 10.0015C15.9197 11.5318 14.7851 12.5425 13.9748 13.0826C13.5392 13.373 13.1107 13.5866 12.795 13.7269C12.6358 13.7977 12.5016 13.8514 12.405 13.8882L12.2363 13.9493C11.7124 14.1239 11.1461 13.8407 10.9714 13.3168C10.797 12.7936 11.0791 12.2282 11.6016 12.0527L11.693 12.0192C11.7605 11.9935 11.8607 11.9535 11.9827 11.8993C12.2296 11.7896 12.551 11.6281 12.8654 11.4185C13.555 10.9588 13.9201 10.4697 13.9201 10.0006L13.9201 9.99908C13.9208 9.52676 13.7543 9.06944 13.4502 8.7081C13.146 8.34676 12.7238 8.10474 12.2582 8.02489ZM11.995 16C11.4455 16 11 16.4477 11 17C11 17.5523 11.4455 18 11.995 18H12.005C12.5545 18 13 17.5523 13 17C13 16.4477 12.5545 16 12.005 16H11.995Z"
        fill="currentColor"
      />
    </svg>
  )
}
