import clsx from 'clsx'
import { HTMLProps } from 'react'
import classes from './card-stack-template.module.scss'

export interface CardStackTemplateProps extends HTMLProps<HTMLDivElement> {
  bannerHeight: number
  isDrawerActive: boolean
}

export const CardStackTemplate = ({
  children,
  onScroll,
  isDrawerActive,
  bannerHeight,
  ...props
}: CardStackTemplateProps) => {
  const style = {
    height: bannerHeight > 0 ? `calc(100vh - ${bannerHeight}px)` : '100vh',
  }
  return (
    <div
      className={clsx(
        classes.cardStackContainer,
        isDrawerActive && '!overflow-hidden',
      )}
      style={style}
      onScroll={onScroll}
    >
      <div {...props} className={clsx(classes.cardStack, props.className)}>
        {children}
      </div>
    </div>
  )
}
