export const NE = {
  2000: 'Neuchâtel',
  2001: 'Neuchâtel 1',
  2002: 'Neuchâtel 2',
  2003: 'Neuchâtel 3',
  2004: 'Neuchâtel 4',
  2006: 'Neuchâtel 6',
  2007: 'Neuchâtel 7',
  2008: 'Neuchâtel',
  2009: 'Neuchâtel 9',
  2010: 'Neuchâtel OFS',
  2012: 'Auvernier',
  2013: 'Colombier NE',
  2014: 'Bôle',
  2015: 'Areuse',
  2016: 'Cortaillod',
  2017: 'Boudry',
  2019: 'Chambrelien',
  2022: 'Bevaix',
  2023: 'Gorgier',
  2024: 'St-Aubin-Sauges',
  2025: 'Chez-le-Bart',
  2027: 'Montalchez',
  2028: 'Vaumarcus',
  2034: 'Peseux',
  2035: 'Corcelles NE',
  2036: 'Cormondrèche',
  2037: 'Montmollin',
  2042: 'Valangin',
  2043: 'Boudevilliers',
  2046: 'Fontaines NE',
  2052: 'Fontainemelon',
  2053: 'Cernier',
  2054: 'Chézard-St-Martin',
  2056: 'Dombresson',
  2057: 'Villiers',
  2058: 'Le Pâquier NE',
  2063: 'Vilars NE',
  2065: 'Savagnier',
  2067: 'Chaumont',
  2068: 'Hauterive NE',
  2072: 'St-Blaise',
  2073: 'Enges',
  2074: 'Marin-Epagnier',
  2075: 'Thielle',
  2087: 'Cornaux NE',
  2088: 'Cressier NE',
  2103: 'Noiraigue',
  2105: 'Travers',
  2108: 'Couvet',
  2112: 'Môtiers NE',
  2113: 'Boveresse',
  2114: 'Fleurier',
  2115: 'Buttes',
  2116: 'Mont-de-Buttes',
  2117: 'La Côte-aux-Fées',
  2123: 'St-Sulpice NE',
  2124: 'Les Sagnettes',
  2126: 'Les Verrières',
  2127: 'Les Bayards',
  2149: 'Champ-du-Moulin',
  2206: 'Geneveys-Coffrane',
  2207: 'Coffrane',
  2208: 'Les Hauts-Geneveys',
  2300: 'La Chaux-de-Fonds',
  2301: 'La Chaux-de-Fonds',
  2302: 'La Chaux-de-Fonds',
  2303: 'La Chaux-de-Fonds',
  2304: 'La Chaux-de-Fonds',
  2306: 'La Chaux-de-Fonds',
  2314: 'La Sagne NE',
  2316: 'Petit-Martel',
  2318: 'Brot-Plamboz',
  2322: 'Le Crêt-du-Locle',
  2325: 'Les Planchettes',
  2400: 'Le Locle',
  2405: 'La Chaux-du-Milieu',
  2406: 'La Brévine',
  2414: 'Cerneux-Péquignot',
  2416: 'Les Brenets',
  2523: 'Lignières',
  2525: 'Le Landeron',
}
