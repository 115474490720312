import { useFormik } from 'formik'
import { useMemo } from 'react'
import { object, string } from 'yup'
import { ExtendedAddress } from './types'
import { REGEX_EXPRESSION } from '@/common'

const useAddressFormSchema = (
  enableAddressValidation: boolean,
  enableAlphanumericZipValidation: boolean,
  enableDocumentCategoryValidation: boolean,
) => {
  return useMemo(() => {
    const requiredFieldValidationMessage =
      'USM-Schemas:AddAdditionalInfoSchema.FieldRequired'
    const objectSchema = {}

    if (enableAddressValidation) {
      Object.assign(objectSchema, {
        street: string().required(requiredFieldValidationMessage),
        city: string().required(requiredFieldValidationMessage),
        country: string().required(requiredFieldValidationMessage),
      })

      if (enableAlphanumericZipValidation) {
        Object.assign(objectSchema, {
          zip: string()
            .required(requiredFieldValidationMessage)
            .max(10, 'USM-Schemas:EditInformationSchema.ZIPLength'),
        })
      } else {
        Object.assign(objectSchema, {
          zip: string()
            .required(requiredFieldValidationMessage)
            .max(10, 'USM-Schemas:EditInformationSchema.ZIPLength')
            .matches(
              REGEX_EXPRESSION.JUST_NUMBERS,
              'USM-Schemas:EditInformationSchema.NumericalOnlyZip',
            ),
        })
      }
    }

    if (enableDocumentCategoryValidation) {
      Object.assign(objectSchema, {
        documentCategory: string().required(requiredFieldValidationMessage),
      })
    }

    return object(objectSchema)
  }, [
    enableAddressValidation,
    enableAlphanumericZipValidation,
    enableDocumentCategoryValidation,
  ])
}

export const useAddressForm = (
  initialValues: ExtendedAddress,
  {
    enableAddressValidation = false,
    enableAlphanumericZipValidation = false,
    enableDocumentCategoryValidation = false,
  },
  onSubmit: (v: ExtendedAddress) => void | Promise<unknown>,
) => {
  const validationSchema = useAddressFormSchema(
    enableAddressValidation,
    enableAlphanumericZipValidation,
    enableDocumentCategoryValidation,
  )

  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange:
      enableAddressValidation || enableDocumentCategoryValidation,
    validateOnBlur: enableAddressValidation || enableDocumentCategoryValidation,
    enableReinitialize: true,
  })
}
