import { FC } from 'react'
import { IconProps } from './types'

export const KeyIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2071 1.79289C21.5976 2.18342 21.5976 2.81658 21.2071 3.20711L20.4142 4L22.3536 5.93941C22.9394 6.5252 22.9394 7.47495 22.3536 8.06074L19.5607 10.8536C18.9749 11.4394 18.0252 11.4394 17.4394 10.8536L15.5 8.91421L12.7489 11.6653C13.5356 12.7403 14 14.0659 14 15.5C14 19.0899 11.0899 22 7.5 22C3.91015 22 1 19.0899 1 15.5C1 11.9101 3.91015 9 7.5 9C8.9341 9 10.2597 9.46443 11.3347 10.2511L19.7929 1.79289C20.1834 1.40237 20.8166 1.40237 21.2071 1.79289ZM16.9142 7.5L18.5001 9.08586L20.5859 7.00008L19 5.41421L16.9142 7.5ZM7.5 11C5.01472 11 3 13.0147 3 15.5C3 17.9853 5.01472 20 7.5 20C9.98528 20 12 17.9853 12 15.5C12 13.0147 9.98528 11 7.5 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
