import { useState } from 'react'
import { TableElement } from '@/components'
import clsx from 'clsx'
import { Checkbox } from '@eplix/ui'

interface SelectableItemProps {
  rowsContentStyle?: string
  item: unknown
  onSelected: (item: unknown, isSelected: boolean) => void
  isPreselected: boolean
}

export const SelectableItem = ({
  rowsContentStyle,
  item,
  onSelected,
  isPreselected = false,
}: SelectableItemProps) => {
  const [isSelected, setIsSelected] = useState(isPreselected)

  const handleOnChange = () => {
    const toggledValue = !isSelected
    setIsSelected(toggledValue)

    onSelected(item, toggledValue)
  }

  return (
    <TableElement
      as="td"
      className={clsx(
        rowsContentStyle ?? 'pr-4 py-3 typo-regular-200',
        isPreselected && 'bg-outline',
      )}
    >
      <Checkbox
        checked={isSelected}
        disabled={isPreselected}
        className="!p-0 relative -top-0"
        onChange={handleOnChange}
      />
    </TableElement>
  )
}
