import { useCallback } from 'react'
import { GlobalValueKey } from './global-value-key'
import { useGlobalValue } from './use-global-state'

export const useGlobalFetchTrigger = (key: GlobalValueKey) => {
  const [, setValue] = useGlobalValue(key, false)
  const triggerFetch = useCallback(
    () => setValue((value: boolean) => !value),
    [setValue],
  )

  return triggerFetch
}
