import { useInitDatadog } from '@/common'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { ApplicationWrapper, EplixDialog } from '@/components'
import ZendeskConfig from '@/config/zendesk.config'
import { LANGUAGE_COOKIE_NAME } from '@/features/user-account/components/settings/application-language-panel/config'
import {
  setGlobalDateLocale,
  useIsZendeskWidgetLoaded,
  usePermissions,
  useToggleZendeskWidget,
} from '@/framework'
import { setCookies } from '@/framework/cookies/set-cookies'
import { I18nHTTPClientOptions, useI18n } from '@/framework/i18n'
import { LOCALE_PATTERN } from '@/framework/i18n/server/i18n-helpers'
import { IDProvider, SnackbarProvider } from '@eframe-ui/react'
import { ThemeProvider } from '@eplix/ui'
import 'focus-visible'
import Cookies from 'js-cookie'
import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import { SWRConfig } from 'swr'
import '../styles/global.scss'
import '../styles/reset.scss'

interface PageProps {
  i18n: I18nHTTPClientOptions
  prefetch: {
    theme: never
    currentUser: { status: UserSessionStatusEnum }
    permissions: string[]
    zendeskApiKey: string
  } /** Declared in SWRConfig value component property -> fallback config property */
}

export default function MyApp({ Component, pageProps }: AppProps<PageProps>) {
  const [, setPermissions] = usePermissions()

  useEffect(() => {
    setPermissions(pageProps?.prefetch?.permissions ?? [])
  }, [pageProps?.prefetch?.permissions, setPermissions])

  useInitDatadog()

  const [, setIsZendeskWidgetLoaded] = useIsZendeskWidgetLoaded()
  const [, setZendeskWidgetToggle] = useToggleZendeskWidget()
  const onZendeskLoaded = () => {
    setIsZendeskWidgetLoaded(true)
    ZendeskAPI('webWidget', 'setLocale', pageProps.i18n.locale || 'de_CH')
    ZendeskAPI(
      'webWidget:on',
      'userEvent',
      function (event: { action: string }) {
        if (event.action === 'Web Widget Minimised') {
          setZendeskWidgetToggle(false)
        }
      },
    )
  }

  useEffect(() => {
    if (
      LOCALE_PATTERN.exec(window.location.href) &&
      pageProps.i18n?.locale !== Cookies.get(LANGUAGE_COOKIE_NAME)
    ) {
      setCookies(LANGUAGE_COOKIE_NAME, pageProps.i18n?.locale)
    }
  }, [pageProps.i18n])

  const i18next = useI18n(
    pageProps.i18n || ({ locale: 'de_CH' } as I18nHTTPClientOptions),
  )

  useEffect(() => {
    setGlobalDateLocale(pageProps.i18n?.locale)
  }, [pageProps.i18n?.locale])
  return (
    <>
      <SWRConfig value={{ fallback: pageProps.prefetch || {} }}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <link rel="icon" type="image/ico" href="/favicon.ico" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.ico"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.ico"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <title>ePlix</title>
        </Head>
        <ThemeProvider
          className="provider"
          theme={pageProps.prefetch?.theme || 'light'}
        >
          <IDProvider>
            <I18nextProvider i18n={i18next}>
              <EplixDialog>
                <ApplicationWrapper {...pageProps}>
                  <SnackbarProvider>
                    <Component {...pageProps} />
                  </SnackbarProvider>
                </ApplicationWrapper>
              </EplixDialog>
            </I18nextProvider>
          </IDProvider>
        </ThemeProvider>
      </SWRConfig>
      <Zendesk
        defer
        zendeskKey={pageProps.prefetch.zendeskApiKey}
        {...ZendeskConfig}
        onLoaded={onZendeskLoaded}
      />
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)

  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: '/dashboard' })
    appContext.ctx.res.end()
    return
  }

  return { ...appProps }
}
