import { CustomerPersonalData, ProfileResponse } from '@/api'
import { Constants, RequestDateFormat } from '@/common/constants'
import { CivilStatusEnum, CountryEnum, WorkingStatusEnum } from '@/common/enums'
import { UtilsEnum } from '@/common/enums/UtilsEnum'
import { parseRequestDate } from '@/framework/date-time'

const getInitialValues = (personalData?: ProfileResponse) => {
  return {
    employerStatus: personalData?.employerStatus,
    street: personalData?.street ?? Constants.EmptyString,
    streetNumber: personalData?.streetNumber ?? Constants.EmptyString,
    isUsCitizen:
      personalData?.isUsCitizen !== null ? !personalData?.isUsCitizen : false,
    isCrossBorder: personalData?.isCrossBorder ?? false,
    zip: personalData?.zip ?? Constants.EmptyString,
    city: personalData?.city ?? Constants.EmptyString,
    contributionTo2ndPillar: personalData?.contributionTo2ndPillar ?? false,
    marriageDate:
      personalData?.marriageDate &&
      parseRequestDate(personalData.marriageDate, RequestDateFormat.YYYY_MM_DD),
    alias: personalData?.alias ?? personalData?.firstName,
    gender: personalData?.gender ?? Constants.EmptyString,
    country: personalData?.country ?? CountryEnum.SWITZERLAND,
    maritalStatus: personalData?.maritalStatus ?? Constants.EmptyString,
    documentCategory: personalData?.documentCategory ?? Constants.EmptyString,
    nationality:
      personalData?.nationality?.toUpperCase() ?? Constants.EmptyString,
  }
}

export const getUserPersonalDataActions = () => {
  return { getInitialValues }
}

export const shouldShowPersonalData = (personalData?: CustomerPersonalData) => {
  if (!personalData) return false

  const { has3aPortfolio, profile, hasVestedBenefitsPortfolio } = personalData

  const hasRequiredFields =
    profile?.zip &&
    profile?.city &&
    profile?.street &&
    profile?.country &&
    profile?.maritalStatus &&
    profile?.isUsCitizen !== null &&
    profile?.isUsCitizen !== undefined &&
    profile?.nationality

  let shouldShow = false

  if (has3aPortfolio) {
    shouldShow =
      !hasRequiredFields ||
      isEmployerStatusEmpty(profile?.employerStatus) ||
      isMarriageDateMissing(profile) ||
      isContributionTo2ndPillarMissing(profile)
  } else if (hasVestedBenefitsPortfolio) {
    shouldShow = !hasRequiredFields || isMarriageDateMissing(profile)
  } else if (profile?.isNeoInsured) {
    shouldShow = !hasRequiredFields
  }

  return shouldShow
}

export const shouldShowAddressForm = (personalData?: CustomerPersonalData) => {
  if (!personalData) return false

  const { profile, has3aPortfolio, hasVestedBenefitsPortfolio } = personalData
  const hasRequiredFields =
    profile?.zip && profile?.street && profile?.city && profile.country

  if (!has3aPortfolio && !hasVestedBenefitsPortfolio && !profile.isNeoInsured) {
    return !hasRequiredFields
  }
}

const isEmployerStatusEmpty = (employerStatus?: string) =>
  employerStatus === null || employerStatus === UtilsEnum.UNKNOWN

const isMarriageDateMissing = (profile?: ProfileResponse) =>
  (profile?.maritalStatus === CivilStatusEnum.MARRIED ||
    profile?.maritalStatus === CivilStatusEnum.REGISTERED_PARTNERSHIP) &&
  profile?.marriageDate === null

const isContributionTo2ndPillarMissing = (profile?: ProfileResponse) =>
  profile?.employerStatus !== null &&
  profile?.employerStatus !== UtilsEnum.UNKNOWN &&
  profile?.employerStatus !== WorkingStatusEnum.RETIRED &&
  profile?.contributionTo2ndPillar === null
