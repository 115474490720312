import { CivilStatusEnum, CountryEnum } from '@/common/enums'
import { alphanumericZipCountries } from '@/common/constants/alphanumeric-zip-countries'
import { crossBorderCountries } from '@/common'
import { PersonalDocumentsEnum } from '../enums/PersonalDocumentsHintEnum'
import { ProfileResponse } from '@/api/eplix'

const getIsAlphanumericZipCountry = (country: string | undefined) =>
  alphanumericZipCountries.includes(country as CountryEnum)

const getIsCrossBorderCountry = (country: string | undefined) =>
  crossBorderCountries.includes(country as CountryEnum)

const isMarried = (status: CivilStatusEnum | string | undefined) =>
  status === CivilStatusEnum.MARRIED ||
  status === CivilStatusEnum.REGISTERED_PARTNERSHIP

const getShouldAskForDocumentCategory = (
  documentType: string,
  issuingCountry: string,
) => {
  const isResidencePermit = getIsResidencePermit(documentType)
  return isResidencePermit && issuingCountry === CountryEnum.SWITZERLAND
}

const getIsResidencePermit = (documentType: string) => {
  return documentType === PersonalDocumentsEnum.RESIDENCE_PERMIT
}

const getShouldShowDocumentCategory = (
  country: string,
  isCrossBorder: boolean,
  nationality?: string,
  documentCategory?: string,
) => {
  const isSwissNationalityUser = getIsSwissNationalityUser(nationality)
  const isCrossBorderOrSwissUser = getIsCrossBorderOrSwissUser(
    isCrossBorder,
    country,
  )
  return (
    !documentCategory && !isSwissNationalityUser && isCrossBorderOrSwissUser
  )
}

const getIsNotCrossBorderAndIsNotSwissUser = (country: string) => {
  return (
    !getIsCrossBorderCountry(country) && !getIsSwissLivingCountryUser(country)
  )
}

const getIsSwissNationalityUser = (nationality: string) => {
  return nationality === CountryEnum.SWITZERLAND
}

const getIsSwissLivingCountryUser = (country: string) => {
  return country === CountryEnum.SWITZERLAND
}

const getIsCrossBorderOrSwissUser = (
  isCrossBorder: boolean,
  country: string,
) => {
  return isCrossBorder || country === CountryEnum.SWITZERLAND
}

const getHasAddress = (information: ProfileResponse) => {
  return (
    !!information?.city &&
    !!information?.zip &&
    !!information?.street &&
    !!information?.country
  )
}

export const getCommonUserDataActions = () => {
  return {
    isMarried,
    getHasAddress,
    getIsResidencePermit,
    getIsCrossBorderCountry,
    getIsSwissNationalityUser,
    getIsAlphanumericZipCountry,
    getIsSwissLivingCountryUser,
    getIsCrossBorderOrSwissUser,
    getShouldShowDocumentCategory,
    getShouldAskForDocumentCategory,
    getIsNotCrossBorderAndIsNotSwissUser,
  }
}
