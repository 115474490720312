import { ForwardedRef, forwardRef, useState } from 'react'
import clsx from 'clsx'
import { EyeCloseIcon, EyeOpenIcon } from '../icons'
import { TextField, TextFieldProps } from './text-field'
import { Button } from '../buttons'

import classes from './password-field.module.scss'

interface TogglePasswordVisibilityButtonProps {
  isVisible?: boolean
  toggleButtonTitle: string
  onToggle: (v: boolean) => void
  disabled?: boolean
}

const TogglePasswordVisibilityButton = ({
  isVisible,
  toggleButtonTitle,
  onToggle,
  disabled,
}: TogglePasswordVisibilityButtonProps) => {
  return (
    <Button
      variant="text"
      name="toggle_password_visibility"
      type="button"
      title={toggleButtonTitle}
      className={classes.toggleVisibilityButton}
      onClick={(e) => (!disabled ? onToggle(!isVisible) : e?.preventDefault)}
      endIcon={
        <>
          <EyeCloseIcon
            iconSize={24}
            className={clsx(
              classes.toggleIcon,
              !isVisible && classes.iconHidden,
              disabled && 'text-text-disabled',
            )}
          />
          <EyeOpenIcon
            iconSize={24}
            className={clsx(
              classes.toggleIcon,
              isVisible && classes.iconHidden,
              disabled && 'text-text-disabled',
            )}
          />
        </>
      }
    />
  )
}

export const PasswordField = forwardRef(
  (
    {
      toggleButtonTitle = 'Toggle password visibility',
      disabled,
      ...props
    }: Omit<TextFieldProps, 'type'> & { toggleButtonTitle?: string },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [isPasswordVisible, setPasswordVisibility] = useState(false)
    return (
      <TextField
        {...props}
        type={isPasswordVisible ? 'text' : 'password'}
        ref={ref}
        endAdornment={
          <TogglePasswordVisibilityButton
            disabled={disabled}
            toggleButtonTitle={toggleButtonTitle}
            isVisible={isPasswordVisible}
            onToggle={setPasswordVisibility}
          />
        }
      />
    )
  },
)
