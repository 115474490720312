/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:05:23.

export enum RegistrationRequestStatusEnum {
  RESEND = 'RESEND',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  REGISTERED = 'REGISTERED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
