import { DocumentNeoTypeEnum } from '@/common/enums/DocumentNeoTypeEnum'

export const MORE_INFORMATION = {
  code: 'MYP-PensionFund:FinancingPanel.MoreInfo',
  default: 'More information',
}

export const TAX_CERTIFICATE_HISTORY = {
  actual: false,
  documentType: DocumentNeoTypeEnum.TAX_CERTIFICATE,
}

export const PENSION_ATTESTATION_HISTORY = {
  actual: false,
  documentType: DocumentNeoTypeEnum.PENSION_ATTESTATION,
}

export const ACTIVE_PENSION_ATTESTATION = {
  actual: true,
  documentType: DocumentNeoTypeEnum.PENSION_ATTESTATION,
}

export const ACTIVE_PENSION_CERTIFICATE = {
  actual: true,
  documentType: DocumentNeoTypeEnum.PENSION_CERTIFICATE,
}

export const ACTIVE_EXIT_CERTIFICATE = {
  actual: true,
  documentType: DocumentNeoTypeEnum.EXIT_CERTIFICATE,
}

export const PENSION_CERTIFICATE_HISTORY = {
  actual: false,
  documentType: DocumentNeoTypeEnum.PENSION_CERTIFICATE,
}
