export const GR = {
  6534: 'S. Vittore',
  6535: 'Roveredo GR',
  6537: 'Grono',
  6538: 'Verdabbio',
  6540: 'Castaneda',
  6541: 'Sta. Maria',
  6542: 'Buseno',
  6543: 'Arvigo',
  6544: 'Braggio',
  6545: 'Selma',
  6546: 'Cauco',
  6547: 'Augio',
  6548: 'Rossa',
  6549: 'Laura',
  6556: 'Leggia',
  6557: 'Cama',
  6558: 'Lostallo',
  6562: 'Soazza',
  6563: 'Mesocco',
  6565: 'S. Bernardino',
  7000: 'Chur',
  7001: 'Chur',
  7002: 'Chur',
  7003: 'Chur Postauto',
  7004: 'Chur',
  7006: 'Chur',
  7007: 'Chur',
  7012: 'Felsberg',
  7013: 'Domat/Ems',
  7014: 'Trin',
  7015: 'Tamins',
  7016: 'Trin Mulin',
  7017: 'Flims Dorf',
  7018: 'Flims Waldhaus',
  7019: 'Fidaz',
  7023: 'Haldenstein',
  7026: 'Maladers',
  7027: 'Lüen',
  7028: 'St. Peter',
  7029: 'Peist',
  7031: 'Laax GR',
  7032: 'Laax GR 2',
  7050: 'Arosa',
  7056: 'Molinis',
  7057: 'Langwies',
  7058: 'Litzirüti',
  7062: 'Passugg-Araschgen',
  7063: 'Praden',
  7064: 'Tschiertschen',
  7074: 'Malix',
  7075: 'Churwalden',
  7076: 'Parpan',
  7077: 'Valbella',
  7078: 'Lenzerheide/Lai',
  7082: 'Vaz/Obervaz',
  7083: 'Lantsch/Lenz',
  7084: 'Brienz/Brinzauls',
  7104: 'Versam',
  7106: 'Tenna',
  7107: 'Safien Platz',
  7109: 'Thalkirch',
  7110: 'Peiden',
  7111: 'Pitasch',
  7112: 'Duvin',
  7113: 'Camuns',
  7114: 'Uors (Lumnezia)',
  7115: 'Surcasti',
  7116: 'Tersnaus',
  7122: 'Valendas',
  7126: 'Castrisch',
  7127: 'Sevgein',
  7128: 'Riein',
  7130: 'Ilanz',
  7132: 'Vals',
  7133: 'Obersaxen Affeier',
  7134: 'Obersaxen Meierhof',
  7135: 'Obersaxen Giraniga',
  7136: 'Obers Friggahüs',
  7137: 'Flond',
  7138: 'Surcuolm',
  7141: 'Luven',
  7142: 'Cumbel',
  7143: 'Morissen',
  7144: 'Vella',
  7145: 'Degen',
  7146: 'Vattiz',
  7147: 'Vignogn',
  7148: 'Lumbrein',
  7149: 'Vrin',
  7151: 'Schluein',
  7152: 'Sagogn',
  7153: 'Falera',
  7154: 'Ruschein',
  7155: 'Ladir',
  7156: 'Pigniu',
  7157: 'Siat',
  7158: 'Waltensburg/Vuorz',
  7159: 'Andiast',
  7162: 'Tavanasa',
  7163: 'Danis',
  7164: 'Dardin',
  7165: 'Breil/Brigels',
  7166: 'Trun',
  7167: 'Zignau',
  7168: 'Schlans',
  7172: 'Rabius',
  7173: 'Surrein',
  7174: 'S. Benedetg',
  7175: 'Sumvitg',
  7176: 'Cumpadials',
  7180: 'Disentis/Mustér',
  7182: 'Cavardiras',
  7183: 'Mompé Medel',
  7184: 'Curaglia',
  7185: 'Platta',
  7186: 'Segnas',
  7187: 'Camischolas',
  7188: 'Sedrun',
  7189: 'Rueras',
  7201: 'Untervaz Bahnhof',
  7202: 'Says',
  7203: 'Trimmis',
  7204: 'Untervaz',
  7205: 'Zizers',
  7206: 'Igis',
  7208: 'Malans GR',
  7212: 'Seewis Dorf',
  7213: 'Valzeina',
  7214: 'Grüsch',
  7215: 'Fanas',
  7220: 'Schiers',
  7222: 'Mittellunden',
  7223: 'Buchen',
  7224: 'Putz',
  7226: 'Stels',
  7228: 'Schuders',
  7231: 'Pragg-Jenaz',
  7232: 'Furna',
  7233: 'Jenaz',
  7235: 'Fideris',
  7240: 'Küblis',
  7241: 'Conters',
  7242: 'Luzein',
  7243: 'Pany',
  7244: 'Gadenstätt',
  7245: 'Ascharina',
  7246: 'St. Antönien',
  7247: 'Saas im Prättigau',
  7249: 'Serneus',
  7250: 'Klosters',
  7252: 'Klosters Dorf',
  7260: 'Davos Dorf',
  7265: 'Davos Wolfgang',
  7270: 'Davos Platz',
  7272: 'Davos Clavadel',
  7276: 'Davos Frauenkirch',
  7277: 'Davos Glaris',
  7278: 'Davos Monstein',
  7302: 'Landquart',
  7303: 'Mastrils',
  7304: 'Maienfeld',
  7306: 'Fläsch',
  7307: 'Jenins',
  7402: 'Bonaduz',
  7403: 'Rhäzüns',
  7404: 'Feldis/Veulden',
  7405: 'Rothenbrunnen',
  7407: 'Trans',
  7408: 'Cazis',
  7411: 'Sils im Domleschg',
  7412: 'Scharans',
  7413: 'Fürstenaubruck',
  7414: 'Fürstenau',
  7415: 'Rodels',
  7416: 'Almens',
  7417: 'Paspels',
  7418: 'Tumegl/Tomils',
  7419: 'Scheid',
  7421: 'Summaprada',
  7422: 'Tartar',
  7423: 'Sarn',
  7424: 'Präz',
  7425: 'Masein',
  7426: 'Flerden',
  7427: 'Urmein',
  7428: 'Tschappina',
  7430: 'Thusis',
  7431: 'Mutten',
  7432: 'Zillis',
  7433: 'Donat',
  7434: 'Sufers',
  7435: 'Splügen',
  7436: 'Medels',
  7437: 'Nufenen',
  7438: 'Hinterrhein',
  7440: 'Andeer',
  7442: 'Clugin',
  7443: 'Pignia',
  7444: 'Ausserferrera',
  7445: 'Innerferrera',
  7446: 'Campsut-Cröt',
  7447: 'Cresta (Avers)',
  7448: 'Juf',
  7450: 'Tiefencastel',
  7451: 'Alvaschein',
  7452: 'Cunter',
  7453: 'Tinizong',
  7454: 'Rona',
  7455: 'Mulegns',
  7456: 'Sur',
  7457: 'Bivio',
  7458: 'Mon',
  7459: 'Stierva',
  7460: 'Savognin',
  7462: 'Salouf',
  7463: 'Riom',
  7464: 'Parsonz',
  7472: 'Surava',
  7473: 'Alvaneu Bad',
  7477: 'Filisur',
  7482: 'Bergün/Bravuogn',
  7484: 'Latsch',
  7492: 'Alvaneu Dorf',
  7493: 'Schmitten (Albula)',
  7494: 'Wiesen GR',
  7500: 'St. Moritz',
  7502: 'Bever',
  7503: 'Samedan',
  7504: 'Pontresina',
  7505: 'Celerina',
  7512: 'Champfèr',
  7513: 'Silvaplana',
  7514: 'Sils/Segl Maria',
  7515: 'Sils/Segl Baselgia',
  7516: 'Maloja',
  7517: 'Plaun da Lej',
  7522: 'La Punt-Chamues-ch',
  7523: 'Madulain',
  7524: 'Zuoz',
  7525: 'S-chanf',
  7526: 'Cinuos-chel',
  7527: 'Brail',
  7530: 'Zernez',
  7532: 'Tschierv',
  7533: 'Fuldera',
  7534: 'Lü',
  7535: 'Valchava',
  7536: 'Sta. Maria V. M.',
  7537: 'Müstair',
  7542: 'Susch',
  7543: 'Lavin',
  7545: 'Guarda',
  7546: 'Ardez',
  7550: 'Scuol',
  7551: 'Ftan',
  7552: 'Vulpera',
  7553: 'Tarasp',
  7554: 'Sent',
  7556: 'Ramosch',
  7557: 'Vnà',
  7558: 'Strada',
  7559: 'Tschlin',
  7560: 'Martina',
  7562: 'Samnaun-Compatsch',
  7563: 'Samnaun Dorf',
  7602: 'Casaccia',
  7603: 'Vicosoprano',
  7604: 'Borgonovo',
  7605: 'Stampa',
  7606: 'Promontogno',
  7608: 'Castasegna',
  7610: 'Soglio',
  7710: 'Ospizio Bernina',
  7741: 'S. Carlo GR',
  7742: 'Poschiavo',
  7743: 'Brusio',
  7744: 'Campocologno',
  7745: 'Li Curt',
  7746: 'Le Prese',
  7747: 'Viano',
  7748: 'Campascio',
}
