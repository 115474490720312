import clsx from 'clsx'
import { forwardRef, ForwardedRef, HTMLProps, memo } from 'react'
import classes from './switch.module.scss'

interface SwitchProps extends HTMLProps<HTMLInputElement> {
  disabled?: boolean
  sliderClassName?: string
}

export const Switch = memo(
  forwardRef(
    (
      { onChange, disabled, sliderClassName, ...props }: SwitchProps,
      ref: ForwardedRef<HTMLInputElement>,
    ) => {
      return (
        <label className={classes.inputWrapper}>
          <input
            className={clsx(classes.inputCheck, props.className)}
            disabled={disabled}
            type="checkbox"
            onChange={onChange}
            {...props}
            ref={ref}
            checked={props.checked}
            data-cy={props.name}
          />
          <div className={clsx(classes.slider, sliderClassName)} />
        </label>
      )
    },
  ),
)
