import { NextRouter } from 'next/router'

/**
 * Matches a string that ends with `[<queryKey>]`, `[...<queryKey>]` or `[[...<queryKey>]]`.
 */
const getDynamicPageLeafExp = (queryKey = 'path') => {
  return new RegExp(`\\/\\[\\[?(\\.\\.\\.)?${queryKey}\\]?\\]$`)
}

const NOT_READY_PATH: ClientPath = [null, null]

/**
 * A tuple `[path: string, baseUrl: string]` representing the current route state of a Next.js page that takes dynamic subroutes.
 */
export type ClientPath = [path: string | null, baseUrl: string | null]

/**
 * Derives a tuple `[path: string, baseUrl: string]` from a `NextRouter` instance and a `queryKey` parameter.
 *
 * Example: `getClientPath(router, 'foo')` is valid when called in a
 * * ... single param dynamic page `[foo].tsx`.
 * * ... dynamic page catching rest URL params `[...foo].tsx`.
 * * ... dynamic page with catch-all URL params `[[...foo]].tsx`.
 *
 * @throws
 * * This will throw if called within the context of a Next.js page that is _not_ a [dynamic route leaf](https://nextjs.org/docs/routing/dynamic-routes)
 * * This will throw if called within the context of a Next.js page that, even if dynamic, doesn't expose its dynamic URL parts at `queryKey`.
 *
 * @returns A tuple `[path: string, baseUrl: string]`
 */
export function getClientPath(
  router: NextRouter,
  queryKey = 'path',
): ClientPath {
  // On a static dynamic Next.js page, the Next.js router will be empty on mount.
  if (!router.isReady) {
    console.warn('Next.js router is not ready yet.')
    return NOT_READY_PATH
  }

  // Check the current Next.js router pathname: It should end with some kind of dynamic route: `[param]`, `[...rest]` or `[[...catchAll]]`.
  const baseUrlExp = getDynamicPageLeafExp(queryKey)
  if (!baseUrlExp.test(router.pathname)) {
    return [null, null]
  }

  // From the sanitized Next.js router pathname, remove the dynamic part.
  const basePath = router.pathname.replace(baseUrlExp, '')
  // From the current actual URL, remove the parts which are covered by basePath.
  const baseUrl = router.asPath
    .split('/')
    .splice(0, basePath.split('/').length)
    .join('/')

  // Get the client route path parts from the respective namespace.
  const rawPath = router.query[queryKey]
  // If they don't exist, return a root tuple.
  if (!rawPath) {
    return ['/', baseUrl]
  }
  // As Next.js `router.query` can be of type string or string[], check and act accordingly.
  const path = Array.isArray(rawPath) ? rawPath.join('/') : rawPath
  return [`/${path}`, baseUrl]
}
