import { FC } from 'react'
import { IconProps } from './types'

export const InsuranceIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4472 1.10557C12.1657 0.964809 11.8343 0.964809 11.5528 1.10557C11.1596 1.30215 10.6833 1.61624 10.2056 1.93126L10.2056 1.93127L10.2054 1.93137C10.1146 1.99122 10.0238 2.0511 9.93354 2.11021C9.33403 2.50267 8.67373 2.9201 7.95279 3.28057C6.50662 4.00366 4.91472 4.4479 3.24254 4.02986C2.94379 3.95517 2.6273 4.02229 2.38459 4.21179C2.14187 4.4013 2 4.69206 2 5C2 10.565 2.75391 14.5224 4.38176 17.3711C6.03822 20.2699 8.52576 21.896 11.6838 22.9487C11.889 23.0171 12.111 23.0171 12.3162 22.9487C15.4742 21.896 17.9618 20.2699 19.6182 17.3711C21.2461 14.5224 22 10.565 22 5C22 4.70041 21.8657 4.4166 21.634 4.22666C21.4023 4.03672 21.0977 3.96066 20.8039 4.01942C18.571 4.46599 16.9583 3.97936 15.7028 3.29652C15.059 2.94637 14.5021 2.54061 13.9789 2.14744C13.9272 2.10864 13.875 2.06915 13.8221 2.02925L13.8217 2.02892L13.8217 2.02891C13.3779 1.69377 12.8967 1.3303 12.4472 1.10557ZM11.3376 3.58085C11.5857 3.41761 11.7989 3.27732 11.9851 3.16075C12.1712 3.29033 12.3881 3.45346 12.654 3.65352L12.654 3.65353L12.7774 3.74631C13.3104 4.14689 13.966 4.62863 14.7472 5.05348C16.1009 5.78967 17.8097 6.34295 19.9882 6.15463C19.8883 10.9436 19.1542 14.152 17.8818 16.3789C16.5886 18.6419 14.6757 19.9996 12 20.9429C9.32425 19.9996 7.4114 18.6419 6.11824 16.3789C4.84728 14.1547 4.11345 10.9513 4.01213 6.17184C5.84175 6.30632 7.49608 5.74499 8.84721 5.06943C9.67627 4.6549 10.416 4.18483 11.029 3.78354C11.1375 3.71247 11.2402 3.6449 11.3376 3.58085ZM16.7372 9.67573C17.1104 9.26861 17.0828 8.63604 16.6757 8.26285C16.2686 7.88966 15.636 7.91716 15.2629 8.32428L10.5 13.5201L8.73716 11.597C8.36397 11.1899 7.7314 11.1624 7.32428 11.5356C6.91716 11.9088 6.88966 12.5413 7.26285 12.9485L9.76285 15.6757C9.95226 15.8824 10.2197 16 10.5 16C10.7803 16 11.0478 15.8824 11.2372 15.6757L16.7372 9.67573Z"
        fill="currentColor"
      />
    </svg>
  )
}
