/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 11:54:03.

export enum UserTypeEnum {
  MFS = 'MFS',
  EASY_TEMP = 'EASY_TEMP',
  MY_TELLCO_TELLCO = 'MY_TELLCO_TELLCO',
  MY_TELLCO_PIGUET_GALLAND = 'MY_TELLCO_PIGUET_GALLAND',
  PG_MFS = 'PG_MFS',
  ABACUS_INDUNI = 'ABACUS_INDUNI',
  MY_TELLCO_ALKIMIA = 'MY_TELLCO_ALKIMIA',
}

export enum ExtendedUserTypeEnum {
  TELLCO = 'TELLCO',
  UNKNOWN = 'UNKNOWN',
}

//TODO: IRA - Move to a better place
export const hasWritePersmission = (userGroup: string) => {
  return (
    userGroup === UserTypeEnum.MY_TELLCO_ALKIMIA ||
    userGroup === UserTypeEnum.MY_TELLCO_PIGUET_GALLAND
  )
}
