import clsx from 'clsx'
import {
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  Children,
  cloneElement,
} from 'react'

import classes from './table.module.scss'
import { TableRow } from './table-row'
import { TableRowGroup } from './table-row-group'

export const TableHeader = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <TableRowGroup {...props} className={props.className} ref={ref}>
      <TableRow className={clsx(classes.row, classes.headerRow)} role="row">
        {Children.map(children, (child) =>
          cloneElement(child as JSX.Element, {
            role: 'columnheader',
          }),
        )}
      </TableRow>
    </TableRowGroup>
  )
})
