import { FC } from 'react'
import { IconProps } from './types'

export const ArrowRightIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289C10.9024 3.68342 10.9024 4.31658 11.2929 4.70711L17.5858 11H3V13H17.5858L11.2929 19.2929C10.9024 19.6834 10.9024 20.3166 11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071L20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929L12.7071 3.29289Z"
        fill="currentColor"
      />
    </svg>
  )
}
