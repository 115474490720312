import { useCallback, useEffect, useRef, useState } from 'react'
import { PortfolioWizardFormsEnum } from '../types'

/**
 * Defines data that should be passed to the wizard
 */
export interface WizardStep {
  label: string
  onSubmit?: () => void
  onBack?: () => void
}

/**
 * Defines data that wizard uses in order to handle navigation
 */
export interface WizardState {
  current: WizardStep
  index: number
  displayIndex: number | null
  formName: string | null
}

/**
 * Defines data that should be passed to every component in wizard
 */
export interface WizardProps {
  handleBack: () => void
  state: WizardState
  handleNext: () => void
  totalDisplaySteps: number
  setStateByIndex: (stateIndex: number) => void
  setStateByFormName: (name: string) => void
}

const getState = (
  config: WizardStep,
  index: number,
  formNameMap: Record<string, number>,
) => ({
  current: { ...config },
  index,
  displayIndex: index + 1,
  formName: Object.keys(formNameMap)[Object.values(formNameMap)[index]],
})
const createStatesFromSteps = (
  steps: WizardStep[],
  formNameMap: Record<string, number>,
) => steps.map((config, index) => getState(config, index, formNameMap))
const createStatesFromForms = (
  length: number,
  formNameMap: Record<string, number>,
) =>
  Array.from({ length }, (_, index) =>
    getState({ label: '' }, index, formNameMap),
  )

/**
 * Wizard hook that handles navigation and defines interface that child forms must implement in order to be part of the wizard.
 *
 * @param numberOfSteps - number of children Wizard is wrapping, used when steps are not passed as a parameter
 * @param skipDisplayIndex - index of children that will not to be included in the step count, starting from 0
 * @param steps - list of steps that should be followed respectively
 * @returns current state and dispatch function for going back and forth in wizard
 */
export default function useWizard(
  numberOfSteps: number,
  formNameMap: Record<string, number>,
  skipDisplayIndex?: Array<number>,
  steps?: WizardStep[],
  onWizardStepChange?: (step: PortfolioWizardFormsEnum) => void,
  startFromIndex = 0,
) {
  const _states: WizardState[] = steps
    ? createStatesFromSteps(steps, formNameMap)
    : createStatesFromForms(numberOfSteps, formNameMap)

  if (skipDisplayIndex) {
    skipDisplayIndex.forEach((skipIndex) => {
      if (_states[skipIndex]) {
        _states[skipIndex].displayIndex = null
      }

      for (let i = skipIndex + 1; i < _states.length; i++) {
        const state = _states[i]

        if (state.displayIndex) {
          state.displayIndex--
        }
      }
    })
  }

  const states = useRef(_states)

  const totalDisplaySteps = states.current.filter(
    (state) => state.displayIndex,
  ).length
  const [state, setState] = useState(states.current[startFromIndex])

  useEffect(() => {
    setState(states.current[startFromIndex])
  }, [startFromIndex])

  const changeState = useCallback(
    (newState: WizardState) => {
      onWizardStepChange &&
        newState.formName &&
        onWizardStepChange(newState.formName as PortfolioWizardFormsEnum)
      setState(newState)
    },
    [onWizardStepChange],
  )

  const handleNext = useCallback(() => {
    if (state.index < numberOfSteps - 1) {
      states && changeState(states.current[state.index + 1])
    }
  }, [changeState, numberOfSteps, state.index])

  const handleBack = useCallback(() => {
    if (state.index > 0) {
      states && changeState(states.current[state.index - 1])
    }
  }, [changeState, state.index])

  const setStateByIndex = (stateIndex: number) => {
    if (stateIndex <= numberOfSteps - 1 && stateIndex >= 0) {
      states && changeState(states.current[stateIndex])
    }
  }

  const [formName, setFormName] = useState('')

  if (formName) {
    const index = formNameMap[formName]
    if (state.index !== index) {
      changeState(states.current[index])
    }
  }

  const setStateByFormName = (name: string) => {
    setFormName(name)
  }

  return {
    handleBack,
    state,
    handleNext,
    totalDisplaySteps,
    setStateByIndex,
    setStateByFormName,
  } as WizardProps
}
