const SliderDotValue = ({ value, color }: { value: number; color: string }) => {
  if (!value) {
    return null
  }

  return (
    <div
      className={`absolute grow top-0 pointer-events-none flex flex-row h-2 w-2 -ml-1 mt-1 rounded-full`}
      style={{ left: `${value}%`, backgroundColor: color, zIndex: 1 }}
    ></div>
  )
}

export default SliderDotValue
