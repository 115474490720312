import { PortfolioDetails, PortfolioEditInformation } from '@/api'
import { useCallback, useEffect, useMemo } from 'react'
import { useSetPendingOrders } from '@/features/portfolio/hooks/client/use-pending-orders'
import { useDataFetcher } from '@/common'
import { isNumber } from 'lodash'
import { GlobalValueKey } from '@/framework'
import {
  getEditInformation,
  getPortfolio,
} from '@/features/portfolio/lib/overview-api-client'
import { asyncCall } from '@/common/helpers/async-wrapper'

interface PortfolioDetailsProps {
  selectedPortfolioId: number | null
}

export const usePortfolioDetails = ({
  selectedPortfolioId,
}: PortfolioDetailsProps) => {
  const payload = useMemo(
    () => ({ portfolioId: selectedPortfolioId as number }),
    [selectedPortfolioId],
  )

  const hasSelectedPortfolio =
    isNumber(selectedPortfolioId) && selectedPortfolioId !== -1

  const { result: portfolioResult, isLoading } = useDataFetcher({
    fetcher: getPortfolio,
    enabled: hasSelectedPortfolio,
    payload: payload,
    fetchTriggerKey: GlobalValueKey.FETCH_TRIGGER_PORTFOLIO_UPDATED,
  })

  const setPendingOrders = useSetPendingOrders()

  const { result: editInformationResult } = useDataFetcher({
    fetcher: getEditInformation,
    enabled: hasSelectedPortfolio,
    payload: payload,
    fetchTriggerKey: GlobalValueKey.FETCH_TRIGGER_PORTFOLIO_UPDATED,
  })
  const pendingOrdersStateSet = useCallback(async () => {
    if (portfolioResult) {
      await setPendingOrders(
        portfolioResult.investmentAccount
          ? portfolioResult.investmentAccount.pendingOrders
          : [],
      )
    }
  }, [portfolioResult, setPendingOrders])

  useEffect(() => {
    asyncCall(pendingOrdersStateSet)
  }, [pendingOrdersStateSet])

  return [portfolioResult ?? {}, editInformationResult ?? {}, isLoading] as [
    PortfolioDetails,
    PortfolioEditInformation,
    boolean,
  ]
}
