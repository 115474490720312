import { createContext, useContext } from 'react'
import { ClientPath } from '../lib/get-client-path'
import { useWithTransition } from '@/framework/client-router/hooks/use-with-transition'

export const ClientRouterContext = createContext<ClientPath>(['/', ''])

export const useClientRouter = () => {
  const [path, basePath] = useContext(ClientRouterContext)
  const memoizedPath = useWithTransition(path)
  return [memoizedPath, basePath]
}
