import { FC } from 'react'
import { IconProps } from './types'

export const ShareIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6.51172C14 4.57872 15.567 3.01172 17.5 3.01172C19.433 3.01172 21 4.57872 21 6.51172C21 8.44472 19.433 10.0117 17.5 10.0117C16.4115 10.0117 15.4391 9.51484 14.7972 8.73553L10.798 10.7414C10.929 11.1371 11 11.5603 11 12C11 12.4492 10.9259 12.8812 10.7894 13.2843L14.7821 15.2868C15.4238 14.4969 16.403 13.9922 17.5 13.9922C19.433 13.9922 21 15.5592 21 17.4922C21 19.4252 19.433 20.9922 17.5 20.9922C15.567 20.9922 14 19.4252 14 17.4922C14 17.3736 14.0059 17.2564 14.0174 17.1408L9.68297 14.9668C8.97337 15.6089 8.03236 16 7 16C4.79086 16 3 14.2091 3 12C3 9.79086 4.79086 8 7 8C8.04266 8 8.99215 8.39894 9.70415 9.0525L14.02 6.88786C14.0068 6.76429 14 6.6388 14 6.51172ZM17.5 5.01172C16.6716 5.01172 16 5.68329 16 6.51172C16 7.34015 16.6716 8.01172 17.5 8.01172C18.3284 8.01172 19 7.34015 19 6.51172C19 5.68329 18.3284 5.01172 17.5 5.01172ZM7 10C5.89543 10 5 10.8954 5 12C5 13.1046 5.89543 14 7 14C8.10457 14 9 13.1046 9 12C9 10.8954 8.10457 10 7 10ZM16 17.4922C16 16.6638 16.6716 15.9922 17.5 15.9922C18.3284 15.9922 19 16.6638 19 17.4922C19 18.3206 18.3284 18.9922 17.5 18.9922C16.6716 18.9922 16 18.3206 16 17.4922Z"
        fill="currentColor"
      />
    </svg>
  )
}
