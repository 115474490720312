/* eslint-disable  @typescript-eslint/no-explicit-any */
export const passwordValidator = function (this: any, value: string) {
  let error = ''
  if (!value.match(new RegExp('[A-Z].*'))) {
    error += ' upper'
  }
  if (!value.match(new RegExp('[a-z].*'))) {
    error += ' lower'
  }
  if (value.length < 8) {
    error += ' min'
  }
  if (!value.match(new RegExp('(?=.*[!?@#$&.<>%^()~*])(?=.*[0-9].*)'))) {
    error += ' spec'
  }
  if (error) {
    return this.createError({
      message: error,
    })
  }
  return true
}
