import {
  DASHBOARD_ROUTE,
  PENSION_ROUTE,
  SALARY_ROUTE,
  USER_ACCOUNT_ROUTE,
} from '@/common/config/route'
import { PermissionEnum } from '@/common/enums/Permission'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { useDataFetcher } from '@/common/hooks'
import { ZendeskButton } from '@/components'
import { EASYTEMP_REQUEST } from '@/features/payslip/constants'
import { getPayslipYearsFilter } from '@/features/payslip/lib/user-information-client'
import { getPensionStatments } from '@/features/pension-statements/lib/client-calls'
import { getCompanies } from '@/features/sickness-accident/lib/sickness-accident-client'
// import { getUserDocumentInfo } from '@/features/user-account/lib/user-account-client'
import {
  useEasyTempData,
  useGetCompaniesFromAbsence,
  useHasUiPermission,
  usePensionStatementsWhole,
} from '@/framework'
import { RouteExp } from '@/framework/client-router'
import {
  DashboardIcon,
  LogOutIcon,
  PillarsIcon,
  UserIcon,
  WalletIcon,
} from '@eplix/ui'
import { useBreakpoint } from '@eplix/ui/utils/use-breakpoint'
import { useRouter } from 'next/router'
import { memo, useMemo } from 'react'
import { NavigationDrawer } from './navigation-drawer'
import { NavigationDrawerButton } from './navigation-drawer-button'
import { NavigationDrawerContent } from './navigation-drawer-content'
import { NavigationDrawerDivider } from './navigation-drawer-divider'
import { NavigationDrawerLink } from './navigation-drawer-link'
import { NavigationDrawerLogoutButton } from './navigation-drawer-logout-button'

export interface MainNavigationProps {
  wide?: boolean
  extended?: boolean
  disabled?: boolean
  navAlwaysCollapsed?: boolean
  userStatus?: UserSessionStatusEnum
}

const userAccountRoute = new RouteExp('/user-account')
const dashboardRoute = new RouteExp('/pension')
const salaryRoute = new RouteExp('/salary')
// const riskInsuranceRoute = new RouteExp('/risk-insurance')

// const { isAnyInsuranceValidInCurrentYear } = getRiskInsuranceActions()

// const {
//   getIsSwissNationalityUser,
//   getIsCrossBorderOrSwissUser,
//   getIsSwissLivingCountryUser,
// } = getCommonUserDataActions()

export const MainNavigation = memo(
  ({ wide, disabled, navAlwaysCollapsed, userStatus }: MainNavigationProps) => {
    const router = useRouter()
    const { asPath } = router
    const breakpoint = useBreakpoint()

    const isCollapsed = useMemo(() => {
      return navAlwaysCollapsed
        ? navAlwaysCollapsed
        : breakpoint !== 'xxxl' && !wide
    }, [wide, breakpoint, navAlwaysCollapsed])

    const hasUiPensionPermission = useHasUiPermission(
      PermissionEnum.MY_PENSION_ACCESS,
    )
    const hasUiPayslipInduniPermission = useHasUiPermission(
      PermissionEnum.PAYSLIPS_ALL,
    )
    const hasAnyPermissions = useHasUiPermission(PermissionEnum.ANY)
    const [monthPensionStatementsGlobal] = usePensionStatementsWhole()

    const { result: monthPensionStatementsRefetch } = useDataFetcher({
      fetcher: getPensionStatments,
      enabled: !monthPensionStatementsGlobal && hasAnyPermissions,
    })

    // const { result: userInformationResponse } = useDataFetcher({
    //   fetcher: getInformation,
    //   fetchTriggerKey: GlobalValueKey.FETCH_TRIGGER_GET_USER_INFORMATION,
    //   enabled: userStatus === UserSessionStatusEnum?.AUTHENTICATED,
    // })

    // const { result: metadata3A } = useDataFetcher({
    //   fetcher: getPortfolioTypeMetadata,
    //   payload: useMemo(
    //     () => ({ portfolioType: PortfolioTypeEnum.PILLAR_3A }),
    //     [],
    //   ),
    //   enabled: userStatus === UserSessionStatusEnum?.AUTHENTICATED,
    // })

    // const { result: metadataFZS } = useDataFetcher({
    //   fetcher: getPortfolioTypeMetadata,
    //   payload: useMemo(
    //     () => ({ portfolioType: PortfolioTypeEnum.VESTED_BENEFITS }),
    //     [],
    //   ),
    //   enabled: userStatus === UserSessionStatusEnum?.AUTHENTICATED,
    // })

    // const hasPortfolio3a = !!metadata3A?.openedPortfolios
    // const hasPortfolioFZS = !!metadataFZS?.openedPortfolios

    // const { result: investedCapital } = useDataFetcher({
    //   fetcher: getInvestedCapital,
    //   enabled: hasPortfolio3a || hasPortfolioFZS,
    //   fetchTriggerKey: GlobalValueKey.INVESTED_CAPITAL,
    // })

    // const { result: insuranceFree } = useDataFetcher({
    //   fetcher: getInsurance,
    //   payload: InsuranceTypeEnum.Free,
    //   enabled: userStatus === UserSessionStatusEnum?.AUTHENTICATED,
    //   fetchTriggerKey: GlobalValueKey.FREE_INSURANCE,
    // })

    // const { result: insurancePremium } = useDataFetcher({
    //   fetcher: getInsurance,
    //   payload: InsuranceTypeEnum.Premium,
    //   enabled: userStatus === UserSessionStatusEnum?.AUTHENTICATED,
    //   fetchTriggerKey: GlobalValueKey.PREMIUM_INSURANCE,
    // })

    const monthPensionStatements =
      monthPensionStatementsGlobal ?? monthPensionStatementsRefetch

    const [companiesFromAbsenceGlobal] = useGetCompaniesFromAbsence()
    const { result: companiesFromAbsenceRefetch } = useDataFetcher({
      fetcher: getCompanies,
      enabled:
        !companiesFromAbsenceGlobal &&
        userStatus === UserSessionStatusEnum?.AUTHENTICATED,
    })

    const companiesFromAbsence =
      companiesFromAbsenceGlobal ?? companiesFromAbsenceRefetch

    const [hasEasyTempData] = useEasyTempData()
    const { result: easyTempYears } = useDataFetcher({
      fetcher: getPayslipYearsFilter,
      payload: EASYTEMP_REQUEST,
      enabled: !hasEasyTempData && hasAnyPermissions,
    })

    const easyTempData =
      hasEasyTempData ?? (easyTempYears?.years as [])?.length > 0

    const hasWorkPermissionForWorkButton =
      hasUiPayslipInduniPermission ||
      monthPensionStatements?.length > 0 ||
      easyTempData ||
      companiesFromAbsence?.companies?.length

    // const hasFreeInsurance = insuranceFree?.insurance?.hasInsurance
    // const hasPremiumInsurance = insurancePremium?.insurance?.hasInsurance
    // const userAge = getDifferenceBetweenYears(
    //   userInformationResponse?.birthdate ?? Constants.EmptyString,
    //   RequestDateFormat.DD_MM_YYYY,
    // )

    // const sixtyYearsAfterBirthdateYear = addYears(
    //   userInformationResponse?.birthdate,
    //   UserAgeEnum.AGE_60,
    //   RequestDateFormat.DD_MM_YYYY,
    // )

    // const parsedSixtyYearsAfterBirthdateYear = parseRequestDateAndTime(
    //   sixtyYearsAfterBirthdateYear,
    //   RequestDateFormat.DD_MM_YYYY,
    //   RequestDateFormat.YYYY_MM_DD,
    // )

    // const lastDateOfBirthdayMonth = getEndOfMonth(
    //   parsedSixtyYearsAfterBirthdateYear,
    // )

    // const differenceBetweenYears = getDifferenceBetweenYears(
    //   lastDateOfBirthdayMonth,
    //   RequestDateFormat.DD_MM_YYYY,
    //   true,
    // )

    // const upperBound =
    //   userInformationResponse?.gender === GenderEnum.FEMALE
    //     ? AgeBoundariesEnum.FEMALE_REGULAR_BOUNDARY
    //     : AgeBoundariesEnum.MALE_REGULAR_BOUNDARY

    // const fitAgeBoundaries =
    //   userAge > UserAgeEnum.AGE_60 && userAge < upperBound
    //     ? hasFreeInsurance
    //     : userAge >= UserAgeEnum.AGE_18 &&
    //       userAge < UserAgeEnum.AGE_60 &&
    //       differenceBetweenYears < 1

    // const lifeguardFreeValidTo = convertToDate(
    //   insuranceFree?.insurance?.validTo,
    //   RequestDateFormat.YYYY_MM_DD,
    // )

    // const lifeguardPremiumValidTo = convertToDate(
    //   insurancePremium?.insurance?.validTo,
    //   RequestDateFormat.YYYY_MM_DD,
    // )

    // const isValidInCurrentYear = isAnyInsuranceValidInCurrentYear(
    //   lifeguardFreeValidTo,
    //   lifeguardPremiumValidTo,
    // )

    // const switzerlandPerson =
    //   userInformationResponse?.country === CountryEnum.SWITZERLAND &&
    //   userInformationResponse?.nationality === CountryEnum.SWITZERLAND

    // const nonSwitzerlandPermit =
    //   userInformationResponse?.country === CountryEnum.SWITZERLAND &&
    //   userInformationResponse?.nationality !== CountryEnum.SWITZERLAND

    // const shouldCheckPermit =
    //   userInformationResponse?.isCrossBorder ||
    //   (userInformationResponse?.country === CountryEnum.SWITZERLAND &&
    //     userInformationResponse?.nationality !== CountryEnum.SWITZERLAND)

    // const { result: personalDocuments } = useDataFetcher({
    //   fetcher: getUserDocumentInfo,
    //   enabled: shouldCheckPermit,
    // })

    // const hasValidPermit = personalDocuments?.documents.some(
    //   (obj) =>
    //     obj.documentCategory === 'B' ||
    //     obj.documentCategory === 'C' ||
    //     obj.documentCategory === 'Ci',
    // )

    // const hasValidPermitCrossborder = personalDocuments?.documents.some(
    //   (obj) => obj.documentCategory === 'G',
    // )

    // const showRiskInsuranceCard =
    //   fitAgeBoundaries &&
    //   (isValidInCurrentYear ||
    //     switzerlandPerson ||
    //     (userInformationResponse?.isCrossBorder && hasValidPermitCrossborder) ||
    //     (nonSwitzerlandPermit && hasValidPermit) ||
    //     userInformationResponse?.country === null)

    // const isValidUser =
    //   switzerlandPerson ||
    //   (nonSwitzerlandPermit && hasValidPermit) ||
    //   (userInformationResponse?.isCrossBorder && hasValidPermitCrossborder)

    // const showRiskInsuranceCard =
    //   hasFreeInsurance ||
    //   hasPremiumInsurance ||
    //   (investedCapital?.assetsValue < 10000 && isValidUser) ||
    //   (!hasPortfolio3a && !hasPortfolioFZS && isValidUser)

    return (
      <NavigationDrawer
        collapsed={isCollapsed}
        disabled={disabled || !hasAnyPermissions}
      >
        <NavigationDrawerContent as="ul">
          <NavigationDrawerLink
            text="USM-MainNavigation:Overview"
            href={DASHBOARD_ROUTE}
            icon={<DashboardIcon />}
          />
          <NavigationDrawerLink
            text="USM-MainNavigation:Salary"
            href={SALARY_ROUTE}
            icon={<WalletIcon />}
            disabled={!hasWorkPermissionForWorkButton}
            isActive={!!salaryRoute.match(asPath)}
          />
          <NavigationDrawerLink
            disabled={!hasUiPensionPermission}
            text="USM-MainNavigation:PensionFund"
            href={PENSION_ROUTE}
            icon={<PillarsIcon />}
            isActive={!!dashboardRoute.match(asPath)}
          />
          {/* <NavigationDrawerLink
            text="USM-MainNavigation:Insurance"
            href={RISK_INSURANCE_ROUTE}
            icon={<InsuranceIcon size={24} />}
            disabled={!showRiskInsuranceCard}
            isActive={!!riskInsuranceRoute.match(asPath)}
          /> */}
        </NavigationDrawerContent>
        <NavigationDrawerContent>
          <NavigationDrawerDivider />
        </NavigationDrawerContent>
        <NavigationDrawerContent as="ul">
          <NavigationDrawerLink
            text="USM-MainNavigation:Profile"
            icon={<UserIcon />}
            href={USER_ACCOUNT_ROUTE}
            isActive={!!userAccountRoute.match(asPath)}
          />

          <NavigationDrawerButton
            content={
              <ZendeskButton
                className="text-black p-0 !gap-4"
                color="black"
                isCollapsed={isCollapsed}
              />
            }
          />
          <NavigationDrawerLogoutButton
            collapsed={isCollapsed}
            text="USM-MainNavigation:LogoutButton"
            icon={<LogOutIcon />}
          />
        </NavigationDrawerContent>
      </NavigationDrawer>
    )
  },
)
