export default {
  webWidget: {
    contactForm: {
      selectTicketForm: {
        'de-CH': 'Thema wählen:',
        de: 'Thema wählen:',
        'fr-CH': 'Choisir un sujet:',
        fr: 'Choisir un sujet:',
        'it-CH': 'Scegliere un argomento:',
        it: 'Scegliere un argomento:',
        'en-US': 'Choose topic:',
        en: 'Choose topic:',
      },
    },
  },
}
