import { FC } from 'react'
import { IconProps } from './types'

export const TwintIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4707 2.13283C11.7635 1.95671 12.1295 1.95566 12.4233 2.13009L20.1925 6.74169C20.4744 6.90901 20.6472 7.21252 20.6472 7.5403V16.5622C20.6472 16.8932 20.4709 17.1992 20.1846 17.3654L12.4155 21.8745C12.1256 22.0428 11.7675 22.0418 11.4785 21.8719L3.81147 17.3634C3.52774 17.1966 3.35352 16.892 3.35352 16.5629V7.5396C3.35352 7.21372 3.52432 6.91168 3.80359 6.74373L11.4707 2.13283ZM8.75569 9.4386C8.51628 9.37963 8.23047 9.33998 7.93529 9.46925C7.54915 9.63835 7.26396 10.0356 7.21609 10.5073C7.19893 10.6764 7.22413 10.8216 7.26062 10.9325C7.29176 11.0271 7.32585 11.0814 7.33401 11.0944C7.33623 11.098 7.33653 11.0985 7.3343 11.0958L7.3452 11.1087L10.292 14.9324L11.2943 13.6334L10.2875 12.3232C10.0012 11.9507 9.54865 11.3424 9.54865 10.5752C9.54865 9.08895 10.7684 7.9028 12.2497 7.9028C13.7311 7.9028 14.9508 9.08895 14.9508 10.5752C14.9508 11.2823 14.5531 11.8426 14.2839 12.1915L13.1698 13.6353L14.1762 14.9451L16.5577 11.9689C16.8141 11.6485 17.2816 11.5966 17.602 11.853C17.9224 12.1094 17.9743 12.5769 17.7179 12.8973L15.0115 16.2795C14.5745 16.8257 13.7411 16.8175 13.3148 16.2628L12.2308 14.8521L11.1147 16.2985C10.6984 16.838 9.88439 16.8377 9.46847 16.298L6.18645 12.0394C5.94998 11.75 5.659 11.1332 5.73776 10.3572C5.83494 9.39969 6.41995 8.51069 7.33922 8.10812C8.08838 7.78004 8.77033 7.91186 9.1111 7.99581C9.50952 8.09395 9.75294 8.49649 9.6548 8.89491C9.55665 9.29332 9.15411 9.53674 8.75569 9.4386ZM12.2333 12.4167L13.1075 11.2837C13.3693 10.9445 13.4649 10.7373 13.4649 10.5752C13.4649 9.93023 12.9312 9.38873 12.2497 9.38873C11.5682 9.38873 11.0346 9.93023 11.0346 10.5752C11.0346 10.78 11.1597 11.0196 11.4657 11.4178L12.2333 12.4167Z"
        fill="currentColor"
      />
    </svg>
  )
}
