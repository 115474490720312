import { NextRouter } from 'next/router'
import { useMemo } from 'react'
import { ClientPath, getClientPath } from '../lib/get-client-path'

export const useClientPath = (
  router: NextRouter,
  queryKey = 'path',
): ClientPath => {
  return useMemo(() => {
    return getClientPath(router, queryKey)
  }, [router, queryKey])
}
