import clsx from 'clsx'

import { createElement, useCallback } from 'react'

import classes from './grid.module.scss'
import { forwardRefWithAs } from '../utils/forward-ref-with-as'

export const GridDefaultElement = 'div'
export type GridAllowedElements = 'div' | 'span'

export interface GridProps {
  columns?: number | string
  gap?: number | string
  rowGap?: number | string
  columnGap?: number | string
}

export const Grid = forwardRefWithAs<
  GridProps,
  typeof GridDefaultElement,
  GridAllowedElements
>(
  (
    { as, columns, gap, rowGap, columnGap, className, children, ...props },
    ref,
  ) => {
    // if no value is provided we fall back to an empty string
    const normalizedRowGap = rowGap || gap || ''
    const normalizedColumnGap = columnGap || gap || ''
    const valueSuffix = useCallback(
      (value?: number | string) =>
        value && typeof value === 'number' ? 'px' : '',
      [],
    )
    return createElement(
      as || GridDefaultElement,
      {
        ...props,
        ref,
        className: clsx(className, classes.gridLayout),
        style: {
          // falsy values here means that you might define those values by yourself via css
          '--columns-amount': columns,
          '--row-gap': `${normalizedRowGap}${valueSuffix(normalizedRowGap)}`,
          '--column-gap': `${normalizedColumnGap}${valueSuffix(
            normalizedColumnGap,
          )}`,
          ...props.style,
        },
      },
      children,
    )
  },
)
