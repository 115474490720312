import { CustomerPersonalData } from '@/api'
import { useMemo } from 'react'
import { shouldShowPersonalData } from '../actions'

export const useShouldShowPersonalDataPage = (
  personalData?: CustomerPersonalData,
) => {
  return useMemo(() => {
    return shouldShowPersonalData(personalData)
  }, [personalData])
}
