export const AR = {
  9035: 'Grub AR',
  9037: 'Speicherschwendi',
  9038: 'Rehetobel',
  9042: 'Speicher',
  9043: 'Trogen',
  9044: 'Wald AR',
  9052: 'Niederteufen',
  9053: 'Teufen AR',
  9055: 'Bühler',
  9056: 'Gais',
  9062: 'Lustmühle',
  9063: 'Stein AR',
  9064: 'Hundwil',
  9100: 'Herisau',
  9101: 'Herisau',
  9102: 'Herisau',
  9103: 'Schwellbrunn',
  9104: 'Waldstatt',
  9105: 'Schönengrund',
  9107: 'Urnäsch',
  9112: 'Schachen b.Herisau',
  9405: 'Wienacht-Tobel',
  9410: 'Heiden',
  9411: 'Reute AR',
  9414: 'Schachen b. Reute',
  9426: 'Lutzenberg',
  9427: 'Wolfhalden',
  9428: 'Walzenhausen',
}
