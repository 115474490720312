import {
  createElement,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  PropsWithoutRef,
  ReactHTML,
} from 'react'
import clsx from 'clsx'

import classes from './navigation-drawer.module.scss'

type ContentType = 'ul' | 'div'

export interface NavigationDrawerContentProps<TElement extends ContentType>
  extends HTMLProps<ReactHTML[TElement]> {
  as?: TElement
}

export const NavigationDrawerContent = forwardRef(
  <TElement extends ContentType = 'div'>(
    { as, ...props }: PropsWithoutRef<NavigationDrawerContentProps<TElement>>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'div',
      {
        ...props,
        className: clsx(
          classes.content,
          as === 'ul' && classes.list,
          props.className,
        ),
        ref: ref,
      },
      props.children,
    )
  },
)
