import { FC } from 'react'
import { IconProps } from './types'

export const FavIconFill: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="#3A85F8"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8575 2.4855C12.6768 2.1843 12.3513 2 12 2C11.6487 2 11.3232 2.1843 11.1425 2.4855L8.38011 7.08952L2.83561 8.01361C2.48567 8.07193 2.19283 8.31129 2.06605 8.64263C1.93927 8.97396 1.99752 9.34767 2.21914 9.62469L5.94021 14.276L5.01362 19.8356C4.95178 20.2066 5.10336 20.5809 5.40591 20.8044C5.70846 21.0278 6.11081 21.0626 6.44723 20.8944L12 18.118L17.5528 20.8944C17.8892 21.0626 18.2916 21.0278 18.5941 20.8044C18.8967 20.5809 19.0482 20.2066 18.9864 19.8356L18.0598 14.276L21.7809 9.62469C22.0025 9.34767 22.0608 8.97396 21.934 8.64263C21.8072 8.31129 21.5143 8.07193 21.1644 8.01361L15.6199 7.08952L12.8575 2.4855Z"
        fill="#3A85F8"
      />
    </svg>
  )
}
