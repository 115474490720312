import { Translate } from '../translate'
import { AmountTextProps } from './types'

export const AmountText = ({
  amount,
  className,
  variant = 'regular-300',
  color = 'text-secondary',
}: AmountTextProps) => {
  return (
    <Translate
      label={amount}
      variant={variant}
      color={color}
      className={className}
    />
  )
}
