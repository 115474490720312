import {
  ActionResponse,
  DocumentTime,
  ErrorResponse,
  GetAssetsResponse,
  GetRiskInformationResponse,
  InvitationRequest,
  InvitationResponse,
  PendingOrdersResponse,
  PensionFundsResponse,
  RecommendedStrategyResponse,
  RiskProfiles,
  SalesPartnerInformationResponse,
  SubmitPortfolioOpeningRequest,
} from '@/api'
import { PortfolioTypeEnum } from '@/common/enums/PortfolioType'
import { getJSON, postJSON } from '@/framework/common'
import {
  EXECUTE_REBALANCING_API_ROUTE,
  GET_pendingOrders,
  OPENING_PORTFOLIOS_SALES_PARTNER,
  PORTFOLIO_DOWNLOAD_DOCUMENT_API_ROUTE,
  PORTFOLIO_GENERATE_REPORT_API_ROUTE,
} from '../api-routes/portfolio-overview'
import {
  AddPersonalInformationRequest,
  PersonalInfoRequest,
} from '../components/forms/personal-information/types'
import {
  AssetsTransferRequestPayload,
  AssetsTransferRequestProps,
  CancelPendingOrderFormRequest,
  GetAcceptedDatesRequest,
  GetAssetsRequest,
  GetOpeningRiskRequest,
  GetOpeningRiskRequestWrap,
  GetPendingOrdersRequest,
  GetPortfolioDocumentRequest,
  GetPortfolioGenerateReportRequest,
  GetPortfolioPartnerSalesRequest,
  GetStrategyReq,
  InvitationRequestForm,
  NowRebalancingFormRequest,
  SetCancelPendingOrderRequest,
  SetViewReportRequest,
  SubmitPortfolioOpeningReq,
  ViewReportRequest,
} from './types'

const RISK_3A_ROUTE = '/api/portfolio/3a/risk-information'
const RISK_FZS_ROUTE = '/api/portfolio/vested-benefits/risk-information'

export const cancelPendingOrder = async (
  req: CancelPendingOrderFormRequest,
): Promise<ActionResponse | ErrorResponse> => {
  return await postJSON<
    ActionResponse | ErrorResponse,
    SetCancelPendingOrderRequest
  >(req.url, req.payload)
}

export const addPersonalInformation = async (
  req: AddPersonalInformationRequest,
): Promise<ActionResponse | ErrorResponse> => {
  return await postJSON<ActionResponse | ErrorResponse, PersonalInfoRequest>(
    req.url,
    req.employmentStatusRequest,
  )
}

export const generateReport = async (
  req: GetPortfolioGenerateReportRequest,
) => {
  return await postJSON<BlobPart, GetPortfolioGenerateReportRequest>(
    PORTFOLIO_GENERATE_REPORT_API_ROUTE,
    req,
    {},
    'blob',
  )
}

export const getAcceptedDates = async (req: GetAcceptedDatesRequest) => {
  return postJSON<DocumentTime | ErrorResponse, GetAcceptedDatesRequest>(
    req.url,
    req,
  )
}

export const getAssets = async (req: GetAssetsRequest) => {
  return postJSON<GetAssetsResponse | ErrorResponse, GetAssetsRequest>(
    req.url,
    req,
  )
}

export const executeRebalancing = async (
  req: NowRebalancingFormRequest,
): Promise<ActionResponse | ErrorResponse> => {
  return await postJSON<
    ActionResponse | ErrorResponse,
    NowRebalancingFormRequest
  >(EXECUTE_REBALANCING_API_ROUTE, req)
}

export const getOpeningRisk = async (req: GetOpeningRiskRequestWrap) => {
  const url =
    req.portfolioType === PortfolioTypeEnum.PILLAR_3A
      ? RISK_3A_ROUTE
      : RISK_FZS_ROUTE
  return postJSON<
    GetRiskInformationResponse | ErrorResponse,
    GetOpeningRiskRequest
  >(url, req.payload)
}

export const getPendingOrders = async (req: GetPendingOrdersRequest) => {
  return postJSON<
    PendingOrdersResponse | ErrorResponse,
    GetPendingOrdersRequest
  >(GET_pendingOrders, req)
}

export const getPensionFunds = async (url: string) => {
  return await getJSON<PensionFundsResponse | ErrorResponse>(url)
}

export const getPortfolioDocuments = async (req: ViewReportRequest) => {
  return await postJSON<BlobPart, SetViewReportRequest>(
    req.url,
    req.payload,
    {},
    'blob',
  )
}

export const getPortfolioDownloadDocument = async (
  req: GetPortfolioDocumentRequest,
) => {
  return postJSON<BlobPart, GetPortfolioDocumentRequest>(
    PORTFOLIO_DOWNLOAD_DOCUMENT_API_ROUTE,
    req,
    {},
    'blob',
  )
}

export const getRiskProfiles = async (url: string) => {
  return getJSON<RiskProfiles | ErrorResponse>(url)
}

export const getSalesPartner = async (url: string) => {
  return getJSON<InvitationResponse | ErrorResponse>(url)
}

export const getAnswer = async (req: InvitationRequestForm) => {
  return postJSON<ActionResponse | ErrorResponse, InvitationRequest>(
    req.url,
    req.payload,
  )
}

export const getOpeningPortfoliosSalesPartner = async (
  req: GetPortfolioPartnerSalesRequest,
) => {
  return postJSON<
    SalesPartnerInformationResponse | null,
    GetPortfolioPartnerSalesRequest
  >(OPENING_PORTFOLIOS_SALES_PARTNER, req)
}

export const getStrategy = async (req: GetStrategyReq) => {
  return postJSON<RecommendedStrategyResponse, GetStrategyReq>(req.url, req)
}

export const submitPortfolioOpening = async (
  req: SubmitPortfolioOpeningReq,
): Promise<ActionResponse | ErrorResponse> => {
  return await postJSON<
    ActionResponse | ErrorResponse,
    SubmitPortfolioOpeningRequest
  >(req.url, req.portfolioOpeningRequest)
}

export const transferAssets = async (req: AssetsTransferRequestProps) =>
  postJSON<BlobPart, AssetsTransferRequestPayload>(
    req.url,
    req.payload,
    {},
    'blob',
  )
