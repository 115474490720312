import { FC } from 'react'
import { IconProps } from './types'

export const BellIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 1C10.1436 1 8.36309 1.7375 7.05033 3.05025C5.73758 4.36301 5.00008 6.14348 5.00008 8C5.00008 11.3527 4.28301 13.4346 3.62218 14.6461C3.29039 15.2544 2.9673 15.6535 2.74288 15.8904C2.6304 16.0091 2.54202 16.0878 2.4888 16.1322C2.46218 16.1544 2.44432 16.168 2.43663 16.1737L2.43377 16.1758C2.07528 16.4221 1.91664 16.8725 2.04299 17.2898C2.17068 17.7115 2.55941 18 3.00008 18H8.12602C8.57006 19.7252 10.1362 21 12 21C13.8638 21 15.4299 19.7252 15.874 18H21.0001C21.4407 18 21.8295 17.7115 21.9572 17.2898C22.0835 16.8725 21.9249 16.4221 21.5664 16.1758L21.5635 16.1737C21.5558 16.168 21.538 16.1544 21.5114 16.1322C21.4581 16.0878 21.3698 16.0091 21.2573 15.8904C21.0329 15.6535 20.7098 15.2544 20.378 14.6461C19.7171 13.4346 19.0001 11.3527 19.0001 8C19.0001 6.14349 18.2626 4.36301 16.9498 3.05025C15.6371 1.7375 13.8566 1 12.0001 1ZM13.7324 18H10.2676C10.6134 18.5978 11.2597 19 12 19C12.7403 19 13.3866 18.5978 13.7324 18ZM16 16H18.8508C18.7747 15.8753 18.6983 15.7434 18.6222 15.6039C17.783 14.0654 17.0001 11.6473 17.0001 8C17.0001 6.67392 16.4733 5.40215 15.5356 4.46447C14.5979 3.52678 13.3262 3 12.0001 3C10.674 3 9.40223 3.52678 8.46454 4.46447C7.52686 5.40215 7.00008 6.67392 7.00008 8C7.00008 11.6473 6.21715 14.0654 5.37797 15.6039C5.30188 15.7434 5.22549 15.8753 5.1494 16H8H16Z"
        fill="currentColor"
      />
    </svg>
  )
}
