import { AccordionHeaderValuesProps } from '../types'
import { InlineButton } from '@eframe-ui/react'
import classes from './accordion-header-values.module.scss'
import { Chip, Typo } from '@eplix/ui'
import { formatMoney } from '@/common'
import { memo } from 'react'

export const AccordionHeaderValues = memo(
  ({
    type = 'empty',
    icon,
    value,
    chipValue,
    onChipClick = () => {},
    colorClass = 'bg-[#7DDDAF]',
    actionIcon,
    actionName,
    onActionClick = () => {},
    hideValue = false,
    hideChip = false,
    shouldBreakContent = false,
  }: AccordionHeaderValuesProps) => {
    return type === 'action' ? (
      <div className={classes.actionContainer}>
        <InlineButton
          onClick={onActionClick}
          className="pr-0 md:pr-2"
          data-cy="btn_accordion_header"
        >
          <p className="typo-regular-200">{actionName}</p>
          {actionIcon}
        </InlineButton>
      </div>
    ) : type === 'numbers' ? (
      <div className={classes.numberValuesContainer}>
        {icon}
        <div
          className={
            shouldBreakContent
              ? classes.numberContainerBreak
              : classes.numbersContainer
          }
        >
          {!hideValue && (
            <p className="text-text-primary typo-medium-300">
              {formatMoney(value ?? 0)}
            </p>
          )}
          {!hideChip && (
            <Chip
              name="accordion_header"
              className={`${colorClass} rounded w-auto py-0 px-1 h-7 border-0 text-text-primary ring-0`}
              onClick={onChipClick}
            >
              <Typo color="text-primary" variant="medium-200">
                {chipValue}
              </Typo>
            </Chip>
          )}
        </div>
      </div>
    ) : (
      <></>
    )
  },
)
