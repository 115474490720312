import { LoadingButton as SpinnerButton } from '@eframe-ui/react'
import { TranslateSimple } from '../translate'
import { LoadingButtonProps } from './types'
import { Ref, forwardRef } from 'react'

export const LoadingButton = forwardRef(
  (
    { loading, text, name, ...props }: LoadingButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <SpinnerButton
      loading={loading}
      {...props}
      ref={ref}
      data-cy={`loading_btn_${name}`}
    >
      <TranslateSimple label={text} />
    </SpinnerButton>
  ),
)
