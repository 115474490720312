import { useEffect } from 'react'
import { datadogConfig } from '@/config'
import { datadogRum } from '@datadog/browser-rum'

export const useInitDatadog = () => {
  useEffect(() => {
    if (datadogConfig.enabled) {
      datadogRum.init(datadogConfig)
    }
  }, [])
}
