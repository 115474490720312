import { FC } from 'react'
import { IconProps } from './types'

export const DownloadIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.9192V4H11V11.9207L8.81746 9.73821C8.42693 9.34768 7.79377 9.34768 7.40324 9.73821C7.01272 10.1287 7.01272 10.7619 7.40324 11.1524L11.2921 15.0413C11.6827 15.4318 12.3158 15.4318 12.7063 15.0413L16.5952 11.1524C16.9858 10.7619 16.9858 10.1287 16.5952 9.73821C16.2047 9.34768 15.5715 9.34768 15.181 9.73821L13 11.9192ZM6 13.332V17.4431C6 17.5905 6.05853 17.7318 6.16272 17.836C6.26691 17.9402 6.40821 17.9987 6.55556 17.9987H17.4444C17.5918 17.9987 17.7331 17.9402 17.8373 17.836C17.9415 17.7318 18 17.5905 18 17.4431V13.332H20V17.4431C20 18.1209 19.7308 18.7709 19.2515 19.2502C18.7722 19.7295 18.1222 19.9987 17.4444 19.9987H6.55556C5.87778 19.9987 5.22776 19.7295 4.7485 19.2502C4.26925 18.7709 4 18.1209 4 17.4431V13.332H6Z"
        fill="currentColor"
      />
    </svg>
  )
}
