/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorResponse } from '@/api'
import { AnyObject } from 'yup'

export const hasResponseErrors = (errorResponse: ErrorResponse | undefined) => {
  return !!errorResponse?.message || !!errorResponse?.errors?.length
}

export const hasErrors = (response: AnyObject | unknown | ErrorResponse) => {
  if (!response) return true
  if (isBlob(response)) return false

  const errorResponse: ErrorResponse = response as ErrorResponse
  if (errorResponse.message) {
    console.error(errorResponse.message)
  }
  return (
    !!errorResponse.message ||
    !!errorResponse.errors?.length ||
    isEmpty(response)
  )
}

const isEmpty = (obj: AnyObject | unknown | ErrorResponse) => {
  return Object.keys(obj as AnyObject).length === 0
}

const isBlob = (value: any): value is Blob => {
  return (
    typeof value === 'object' &&
    typeof value.type === 'string' &&
    typeof value.stream === 'function' &&
    typeof value.arrayBuffer === 'function' &&
    typeof value.constructor === 'function' &&
    typeof value.constructor.name === 'string' &&
    /^(Blob|File)$/.test(value.constructor.name) &&
    /^(Blob|File)$/.test(value[Symbol.toStringTag])
  )
}
