import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import clsx from 'clsx'
import classes from './dialog.module.scss'

export type DialogOverlayProps = HTMLAttributes<HTMLDivElement>

export const DialogOverlay = forwardRef(
  (
    { className, ...props }: DialogOverlayProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <HeadlessDialog.Overlay
        ref={ref}
        {...props}
        className={clsx(classes.overlay, className)}
      />
    )
  },
)
