const base = '/api/portfolio/overview'

export const SINGLE_PORTFOLIO_API_ROUTE = `${base}/portfolio-details`
export const portfolioTypeS_BASIC_METADATA_API_ROUTE = `${base}/basic-metadata`
export const ACCOUNT_TRANSACTION_API_ROUTE = `${base}/account-transaction`
export const portfolioType_METADATA_API_ROUTE = `${base}/metadata`
export const SETTINGS_API_ROUTE = `${base}/get-portfolio-settings`
export const UPDATE_SETTINGS_API_ROUTE = `${base}/set-portfolio-settings`
export const PORTFOLIO_REPORTS_API_ROUTE = `${base}/reports`
export const PORTFOLIO_TAX_CERTIFICATE_API_ROUTE = `${base}/tax-certificate`
export const PORTFOLIO_GENERATE_REPORT_API_ROUTE = `${base}/generate-report`
export const PORTFOLIO_DOWNLOAD_DOCUMENT_API_ROUTE = `${base}/download-document`
export const PORTFOLIOS_EDIT_RISK_API_ROUTE = `${base}/portfolio-edit-risk`
export const PORTFOLIOS_GET_OFFER_API_ROUTE = `${base}/get-offer`
export const PORTFOLIOS_GET_CURRENT_OFFER_API_ROUTE = `${base}/get-current-offer`
export const PORTFOLIOS_SUBMIT_EDIT_API_ROUTE = `${base}/submit-edit`
export const IS_PORTFOLIO_EDIT_AVAILABLE_API_ROUTE = `${base}/is-portfolio-edit-available`
export const PORTFOLIO_FILL_DOCUMENT_TEMPLATE = `${base}/get-portfolio-fill-document-template-api`
export const payInData_API_ROUTE = `${base}/pay-in-data`
export const PORTFOLIOS_salesPartner = `${base}/sales-partner`
export const OPENING_PORTFOLIOS_SALES_PARTNER = `${base}/opening-sales-partner`
export const CANCEL_pendingOrders = `${base}/cancel-pending-order`
export const EXECUTE_REBALANCING_API_ROUTE = `${base}/execute-rebalancing`
export const EXPECTED_CONTRIBUTION_ROUTE = `${base}/expected-contribution`
export const PENSION_AGREEMENT_EXISTS = `${base}/pension-agreement-exists`
export const INTEREST_RATES = `${base}/interest-rates`
export const STRATEGY_SWITCH_DOCUMENTS = `${base}/strategy-switch-documents`
export const GET_pendingOrders = `${base}/get-pending-orders`
export const GET_EVALUATION_HISTORY = `${base}/get-evaluation-history`
export const GET_CLOSED_PORTFOLIOS = `${base}/get-closed-portfolios`
