import { TextField } from '@eplix/ui'
import { ChangeEvent } from 'react'
import { IBANFieldProps } from './types'
import { CountryCodeEnum } from '@/common/enums'
import { getIBANFieldActions } from './actions'

export const IBANField = ({
  iban,
  error,
  helperText,
  onValueChangeCallback,
}: IBANFieldProps) => {
  const { getFormattedValue } = getIBANFieldActions()

  const handleIBANChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formatted = getFormattedValue(event.target.value)
    onValueChangeCallback(formatted)
  }

  return (
    <TextField
      name="iban"
      error={error}
      helperText={helperText}
      onChange={handleIBANChange}
      formFieldClassName="md:w-1/2 bg-[#f4f7fa]"
      value={iban || CountryCodeEnum.SWITZERLAND}
      onPaste={(val) => {
        const data = val.clipboardData.getData('text')
        const formatted = getFormattedValue(data)
        onValueChangeCallback(formatted)
      }}
    />
  )
}
