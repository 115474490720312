import { FC } from 'react'
import { IconProps } from './types'

export const FlipIcon: FC<IconProps> = ({ iconSize = 12, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 12 12`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78369 1.89061L3.78475 1.88995L3.78533 1.88959L3.78369 1.89061L3.76954 1.89981C3.75547 1.90907 3.73233 1.9246 3.70141 1.94638C3.63953 1.98999 3.54678 2.05847 3.43333 2.15175C3.2061 2.33857 2.89807 2.62303 2.58883 3.0044C1.97209 3.76501 1.35115 4.90935 1.35115 6.44553C1.35115 7.98172 1.97209 9.12606 2.58883 9.88667C2.89807 10.268 3.2061 10.5525 3.43333 10.7393C3.54678 10.8326 3.63953 10.9011 3.70141 10.9447C3.73233 10.9665 3.75547 10.982 3.76954 10.9913L3.78368 11.0004L3.78531 11.0015L3.78475 11.0011L3.78368 11.0004C4.08176 11.1846 4.14207 11.5325 3.91832 11.7778C3.69446 12.0232 3.27101 12.0729 2.97252 11.8889L3.37787 11.4445C2.97252 11.8889 2.97252 11.8889 2.97252 11.8889L2.97 11.8873L2.96648 11.8851L2.95655 11.8788C2.94865 11.8738 2.93825 11.8671 2.92549 11.8587C2.89999 11.8419 2.86507 11.8184 2.82209 11.7881C2.73619 11.7276 2.61782 11.6399 2.47793 11.5248C2.19849 11.2951 1.83094 10.9547 1.46461 10.5029C0.730201 9.59715 0 8.24201 0 6.44553C0 4.64906 0.730201 3.29392 1.46461 2.38819C1.83094 1.9364 2.19849 1.59598 2.47793 1.36623C2.61782 1.25122 2.73619 1.16348 2.82209 1.10294C2.86507 1.07265 2.89999 1.04913 2.92549 1.03235C2.93825 1.02396 2.94865 1.01726 2.95655 1.01223L2.96648 1.00596L2.97 1.00376L2.97138 1.0029L2.97198 1.00253C2.97198 1.00253 2.97252 1.0022 3.37787 1.44655L2.97198 1.00253C3.27047 0.818472 3.69446 0.867876 3.91832 1.11329C4.14202 1.35851 4.08159 1.70644 3.78369 1.89061Z"
        fill="#C1C4C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.100918 0.492135C0.143479 0.187374 0.478471 -0.0313154 0.849144 0.00367705L4.64582 0.362092C5.01649 0.397085 5.28248 0.672509 5.23992 0.977269L4.80399 4.09882C4.76143 4.40358 4.42643 4.62227 4.05576 4.58727C3.68509 4.55228 3.4191 4.27686 3.46166 3.9721L3.82053 1.40237L0.695019 1.10731C0.324345 1.07232 0.0583574 0.796895 0.100918 0.492135Z"
        fill="#C1C4C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21631 10.1094L8.21525 10.11L8.21467 10.1104L8.21631 10.1094L8.23045 10.1002C8.24453 10.0909 8.26767 10.0754 8.29859 10.0536C8.36047 10.01 8.45322 9.94153 8.56666 9.84825C8.7939 9.66143 9.10193 9.37697 9.41117 8.9956C10.0279 8.23499 10.6489 7.09065 10.6489 5.55447C10.6489 4.01828 10.0279 2.87394 9.41117 2.11333C9.10193 1.73196 8.7939 1.44751 8.56666 1.26068C8.45322 1.1674 8.36047 1.09892 8.29859 1.05532C8.26767 1.03353 8.24453 1.018 8.23045 1.00874L8.21632 0.999567L8.21469 0.998538L8.21525 0.998883L8.21632 0.999567C7.91824 0.815434 7.85793 0.467497 8.08168 0.222218C8.30554 -0.0231924 8.72899 -0.0729275 9.02748 0.11113L8.62213 0.555484C9.02748 0.11113 9.02748 0.11113 9.02748 0.11113L9.03 0.112694L9.03352 0.114887L9.04345 0.121163C9.05135 0.126187 9.06175 0.132894 9.07451 0.141283C9.10001 0.158058 9.13493 0.181584 9.17791 0.211869C9.26381 0.272408 9.38218 0.360148 9.52207 0.475163C9.80151 0.704916 10.1691 1.04533 10.5354 1.49712C11.2698 2.40285 12 3.75799 12 5.55447C12 7.35094 11.2698 8.70608 10.5354 9.61181C10.1691 10.0636 9.80151 10.404 9.52207 10.6338C9.38218 10.7488 9.26381 10.8365 9.17791 10.8971C9.13493 10.9273 9.10001 10.9509 9.07451 10.9676C9.06175 10.976 9.05135 10.9827 9.04345 10.9878L9.03352 10.994L9.03 10.9962L9.02862 10.9971L9.02802 10.9975C9.02802 10.9975 9.02748 10.9978 8.62213 10.5534L9.02802 10.9975C8.72953 11.1815 8.30554 11.1321 8.08168 10.8867C7.85798 10.6415 7.91841 10.2936 8.21631 10.1094Z"
        fill="#C1C4C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8991 11.5079C11.8565 11.8126 11.5215 12.0313 11.1509 11.9963L7.35418 11.6379C6.98351 11.6029 6.71752 11.3275 6.76008 11.0227L7.19601 7.90118C7.23857 7.59642 7.57357 7.37773 7.94424 7.41273C8.31491 7.44772 8.5809 7.72314 8.53834 8.0279L8.17947 10.5976L11.305 10.8927C11.6757 10.9277 11.9416 11.2031 11.8991 11.5079Z"
        fill="#C1C4C7"
      />
    </svg>
  )
}
