import { FC } from 'react'
import { IconProps } from './types'

export const LinkIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 4C13 3.44772 13.4477 3 14 3H20C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11C19.4477 11 19 10.5523 19 10V6.41452L10.0002 15.4144L8.58594 14.0002L17.5861 5H14C13.4477 5 13 4.55228 13 4Z"
        fill="currentColor"
      />
      <path
        d="M5.86667 7C5.63681 7 5.41637 7.09131 5.25384 7.25384C5.09131 7.41637 5 7.63681 5 7.86667V18.1333C5 18.3632 5.09131 18.5836 5.25384 18.7462C5.41637 18.9087 5.63681 19 5.86667 19H16.1333C16.3632 19 16.5836 18.9087 16.7462 18.7462C16.9087 18.5836 17 18.3632 17 18.1333V14.0078H19V18.1333C19 18.8936 18.698 19.6228 18.1604 20.1604C17.6228 20.698 16.8936 21 16.1333 21H5.86667C5.10638 21 4.37723 20.698 3.83963 20.1604C3.30202 19.6228 3 18.8936 3 18.1333V7.86667C3 7.10638 3.30202 6.37723 3.83963 5.83963C4.37723 5.30202 5.10638 5 5.86667 5H10.0078V7H5.86667Z"
        fill="currentColor"
      />
    </svg>
  )
}
