import { FC } from 'react'
import { IconProps } from './types'

export const MagicIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9997 6C9.74971 4.50002 10.4997 3.75001 11.9997 3C10.4997 2.24999 9.74971 1.50002 8.9997 0C8.24969 1.49998 7.49962 2.24999 5.9997 3C7.49972 3.75001 8.24969 4.50002 8.9997 6ZM12.7068 10.1213L4.82812 18L6.24234 19.4142L14.121 11.5355L12.7068 10.1213ZM14.121 8.70711L15.5352 10.1213L17.556 8.1005L16.1418 6.68629L14.121 8.70711ZM3.41391 16.5858C2.63286 17.3668 2.63286 18.6332 3.41391 19.4142L4.82812 20.8284C5.60917 21.6095 6.8755 21.6095 7.65655 20.8284L18.9703 9.51472C19.7513 8.73367 19.7513 7.46734 18.9703 6.68629L17.556 5.27208C16.775 4.49103 15.5087 4.49103 14.7276 5.27208L3.41391 16.5858ZM23.9997 15C22.4997 15.75 21.7497 16.5 20.9997 18C20.2497 16.5 19.4997 15.75 17.9997 15C19.4996 14.25 20.2497 13.5 20.9997 12C21.7497 13.5 22.4997 14.25 23.9997 15ZM20.4997 5C21.1247 3.75002 21.7497 3.12501 22.9997 2.5C21.7497 1.87499 21.1247 1.25002 20.4997 0C19.8747 1.24998 19.2496 1.87499 17.9997 2.5C19.2497 3.12501 19.8747 3.75002 20.4997 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
