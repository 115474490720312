export function debounce<Args extends unknown[]>(
  callback: (...args: Args) => void,
  wait: number,
) {
  let timerId: NodeJS.Timeout
  return (...args: Args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      callback(...args)
    }, wait)

    return {
      cancel: () => clearTimeout(timerId),
    }
  }
}
