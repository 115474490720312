import {
  Card,
  CheckIcon,
  ErrorIcon,
  InfoIcon,
  WarningIcon,
} from '@eframe-ui/react'
import { CrossIcon, IconButton } from '@eplix/ui'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SnackbarProps, SnackbarType } from './types'
import { asyncCall } from '@/common/helpers/async-wrapper'

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const SnackbarWithTranslate: FC<SnackbarProps> = ({
  className,
  isPersistent = false,
  color = 'text-primary',
  duration = 0,
  iconSize = 24,
  snackbarType,
  hideCloseIcon = false,
  cardStyle,
  callbackHide,
  children,
}) => {
  const [isTimeOut, setIsTimeout] = useState<boolean>(false)

  const countDown = useCallback(async () => {
    let isMounted = true
    await delay(duration)
    if (isMounted) {
      setIsTimeout(true)
    }

    return () => {
      isMounted = false
    }
  }, [duration])

  useEffect(() => {
    if (isPersistent) return
    asyncCall(countDown)

    return () => {
      setIsTimeout(false)
    }
  }, [countDown, duration, isPersistent])

  const iconMap = useMemo(() => {
    return {
      [SnackbarType.ERROR]: <ErrorIcon size={iconSize} />,
      [SnackbarType.SUCCESS]: <CheckIcon size={iconSize} />,
      [SnackbarType.INFO]: <InfoIcon size={iconSize} color={color} />,
      [SnackbarType.WARNING]: <WarningIcon size={iconSize} />,
    }
  }, [iconSize, color])

  const colorMap = useMemo(() => {
    return {
      [SnackbarType.ERROR]: '#FF7B72',
      [SnackbarType.SUCCESS]: '#7DDDAF',
      [SnackbarType.INFO]: '#89C9F8',
      [SnackbarType.WARNING]: '#FFB23E',
    }
  }, [])

  return (
    <div className={clsx(className, isTimeOut && !isPersistent && 'hidden')}>
      <Card
        bgColor={colorMap[snackbarType ?? SnackbarType.INFO]}
        className={clsx(cardStyle, 'gap-4 w-full justify-between items-center')}
        boRadius={'8px'}
      >
        <div>{iconMap[snackbarType ?? SnackbarType.INFO]}</div>
        <>{children}</>
        {!hideCloseIcon && (
          <div>
            <IconButton
              icon={<CrossIcon iconSize={24} color={color ?? 'black'} />}
              title={''}
              onClick={() => {
                callbackHide && callbackHide()
              }}
              variant="text"
              className="bg-transparent"
            />
          </div>
        )}
      </Card>
    </div>
  )
}
