import { FC, HTMLProps, ReactNode } from 'react'
import { ZendeskButton } from '@/components'
import clsx from 'clsx'

import classes from './mobile-header.module.scss'

export interface MobileHeaderProps extends HTMLProps<HTMLDivElement> {
  startAction?: ReactNode
}

export const MobileHeader: FC<MobileHeaderProps> = ({
  children,
  className,
  startAction,
}) => {
  return (
    <div className={clsx(classes.mobileHeader, className)}>
      <div className={classes.startAction}>{startAction}</div>
      <div className="flex justify-between w-full items-center">
        <div>{children}</div>
        <ZendeskButton />
      </div>
    </div>
  )
}
