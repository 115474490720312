import { FC } from 'react'
import { IconProps } from './types'

export const LayersIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5528 1.10557C11.8343 0.964809 12.1657 0.964809 12.4472 1.10557L22.4472 6.10557C22.786 6.27496 23 6.62123 23 7C23 7.37877 22.786 7.72504 22.4472 7.89443L12.4472 12.8944C12.1657 13.0352 11.8343 13.0352 11.5528 12.8944L1.55279 7.89443C1.214 7.72504 1 7.37877 1 7C1 6.62123 1.214 6.27496 1.55279 6.10557L11.5528 1.10557ZM4.23607 7L12 10.882L19.7639 7L12 3.11803L4.23607 7ZM1.10579 16.553C1.35278 16.059 1.95345 15.8588 2.44743 16.1058L12.0002 20.8822L21.553 16.1058C22.047 15.8588 22.6477 16.059 22.8946 16.553C23.1416 17.047 22.9414 17.6477 22.4474 17.8946L12.4474 22.8946C12.1659 23.0354 11.8345 23.0354 11.553 22.8946L1.55301 17.8946C1.05903 17.6477 0.858803 17.047 1.10579 16.553ZM2.44743 11.1058C1.95345 10.8588 1.35278 11.059 1.10579 11.553C0.858803 12.047 1.05903 12.6477 1.55301 12.8946L11.553 17.8946C11.8345 18.0354 12.1659 18.0354 12.4474 17.8946L22.4474 12.8946C22.9414 12.6477 23.1416 12.047 22.8946 11.553C22.6477 11.059 22.047 10.8588 21.553 11.1058L12.0002 15.8822L2.44743 11.1058Z"
        fill="currentColor"
      />
    </svg>
  )
}
