import clsx from 'clsx'
import classes from '@eplix/ui/inputs/text-input.module.scss'
import { ForwardedRef, forwardRef, memo, useState } from 'react'
import {
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
  FormFieldVariant,
} from '@eplix/ui'
import { useId } from '@eframe-ui/react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import frLocale from 'date-fns/locale/fr'
import itLocale from 'date-fns/locale/it'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { LocaleEnum } from '@/common/enums/Locale'
import { useTranslation } from 'react-i18next'

const localeMap: { [key: string]: Locale } = {
  [LocaleEnum.DE]: deLocale,
  [LocaleEnum.FR]: frLocale,
  [LocaleEnum.EN]: enLocale,
  [LocaleEnum.IT]: itLocale,
}

export interface TimeFieldProps {
  label: string
  className?: string
  name: string
  onChange: (value: Date) => void
  value: string
  helperText?: string
  error?: boolean
  disabled?: boolean
  variant?: FormFieldVariant
  minTime?: Date
}

export const TimeField = memo(
  forwardRef((props: TimeFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      label,
      className,
      value,
      onChange,
      helperText,
      error,
      disabled,
      variant,
      minTime,
    } = props
    const id = useId()
    const ariaLabelId = `label-${id}`
    const [dateValue, setDateValue] = useState<Date>(new Date(value))

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const { i18n } = useTranslation()
    const locale = localeMap[i18n.language] ?? enLocale

    const handleDateChange = (date: Date) => {
      if (!date) return
      onChange(date)
      setDateValue(date)
    }

    return (
      <FormFieldControl
        variant={variant}
        className={clsx(className, disabled && classes.disabled)}
      >
        <FormFieldLabel htmlFor={ariaLabelId} label={label} />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}
        >
          <TimePicker
            disabled={disabled}
            ref={ref}
            open={open}
            value={dateValue}
            minTime={minTime}
            mask="__:__ aa"
            disableMaskedInput={true}
            showToolbar={false}
            closeOnSelect={true}
            onClose={handleClose}
            inputFormat="hh:mm a"
            onChange={(date) => {
              handleDateChange(date ?? new Date())
            }}
            PopperProps={{ placement: 'bottom-start' }}
            className={clsx(className, classes.textInput)}
            renderInput={(params) => (
              <TextField
                disabled={disabled}
                {...params}
                fullWidth
                error={error}
                onClick={handleOpen}
                onKeyDown={(event) => {
                  event.preventDefault()
                }}
                placeholder="hh:mm a"
                className="text-text-primary"
                InputProps={{
                  style: {
                    fontFamily: 'inherit',
                    fontWeight: '300',
                    fontSize: '350',
                    font: 'var(--ex-typo-regular-300-font)',
                    color: 'var(--ex-rgb-text-primary)',
                    backgroundColor: 'var(--ex-rgb-surface-2)',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleOpen}>
                        <AccessTimeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
        {helperText && (
          <FormFieldHelperText
            style={{ color: 'var(--ex-rgb-signal-red)' }}
            helperText={helperText}
          />
        )}
      </FormFieldControl>
    )
  }),
)
