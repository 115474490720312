import clsx from 'clsx'
import React, {
  KeyboardEvent,
  RefObject,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useNumberFormatter } from '@react-aria/i18n'
import { useSlider, useSliderThumb } from '@react-aria/slider'
import { VisuallyHidden } from '@react-aria/visually-hidden'
import { SliderState, useSliderState } from '@react-stately/slider'
import { AriaSliderProps, AriaSliderThumbProps } from '@react-types/slider'

import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import classes from './slider.module.scss'

export interface SliderProps extends AriaSliderProps {
  startLabel?: string
  endLabel?: string
  width?: string
  minThresholdValue?: number
  maxThresholdValue?: number
  isDisabled?: boolean
  sliderUnit?: string
  thumbLabel?: string
  thumbLabelFontSize?: string
  thumbLabelWidth?: string
  valueFormatter?: (value: string) => string
  inputMaxCharNumber?: number
  isThumbEditDisabled?: boolean
  floatingLabelClass?: string
  floatingLabelWrapperClass?: string
  sliderThumbClass?: string
  sliderColor?: string
  labelStyle?: string
}

export interface SliderThumbProps extends AriaSliderThumbProps {
  index: number
  name: string
  state: SliderState
  trackRef: RefObject<HTMLElement>
}

export interface IndicatorTick {
  thresholdValue: number
  maxValue: number
  state: SliderState
  setValue: (value: number) => void
}

export const Slider = (props: SliderProps) => {
  const valueFormatter = props.valueFormatter
  const trackRef = useRef<HTMLDivElement>(null)
  const labelRef = useRef<HTMLSpanElement>(null)
  const sliderValueRef = useRef('')
  const [sliderValue, setSliderValue] = useState('')
  const [labelWidth, setLabelWidth] = useState(0)
  const [labelHeight, setLabelHeight] = useState(0)
  const [distance, setDistance] = useState<number | null>(null)
  const sliderLineRef = useRef<HTMLDivElement>(null)
  const numberFormatter = useNumberFormatter()
  const [isInputEditInProgress, setIsInputEditInProgress] = useState(false)
  const state: SliderState = useSliderState({
    ...props,
    numberFormatter,
  })

  const { groupProps, trackProps, labelProps } = useSlider(
    props,
    state,
    trackRef,
  )
  const {
    width = '100%',
    minThresholdValue,
    maxThresholdValue,
    maxValue,
    minValue,
    startLabel,
    endLabel,
    isDisabled,
    sliderUnit,
    isThumbEditDisabled,
    thumbLabelWidth,
    thumbLabelFontSize,
    floatingLabelClass,
    floatingLabelWrapperClass,
    sliderThumbClass,
    sliderColor,
    labelStyle,
  } = props

  sliderValueRef.current = sliderValue
  useEffect(() => {
    setSliderValue(state.getThumbValue(0)?.toString())
    // eslint-disable-next-line
  }, [state.getThumbValue(0)])

  useEffect(() => {
    // @ts-ignore
    setLabelWidth(labelRef.current.getBoundingClientRect().width)
  }, [sliderValue])

  useEffect(() => {
    // @ts-ignore
    setLabelHeight(labelRef.current.getBoundingClientRect().height)
  }, [sliderValue])

  useEffect(() => {
    if (labelRef.current && sliderLineRef.current) {
      const labelRect = labelRef.current.getBoundingClientRect()
      const sliderRect = sliderLineRef.current.getBoundingClientRect()
      const newDistance = labelRect.top - sliderRect.top
      setDistance(newDistance)
    }
  }, [sliderValue])

  const isBelowMinThresholdValue = (minThreshold: number) => {
    return minThreshold > state.getThumbValue(0)
  }

  const isAboveMaxThresholdValue = (maxThreshold: number) => {
    return maxThreshold < state.getThumbValue(0)
  }

  const setThumbValue = (value: number) => {
    state.setThumbValue(0, value)
  }

  const setThresholdClasses = () => {
    return [
      minThresholdValue &&
        isBelowMinThresholdValue(minThresholdValue) &&
        classes.belowMinValue,
      maxThresholdValue &&
        isAboveMaxThresholdValue(maxThresholdValue) &&
        classes.aboveMaxValue,
    ].filter(Boolean)
  }

  const handleSliderValueChange = (event: ContentEditableEvent) => {
    if (event.target.value && !isNaN(Number(event.target.value))) {
      setSliderValue(event.target.value)
    } else {
      setSliderValue('')
    }
  }

  const calculateThumbValue = () => {
    if (!sliderValueRef.current?.trim()) {
      return '0'
    }

    if (maxValue && Number(sliderValueRef.current) > maxValue) {
      setSliderValue(maxValue.toString())
      return maxValue.toString()
    } else if (minValue && Number(sliderValueRef.current) < minValue) {
      setSliderValue(minValue.toString())
      return minValue.toString()
    } else {
      return sliderValueRef.current
    }
  }

  const handleSliderValueBlur = () => {
    setIsInputEditInProgress(false)
    if (sliderValueRef.current?.trim()) {
      const thumbValue = Number(calculateThumbValue())
      state.setThumbValue(0, thumbValue)
      props.onChangeEnd && props.onChangeEnd([thumbValue])
    } else {
      setSliderValue(state.getThumbValueLabel(0))
    }
  }

  const handleSliderValueKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      // @ts-ignore
      event.target.blur()
      event.stopPropagation()
    } else if (['ArrowLeft', 'ArrowRight', 'Backspace'].includes(event.key)) {
      return
      // @ts-ignore
    } else if (isNaN(event.key)) {
      event.preventDefault()
    }
  }

  const handlePaste = (event: React.ClipboardEvent) => {
    const pasteText = event.clipboardData.getData('text')
    if (isNaN(Number(pasteText))) {
      event.preventDefault()
    }
  }

  return (
    <div
      {...groupProps}
      role="slider"
      aria-valuemin={minValue}
      aria-valuemax={maxValue}
      aria-valuenow={Number(sliderValue)}
      aria-valuetext={`${sliderValue}${sliderUnit}`}
      className={clsx(classes.slider, isDisabled && classes.disabled)}
      style={{
        width,
      }}
    >
      <span
        className={clsx(
          classes.floatingLabelWrap,
          floatingLabelWrapperClass,
          ...setThresholdClasses(),
          state.isThumbDragging(0) && classes.floatingLabelDrag,
        )}
        style={{
          left: `clamp(${labelWidth / 2}px, ${
            state.getThumbPercent(0) * 100
          }%, calc(100% - ${labelWidth / 2}px))`,
          transform: `translateX(-50%)`,
          backgroundColor: sliderColor,
        }}
      >
        <span
          ref={labelRef}
          className={clsx(
            thumbLabelWidth,
            classes.floatingLabel,
            sliderUnit && classes.withUnit,
            'px-1',
            floatingLabelClass,
          )}
          style={{ whiteSpace: 'break-spaces' }}
        >
          <p className={clsx(thumbLabelFontSize, 'pr-1')}>{props.thumbLabel}</p>
          <ContentEditable
            inputMode="numeric"
            className={clsx(thumbLabelFontSize, classes.sliderValue)}
            html={
              valueFormatter && !isInputEditInProgress
                ? valueFormatter(sliderValueRef.current)
                : sliderValueRef.current
            }
            onChange={isThumbEditDisabled ? () => {} : handleSliderValueChange}
            onBlur={isThumbEditDisabled ? () => {} : handleSliderValueBlur}
            onPaste={isThumbEditDisabled ? () => {} : handlePaste}
            onKeyDown={
              isThumbEditDisabled ? () => {} : handleSliderValueKeyDown
            }
            disabled={isThumbEditDisabled}
            onFocus={() => setIsInputEditInProgress(true)}
            onBeforeInput={(e) => {
              if (
                props.inputMaxCharNumber &&
                sliderValueRef.current.length >= props.inputMaxCharNumber
              ) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          />
          <span>{sliderUnit}</span>
        </span>
      </span>
      <div {...trackProps} ref={trackRef} className={classes.sliderLineWrap}>
        {!state.isThumbDragging(0) && (
          <span
            className={clsx(classes.arrowDownWrap, ...setThresholdClasses())}
            style={{
              left: `clamp(10px, calc(${
                state.getThumbPercent(0) * 100
              }%), calc(100% - 10px))`,
              transform: `translateX(-50%)`,
              top: `calc(${distance}px + ${labelHeight}px + 4px)`,
              backgroundColor: sliderColor,
            }}
          >
            <span className={classes.arrowDown} />
          </span>
        )}
        <div ref={sliderLineRef} className={classes.sliderLine}>
          <div
            className={clsx(classes.sliderLineInner, ...setThresholdClasses())}
            style={{
              width: `${state.getThumbPercent(0) * 100}%`,
              position: 'relative',
              background: sliderColor,
            }}
          />
        </div>

        {minThresholdValue && maxValue && (
          <IndicatorTick
            thresholdValue={minThresholdValue}
            maxValue={maxValue}
            state={state}
            setValue={setThumbValue}
          />
        )}
        {maxThresholdValue && maxValue && (
          <IndicatorTick
            thresholdValue={maxThresholdValue}
            maxValue={maxValue}
            state={state}
            setValue={setThumbValue}
          />
        )}
        <Thumb
          name="slider_thumb"
          index={0}
          state={state}
          trackRef={trackRef}
          className={clsx(sliderThumbClass, ...setThresholdClasses())}
        />
      </div>
      <div className={classes.sliderLabels}>
        {startLabel && (
          <label {...labelProps} style={{ fontSize: labelStyle }}>
            {startLabel}
          </label>
        )}
        {endLabel && (
          <label {...labelProps} style={{ fontSize: labelStyle }}>
            {endLabel}
          </label>
        )}
      </div>
    </div>
  )
}

const Thumb = forwardRef<
  HTMLDivElement,
  SliderThumbProps & { className: string; sliderThumbClass?: string }
>(({ state, trackRef, index, className, sliderThumbClass, ...props }, ref) => {
  const inputRef = React.useRef(null)
  const { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state,
  )

  return (
    <div
      {...thumbProps}
      ref={ref}
      className={clsx(
        classes.sliderThumb,
        sliderThumbClass,
        className,
        state.isThumbDragging(index) && classes.thumbDrag,
      )}
      style={{
        left: `${state.getThumbPercent(index) * 100}%`,
      }}
    >
      <div className={clsx(classes.sliderThumbInner)}>
        <VisuallyHidden>
          <input ref={inputRef} {...inputProps} data-cy={props.name} />
        </VisuallyHidden>
      </div>
    </div>
  )
})

const IndicatorTick = ({
  thresholdValue,
  maxValue,
  state,
  setValue,
}: IndicatorTick) => {
  return (
    <div
      className={classes.thresholdIndicator}
      style={{
        left: `${(thresholdValue / maxValue) * 100}%`,
        zIndex: state.getThumbValue(0) === thresholdValue ? 0 : 1,
      }}
      onClick={() => setValue(thresholdValue)}
    />
  )
}
