import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { NavigationDrawerContent } from './navigation-drawer-content'
import { EplixLogo } from '@eplix/ui/logos'

import classes from './navigation-drawer.module.scss'
import { EplixIcon } from '@eplix/ui/icons/eplix-icon'

export interface NavigationDrawerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  collapsed?: boolean
  disabled?: boolean
}

const COLLAPSED_WIDTH = '72px'
const EXPANDED_WIDTH = '256px'

export const NavigationDrawer = forwardRef<HTMLElement, NavigationDrawerProps>(
  ({ collapsed, disabled, ...props }, ref) => {
    return (
      <motion.nav
        ref={ref}
        style={{ ...props.style }}
        className={clsx(
          classes.navigationDrawerBase,
          collapsed && classes.collapsed,
          !collapsed && 'p-6 justify-start',
          props.className,
        )}
        transition={{ duration: 0.25, ease: 'easeInOut' }}
        initial={{
          width: collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
          transform: disabled ? 'translate(-100%, 0)' : 'translate(0, 0)',
          marginRight: disabled
            ? collapsed
              ? `-${COLLAPSED_WIDTH}`
              : `-${EXPANDED_WIDTH}`
            : 0,
        }}
        animate={{
          width: collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
          transform: disabled ? 'translate(-100%, 0)' : 'translate(0%, 0)',
          marginRight: disabled
            ? collapsed
              ? `-${COLLAPSED_WIDTH}`
              : `-${EXPANDED_WIDTH}`
            : 0,
        }}
      >
        <NavigationDrawerContent>
          <div
            className={clsx(
              classes.navigationHeader,
              collapsed && 'justify-center',
            )}
          >
            {collapsed ? <EplixIcon width={28} height={24} /> : <EplixLogo />}
          </div>
        </NavigationDrawerContent>
        <div className={clsx(collapsed && 'px-2')}>{props.children}</div>
      </motion.nav>
    )
  },
)
