import { FC } from 'react'
import { IconProps } from './types'

export const ChartLegendIcon: FC<IconProps> = ({
  iconSize = 12,
  color = '#3A85F8',
  colorInterpolation = '#CBDDF6',
  ...props
}) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 ${iconSize} ${iconSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="12" height="2" fill={color} />
      <rect y="2.5" width="12" height="10" fill={String(colorInterpolation)} />
    </svg>
  )
}
