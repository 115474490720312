import { FC } from 'react'
import { IconProps } from './types'

export const EyeOpenIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4256 11.2925C21.6051 11.5704 21.75 11.8111 21.8593 12C21.75 12.1889 21.6051 12.4296 21.4256 12.7075C20.9646 13.4213 20.2818 14.3706 19.3955 15.3161C17.6038 17.2272 15.0999 19 12 19C8.90013 19 6.39619 17.2272 4.60454 15.3161C3.71816 14.3706 3.03543 13.4213 2.57441 12.7075C2.39492 12.4296 2.25003 12.1889 2.14074 12C2.25003 11.8111 2.39492 11.5704 2.57441 11.2925C3.03543 10.5787 3.71816 9.62941 4.60454 8.68394C6.39619 6.77285 8.90013 5 12 5C15.0999 5 17.6038 6.77285 19.3955 8.68394C20.2818 9.62941 20.9646 10.5787 21.4256 11.2925ZM23.1057 13.7925C23.3602 13.3985 23.5542 13.0684 23.686 12.834L23.8944 12.4472C24.0352 12.1657 24.0348 11.8336 23.8941 11.5521L23.686 11.166C23.5542 10.9316 23.3602 10.6015 23.1057 10.2075C22.5979 9.42131 21.8432 8.3706 20.8545 7.31606C18.8955 5.22644 15.8995 3 12 3C8.10049 3 5.10448 5.22644 3.14546 7.31606C2.15683 8.3706 1.40207 9.42131 0.894336 10.2075C0.63985 10.6015 0.445792 10.9316 0.313971 11.166L0.105573 11.5528C-0.0351909 11.8343 -0.0351909 12.1657 0.105573 12.4472L0.313971 12.834C0.445792 13.0684 0.63985 13.3985 0.894336 13.7925C1.40207 14.5787 2.15683 15.6294 3.14546 16.6839C5.10448 18.7736 8.10049 21 12 21C15.8995 21 18.8955 18.7736 20.8545 16.6839C21.8432 15.6294 22.5979 14.5787 23.1057 13.7925ZM8.86133 11.9688C8.86133 10.3119 10.2045 8.96875 11.8613 8.96875C13.5182 8.96875 14.8613 10.3119 14.8613 11.9688C14.8613 13.6256 13.5182 14.9688 11.8613 14.9688C10.2045 14.9688 8.86133 13.6256 8.86133 11.9688ZM11.8613 6.96875C9.09991 6.96875 6.86133 9.20733 6.86133 11.9688C6.86133 14.7302 9.09991 16.9688 11.8613 16.9688C14.6228 16.9688 16.8613 14.7302 16.8613 11.9688C16.8613 9.20733 14.6228 6.96875 11.8613 6.96875Z"
        fill="currentColor"
      />
    </svg>
  )
}
