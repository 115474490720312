import { FC } from 'react'
import { IconProps } from './types'

export const EyeCloseIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74016 9.32733C2.36866 8.91868 1.73621 8.88856 1.32755 9.26007C0.918892 9.63157 0.888775 10.264 1.26028 10.6727L1.33721 10.7573L1.33721 10.7573L1.33721 10.7573L1.33723 10.7574C1.62757 11.0769 1.94455 11.4258 2.29558 11.7869L0.953863 13.1063C0.550499 13.503 0.53743 14.1438 0.92467 14.5375C1.31191 14.9313 1.95282 14.929 2.35618 14.5323L3.76493 13.147C4.37678 13.6471 5.06986 14.1304 5.86333 14.5526L5.29194 16.2635C5.11699 16.7873 5.39983 17.3538 5.92368 17.5287C6.44752 17.7037 7.014 17.4209 7.18895 16.897L7.70813 15.3424C8.67852 15.6642 9.76898 15.8887 11.0002 15.9682V17.8906C11.0002 18.4429 11.4479 18.8906 12.0002 18.8906C12.5525 18.8906 13.0002 18.4429 13.0002 17.8906V15.9682C14.0563 15.9001 15.0088 15.7252 15.8708 15.4737L16.4492 17.2056C16.6241 17.7294 17.1906 18.0123 17.7144 17.8373C18.2383 17.6624 18.5211 17.0959 18.3462 16.5721L17.7393 14.7548C18.6011 14.3368 19.3505 13.8446 20.0091 13.3283L21.3123 14.5632C21.7132 14.9431 22.3461 14.9261 22.726 14.5252C23.1059 14.1243 23.0889 13.4914 22.688 13.1115L21.5047 11.9902C21.9367 11.5571 22.3187 11.1366 22.6632 10.7573L22.7402 10.6727C23.1117 10.264 23.0816 9.63157 22.6729 9.26007C22.2642 8.88856 21.6318 8.91868 21.2603 9.32733L21.2073 9.38568C19.4166 11.356 17.0136 14 12.0002 14C6.98688 14 4.58386 11.356 2.79319 9.38568L2.74016 9.32733Z"
        fill="currentColor"
      />
    </svg>
  )
}
