import {
  ReactNode,
  cloneElement,
  Children,
  isValidElement,
  useState,
  useMemo,
  useCallback,
  ReactElement,
} from 'react'
import { Listbox } from '@headlessui/react'
import { Dialog, DialogMain } from '../dialog'
import {
  SelectInputOptions,
  SelectInputOptionsProps,
} from './select-input-options'
import classes from './select-input.module.scss'
import { SelectInputButton } from './select-input-button'
import { OptionModel } from './option-model'

export type SelectInputProps<TType = string | OptionModel> = {
  value: TType | TType[]
  label?: string | JSX.Element
  onChange(value: TType | TType[]): void
  disabled?: boolean
  children?: ReactNode
  multiple?: boolean
}

export const SelectInput = ({
  children,
  label,
  value,
  onChange,
  disabled,
  multiple,
}: SelectInputProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  // split the select options from the rest of the children elements
  const [options, rest] = useMemo(() => {
    const childrenArray = Children.toArray(children).filter(
      isValidElement,
    ) as ReactElement[]

    return [
      childrenArray.filter((child) => child.type === SelectInputOptions),
      childrenArray
        .filter((child) => child.type !== SelectInputOptions)
        .map((child) =>
          // the select input button allows us to detect the touch devices
          child.type === SelectInputButton
            ? cloneElement(child, {
                onTouchStart: () => setIsModalOpen(true),
              })
            : child,
        ),
    ]
  }, [children])
  // this rendering function will be used for the modal content as for the plain Listbox Component
  const renderOptions = useCallback(
    (props: Partial<SelectInputOptionsProps>) =>
      Children.map(options, (child) => cloneElement(child, props)),
    [options],
  )

  return (
    <Listbox
      value={value}
      onChange={onChange}
      disabled={disabled}
      multiple={multiple}
    >
      {({ open }) => (
        <>
          {rest}
          <Dialog
            title={label}
            variant={'select'}
            isOpen={open && isModalOpen}
            onClose={setIsModalOpen}
          >
            <DialogMain>
              {renderOptions({
                open: true,
                className: classes.modalOptions,
              })}
            </DialogMain>
          </Dialog>
          {renderOptions({ open: open && !isModalOpen })}
        </>
      )}
    </Listbox>
  )
}
