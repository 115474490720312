import { Translate } from '@eplix/ui'
import { Card, WarningIcon } from '@eframe-ui/react'
import clsx from 'clsx'
import classes from './warning-message-card.module.scss'
import { memo } from 'react'

export interface WarningMessageCardProps {
  label: string
  className?: string
  bgColor?: string
}

export const WarningMessageCard = memo(
  ({ label, className, bgColor = '#FFB23E' }: WarningMessageCardProps) => {
    return (
      <div className={clsx(classes.warningMessage, className)}>
        <Card
          className={'gap-4 w-full justify-center items-center'}
          bgColor={bgColor}
        >
          <div>
            <WarningIcon size={24} />
          </div>
          <Translate label={label} color={'black'} />
        </Card>
      </div>
    )
  },
)
