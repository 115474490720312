import { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

interface DisplayApiErrorProps {
  apiErrorMessage: string
  translationKey?: string
  className?: string
}

export const DisplayApiError = forwardRef(
  (
    { apiErrorMessage, translationKey, className }: DisplayApiErrorProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation()
    return (
      <>
        {apiErrorMessage && (
          <div
            ref={ref}
            style={{
              color: 'var(--ex-rgb-signal-red)',
              marginBottom: 'var(--ex-len-6)',
            }}
            className={className}
          >
            {t(`${translationKey}.${apiErrorMessage}`, apiErrorMessage)}
          </div>
        )}
      </>
    )
  },
)
