export enum UserSessionStatusEnum {
  ANONYMOUS = 'anonymous',
  AUTHENTICATING = 'authenticating',
  AUTHENTICATED = 'authenticated',
  PASSWORD_CHANGE = 'password-change',
  REGISTERING = 'registering',
  REGISTERED = 'registered',
  AUTHENTICATED_WITHOUT_ACCEPTED_DOCUMENTS = 'authenticated-without-accepted-documents',
  OFFLINE = 'offline',
  WITHOUT_ADDRESS = 'without-address',
  WITHOUT_PERMIT = 'without-permit',
}
