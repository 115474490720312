import { Listbox } from '@headlessui/react'
import { ForwardedRef, forwardRef, Fragment, HTMLProps } from 'react'
import { MenuItem } from '../menu-list'
import { OptionModel } from './option-model'

export type SelectInputOptionProps<TType = unknown> =
  HTMLProps<HTMLLIElement> & {
    optionValue: TType
    dataCy?: string | OptionModel
  }

export const SelectInputOption = forwardRef(
  (
    {
      optionValue,
      dataCy = (optionValue as OptionModel).value?.toString(),
      ...props
    }: SelectInputOptionProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    return (
      <Listbox.Option
        {...props}
        value={optionValue}
        as={Fragment}
        data-cy={`dd_option_${dataCy}`}
      >
        {({ selected, active }) => (
          <MenuItem {...props} selected={selected} active={active} ref={ref}>
            {props.children}
          </MenuItem>
        )}
      </Listbox.Option>
    )
  },
)
