import { FC } from 'react'
import { IconProps } from './types'

export const FavIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.5C12.3513 2.5 12.6768 2.6843 12.8575 2.9855L15.6199 7.58952L21.1644 8.51361C21.5143 8.57193 21.8072 8.81129 21.934 9.14263C22.0608 9.47396 22.0025 9.84767 21.7809 10.1247L18.0598 14.776L18.9864 20.3356C19.0482 20.7066 18.8967 21.0809 18.5941 21.3044C18.2916 21.5278 17.8892 21.5626 17.5528 21.3944L12 18.618L6.44723 21.3944C6.11081 21.5626 5.70846 21.5278 5.40591 21.3044C5.10336 21.0809 4.95178 20.7066 5.01362 20.3356L5.94021 14.776L2.21914 10.1247C1.99752 9.84767 1.93927 9.47396 2.06605 9.14263C2.19283 8.81129 2.48567 8.57193 2.83561 8.51361L8.38011 7.58952L11.1425 2.9855C11.3232 2.6843 11.6487 2.5 12 2.5ZM12 5.44365L9.8575 9.0145C9.70676 9.26574 9.45342 9.43823 9.16441 9.48639L4.84559 10.2062L7.78088 13.8753C7.95839 14.0972 8.03312 14.3841 7.98641 14.6644L7.30925 18.7273L11.5528 16.6056C11.8343 16.4648 12.1657 16.4648 12.4472 16.6056L16.6908 18.7273L16.0136 14.6644C15.9669 14.3841 16.0416 14.0972 16.2191 13.8753L19.1544 10.2062L14.8356 9.48639C14.5466 9.43823 14.2933 9.26574 14.1425 9.0145L12 5.44365Z"
        fill="currentColor"
      />
    </svg>
  )
}
