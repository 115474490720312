import styled, { css } from 'styled-components'

const PointerTooltipBubble = (
  // @ts-ignore
  { text, innerRef, ...remainingProps },
) => {
  return (
    <div ref={innerRef} {...remainingProps}>
      {text}
    </div>
  )
}

const PointerTooltip = styled(PointerTooltipBubble)`
  ${() => css`
    background: transparent;
    color: black;
    font-weight: lighter;
    font-size: 12px;
    z-index: 4;
  `}
`

export default PointerTooltip
