import { FC } from 'react'
import { IconProps } from './types'

export const WarningIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7072 3.22384C11.2872 2.23788 12.7131 2.23789 13.293 3.22384L22.4188 18.7377C23.007 19.7376 22.2861 20.9982 21.1259 20.9982H2.87435C1.71422 20.9982 0.993238 19.7376 1.58145 18.7377L10.7072 3.22384ZM12.0001 4.97053L3.74855 18.9982H20.2517L12.0001 4.97053ZM11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V15H11V10ZM13 17V16H11V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17Z"
        fill="currentColor"
      />
    </svg>
  )
}
