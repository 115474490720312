import { atom, PrimitiveAtom, useAtom } from 'jotai'
import { GlobalValueKey } from '@/framework'

/* eslint-disable @typescript-eslint/no-explicit-any */
const state: Record<string, PrimitiveAtom<any>> = {}
type GlobalValueInterface<T> = [T, (update: any) => void, () => void]

export const useGlobalValue = <T>(key: GlobalValueKey, defaultValue?: T) => {
  const initialValue = defaultValue ?? null
  if (Object.keys(state).indexOf(key) === -1) {
    state[key] = atom(initialValue)
  }

  const [value, set] = useAtom(state[key])
  const reset = () => set(initialValue)

  return [value, set, reset] as GlobalValueInterface<T>
}
