export const RequestDateFormat = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_M_DD: 'YYYY.M.DD',
  DD_MM_YYYY: 'DD.MM.YYYY',
  dd_MM_yyyy: 'dd.MM.yyyy',
  DD_MM_YYYY_TIME: 'DD.MM.YYYY HH:mm',
  DD_MM_YYYY_TIME_SECONDS: 'DD.MM.YYYY HH:mm:ss',
  YYYY_MM_DD_TIME_SECONDS: 'YYYY.MM.DD HH:mm:ss',
  YYYY_MM_DD_HH_MM: 'YYYY-MM-DD HH:mm',
  yyyy_MM_dd: 'yyyy-MM-dd',
  YYYY_MM: 'YYYY-MM',
  YYYY_MM__DD_TIME_SECONDS_MILLISECONDS: 'YYYY-MM-DD HH:mm:ss:SSS',
  YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH:mm:ss',
  HH_MM_SS: 'HH:mm:ss.SSSSSS',
  DDD_MM_YYYY_HH_mm: 'DD.MM.YYYY, HH:mm',
  HH_MM: 'HH:mm',
  YYYY_MM_DDTHH_mm_ssZ: 'YYYY-MM-DDTHH:mm:ssZ',
  DDD_MM_DD_YYYY_HH_mm_ss_ZZ: 'ddd MMM DD YYYY HH:mm:ss ZZ',
  YYYY_MM_DD_HH_MM_SS: 'YYYY-MM-DD HH:mm:ss',
  DDD_MMM_DD_YYYY_HH_mm_ss_Z: 'ddd MMM DD YYYY HH:mm:ss Z',
}

export const DisplayDateFormat = {
  MMMM_YYYY: 'MMMM YYYY',
  MMMM_DD_YYYY: 'MMMM DD, YYYY',
  YEAR: 'YYYY',
  MONTH: 'M',
  MM: 'MM',
  DD: 'DD',
  YYYY_MM: 'YYYY-MM',
  YYYY__MM__DD: 'YYYY.MM.DD',
  YYYY_MM_DD: 'YYYY-MM-DD',
  yyyy_MM_dd: 'yyyy-MM-dd',
  YYYY_M_DD: 'YYYY.M.DD',
  DD_MM_YYYY: 'DD.MM.YYYY',
  MM_DD_YYYY: 'MM.DD.YYYY',
  DD_MM_YYYY_TIME: 'DD.MM.YYYY HH:mm',
  DD_MM_YYYY__TIME: 'DD.MM.YYYY, HH:mm',
  DD_MMM_YYYY_TIME: 'DD MMM YYYY HH:mm',
  DD_MM_YYYY_TIME_SECONDS: 'DD.MM.YYYY HH:mm:ss',
  YYYY_MM_DD_TIME_SECONDS: 'YYYY MM DD HH:mm:ss',
  dd_MM_yyyy: 'dd-MM-yyyy',
  SHORT_MONTH: 'MMM',
}
