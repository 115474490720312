import {
  createElement,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  FormFieldBorderBox,
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
} from './form-field'
import { ChevronDownIcon, FlagIcon } from '../icons'
import {
  SelectInput,
  SelectInputButton,
  SelectInputOption,
  SelectInputOptions,
  TextInput,
} from '../inputs'
import classes from './phone-number-field.module.scss'
import clsx from 'clsx'
import { Typo } from '../typography'
import { useId } from '@eframe-ui/react'

export interface AreaCodeProps {
  name: string
  code: string
  exampleNumber: string
  flag: JSX.Element
  countryAbbrevation: string
}

export interface PhoneNumberFieldProps {
  label: string
  helpertext?: string
  className?: string
  onChange?: (val: string) => void
  disabled?: boolean
  areaCode: AreaCodeProps[]
  optionsRef?:
    | ((ref?: HTMLUListElement | null) => void)
    | MutableRefObject<HTMLUListElement | null>
}

export const PhoneNumberField = (props: PhoneNumberFieldProps) => {
  const { label, helpertext, className, areaCode, onChange, ...rest } = props
  const htmlOptionRef = useRef<HTMLUListElement | null>(null)
  const [selectedCountry, setSelectedCountry] = useState<AreaCodeProps>(
    areaCode[0],
  )
  const id = useId()
  const ariaLabelId = `label-${id}`
  const [phoneNumber, setPhoneNumber] = useState('')

  const isAtBottom = useMemo(() => {
    if (!htmlOptionRef.current) {
      return false
    }
    const { top, height } = htmlOptionRef.current.getBoundingClientRect() || {
      top: 0,
      height: 0,
    }

    return window.innerHeight < top + height
  }, [])

  const selectCountry = (countryCode: string) => {
    const foundIndex = areaCode.findIndex((val) => val.code == countryCode)

    const selectedAreaCode =
      foundIndex >= 0 ? areaCode[foundIndex] : areaCode[0]
    setSelectedCountry(selectedAreaCode)
  }

  useEffect(() => {
    onChange && onChange(`${selectedCountry.code} ${phoneNumber}`)
  }, [onChange, selectedCountry, phoneNumber])

  return createElement(
    'div',
    {
      ...rest,
      className: clsx(props.className),
    },
    <FormFieldControl className={className}>
      <FormFieldLabel htmlFor={ariaLabelId} label={label} />
      <FormFieldBorderBox className={clsx(classes.borderBox)}>
        <SelectInput
          value={selectedCountry.code}
          onChange={(countryCode) => selectCountry(countryCode as string)}
          disabled={rest.disabled}
        >
          <SelectInputButton
            className={clsx(classes.button)}
            disabled={rest.disabled}
          >
            {({ open }) => {
              return (
                <>
                  <FlagIcon />
                  <span className={classes.phoneLabel}>
                    {selectedCountry.code}
                  </span>
                  <ChevronDownIcon
                    className={clsx(
                      classes.chevron,
                      open && classes.chevronOpen,
                    )}
                  />
                </>
              )
            }}
          </SelectInputButton>
          <SelectInputOptions
            className={clsx(classes.options, isAtBottom && classes.optionsTop)}
          >
            {areaCode.map((country, idx) => {
              return (
                <SelectInputOption
                  key={idx}
                  optionValue={country.code}
                  dataCy={country.code}
                >
                  {country.flag != undefined ? country.flag : null}
                  <span className={classes.phoneOptionsText}>
                    <Typo as="p" variant="regular-300" color="text-primary">
                      {country.name}
                    </Typo>
                    <Typo as="p" variant="regular-300" color="text-secondary">
                      {country.code}
                    </Typo>
                  </span>
                </SelectInputOption>
              )
            })}
          </SelectInputOptions>
        </SelectInput>
        <TextInput
          name="phoneNumber"
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </FormFieldBorderBox>
      {helpertext && <FormFieldHelperText helperText={helpertext} />}
    </FormFieldControl>,
  )
}
