import { Placement } from '../tooltip-content'
import { getTransform } from './get-transform'

export const getSpikeTransform = (
  targetRect?: DOMRect,
  toolTipRect?: Pick<DOMRect, 'width' | 'left' | 'top' | 'height'>,
  spikeRect?: DOMRect,
  placement?: Placement | null,
  contentComputedStyles?: CSSStyleDeclaration,
) => {
  const unitRegex = /(px|ch|[%]|pt|pc|mm|cm|in|em|ex|rem|vw|vh|vmin|vmax)/
  const borderRadius = contentComputedStyles
    ? {
        topLeft:
          Number(
            contentComputedStyles.borderTopLeftRadius.split(unitRegex)[0],
          ) || 0,
        topRight:
          Number(
            contentComputedStyles.borderTopRightRadius.split(unitRegex)[0],
          ) || 0,
        bottomLeft:
          Number(
            contentComputedStyles.borderBottomLeftRadius.split(unitRegex)[0],
          ) || 0,
        bottomRight:
          Number(
            contentComputedStyles.borderBottomRightRadius.split(unitRegex)[0],
          ) || 0,
      }
    : { topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }

  const position = { left: 0, top: 0 }

  if (!targetRect || !spikeRect || !toolTipRect) {
    return { transform: '' }
  }

  if (!placement || placement === 'top' || placement === 'bottom') {
    const radius =
      placement === 'top' || !placement
        ? { left: borderRadius.topLeft, right: borderRadius.topRight }
        : { left: borderRadius.bottomLeft, right: borderRadius.bottomRight }
    position.left =
      targetRect.left +
      targetRect.width / 2 -
      spikeRect.width / 2 -
      toolTipRect.left

    if (position.left < radius.left) {
      position.left = radius.left
    }
    if (position.left > toolTipRect.width - radius.right - spikeRect.width) {
      position.left = toolTipRect.width - spikeRect.width - radius.right
    }
  }

  if (placement === 'left' || placement === 'right') {
    const radius =
      placement === 'left'
        ? { top: borderRadius.topLeft, bottom: borderRadius.bottomLeft }
        : { top: borderRadius.topRight, bottom: borderRadius.bottomRight }
    position.top =
      targetRect.top +
      targetRect.height / 2 -
      spikeRect.height / 2 -
      toolTipRect.top

    if (position.top < radius.top) {
      position.top = radius.top
    }
    if (position.top > toolTipRect.height - radius.bottom - spikeRect.height) {
      position.top = toolTipRect.height - radius.bottom - spikeRect.height
    }
  }

  return { transform: getTransform(position) }
}
