import { DisplayApiError } from '@/framework/error-handling/display-api-error'
import { Typo } from '@eplix/ui'
import { Trans, useTranslation } from 'react-i18next'
import { LoginErrorMessageProps } from '@/framework/auth/components/login-error-message/types'

export const LoginErrorMessage = ({ errorMessage }: LoginErrorMessageProps) => {
  const { t } = useTranslation()
  switch (errorMessage) {
    case 'INCORRECT_CREDENTIALS':
      return (
        <Typo as="p" color="signal-red" variant="regular-300" className="mb-4">
          <Trans
            i18nKey={'USM-Login:ResponseError.INCORRECT_CREDENTIALS'}
            t={t}
            components={[
              <a key={'0'} className="underline" href="/registration" />,
            ]}
          />
        </Typo>
      )
    case 'ACCOUNT_IS_BLOCKED':
      return (
        <Typo as="p" color="signal-red" variant="regular-300" className="mb-4">
          <Trans
            i18nKey={'USM-Login:ResponseError.ACCOUNT_IS_BLOCKED'}
            t={t}
            components={[
              <a
                key={'0'}
                className="text-blue"
                href="/registration"
                title="/registration"
              >
                registration
              </a>,
              <a
                key={'1'}
                className="text-blue"
                href="mailto:helpdesk@eplix.ch"
                title="mailto:helpdesk@eplix.ch"
              >
                helpdesk@eplix.ch
              </a>,
            ]}
          />
        </Typo>
      )
    default:
      return (
        <DisplayApiError
          apiErrorMessage={errorMessage}
          translationKey="USM-Login:ResponseError"
        />
      )
  }
}
