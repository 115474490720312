import { FC } from 'react'
import { IconProps } from './types'

export const FlagIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13.5658V5.66755C6.17713 5.24293 8.884 4.55861 11.5528 5.893C14.4761 7.35465 17.3702 6.90327 19 6.43091V14.3298C17.8231 14.7548 15.1163 15.4398 12.4475 14.1043C9.52415 12.6413 6.62997 13.0931 5 13.5658ZM12.4472 4.10414C8.67402 2.21755 4.94791 3.51464 3.89904 3.94943C3.32298 4.18822 3 4.74454 3 5.30759V14.1892C3 14.2485 3.00316 14.3065 3.00929 14.3631C3.00316 14.4079 3 14.4536 3 14.5V22H5V15.6646C5.02625 15.657 5.05237 15.6486 5.07834 15.6395C6.32326 15.2038 8.95662 14.5938 11.5525 15.8928C15.3261 17.7813 19.0527 16.4828 20.1014 16.0476C20.6772 15.8087 21 15.2526 21 14.6897V5.80759C21 4.68176 19.8601 4.02913 18.9221 4.35719C17.6772 4.79258 15.0434 5.40225 12.4472 4.10414Z"
        fill="currentColor"
      />
    </svg>
  )
}
