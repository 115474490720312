import { ZipCodes } from '@/framework/zip-codes'

export const getCityBasedOnZipCode = (
  zip: number,
  country: string,
  switzerlandName: string,
) => {
  if (zip && country === switzerlandName) {
    return ZipCodes[zip] || ''
  }
}
