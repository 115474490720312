import useMediaQuery from '@eplix/ui/utils/use-media-query'
import { Document } from '@/features/registration/components/documents/document'
import { Drawer } from '@/features/registration/components/drawer/drawer'
import { DocumentsEnum } from '@/common/enums'
import clsx from 'clsx'
import Image from 'next/image'
import { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import screenSize from '@eplix/ui/foundation/media-queries.json'
interface AuthLayoutProps {
  closeDrawer?: () => void
  drawerDocument?: DocumentsEnum | null
  children?: Array<JSX.Element> | JSX.Element
  sectionStyle?: string
}

export const AuthLayout = forwardRef(
  (
    { closeDrawer, drawerDocument, children, sectionStyle }: AuthLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const matchesLargeScreen = useMediaQuery(`(min-width:${screenSize.lg}px)`)
    const { t } = useTranslation()

    const drawer = (largeScreen = false) => (
      <Drawer
        closeDrawer={closeDrawer}
        drawerDocument={drawerDocument}
        largeScreen={largeScreen}
      >
        <Document open={drawerDocument as DocumentsEnum} />
      </Drawer>
    )

    return (
      <div
        className={clsx(
          'flex justify-center md:min-h-screen lg:grid grid-rows-[max-content_1fr]',
          'lg:grid-rows-none lg:grid-cols-2',
        )}
      >
        <section
          className={clsx(
            'relative flex flex-col justify-start px-6 py-6 sm:px-8',
            'md:items-center md:px-[214px] md:justify-center',
            'lg:px-[76px]',
            'xxl:px-[150px]',
            'xxxl:px-[380px]',
            sectionStyle,
          )}
          ref={ref}
        >
          <div className="w-full max-w-[327px] md:max-w-[352px] lg:pt-0 lg:max-w-[426px]">
            {children}
          </div>
        </section>
        <section className="hidden lg:block w-full h-full relative overflow-x-hidden overflow-y-auto lg:flex lg:items-center lg:text-center bg-none lg:bg-background-primary justify-center lg:pr-[76px] xxl:pr-[150px] xxxl:pr-[380px]">
          <div className="hidden lg:block flex max-h-[600px] w-full h-full">
            <Image
              alt="Auth"
              priority
              src={t('common:AuthImage', '/email_cover_en.png')}
              layout="intrinsic"
              width="690"
              height="600"
              className="object-contain"
            />
          </div>
          {matchesLargeScreen && drawer(matchesLargeScreen)}
        </section>
        {!matchesLargeScreen && drawer()}
      </div>
    )
  },
)
