// generated with https://www.freakflagsprite.com/

import { HTMLAttributes, useMemo } from 'react'
import clsx from 'clsx'
import classes from './country-flag.module.scss'
import { flagPositions } from './flag-positions'

export const sizes = {
  sm: {
    width: 16,
    height: 10,
  },
  md: {
    width: 24,
    height: 15,
  },
  lg: {
    width: 32,
    height: 20,
  },
  xl: {
    width: 48,
    height: 30,
  },
}

export interface CountryFlagProps extends HTMLAttributes<HTMLDivElement> {
  country: keyof typeof flagPositions
  size?: keyof typeof sizes
}

export const CountryFlag = ({
  className,
  size = 'lg',
  country,
  ...props
}: CountryFlagProps) => {
  const { width, height } = useMemo(() => sizes[size], [size])

  return (
    <div
      role={'presentation'}
      className={clsx(classes.root, className)}
      style={{
        backgroundPosition: flagPositions[country],
        width,
        height,
      }}
      {...props}
    />
  )
}
