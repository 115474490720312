import { FormikProps } from 'formik'

export const getToggleButtonConfig = (
  preselectedValue: boolean | undefined,
) => {
  return [
    {
      label: 'common:Yes',
      value: true,
      preselected: preselectedValue,
      disabled: false,
    },
    {
      label: 'common:No',
      value: false,
      preselected: !preselectedValue,
      disabled: false,
    },
  ]
}

export const toggleHandler = <T>(
  form: FormikProps<T>,
  fieldName: string,
  value: boolean,
) => {
  form.setFieldValue(fieldName, value)
}
