import clsx from 'clsx'
import { WizardNavigation } from '@/components/wizard/navigation/wizard-navigation'
import { WizardFooterNavigationProps } from './types'
import { memo } from 'react'

export const WizardFooterNavigation = memo(
  ({
    wizardProps,
    children,
    type,
    footerClass,
    wizardNavigationClass,
    isNextVisible = true,
    isBackVisible = true,
  }: WizardFooterNavigationProps) => {
    return (
      <footer
        className={clsx(
          'z-above flex flex-col mb-0 mt-auto sticky bottom-0 bg-surface-2 !border-t-0',
          'mx-[-24px]',
          'lg:mx-[-32px]',
          footerClass,
        )}
      >
        <WizardNavigation
          wizardProps={wizardProps}
          isNextVisible={isNextVisible}
          isBackVisible={isBackVisible}
          type={type}
          wrapperCustomStyle={clsx(
            'modal bottom-0 w-full overflow-hidden',
            wizardNavigationClass,
          )}
        >
          {children}
        </WizardNavigation>
      </footer>
    )
  },
)
