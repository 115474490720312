import {
  HighRisk as HighRiskEn,
  HighRiskHighlighted as HighRiskHighlightedEn,
  IncreasedRisk as IncreasedRiskEn,
  IncreasedRiskHighlighted as IncreasedRiskHighlightedEn,
  LowRisk as LowRiskEn,
  LowRiskHighlighted as LowRiskHighlightedEn,
  MediumRisk as MediumRiskEn,
  MediumRiskHighlighted as MediumRiskHighlightedEn,
  ModerateRisk as ModerateRiskEn,
  ModerateRiskHighlighted as ModerateRiskHighlightedEn,
} from '@/components/risk-profile-chart/icons/en'
import {
  HighRisk as HighRiskDe,
  HighRiskHighlighted as HighRiskHighlightedDe,
  IncreasedRisk as IncreasedRiskDe,
  IncreasedRiskHighlighted as IncreasedRiskHighlightedDe,
  LowRisk as LowRiskDe,
  LowRiskHighlighted as LowRiskHighlightedDe,
  MediumRisk as MediumRiskDe,
  MediumRiskHighlighted as MediumRiskHighlightedDe,
  ModerateRisk as ModerateRiskDe,
  ModerateRiskHighlighted as ModerateRiskHighlightedDe,
} from '@/components/risk-profile-chart/icons/de'

import {
  HighRisk as HighRiskFr,
  HighRiskHighlighted as HighRiskHighlightedFr,
  IncreasedRisk as IncreasedRiskFr,
  IncreasedRiskHighlighted as IncreasedRiskHighlightedFr,
  LowRisk as LowRiskFr,
  LowRiskHighlighted as LowRiskHighlightedFr,
  MediumRisk as MediumRiskFr,
  MediumRiskHighlighted as MediumRiskHighlightedFr,
  ModerateRisk as ModerateRiskFr,
  ModerateRiskHighlighted as ModerateRiskHighlightedFr,
} from '@/components/risk-profile-chart/icons/fr'

import {
  HighRisk as HighRiskIt,
  HighRiskHighlighted as HighRiskHighlightedIt,
  IncreasedRisk as IncreasedRiskIt,
  IncreasedRiskHighlighted as IncreasedRiskHighlightedIt,
  LowRisk as LowRiskIt,
  LowRiskHighlighted as LowRiskHighlightedIt,
  MediumRisk as MediumRiskIt,
  MediumRiskHighlighted as MediumRiskHighlightedIt,
  ModerateRisk as ModerateRiskIt,
  ModerateRiskHighlighted as ModerateRiskHighlightedIt,
} from '@/components/risk-profile-chart/icons/it'

import { IconValueMapType } from '@/components/risk-profile-chart/types'
import { LocaleEnum } from '@/common/enums/Locale'

const RiskIconValueMapEn = {
  1: LowRiskEn,
  2: ModerateRiskEn,
  3: MediumRiskEn,
  4: IncreasedRiskEn,
  5: HighRiskEn,
}

const RiskHighlightedIconValueMapEn = {
  1: LowRiskHighlightedEn,
  2: ModerateRiskHighlightedEn,
  3: MediumRiskHighlightedEn,
  4: IncreasedRiskHighlightedEn,
  5: HighRiskHighlightedEn,
}

const RiskIconValueMapDe = {
  1: LowRiskDe,
  2: ModerateRiskDe,
  3: MediumRiskDe,
  4: IncreasedRiskDe,
  5: HighRiskDe,
}

const RiskHighlightedIconValueMapDe = {
  1: LowRiskHighlightedDe,
  2: ModerateRiskHighlightedDe,
  3: MediumRiskHighlightedDe,
  4: IncreasedRiskHighlightedDe,
  5: HighRiskHighlightedDe,
}

const RiskIconValueMapFr = {
  1: LowRiskFr,
  2: ModerateRiskFr,
  3: MediumRiskFr,
  4: IncreasedRiskFr,
  5: HighRiskFr,
}

const RiskHighlightedIconValueMapFr = {
  1: LowRiskHighlightedFr,
  2: ModerateRiskHighlightedFr,
  3: MediumRiskHighlightedFr,
  4: IncreasedRiskHighlightedFr,
  5: HighRiskHighlightedFr,
}

const RiskIconValueMapIt = {
  1: LowRiskIt,
  2: ModerateRiskIt,
  3: MediumRiskIt,
  4: IncreasedRiskIt,
  5: HighRiskIt,
}

const RiskHighlightedIconValueMapIt = {
  1: LowRiskHighlightedIt,
  2: ModerateRiskHighlightedIt,
  3: MediumRiskHighlightedIt,
  4: IncreasedRiskHighlightedIt,
  5: HighRiskHighlightedIt,
}

export const RiskValueIconMap: IconValueMapType = {
  [LocaleEnum.EN]: RiskIconValueMapEn,
  [LocaleEnum.DE]: RiskIconValueMapDe,
  [LocaleEnum.FR]: RiskIconValueMapFr,
  [LocaleEnum.IT]: RiskIconValueMapIt,
}

export const RiskHighlightedIconValueMap: IconValueMapType = {
  [LocaleEnum.EN]: RiskHighlightedIconValueMapEn,
  [LocaleEnum.DE]: RiskHighlightedIconValueMapDe,
  [LocaleEnum.FR]: RiskHighlightedIconValueMapFr,
  [LocaleEnum.IT]: RiskHighlightedIconValueMapIt,
}
