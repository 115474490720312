import clsx from 'clsx'
import {
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
} from 'react'

import classes from './user-card.module.scss'

export const UserCardIcon = forwardRef(
  (
    {
      ...props
    }: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        {...props}
        className={clsx(classes.userCardIcon, props.className)}
        ref={ref}
      >
        {props.children}
      </div>
    )
  },
)
