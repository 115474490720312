import { FC } from 'react'
import { IconProps } from './types'

export const BooksIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H6C6.36429 2 6.70583 2.09739 7 2.26757C7.29417 2.09739 7.63571 2 8 2H10C10.7044 2 11.3237 2.36414 11.68 2.91443C11.7413 2.88674 11.8045 2.86186 11.8696 2.84L13.7656 2.20344C14.8127 1.85187 15.9466 2.41574 16.2982 3.46286L21.3907 18.6308C21.7422 19.6779 21.1784 20.8118 20.1313 21.1634L18.2353 21.7999C17.1881 22.1515 16.0543 21.5876 15.7027 20.5405L12 9.51206V20C12 21.1046 11.1046 22 10 22H8C7.63571 22 7.29417 21.9026 7 21.7324C6.70583 21.9026 6.36429 22 6 22H4C2.89543 22 2 21.1046 2 20V4ZM4 4L6 4V20H4V4ZM10 20H8V4H10V20ZM12.5062 4.73599L14.4022 4.09943L19.4947 19.2674L17.5987 19.9039L12.5062 4.73599Z"
        fill="currentColor"
      />
    </svg>
  )
}
