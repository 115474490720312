import { PermissionEnum } from '@/common/enums/Permission'
import { usePermissions } from '@/framework'
import { useEffect, useRef, useState } from 'react'

export const useHasUiPermission = (uiPermission: string) => {
  const [permissions] = usePermissions()
  const hasUiPermission = useRef(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (permissions?.length) {
      hasUiPermission.current = permissions.includes(uiPermission)
    }
    setLoaded(true)
  }, [permissions, uiPermission])

  if (uiPermission === PermissionEnum.ANY && !!permissions?.length) {
    return !!permissions.length
  }
  return loaded ? hasUiPermission.current : true
}
