import { getFontsActions } from '../../common/actions/fonts-actions'
import {
  SelectField,
  SelectFieldProps,
  SelectInputOption,
  Translate,
} from '@eplix/ui'
import { mediaQueries } from '@eplix/ui/tests/utils/helpers'
import { TranslateProps } from '@eplix/ui/translate/types'
import { ColorProp, TypographyProp } from '@eplix/ui/utils/css-helpers/types'
import useMediaQuery from '@eplix/ui/utils/use-media-query'
import React, { memo, useMemo } from 'react'

interface DropdownProps
  extends SelectFieldProps,
    Omit<TranslateProps, 'label' | 'value'> {
  options: Array<{ label: string; value: unknown }>
  name: string
  fieldClassName?: string
  borderBoxClassName?: string
  optionProps?: Omit<
    React.ComponentProps<typeof SelectInputOption>,
    'optionValue' | 'children'
  >
  optionValueLabelStyle?: SelectLabelStyle
}

export interface SelectLabelStyle {
  color?: ColorProp
  font?: TypographyProp
}

const { getTextVariantFont } = getFontsActions()

export const Dropdown: React.FC<DropdownProps> = memo(
  ({
    options,
    name,
    children,
    optionProps,
    fieldClassName,
    borderBoxClassName,
    optionValueLabelStyle,
    ...selectFieldProps
  }) => {
    const { xs } = mediaQueries
    const isMobile = useMediaQuery(xs) ?? false
    const textVariantFont = getTextVariantFont(isMobile)
    const mappedOptions = useMemo(() => {
      return options?.map(({ label, value }, index) => (
        <SelectInputOption
          key={index}
          optionValue={{ value, text: label }}
          {...optionProps}
        >
          <Translate variant={textVariantFont} color="black" label={label} />
        </SelectInputOption>
      ))
    }, [options, optionProps, textVariantFont])

    return (
      <SelectField
        name={name}
        {...selectFieldProps}
        className={fieldClassName}
        borderBoxClassName={borderBoxClassName}
        optionValueLabelStyle={optionValueLabelStyle}
      >
        {mappedOptions}
        {children}
      </SelectField>
    )
  },
)
