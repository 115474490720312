import clsx from 'clsx'
import {
  ReactHTML,
  HTMLProps,
  createElement,
  ForwardedRef,
  forwardRef,
} from 'react'

import classes from './grid.module.scss'

export type GridItemElement = keyof ReactHTML

export interface GridItemProps<TElement extends GridItemElement>
  extends Omit<HTMLProps<ReactHTML[TElement]>, 'rows'> {
  as?: TElement
  rows?: number | string
  columns?: number | string
}

export const GridItem = forwardRef(
  <TElement extends GridItemElement = 'div'>(
    {
      as,
      columns = 1,
      rows = 1,
      className,
      children,
      ...props
    }: GridItemProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'div',
      {
        ...props,
        ref,
        className: clsx(className, classes.gridItem),
        style: {
          '--item-columns-amount': columns,
          '--item-rows-amount': rows,
          ...props.style,
        },
      },
      children,
    )
  },
)
