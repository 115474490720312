import { FC } from 'react'
import { IconProps } from './types'

export const ShowFileIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2C3.83696 2 3.20107 2.26339 2.73223 2.73223C2.26339 3.20107 2 3.83696 2 4.5V16.5C2 17.163 2.26339 17.7989 2.73223 18.2678C3.20107 18.7366 3.83696 19 4.5 19H8V17H4.5C4.36739 17 4.24022 16.9473 4.14645 16.8536C4.05268 16.7598 4 16.6326 4 16.5V4.5C4 4.36739 4.05268 4.24021 4.14645 4.14645C4.24021 4.05268 4.36739 4 4.5 4H10V6.5C10 7.32843 10.6716 8 11.5 8H14V9.00139C11.0202 9.06784 8.625 11.5042 8.625 14.5C8.625 17.5376 11.0874 20 14.125 20C15.3783 20 16.5336 19.5808 17.4585 18.875L19.9894 21.4454L21.4145 20.0422L18.8045 17.3914C19.3247 16.5513 19.625 15.5607 19.625 14.5C19.625 12.1209 18.1144 10.0946 16 9.32788V6.25C16 5.98478 15.8946 5.73043 15.7071 5.54289L12.4571 2.29289C12.2696 2.10536 12.0152 2 11.75 2H4.5ZM13.3358 6L12 4.66421V6H13.3358ZM14.125 11C12.192 11 10.625 12.567 10.625 14.5C10.625 16.433 12.192 18 14.125 18C16.058 18 17.625 16.433 17.625 14.5C17.625 12.567 16.058 11 14.125 11Z"
        fill="currentColor"
      />
    </svg>
  )
}
