import { useDataFetcher } from '@/common'
import { ApplicationLoader } from '@/components'
import { getNEODocuments } from '@/features/pension/components/pension-fund/lib/get-pension-certificate-documents'
import {
  ACTIVE_PENSION_ATTESTATION,
  TAX_CERTIFICATE_HISTORY,
} from '@/features/pension/constants'
import { TaxCertificateDocuments } from './tax-certificate-documents'
import { TaxCertificateProps } from './types'
import { getTaxCertificatesDocuments } from '@/features/user-account/lib/user-account-client'

export const TaxCertificate = ({
  hasAnyUiPermission,
  config,
}: TaxCertificateProps) => {
  const taxCertificationDocuments = useDataFetcher({
    fetcher: getTaxCertificatesDocuments,
    enabled: hasAnyUiPermission,
  })

  const neoTaxCertificateDocuments = useDataFetcher({
    fetcher: getNEODocuments,
    enabled: hasAnyUiPermission,
    payload: TAX_CERTIFICATE_HISTORY,
  })

  const { result: pensionAttestations } = useDataFetcher({
    fetcher: getNEODocuments,
    payload: ACTIVE_PENSION_ATTESTATION,
  })

  const hasPensionAttestation = Boolean(pensionAttestations)
  const taxCertificateOrAttestation = hasPensionAttestation
    ? pensionAttestations
    : neoTaxCertificateDocuments?.result
  const conditionForTaxCertificateWithPensionAttestation =
    neoTaxCertificateDocuments?.result && hasPensionAttestation
  const taxCertificateWithPensionAttestation =
    conditionForTaxCertificateWithPensionAttestation
      ? neoTaxCertificateDocuments?.result?.concat(pensionAttestations)
      : taxCertificateOrAttestation

  return (
    <>
      <ApplicationLoader
        show={
          taxCertificationDocuments?.isLoading &&
          neoTaxCertificateDocuments?.isLoading
        }
      />
      {!taxCertificationDocuments?.isLoading &&
        !neoTaxCertificateDocuments?.isLoading &&
        (taxCertificationDocuments?.result?.documents ||
          neoTaxCertificateDocuments?.result ||
          taxCertificateWithPensionAttestation) && (
          <TaxCertificateDocuments
            taxCertificationDocuments={taxCertificationDocuments.result}
            neoTaxCertificateDocuments={taxCertificateWithPensionAttestation}
            hasPensionAttestation={hasPensionAttestation}
            {...config}
          />
        )}
    </>
  )
}
