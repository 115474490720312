import { Button, MoonIcon, SunIcon, Theme, useTheme } from '@eplix/ui'
import useSWR, { useSWRConfig } from 'swr'
import Cookies from 'js-cookie'

const cacheKey = 'theme'

export const ToggleTheme = () => {
  const [theme, , setTheme] = useTheme()
  const { mutate } = useSWRConfig()
  const { data } = useSWR(cacheKey)

  return (
    <Button
      name="toggle_theme"
      startIcon={theme === 'dark' ? <MoonIcon /> : <SunIcon />}
      title={`toggle theme to ${theme === 'dark' ? 'light' : 'dark'}`}
      checked={theme === 'dark'}
      onChange={async () => {
        const value = data === '' || data === 'light' ? 'dark' : 'light'
        Cookies.set(cacheKey, value)

        await mutate(cacheKey, value, true)
        setTheme((value as Theme) || 'light')
      }}
    />
  )
}
