import { FC } from 'react'
import { IconProps } from './types'

export const PeopleIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C6 5.34315 7.34315 4 9 4C10.6569 4 12 5.34315 12 7C12 8.65685 10.6569 10 9 10C7.34315 10 6 8.65685 6 7ZM9 2C6.23858 2 4 4.23858 4 7C4 9.76142 6.23858 12 9 12C11.7614 12 14 9.76142 14 7C14 4.23858 11.7614 2 9 2ZM5 14C3.67392 14 2.40215 14.5268 1.46447 15.4645C0.526784 16.4021 0 17.6739 0 19V22H2V19C2 18.2044 2.31607 17.4413 2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16H13C13.7956 16 14.5587 16.3161 15.1213 16.8787C15.6839 17.4413 16 18.2043 16 19V22H18V19C18 17.6739 17.4732 16.4021 16.5355 15.4645C15.5979 14.5268 14.3261 14 13 14H5ZM19.032 14.7502C19.1701 14.2155 19.7155 13.8939 20.2502 14.032C21.3965 14.328 22.3286 15.1347 22.9519 15.9388C23.6307 16.8144 23.9994 17.8906 24.0002 18.9984V21.9991H22.0002V18.9999C21.9997 18.3353 21.7784 17.6894 21.3713 17.1641C20.9086 16.5673 20.3202 16.1157 19.7502 15.9685C19.2155 15.8304 18.8939 15.285 19.032 14.7502ZM16.2483 2.1604C15.7133 2.02341 15.1685 2.34608 15.0315 2.88111C14.8945 3.41613 15.2172 3.96091 15.7522 4.0979C16.3975 4.26312 16.9695 4.63842 17.3779 5.16463C17.7864 5.69084 18.0081 6.33802 18.0081 7.00415C18.0081 7.67027 17.7864 8.31746 17.3779 8.84366C16.9695 9.36987 16.3975 9.74517 15.7522 9.9104C15.2172 10.0474 14.8945 10.5922 15.0315 11.1272C15.1685 11.6622 15.7133 11.9849 16.2483 11.8479C17.3238 11.5725 18.2771 10.947 18.9578 10.07C19.6386 9.193 20.0081 8.11436 20.0081 7.00415C20.0081 5.89393 19.6386 4.8153 18.9578 3.93828C18.2771 3.06127 17.3238 2.43577 16.2483 2.1604Z"
        fill="currentColor"
      />
    </svg>
  )
}
