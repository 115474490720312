/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 11:52:37.

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum NeoGenderEnum {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
}
