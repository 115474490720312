import clsx from 'clsx'
import { ForwardedRef, forwardRef, HTMLProps } from 'react'

import classes from './form-field.module.scss'
import { TranslateSimple } from '../translate'

export type FormFieldVariant = 'error' | 'warning' | 'success' | 'disabled'

export interface FormFieldControlProps extends HTMLProps<HTMLDivElement> {
  variant?: FormFieldVariant
}

export interface FormFieldHelperTextProps
  extends HTMLProps<HTMLParagraphElement> {
  helperText: string | JSX.Element
}

export const FormFieldControl = forwardRef(
  (
    { variant, className, children, ...props }: FormFieldControlProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        className={clsx(
          className,
          classes.formFieldControl,
          variant && classes[variant],
        )}
      >
        {children}
      </div>
    )
  },
)

export const FormFieldLabel = forwardRef(
  (
    { className, label, ...props }: HTMLProps<HTMLLabelElement>,
    ref: ForwardedRef<HTMLLabelElement>,
  ) => (
    <label
      {...props}
      ref={ref}
      className={clsx(className, classes.formFieldLabel)}
    >
      <TranslateSimple label={label} />
    </label>
  ),
)

export const FormFieldHelperText = forwardRef(
  (
    { helperText, className, ...props }: FormFieldHelperTextProps,
    ref: ForwardedRef<HTMLParagraphElement>,
  ) => {
    return (
      <p
        {...props}
        ref={ref}
        className={clsx(className, classes.formFieldHelperText)}
      >
        {typeof helperText === 'object' ? (
          helperText
        ) : (
          <TranslateSimple label={helperText} />
        )}
      </p>
    )
  },
)

export const FormFieldBorderBox = forwardRef(
  (
    { className, ...props }: HTMLProps<HTMLDivElement>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        className={clsx(classes.formFieldBorderBox, className)}
      />
    )
  },
)
