import { FilterChipsProps } from './types'
import { Chip, Translate } from '@eplix/ui'
import clsx from 'clsx'

export const FilterChips = ({
  title,
  elements,
  selectedElements,
  clickHandler,
}: FilterChipsProps) => {
  return (
    <div className="flex flex-col gap-4">
      <Translate label={title} />
      <div className="flex flex-row gap-4 flex-wrap">
        {elements?.map((element, index) => {
          const isSelected = selectedElements?.includes(element)
          return (
            <Chip
              as="a"
              variant="outlined"
              name={element}
              key={index}
              selected={isSelected}
              className={clsx(
                'pointer-events-auto min-w-[84px] rounded-[100px]',
                isSelected && 'bg-[rgba(58, 133, 248, 1)]',
              )}
              onClick={() => {
                clickHandler(element)
              }}
            >
              {element}
            </Chip>
          )
        })}
      </div>
    </div>
  )
}
