export const COLORS = [
  { start: '#3A85F8', end: '#3378de' },
  { start: '#F8CE3A', end: '#deb833' },
  { start: '#B9F6D9', end: '#a4dbc1' },
  { start: '#66B4EC', end: '#5ba1d4' },
  { start: '#C272EE', end: '#ae66d4' },
  { start: '#DDEE43', end: '#c5d43b' },
  { start: '#2CB1C3', end: '#2799a8' },
  { start: '#FF73BE', end: '#e667ac' },
  { start: '#2E6BC8', end: '#285ead' },
  { start: '#80D4EC', end: '#72bed4' },
  { start: '#7D68FF', end: '#705ee6' },
  { start: '#E03290', end: '#c72c7f' },
  { start: '#32E32E', end: '#2cc928' },
]
