import clsx from 'clsx'
import { memo, useMemo } from 'react'
import { ProgressBarProps } from './types'
import { getProgressSteps } from '@/components/progress-bar/hooks/get-progress-steps'

export const ProgressBar = memo(
  ({ currentStep, totalSteps, hidden = false }: ProgressBarProps) => {
    const width = useMemo(() => {
      return 100 / (totalSteps - 1)
    }, [totalSteps])

    const progressSteps = getProgressSteps(totalSteps, currentStep, width)

    return (
      <div
        className={clsx('w-full h-1 flex flex-row gap-1', hidden && 'hidden')}
      >
        {!hidden &&
          progressSteps.map((step) => {
            return step
          })}
      </div>
    )
  },
)
