import { MessagesViewEnum } from '@/common/enums/MessagesView'
import { toggleButtonConfig } from './manage-maintenance/constants'
import { PlannedMaintenance } from '@/api'
import { LocaleEnum } from '@/common/enums/Locale'

export type MaintenanceLabels = {
  label: string
  translationOptions?: {
    from: string
    to: string
  }
}

export const getMaintenanceLabels = (
  isTherePlannedMaintenance: boolean,
  shouldNotifyAnonymousUser: boolean,
  shouldNotifyAuthUser: boolean,
  from: string,
  to: string,
  isInPast: boolean,
): MaintenanceLabels => {
  if (isInPast) {
    return {
      label: 'USM-UserAccount:ManageMaintenance.MaintenanceInThePast',
      translationOptions: {
        from,
        to,
      },
    }
  } else if (
    isTherePlannedMaintenance &&
    (shouldNotifyAuthUser || shouldNotifyAnonymousUser)
  ) {
    return {
      label: 'USM-UserAccount:ManageMaintenance.NextMaintenanceAndBanner',
      translationOptions: {
        from,
        to,
      },
    }
  } else if (
    isTherePlannedMaintenance &&
    !(shouldNotifyAuthUser || shouldNotifyAnonymousUser)
  ) {
    return {
      label: 'USM-UserAccount:ManageMaintenance.NextMaintenanceNoBanner',
      translationOptions: {
        from,
        to,
      },
    }
  } else if (!isTherePlannedMaintenance) {
    return {
      label: 'USM-UserAccount:ManageMaintenance.NoMaintenanceScheduled',
    }
  }

  return {
    label: '',
  }
}

const getIsGeneralMessagesView = (option?: MessagesViewEnum) =>
  option === MessagesViewEnum.GENERAL

const getIsCustomMessagesView = (option?: MessagesViewEnum) =>
  option === MessagesViewEnum.CUSTOM

const getToggleButtonMessageConfig = (
  selectedOption = MessagesViewEnum.GENERAL,
) => {
  return toggleButtonConfig.map((option) => {
    return { ...option, preselected: selectedOption === option.value }
  })
}

const getLocaleForCustomMessage = (
  locale: LocaleEnum,
  data: PlannedMaintenance,
) => {
  switch (locale) {
    case 'en_GB':
      return data.messageEn
    case 'de_CH':
      return data.messageDe
    case 'fr_CH':
      return data.messageFr
    case 'it_CH':
      return data.messageIt
    default:
      return data.messageDe
  }
}
export const getManageMaintenanceActions = () => {
  return {
    getIsGeneralMessagesView,
    getIsCustomMessagesView,
    getToggleButtonMessageConfig,
    getLocaleForCustomMessage,
  }
}
