export function round(value: number, decimalPlaces = 0): number {
  if (!value) {
    return 0
  }

  if (value < 0) {
    return -round(-value, decimalPlaces)
  }

  return +(Math.round(+(value + 'e+' + decimalPlaces)) + 'e-' + decimalPlaces)
}

export function roundFixed(value: number, decimalPlaces = 0): string {
  if (!isNaN(round(value, decimalPlaces)))
    return round(value, decimalPlaces).toFixed(decimalPlaces)
  else return round(0, decimalPlaces).toFixed(decimalPlaces)
}

const countNumberOfDecimals = (value: number) => {
  let numberOfDecimals = 0
  const isNumberFloat = value % 1 !== 0

  if (isNumberFloat) {
    const splitValue = value.toString().split('.')
    numberOfDecimals = splitValue.length > 1 ? splitValue[1].length : 0
  }

  return numberOfDecimals
}

export const roundToMinNDecimals = (value: number, n: number) => {
  const numberOfDecimals = countNumberOfDecimals(value)
  if (numberOfDecimals < n) {
    return roundFixed(value, n)
  } else {
    return value
  }
}
