import { ExtendedUserTypeEnum, UserTypeEnum } from '@/common/enums'

export type PartnerClassType = Record<string, string>

export const getUserTypeMap = (
  language = 'en_GB',
): Record<string, Record<string, string | PartnerClassType>> => ({
  [ExtendedUserTypeEnum.TELLCO]: {
    invitationLabel: ``,
    partnerLogo: 'tellco-logo.svg',
  },
  [UserTypeEnum.MY_TELLCO_TELLCO]: {
    invitationLabel: `USM-Registration:InvitationLabel.${UserTypeEnum.MY_TELLCO_TELLCO}`,
    partnerLogo: 'tellco-logo.svg',
  },
  [UserTypeEnum.MY_TELLCO_PIGUET_GALLAND]: {
    invitationLabel: `USM-Registration:InvitationLabel.${UserTypeEnum.MY_TELLCO_PIGUET_GALLAND}`,
    partnerLogo: `piguet-galland-logo${
      language?.includes('en_GB') ? `-en.svg` : '.svg'
    }`,
    class: { width: '100%', paddingLeft: '40px' },
  },
  [UserTypeEnum.MY_TELLCO_ALKIMIA]: {
    invitationLabel: `USM-Registration:InvitationLabel.${UserTypeEnum.MY_TELLCO_ALKIMIA}`,
    partnerLogo: 'alkimia-logo.svg',
    class: { width: '33%' },
  },
  [UserTypeEnum.ABACUS_INDUNI]: {
    invitationLabel: `USM-Registration:InvitationLabel.${UserTypeEnum.ABACUS_INDUNI}`,
    partnerLogo: 'induni-logo.png',
    class: { width: '45%' },
  },
})
