import { Translate, TranslateSimple, Typo } from '@eplix/ui'
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DataSection, ListType, TextWithLink } from './document.model'
import classes from './documents.module.scss'
import { DocumentsEnum } from '@/common/enums'
import { useRouter } from 'next/router'
import { getPrivacyPolicyModel } from './privacy-policy.model'
import { getTermsOfUseModel } from './terms-of-use.model'
import { EplixLogo } from '@eplix/ui/logos/eplix-logo'

interface OpenProp {
  open: DocumentsEnum
}

const getUrlLanguage = (routerLocale: string | undefined) =>
  routerLocale?.split('_')[0] ?? 'de'

export const Document = ({ open }: OpenProp) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [urlLanguage, setUrlLanguage] = useState('')

  useEffect(() => {
    const language = getUrlLanguage(router.locale)
    setUrlLanguage(language)
  }, [router.locale])

  const renderTextWithLink = useCallback(
    ({ text, href }: TextWithLink, index: number) => {
      if (!href)
        return (
          <Typo
            key={`${text}_${index}`}
            variant="regular-300"
            color="text-secondary"
            className="mt-4"
          >
            <Trans
              i18nKey={text}
              components={{ underline: <u />, bold: <strong /> }}
            />
          </Typo>
        )

      return (
        <Typo
          key={`${href}_${index}`}
          variant="regular-300"
          color="text-secondary"
          className="mt-4"
        >
          <Trans
            i18nKey={text}
            t={t}
            components={[
              <a
                key={`href_${index}`}
                className={classes.link}
                href={href as string}
                style={{ hyphens: 'auto' }}
                target="_blank"
                rel="noreferrer"
              />,
            ]}
          />
        </Typo>
      )
    },
    [t],
  )

  const getListClass = useCallback((type: ListType) => {
    switch (type) {
      case ListType.ORDERED:
        return classes.orderedList
      case ListType.UNORDERED:
        return classes.unorderedList
      case ListType.ALPHABETICAL:
        return classes.alphabeticalList
    }
  }, [])

  const wrapItemsIntoList = useCallback(
    (type: ListType, children: ReactNode, index: number) => {
      const listClass = getListClass(type)

      if (type === ListType.UNORDERED)
        return (
          <ul key={`unorderedList_${index}`} className={`list ${listClass}`}>
            {children}
          </ul>
        )

      return (
        <ol key={`orderedList_${index}`} className={`list ${listClass}`}>
          {children}
        </ol>
      )
    },
    [getListClass],
  )

  const Hyphen = ({ children }: { children?: string }) => (
    <span style={{ hyphens: 'auto' }}>{children}</span>
  )

  const renderContent = useCallback(
    ({ title = '', content, paragraph }: DataSection) => {
      const renderTitle = () => {
        if (title) {
          return (
            <Translate
              variant="regular-300"
              color="text-secondary"
              className="mt-4"
              label={title}
            />
          )
        }
        return null
      }

      const renderItems = () => {
        if (content) {
          return content.map((c, index) => {
            const items = c.list?.items.map((item, _index) => (
              <li key={`content_${_index}`}>
                {(item.text as TextWithLink)?.text && (
                  <Trans
                    i18nKey={(item.text as TextWithLink).text}
                    components={{
                      lnk: (
                        <a
                          key={item.text as string}
                          className={`d-block ${classes.link}`}
                          href={(item.text as TextWithLink).href as string}
                          style={{ hyphens: 'auto' }}
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                      bold: <strong />,
                    }}
                  />
                )}

                {item.title && (
                  <Trans
                    i18nKey={item.title as string}
                    components={{
                      underline: <u />,
                      bold: <strong />,
                      hyphen: <Hyphen />,
                    }}
                  />
                )}

                {item.subItems?.map((subItem, subItemIndex) => (
                  <Fragment key={subItemIndex}>
                    {subItem?.content?.map((subContent, subContentIndex) => {
                      const subItems = subContent?.list?.items?.map(
                        (_item, listItemIndex) => {
                          return (_item.text as TextWithLink)?.text ? (
                            <li key={`listItemTextWithlink_${listItemIndex}`}>
                              <Trans
                                i18nKey={(_item.text as TextWithLink).text}
                                t={t}
                                components={(
                                  (_item.text as TextWithLink).href as string[]
                                ).map((link, __index) => (
                                  <a
                                    key={`${_item.text}-${__index}`}
                                    className={classes.link}
                                    href={link as string}
                                    target="_blank"
                                    rel="noreferrer"
                                  />
                                ))}
                              />
                            </li>
                          ) : (
                            <li key={`listItemText_${listItemIndex}`}>
                              <TranslateSimple label={_item.title as string} />
                            </li>
                          )
                        },
                      )
                      return wrapItemsIntoList(
                        subContent?.list?.type as ListType,
                        subItems,
                        subContentIndex,
                      )
                    })}
                  </Fragment>
                ))}

                {typeof item.text === 'string' && (
                  <Translate
                    variant="regular-300"
                    color="text-secondary"
                    className="mt-4"
                    key={`text_${_index}`}
                    label={item?.text as string}
                  />
                )}
              </li>
            ))
            return wrapItemsIntoList(c.list?.type as ListType, items, index)
          })
        }
        return null
      }

      const renderParagraph = () => {
        if (paragraph) {
          return paragraph.map((p, paragraphIndex) => (
            <Typo
              variant="regular-300"
              color="text-secondary"
              className="mt-4"
              key={`paragraphWithLink_${paragraphIndex}`}
            >
              <Trans
                i18nKey={(p as TextWithLink).text}
                components={[
                  <a
                    key={(p as TextWithLink).text}
                    className={classes.link}
                    href={(p as TextWithLink).href as string}
                    target="_blank"
                    rel="noreferrer"
                  />,
                ]}
              />
            </Typo>
          ))
        }
        return null
      }

      return (
        <>
          {renderTitle()}
          {renderItems()}
          {renderParagraph()}
        </>
      )
    },
    [wrapItemsIntoList, t],
  )

  const titleLabel =
    open === DocumentsEnum.TERMS_OF_USE
      ? 'USM-Registration:TermsOfUse.Title'
      : 'USM-Registration:PrivacyPolicy.Title'
  const subtitleLabel =
    open === DocumentsEnum.TERMS_OF_USE
      ? 'USM-Registration:TermsOfUse.Version'
      : 'USM-Registration:PrivacyPolicy.Version'

  const privacyPolicyModel = getPrivacyPolicyModel(urlLanguage)
  const termsOfUseModel = getTermsOfUseModel(urlLanguage)

  return (
    <div className={classes.wrapper}>
      <div className="flex flex-col lg:flex-row justify-between lg:items-center mb-6">
        <EplixLogo height={35} className="mb-6 block lg:hidden" />
        <div className="title">
          <Translate
            label={titleLabel}
            variant="medium-700"
            color="text-primary"
          />
          <Translate
            label={subtitleLabel}
            variant="regular-300"
            color="text-secondary"
          />
        </div>
        <EplixLogo className="w-[100px] h-[35px] hidden lg:block" />
      </div>
      <ol className={classes.orderedList}>
        {open === DocumentsEnum.TERMS_OF_USE
          ? termsOfUseModel.map((data, index) => (
              <li key={`termsOfUse_${index}`}>
                <TranslateSimple label={data.title} />
                {data.text?.map(renderTextWithLink)}
                {data.sections?.map(renderContent)}
              </li>
            ))
          : privacyPolicyModel.map((data, index) => {
              return (
                <li key={`privacyPolicy_${index}`}>
                  <TranslateSimple label={data.title} />
                  {data.text?.map(renderTextWithLink)}
                  {data.sections?.map(renderContent)}
                </li>
              )
            })}
      </ol>
    </div>
  )
}
