import { FC } from 'react'
import { IconProps } from './types'

export const SignIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM11 7C11 6.44772 11.4455 6 11.995 6H12.005C12.5545 6 13 6.44772 13 7C13 7.55228 12.5545 8 12.005 8H11.995C11.4455 8 11 7.55228 11 7ZM13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V11Z"
        fill="currentColor"
      />
    </svg>
  )
}
