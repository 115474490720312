import { FC } from 'react'
import { IconProps } from './types'

export const CreditCardIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.59259 4.96655C3.16074 4.96655 2 6.1273 2 7.55915V16.4408C2 17.8727 3.16074 19.0334 4.59259 19.0334H19.4074C20.8393 19.0334 22 17.8727 22 16.4408V7.55915C22 6.1273 20.8393 4.96655 19.4074 4.96655H4.59259ZM3.48148 7.55915C3.48148 6.9455 3.97894 6.44803 4.59259 6.44803H19.4074C20.0211 6.44803 20.5185 6.9455 20.5185 7.55915V7.9471H3.48148V7.55915ZM3.48148 10.5182V16.4408C3.48148 17.0545 3.97894 17.552 4.59259 17.552H19.4074C20.0211 17.552 20.5185 17.0545 20.5185 16.4408V10.5182H3.48148ZM14.0393 12.0875C13.5279 12.0875 13.1134 12.5021 13.1134 13.0135V15.1602C13.1134 15.6716 13.5279 16.0861 14.0393 16.0861H17.9839C18.4953 16.0861 18.9098 15.6716 18.9098 15.1602V13.0135C18.9098 12.5021 18.4953 12.0875 17.9839 12.0875H14.0393ZM14.5949 14.6046V13.569H17.4284V14.6046H14.5949Z"
        fill="currentColor"
      />
    </svg>
  )
}
