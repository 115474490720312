import easyTempLogo from '@/public/img/logos/easytemp-logo.png'
import easyWorkLogo from '@/public/img/logos/easywork-logo.png'
import eframeLogo from '@/public/img/logos/eframe-logo.png'
import egroupLogo from '@/public/img/logos/egroup-logo.png'
import enoveticLogoSmall from '@/public/img/logos/enovetic-logo-small.png'
import induniLogo from '@/public/img/logos/induni-logo.png'
import inegLogo from '@/public/img/logos/ineg-logo.png'
import realisatorLogo from '@/public/img/logos/realisator-logo.png'
import tellcoImmobilienLogo from '@/public/img/logos/tellco-immobilien-logo.png'
import tellcoLogo from '@/public/img/logos/tellco-logo.png'
import { MandantId } from '../enums/MandantId'

interface MandantNameToLogoMapType {
  logo: string
  cardClass?: string
  drawerClass?: string
}

export const MandantNameToLogoMap: Record<string, MandantNameToLogoMapType> = {
  [MandantId.INEG]: {
    logo: inegLogo.src,
    cardClass: 'mt-2 px-1',
  },
  [MandantId.INDUNI_3422]: {
    logo: induniLogo.src,
    cardClass: 'mt-2 px-1',
  },
  [MandantId.INDUNI_4599]: {
    logo: induniLogo.src,
    cardClass: 'mt-2 px-1',
  },
  [MandantId.INDUNI_8696]: {
    logo: induniLogo.src,
    cardClass: 'mt-2 px-1',
  },
  [MandantId.INDUNI_8909]: {
    logo: induniLogo.src,
    cardClass: 'mt-2 px-1',
  },
  [MandantId.MASTER_INDUNI]: {
    logo: induniLogo.src,
    cardClass: 'mt-2 px-1',
  },
  [MandantId.EASYTEMP]: {
    logo: easyTempLogo.src,
    cardClass: 'px-1',
  },
  [MandantId.EASYWORK]: {
    logo: easyWorkLogo.src,
    cardClass: 'px-1',
  },
  [MandantId.EGROUP_AG]: {
    logo: egroupLogo.src,
    cardClass: 'px-1',
  },
  [MandantId.EFRAME_AG]: {
    logo: eframeLogo.src,
    cardClass: 'px-1',
  },
  [MandantId.TELLCO_AG]: {
    logo: tellcoLogo.src,
    cardClass: 'mb-1 px-1',
  },
  [MandantId.TELLCO_IMMOBILIEN_AG]: {
    logo: tellcoImmobilienLogo.src,
    cardClass: 'px-1',
  },
  [MandantId.REALISATOR]: {
    logo: realisatorLogo.src,
    cardClass: 'px-1',
  },
  [MandantId.ECARE_AG]: {
    logo: enoveticLogoSmall.src,
    cardClass: 'px-1',
  },
  [MandantId.ENOVETIC]: {
    logo: enoveticLogoSmall.src,
    cardClass: 'px-1',
  },
}
