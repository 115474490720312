import { SupportIcon, Button } from '@eplix/ui'
import { ButtonVariant } from '@eplix/ui/buttons/types'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'

interface SupportLinkProps {
  disabled?: boolean
  variant?: ButtonVariant
  className?: string
}

export const SupportLink = memo(
  ({ className, variant = 'text', disabled = false }: SupportLinkProps) => {
    const { t } = useTranslation()
    const link = t('common:SupportLink')

    return (
      <Button
        as="a"
        variant={variant}
        name="support_link"
        href={link}
        target="_blank"
        startIcon={<SupportIcon />}
        disabled={disabled}
        className={`${className} ${disabled ? 'text-outline' : 'text-blue'}`}
        text="common:Support"
      />
    )
  },
)
