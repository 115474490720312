import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import clsx from 'clsx'

import classes from './navigation-drawer.module.scss'

export const NavigationDrawerDivider = forwardRef(
  (props: HTMLProps<HTMLDivElement>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        {...props}
        className={clsx(classes.drawerDivider, props.className)}
        ref={ref}
      />
    )
  },
)
