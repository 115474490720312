import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'
import { Loader, TranslateSimple } from '@eplix/ui'
import loaderClasses from '@eplix/ui/loader/loader.module.scss'
import classes from './button.module.scss'
import { ButtonVariant, ButtonElement, ButtonProps } from './types'

const getVariantClass = (variant?: ButtonVariant) => {
  switch (variant) {
    case 'outlined':
      return classes.outlined
    case 'text':
      return classes.text
    default:
      return classes.elevated
  }
}

export const Button = forwardRef(
  <TElement extends ButtonElement = 'button'>(
    {
      as,
      name,
      variant,
      startIcon,
      endIcon,
      className,
      text,
      loading = false,
      loadingIcon,
      ...props
    }: ButtonProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    const loadingSpinner = loadingIcon ?? (
      <Loader className={loaderClasses.loaderSmall} />
    )

    return createElement(
      as || 'button',
      {
        ...props,
        ref,
        'data-cy': `btn_${name}`,
        className: clsx(
          classes.buttonBase,
          getVariantClass(variant),
          startIcon
            ? classes.withStartIcon
            : endIcon
            ? classes.withEndIcon
            : 'px-8',
          className,
        ),
      },
      <>
        {loading ? (
          loadingSpinner
        ) : (
          <>
            {startIcon}
            <TranslateSimple label={text} />
            {endIcon}
          </>
        )}
      </>,
    )
  },
)
