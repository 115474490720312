import clsx from 'clsx'

const SliderSection = ({
  color,
  isFirstSection,
  isLastSection,
  sectionWidth,
  offsetPercentage,
  sectionBorderColor,
}: {
  color: string
  isFirstSection: boolean
  isLastSection: boolean
  sectionWidth: number
  offsetPercentage: number
  sectionBorderColor: string
}) => {
  const sectionBorderWidth = 1

  return (
    <div
      className={clsx(
        'absolute grow top-0 pointer-events-none flex flex-row h-full',
        isFirstSection ? 'rounded-l-lg ' : '',
        isLastSection ? 'rounded-r-lg ' : '',
      )}
      style={{
        width: `${sectionWidth}%`,
        left: `${offsetPercentage}%`,
        backgroundColor: color,
      }}
    >
      {!isFirstSection && (
        <div
          style={{
            width: `${sectionBorderWidth}px`,
            backgroundColor: sectionBorderColor,
          }}
          className={'pointer-events-none'}
        ></div>
      )}
      <div className={'grow h-full pointer-events-none'}></div>
      {!isLastSection && (
        <div
          style={{
            width: `${sectionBorderWidth}px`,
            backgroundColor: sectionBorderColor,
          }}
          className={'pointer-events-none'}
        ></div>
      )}
    </div>
  )
}

export default SliderSection
