import { useRef } from 'react'
import { useIsPresent } from 'framer-motion'

export const useWithTransition: <Type>(state: Type) => Type = (state) => {
  const lastState = useRef<typeof state>(state)
  const isPresent = useIsPresent()

  if (!isPresent) {
    return lastState.current
  }

  lastState.current = state
  return state
}
