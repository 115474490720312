import { VerifySMSCodeRequest } from '@/api'
import {
  getCommonUserDataActions,
  getResendSMSCodeErrorMessage,
  REGEX_EXPRESSION,
  useDataSubmit,
} from '@/common'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { asyncCall } from '@/common/helpers/async-wrapper'
import { ResendSmsLink } from '@/components/resend-sms-link/resend-sms-link'
import { useValidateTemporaryBlockedError } from '@/features/payslip/hooks/use-validate-temporary-blocked-error'
import { State } from '@/features/registration/components/verify-sms-code/types'
import { useUserData } from '@/features/registration/hooks/client/use-add-credentials-client'
import { shouldShowAddressForm } from '@/features/user-personal-data/actions'
import { useShouldShowPersonalDataPage } from '@/features/user-personal-data/hooks/user-personal-data-hooks'
import {
  completeLoginRequest,
  useResendSmsRequest,
  useResetCurrentUser,
  useValidateForm,
} from '@/framework/auth'
import { DisplayApiError } from '@/framework/error-handling/display-api-error'
import { useApiErrorHandler } from '@/framework/error-handling/hooks/use-api-error-handler'
import { useObserver } from '@/framework/hooks/use-observer'
import { FormInput } from '@eframe-ui/react'
import { Button, LoadingButton, Translate } from '@eplix/ui'
import { useRouter } from 'next/router'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ValidateFormProps } from './types'

const { getIsSwissNationalityUser, getIsCrossBorderOrSwissUser } =
  getCommonUserDataActions()

export const ValidateForm = ({
  isLoading,
  personalDocuments,
  setEnabled,
  setPersonalData,
  setUserStatusState,
  setDocumentsToAccept,
  setIsLoginInProgress,
}: ValidateFormProps) => {
  const [isRedirecting, setIsRedirecting] = useState(false)
  const { t } = useTranslation()
  const state = useObserver<State>({
    enableValidation: false,
    errorMessage: '',
    resendCodeBlocked: false,
  })
  const user = useUserData()
  const smsCodeRef = useRef<HTMLInputElement>(null)
  const resetCurrentUser = useResetCurrentUser()
  const router = useRouter()

  const {
    result,
    submit,
    error,
    isLoading: isLoadingCompleteLoginReq,
  } = useDataSubmit({
    fetcher: completeLoginRequest,
    payload: { smsCode: smsCodeRef.current?.value ?? '' },
  })

  const form = useValidateForm(
    {
      smsCode: '',
    },
    { enableValidation: state.enableValidation },
    submit,
  )

  const apiErrorMessage = useApiErrorHandler<
    Pick<VerifySMSCodeRequest, 'smsCode'>
  >(error, form, 'common:SMSCodeResponseError', true)

  const { handleSubmit, handleChange, errors, values } = form

  const backToLogin = () => {
    resetCurrentUser().then(() => window.location.reload())
  }

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    form.setErrors({})
    state.enableValidation = true
    smsCodeRef.current?.focus()
    setIsLoginInProgress?.(true)
    handleSubmit()
  }

  if (useValidateTemporaryBlockedError(form.errors.smsCode ?? '')) {
    state.resendCodeBlocked = true
  }

  useEffect(() => {
    form.errors && setIsLoginInProgress(false)
  }, [form.errors, setIsLoginInProgress])

  useEffect(() => {
    state.errorMessage = getResendSMSCodeErrorMessage(
      state.resendCodeBlocked,
      apiErrorMessage,
    )
  }, [apiErrorMessage, state])

  useEffect(() => {
    smsCodeRef.current?.focus()
  }, [state])

  const hasMyTellcoOrEplixDocument =
    !!result?.documentsToBeAccepted?.eplixDocumentTypes.length ||
    !!result?.documentsToBeAccepted?.mytellcoDocumentTypes.length

  const showAddressPage = shouldShowAddressForm(result?.personalData)
  const showPersonalData = useShouldShowPersonalDataPage(result?.personalData)

  const isSwissNationalityUser = getIsSwissNationalityUser(
    result?.personalData?.profile.nationality,
  )

  const isCrossBorderOrSwissUser = getIsCrossBorderOrSwissUser(
    result?.personalData?.profile.isCrossBorder,
    result?.personalData?.profile.country,
  )

  const showAddressPageForPermit =
    !isSwissNationalityUser &&
    isCrossBorderOrSwissUser &&
    !result?.personalData?.profile.documentCategory

  const showPageForOfflineUser =
    showPersonalData &&
    result.currentUser.status === UserSessionStatusEnum.OFFLINE

  useEffect(() => {
    if (hasMyTellcoOrEplixDocument && result) {
      setDocumentsToAccept(result.documentsToBeAccepted)
    }
  }, [hasMyTellcoOrEplixDocument, result, setDocumentsToAccept])

  const replaceToDashboard = useCallback(async () => {
    if (
      !showAddressPage &&
      !showPersonalData &&
      !showAddressPageForPermit &&
      result?.currentUser?.status === UserSessionStatusEnum.AUTHENTICATED
    ) {
      setIsRedirecting(true)
      await router.replace('/dashboard')
      setIsRedirecting(false)
    }
  }, [
    result?.currentUser?.status,
    router,
    showAddressPage,
    showAddressPageForPermit,
    showPersonalData,
  ])

  useEffect(() => {
    asyncCall(replaceToDashboard)
  }, [replaceToDashboard])

  useEffect(() => {
    if (result) {
      setPersonalData(result.personalData)

      if (hasMyTellcoOrEplixDocument) {
        setEnabled(true)
        setUserStatusState(
          UserSessionStatusEnum.AUTHENTICATED_WITHOUT_ACCEPTED_DOCUMENTS,
        )
      } else if (showPageForOfflineUser) {
        setUserStatusState(UserSessionStatusEnum.OFFLINE)
      } else if (showAddressPage) {
        setUserStatusState(UserSessionStatusEnum.WITHOUT_ADDRESS)
      } else if (showAddressPageForPermit) {
        setUserStatusState(UserSessionStatusEnum.WITHOUT_PERMIT)
      }
    }
  }, [
    hasMyTellcoOrEplixDocument,
    result,
    setEnabled,
    setPersonalData,
    setUserStatusState,
    showAddressPage,
    showAddressPageForPermit,
    showPageForOfflineUser,
  ])

  const smsCode = values.smsCode.replace(
    REGEX_EXPRESSION.smsCode_FORBIDDEN_CHARS,
    '',
  )

  return (
    <form
      className="flex flex-col gap-y-4 w-full"
      onSubmit={formSubmitHandler}
      noValidate
    >
      <Translate
        className="mb-4"
        variant="regular-300"
        label="USM-Login:ResendSMSForm.TextLead"
        translationOptions={{ phoneNumber: user?.fullNumber }}
      />

      <FormInput
        ref={smsCodeRef}
        label={t(
          'USM-Login:ResendSMSForm.VerifySMSCodeLabel',
          'Verification code',
        )}
        type="text"
        name="smsCode"
        disabled={isLoading}
        error={!!errors.smsCode}
        value={smsCode}
        onChange={handleChange}
        maxLength={6}
      />
      <ResendSmsLink
        form={form}
        resendSMSCode={useResendSmsRequest()}
        callback={() => {
          form.setTouched({}, false)
        }}
        isRequestInProgress={isLoading}
      />
      {!isLoading && (
        <DisplayApiError
          apiErrorMessage={state.errorMessage}
          translationKey="common:SMSCodeResponseError"
        />
      )}
      <LoadingButton
        loading={
          (hasMyTellcoOrEplixDocument && !personalDocuments?.length) ||
          isLoading ||
          isLoadingCompleteLoginReq ||
          isRedirecting
        }
        disabled={isRedirecting}
        variant="elevated"
        className="lg:w-fit"
        text="USM-Login:ResendSMSForm.LoginButton"
        name="resend_sms_login"
      />
      <Button
        variant="outlined"
        name="resend_sms_back"
        type="button"
        disabled={isLoading}
        onClick={backToLogin}
        className="mt-4 lg:w-fit"
        text="USM-Login:ResendSMSForm.BackButton"
      />
    </form>
  )
}
