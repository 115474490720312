export const NW = {
  6052: 'Hergiswil NW',
  6362: 'Stansstad',
  6363: 'Obbürgen',
  6365: 'Kehrsiten',
  6370: 'Stans',
  6371: 'Stans',
  6372: 'Ennetmoos',
  6373: 'Ennetbürgen',
  6374: 'Buochs',
  6375: 'Beckenried',
  6376: 'Emmetten',
  6382: 'Büren NW',
  6383: 'Dallenwil',
  6386: 'Wolfenschiessen',
  6387: 'Oberrickenbach',
}
