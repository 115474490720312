import { FC } from 'react'
import { IconProps } from './types'

export const GraphUpIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6C15.4477 6 15 6.44772 15 7C15 7.55228 15.4477 8 16 8H19.5993L13.5002 14.0871L9.20726 9.79418C8.81673 9.40365 8.18357 9.40365 7.79304 9.79418L0.585938 17.0013L2.00015 18.4155L8.50015 11.9155L12.793 16.2084C13.1836 16.5989 13.8167 16.5989 14.2073 16.2084L21 9.42787V13C21 13.5523 21.4477 14 22 14C22.5523 14 23 13.5523 23 13V7C23 6.44772 22.5523 6 22 6H16Z"
        fill="currentColor"
      />
    </svg>
  )
}
