import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import clsx from 'clsx'

import classes from './navigation-drawer.module.scss'
import { useLogout } from '@/framework/auth'
import { SvgLoader, TranslateSimple } from '@eplix/ui'

export interface NavigationDrawerLogoutButtonProps
  extends HTMLProps<HTMLLIElement> {
  /**
   * Icon displayed beside button text. When the request is sent this icon is replaced with SvgLoader
   */
  collapsed?: boolean
  icon?: JSX.Element
  /**
   * The displayed Text.
   */
  text: string

  disabled?: boolean
  isActive?: boolean
}

export const NavigationDrawerLogoutButton = forwardRef(
  (
    {
      collapsed,
      disabled,
      text,
      icon,
      isActive,
      ...props
    }: NavigationDrawerLogoutButtonProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const [logout, { isLoading }] = useLogout()

    return (
      <li
        {...props}
        ref={ref}
        className={clsx(classes.listItem, props.className)}
      >
        <button
          tabIndex={disabled ? -1 : undefined}
          className={clsx(classes.link, isActive && classes.linkActive)}
          aria-disabled={disabled}
          onClick={() =>
            logout({}).then(() => window.location.replace('/auth/login'))
          }
          data-cy="btn_logout"
        >
          {isLoading ? (
            <SvgLoader size={24} progress={75} strokeWidth={2} />
          ) : (
            <span className={clsx(classes.iconWrapper)}> {icon} </span>
          )}
          {!collapsed && (
            <span className={clsx(classes.linkText)}>
              <span className={clsx(classes.linkTextWrapper)}>
                <TranslateSimple label={text} />
              </span>
            </span>
          )}
        </button>
      </li>
    )
  },
)
