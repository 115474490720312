import { UtilsEnum } from '../enums/UtilsEnum'

export const isUndefined = (value: unknown) => value === undefined

export const isNull = (value: unknown) => value === null

export const isNullOrUndefined = (value: unknown) =>
  isNull(value) || isUndefined(value)

export const isValidValue = (value: unknown) =>
  !isNullOrUndefined(value) && value !== UtilsEnum.UNKNOWN

export const isNotUnknown = (value: unknown) => value !== UtilsEnum.UNKNOWN
