/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 11:51:04.

export enum AccountStatusEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED',
  SNOOPER = 'SNOOPER',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  MANUALLY_REGISTERED = 'MANUALLY_REGISTERED',
  INTERESTED = 'INTERESTED',
  DELETE_REQUESTED = 'DELETE_REQUESTED',
  DOCUMENT_VERIFICATION = 'DOCUMENT_VERIFICATION',
}
