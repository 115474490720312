import {
  ChevronDownIcon,
  FormFieldBorderBox,
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
  SelectInput,
  SelectInputButton,
  SelectInputOptions,
  TextInput,
  WarningIcon,
} from '@eplix/ui'
import {
  ChangeEvent,
  createElement,
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import classes from './phone-number-field.module.scss'
import { useUserData } from '@/features/registration/hooks/client/use-add-credentials-client'
import { PhoneFieldAreaCodes } from '@/components/phone-number/components/phone-field-area-codes'
import { Constants } from '@/common'
import { useId } from '@eframe-ui/react'

export interface AreaCodeProps {
  name: string
  code: string
  exampleNumber?: string
  flag: JSX.Element
}
export interface PhoneNumberFieldProps {
  label: string
  helpertext?: string | JSX.Element
  className?: string
  onChange?: (val: string) => void
  disabled?: boolean
  areaCode: AreaCodeProps[]
  optionsRef?:
    | ((ref?: HTMLUListElement | null) => void)
    | MutableRefObject<HTMLUListElement | null>
  value: string
  setPhoneNumber: (s: string) => void
  setAreaCode?: (s: AreaCodeProps) => void
  name?: string
  error: boolean
  forwardRef?: MutableRefObject<HTMLInputElement | null>
  formFieldClassName?: string
  inputClassName?: string
}

const MAX_phoneNumber = 15

export const PhoneNumberField = memo((props: PhoneNumberFieldProps) => {
  const {
    error,
    setPhoneNumber,
    setAreaCode,
    value,
    label,
    helpertext,
    className,
    areaCode,
    onChange,
    forwardRef,
    formFieldClassName,
    inputClassName,
    ...rest
  } = props

  const id = useId()
  const ariaLabelId = `label-${id}`
  const user = useUserData()
  const htmlOptionRef = useRef<HTMLUListElement | null>(null)
  const [selectedCountry, setSelectedCountry] = useState<AreaCodeProps>(
    user?.areaCodeValue ? user?.areaCodeValue : areaCode[0],
  )
  const isAtBottom = useMemo(() => {
    if (!htmlOptionRef.current) {
      return false
    }
    const { top, height } = htmlOptionRef.current.getBoundingClientRect() || {
      top: 0,
      height: 0,
    }

    return window.innerHeight < top + height
  }, [])

  const selectCountry = (countryName: string) => {
    const foundIndex = areaCode.findIndex((val) => val.name === countryName)
    const defaultAreaValue = user?.areaCodeValue || areaCode[0]
    const selectedAreaCode =
      foundIndex >= 0 ? areaCode[foundIndex] : defaultAreaValue
    setSelectedCountry(selectedAreaCode)
    setAreaCode && setAreaCode(selectedAreaCode)
  }

  const getNumberWithoutZero = (number: string) => {
    const idx = number.search(/[1-9]/g)
    return number.substring(idx)
  }

  useEffect(() => {
    onChange && onChange(`${selectedCountry.code}${value}`)
  }, [onChange, selectedCountry, value])

  const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const numberOfAreaCode = Number(
      selectedCountry.code.replace('-', '').length - 1,
    )
    const re = new RegExp(
      '^[0-9\b]{1,' + (MAX_phoneNumber - numberOfAreaCode) + '}$',
    )
    if (e.target.value === '' || re.test(e.target.value)) {
      const number = getNumberWithoutZero(e.target.value)
      setPhoneNumber(number)
    }
  }

  return createElement(
    'div',
    {
      ...rest,
      className: clsx(props.className),
    },
    <FormFieldControl className={className}>
      <FormFieldLabel htmlFor={ariaLabelId} label={label} />
      <FormFieldBorderBox
        className={clsx(
          classes.borderBox,
          error && classes.redErrorBorder,
          formFieldClassName,
        )}
      >
        <SelectInput
          value={selectedCountry.name}
          onChange={(val) => selectCountry(val as string)}
          disabled={rest.disabled}
        >
          <SelectInputButton
            className={clsx(classes.button)}
            disabled={rest.disabled}
          >
            {({ open }) => {
              return (
                <>
                  {selectedCountry.flag}
                  <span className={classes.phoneLabel}>
                    {selectedCountry.code}
                  </span>
                  <ChevronDownIcon
                    className={clsx(
                      classes.chevron,
                      open && classes.chevronOpen,
                      rest.disabled && 'text-text-disabled',
                    )}
                  />
                </>
              )
            }}
          </SelectInputButton>
          <SelectInputOptions
            className={clsx(classes.options, isAtBottom && classes.optionsTop)}
          >
            <PhoneFieldAreaCodes areaCode={areaCode} />
          </SelectInputOptions>
        </SelectInput>
        <TextInput
          name="phoneNumber"
          className={inputClassName}
          disabled={rest.disabled}
          ref={forwardRef}
          type="tel"
          value={value}
          onChange={(e) => handlePhoneNumber(e)}
          placeholder={Constants.PhoneNumberTooltip}
        />
        <>
          {error && (
            <WarningIcon
              style={{ color: 'var(--ex-rgb-signal-red)', width: '32px' }}
            />
          )}
        </>
      </FormFieldBorderBox>
      {helpertext && error && (
        <FormFieldHelperText
          style={{ color: 'var(--ex-rgb-signal-red)' }}
          helperText={helpertext}
        />
      )}
      {helpertext && !error && (
        <FormFieldHelperText
          style={{ color: 'var(--ex-rgb-text-secondary)' }}
          helperText={helpertext}
        />
      )}
    </FormFieldControl>,
  )
})
