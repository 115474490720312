/* eslint-disable @typescript-eslint/no-explicit-any */
export const replaceUndefinedPropsWithNull = (obj: any) => {
  if (obj === undefined) {
    return null
  }
  if (typeof obj === 'object') {
    for (const key in obj) {
      obj[key] = replaceUndefinedPropsWithNull(obj[key])
    }
  }
  return obj
}
