import { FC } from 'react'
import { IconProps } from './types'

export const AddPortfolioIcon: FC<IconProps> = ({
  iconSize = 24,
  ...props
}) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.24783 4.24783C4.40652 4.08915 4.62174 4 4.84615 4H13.1538C13.3783 4 13.5935 4.08915 13.7522 4.24783C13.9109 4.40652 14 4.62174 14 4.84615V6.01562H16V4.84615C16 4.09131 15.7001 3.36738 15.1664 2.83362C14.6326 2.29986 13.9087 2 13.1538 2H4.84615C4.09131 2 3.36738 2.29986 2.83362 2.83362C2.29986 3.36738 2 4.09131 2 4.84615V13.1538C2 13.9087 2.29986 14.6326 2.83362 15.1664C3.36738 15.7001 4.09131 16 4.84615 16H6V14H4.84615C4.62174 14 4.40652 13.9109 4.24783 13.7522C4.08915 13.5935 4 13.3783 4 13.1538V4.84615C4 4.62174 4.08915 4.40652 4.24783 4.24783Z"
        fill="currentColor"
      />
      <path
        d="M16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12V14H12C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16H14V18C14 18.5523 14.4477 19 15 19C15.5523 19 16 18.5523 16 18V16H18C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14H16V12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10.8462C8 9.27427 9.27427 8 10.8462 8H19.1538C20.7257 8 22 9.27427 22 10.8462V19.1538C22 20.7257 20.7257 22 19.1538 22H10.8462C9.27427 22 8 20.7257 8 19.1538V10.8462ZM10.8462 10C10.3788 10 10 10.3788 10 10.8462V19.1538C10 19.6212 10.3788 20 10.8462 20H19.1538C19.6212 20 20 19.6212 20 19.1538V10.8462C20 10.3788 19.6212 10 19.1538 10H10.8462Z"
        fill="currentColor"
      />
    </svg>
  )
}
