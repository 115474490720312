import { useTranslation } from 'react-i18next'
import { Button, LoadingButton, TextField, Translate, Typo } from '@eplix/ui'
import { Divider } from '@eframe-ui/react'
import {
  useReSendSmsValidateForm,
  useResetCurrentUser,
  validatePasswordSmsRequest,
} from '@/framework/auth'
import classes from '../auth-forms.module.scss'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { ExpiredTimeError } from './expired-time-error'
import {
  ErrorResponse,
  ForgotPasswordRequest,
  ForgotPasswordVerifySMSCodeRequest,
} from '@/api'
import { useUserData } from '@/features/registration/hooks/client/use-add-credentials-client'
import { debounce } from '@eplix/ui/utils/debounce'
import clsx from 'clsx'
import { useApiErrorHandler } from '@/framework/error-handling/hooks/use-api-error-handler'
import { DisplayApiError } from '@/framework/error-handling/display-api-error'
import { useForgotPasswordResendSmsRequest } from '@/features/user-account/hooks/use-forgot-password-resend-sms-request'
import {
  getResendSMSCodeErrorMessage,
  REGEX_EXPRESSION,
  useDataSubmit,
} from '@/common'
import { useObserver } from '@/framework/hooks/use-observer'
import { State } from '@/features/registration/components/verify-sms-code/types'
import { useValidateTemporaryBlockedError } from '@/features/payslip/hooks/use-validate-temporary-blocked-error'
import { RESEND_smsCode_TIME_OUT } from './constants'
import { ForgotPasswordProps } from '@/framework/auth/components/forgot-password/types'
import { isEmpty } from 'lodash'
import { ResendSmsLink } from '@/components/resend-sms-link/resend-sms-link'

type DebounceApi = {
  (): { cancel: () => void }
}

export const ResendSmsForm = ({ wizardProps }: ForgotPasswordProps) => {
  const { t } = useTranslation()
  const state = useObserver<State>({
    enableValidation: false,
    errorMessage: '',
    resendCodeBlocked: false,
  })
  const { fullNumber } = useUserData()
  const { submit, result, error, isLoading } = useDataSubmit({
    fetcher: validatePasswordSmsRequest,
  })
  const resetCurrentUser = useResetCurrentUser()
  const [timeExpired, setTimeExpired] = useState(false)
  const activeTimer = useRef<DebounceApi>()
  const smsCodeRef = useRef<HTMLInputElement>(null)
  const [data] = useState<ForgotPasswordRequest>({
    phonenumber: fullNumber,
  })
  const resendSMSCode = useForgotPasswordResendSmsRequest(data)

  const onSubmit = async (
    values: Pick<ForgotPasswordVerifySMSCodeRequest, 'smsCode'>,
  ) => {
    await submit(values)
  }

  useEffect(() => {
    if (result && isEmpty(error)) {
      wizardProps && wizardProps.handleNext()
    }
  }, [error, result, wizardProps])

  const reportNewPhoneNumber = () => {
    resetCurrentUser().then(wizardProps?.handleBack)
  }

  const form = useReSendSmsValidateForm(
    {
      smsCode: '',
    },
    { enableValidation: state.enableValidation },
    onSubmit,
  )

  const formSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    form.setErrors({})
    state.enableValidation = true
    smsCodeRef.current?.focus()
    form.handleSubmit()
  }

  const apiErrorMessage = useApiErrorHandler<
    Pick<ForgotPasswordVerifySMSCodeRequest, 'smsCode'>
  >(error as ErrorResponse, form, 'common:SMSCodeResponseError', true)

  const startTimer = useCallback(() => {
    if (activeTimer.current) {
      activeTimer.current().cancel()
    }

    activeTimer.current = debounce(() => {
      setTimeExpired(true)
    }, RESEND_smsCode_TIME_OUT)
  }, [])

  useEffect(() => {
    startTimer()
  }, [startTimer])

  state.resendCodeBlocked = useValidateTemporaryBlockedError(
    form.errors.smsCode ?? '',
  )

  useEffect(() => {
    state.errorMessage = getResendSMSCodeErrorMessage(
      state.resendCodeBlocked,
      apiErrorMessage,
    )
  }, [apiErrorMessage, state])

  useEffect(() => {
    smsCodeRef.current?.focus()
  }, [state])

  const isBackDisabled = wizardProps?.state.index == 0 || isLoading
  const smsCode = form.values.smsCode.replace(
    REGEX_EXPRESSION.smsCode_FORBIDDEN_CHARS,
    '',
  )

  return (
    <>
      {timeExpired && (
        <ExpiredTimeError message="USM-ForgotPassword:ResendSMSForm.ExpiredTimeError" />
      )}
      {!timeExpired && (
        <form className={classes.form} onSubmit={formSubmitHandler} noValidate>
          <Translate
            label="USM-ForgotPassword:ResendSMSForm.TextLead"
            translationOptions={{ phoneNumber: fullNumber }}
            variant="regular-300"
            className="mb-4"
          />
          <TextField
            ref={smsCodeRef}
            label="USM-ForgotPassword:ResendSMSForm.SMSCode"
            type="text"
            name="smsCode"
            disabled={isLoading}
            error={!!form.errors.smsCode}
            value={smsCode}
            onChange={form.handleChange}
            maxLength={6}
          />
          <ResendSmsLink
            form={form}
            resendSMSCode={resendSMSCode}
            callback={() => {
              form.setTouched({}, false)
            }}
            isRequestInProgress={isLoading}
          />
          <Typo variant="regular-200" color="text-secondary">
            {t(
              'USM-ForgotPassword:ResendSMSForm.BottomText',
              'You have not received a verification code? Presumably you have a new phone number? ',
            )}
            &nbsp;
            <Typo
              as="a"
              color="blue"
              variant="regular-200"
              className={classes.resendCodeLink}
              onClick={reportNewPhoneNumber}
            >
              {t(
                'USM-ForgotPassword:ResendSMSForm.ReportNewPhone',
                'Report your new phone number.',
              )}
            </Typo>
          </Typo>
          {!isLoading && (
            <DisplayApiError
              apiErrorMessage={state.errorMessage}
              translationKey="USM-ForgotPassword:NewPasswordResponseError"
            />
          )}
          <div className={classes.forgotPassword}>
            <LoadingButton
              loading={isLoading}
              variant="elevated"
              className="lg:w-fit"
              type="submit"
              text="USM-ForgotPassword:ResendSMSForm.NextButton"
              name="resend_sms_next"
            />
            <Divider className={classes.divider} />
            <Translate
              label="USM-ForgotPassword:ResendSMSForm.ResetPassword"
              variant="regular-300"
            />
            <Button
              variant="outlined"
              name="forgot_password_back"
              type="button"
              onClick={() => {
                resetCurrentUser().then(wizardProps?.handleBack)
              }}
              disabled={isBackDisabled}
              className={clsx(classes.button, classes.resendBackButton)}
              text="USM-ForgotPassword:ResendSMSForm.BackButton"
            />
          </div>
        </form>
      )}
    </>
  )
}
