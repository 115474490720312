import { useHasUiPermission } from '@/framework'

interface ViewContainerProps {
  uiPermission: string
  children: JSX.Element | Array<JSX.Element>
}

/***
Usage:
 <ViewContainer uiPermission={PermissionEnum.ViewMyComponent}>
    <MyComponent />
 </ViewComponent>
 */
export const ViewContainer = ({
  uiPermission,
  children,
}: ViewContainerProps) => {
  const hasUiPermission = useHasUiPermission(uiPermission)

  return hasUiPermission ? children : null
}
