export enum MandantId {
  ENOVETIC = 1700,
  INEG = 8136,
  INDUNI_3422 = 3422,
  INDUNI_4599 = 4599,
  INDUNI_8696 = 8696,
  INDUNI_8909 = 8909,
  MASTER_INDUNI = 5,
  EASYTEMP = 1255,
  EASYWORK = 1256,
  EFRAME_AG = 1670,
  EGROUP_AG = 2106,
  TELLCO_AG = 2115,
  TELLCO_IMMOBILIEN_AG = 2116,
  ECARE_AG = 1665,
  REALISATOR = 1666,
}
