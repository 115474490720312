import { Translate } from '@eplix/ui'
import useWizard from '@/components/wizard/hook/use-wizard'
import { WizardHandler, WizardInputProps } from '@/components/wizard/types'
import { ProgressBar } from '@/components'
import clsx from 'clsx'
import * as React from 'react'
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import classes from './wizard.module.scss'
import { debounce } from '@/../ui/utils/debounce'
import { EplixIcon } from '@eplix/ui/icons/eplix-icon'

export const Wizard = memo(
  forwardRef<WizardHandler, WizardInputProps>(
    (
      {
        steps,
        children,
        skipDisplayIndex,
        showEPlixLogo = true,
        onWizardStepChange,
        showSteps = true,
        title,
        wizardWrapClassName,
        formWrapperClassName,
        logoWrapperClassName,
        logoContainerClassName,
        headerWrapperClass,
        label,
        currentStepCallback,
        totalStepsCallback,
        mainLogo = <EplixIcon width={64} height={42} />,
        hideHeaderIndex,
        showBrandedPage,
        startFromIndex = 0,
      }: WizardInputProps,
      ref,
    ) => {
      const [startFadeIn, setStartFadeIn] = useState(false)

      const forms = useMemo(() => children.filter((child) => child), [children])

      const formNameMap = useMemo(() => {
        const _formNameMap: Record<string, number> = {}
        forms.forEach((form, index) => {
          const key = (form as JSX.Element)?.key
          if (key) {
            _formNameMap[key] = index
          }
        })

        return _formNameMap
      }, [forms])

      const wizardProps = useWizard(
        forms.length,
        formNameMap,
        skipDisplayIndex,
        steps,
        onWizardStepChange,
        startFromIndex,
      )

      const { state, totalDisplaySteps } = wizardProps

      const filteredChildren = children.filter(Boolean)
      const form = useMemo(() => {
        const child =
          filteredChildren[state.index] ?? filteredChildren[state.index + 1]
        if (!child) {
          return null
        }
        return React.cloneElement(child as JSX.Element, {
          ...(child as JSX.Element).props,
          wizardProps,
        })
      }, [filteredChildren, state.index, wizardProps])

      useImperativeHandle(ref, () => ({
        getWizardProps() {
          return wizardProps
        },
      }))

      const shouldShowStartLabel =
        state.displayIndex ===
        1 + (hideHeaderIndex?.length ? hideHeaderIndex?.length : 0)
      const shouldShowStep = showSteps && state.displayIndex
      const shouldShowLogoContainer = showEPlixLogo || shouldShowStep || title

      const currentStep = useMemo(
        () =>
          currentStepCallback
            ? currentStepCallback(Number(state?.displayIndex))
            : wizardProps.state.displayIndex,
        [
          currentStepCallback,
          state?.displayIndex,
          wizardProps.state.displayIndex,
        ],
      )
      const showHeader = useMemo(() => {
        return !hideHeaderIndex?.includes(currentStep ?? -1)
      }, [currentStep, hideHeaderIndex])

      const totalSteps = useMemo(
        () =>
          totalStepsCallback
            ? totalStepsCallback(totalDisplaySteps)
            : totalDisplaySteps,
        [totalDisplaySteps, totalStepsCallback],
      )

      const hasFadeIn = showBrandedPage && currentStep === 1

      useEffect(() => {
        if (hasFadeIn) {
          debounce(() => setStartFadeIn(true), 0)()
        }
      }, [hasFadeIn])

      return (
        <div
          className={clsx(
            'relative flex flex-col justify-center md:gap-8 w-full h-full',
            hasFadeIn && 'opacity-0',
            startFadeIn && classes.fadeIn,
            wizardWrapClassName,
          )}
        >
          {showHeader && (
            <div
              className={clsx(
                'flex flex-col-reverse lg:flex-col lg:gap-8 w-full',
                headerWrapperClass,
              )}
            >
              <div className={clsx(logoContainerClassName, 'flex flex-row')}>
                {shouldShowLogoContainer && (
                  <div
                    className={clsx(
                      'relative flex items-center justify-between w-full mb-6 md:mb-0',
                      logoWrapperClassName,
                    )}
                  >
                    {showEPlixLogo && mainLogo}
                    <div className="flex flex-col items-end h-full justify-end">
                      {label && shouldShowStartLabel && (
                        <Translate
                          as="div"
                          label={label?.label}
                          translationOptions={label?.translationOptions}
                          color="text-tertiary"
                        />
                      )}
                      {title && shouldShowStep && !shouldShowStartLabel && (
                        <Translate
                          as="div"
                          label={title.label}
                          translationOptions={title.translationOptions}
                          color="text-tertiary"
                        />
                      )}
                      {shouldShowStep && !shouldShowStartLabel && (
                        <div className="flex flex-row text-text-secondary typo-regular-100 text-text-tertiary">
                          <Translate
                            label="USM-Wizard:Wizard.Step"
                            variant="regular-100"
                            color="text-tertiary"
                          />
                          &nbsp;
                          {showBrandedPage
                            ? (currentStep as number) - 1
                            : currentStep}
                          &nbsp;
                          <Translate
                            label="USM-Wizard:Wizard.Of"
                            variant="regular-100"
                            color="text-tertiary"
                          />
                          &nbsp;
                          {showBrandedPage
                            ? (totalSteps as number) - 1
                            : totalSteps}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <ProgressBar
                totalSteps={
                  showBrandedPage ? totalDisplaySteps - 1 : totalDisplaySteps
                }
                currentStep={
                  showBrandedPage
                    ? (state.displayIndex as number) - 1
                    : (state.displayIndex as number)
                }
                hidden={!shouldShowStep}
              />
            </div>
          )}
          <div
            className={clsx(
              'relative flex items-center flex-col gap-4 w-full h-full',
              formWrapperClassName,
            )}
          >
            {form}
          </div>
        </div>
      )
    },
  ),
)
