import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'

import classes from './table.module.scss'

export interface TableSummaryProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  summary?: string
  mainTitle?: string
}

export const TableSummary = forwardRef<HTMLDivElement, TableSummaryProps>(
  ({ summary, mainTitle, className, children, ...props }, ref) => {
    return (
      <div {...props} className={clsx(classes.table, className)} ref={ref}>
        <div className={classes.summary}>{summary}</div>
        <div className={classes.mainTitle}>{mainTitle}</div>
        {children}
      </div>
    )
  },
)
