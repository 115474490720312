import screenSize from '../foundation/media-queries.json'
import isClient from './is-client'
import { useWindowSize } from './use-window-size'
import useDebouncedMemo from './use-debounce-memo'
import { useMemo } from 'react'
import { MediaQueryProp } from './css-helpers/types'

const BREAK_POINT_BOUNDARIES: Record<
  MediaQueryProp,
  {
    max?: number
    min?: number
  }
> = {
  xs: {
    max: screenSize.sm,
  },
  sm: {
    min: screenSize.sm,
    max: screenSize.md,
  },
  md: {
    min: screenSize.md,
    max: screenSize.lg,
  },
  lg: {
    min: screenSize.lg,
    max: screenSize.xl,
  },
  xl: {
    min: screenSize.xl,
    max: screenSize.xxl,
  },
  xxl: {
    min: screenSize.xxl,
    max: screenSize.xxxl,
  },
  xxxl: {
    min: screenSize.xxxl,
  },
  xxxxl: {
    min: screenSize.xxxxl,
  },
}

const AVAILABLE_BREAKPOINTS = Object.keys(
  BREAK_POINT_BOUNDARIES,
) as MediaQueryProp[]

const DEBOUNCE_TIMEOUT = 50

const minWidthString = (val: number) => `(min-width:${val}px)`
const maxWidthString = (val: number) => `(max-width:${val}px)`

const createMediaQueryString = (breakpoint: MediaQueryProp) => {
  const { min, max } = BREAK_POINT_BOUNDARIES[breakpoint]

  if (min && max) {
    return `${minWidthString(min)} and ${maxWidthString(max)}`
  }

  if (min) {
    return minWidthString(min)
  }

  if (max) {
    return maxWidthString(max)
  }

  return minWidthString(BREAK_POINT_BOUNDARIES.xs.max as number)
}

export const useBreakpoint = (
  defaultBreakpoint?: MediaQueryProp,
): MediaQueryProp | undefined => {
  const [width] = useWindowSize()
  const mediaQueries = useMemo<string[]>(
    // match the breakpoints from the biggest to the smallest
    () => AVAILABLE_BREAKPOINTS.map(createMediaQueryString).reverse(),
    [],
  )

  // debounce this function in order to avoid too many computations while the window resize
  return useDebouncedMemo(
    () => {
      // no breakpoints on the server
      if (!isClient || !width) return defaultBreakpoint

      // find the first breakpoint matched from the biggest to the smallest
      const activeMediaQueryIndex = mediaQueries.findIndex(
        (mq) => window.matchMedia(mq).matches,
      )

      return (
        // return the breakpoint as string
        AVAILABLE_BREAKPOINTS[
          AVAILABLE_BREAKPOINTS.length - (1 + activeMediaQueryIndex)
        ] || defaultBreakpoint
      )
    },
    [width, defaultBreakpoint, mediaQueries],
    DEBOUNCE_TIMEOUT,
  )
}
