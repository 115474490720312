import { MessagesViewEnum } from '@/common/enums/MessagesView'

export const toggleButtonConfig = [
  {
    label: 'USM-UserAccount:ManageMaintenance.General',
    value: MessagesViewEnum.GENERAL,
    disabled: false,
  },
  {
    label: 'USM-UserAccount:ManageMaintenance.Custom',
    value: MessagesViewEnum.CUSTOM,
    disabled: false,
  },
]

export const MAX_DESCRIBE_CHARACTERS_NUMBER = 300
