/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestDateFormat } from '@/common/constants/date-format'
import { SortTypeEnum } from '@/common/enums/SortType'
import { formatMoney } from '@/common/formatters/money-formatter'
import { roundFixed } from '@/common/helpers/round-decimals'
import { ColumnType, TableRowData } from '@/components/application-table/types'
import { formatPercentageNoRounding } from '@/features/portfolio/components/portfolio-info-overview/util'
import { getDifferenceBetweenDates, parseRequestDateAndTime } from '@/framework'
import { identity } from 'lodash'

const requestDateFormat = RequestDateFormat.YYYY_MM_DD

const formatNumber = (value: number | string) => roundFixed(Number(value), 2)
const formatCurrency = (value: number | string) => formatMoney(Number(value))
const formatDate = (
  value: string,
  format: string,
  inputFormat?: string,
): string =>
  parseRequestDateAndTime(value, inputFormat ?? requestDateFormat, format)

const getFormatterByType = (type: ColumnType) => {
  switch (type) {
    case ColumnType.DATE:
      return formatDate
    case ColumnType.CURRENCY:
      return formatCurrency
    case ColumnType.PERCENTAGE:
      return (value: number) => formatPercentageNoRounding(value)
    default:
      return identity
  }
}

const formatters: Record<
  string,
  (value: any, format?: any, inputFormat?: string) => string
> = {
  formatNumber,
  formatCurrency,
  formatDate,
}

const filterRows = (key: string, rows: Array<TableRowData>) => {
  const searchTerm = key.toLowerCase()
  return rows.filter((row: TableRowData) => {
    const foundRow = Object.values(row).find((objValue) => {
      return objValue?.toString().toLowerCase().includes(searchTerm)
    })
    return foundRow !== undefined
  })
}

const compareDateCallback = (
  a: Record<string, any>,
  b: Record<string, any>,
  property: string,
  format?: string,
) => {
  const dateTimeFormat = RequestDateFormat.DD_MM_YYYY_TIME
  const dateFormat = format ?? dateTimeFormat

  return getDifferenceBetweenDates(b[property], a[property], dateFormat)
}

const sortDate = (
  records: Array<TableRowData>,
  order: string,
  property: string,
  dateFormat?: string,
): Array<TableRowData> => {
  if (!records) return []
  if (order === SortTypeEnum.ASCENDING) {
    return records.sort((a, b) =>
      compareDateCallback(b, a, property, dateFormat),
    )
  }

  if (order === SortTypeEnum.DESCENDING) {
    return records.sort((a, b) =>
      compareDateCallback(a, b, property, dateFormat),
    )
  }

  return []
}

const compareTextCallback = (
  a: Record<string, any>,
  b: Record<string, any>,
  property: string,
) => {
  const aValue = a[property]?.props?.label ?? a[property]
  const bValue = b[property]?.props?.label ?? b[property]

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return aValue.localeCompare(bValue)
  }

  return 0
}

const sortText = (
  records: Array<TableRowData>,
  order: string,
  property: string,
): Array<TableRowData> => {
  if (!records) return []
  if (order === SortTypeEnum.ASCENDING) {
    return records.sort((a, b) => compareTextCallback(b, a, property))
  }

  if (order === SortTypeEnum.DESCENDING) {
    return records.sort((a, b) => compareTextCallback(a, b, property))
  }
  return []
}

const compareNumbersCallback = (propA: string, propB: string) => {
  return parseFloat(propA) - parseFloat(propB)
}

const sortNumber = (
  records: Array<TableRowData>,
  order: string,
  property: string,
): Array<TableRowData> => {
  if (!records) return []

  if (order === SortTypeEnum.ASCENDING) {
    return records.sort((a, b) =>
      compareNumbersCallback(
        a[property]?.toString() ?? '0',
        b[property]?.toString() ?? '0',
      ),
    )
  }

  if (order === SortTypeEnum.DESCENDING) {
    return records.sort((a, b) =>
      compareNumbersCallback(
        b[property]?.toString() ?? '0',
        a[property]?.toString() ?? '0',
      ),
    )
  }
  return []
}

const sort: Record<
  string,
  (
    records: any,
    order: string,
    property: string,
    dateFormat?: string,
  ) => Array<TableRowData>
> = {
  sortDate,
  sortText,
  sortNumber,
}

const getIsOrderAscending = (order: string) => order === SortTypeEnum.ASCENDING

const getOrderDirection = (order: string) =>
  getIsOrderAscending(order) ? SortTypeEnum.DESCENDING : SortTypeEnum.ASCENDING

export const getTableActions = () => {
  return {
    sort,
    formatters,
    filterRows,
    getIsOrderAscending,
    getOrderDirection,
    getFormatterByType,
  }
}
