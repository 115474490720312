import { ForgotPasswordConfirmPhoneForm } from './forgot-password-confirm-phone-form'
import { ResendSmsForm } from './resend-sms-form'
import { NewPasswordForm } from './new-password-form'
import { Wizard } from '@/components/wizard/wizard'
import { AuthLayout } from '@/components'

export const ForgotPasswordScreen = () => {
  return (
    <AuthLayout>
      <Wizard skipDisplayIndex={[0, 1, 2]}>
        <ForgotPasswordConfirmPhoneForm />
        <ResendSmsForm />
        <NewPasswordForm />
      </Wizard>
    </AuthLayout>
  )
}
