export const REGEX_EXPRESSION = {
  EDIT_SICKNESS_CASE: /^\/sickness\/\d+\/edit$/,
  NEW_SICKNESS_CASE: /[a-z]{2}_[A-Z]{2}\/sickness\/new-sickness/,
  JUST_NUMBERS: /^[0-9]+$/,
  smsCode_FORBIDDEN_CHARS: /[^0-9]/gi,
  NAME_FORBIDDEN_CHARS: /^[0-9+()~!@#$%^&*_+{}|:?<>,./\\;\[\]]*$/,
  NAME_REG_EXP: /^(?!.*[0-9~!@#$%^&*()_+=\/.,<>?\[\]]).*$/,
  MONEY_FORMAT_NO_DECIMAL: /\d(?=(\d{3})+$)/g,
  MONEY_FORMAT: /\d(?=(\d{3})+\.)/g,
  DECIMAL_FORMAT: /^\d+((\.\d{1,2})?|\.)$/, //used as input mask (it allows "1."))
  EMAIL_VALIDATION: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  ALPHANUMERICAL: /^([a-zA-Z0-9]*)$/,
  IBAN: /^(\d{0,2})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,1}).*/,
  NAME: /^([a-zA-Z]+-{0,1}\s{0,1}[a-zA-Z]*'{0,1}[a-zA-Z]*\s{0,1}[a-zA-Z]+-{0,1}\s{0,1}[a-zA-Z]+)$/g,
  DATE: /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048])00)[-/]?02[-/]?29)$/,
  FIND_QUOTES: /['"]+/g,
}
