import { FC } from 'react'
import { IconProps } from './types'

export const ShieldIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      width={24}
      height={26}
      viewBox={`0 0 24 26`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="fefefe"
      {...props}
    >
      <path
        d="M11.2195 1.82725L11.2192 1.82706L11.219 1.8269L11.2195 1.82725ZM11.2195 1.82725L11.2208 1.82824C11.273 1.86767 11.3258 1.90755 11.3781 1.94687L11.9771 1.14975M11.2195 1.82725L11.9771 1.14975M11.9771 1.14975L11.3781 1.94688C11.9061 2.34361 12.5124 2.78745 13.225 3.175C14.6502 3.95012 16.5002 4.49996 19 4C19 9.49994 18.25 13.25 16.75 15.875C15.2501 18.4998 13.0003 19.9999 10 21C6.99974 19.9999 4.7499 18.4998 3.25 15.875C1.74998 13.25 1 9.49994 1 4C2.99972 4.49993 4.8496 3.9502 6.4 3.175C7.17504 2.78748 7.87508 2.3437 8.48125 1.94688C8.57283 1.88692 8.66468 1.82636 8.75487 1.76689L8.75589 1.76622L8.75606 1.7661M11.9771 1.14975L8.75606 1.7661M8.75606 1.7661C8.75639 1.76588 8.75672 1.76566 8.75705 1.76545M8.75606 1.7661L8.75705 1.76545M8.75705 1.76545C9.25218 1.43896 9.67235 1.16382 10 1C10.3506 1.17531 10.7542 1.47586 11.2188 1.82673L8.75705 1.76545Z"
        stroke="#A48519"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
