import { FC } from 'react'
import { IconProps } from './types'

export const BriefcaseIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8284 3.17157C15.338 3.68111 15.6958 4.31397 15.873 5H19C20.6569 5 22 6.34315 22 8V11.5216C22 12.3816 21.6299 13.1968 21 13.7497V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V13.7497C2.37013 13.1968 2 12.3816 2 11.5216V8C2 6.34315 3.34315 5 5 5H8.127C8.30415 4.31397 8.66202 3.68111 9.17157 3.17157C9.92172 2.42142 10.9391 2 12 2C13.0609 2 14.0783 2.42142 14.8284 3.17157ZM10.5858 4.58578C10.9608 4.21071 11.4696 4 12 4C12.5304 4 13.0392 4.21071 13.4142 4.58578C13.5389 4.71046 13.6454 4.8499 13.7321 5H10.2679C10.3546 4.8499 10.4611 4.71046 10.5858 4.58578ZM14.9927 15.2103C16.3366 15.0823 17.6749 14.868 19 14.5672V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V14.5672C6.32513 14.868 7.6634 15.0823 9.00726 15.2103C9.11521 16.769 10.4138 18 12 18C13.5862 18 14.8848 16.769 14.9927 15.2103ZM12.9413 15.3385C12.3139 15.3572 11.6861 15.3572 11.0587 15.3385C11.1975 15.7242 11.5665 16 12 16C12.4335 16 12.8025 15.7242 12.9413 15.3385ZM5 7C4.44772 7 4 7.44772 4 8V11.5216C4 11.9721 4.28958 12.3335 4.6706 12.4307C9.4876 13.6598 14.5124 13.6598 19.3294 12.4307C19.7104 12.3335 20 11.9721 20 11.5216V8C20 7.44772 19.5523 7 19 7H5Z"
        fill="currentColor"
      />
    </svg>
  )
}
