import clsx from 'clsx'
import classes from './user-card.module.scss'

import { forwardRef, HTMLProps, PropsWithoutRef, ReactHTML } from 'react'

export type UserCardElement = 'button' | 'a' | 'span' | 'div'

export interface UserCardElementProps<TElement extends UserCardElement>
  extends HTMLProps<ReactHTML[TElement]> {
  /**
   * Controls which DOM element is used to render the button. Obviously impacts which additional props the component
   * can take. Possible options: `'a'`, `'button'`, `'span'` and `'div'`. Defaults to `'div'`
   */
  as?: TElement
}

export const UserCard = forwardRef<
  HTMLDivElement,
  PropsWithoutRef<HTMLProps<HTMLDivElement>>
>(({ children, className, ...props }, ref) => {
  return (
    <div {...props} className={clsx(classes.userCard, className)} ref={ref}>
      {children}
    </div>
  )
})
