import useMediaQuery from '@eplix/ui/utils/use-media-query'
import { ScreenBreakPoints } from '../constants'

export const useMatchSmallScreen = () => {
  return useMediaQuery(`(max-width:${ScreenBreakPoints.LG}px)`)
}
export const useMatchSmallAndMediumScreen = () => {
  return useMediaQuery(`(max-width:${ScreenBreakPoints.MD}px)`)
}
export const useMatchMobileScreen = () => {
  return useMediaQuery(`(max-width:${ScreenBreakPoints.SM}px)`)
}
export const useMatchMediumOrLargeScreen = () => {
  return useMediaQuery(`(min-width:${ScreenBreakPoints.MD}px)`)
}
export const useMatchXLargeScreen = () => {
  return useMediaQuery(`(min-width:${ScreenBreakPoints.XL}px)`)
}
export const useMatchXXLargeScreen = () => {
  return useMediaQuery(`(min-width:${ScreenBreakPoints.XXL}px)`)
}
export const useMatchXXXLargeScreen = () => {
  return useMediaQuery(`(min-width:${ScreenBreakPoints.XXXL}px)`)
}
export const useMatchXXXXLargeScreen = () => {
  return useMediaQuery(`(min-width:${ScreenBreakPoints.XXXXL}px)`)
}
