import { DependencyList, useCallback, useEffect, useState } from 'react'
import { debounce } from './debounce'

/**
 * Debounced useMemo()
 * borrowed from https://github.com/SevenOutman/use-debounced-memo/blob/master/src/useDebouncedMemo.ts
 */
export function useDebouncedMemo<T>(
  factory: () => T,
  deps: DependencyList,
  wait: number,
): T {
  const [state, setState] = useState(factory())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetState = useCallback(debounce(setState, wait), deps)

  useEffect(() => {
    return debouncedSetState(factory()).cancel
  }, [debouncedSetState, factory])

  return state
}

export default useDebouncedMemo
