import { IconProps } from './types'

export const EplixIcon = (props: IconProps) => {
  return (
    <svg
      id="eplix_icon"
      data-name="eplix_icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 117 78"
      {...props}
    >
      <defs>
        <style>
          {`.eplix_icon-1 {
            fill: #fff;
          }
            .eplix_icon-1, .eplix_icon-2, .eplix_icon-3, .eplix_icon-4, .eplix_icon-5 {
            stroke-width: 0px;
          }
            .eplix_icon-2 {
            fill: #88bf20;
          }
            .eplix_icon-3 {
            fill: url(#eplix_icon_radial-gradient-2);
          }
            .eplix_icon-4 {
            fill: url(#eplix_icon_radial-gradient);
          }
            .eplix_icon-5 {
            fill: #3a85f8;
          }`}
        </style>
        <radialGradient
          id="eplix_icon_radial-gradient"
          cx="38.19"
          cy="38.56"
          fx="38.19"
          fy="38.56"
          r="39.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#c8de8d" />
          <stop offset=".92" stopColor="#88bf20" />
        </radialGradient>
        <radialGradient
          id="eplix_icon_radial-gradient-2"
          cx="-889.32"
          cy="550.25"
          fx="-889.32"
          fy="550.25"
          r="39.75"
          gradientTransform="translate(-810.51 589.69) rotate(-180)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#1f4c76" />
          <stop offset=".92" stopColor="#3a85f8" />
        </radialGradient>
      </defs>
      <g>
        <circle className="eplix_icon-5" cx="39" cy="39" r="39" />
        <path
          className="eplix_icon-2"
          d="m78,0c-7.11,0-13.76,1.91-19.5,5.23,11.65,6.74,19.5,19.34,19.5,33.77h-39c0,21.54,17.46,39,39,39s39-17.46,39-39S99.54,0,78,0Z"
        />
        <path
          className="eplix_icon-4"
          d="m78,39h-39c0,14.43,7.85,27.03,19.5,33.77,11.65-6.74,19.5-19.34,19.5-33.77h0Z"
        />
        <path
          className="eplix_icon-3"
          d="m39,39h39c0-14.43-7.85-27.03-19.5-33.77-11.65,6.74-19.5,19.34-19.5,33.77h0Z"
        />
      </g>
      <g>
        <path
          className="eplix_icon-1"
          d="m64.04,66.96h.02c1.03,0,1.89-.34,2.49-.98.6-.64.91-1.53.92-2.65v-9.98c3.09,3.49,7.73,5.39,13.32,5.41h.09c11.04,0,18.48-7.9,18.53-19.69.05-11.82-7.37-19.79-18.47-19.84h-.1c-5.54,0-10.16,1.87-13.26,5.3v-1.65c0-1.07-.3-1.98-.89-2.62-.61-.67-1.48-1.03-2.51-1.03h-.02c-2.05,0-3.33,1.39-3.34,3.63l-.12,40.44c0,1.12.28,2.01.85,2.64.58.66,1.44,1,2.48,1.01Zm16-41.35h.06c7.49.03,12.5,5.43,12.47,13.44-.03,7.99-5.06,13.34-12.52,13.34h-.06c-7.37-.03-12.5-5.56-12.47-13.44.02-3.93,1.24-7.28,3.54-9.7,2.27-2.38,5.37-3.64,8.97-3.64Z"
        />
        <path
          className="eplix_icon-1"
          d="m37.08,19.24c-11.53-.05-19.44,7.95-19.48,19.69-.05,11.81,7.79,19.79,19.71,19.84,6.49.03,11.93-2.55,15.38-6.79.46-.63.69-1.34.7-2.04,0-1.89-1.29-3.23-3.2-3.24-1.07,0-1.91.46-2.6,1.25-2.23,2.67-5.75,4.47-10.25,4.45-6.95-.03-11.67-4.3-12.72-10.76l26.81.11c2.67.01,4.28-1.56,4.29-4.31v-.16c.04-10.08-7.87-17.98-18.64-18.02Zm-12.29,16.25c1.32-5.98,5.92-9.9,12.26-9.87,6.34.02,10.91,3.98,12.18,9.97l-24.45-.1Z"
        />
      </g>
    </svg>
  )
}
