import classes from './password-policy-checklist.module.scss'
import { CheckListGreenIcon, CheckListIcon, Typo } from '@eplix/ui'
import { useTranslation } from 'react-i18next'
import { PasswordPolicyErrorCodeEnum } from '@/common/enums/PasswordPolicyErrorCode'
import { memo } from 'react'

type PasswordPolicyChecklistProps = {
  passwordErrors: string | undefined
  isDirty?: boolean | undefined
}

export const PasswordPolicyChecklist = memo(
  ({ passwordErrors, isDirty }: PasswordPolicyChecklistProps) => {
    const { t } = useTranslation()

    const validatePasswordPolicy = () => {
      const hasError = (errorCode: string) =>
        passwordErrors?.includes(errorCode)
      const required = hasError(PasswordPolicyErrorCodeEnum.REQUIRED)
      const isInErrorState = !isDirty || required
      return {
        hasRequiredValidationError: required,
        hasMinLengthValidationError:
          isInErrorState || hasError(PasswordPolicyErrorCodeEnum.MIN),
        hasUppercaseOrLowercaseError:
          isInErrorState ||
          hasError(PasswordPolicyErrorCodeEnum.UPPER) ||
          hasError(PasswordPolicyErrorCodeEnum.LOWER),
        hasSpecialCharacterValidationError:
          isInErrorState || hasError(PasswordPolicyErrorCodeEnum.SPEC),
      }
    }

    const fieldErrors = validatePasswordPolicy()

    return (
      <div className={classes.checkList}>
        <Typo variant="regular-200" color="text-primary">
          {t(
            'USM-UserAccount:PasswordPolicyChecklist:Requirements',
            'Password requirements',
          )}
          :
        </Typo>

        <Typo variant="regular-100" color="text-primary">
          <div className={classes.insideList}>
            <div className={classes.checkListIcon}>
              {fieldErrors?.hasMinLengthValidationError && <CheckListIcon />}
              {!fieldErrors?.hasMinLengthValidationError && (
                <CheckListGreenIcon />
              )}
            </div>
            {t(
              'USM-UserAccount:PasswordPolicyChecklist:Minimum',
              'Minimum of 8 Characters',
            )}
          </div>
        </Typo>
        <Typo variant="regular-100" color="text-primary">
          <div className={classes.insideList}>
            <div className={classes.checkListIcon}>
              {fieldErrors?.hasUppercaseOrLowercaseError && <CheckListIcon />}
              {!fieldErrors?.hasUppercaseOrLowercaseError && (
                <CheckListGreenIcon />
              )}
            </div>
            {t(
              'USM-UserAccount:PasswordPolicyChecklist:UppercaseAndLowercase',
              'Contains at least 1 uppercase and 1 lowercase letter',
            )}
          </div>
        </Typo>
        <Typo variant="regular-100" color="text-primary">
          <div className={classes.insideList}>
            <div className={classes.checkListIcon}>
              {fieldErrors?.hasSpecialCharacterValidationError && (
                <CheckListIcon />
              )}
              {!fieldErrors?.hasSpecialCharacterValidationError && (
                <CheckListGreenIcon />
              )}
            </div>
            {t(
              'USM-UserAccount:PasswordPolicyChecklist:Special',
              '1 number and 1 special character (123 !?@#$&.<>%^()~*)',
            )}
          </div>
        </Typo>
      </div>
    )
  },
)
