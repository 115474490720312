import clsx from 'clsx'
import { ForwardedRef, forwardRef, HTMLProps } from 'react'

import classes from './menu-list.module.scss'

export type MenuListProps = HTMLProps<HTMLUListElement>

export const MenuList = forwardRef(
  (
    { className, children, ...props }: MenuListProps,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    return (
      <ul
        role="menu"
        className={clsx(classes.menuList, className)}
        ref={ref}
        {...props}
      >
        {children}
      </ul>
    )
  },
)
