import clsx from 'clsx'
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'

import classes from './text-input.module.scss'

export type TextInputProps = InputHTMLAttributes<HTMLInputElement>

export const TextInput = forwardRef(
  (
    { className, ...props }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <input
        {...props}
        className={clsx(className, classes.textInput)}
        ref={ref}
        data-cy={props.name}
      />
    )
  },
)
