import { ActionResponse, ErrorResponse } from '@/api'
import { setSettings } from '@/features/portfolio/lib/overview-api-client'
import { SetPortfolioSettingsProps } from '@/features/portfolio/lib/types'

import { useMutation } from '@/framework/common'

export const setPortfolioSettingsKey = 'setPortfolioSettings'
export const getSalesPartnerPortfoliosKey = 'getSalesPartnerPortfoliosKey'

export const useSetPortfolioSettings = () => {
  return useMutation<ActionResponse | ErrorResponse, SetPortfolioSettingsProps>(
    setSettings,
    setPortfolioSettingsKey,
  )
}
