/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:03:13.

export enum CooperationPartnerEnum {
  TELLCO = 'TELLCO',
  PIGUET_GALLAND = 'PIGUET_GALLAND',
  ALKIMIA = 'ALKIMIA',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
