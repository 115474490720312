import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TranslateSimpleProps } from '../types'

export const useTranslate = ({
  label,
  placeholder,
  translationOptions,
}: TranslateSimpleProps) => {
  const { t } = useTranslation()

  return useMemo(
    () => t((label || placeholder) as string, translationOptions),
    [t, label, placeholder, translationOptions],
  )
}
