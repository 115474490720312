import { Chip } from '@eplix/ui'
import { YearsChipProps } from './types'
import { memo } from 'react'

export const YearsChip = memo(
  ({
    years,
    setSelectedYearHandler,
    selectedYear,
    selectedElements,
  }: YearsChipProps) => {
    const mapChipYears =
      years &&
      years.map((year) => {
        const isSelected = selectedElements?.includes(year)
        return (
          <div key={year?.toString()} className="!items-center mr-2">
            <Chip
              as="a"
              variant="outlined"
              name="year"
              className="m-1 min-w-[84px] !flex-center !items-center justify-center !text-center"
              selected={year === selectedYear || isSelected}
              onClick={() => year && setSelectedYearHandler(year)}
            >
              {year}
            </Chip>
          </div>
        )
      })
    return <>{mapChipYears}</>
  },
)
