import { useId } from '@eframe-ui/react'
import { TypographyProp } from '@eplix/ui/utils/css-helpers/types'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import {
  FC,
  HTMLProps,
  KeyboardEvent,
  ReactNode,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ChevronDownIcon, CrossIcon } from '../icons'
import { Translate } from '../translate'
import classes from './accordion.module.scss'

export interface AccordionProps extends HTMLProps<HTMLDivElement> {
  getToggleState?: ((args: boolean) => void) | undefined
  title: string
  titleClassName?: string
  titleVariant?: TypographyProp
  disableInitAnimation?: boolean
  as?: 'div' | 'li'
  asTitle?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'span'
  /**
   * _onToggle props is used only by accordionGroup component.
   */
  _onToggle?: (() => void) | undefined
  onUpdate?: ((a?: boolean) => void) | undefined
  open?: boolean
  _open?: boolean
  children: ReactNode
  isFirst?: boolean
  isLast?: boolean
  withoutBorder?: boolean
  crossIcon?: boolean
  scrollIntoViewOnOpen?: boolean
  translationOptions?: Record<string, unknown>
  headerClassName?: string
}

export const Accordion: FC<AccordionProps> = ({
  as = 'div',
  asTitle = 'h2',
  title,
  className,
  _onToggle,
  onUpdate,
  open,
  _open,
  children,
  isFirst,
  isLast,
  withoutBorder,
  crossIcon,
  titleClassName,
  scrollIntoViewOnOpen = false,
  getToggleState,
  titleVariant = 'regular-300',
  translationOptions,
  headerClassName,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open)
  const firstUpdate = useRef(true)
  const id = useId()
  const accordionButtonId = useMemo(() => `accordion-button-${id}`, [id])
  const accordionRegionId = useMemo(() => `accordion-region-${id}`, [id])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    setIsOpen(Boolean(_open))
    getToggleState?.(Boolean(_open))
  }, [_open, getToggleState])

  const handleKeyDown = (event: KeyboardEvent) =>
    event.key === 'Enter' && toggleAccordion()

  const toggleAccordion = () => (_onToggle ? _onToggle() : setIsOpen(!isOpen))

  const arrow = {
    closed: { rotate: 0 },
    opened: { rotate: -180, transition: { duration: 0.2 } },
  }

  const AccordionIcon = () => {
    return crossIcon ? (
      <CrossIcon
        className={classes.icon}
        onClick={() => {
          onUpdate && onUpdate()
        }}
      />
    ) : (
      <AnimatePresence initial={false}>
        <motion.span
          className={classes.icon}
          animate={isOpen ? arrow.opened : arrow.closed}
        >
          <ChevronDownIcon />
        </motion.span>
      </AnimatePresence>
    )
  }

  const ref = useRef<HTMLDivElement>(null)
  const scrollIntoView = useCallback(() => {
    if (scrollIntoViewOnOpen && isOpen) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [isOpen, scrollIntoViewOnOpen])

  return createElement(
    as,
    {
      className: clsx(
        !withoutBorder && classes.border,
        classes.accordion,
        isOpen && classes.open,
        className,
      ),
      onKeyDown: handleKeyDown,
      initial: isFirst && { marginBottom: '12px' },
      animate: isOpen
        ? isFirst
          ? { marginBottom: '12px' }
          : isLast
          ? { marginTop: '12px' }
          : { marginTop: '12px', marginBottom: '12px' }
        : !isFirst
        ? isLast
          ? { marginTop: '-0.5px' }
          : { marginTop: '-1px' }
        : { margin: '0' },
      transition: { ease: 'easeInOut', duration: 0.3 },
      ...props,
      ref,
    },

    <>
      <button
        data-cy="btn_toggle_accordion"
        className={clsx(classes.header, headerClassName)}
        aria-controls={accordionRegionId}
        aria-expanded={isOpen ? 'true' : 'false'}
        id={accordionButtonId}
        onClick={toggleAccordion}
        tabIndex={0}
      >
        <div className={clsx(classes.title, titleClassName)}>
          <Translate
            as={asTitle}
            label={title}
            color="black"
            translationOptions={translationOptions}
            variant={titleVariant}
          />
        </div>
        <AccordionIcon />
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            animate={isOpen ? { padding: '0px 0px 20px' } : { padding: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            onAnimationComplete={scrollIntoView}
          >
            <motion.div
              role="region"
              aria-labelledby={accordionButtonId}
              style={{ transformOrigin: 'top' }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              transition={{ ease: 'easeInOut', duration: 0 }}
              id={accordionRegionId}
              className={clsx(children && classes.content)}
            >
              {isOpen && children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>,
  )
}
