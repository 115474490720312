import { Card, WarningIcon } from '@eframe-ui/react'
import { FC, HTMLProps, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { asyncCall } from '@/common/helpers/async-wrapper'

export interface InfoSnackbarProps extends HTMLProps<HTMLDivElement> {
  text: string | JSX.Element
  isPersistent?: boolean
  duration?: number
  className?: string
  textStyle?: string
}
const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))
export const InfoSnackbar: FC<InfoSnackbarProps> = ({
  className,
  isPersistent = false,
  text,
  duration = 0,
  textStyle,
}) => {
  const [isTimeOut, setIsTimeout] = useState<boolean>(false)
  const countDown = useCallback(async () => {
    let isMounted = true
    await delay(duration)
    if (isMounted) {
      setIsTimeout(true)
    }
    return () => {
      isMounted = false
    }
  }, [duration])

  useEffect(() => {
    if (isPersistent) return
    asyncCall(countDown)
    return () => {
      setIsTimeout(false)
    }
  }, [countDown, duration, isPersistent])
  return (
    <div className={clsx(className, isTimeOut && !isPersistent && 'hidden')}>
      <Card
        className="gap-4 w-full justify-center items-center"
        bgColor="#FFB23E"
      >
        <div>
          <WarningIcon size={24} />
        </div>
        <p className={textStyle}>{text}</p>
      </Card>
    </div>
  )
}
