import { Hint } from '@eplix/ui'
import { UploadDocumentsHint } from '@/features/registration/components/upload-documents/components/upload-documents-hint/upload-documents-hint'
import { getPersonalDocumentsHintActions } from '@/components/personal-documents-hint/actions'
import { PersonalDocumentsHintProps } from './types'
import { Dropdown } from '../dropdown'
import { useMemo } from 'react'

const { useGetPersonalDocuments } = getPersonalDocumentsHintActions()

export const PersonalDocumentsHint = ({
  onChange,
  documentType,
  className,
  buttonStyle,
  label = 'common:PersonalDocumentsHint.SelectDocument',
}: PersonalDocumentsHintProps) => {
  const personalDocuments = useGetPersonalDocuments()

  const memoizedOptions = useMemo(
    () =>
      personalDocuments.map(({ translation, value }) => ({
        label: translation,
        value,
      })),
    [personalDocuments],
  )

  return (
    <div>
      <Dropdown
        label={label}
        name="select_document"
        value={documentType}
        className={className}
        onChange={onChange}
        buttonLabel={documentType.text}
        options={memoizedOptions}
        optionsProps={{ className: 'h-fit mt-1' }}
      />
      <Hint
        activeMessage={
          <UploadDocumentsHint documentType={documentType.value as string} />
        }
        buttonStyle={buttonStyle}
      />
    </div>
  )
}
