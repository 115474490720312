export * from './accordion'
export * from './alerts'
export * from './buttons'
export * from './callout'
export * from './chips'
export * from './country-flag'
export * from './cta-list'
export * from './definition-list'
export * from './form-fields'
export * from './greeting'
export * from './grid'
export * from './hint'
export * from './icons'
export * from './inputs'
export * from './loader'
export * from './loading-dots'
export * from './logos'
export * from './menu-list'
export * from './slider'
export * from './slider-ranged/slider-ranged'
export * from './table'
export * from './templates'
export * from './theme-provider'
export * from './tooltip'
export * from './translate'
export * from './typography'
export * from './user-card'

//Infer generic Props when forwarding refs
//https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref

declare module 'react' {
  //eslint-disable-next-line
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}
