import useMediaQuery from '@eplix/ui/utils/use-media-query'
import { Document } from '@/features/registration/components/documents/document'
import { Drawer } from '@/features/registration/components/drawer/drawer'
import { DocumentsEnum } from '@/common/enums'
import clsx from 'clsx'
import Image from 'next/image'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import screenSize from '@eplix/ui/foundation/media-queries.json'
import { RegistrationLayoutProps } from '@/components/layouts/registration-layout/types'

export const RegistrationLayout = forwardRef(
  (
    {
      closeDrawer,
      drawerDocument,
      children,
      sectionStyle,
    }: RegistrationLayoutProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [documentOpen, setDocumentOpen] = useState(false)
    const matchesLargeScreen = useMediaQuery(`(min-width:${screenSize.lg}px)`)
    const { t } = useTranslation()

    useEffect(() => {
      if (drawerDocument !== null) {
        const timeout = setTimeout(() => {
          setDocumentOpen(true)
        }, 100)

        return () => clearTimeout(timeout)
      } else {
        setDocumentOpen(false)
      }
    }, [drawerDocument])

    const drawer = (largeScreen = false) => (
      <Drawer
        closeDrawer={closeDrawer}
        drawerDocument={drawerDocument}
        largeScreen={largeScreen}
      >
        <Document open={drawerDocument as DocumentsEnum} />
      </Drawer>
    )

    return (
      <div
        className={clsx(
          'flex justify-center lg:grid min-h-screen grid-rows-[max-content_1fr]',
          'lg:grid-rows-none lg:grid-cols-2',
        )}
      >
        <section
          className={clsx('w-full h-full items-center', sectionStyle)}
          ref={ref}
        >
          <div className="h-full">{children}</div>
        </section>
        <section
          className="relative hidden w-full items-center overflow-x-hidden overflow-y-auto lg:flex"
          style={{ maxHeight: documentOpen ? '100%' : '956px' }}
        >
          <div className="hidden lg:flex mx-auto max-w-[458px] max-h-[396px]">
            <Image
              alt="Auth"
              priority
              src={t('common:AuthImage', '/email_cover_en.png')}
              layout="intrinsic"
              width="458"
              height="396"
              className="object-contain"
            />
          </div>
          {matchesLargeScreen && drawer(matchesLargeScreen)}
        </section>
        {!matchesLargeScreen && drawer()}
      </div>
    )
  },
)
