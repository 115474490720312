import { AG } from '@/framework/zip-codes/source/AG'
import { AR } from '@/framework/zip-codes/source/AR'
import { AI } from '@/framework/zip-codes/source/AI'
import { BE } from '@/framework/zip-codes/source/BE'
import { BL } from '@/framework/zip-codes/source/BL'
import { BS } from '@/framework/zip-codes/source/BS'
import { DE } from '@/framework/zip-codes/source/DE'
import { FL } from '@/framework/zip-codes/source/FL'
import { FR } from '@/framework/zip-codes/source/FR'
import { GE } from '@/framework/zip-codes/source/GE'
import { GL } from '@/framework/zip-codes/source/GL'
import { GR } from '@/framework/zip-codes/source/GR'
import { IT } from '@/framework/zip-codes/source/IT'
import { JU } from '@/framework/zip-codes/source/JU'
import { LU } from '@/framework/zip-codes/source/LU'
import { NE } from '@/framework/zip-codes/source/NE'
import { NW } from '@/framework/zip-codes/source/NW'
import { OW } from '@/framework/zip-codes/source/OW'
import { SG } from '@/framework/zip-codes/source/SG'
import { SH } from '@/framework/zip-codes/source/SH'
import { SO } from '@/framework/zip-codes/source/SO'
import { SZ } from '@/framework/zip-codes/source/SZ'
import { TG } from '@/framework/zip-codes/source/TG'
import { TI } from '@/framework/zip-codes/source/TI'
import { UR } from '@/framework/zip-codes/source/UR'
import { VD } from '@/framework/zip-codes/source/VD'
import { VS } from '@/framework/zip-codes/source/VS'
import { ZG } from '@/framework/zip-codes/source/ZG'
import { ZH } from '@/framework/zip-codes/source/ZH'

export const ZipCodes: Record<string, string> = {
  ...AG,
  ...AI,
  ...AR,
  ...BE,
  ...BL,
  ...BS,
  ...DE,
  ...FL,
  ...FR,
  ...GE,
  ...GL,
  ...GR,
  ...IT,
  ...JU,
  ...LU,
  ...NE,
  ...NW,
  ...OW,
  ...SG,
  ...SH,
  ...SO,
  ...SZ,
  ...TG,
  ...TI,
  ...UR,
  ...VD,
  ...VS,
  ...ZG,
  ...ZH,
}
