import { FC } from 'react'
import { IconProps } from './types'

export const ExpandIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29289 4.29289C7.90237 4.68342 7.90237 5.31658 8.29289 5.70711C8.68342 6.09763 9.31658 6.09763 9.70711 5.70711L11 4.41421V9.00015H13V4.41452L14.2927 5.70726C14.6833 6.09778 15.3164 6.09778 15.707 5.70726C16.0975 5.31673 16.0975 4.68357 15.707 4.29304L12.7217 1.30782L12.7071 1.29289C12.325 0.910774 11.7106 0.90255 11.3184 1.26822C11.3098 1.27626 11.3013 1.28449 11.2929 1.29289L11.2927 1.29304L11.2921 1.29365L8.29289 4.29289ZM13 15.0002L13 19.5861L14.2929 18.2932C14.6834 17.9027 15.3166 17.9027 15.7071 18.2932C16.0976 18.6837 16.0976 19.3169 15.7071 19.7074L12.7081 22.7064L12.7073 22.7073L12.7071 22.7074C12.6903 22.7242 12.6731 22.7402 12.6555 22.7556C12.2626 23.0972 11.6666 23.0812 11.2929 22.7074L11.2783 22.6925L8.29304 19.7073C7.90252 19.3167 7.90252 18.6836 8.29304 18.293C8.68357 17.9025 9.31673 17.9025 9.70726 18.293L11 19.5858L11 15.0002H13ZM18 5.00015H20C21.6569 5.00015 23 6.3433 23 8.00015V16.0002C23 17.657 21.6569 19.0002 20 19.0002H18V17.0002H20C20.5523 17.0002 21 16.5524 21 16.0002V8.00015C21 7.44787 20.5523 7.00015 20 7.00015H18V5.00015ZM4 19.0002H6V17.0002H4C3.44772 17.0002 3 16.5524 3 16.0001L3 8.00015C3 7.44787 3.44772 7.00015 4 7.00015H6L6 5.00015H4C2.34315 5.00015 1 6.34329 1 8.00015V16.0001C1 17.657 2.34315 19.0002 4 19.0002Z"
        fill="currentColor"
      />
    </svg>
  )
}
