import { FC } from 'react'
import { IconProps } from './types'

export const DashedPeopleIcon: FC<IconProps> = ({
  iconSize = 56,
  ...props
}) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 56 56`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_ii_3415_1554)">
        <rect width="56" height="56" rx="28" fill="#E8ECF0" />
        <g clipPath="url(#clip0_3415_1554)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.2483 18.1604C31.7133 18.0234 31.1685 18.3461 31.0315 18.8811C30.8945 19.4161 31.2172 19.9609 31.7522 20.0979C32.3975 20.2631 32.9695 20.6384 33.3779 21.1646C33.7864 21.6908 34.0081 22.338 34.0081 23.0041C34.0081 23.6703 33.7864 24.3175 33.3779 24.8437C32.9695 25.3699 32.3975 25.7452 31.7522 25.9104C31.2172 26.0474 30.8945 26.5922 31.0315 27.1272C31.1685 27.6622 31.7133 27.9849 32.2483 27.8479C33.3238 27.5725 34.2771 26.947 34.9578 26.07C35.6386 25.193 36.0081 24.1144 36.0081 23.0041C36.0081 21.8939 35.6386 20.8153 34.9578 19.9383C34.2771 19.0613 33.3238 18.4358 32.2483 18.1604ZM36.2502 30.032C35.7155 29.8939 35.1701 30.2155 35.032 30.7502C34.8939 31.285 35.2155 31.8304 35.7502 31.9685C36.3202 32.1157 36.9086 32.5673 37.3713 33.1641C37.7784 33.6894 37.9997 34.3353 38.0002 34.9999V39H40.0002V34.9984C39.9994 33.8906 39.6307 32.8144 38.9519 31.9388C38.3286 31.1347 37.3965 30.328 36.2502 30.032Z"
            fill="#3A85F8"
          />
          <path
            d="M26 31C26 31.5523 25.5523 32 25 32C24.4477 32 24 31.5523 24 31C24 30.4477 24.4477 30 25 30C25.5523 30 26 30.4477 26 31Z"
            fill="#3A85F8"
          />
          <path
            d="M22.5 31C22.5 31.5523 22.0523 32 21.5 32C20.9477 32 20.5 31.5523 20.5 31C20.5 30.4477 20.9477 30 21.5 30C22.0523 30 22.5 30.4477 22.5 31Z"
            fill="#3A85F8"
          />
          <path
            d="M19 32C19 32.5523 18.5523 33 18 33C17.4477 33 17 32.5523 17 32C17 31.4477 17.4477 31 18 31C18.5523 31 19 31.4477 19 32Z"
            fill="#3A85F8"
          />
          <path
            d="M18 35C18 35.5523 17.5523 36 17 36C16.4477 36 16 35.5523 16 35C16 34.4477 16.4477 34 17 34C17.5523 34 18 34.4477 18 35Z"
            fill="#3A85F8"
          />
          <path
            d="M18 38C18 38.5523 17.5523 39 17 39C16.4477 39 16 38.5523 16 38C16 37.4477 16.4477 37 17 37C17.5523 37 18 37.4477 18 38Z"
            fill="#3A85F8"
          />
          <path
            d="M34 38C34 38.5523 33.5523 39 33 39C32.4477 39 32 38.5523 32 38C32 37.4477 32.4477 37 33 37C33.5523 37 34 37.4477 34 38Z"
            fill="#3A85F8"
          />
          <path
            d="M34 35C34 35.5523 33.5523 36 33 36C32.4477 36 32 35.5523 32 35C32 34.4477 32.4477 34 33 34C33.5523 34 34 34.4477 34 35Z"
            fill="#3A85F8"
          />
          <path
            d="M33 32C33 32.5523 32.5523 33 32 33C31.4477 33 31 32.5523 31 32C31 31.4477 31.4477 31 32 31C32.5523 31 33 31.4477 33 32Z"
            fill="#3A85F8"
          />
          <path
            d="M29.5 31C29.5 31.5523 29.0523 32 28.5 32C27.9477 32 27.5 31.5523 27.5 31C27.5 30.4477 27.9477 30 28.5 30C29.0523 30 29.5 30.4477 29.5 31Z"
            fill="#3A85F8"
          />
          <path
            d="M22 23C22 23.5523 21.5523 24 21 24C20.4477 24 20 23.5523 20 23C20 22.4477 20.4477 22 21 22C21.5523 22 22 22.4477 22 23Z"
            fill="#3A85F8"
          />
          <path
            d="M23 26C23 26.5523 22.5523 27 22 27C21.4477 27 21 26.5523 21 26C21 25.4477 21.4477 25 22 25C22.5523 25 23 25.4477 23 26Z"
            fill="#3A85F8"
          />
          <path
            d="M26 27C26 27.5523 25.5523 28 25 28C24.4477 28 24 27.5523 24 27C24 26.4477 24.4477 26 25 26C25.5523 26 26 26.4477 26 27Z"
            fill="#3A85F8"
          />
          <path
            d="M29 26C29 26.5523 28.5523 27 28 27C27.4477 27 27 26.5523 27 26C27 25.4477 27.4477 25 28 25C28.5523 25 29 25.4477 29 26Z"
            fill="#3A85F8"
          />
          <path
            d="M30 23C30 23.5523 29.5523 24 29 24C28.4477 24 28 23.5523 28 23C28 22.4477 28.4477 22 29 22C29.5523 22 30 22.4477 30 23Z"
            fill="#3A85F8"
          />
          <path
            d="M29 20C29 20.5523 28.5523 21 28 21C27.4477 21 27 20.5523 27 20C27 19.4477 27.4477 19 28 19C28.5523 19 29 19.4477 29 20Z"
            fill="#3A85F8"
          />
          <path
            d="M26 19C26 19.5523 25.5523 20 25 20C24.4477 20 24 19.5523 24 19C24 18.4477 24.4477 18 25 18C25.5523 18 26 18.4477 26 19Z"
            fill="#3A85F8"
          />
          <path
            d="M23 20C23 20.5523 22.5523 21 22 21C21.4477 21 21 20.5523 21 20C21 19.4477 21.4477 19 22 19C22.5523 19 23 19.4477 23 20Z"
            fill="#3A85F8"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_ii_3415_1554"
          x="-5"
          y="-5"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.611192 0 0 0 0 0.611192 0 0 0 0 0.672497 0 0 0 0.55 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3415_1554"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-5" dy="-5" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_3415_1554"
            result="effect2_innerShadow_3415_1554"
          />
        </filter>
        <clipPath id="clip0_3415_1554">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(16 16)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
