import { FC } from 'react'
import { IconProps } from './types'

export const PayoutIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29289 5.29289C6.90237 5.68342 6.90237 6.31658 7.29289 6.70711C7.68342 7.09763 8.31658 7.09763 8.70711 6.70711L11 4.41421L11 14H13L13 4.41421L15.2929 6.70711C15.6834 7.09763 16.3166 7.09763 16.7071 6.70711C17.0976 6.31658 17.0976 5.68342 16.7071 5.2929L12.7071 1.29289C12.5196 1.10536 12.2652 1 12 1C11.7348 1 11.4804 1.10536 11.2929 1.29289L7.29289 5.29289ZM4 12C4 11.4477 4.44772 11 5 11H9V9H5C3.34315 9 2 10.3431 2 12V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V12C22 10.3431 20.6569 9 19 9H15V11H19C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H5C4.44772 20 4 19.5523 4 19V12ZM7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44771 18 7 18H17C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16H7Z"
        fill="currentColor"
      />
    </svg>
  )
}
