import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import React, { memo, ReactElement } from 'react'
import { DotProps } from '@/components/risk-profile-chart/types'
import { data, shiftX, shiftY } from '@/components/risk-profile-chart/consts'
import { useTranslation } from 'react-i18next'
import {
  RiskHighlightedIconValueMap,
  RiskValueIconMap,
} from '@/components/risk-profile-chart/risk-value-icon-map'
import { useRouter } from 'next/router'
import { LocaleEnum } from '@/common/enums/Locale'

export const RiskProfileChart = memo(
  ({ selectedRisk }: { selectedRisk: number }) => {
    const { t } = useTranslation()
    const { locale = LocaleEnum.EN } = useRouter()
    const map = RiskValueIconMap[locale]
    const highlightedMap = RiskHighlightedIconValueMap[locale]
    const CustomizedDot = ({
      cx,
      cy,
      index,
    }: DotProps): ReactElement<SVGElement> => {
      if (index === 0 || index > 5) return <svg />

      const x = cx - shiftX
      const y = cy - shiftY

      if (index === selectedRisk) {
        return highlightedMap[index]({ x, y })
      }

      return map[index]({ x, y })
    }

    return (
      <ResponsiveContainer width={'100%'} height={400}>
        <ComposedChart data={data} className="ml-[-20px]">
          <defs>
            <linearGradient
              key="gradient_area"
              id="gradient_area"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5.54%" stopColor="#3A85F8" stopOpacity={0.2} />
              <stop offset="94.46%" stopColor="#3A85F8" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="x"
            tick={false}
            label={t('USM-UserAccount:RiskProfilePanel.ExpectedRisk')}
          />
          <YAxis
            dataKey="y"
            tick={false}
            label={{
              value: t('USM-UserAccount:RiskProfilePanel.PotentialReturn'),
              angle: -90,
            }}
          />
          <Area
            dataKey="y"
            strokeWidth={0}
            fillOpacity={1}
            fill={`url(#gradient_area)`}
            legendType="none"
            tooltipType="none"
          />
          <Line
            type="monotone"
            dataKey="y"
            stroke="#3A85F8"
            strokeWidth={3}
            dot={CustomizedDot}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )
  },
)
