import { FC } from 'react'
import { IconProps } from './types'

export const FileIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C5.20435 1 4.44129 1.31607 3.87868 1.87868C3.31607 2.44129 3 3.20435 3 4V20C3 20.7957 3.31607 21.5587 3.87868 22.1213C4.44129 22.6839 5.20435 23 6 23H18C18.7957 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7957 21 20V9C21 8.73478 20.8946 8.48043 20.7071 8.29289L13.7071 1.29289C13.5196 1.10536 13.2652 1 13 1H6ZM5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3H12V8.5C12 9.32843 12.6716 10 13.5 10H19V20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H6C5.73478 21 5.48043 20.8946 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20V4C5 3.73478 5.10536 3.48043 5.29289 3.29289ZM17.5858 8L14 4.41421V8H17.5858Z"
        fill="currentColor"
      />
    </svg>
  )
}
