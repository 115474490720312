import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import clsx from 'clsx'

import classes from './menu-list.module.scss'

export type MenuVariant = 'base' | 'select'

interface MenuProps extends HTMLProps<HTMLDivElement> {
  variant?: MenuVariant
}

export const Menu = forwardRef(
  (
    { variant = 'base', ...props }: MenuProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        className={clsx(classes.menu, props.className, classes[variant])}
      >
        {props.children}
      </div>
    )
  },
)
