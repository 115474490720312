import {
  Card,
  CheckIcon,
  ErrorIcon,
  InfoIcon,
  WarningIcon,
} from '@eframe-ui/react'
import { CrossIcon, IconButton, Translate, Typo } from '@eplix/ui'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TranslateWithLink } from '@/components'
import { SnackbarProps, SnackbarType } from './types'
import { useRouter } from 'next/router'
import { asyncCall } from '@/common/helpers/async-wrapper'

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const Snackbar: FC<SnackbarProps> = ({
  className,
  textStyle,
  isPersistent = false,
  color = 'text-primary',
  link,
  text,
  duration = 0,
  iconSize = 24,
  snackbarType,
  hideCloseIcon = false,
  cardStyle,
  title,
  children,
  callbackHide,
  iconClassName,
  textVariant,
  textWrapperStyle,
  linkClassName,
  isExternalLink = false,
}) => {
  const [isTimeOut, setIsTimeout] = useState<boolean>(false)

  const countDown = useCallback(async () => {
    let isMounted = true
    await delay(duration)
    if (isMounted) {
      setIsTimeout(true)
    }

    return () => {
      isMounted = false
    }
  }, [duration])

  useEffect(() => {
    if (isPersistent) return
    asyncCall(countDown)

    return () => {
      setIsTimeout(false)
    }
  }, [countDown, duration, isPersistent])

  const iconMap = useMemo(() => {
    return {
      [SnackbarType.ERROR]: <ErrorIcon size={iconSize} />,
      [SnackbarType.SUCCESS]: <CheckIcon size={iconSize} />,
      [SnackbarType.INFO]: <InfoIcon size={iconSize} color={color} />,
      [SnackbarType.WARNING]: <WarningIcon size={iconSize} />,
    }
  }, [iconSize, color])

  const colorMap = useMemo(() => {
    return {
      [SnackbarType.ERROR]: '#FF7B72',
      [SnackbarType.SUCCESS]: '#7DDDAF',
      [SnackbarType.INFO]: '#89C9F8',
      [SnackbarType.WARNING]: '#FFB23E',
    }
  }, [])

  const router = useRouter()

  const openLink = useCallback(
    async (_link: string) => {
      await router.push(_link)
    },
    [router],
  )

  const components = useMemo(() => {
    if (isExternalLink) {
      return [
        <a
          key="link-outside-app"
          href={link ?? ''}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx('cursor-pointer text-blue underline', linkClassName)}
        />,
      ]
    } else {
      return [
        <Typo
          key="link-inside-app"
          as="a"
          onClick={async () => {
            if (link) {
              await openLink(link)
            }
          }}
          className={clsx('cursor-pointer text-white underline', linkClassName)}
        />,
      ]
    }
  }, [isExternalLink, link, linkClassName, openLink])

  return (
    <div className={clsx(className, isTimeOut && !isPersistent && 'hidden')}>
      <Card
        bgColor={colorMap[snackbarType ?? SnackbarType.INFO]}
        className={clsx(cardStyle, 'gap-4 w-full justify-between items-center')}
        boRadius={'8px'}
      >
        <div className="flex flex-col my-auto">
          {iconMap[snackbarType ?? SnackbarType.INFO]}
        </div>
        {!children && text && (
          <div className={clsx(textWrapperStyle, 'flex flex-col')}>
            {title && (
              <Translate
                className="mb-3"
                label={title}
                variant="regular-200"
                color="black"
              />
            )}
            <Typo variant="regular-300">
              <TranslateWithLink
                className={textStyle}
                color={color}
                label={text}
                variant={textVariant}
                components={components}
              />
            </Typo>
          </div>
        )}
        {children && children}
        {!hideCloseIcon && (
          <div>
            <IconButton
              icon={<CrossIcon iconSize={24} color={color ?? 'black'} />}
              title={''}
              onClick={() => {
                callbackHide && callbackHide()
              }}
              variant="text"
              className={clsx(iconClassName, 'bg-transparent')}
            />
          </div>
        )}
      </Card>
    </div>
  )
}
