export enum GlobalValueKey {
  CURRENT_USER = 'currentUser',
  USER_INFORMATION = 'userInformation',
  HIDE_CLOSE_BUTTON = 'hideCloseButton',
  SELECTED_PORTFOLIO_ID = 'selectedPortfolioId',
  SELECTED_SICKNESS_ACCIDENT_ID = 'selectedSicknessAccidentId',
  SHOULD_GO_TO_LAST_PORTFOLIO = 'shouldGoToLastPortfolio',
  SHOULD_SHOW_TRANSACTIONS = 'useShouldShowTransactions',
  SELECTED_PENSION_PLAN_ID = 'selectedPensionPlanId',
  SELECTED_INSURANCE_PLAN_ID = 'insurancePlanId',
  EMAIL_CHANGE_SUCCESS_MESSAGE = 'emailChangeSuccessMessage',
  NO_FETCH_TRIGGER = 'noFetchTrigger', // System value. Used  by default in case when trigger fetching should not be used.
  FETCH_TRIGGER_SICKNESS_CASES = 'fetchTriggerSicknessCases',
  FETCH_TRIGGER_PORTFOLIO_SETTINGS = 'fetchTriggerPortfolioSettings',
  FETCH_TRIGGER_METADATA = 'fetchTriggerMetadata',
  FETCH_TRIGGER_PORTFOLIO_UPDATED = 'fetchTriggerPortfolioUpdated',
  FETCH_TRIGGER_USER_INFORMATION = 'fetchUserInformation',
  FETCH_TRIGGER_REPORTS = 'fetchTriggerReports',
  FETCH_TRIGGER_SETTINGS_PORTFOLIO = 'fetchTriggerSettingsPortfolio',
  FETCH_TRIGGER_CORRESPONDENCE_EMAIL = 'fetchTriggerCorrespondenceEmail',
  FETCH_TRIGGER_NEO_CORRESPONDENCE_EMAIL = 'fetchTriggerNeoCorrespondenceEmail',
  FETCH_TRIGGER_COMPARTMENTS = 'fetchTriggerCompartments',
  FETCH_TRIGGER_DATA_COMBINATION = 'fetchTriggerDataCombination',
  IS_TEST_USER = 'isTestUser',
  MAINTENANCE_MODE_BANNER_HEIGHT = 'maintenanceMode',
  RESPONSE_ERROR_MESSAGE = 'responseErrorMessage',
  RESPONSE_FORM_FIELD_ERROR_MESSAGES = 'responseFormFieldErrorMessages',
  FETCH_TRIGGER_plannedMaintenances = 'fetchTriggerPlannedMaintenances',
  PERMISSIONS = 'permissions',
  IS_ABSENCE_CASE_CREATED = 'isAbsenceCaseCreated',
  FETCH_TRIGGER_GET_USER_INFORMATION = 'fetchTriggerGetUserInformation',
  ZENDESK_WIDGET = 'zendeskWidget',
  TOGGLE_ZENDESK_WIDGET = 'toggleZendeskWidget',
  FETCH_TRIGGER_GET_PAYOUTS = 'fetchTriggerGetPayouts',
  FETCH_TRIGGER_GET_PAYOUT_DOCUMENTS = 'fetchTriggerGetPayoutDocuments',
  PAYOUT_PORTFOLIO_DATA = 'payoutPortfolioData',
  FETCH_TRIGGER_GET_PENDING_ORDERS = 'fetchTriggerGetPendingOrders',
  PENSION_STATEMENT_TOTAL_VALUE_BY_FILTER = 'PensionStatementTotalValueByFilter',
  PENSION_STATEMENT_WHOLE = 'PensionStatements',
  HAS_EASY_TEMP_DATA = 'HasEasyTempData',
  COMPANIES_FROM_ABSENCE = 'GetCompaniesFromAbsence',
  GET_ALL_ABSENCES = 'GetAllAbsences',
  GET_ABSENCES_DETAIL = 'GetAbsencesDetail',
  INSURED_DOCUMENTS = 'insuredDocuments',
  COMPANY_SELECTED = 'companySelected',
  POWER_OF_ATTORNEY = 'powerOfAttorney',
  PREMIUM_INSURANCE = 'premiumInsurance',
  FREE_INSURANCE = 'freeInsurance',
  INVESTED_CAPITAL = 'investedCapital',
}
