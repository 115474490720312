import styled from 'styled-components'
import React from 'react'

const ThumbTooltipArrow = styled.div<{ borderColor: string }>`
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: 5;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-left: 0;
  border-top: 0;
  margin-top: -1px;
  margin-left: -6px;
  background: #eef2f5;
  transform: rotate(45deg);
`

const ThumbTooltipArrowWrapper = ({
  arrowRef,
  arrowStyle,
  borderColor,
}: {
  arrowRef: React.Ref<HTMLDivElement>
  arrowStyle: React.CSSProperties
  borderColor: string
}) => {
  return (
    <div ref={arrowRef} style={arrowStyle}>
      <ThumbTooltipArrow borderColor={borderColor} />
    </div>
  )
}

const TooltipBubble = (
  // @ts-ignore
  { text, innerRef, arrowStyle, arrowRef, borderColor, ...remainingProps },
) => {
  return (
    <div ref={innerRef} {...remainingProps}>
      {text}
      <ThumbTooltipArrowWrapper
        // @ts-ignore
        arrowRef={arrowRef}
        arrowStyle={arrowStyle}
        borderColor={borderColor}
      />
    </div>
  )
}

const ThumbTooltip = styled(TooltipBubble)`
  background: #eef2f5;
  color: black;
  font-weight: lighter;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  border: 2px solid ${({ borderColor }) => borderColor};

  &[data-popper-placement^='top'] > ${ThumbTooltipArrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${ThumbTooltipArrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${ThumbTooltipArrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${ThumbTooltipArrow} {
    left: -4px;
  }
`

export type TooltipTemplateFunction = (value: number) => string

export default ThumbTooltip
