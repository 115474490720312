import React, { memo } from 'react'
import { Pie, PieChart as PieChart } from 'recharts'
import { AllocationChartProps } from '../types'

export const AllocationChart = memo(
  ({
    size,
    data,
    outerRadius,
    innerRadius,
    value,
    valueStyle = '',
    isAnimationActive,
    dataKey = 'percent',
    variant = 'regular',
    shouldPulse,
  }: AllocationChartProps) => {
    const pieData =
      variant === 'regular'
        ? data
        : data.map((line, index) => {
            return index === 0 ? { ...line, fill: '#6C7177' } : line
          })
    const pulseClass = shouldPulse ? 'animate-pulse' : ''

    return (
      <div className={`flex justify-center mt-0 items-center ${pulseClass}`}>
        <div className="flex flex-col items-center">
          <PieChart width={size} height={size} id={value}>
            <text
              x={size / 2}
              y={size / 2}
              textAnchor="middle"
              dominantBaseline="middle"
              className={valueStyle}
            >
              {value}
            </text>
            <Pie
              isAnimationActive={isAnimationActive}
              data={pieData}
              dataKey={dataKey}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              fill="#red"
              cx="50%"
              cy="50%"
            />
          </PieChart>
        </div>
      </div>
    )
  },
)
