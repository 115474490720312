import { FC } from 'react'
import { IconProps } from './types'

export const DeleteIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3L14 3C14.5523 3 15 3.44772 15 4V5L17 5V4C17 2.34315 15.6569 1 14 1L10 1C8.34314 1 7 2.34315 7 4V5L9 5L9 4C9 3.44772 9.44772 3 10 3ZM21 7H19V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V7H3V5H21V7ZM7 7H17V18C17 18.5523 16.5523 19 16 19H8C7.44772 19 7 18.5523 7 18V7ZM9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16V10C11 9.44771 10.5523 9 10 9C9.44772 9 9 9.44771 9 10V16ZM14 17C13.4477 17 13 16.5523 13 16V10C13 9.44771 13.4477 9 14 9C14.5523 9 15 9.44771 15 10V16C15 16.5523 14.5523 17 14 17Z"
        fill="currentColor"
      />
    </svg>
  )
}
