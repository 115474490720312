import { Card, WarningIcon } from '@eframe-ui/react'
import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { MessageProps } from '@/components/messages/warning-message/types'
import { TranslateSimple } from '@eplix/ui'
import { asyncCall } from '@/common/helpers/async-wrapper'

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

//TODO - IRA: Check if this can be replaced with other warning-message component, or rename!
export const WarningMessage = ({
  className,
  isPersistent = false,
  text,
  duration = 0,
  textStyle,
}: MessageProps) => {
  const [isTimeOut, setIsTimeout] = useState<boolean>(false)

  const countDown = useCallback(async () => {
    let isMounted = true
    await delay(duration)
    if (isMounted) {
      setIsTimeout(true)
    }

    return () => {
      isMounted = false
    }
  }, [duration])

  useEffect(() => {
    if (isPersistent) return
    asyncCall(countDown)

    return () => {
      setIsTimeout(false)
    }
  }, [countDown, duration, isPersistent])

  return (
    <div className={clsx(className, isTimeOut && !isPersistent && 'hidden')}>
      <Card
        className="gap-4 w-full justify-center items-center"
        bgColor="#FFB23E"
      >
        <div>
          <WarningIcon size={24} />
        </div>
        <div className={textStyle}>
          <TranslateSimple label={text}></TranslateSimple>
        </div>
      </Card>
    </div>
  )
}
