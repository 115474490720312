export const getResendSMSCodeErrorMessage = (
  resendCodeBlocked: boolean,
  apiErrorMessage: string,
) => {
  return (apiErrorMessage === 'EXPIRED' ||
    apiErrorMessage === 'TEMPORARILY_BLOCKED') &&
    resendCodeBlocked
    ? 'EXPIRED_WITH_ATTEMPT_LIMIT_REACHED'
    : apiErrorMessage
}
