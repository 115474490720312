import clsx from 'clsx'
import { ForwardedRef, forwardRef, HTMLProps } from 'react'

import classes from './alert.module.scss'

type AlertVariant = 'error' | 'success' | 'warning'

export interface AlertProps extends HTMLProps<HTMLDivElement> {
  variant: AlertVariant
}

export const Alert = forwardRef(
  (
    { variant, className, ...props }: AlertProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div
        role="alert"
        className={clsx(className, classes.alert, classes[variant])}
        ref={ref}
        {...props}
      />
    )
  },
)
