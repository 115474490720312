import { Asset, AssetAllocation, Currency, InvestmentAccountAsset } from '@/api'
import { DataConfig, DataValues } from '@/components'

export type AssetPercentages = {
  [assetId: number]: number
}

const emptyAsset: Asset = {
  name: '',
} as Asset

const getAssetById = (
  assetId: number,
  assets: Array<Asset | InvestmentAccountAsset>,
) => {
  const foundAssets = assets.filter((asset) => asset.id === assetId)
  return foundAssets.length > 0 ? foundAssets[0] : emptyAsset
}

export const getAssetAllocations = (
  assetPercentages: AssetPercentages,
  assets: Array<Asset | InvestmentAccountAsset>,
) => {
  const assetAllocations: Array<AssetAllocation> = []
  Object.entries(assetPercentages).map((entry) => {
    const id = +entry[0]
    const percentage = +entry[1]

    const asset = getAssetById(id, assets)

    /**
     * If effectiveAllocation percentage is 0,
     * chosenAllocation value will be displayed
     */
    assetAllocations.push({
      id: id,
      percentage: percentage,
      isRecommended: (asset as Asset).isRecommended ?? false,
    } as AssetAllocation)
  })
  return assetAllocations
}

export const getDataForAssetChart = (
  assets: Array<Asset | InvestmentAccountAsset>,
  assetPercentages: AssetPercentages,
) => {
  if (!assets || !assetPercentages) return
  const assetAllocation: Array<AssetAllocation> = getAssetAllocations(
    assetPercentages,
    assets,
  )

  return assetAllocation.map((singleAssetAllocation) => {
    return {
      name: getAssetById(singleAssetAllocation.id, assets).name,
      percent: singleAssetAllocation.percentage,
      fill: '#2E6BC8',
    } as DataConfig
  }) as DataValues
}

export const getDataForCurrencyChart = (
  currencies: Array<Currency> | undefined,
) => {
  if (!currencies || currencies.length === 0) return []
  return currencies.map((currency) => {
    return {
      name: currency.code,
      percent: currency.percentage,
      fill: '#2E6BC8',
    } as DataConfig
  }) as DataValues
}
