import { Document1, PensionCertificate } from '@/api'
import { DocumentNeoTypeEnum } from '@/common/enums/DocumentNeoTypeEnum'
import { getJSON, postJSON } from '@/framework/common'
import {
  PENSION_CERTIFICATE,
  PENSION_CERTIFICATE_DOCUMENTS,
  PKFLEX_CERTIFICATE_DOCUMENTS,
  PKPRO_CERTIFICATE_DOCUMENTS,
} from '../api-routes/pension-certificate'

export interface NEODocumentsRequest {
  actual: boolean
  documentType: DocumentNeoTypeEnum
}

export const getNEODocuments = async (req: NEODocumentsRequest) => {
  return await postJSON<Document1[], NEODocumentsRequest>(
    PENSION_CERTIFICATE_DOCUMENTS,
    req,
  )
}

export const getPkProNEODocuments = async (req: NEODocumentsRequest) => {
  return await postJSON<Document1[], NEODocumentsRequest>(
    PKPRO_CERTIFICATE_DOCUMENTS,
    req,
  )
}

export const getPkFlexNEODocuments = async (req: NEODocumentsRequest) => {
  return await postJSON<Document1[], NEODocumentsRequest>(
    PKFLEX_CERTIFICATE_DOCUMENTS,
    req,
  )
}

export const getPensionCertificateInformation = async () => {
  return await getJSON<PensionCertificate[]>(PENSION_CERTIFICATE)
}
