import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'
import clsx from 'clsx'
import { DisplayAccordionElement, DisplayAccordionProps } from '../types'
import classes from './display-accordion.module.scss'
import { AccordionHeader } from '@/components'

export const DisplayAccordion = forwardRef(
  <TElement extends DisplayAccordionElement = 'div'>(
    {
      as,
      variant = 'regular',
      allocationChartProps,
      accordionHeaderValuesProps,
      accordionTextProps = { title: '', subtitle: '' },
      className,
      ...props
    }: DisplayAccordionProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'div',
      {
        ...props,
        ref,
        className: clsx(
          classes.container,
          variant === 'regular' && classes.regularContainer,
          variant === 'outlined' && classes.outlinedContainer,
          className,
        ),
      },
      <>
        <AccordionHeader
          allocationChartProps={allocationChartProps}
          accordionHeaderValuesProps={accordionHeaderValuesProps}
          accordionTextProps={accordionTextProps}
          variant={variant}
        />
      </>,
    )
  },
)
