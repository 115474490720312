import {
  ChangeEvent,
  cloneElement,
  DetailedHTMLProps,
  HTMLAttributes,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Placement, TooltipContent } from './tooltip-content'

interface TooltipProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  content: ReactNode
  placement?: Placement
  showOnClick?: boolean
}

export const Tooltip = ({
  placement,
  children,
  content,
  showOnClick,
  ...props
}: TooltipProps) => {
  const [show, setShow] = useState(false)
  const ref = useRef<HTMLElement | null>(null)

  const toggleTooltip = (toggle: boolean, e?: ChangeEvent<HTMLElement>) => {
    if (e) {
      ref.current = e.currentTarget
    }
    setShow(toggle)
  }

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        toggleTooltip(false)
      }
    }
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <>
      <TooltipContent
        targetRef={ref}
        content={content}
        placement={placement}
        show={show}
        id={props.id}
        showOnClick={showOnClick}
      />
      {isValidElement(children) &&
        cloneElement(children, {
          ...children.props,
          onMouseOver: (e: ChangeEvent<HTMLElement>) =>
            !showOnClick && toggleTooltip(true, e),
          onMouseLeave: () => !showOnClick && toggleTooltip(false),
          onFocus: (e: ChangeEvent<HTMLElement>) =>
            !showOnClick && toggleTooltip(true, e),
          onBlur: () => toggleTooltip(false),
          onClick: (e: ChangeEvent<HTMLElement>) => {
            children.props.onClick && children.props.onClick(e)
            showOnClick && toggleTooltip(!show, e)
          },
        })}
    </>
  )
}
