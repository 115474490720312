import { Resource } from 'i18next'
import { createI18nHTTPClient } from '../lib/i18n-http-client'

export interface I18nHTTPClientOptions {
  locale: string
  initialStore?: Resource
}

export function useI18n({ locale, initialStore }: I18nHTTPClientOptions) {
  const instance = createI18nHTTPClient(locale)
  if (!initialStore) {
    return instance
  }
  for (const [lng, store] of Object.entries(initialStore)) {
    for (const [ns, bundle] of Object.entries(store)) {
      instance.addResourceBundle(lng, ns, bundle)
    }
  }
  return instance
}
