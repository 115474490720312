export const SZ = {
  6402: 'Merlischachen',
  6403: 'Küssnacht am Rigi',
  6405: 'Immensee',
  6410: 'Goldau',
  6414: 'Oberarth',
  6415: 'Arth',
  6416: 'Steinerberg',
  6417: 'Sattel',
  6418: 'Rothenthurm',
  6422: 'Steinen',
  6423: 'Seewen SZ',
  6424: 'Lauerz',
  6430: 'Schwyz',
  6431: 'Schwyz',
  6432: 'Rickenbach SZ',
  6433: 'Stoos SZ',
  6434: 'Illgau',
  6436: 'Ried (Muotathal)',
  6438: 'Ibach',
  6440: 'Brunnen',
  6442: 'Gersau',
  6443: 'Morschach',
  8806: 'Bäch SZ',
  8807: 'Freienbach',
  8808: 'Pfäffikon SZ',
  8832: 'Wollerau',
  8834: 'Schindellegi',
  8835: 'Feusisberg',
  8836: 'Bennau',
  8840: 'Einsiedeln',
  8841: 'Gross',
  8842: 'Unteriberg',
  8843: 'Oberiberg',
  8844: 'Euthal',
  8845: 'Studen SZ',
  8846: 'Willerzell',
  8847: 'Egg SZ',
  8849: 'Alpthal',
  8852: 'Altendorf',
  8853: 'Lachen SZ',
  8854: 'Siebnen',
  8855: 'Wangen SZ',
  8856: 'Tuggen',
  8857: 'Vorderthal',
  8858: 'Innerthal',
  8862: 'Schübelbach',
  8863: 'Buttikon SZ',
  8864: 'Reichenburg',
}
