import { FC } from 'react'
import { IconProps } from './types'

export const StarEmptyIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 36 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0711 1.3295C17.4059 0.490018 18.5941 0.490018 18.9289 1.3295L22.9152 11.3267C23.058 11.6849 23.3941 11.929 23.7789 11.9542L34.5186 12.6561C35.4204 12.715 35.7876 13.8452 35.0927 14.4229L26.8166 21.3035C26.5201 21.55 26.3918 21.945 26.4867 22.3188L29.1379 32.7498C29.3606 33.6257 28.3992 34.3241 27.635 33.8417L18.5338 28.0969C18.2077 27.8911 17.7923 27.8911 17.4662 28.0969L8.36502 33.8417C7.60077 34.3241 6.63944 33.6257 6.86207 32.7498L9.51326 22.3188C9.60825 21.945 9.47989 21.55 9.18337 21.3035L0.90732 14.4229C0.212362 13.8452 0.57956 12.715 1.4814 12.6561L12.2211 11.9542C12.6059 11.929 12.942 11.6849 13.0848 11.3267L17.0711 1.3295Z"
        fill="url(#paint0_linear_11065_35254)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11065_35254"
          x1="38"
          y1="19"
          x2="-2"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8CE3A" />
          <stop offset="1" stopColor="#FFA217" />
        </linearGradient>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M18 1.69989L21.9863 11.6971C22.272 12.4134 22.9441 12.9018 23.7136 12.952L34.4534 13.654L26.1773 20.5345C25.5843 21.0275 25.3276 21.8176 25.5176 22.5651L28.1688 32.9961L19.0675 27.2513C18.4154 26.8396 17.5846 26.8396 16.9325 27.2513L7.83125 32.9961L10.4824 22.5651C10.6724 21.8176 10.4157 21.0275 9.82267 20.5345L9.18337 21.3035L9.82267 20.5345L1.54662 13.654L12.2864 12.952C13.0559 12.9018 13.728 12.4134 14.0137 11.6971L18 1.69989Z"
        stroke="#1B1B1B"
        strokeWidth="2"
      />
    </svg>
  )
}
