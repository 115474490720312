import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import { useWindowSize } from '../utils/use-window-size'
import classes from './tooltip.module.scss'
import { getPlacementClass } from './helpers/get-placement-class'
import { handleScroll } from './helpers/handle-scroll'
import { detectEdges } from './helpers/detect-edges'
import { getPlacementState } from './helpers/get-placement-state'
import { calcDomRectPosition } from './helpers/calc-dom-rect-position'
import { getSpikeTransform } from './helpers/get-spike-transform'
import { getTransform } from './helpers/get-transform'
import { ThemeProvider, useTheme } from '../theme-provider'

export type DetectEdge = {
  left: boolean
  right: boolean
  top: boolean
  bottom: boolean
}

export type Placement = 'top' | 'bottom' | 'left' | 'right'

interface TooltipProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  content: ReactNode
  placement?: Placement
  show: boolean
  targetRef: RefObject<HTMLElement | null>
  showOnClick?: boolean
}

export const TooltipContent = ({
  content,
  className,
  placement = 'top',
  targetRef,
  show,
  id,
  showOnClick,
}: TooltipProps) => {
  const [theme] = useTheme()
  const [toolTipRef, setToolTipRef] = useState<HTMLDivElement | null>(null)
  const [spikeRef, setSpikeRef] = useState<HTMLDivElement | null>(null)
  const container = useRef<HTMLElement | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const detectEdge = useRef<DetectEdge>({
    top: false,
    bottom: false,
    left: false,
    right: false,
  })
  const [currentPlacement, updateCurrentPlacement] = useState<Placement | null>(
    null,
  )
  const [position, setPosition] = useState<{
    transform: string
  } | null>(null)
  const [spikePosition, setSpikePosition] = useState<{
    transform: string
  } | null>(null)

  const [width, height] = useWindowSize()

  useEffect(() => {
    container.current = window !== undefined ? document.body : null
  }, [])

  useEffect(() => {
    if (!toolTipRef || !targetRef.current) {
      return
    }

    const targetRect = targetRef.current.getBoundingClientRect()
    const toolTipRect = toolTipRef.getBoundingClientRect()

    detectEdge.current = detectEdges(targetRect, toolTipRect, width, height)

    const calculatedPlacement = getPlacementState(placement, detectEdge.current)

    updateCurrentPlacement(calculatedPlacement)

    const calculatedRect = calcDomRectPosition(
      targetRect,
      toolTipRect,
      calculatedPlacement,
      width,
      height,
    )

    setPosition({
      transform: getTransform(calculatedRect),
    })

    const spikeRect = spikeRef?.getBoundingClientRect()
    const firstToolTipChild = toolTipRef.firstChild as HTMLElement
    const contentStyles = window.getComputedStyle(firstToolTipChild)

    const waitForPosition = setTimeout(
      () => {
        setSpikePosition(
          getSpikeTransform(
            targetRef.current?.getBoundingClientRect(),
            calculatedRect,
            spikeRect,
            calculatedPlacement,
            contentStyles,
          ),
        )
        show && setIsVisible(true)
      },
      showOnClick ? 0 : 50,
    )

    const eventListener = () => {
      if (!toolTipRef || !targetRef.current) {
        return
      }
      handleScroll(
        targetRef.current.getBoundingClientRect(),
        toolTipRef.getBoundingClientRect(),
        detectEdge,
        placement,
        updateCurrentPlacement,
        setPosition,
        setSpikePosition,
        width,
        height,
        contentStyles,
        spikeRect,
      )
    }

    isVisible && window.addEventListener('scroll', eventListener, true)

    return () => {
      window.removeEventListener('scroll', eventListener, true)
      clearTimeout(waitForPosition)
    }
  }, [
    isVisible,
    toolTipRef,
    height,
    width,
    placement,
    targetRef,
    spikeRef,
    showOnClick,
    show,
  ])

  return container && (show || isVisible)
    ? createPortal(
        <ThemeProvider theme={theme}>
          <div
            className={clsx(classes.tooltip, className)}
            style={{
              opacity: isVisible ? (show ? 1 : 0) : 0,
              ...position,
            }}
            onTransitionEnd={() => {
              if (show && isVisible) {
                return
              }
              setIsVisible(false)
              updateCurrentPlacement(null)
              setPosition(null)
            }}
            ref={setToolTipRef}
          >
            <div
              className={clsx(classes.tooltipContent)}
              role="tooltip"
              id={id}
            >
              {content}
            </div>
            <div
              className={clsx(
                classes.tooltipSpike,
                getPlacementClass(currentPlacement),
              )}
              ref={setSpikeRef}
              style={{ ...spikePosition }}
            />
          </div>
        </ThemeProvider>,
        container.current as Element,
      )
    : null
}
