export const BE = {
  1657: 'Abländschen',
  1738: 'Sangernboden',
  1797: 'Münchenwiler',
  2333: 'La Ferrière',
  2500: 'Biel/Bienne',
  2501: 'Biel/Bienne',
  2502: 'Biel/Bienne',
  2503: 'Biel/Bienne',
  2504: 'Biel/Bienne',
  2505: 'Biel/Bienne',
  2510: 'Biel/Bienne DistBa',
  2512: 'Tüscherz-Alfermée',
  2513: 'Twann',
  2514: 'Ligerz',
  2515: 'Prêles',
  2516: 'Lamboing',
  2517: 'Diesse',
  2518: 'Nods',
  2520: 'La Neuveville',
  2532: 'Magglingen/Macolin',
  2533: 'Evilard',
  2534: 'Orvin',
  2535: 'Frinvillier',
  2536: 'Plagne',
  2537: 'Vauffelin',
  2538: 'Romont BE',
  2542: 'Pieterlen',
  2543: 'Lengnau BE',
  2552: 'Orpund',
  2553: 'Safnern',
  2554: 'Meinisberg',
  2555: 'Brügg BE',
  2556: 'Scheuren',
  2557: 'Studen BE',
  2558: 'Aegerten',
  2560: 'Nidau',
  2562: 'Port',
  2563: 'Ipsach',
  2564: 'Bellmund',
  2565: 'Jens',
  2572: 'Sutz',
  2575: 'Täuffelen',
  2576: 'Lüscherz',
  2577: 'Siselen BE',
  2603: 'Péry',
  2604: 'La Heutte',
  2605: 'Sonceboz-Sombeval',
  2606: 'Corgémont',
  2607: 'Cortébert',
  2608: 'Courtelary',
  2610: 'St-Imier',
  2612: 'Cormoret',
  2613: 'Villeret',
  2615: 'Sonvilier',
  2616: 'Renan BE',
  2710: 'Tavannes',
  2712: 'Le Fuet',
  2713: 'Bellelay',
  2715: 'Monible',
  2716: 'Sornetan',
  2717: 'Fornet-Dessous',
  2720: 'Tramelan',
  2722: 'Les Reussilles',
  2723: 'Mont-Tramelan',
  2732: 'Reconvilier',
  2733: 'Pontenet',
  2735: 'Malleray-Bévilard',
  2736: 'Sorvilier',
  2738: 'Court',
  2740: 'Moutier',
  2742: 'Perrefitte',
  2743: 'Eschert',
  2744: 'Belprahon',
  2745: 'Grandval',
  2746: 'Crémines',
  2747: 'Seehof',
  2748: 'Souboz',
  2762: 'Roches BE',
  3000: 'Bern',
  3001: 'Bern',
  3002: 'Bern Postfinance',
  3003: 'Bern',
  3004: 'Bern',
  3005: 'Bern',
  3006: 'Bern',
  3007: 'Bern',
  3008: 'Bern',
  3010: 'Bern',
  3011: 'Bern',
  3012: 'Bern',
  3013: 'Bern',
  3014: 'Bern',
  3015: 'Bern',
  3017: 'Bern Zustellung',
  3018: 'Bern',
  3019: 'Bern',
  3020: 'Bern',
  3024: 'Bern',
  3027: 'Bern',
  3029: 'Bern Entris',
  3030: 'Bern',
  3032: 'Hinterkappelen',
  3033: 'Wohlen b. Bern',
  3034: 'Murzelen',
  3035: 'Frieswil',
  3036: 'Detligen',
  3037: 'Herrenschwanden',
  3038: 'Kirchlindach',
  3039: 'Bern PF OC',
  3040: 'Bern',
  3041: 'Bern',
  3042: 'Ortschwaben',
  3043: 'Uettligen',
  3044: 'Innerberg',
  3045: 'Meikirch',
  3046: 'Wahlendorf',
  3047: 'Bremgarten b. Bern',
  3048: 'Worblaufen',
  3049: 'Säriswil',
  3050: 'Bern',
  3052: 'Zollikofen',
  3053: 'Münchenbuchsee',
  3054: 'Schüpfen',
  3063: 'Ittigen',
  3065: 'Bolligen',
  3066: 'Stettlen',
  3067: 'Boll',
  3068: 'Utzigen',
  3070: 'Ostermundigen LZB',
  3071: 'Ostermundigen Zust',
  3072: 'Ostermundigen',
  3073: 'Gümligen',
  3074: 'Muri b. Bern',
  3075: 'Rüfenacht BE',
  3076: 'Worb',
  3077: 'Enggistein',
  3078: 'Richigen',
  3082: 'Schlosswil',
  3083: 'Trimstein',
  3084: 'Wabern',
  3085: 'Wabern Weihnachten',
  3086: 'Zimmerwald',
  3087: 'Niedermuhlern',
  3088: 'Oberbütschel',
  3089: 'Hinterfultigen',
  3095: 'Spiegel b. Bern',
  3096: 'Oberbalm',
  3097: 'Liebefeld',
  3098: 'Köniz',
  3099: 'Rüti b. Riggisberg',
  3110: 'Münsingen',
  3111: 'Tägertschi',
  3112: 'Allmendingen b. BE',
  3113: 'Rubigen',
  3114: 'Wichtrach',
  3115: 'Gerzensee',
  3116: 'Kirchdorf BE',
  3122: 'Kehrsatz',
  3123: 'Belp',
  3124: 'Belpberg',
  3125: 'Toffen',
  3126: 'Kaufdorf',
  3127: 'Mühlethurnen',
  3128: 'Kirchenthurnen',
  3132: 'Riggisberg',
  3144: 'Gasel',
  3145: 'Niederscherli',
  3147: 'Mittelhäusern',
  3148: 'Lanzenhäusern',
  3150: 'Schwarzenburg',
  3152: 'Mamishaus',
  3153: 'Rüschegg Gambach',
  3154: 'Rüschegg Heubach',
  3155: 'Helgisried',
  3156: 'Riffenmatt',
  3157: 'Milken',
  3158: 'Guggisberg',
  3159: 'Riedstätt',
  3172: 'Niederwangen BE',
  3173: 'Oberwangen b. Bern',
  3174: 'Thörishaus',
  3176: 'Neuenegg',
  3177: 'Laupen BE',
  3179: 'Kriechenwil',
  3183: 'Albligen',
  3202: 'Frauenkappelen',
  3203: 'Mühleberg',
  3204: 'Rosshäusern',
  3205: 'Gümmenen',
  3206: 'Rizenbach',
  3207: 'Wileroltigen',
  3208: 'Gurbrü',
  3225: 'Müntschemier',
  3226: 'Treiten',
  3232: 'Ins',
  3233: 'Tschugg',
  3234: 'Vinelz',
  3235: 'Erlach',
  3236: 'Gampelen',
  3237: 'Brüttelen',
  3238: 'Gals',
  3250: 'Lyss',
  3251: 'Wengi b. Büren',
  3252: 'Worben',
  3255: 'Rapperswil BE',
  3256: 'Dieterswil',
  3257: 'Grossaffoltern',
  3262: 'Suberg',
  3263: 'Büetigen',
  3264: 'Diessbach b. Büren',
  3266: 'Wiler b. Seedorf',
  3267: 'Seedorf BE',
  3268: 'Lobsigen',
  3270: 'Aarberg',
  3271: 'Radelfingen',
  3272: 'Walperswil',
  3273: 'Kappelen',
  3274: 'Hermrigen',
  3282: 'Bargen BE',
  3283: 'Kallnach',
  3292: 'Busswil b. Büren',
  3293: 'Dotzigen',
  3294: 'Büren an der Aare',
  3295: 'Rüti b. Büren',
  3296: 'Arch',
  3297: 'Leuzigen',
  3298: 'Oberwil b. Büren',
  3302: 'Moosseedorf',
  3303: 'Jegenstorf',
  3305: 'Iffwil',
  3306: 'Etzelkofen',
  3308: 'Grafenried',
  3309: 'Kernenried',
  3312: 'Fraubrunnen',
  3313: 'Büren zum Hof',
  3314: 'Schalunen',
  3315: 'Bätterkinden',
  3317: 'Limpach',
  3321: 'Schönbühl EKZ',
  3322: 'Urtenen-Schönbühl',
  3323: 'Bäriswil BE',
  3324: 'Hindelbank',
  3325: 'Hettiswil',
  3326: 'Krauchthal',
  3360: 'Herzogenbuchsee',
  3362: 'Niederönz',
  3363: 'Oberönz',
  3365: 'Seeberg',
  3366: 'Bettenhausen',
  3367: 'Thörigen',
  3368: 'Bleienbach',
  3372: 'Wanzwil',
  3373: 'Heimenhausen',
  3374: 'Wangenried',
  3375: 'Inkwil',
  3376: 'Graben',
  3377: 'Walliswil b.Wangen',
  3380: 'Wangen an der Aare',
  3400: 'Burgdorf',
  3401: 'Burgdorf',
  3402: 'Burgdorf',
  3412: 'Heimiswil',
  3413: 'Kaltacker',
  3414: 'Oberburg',
  3415: 'Hasle-Rüegsau',
  3416: 'Affoltern BE',
  3417: 'Rüegsau',
  3418: 'Rüegsbach',
  3419: 'Biembach',
  3421: 'Lyssach',
  3422: 'Kirchberg BE',
  3423: 'Ersigen',
  3424: 'Niederösch',
  3425: 'Koppigen',
  3426: 'Aefligen',
  3427: 'Utzenstorf',
  3428: 'Wiler b.Utzenstorf',
  3429: 'Hellsau',
  3432: 'Lützelflüh-Goldb',
  3433: 'Schwanden i. E.',
  3434: 'Obergoldbach',
  3435: 'Ramsei',
  3436: 'Zollbrück',
  3437: 'Rüderswil',
  3438: 'Lauperswil',
  3439: 'Ranflüh',
  3452: 'Grünenmatt',
  3453: 'Heimisbach',
  3454: 'Sumiswald',
  3455: 'Grünen',
  3456: 'Trachselwald',
  3457: 'Wasen im Emmental',
  3462: 'Weier im Emmental',
  3463: 'Häusernmoos',
  3464: 'Schmidigen-Mühlew',
  3465: 'Dürrenroth',
  3472: 'Wynigen',
  3473: 'Alchenstorf',
  3474: 'Rüedisbach',
  3475: 'Riedtwil',
  3476: 'Oschwand',
  3503: 'Gysenstein',
  3504: 'Niederhünigen',
  3506: 'Grosshöchstetten',
  3507: 'Biglen',
  3508: 'Arni BE',
  3510: 'Konolfingen',
  3512: 'Walkringen',
  3513: 'Bigenthal',
  3531: 'Oberthal',
  3532: 'Zäziwil',
  3533: 'Bowil',
  3534: 'Signau',
  3535: 'Schüpbach',
  3536: 'Aeschau',
  3537: 'Eggiwil',
  3538: 'Röthenbach i. E.',
  3543: 'Emmenmatt',
  3550: 'Langnau i. E.',
  3551: 'Oberfrittenbach',
  3552: 'Bärau',
  3553: 'Gohl',
  3555: 'Trubschachen',
  3556: 'Trub',
  3557: 'Fankhaus (Trub)',
  3600: 'Thun',
  3601: 'Thun',
  3602: 'Thun',
  3603: 'Thun',
  3604: 'Thun',
  3605: 'Thun',
  3607: 'Thun',
  3608: 'Thun',
  3609: 'Thun',
  3612: 'Steffisburg',
  3613: 'Steffisburg',
  3614: 'Unterlangenegg',
  3615: 'Heimenschwand',
  3616: 'Schwarzenegg',
  3617: 'Fahrni b. Thun',
  3618: 'Süderen',
  3619: 'Eriz',
  3622: 'Homberg b. Thun',
  3623: 'Teuffenthal BE',
  3624: 'Goldiwil (Thun)',
  3625: 'Heiligenschwendi',
  3626: 'Hünibach',
  3627: 'Heimberg',
  3628: 'Uttigen',
  3629: 'Kiesen',
  3631: 'Höfen b. Thun',
  3632: 'Niederstocken',
  3633: 'Amsoldingen',
  3634: 'Thierachern',
  3635: 'Uebeschi',
  3636: 'Längenbühl',
  3638: 'Blumenstein',
  3645: 'Gwatt (Thun)',
  3646: 'Einigen',
  3647: 'Reutigen',
  3652: 'Hilterfingen',
  3653: "Oberhofen Thun'see",
  3654: 'Gunten',
  3655: 'Sigriswil',
  3656: 'Tschingel',
  3657: 'Schwanden Sigrisw',
  3658: 'Merligen',
  3661: 'Uetendorf',
  3662: 'Seftigen',
  3663: 'Gurzelen',
  3664: 'Burgistein',
  3665: 'Wattenwil',
  3671: 'Brenzikofen',
  3672: 'Oberdiessbach',
  3673: 'Linden',
  3674: 'Bleiken',
  3700: 'Spiez',
  3702: 'Hondrich',
  3703: 'Aeschi b. Spiez',
  3704: 'Krattigen',
  3705: 'Faulensee',
  3706: 'Leissigen',
  3707: 'Därligen',
  3711: 'Emdthal',
  3713: 'Reichenbach i. K.',
  3714: 'Frutigen',
  3715: 'Adelboden',
  3716: 'Kandergrund',
  3717: 'Blausee-Mitholz',
  3718: 'Kandersteg',
  3722: 'Scharnachtal',
  3723: 'Kiental',
  3724: 'Ried (Frutigen)',
  3725: 'Achseten',
  3752: 'Wimmis',
  3753: 'Oey',
  3754: 'Diemtigen',
  3755: 'Horboden',
  3756: 'Zwischenflüh',
  3757: 'Schwenden',
  3758: 'Latterbach',
  3762: 'Erlenbach i. S.',
  3763: 'Därstetten',
  3764: 'Weissenburg',
  3765: 'Oberwil i. S.',
  3766: 'Boltigen',
  3770: 'Zweisimmen',
  3771: 'Blankenburg',
  3772: 'St. Stephan',
  3773: 'Matten(St.Stephan)',
  3775: 'Lenk im Simmental',
  3776: 'Oeschseite',
  3777: 'Saanenmöser',
  3778: 'Schönried',
  3780: 'Gstaad',
  3781: 'Turbach',
  3782: 'Lauenen b. Gstaad',
  3783: 'Grund b. Gstaad',
  3784: 'Feutersoey',
  3785: 'Gsteig b. Gstaad',
  3792: 'Saanen',
  3800: 'Interlaken',
  3803: 'Beatenberg',
  3804: 'Habkern',
  3805: 'Goldswil',
  3806: 'Bönigen',
  3807: 'Iseltwald',
  3812: 'Wilderswil',
  3813: 'Saxeten',
  3814: 'Gsteigwiler',
  3815: 'Zweilütschinen',
  3816: 'Lütschental',
  3818: 'Grindelwald',
  3822: 'Lauterbrunnen',
  3823: 'Wengen',
  3824: 'Stechelberg',
  3825: 'Mürren',
  3826: 'Gimmelwald',
  3852: 'Ringgenberg BE',
  3853: 'Niederried Interl',
  3854: 'Oberried Brienz',
  3855: 'Brienz BE',
  3856: 'Brienzwiler',
  3857: 'Unterbach BE',
  3858: 'Hofstetten BE',
  3860: 'Meiringen',
  3862: 'Innertkirchen',
  3863: 'Gadmen',
  3864: 'Guttannen',
  4536: 'Attiswil',
  4537: 'Wiedlisbach',
  4538: 'Oberbipp',
  4539: 'Rumisberg',
  4704: 'Niederbipp',
  4900: 'Langenthal',
  4901: 'Langenthal',
  4902: 'Langenthal',
  4911: 'Schwarzhäusern',
  4912: 'Aarwangen',
  4913: 'Bannwil',
  4914: 'Roggwil BE',
  4916: 'Untersteckholz',
  4917: 'Melchnau',
  4919: 'Reisiswil',
  4922: 'Bützberg',
  4923: 'Wynau',
  4924: 'Obersteckholz',
  4932: 'Lotzwil',
  4933: 'Rütschelen',
  4934: 'Madiswil',
  4935: 'Leimiswil',
  4936: 'Kleindietwil',
  4937: 'Ursenbach',
  4938: 'Rohrbach',
  4942: 'Walterswil BE',
  4943: 'Oeschenbach',
  4944: 'Auswil',
  4950: 'Huttwil',
  4952: 'Eriswil',
  4953: 'Schwarzenbach BE',
  4954: 'Wyssachen',
  4955: 'Gondiswil',
  6083: 'Hasliberg Hohfluh',
  6084: 'Hasliberg Wasserw',
  6085: 'Hasliberg Goldern',
  6086: 'Hasliberg Reuti',
  6197: 'Schangnau',
}
