import { Dispatch, MutableRefObject } from 'react'
import { detectEdges } from './detect-edges'
import { DetectEdge, Placement } from '../tooltip-content'
import { getPlacementState } from './get-placement-state'
import { calcDomRectPosition } from './calc-dom-rect-position'
import { getTransform } from './get-transform'
import { getSpikeTransform } from './get-spike-transform'

export const handleScroll = (
  targetRect: DOMRect,
  toolTipRect: DOMRect,
  detectEdge: MutableRefObject<DetectEdge>,
  currentPlacement: Placement,
  setPlacement: Dispatch<Placement>,
  setPosition: Dispatch<{ transform: string } | null>,
  setSpikePosition: Dispatch<{ transform: string } | null>,
  screenWidth: number,
  screenHeight: number,
  contentStyles: CSSStyleDeclaration,
  spikeRect: DOMRect | undefined,
) => {
  const detectedEdges = detectEdges(
    targetRect,
    toolTipRect,
    screenWidth,
    screenHeight,
  )
  const calculatedPlacement = getPlacementState(currentPlacement, detectedEdges)

  const needsUpdate = Object.entries(detectEdge.current).reduce(
    (acc, [key, value]) => {
      if (value !== detectedEdges[key as Placement]) {
        acc = true
      }
      return acc
    },
    false,
  )

  if (needsUpdate) {
    detectEdge.current = detectedEdges
  }

  needsUpdate && setPlacement(calculatedPlacement)

  const calculatedRect = calcDomRectPosition(
    targetRect,
    toolTipRect,
    calculatedPlacement,
    screenWidth,
    screenHeight,
  )

  const transform = getTransform(calculatedRect)

  setPosition({ transform })

  setSpikePosition(
    getSpikeTransform(
      targetRect,
      calculatedRect,
      spikeRect,
      calculatedPlacement,
      contentStyles,
    ),
  )
}
