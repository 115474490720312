import { FC } from 'react'
import { IconProps } from './types'

export const ChartLegendLineIcon: FC<IconProps> = ({
  viewBox = '0 0 32 32',
  iconSize = 12,
  color = '#3A85F8',
  stroke = 4,
  ...props
}) => {
  return (
    <svg
      className="recharts-surface"
      width={iconSize}
      height={iconSize}
      viewBox={viewBox}
      version="1.1"
      {...props}
    >
      <line
        strokeWidth={stroke}
        fill="none"
        stroke={color}
        x1="0"
        y1="32"
        x2="32"
        y2="32"
        className="recharts-legend-icon"
      />
    </svg>
  )
}
