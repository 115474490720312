import { FC } from 'react'
import { IconProps } from './types'

export const TextFileIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11C4 10.4477 4.44772 10 5 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H5C4.44772 12 4 11.5523 4 11Z"
        fill="currentColor"
      />
      <path
        d="M4 14C4 13.4477 4.44772 13 5 13H13C13.5523 13 14 13.4477 14 14C14 14.5523 13.5523 15 13 15H5C4.44772 15 4 14.5523 4 14Z"
        fill="currentColor"
      />
      <path
        d="M5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18H9C9.55228 18 10 17.5523 10 17C10 16.4477 9.55228 16 9 16H5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H10C10.2652 0 10.5196 0.105357 10.7071 0.292893L17.7071 7.29289C17.8946 7.48043 18 7.73478 18 8V19C18 19.7957 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7957 22 15 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19V3C0 2.20435 0.31607 1.44129 0.87868 0.87868ZM3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19V9H10C9.44771 9 9 8.55228 9 8V2H3ZM11 3.41421V7H14.5858L11 3.41421Z"
        fill="currentColor"
      />
    </svg>
  )
}
