import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'

import classes from './table.module.scss'

export const TableRow = forwardRef<
  HTMLDivElement,
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>(({ children, ...props }, ref) => {
  return (
    <div
      role="row"
      {...props}
      className={clsx(classes.row, props.className)}
      ref={ref}
    >
      {children}
    </div>
  )
})
