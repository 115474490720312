import {
  createContext,
  FC,
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import clsx from 'clsx'

import classes from './theme-provider.module.scss'

export type Theme = 'light' | 'dark'
type ToggleTheme = () => void
type SetTheme = (v: Theme) => void
type ThemeContextValue = [Theme, ToggleTheme, SetTheme]

const ThemeContext = createContext<ThemeContextValue>([
  'light',
  () => {},
  () => {},
])

export interface ThemeProviderProps extends HTMLAttributes<HTMLDivElement> {
  /** This property can be a value from e.g. a user preference cookie. Default: `'light'`. */
  theme?: Theme
}

/**
 * This is a React Context Provider which:
 * * adds all CSS Variable tokens to the `:root` selector
 * * makes all CSS Variable aliases that are dependent on theming available to its subtree
 */
export const ThemeProvider: FC<ThemeProviderProps> = ({
  theme = 'light',
  children,
  className,
  ...props
}) => {
  const [currentTheme, setTheme] = useState<Theme>(theme)
  const toggleTheme = useCallback(
    () => setTheme((v) => (v === 'light' ? 'dark' : 'light')),
    [setTheme],
  )

  // update the theme if it was changed via theme attribute
  useEffect(() => setTheme(theme), [theme])

  return (
    <ThemeContext.Provider value={[currentTheme, toggleTheme, setTheme]}>
      <div
        {...props}
        className={clsx(classes.base, classes[currentTheme], className)}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  )
}

/**
 * Returns a tuple `[theme, toggleTheme]`.
 * * The `theme` value describes the currently active theme.
 * * The `toggleTheme` function lets you toggle between themes.
 */
export const useTheme = () => {
  return useContext(ThemeContext)
}
