/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:01:17.

export enum CountryEnum {
  SWITZERLAND = 'SWITZERLAND',
  GERMANY = 'GERMANY',
  FRANCE = 'FRANCE',
  ITALY = 'ITALY',
  AUSTRIA = 'AUSTRIA',
  LIECHTENSTEIN = 'LIECHTENSTEIN',
  AFGHANISTAN = 'AFGHANISTAN',
  ALBANIA = 'ALBANIA',
  ALGERIA = 'ALGERIA',
  AMERICAN_SAMOA = 'AMERICAN_SAMOA',
  ANDORRA = 'ANDORRA',
  ANGOLA = 'ANGOLA',
  ANGUILLA = 'ANGUILLA',
  ANTARCTICA = 'ANTARCTICA',
  ANTIGUA_AND_BARBUDA = 'ANTIGUA_AND_BARBUDA',
  ARGENTINA = 'ARGENTINA',
  ARMENIA = 'ARMENIA',
  ARUBA = 'ARUBA',
  AUSTRALIA = 'AUSTRALIA',
  AZERBAIJAN = 'AZERBAIJAN',
  BAHAMAS = 'BAHAMAS',
  BAHRAIN = 'BAHRAIN',
  BANGLADESH = 'BANGLADESH',
  BARBADOS = 'BARBADOS',
  BELARUS = 'BELARUS',
  BELGIUM = 'BELGIUM',
  BELIZE = 'BELIZE',
  BENIN = 'BENIN',
  BERMUDA = 'BERMUDA',
  BHUTAN = 'BHUTAN',
  BOLIVIA = 'BOLIVIA',
  BOSNIA_AND_HERZEGOVINA = 'BOSNIA_AND_HERZEGOVINA',
  BOTSWANA = 'BOTSWANA',
  BOUVET_ISLAND = 'BOUVET_ISLAND',
  BRAZIL = 'BRAZIL',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'BRITISH_INDIAN_OCEAN_TERRITORY',
  BRUNEI_DARUSSALAM = 'BRUNEI_DARUSSALAM',
  BULGARIA = 'BULGARIA',
  BURKINA_FASO = 'BURKINA_FASO',
  BURUNDI = 'BURUNDI',
  CAMBODIA = 'CAMBODIA',
  CAMEROON = 'CAMEROON',
  CANADA = 'CANADA',
  CAPE_VERDE = 'CAPE_VERDE',
  CAYMAN_ISLANDS = 'CAYMAN_ISLANDS',
  CENTRAL_AFRICAN_REPUBLIC = 'CENTRAL_AFRICAN_REPUBLIC',
  CHAD = 'CHAD',
  CHILE = 'CHILE',
  CHINA = 'CHINA',
  CHRISTMAS_ISLAND = 'CHRISTMAS_ISLAND',
  COCOS_KEELING_ISLANDS = 'COCOS_KEELING_ISLANDS',
  COLOMBIA = 'COLOMBIA',
  COMOROS = 'COMOROS',
  CONGO = 'CONGO',
  CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = 'CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE',
  COOK_ISLANDS = 'COOK_ISLANDS',
  COSTA_RICA = 'COSTA_RICA',
  COTE_D_IVOIRE = 'COTE_D_IVOIRE',
  CROATIA = 'CROATIA',
  CUBA = 'CUBA',
  CYPRUS = 'CYPRUS',
  CZECH_REPUBLIC = 'CZECH_REPUBLIC',
  DENMARK = 'DENMARK',
  DJIBOUTI = 'DJIBOUTI',
  DOMINICA = 'DOMINICA',
  DOMINICAN_REPUBLIC = 'DOMINICAN_REPUBLIC',
  DOMINICAN_REPUBLIC1 = 'DOMINICAN_REPUBLIC1',
  DOMINICAN_REPUBLIC2 = 'DOMINICAN_REPUBLIC2',
  ECUADOR = 'ECUADOR',
  EGYPT = 'EGYPT',
  EL_SALVADOR = 'EL_SALVADOR',
  EQUATORIAL_GUINEA = 'EQUATORIAL_GUINEA',
  ERITREA = 'ERITREA',
  ESTONIA = 'ESTONIA',
  ETHIOPIA = 'ETHIOPIA',
  FALKLAND_ISLANDS_MALVINAS = 'FALKLAND_ISLANDS_MALVINAS',
  FAROE_ISLANDS = 'FAROE_ISLANDS',
  FIJI = 'FIJI',
  FINLAND = 'FINLAND',
  FRENCH_GUIANA = 'FRENCH_GUIANA',
  FRENCH_POLYNESIA = 'FRENCH_POLYNESIA',
  FRENCH_SOUTHERN_TERRITORIES = 'FRENCH_SOUTHERN_TERRITORIES',
  GABON = 'GABON',
  GAMBIA = 'GAMBIA',
  GEORGIA = 'GEORGIA',
  GHANA = 'GHANA',
  GIBRALTAR = 'GIBRALTAR',
  GREECE = 'GREECE',
  GREENLAND = 'GREENLAND',
  GRENADA = 'GRENADA',
  GUADELOUPE = 'GUADELOUPE',
  GUAM = 'GUAM',
  GUATEMALA = 'GUATEMALA',
  GUINEA = 'GUINEA',
  GUINEA_BISSAU = 'GUINEA_BISSAU',
  GUYANA = 'GUYANA',
  HAITI = 'HAITI',
  HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'HEARD_ISLAND_AND_MCDONALD_ISLANDS',
  HOLY_SEE_VATICAN_CITY_STATE = 'HOLY_SEE_VATICAN_CITY_STATE',
  HONDURAS = 'HONDURAS',
  HONG_KONG = 'HONG_KONG',
  HUNGARY = 'HUNGARY',
  ICELAND = 'ICELAND',
  INDIA = 'INDIA',
  INDONESIA = 'INDONESIA',
  IRAN_ISLAMIC_REPUBLIC_OF = 'IRAN_ISLAMIC_REPUBLIC_OF',
  IRAQ = 'IRAQ',
  IRELAND = 'IRELAND',
  ISRAEL = 'ISRAEL',
  JAMAICA = 'JAMAICA',
  JAPAN = 'JAPAN',
  JORDAN = 'JORDAN',
  KAZAKHSTAN = 'KAZAKHSTAN',
  KENYA = 'KENYA',
  KIRIBATI = 'KIRIBATI',
  NORTH_KOREA = 'NORTH_KOREA',
  SOUTH_KOREA = 'SOUTH_KOREA',
  KUWAIT = 'KUWAIT',
  KYRGYZSTAN = 'KYRGYZSTAN',
  LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'LAO_PEOPLES_DEMOCRATIC_REPUBLIC',
  LATVIA = 'LATVIA',
  LEBANON = 'LEBANON',
  LESOTHO = 'LESOTHO',
  LIBERIA = 'LIBERIA',
  LIBYA = 'LIBYA',
  LITHUANIA = 'LITHUANIA',
  LUXEMBOURG = 'LUXEMBOURG',
  MACAO = 'MACAO',
  MADAGASCAR = 'MADAGASCAR',
  MALAWI = 'MALAWI',
  MALAYSIA = 'MALAYSIA',
  MALDIVES = 'MALDIVES',
  MALI = 'MALI',
  MALTA = 'MALTA',
  MARSHALL_ISLANDS = 'MARSHALL_ISLANDS',
  MARTINIQUE = 'MARTINIQUE',
  MAURITANIA = 'MAURITANIA',
  MAURITIUS = 'MAURITIUS',
  MAYOTTE = 'MAYOTTE',
  MEXICO = 'MEXICO',
  MICRONESIA_FEDERATED_STATES_OF = 'MICRONESIA_FEDERATED_STATES_OF',
  MOLDOVA_REPUBLIC_OF = 'MOLDOVA_REPUBLIC_OF',
  MONACO = 'MONACO',
  MONGOLIA = 'MONGOLIA',
  MONTSERRAT = 'MONTSERRAT',
  MOROCCO = 'MOROCCO',
  MOZAMBIQUE = 'MOZAMBIQUE',
  MYANMAR = 'MYANMAR',
  NAMIBIA = 'NAMIBIA',
  NAURU = 'NAURU',
  NEPAL = 'NEPAL',
  NETHERLANDS = 'NETHERLANDS',
  NEW_CALEDONIA = 'NEW_CALEDONIA',
  NEW_ZEALAND = 'NEW_ZEALAND',
  NICARAGUA = 'NICARAGUA',
  NIGER = 'NIGER',
  NIGERIA = 'NIGERIA',
  NIUE = 'NIUE',
  NORFOLK_ISLAND = 'NORFOLK_ISLAND',
  NORTH_MACEDONIA_REPUBLIC_OF = 'NORTH_MACEDONIA_REPUBLIC_OF',
  NORTHERN_MARIANA_ISLANDS = 'NORTHERN_MARIANA_ISLANDS',
  NORWAY = 'NORWAY',
  OMAN = 'OMAN',
  PAKISTAN = 'PAKISTAN',
  PALAU = 'PALAU',
  PALESTINIAN_TERRITORY_OCCUPIED = 'PALESTINIAN_TERRITORY_OCCUPIED',
  PANAMA = 'PANAMA',
  PAPUA_NEW_GUINEA = 'PAPUA_NEW_GUINEA',
  PARAGUAY = 'PARAGUAY',
  PERU = 'PERU',
  PHILIPPINES = 'PHILIPPINES',
  PITCAIRN = 'PITCAIRN',
  POLAND = 'POLAND',
  PORTUGAL = 'PORTUGAL',
  PUERTO_RICO = 'PUERTO_RICO',
  QATAR = 'QATAR',
  REUNION = 'REUNION',
  ROMANIA = 'ROMANIA',
  RUSSIAN_FEDERATION = 'RUSSIAN_FEDERATION',
  RWANDA = 'RWANDA',
  SAINT_HELENA = 'SAINT_HELENA',
  SAINT_KITTS_AND_NEVIS = 'SAINT_KITTS_AND_NEVIS',
  SAINT_LUCIA = 'SAINT_LUCIA',
  SAINT_PIERRE_AND_MIQUELON = 'SAINT_PIERRE_AND_MIQUELON',
  SAINT_VINCENT_AND_THE_GRENADINES = 'SAINT_VINCENT_AND_THE_GRENADINES',
  SAMOA = 'SAMOA',
  SAN_MARINO = 'SAN_MARINO',
  SAO_TOME_AND_PRINCIPE = 'SAO_TOME_AND_PRINCIPE',
  SAUDI_ARABIA = 'SAUDI_ARABIA',
  SENEGAL = 'SENEGAL',
  SEYCHELLES = 'SEYCHELLES',
  SIERRA_LEONE = 'SIERRA_LEONE',
  SINGAPORE = 'SINGAPORE',
  SLOVAKIA = 'SLOVAKIA',
  SLOVENIA = 'SLOVENIA',
  SOLOMON_ISLANDS = 'SOLOMON_ISLANDS',
  SOMALIA = 'SOMALIA',
  SOUTH_AFRICA = 'SOUTH_AFRICA',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS',
  SPAIN = 'SPAIN',
  SRI_LANKA = 'SRI_LANKA',
  SUDAN = 'SUDAN',
  SURINAME = 'SURINAME',
  SVALBARD_AND_JAN_MAYEN = 'SVALBARD_AND_JAN_MAYEN',
  SWAZILAND = 'SWAZILAND',
  SWEDEN = 'SWEDEN',
  SYRIAN_ARAB_REPUBLIC = 'SYRIAN_ARAB_REPUBLIC',
  TAIWAN = 'TAIWAN',
  TAJIKISTAN = 'TAJIKISTAN',
  TANZANIA_UNITED_REPUBLIC_OF = 'TANZANIA_UNITED_REPUBLIC_OF',
  THAILAND = 'THAILAND',
  TIMOR_LESTE = 'TIMOR_LESTE',
  TOGO = 'TOGO',
  TOKELAU = 'TOKELAU',
  TONGA = 'TONGA',
  TRINIDAD_AND_TOBAGO = 'TRINIDAD_AND_TOBAGO',
  TUNISIA = 'TUNISIA',
  TURKEY = 'TURKEY',
  TURKMENISTAN = 'TURKMENISTAN',
  TURKS_AND_CAICOS_ISLANDS = 'TURKS_AND_CAICOS_ISLANDS',
  TUVALU = 'TUVALU',
  UGANDA = 'UGANDA',
  UKRAINE = 'UKRAINE',
  UNITED_ARAB_EMIRATES = 'UNITED_ARAB_EMIRATES',
  UNITED_KINGDOM = 'UNITED_KINGDOM',
  UNITED_STATES_OF_AMERICA = 'UNITED_STATES_OF_AMERICA',
  UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'UNITED_STATES_MINOR_OUTLYING_ISLANDS',
  URUGUAY = 'URUGUAY',
  UZBEKISTAN = 'UZBEKISTAN',
  VANUATU = 'VANUATU',
  VENEZUELA = 'VENEZUELA',
  VIETNAM = 'VIETNAM',
  VIRGIN_ISLANDS_BRITISH = 'VIRGIN_ISLANDS_BRITISH',
  VIRGIN_ISLANDS_US = 'VIRGIN_ISLANDS_US',
  WALLIS_AND_FUTUNA = 'WALLIS_AND_FUTUNA',
  WESTERN_SAHARA = 'WESTERN_SAHARA',
  YEMEN = 'YEMEN',
  ZAMBIA = 'ZAMBIA',
  ZIMBABWE = 'ZIMBABWE',
  ALAND_ISLANDS = 'ALAND_ISLANDS',
  BONAIRE_SINT_EUSTATIUS_AND_SABA = 'BONAIRE_SINT_EUSTATIUS_AND_SABA',
  CURACAO = 'CURACAO',
  GUERNSEY = 'GUERNSEY',
  ISLE_OF_MAN = 'ISLE_OF_MAN',
  JERSEY = 'JERSEY',
  MONTENEGRO = 'MONTENEGRO',
  SAINT_BARTHELEMY = 'SAINT_BARTHELEMY',
  SAINT_MARTIN_FRENCH_PART = 'SAINT_MARTIN_FRENCH_PART',
  SERBIA = 'SERBIA',
  SINT_MAARTEN_DUTCH_PART = 'SINT_MAARTEN_DUTCH_PART',
  SOUTH_SUDAN = 'SOUTH_SUDAN',
  KOSOVO = 'KOSOVO',
}
