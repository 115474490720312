import { Translate, Typo } from '@eplix/ui'
import { Divider, FileIcon } from '@eframe-ui/react'
import { DisplayRowProps } from './types'

export const DisplayRow = ({
  name,
  creationDate,
  downloadFunc,
}: DisplayRowProps) => {
  return (
    <div>
      <div className="grid grid-cols-[2fr_1fr]">
        <Typo variant="regular-200" className={'self-center'}>
          {name}
          {creationDate && (
            <Translate
              translationOptions={{ creationDate }}
              variant="regular-200"
              color="text-secondary"
              label="USM-UserAccount:DocumentsPanel.GeneratedOnDate"
            />
          )}
        </Typo>
        <div
          className={`flex items-center justify-end text-outline ${
            downloadFunc && '!text-blue'
          }`}
        >
          <FileIcon
            onClick={downloadFunc ?? undefined}
            className={`h-6 w-6 mr-3.5 ${downloadFunc && 'cursor-pointer'}`}
          />
        </div>
      </div>
      <Divider className="!my-2" />
    </div>
  )
}
