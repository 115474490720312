/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:04:03.

export enum IllnessReasonEnum {
  UNKNOWN = 'UNKNOWN',
  PSYCHOLOGICAL_SUFFERING = 'PSYCHIC',
  BACK_PAIN = 'BACK',
  PREGNANCY = 'PREGNANCY',
  OTHER_SUFFERING = 'OTHER',
  WORK = 'WORK',
  FREE_TIME = 'FREE_TIME',
}
