import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import {
  ChartLegendIcon,
  ChartLegendLineIcon,
  TranslateSimple,
} from '@eplix/ui'
import { formatMoney } from '@/common'
import { IndicatorsToPropertyNameMapper } from '@/features/portfolio/components/portfolio-info-overview/mappers/indicators-to-property-name-map'
import { PayslipGraphIndicatorsEnums } from '@/common/enums/PayslipGraphIndicators'

const getDisplayLabel = (
  label: string | number,
  year: string | number,
  month: string | number,
) => {
  return label !== year ? (
    <div>
      {label} - {year}
    </div>
  ) : (
    <div>
      {month} - {label}
    </div>
  )
}

export const GraphTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div
        className={
          'bg-white border-1 border-solid border-lightgray rounded-sm p-2'
        }
      >
        {getDisplayLabel(
          label,
          payload?.[0].payload?.year,
          payload?.[0].payload?.month,
        )}
        {payload.map((item) => (
          <div key={item.dataKey}>
            <div className="flex gap-2 items-center bg-gradient-to-b">
              <>
                {item.dataKey ===
                IndicatorsToPropertyNameMapper[
                  PayslipGraphIndicatorsEnums.GROSS_SALARY
                ] ? (
                  <ChartLegendIcon />
                ) : (
                  <ChartLegendLineIcon
                    color={item.color}
                    viewBox="0 16 16 32"
                  />
                )}
                <div className="flex">
                  <TranslateSimple label={item.name as string} />
                  <div> : {formatMoney(item.value as number)}</div>
                </div>
              </>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return null
}
