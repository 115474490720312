import { FC } from 'react'
import { IconProps } from './types'

export const IllnessIcon: FC<IconProps> = ({
  width = 16,
  height = 14,
  stroke = '#3A85F8',
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 16 14`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.00098 1V13"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 10.5L1.5 10.5M15 13L15 8.5C15 8.16667 14.7 7.5 13.5 7.5L5 7.5C4.66667 7 4.5 6 2.5 6L1 6"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7.5V10"
        stroke={stroke}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="5" cy="4" r="1" stroke={stroke} strokeWidth="1.2" />
    </svg>
  )
}
