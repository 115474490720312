import { Accordion } from '@eplix/ui'
import { useTranslation } from 'react-i18next'
import { TaxCertificateItem } from './tax-certificate-item'
import classes from './tax-certificate.module.scss'
import { TaxCertificationDocumentsProps } from './types'

export const TaxCertificateDocuments = ({
  taxCertificationDocuments,
  neoTaxCertificateDocuments,
  hasPensionAttestation,
  ...props
}: TaxCertificationDocumentsProps) => {
  const { t } = useTranslation()

  return (
    <Accordion
      title={t('USM-UserAccount:DocumentsPanel.TaxCertificate')}
      className={classes.accordionStyles}
      withoutBorder
      {...props}
    >
      <>
        {
          <TaxCertificateItem
            taxCertificationDocuments={taxCertificationDocuments}
            neoTaxCertificateDocuments={neoTaxCertificateDocuments}
            hasPensionAttestation={hasPensionAttestation}
          />
        }
      </>
    </Accordion>
  )
}
