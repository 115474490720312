import { useMemo } from 'react'

export const useEnumMapper = (
  enumeration: Record<string, string>,
  labelPrefix?: string,
  ns?: string,
) =>
  useMemo(
    () =>
      Object.keys(enumeration).map((value) => {
        const prefix = `${ns ? ns + ':' : ''}${
          labelPrefix ? labelPrefix + '.' : ''
        }`
        const translation: string = prefix ? `${prefix}${value}` : value

        return { value, translation }
      }),
    [enumeration, labelPrefix, ns],
  )
