import {
  FieldError,
  GetAllCases,
  GetCompaniesResponse,
  PensionDetails,
  ProfileResponse,
} from '@/api'
import { PensionTotalValueLabels } from '@/features/pension-statements/types'
import { ShowTransactionsState } from '@/features/portfolio/components/edit/components/strategy-wrapper/types'
import { CompanyAbsenceSelect } from '@/features/sickness/components/form/types'
import { PayoutPortfolioDataType } from '@/features/withdraw-capital/components/withdrawal-reports/components/withdrawal-report/types'
import { GlobalValueKey, useGlobalValue } from '@/framework'

export const useIsTestUser = () => {
  return useGlobalValue<boolean>(GlobalValueKey.IS_TEST_USER)
}

export const useUserInformation = () => {
  return useGlobalValue<ProfileResponse>(GlobalValueKey.USER_INFORMATION)
}

export const useHideCloseButton = () => {
  return useGlobalValue(GlobalValueKey.HIDE_CLOSE_BUTTON, false)
}

export const useSelectedPortfolioId = () => {
  return useGlobalValue<number | null>(GlobalValueKey.SELECTED_PORTFOLIO_ID)
}

export const useSelectedSicknessAccidentId = () => {
  return useGlobalValue<string | null>(
    GlobalValueKey.SELECTED_SICKNESS_ACCIDENT_ID,
    null,
  )
}

export const useShouldGoToLastPortfolio = () => {
  return useGlobalValue<boolean>(GlobalValueKey.SHOULD_GO_TO_LAST_PORTFOLIO)
}

export const useSelectedPensionId = () => {
  return useGlobalValue<number | null>(GlobalValueKey.SELECTED_PENSION_PLAN_ID)
}

export const useSelectedInsurancePlanId = () => {
  return useGlobalValue<number | null>(
    GlobalValueKey.SELECTED_INSURANCE_PLAN_ID,
  )
}

export const useShouldShowTransactions = () => {
  return useGlobalValue<ShowTransactionsState>(
    GlobalValueKey.SHOULD_SHOW_TRANSACTIONS,
    {
      asset: undefined,
      shouldShowTransactions: false,
    },
  )
}

export const useTitleTotalValueByFilter = () => {
  return useGlobalValue<PensionTotalValueLabels>(
    GlobalValueKey.PENSION_STATEMENT_TOTAL_VALUE_BY_FILTER,
  )
}

export const useAbsenceCaseCreated = () => {
  return useGlobalValue<boolean>(GlobalValueKey.IS_ABSENCE_CASE_CREATED, false)
}

export const useEmailChangeSuccessMessage = () => {
  return useGlobalValue<string>(GlobalValueKey.EMAIL_CHANGE_SUCCESS_MESSAGE)
}

export const useMaintenanceModeBannerHeight = () => {
  return useGlobalValue<number>(GlobalValueKey.MAINTENANCE_MODE_BANNER_HEIGHT)
}

export const useResponseErrorMessage = () => {
  return useGlobalValue<string>(GlobalValueKey.RESPONSE_ERROR_MESSAGE)
}

export const useResponseFormFieldErrorMessages = () => {
  return useGlobalValue<FieldError[]>(
    GlobalValueKey.RESPONSE_FORM_FIELD_ERROR_MESSAGES,
  )
}

export const usePermissions = () => {
  return useGlobalValue<string[]>(GlobalValueKey.PERMISSIONS)
}

export const useIsZendeskWidgetLoaded = () => {
  return useGlobalValue<boolean>(GlobalValueKey.ZENDESK_WIDGET, false)
}

export const useToggleZendeskWidget = () => {
  return useGlobalValue<boolean>(GlobalValueKey.TOGGLE_ZENDESK_WIDGET, false)
}

export const usePayoutPortfolioData = () => {
  return useGlobalValue<PayoutPortfolioDataType>(
    GlobalValueKey.PAYOUT_PORTFOLIO_DATA,
  )
}

export const usePensionStatementsWhole = () => {
  return useGlobalValue<PensionDetails[] | null>(
    GlobalValueKey.PENSION_STATEMENT_WHOLE,
  )
}
export const useEasyTempData = () => {
  return useGlobalValue<boolean>(GlobalValueKey.HAS_EASY_TEMP_DATA)
}

export const useGetCompaniesFromAbsence = () => {
  return useGlobalValue<GetCompaniesResponse | null>(
    GlobalValueKey.COMPANIES_FROM_ABSENCE,
  )
}

export const useGetAllAbsences = () => {
  return useGlobalValue<GetAllCases[] | null>(
    GlobalValueKey.GET_ALL_ABSENCES,
    null,
  )
}

export const useGetAbsenceCompanySelected = () => {
  return useGlobalValue<CompanyAbsenceSelect>(GlobalValueKey.COMPANY_SELECTED, {
    index: 0,
    id: 0,
  })
}
