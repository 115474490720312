import { DocumentGroup, DocumentsToBeAccepted } from '@/api'
import { DocumentsEnum } from '@/common/enums'
import { LegalDocumentType } from '@/common/enums/LegalDocumentType'
import { PortfolioTypeEnum } from '@/common/enums/PortfolioType'

const PORTFOLIO_3A = '3a'
const PORTFOLIO_FZS = 'vested-benefits'

const getPortfolioType = (type?: string) => {
  return type === null || type === PortfolioTypeEnum.PILLAR_3A
    ? PORTFOLIO_3A
    : PORTFOLIO_FZS
}

const getDocument = (
  documentType: DocumentsEnum,
  legalDocumentType: LegalDocumentType,
  documentsToAccept: DocumentsToBeAccepted,
  personalDocumentsInfo?: DocumentGroup[],
) => {
  const hasDocument =
    documentsToAccept?.eplixDocumentTypes?.includes(documentType)

  if (hasDocument && personalDocumentsInfo) {
    return personalDocumentsInfo[0].documents?.find(
      (doc) => doc.name === legalDocumentType,
    )
  }
}

export const getLegalDocumentsActions = () => {
  return { getPortfolioType, getDocument }
}
