import { FC } from 'react'
import { IconProps } from './types'

export const IdeaIconOff: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5996 15.8007L14.7764 17.4472C14.607 17.786 14.2607 18 13.882 18H10.118C9.73926 18 9.393 17.786 9.22361 17.4472L8.40035 15.8007C6.94282 14.7061 6 12.9631 6 11C6 7.68629 8.68629 5 12 5C15.3137 5 18 7.68629 18 11C18 12.9631 17.0572 14.7061 15.5996 15.8007ZM14.0216 14.4846L14.3986 14.2015C15.3748 13.4683 16 12.3075 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 12.3075 8.62519 13.4683 9.60139 14.2015L9.97837 14.4846L10.7361 16H13.2639L14.0216 14.4846Z"
        fill="currentColor"
      />
      <path
        d="M9 20C9 19.4477 9.44771 19 10 19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H10C9.44771 21 9 20.5523 9 20Z"
        fill="currentColor"
      />
    </svg>
  )
}
