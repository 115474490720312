import {
  ActionResponse,
  ErrorResponse,
  ExpectedContribution,
  GetEvaluationHistoryRequest,
  GetEvaluationHistoryResponse,
  GetOfferResponse,
  GetRiskInformationResponse,
  InterestRate,
  MyTellcoTransaction,
  PayInDataResponse,
  PensionAgreementDocumentResponse,
  PortfolioDetails,
  PortfolioEditInformation,
  PortfolioSettingsResponse,
  PortfolioTypeMetadataResponse,
  Report,
  SalesPartnerInformationResponse,
  StrategySwitchHistoryResponse,
} from '@/api'
import { getJSON, IsomorphicRequest, postJSON } from '@/framework/common'
import {
  ACCOUNT_TRANSACTION_API_ROUTE,
  EXPECTED_CONTRIBUTION_ROUTE,
  GET_EVALUATION_HISTORY,
  INTEREST_RATES,
  IS_PORTFOLIO_EDIT_AVAILABLE_API_ROUTE,
  payInData_API_ROUTE,
  PENSION_AGREEMENT_EXISTS,
  PORTFOLIO_FILL_DOCUMENT_TEMPLATE,
  PORTFOLIO_REPORTS_API_ROUTE,
  PORTFOLIO_TAX_CERTIFICATE_API_ROUTE,
  PORTFOLIOS_EDIT_RISK_API_ROUTE,
  PORTFOLIOS_GET_OFFER_API_ROUTE,
  PORTFOLIOS_salesPartner,
  PORTFOLIOS_SUBMIT_EDIT_API_ROUTE,
  portfolioType_METADATA_API_ROUTE,
  portfolioTypeS_BASIC_METADATA_API_ROUTE,
  SETTINGS_API_ROUTE,
  SINGLE_PORTFOLIO_API_ROUTE,
  STRATEGY_SWITCH_DOCUMENTS,
  UPDATE_SETTINGS_API_ROUTE,
} from '../api-routes/portfolio-overview'
import {
  GetAccountTransaction,
  GetEditPortfolioInfomationRequest,
  GetEditPortfolioRiskInfomationRequest,
  GetExpectedContributionRequest,
  GetPayInDataRequest,
  getPensionAgreementDocumentRequest,
  GetPortfolioFillDocumentTemplateRequest,
  GetPortfolioRequest,
  GetPortfolioTypeMetadataRequest,
  GetTaxCertificatePortfolioRequest,
  IsPortfolioEditAvailableRequest,
  SetPortfolioSettingsProps,
  StrategySwitchDocumentsRequest,
  SubmitPortfolioEditRequest,
} from './types'

export const getAccountTransaction = async (req: GetAccountTransaction) => {
  return postJSON<
    Array<MyTellcoTransaction> | ErrorResponse,
    GetAccountTransaction
  >(ACCOUNT_TRANSACTION_API_ROUTE, req)
}

export const getEditInformation = async (
  req: IsPortfolioEditAvailableRequest,
) => {
  return postJSON<
    PortfolioEditInformation | ErrorResponse,
    IsPortfolioEditAvailableRequest
  >(IS_PORTFOLIO_EDIT_AVAILABLE_API_ROUTE, req)
}

export const getEditRisk = async (
  req: GetEditPortfolioRiskInfomationRequest,
) => {
  return postJSON<
    GetRiskInformationResponse | ErrorResponse,
    GetEditPortfolioRiskInfomationRequest
  >(PORTFOLIOS_EDIT_RISK_API_ROUTE, req)
}

export const getEvaluationHistory = async (
  req: GetEvaluationHistoryRequest,
) => {
  return postJSON<
    GetEvaluationHistoryResponse | ErrorResponse,
    GetEvaluationHistoryRequest
  >(GET_EVALUATION_HISTORY, req)
}

export const getExpectedContribution = async (
  req: GetExpectedContributionRequest,
) => {
  return postJSON<
    ExpectedContribution | ErrorResponse,
    GetExpectedContributionRequest
  >(EXPECTED_CONTRIBUTION_ROUTE, req)
}

export const getInterestRates = async (req?: IsomorphicRequest) => {
  return await getJSON<InterestRate | ErrorResponse>(INTEREST_RATES, req)
}

export const getPayInData = async (req: GetPayInDataRequest) => {
  return await postJSON<PayInDataResponse, GetPayInDataRequest>(
    payInData_API_ROUTE,
    req,
  )
}

export const getPensionAgreementExists = async (
  payload: getPensionAgreementDocumentRequest,
) =>
  postJSON<
    PensionAgreementDocumentResponse,
    getPensionAgreementDocumentRequest
  >(PENSION_AGREEMENT_EXISTS, payload)

export const getPortfolioFillDocumentTemplate = async (
  req: GetPortfolioFillDocumentTemplateRequest,
) => {
  return await postJSON<BlobPart, GetPortfolioFillDocumentTemplateRequest>(
    PORTFOLIO_FILL_DOCUMENT_TEMPLATE,
    req,
    {},
    'blob',
  )
}

export const getPortfolioOffer = async (
  req: GetEditPortfolioInfomationRequest,
) => {
  return postJSON<
    GetOfferResponse | ErrorResponse,
    GetEditPortfolioInfomationRequest
  >(PORTFOLIOS_GET_OFFER_API_ROUTE, req)
}

export const getPortfolioReports = async (req: GetPortfolioRequest) => {
  return await postJSON<Array<Report> | ErrorResponse, GetPortfolioRequest>(
    PORTFOLIO_REPORTS_API_ROUTE,
    req,
  )
}

export const getPortfolioTypeMetadata = async (
  req: GetPortfolioTypeMetadataRequest,
) => {
  return await postJSON<
    PortfolioTypeMetadataResponse | ErrorResponse,
    GetPortfolioTypeMetadataRequest
  >(portfolioType_METADATA_API_ROUTE, req)
}

export const getPortfolioTypesBasicMetadata = async () => {
  return await getJSON<Array<PortfolioTypeMetadataResponse> | ErrorResponse>(
    portfolioTypeS_BASIC_METADATA_API_ROUTE,
  )
}

export const getPortfolio = async (req: GetPortfolioRequest) => {
  return postJSON<PortfolioDetails | ErrorResponse, GetPortfolioRequest>(
    SINGLE_PORTFOLIO_API_ROUTE,
    req,
  )
}

export const getStrategySwitchDocuments = async (
  payload: StrategySwitchDocumentsRequest,
) => {
  return await postJSON<
    StrategySwitchHistoryResponse,
    StrategySwitchDocumentsRequest
  >(STRATEGY_SWITCH_DOCUMENTS, payload)
}

export const getPortfolioTaxCertificate = async (
  req: GetTaxCertificatePortfolioRequest,
) => {
  return await postJSON<
    Array<Report> | ErrorResponse,
    GetTaxCertificatePortfolioRequest
  >(PORTFOLIO_TAX_CERTIFICATE_API_ROUTE, req)
}

export const getSettings = async (req: GetPortfolioRequest) => {
  return postJSON<
    PortfolioSettingsResponse | ErrorResponse,
    GetPortfolioRequest
  >(SETTINGS_API_ROUTE, req)
}

export const setSettings = async (req: SetPortfolioSettingsProps) => {
  return postJSON<ActionResponse | ErrorResponse, SetPortfolioSettingsProps>(
    UPDATE_SETTINGS_API_ROUTE,
    req,
  )
}

export const getSalesPartnerPortfolios = async (req: GetPortfolioRequest) => {
  return postJSON<SalesPartnerInformationResponse | null, GetPortfolioRequest>(
    PORTFOLIOS_salesPartner,
    req,
  )
}

export const submitPortfolioEdit = async (req: SubmitPortfolioEditRequest) => {
  return postJSON<ActionResponse | ErrorResponse, SubmitPortfolioEditRequest>(
    PORTFOLIOS_SUBMIT_EDIT_API_ROUTE,
    req,
  )
}
