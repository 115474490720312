import {
  Constants,
  getCommonUserDataActions,
  REGEX_EXPRESSION,
  useSortCountriesAlphabetically,
} from '@/common'
import { PermitTypeEnum } from '@/common/enums/PermitTypeEnum'
import {
  getToggleButtonConfig,
  toggleHandler,
} from '@/common/helpers/toggle-button'
import { Dropdown } from '@/components'
import { PersonalInformation } from '@/features/portfolio/components/forms/personal-information/types'
import { useEnumMapper } from '@/framework/common/hooks/use-enum-mapper'
import { ZipCodes } from '@/framework/zip-codes'
import { Divider } from '@eframe-ui/react'
import {
  FormFieldHelperText,
  OptionModel,
  TextField,
  ToggleButton,
} from '@eplix/ui'
import clsx from 'clsx'
import { FormikProps } from 'formik'
import { ChangeEvent, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AddressFormProps } from './types'

const {
  getHasAddress,
  getIsCrossBorderCountry,
  getIsSwissNationalityUser,
  getIsCrossBorderOrSwissUser,
} = getCommonUserDataActions()

export const AddressForm = memo(
  ({
    form,
    className,
    information,
    isLoginFlow,
    crossBorderClassName,
    shouldCheckPermit = false,
  }: AddressFormProps) => {
    const { t } = useTranslation()
    const placeholder = t('common:Placeholder')
    const permits = useEnumMapper(PermitTypeEnum)
    const hasAddress = getHasAddress(information)
    const hasDocumentCategory = !!information?.documentCategory

    const isCrossBorderToggleConfig = getToggleButtonConfig(
      information?.isCrossBorder,
    )

    const { sortedCountries, switzerlandName } =
      useSortCountriesAlphabetically()

    const { handleChange, errors, values } = form
    const { street, streetNumber, additionalAddress, zip, country, city } =
      values

    const documentCategoryFieldLabel = isLoginFlow
      ? 'common:Permits.Provide'
      : 'common:Permits.Title'

    const permitLabel = form.values.documentCategory
      ? t(`common:Permits.${form.values.documentCategory}`)
      : placeholder

    const permitMap = permits.map(({ value }) => ({
      value,
      label: t(`common:Permits.${value}`),
    }))

    const isSwissNationalityUser = getIsSwissNationalityUser(
      information?.nationality,
    )

    const isCrossBorderOrSwissUser = getIsCrossBorderOrSwissUser(
      values.isCrossBorder,
      country,
    )

    const showDocumentCategoryField =
      !isSwissNationalityUser &&
      isCrossBorderOrSwissUser &&
      (!hasDocumentCategory || shouldCheckPermit)

    const setCityBasedOnZipCode = (): void => {
      const zipValue = form.values.zip
      const isSwitzerland = form.values.country === switzerlandName
      if (zipValue && isSwitzerland) {
        const cityValue = ZipCodes[zipValue] || ''
        form.setFieldValue('city', cityValue)
      }
    }

    const showCrossBorderToggle = useMemo(
      () => getIsCrossBorderCountry(country),
      [country],
    )

    const memoizedOptionsCountry = useMemo(
      () =>
        sortedCountries.map(({ value, translation }) => ({
          value,
          label: translation,
        })),
      [sortedCountries],
    )

    const zipOnChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (value === '' || REGEX_EXPRESSION.ALPHANUMERICAL.test(value)) {
        handleChange(event)
      }
    }

    const handleCountryChange = (option: OptionModel) => {
      form.setFieldValue('country', option.value)
      if (!getIsCrossBorderCountry(option.value as string)) {
        toggleHandler<PersonalInformation>(
          form as FormikProps<PersonalInformation>,
          'isCrossBorder',
          false,
        )
      }
    }

    return (
      <div className={clsx('flex flex-col gap-4', className)}>
        {(!hasAddress || !isLoginFlow) && (
          <div className="flex flex-col gap-4 my-4">
            <div className="flex flex-row gap-4">
              <TextField
                type="text"
                name="street"
                value={street}
                onChange={handleChange}
                error={!!errors.street}
                helperText={errors.street}
                label="common:Field.Street"
              />
              <TextField
                type="text"
                className="w-1/4"
                name="streetNumber"
                value={streetNumber}
                onChange={handleChange}
                error={!!errors.streetNumber}
                helperText={errors.streetNumber}
                label="common:Field.StreetNumber"
              />
            </div>
            <TextField
              type="text"
              onChange={handleChange}
              name="additionalAddress"
              value={additionalAddress}
              error={!!errors.additionalAddress}
              helperText={errors.additionalAddress}
              label="common:Field.AdditionalAddress"
            />
            <div className="flex flex-row items-start gap-4">
              <div className="w-1/3 md:max-w-xs lg:max-w-sm">
                <TextField
                  name="zip"
                  type="text"
                  value={zip}
                  error={!!errors.zip}
                  helperText={errors.zip}
                  onChange={zipOnChangeHandler}
                  onKeyUp={setCityBasedOnZipCode}
                  label="common:Field.ZIP"
                />
              </div>
              <div className="w-2/3 md:flex-grow">
                <TextField
                  type="text"
                  name="city"
                  value={city}
                  error={!!errors.city}
                  onChange={handleChange}
                  helperText={errors.city}
                  label="common:Field.City"
                />
              </div>
            </div>
            <Dropdown
              name="country"
              value={String(country)}
              error={!!errors.country}
              helperText={errors.country}
              label="common:Field.Country"
              onChange={handleCountryChange}
              options={memoizedOptionsCountry}
              buttonLabel={`country:${country}`}
            />
            {showCrossBorderToggle && (
              <div className={clsx('mt-6', crossBorderClassName)}>
                <ToggleButton
                  config={isCrossBorderToggleConfig}
                  onChange={(value) =>
                    toggleHandler<PersonalInformation>(
                      form as FormikProps<PersonalInformation>,
                      'isCrossBorder',
                      value,
                    )
                  }
                  translatedLabel="common:Field.isCrossBorder"
                />
                {errors.isCrossBorder && (
                  <FormFieldHelperText
                    className="mt-1 text-[#ff6359]"
                    helperText="common:Field.MustBeChecked"
                  />
                )}
              </div>
            )}
          </div>
        )}

        {showDocumentCategoryField && (
          <>
            {!hasAddress && isLoginFlow && <Divider className="my-2" />}
            <Dropdown
              label={documentCategoryFieldLabel}
              name="documentCategory"
              value={values.documentCategory}
              onChange={(option: OptionModel) =>
                form.setFieldValue('documentCategory', option.value)
              }
              error={!!errors.documentCategory}
              helperText={errors.documentCategory}
              buttonLabel={permitLabel ?? placeholder}
              options={permitMap}
              fieldClassName={isLoginFlow ? 'mt-2' : Constants.EmptyString}
            />
          </>
        )}
      </div>
    )
  },
)
