export const codesToLabels: Record<number, string> = {
  0: 'wage.type.unknown', // should be -1
  900: 'wage.type.hourly.wage.900',
  901: 'wage.type.hourly.wage.901',
  1000: 'wage.type.monthly.wage.1000',
  1010: 'wage.type.monthly.wage.1000',
  1011: 'wage.type.monthly.wage.1011',
  1015: 'wage.type.correction.monthly.wage',
  1027: 'wage.type.bank.holiday',
  1030: 'wage.type.holiday.days.unpaid',
  1035: 'wage.type.vacation.payout',
  1040: 'wage.type.further.education.days',
  1060: 'wage.type.military.days.50',
  1061: 'wage.type.military.days.80',
  1062: 'wage.type.military.days.100',
  1070: 'wage.type.salary.in.the.event.of.death',
  1100: 'wage.type.hourly.wage.1100',
  1110: 'wage.type.hourly.wage.2nd.rate',
  1135: 'wage.type.hours.of.vacation.paid',
  1138: 'wage.type.hours.of.absence.paid',
  1140: 'wage.type.further.education.hours',
  1150: 'wage.type.hourly.wage.1150',
  1160: 'wage.type.military.hours.50',
  1161: 'wage.type.military.hours.80',
  1162: 'wage.type.military.hours.100',
  1190: 'wage.type.unemployed.hours.partial',
  1200: 'wage.type.overtime.supplement.25',
  1201: 'wage.type.hourly.wage.1201',
  1202: 'wage.type.hourly.wage.1202',
  1204: 'wage.type.hourly.wage.1204',
  1210: 'wage.type.overtime.supplement.50',
  1230: 'wage.type.overtime.supplement.100SH',
  1240: 'wage.type.overtime.supplement.100',
  1250: 'wage.type.overtime.supplement.125',
  1260: 'wage.type.overtime.supplement.150',
  1270: 'wage.type.overtime.supplement.175',
  1280: 'wage.type.overtime.supplement.200',
  1290: 'wage.type.hourly.wage.1290',
  1400: 'wage.type.additional.pay',
  1500: 'wage.type.hourly.wage.1500',
  1510: 'wage.type.administration.fee',
  1550: 'wage.type.hourly.wage.1550',
  1600: 'wage.type.rwh.sm.salary.reduction',
  1630: 'wage.type.rwh.sm.salary.reduction.payment',
  1650: 'wage.type.rwh.sh.salary.reduction.payment',
  1660: 'wage.type.rwh.sh.salary.reduction.20',
  1665: 'wage.type.rwh.sm.salary.reduction.20',
  1700: 'wage.type.private.share.of.service.car',
  1801: 'wage.type.family.allowance',
  2040: 'wage.type.sick.days.unpaid',
  2050: 'wage.type.sickness.hours.100',
  2054: 'wage.type.sickness.hours.80',
  2062: 'wage.type.sickness.days.100',
  2063: 'wage.type.sickness.days.80',
  2064: 'wage.type.sickness.days.20',
  2070: 'wage.type.maternity.paternity.100',
  2071: 'wage.type.maternity.days.80',
  2072: 'wage.type.maternity.days.20',
  2100: 'wage.type.correction.insurance.deductions.sickness',
  2110: 'wage.type.indemnity.sickness',
  2118: 'wage.type.daily.allowance.accident.100',
  2119: 'wage.type.daily.allowance.accident.80',
  2120: 'wage.type.daily.allowance.accident.20',
  2127: 'wage.type.covid19.quarantine.days',
  2128: 'wage.type.covid19.quarantine.hours',
  2300: 'wage.type.remuneration.board.of.directors',
  2800: 'wage.type.lost.of.earnings.indemnity',
  2801: 'wage.type.lost.of.earnings.indemnity.paid',
  2810: 'wage.type.indemnity.sickness.allowance',
  2811: 'wage.type.repayment.daily.sickness.allowance',
  2821: 'wage.type.repayment.daily.accident.suva.allowance',
  2850: 'wage.type.restitution.of.charges',
  2860: 'wage.type.repayment.daily.accident.net.suva.allowance',
  2899: 'wage.type.variance.monthly-daily.salary',
  2900: 'wage.type.rounding.difference',
  3000: 'wage.type.child.and.education.allowances',
  3010: 'wage.type.child.and.education.correction.allowances',
  3011: 'wage.type.family.periodic.allowances',
  3020: 'wage.type.birth.allowances',
  3030: 'wage.type.adoption.allowances',
  3050: 'wage.type.adoption.allowance',
  3100: 'wage.type.holiday.payment',
  3500: 'wage.type.entitlement.vacation.pay',
  3502: 'wage.type.vacation.payment',
  3504: 'wage.type.vacation.leaving.payment',
  3512: 'wage.type.public.holiday.payment',
  3514: 'wage.type.public.holiday.leaving',
  3800: 'wage.type.13th.salary.rate',
  3810: 'wage.type.13th.salary',
  4000: 'wage.type.ic.compensation',
  4100: 'wage.type.premium',
  4110: 'wage.type.special.premium.gross',
  4120: 'wage.type.special.premium.net',
  4130: 'wage.type.social.security.contributions.special.premium.net',
  4140: 'wage.type.cancellation.withholding.tax.paid.special',
  4150: 'wage.type.withholding.tax.paid.special',
  4300: 'wage.type.various.compensation',
  4400: 'wage.type.car.expenses.flat.rate',
  4410: 'wage.type.expenses.representation',
  4500: 'wage.type.room',
  4510: 'wage.type.meals',
  4520: 'wage.type.general.abonnement.sbb',
  4530: 'wage.type.company.car.summation',
  4531: 'wage.type.fringe.benefits.car',
  4735: 'wage.type.holiday.payout',
  5100: 'wage.type.replacement.costs',
  5101: 'wage.type.meal.costs',
  5102: 'wage.type.accommodation.costs',
  5103: 'wage.type.expenses.abroad',
  5104: 'wage.type.postage.expenses',
  5105: 'wage.type.fuel.expenses',
  5106: 'wage.type.delivery.expenses',
  5107: 'wage.type.office.supplies.expenses',
  5108: 'wage.type.mileage.allowance.chf.0.30',
  5109: 'wage.type.mileage.allowance.chf.0.65',
  5110: 'wage.type.mileage.allowance.chf.0.36',
  5149: 'wage.type.other.costs',
  5150: 'wage.type.reimbursement.of.invoices',
  5160: 'wage.type.reimbursement.of.expenses',
  5200: 'wage.type.representation.expenses.flat.rate',
  5210: 'wage.type.car.expenses.flat.rate',
  5230: 'wage.type.meals.flat.rate.allowance',
  5240: 'wage.type.other.flat.rate.expenses',
  6000: 'wage.type.contribution.avs',
  6010: 'wage.type.contribution.ac',
  6030: 'wage.type.contribution.ge',
  6100: 'wage.type.suva.contribution',
  6151: 'wage.type.contribution.laac',
  6200: 'wage.type.lob.contribution.employee.men',
  6210: 'wage.type.lob.contribution.employee.women',
  6254: 'wage.type.contribution.lpp.pta.men',
  6255: 'wage.type.contribution.lpp.pta.women',
  6256: 'wage.type.contribution.correction.lpp.men',
  6257: 'wage.type.contribution.correction.lpp.women',
  6300: 'wage.type.contribution.laa.cna',
  6305: 'wage.type.contribution.laa.women.cna',
  6310: 'wage.type.suva.contribution.employee.correction',
  6320: 'wage.type.supplementary.accident.insurance.deduction',
  6330: 'wage.type.contribution.uvgz',
  6350: 'wage.type.maternity.insurance.deduction',
  6400: 'wage.type.contribution.cp.lpp',
  6510: 'wage.type.withholding.tax.deduction',
  6550: 'wage.type.withholding.tax.correction',
  6710: 'wage.type.withholding.tax.in.percentage',
  6800: 'wage.type.withholding.tax.fixed',
  7000: 'wage.type.various.deductions',
  7200: 'wage.type.deduction.of.private.vehicle.share',
  8060: 'wage.type.representation.allowance',
  8190: 'wage.type.expense.reimbursement',
  8430: 'wage.type.deduction.fuel.consumption',
  8530: 'wage.type.deduction.service.vehicle',
  8590: 'wage.type.fuel.consumption',
  8600: 'wage.type.garnishment.of.wages',
  8710: 'wage.type.loan.repayment',
  8810: 'wage.type.occupational.contribution',
  8820: 'wage.type.rent.garage.apartment',
  9010: 'wage.type.minimum.subsistence.level',
  9020: 'wage.type.transfer.debt.enforcem.office',
  9030: 'wage.type.wage.garnishment',
  9040: 'wage.type.transfer.employee',
  9100: 'wage.type.vacation.balance.payment',
  9110: 'wage.type.public.holiday.balance',
  9150: 'wage.type.loan.balance',
  9240: 'wage.type.vacation.balance.in.days',
  9270: 'wage.type.overtime.balance.in.hours',
  9700: 'wage.type.lob.contribution.employer.men',
  9701: 'wage.type.lob.contribution.employer.women',
}
