import screenSize from '@eplix/ui/foundation/media-queries.json'

export const ScreenBreakPoints = {
  XS: screenSize.xs,
  SM: screenSize.sm,
  MD: screenSize.md,
  LG: screenSize.lg,
  XL: screenSize.xl,
  XXL: screenSize.xxl,
  XXXL: screenSize.xxxl,
  XXXXL: screenSize.xxxxl,
}
