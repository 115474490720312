export const UR = {
  6377: 'Seelisberg',
  6441: 'Rütli',
  6452: 'Sisikon',
  6454: 'Flüelen',
  6460: 'Altdorf UR',
  6461: 'Isenthal',
  6462: 'Seedorf UR',
  6463: 'Bürglen UR',
  6464: 'Spiringen',
  6465: 'Unterschächen',
  6466: 'Bauen',
  6467: 'Schattdorf',
  6468: 'Attinghausen',
  6469: 'Haldi b.Schattdorf',
  6472: 'Erstfeld',
  6473: 'Silenen',
  6474: 'Amsteg',
  6475: 'Bristen',
  6476: 'Intschi',
  6482: 'Gurtnellen',
  6484: 'Wassen UR',
  6485: 'Meien',
  6487: 'Göschenen',
  6490: 'Andermatt',
  6491: 'Realp',
  6493: 'Hospental',
  8751: 'Urnerboden',
}
