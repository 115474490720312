import { RouteExp } from '@/framework/client-router'

export const USER_ACCOUNT_ROUTE = '/user-account'
export const SALARY_ROUTE = '/salary'
export const PENSION_ROUTE = '/pension'
export const DASHBOARD_ROUTE = '/dashboard'
export const PAYSLIP_DOCUMENTS_ROUTE = '/documents'
export const PAYSLIP_WAGE_STATEMENTS_ROUTE = '/documents/tax-certificate'
export const RISK_INSURANCE_ROUTE = '/risk-insurance'

export const PORTFOLIO_3A_TAB_KEY_ROUTE = 'portfolio-3a'
export const PORTFOLIO_FZS_TAB_KEY_ROUTE = 'portfolio-vested-benefits'

export const CHANGE_OF_BENEFICIARIES_ROUTE = '/change-of-beneficiaries'

export const tabRoute = new RouteExp<{ tabKey: string }>('/:tabKey')
export const cardRoute = new RouteExp<{ cardKey: string }>('/:cardKey')

export const cardRouteWithTabKey = new RouteExp<{
  tabKey: string
  cardKey: string
}>('/:tabKey/:cardKey')

export const editRoute = new RouteExp<{
  tabKey: string
  cardKey: string
  edit: string
}>('/:tabKey/:cardKey/:edit', true)

export const editRouteWithoutTabKey = new RouteExp<{
  tabKey: string
  cardKey: string
  edit: string
}>('/:cardKey/:edit', true)
