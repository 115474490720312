import {
  Children,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  useMemo,
} from 'react'
import clsx from 'clsx'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import classes from './dialog.module.scss'
import { Typo } from '../typography'

export type DialogMainProps = HTMLAttributes<HTMLDivElement>

export const DialogMain = forwardRef(
  (
    { className, children, ...props }: DialogMainProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const hasTextualDescription = useMemo(
      () =>
        Children.toArray(children).every(
          (child) =>
            (isValidElement(child) && child.type === Typo) ||
            typeof child === 'string',
        ),
      [children],
    )

    return (
      <div ref={ref} className={clsx(classes.main, className)} {...props}>
        {hasTextualDescription ? (
          <HeadlessDialog.Description as={'div'}>
            {children}
          </HeadlessDialog.Description>
        ) : (
          children
        )}
      </div>
    )
  },
)
