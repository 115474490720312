/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:01:17.

export enum CantonEnum {
  AARGAU = 'AARGAU',
  APPENZEL_AUSSERRHODEN = 'APPENZEL_AUSSERRHODEN',
  APPENZEL_INNERRHODEN = 'APPENZEL_INNERRHODEN',
  BASEL_LAND = 'BASEL_LAND',
  BASEL_STADT = 'BASEL_STADT',
  BERN = 'BERN',
  DEUTCHLAND = 'DEUTCHLAND',
  FREIBURG = 'FREIBURG',
  FUERSTENTUM_LIECHTENSTEIN = 'FUERSTENTUM_LIECHTENSTEIN',
  GENF = 'GENF',
  GLARUS = 'GLARUS',
  GRAUBUENDEN = 'GRAUBUENDEN',
  JURA = 'JURA',
  LUZERN = 'LUZERN',
  NUENBURG = 'NUENBURG',
  NIDWALDEN = 'NIDWALDEN',
  OBWALDEN = 'OBWALDEN',
  SCHAFFHAUSEN = 'SCHAFFHAUSEN',
  SCHWYZ = 'SCHWYZ',
  SOLOTHURN = 'SOLOTHURN',
  ST_GALLEN = 'ST_GALLEN',
  TESSIN = 'TESSIN',
  THURGAU = 'THURGAU',
  URI = 'URI',
  WAADT = 'WAADT',
  WALLIS = 'WALLIS',
  ZUG = 'ZUG',
  ZUERICH = 'ZUERICH',
}
