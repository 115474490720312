/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 11:51:59.

export enum PasswordPolicyErrorCodeEnum {
  REQUIRED = 'required',
  MIN = 'min',
  UPPER = 'upper',
  LOWER = 'lower',
  SPEC = 'spec',
  INVALID_CHARACTERS = 'invalidCharacters',
}
