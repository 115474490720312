import {
  ActionResponse,
  ErrorResponse,
  GetAllCasesResponse,
  GetCaseDetailsResponse,
  GetCaseDocumentResponse,
  GetCompaniesResponse,
  MedicsResponse,
  ReportNewAbsenceCaseRequest,
  ReportNewAbsenceCaseResponse,
  UpdateAbsenceCaseRequest,
} from '@/api'
import { getJSON, postJSON } from '@/framework/common'
import {
  CaseDetailRequest,
  CloseCaseRequest,
  GetAbsenceDocumentRequestProps,
} from '../types'
const baseUrl = '/api/absence'

export const getCompanies = async () => {
  return getJSON<GetCompaniesResponse>(`${baseUrl}/get-companies`)
}

export const getDocument = async (req: GetAbsenceDocumentRequestProps) => {
  return postJSON<GetCaseDocumentResponse, GetAbsenceDocumentRequestProps>(
    `${baseUrl}/get-absence-document`,
    req,
  )
}

export const createSicknessCase = async (req: ReportNewAbsenceCaseRequest) => {
  return postJSON<
    ReportNewAbsenceCaseResponse | ErrorResponse,
    ReportNewAbsenceCaseRequest
  >(`${baseUrl}/create-sickness`, req)
}

export const getAllAbsenceCases = async () => {
  return getJSON<GetAllCasesResponse | ErrorResponse>(
    `${baseUrl}/get-all-cases`,
  )
}

export const updateEditCasses = async (req: UpdateAbsenceCaseRequest) => {
  return postJSON<ActionResponse | ErrorResponse, UpdateAbsenceCaseRequest>(
    `${baseUrl}/update-absence-case`,
    req,
  )
}

export const closeCasse = async (req: CloseCaseRequest) => {
  return postJSON<ActionResponse | ErrorResponse, CloseCaseRequest>(
    `${baseUrl}/close-case`,
    req,
  )
}

export const getMedicalInstitutions = async (req: string) => {
  return postJSON<MedicsResponse | ErrorResponse, string>(
    '/api/absence/get-medical-institutions',
    req,
  )
}

export const getAbsenceCaseDetail = async (req: CaseDetailRequest) => {
  return postJSON<GetCaseDetailsResponse | ErrorResponse, CaseDetailRequest>(
    `${baseUrl}/get-case-detail`,
    req,
  )
}
