import { useId } from '@eframe-ui/react'
import { SelectLabelStyle } from '@eplix/frontend/components/dropdown/dropdown'
import clsx from 'clsx'
import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  MutableRefObject,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ChevronDownIcon, WarningIcon } from '../icons'
import {
  SelectInput,
  SelectInputButton,
  SelectInputOptions,
  SelectInputOptionsProps,
  SelectInputProps,
} from '../inputs'
import { Translate } from '../translate'
import {
  FormFieldBorderBox,
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
} from './form-field'
import classes from './select-field.module.scss'

export interface SelectFieldProps extends SelectInputProps {
  label?: string
  name: string
  error?: boolean
  helperText?: string
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
  className?: string
  buttonProps?: HTMLProps<HTMLButtonElement>
  optionsProps?: SelectInputOptionsProps
  buttonLabel?: ReactNode
  optionsRef?:
    | ((ref?: HTMLUListElement | null) => void)
    | MutableRefObject<HTMLUListElement | null>
  onOpen?: ({ open }: { open: boolean }) => void
  multiple?: boolean
  menuListClassName?: string
  buttonLabelClassName?: string
  optionValueLabelStyle?: SelectLabelStyle
  borderBoxClassName?: string
}

const getSelectFieldVariants = ({ disabled, error }: SelectFieldProps) => {
  if (disabled) {
    return 'disabled'
  } else if (error) {
    return 'error'
  }
}

export const SelectField = forwardRef(
  (props: SelectFieldProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      label,
      name,
      error,
      helperText,
      buttonLabel,
      multiple,
      startAdornment,
      endAdornment,
      className,
      value,
      onChange,
      buttonProps,
      optionsProps,
      optionsRef,
      onOpen,
      menuListClassName,
      buttonLabelClassName,
      optionValueLabelStyle,
      borderBoxClassName,
      ...rest
    } = props

    const id = useId()
    const ariaLabelId = `label-${id}`
    const [isOpen, setIsOpen] = useState(false)
    const htmlOptionRef = useRef<HTMLUListElement | null>(null)
    const isAtBottom = useMemo(() => {
      if (!isOpen || !htmlOptionRef.current) {
        return false
      }
      const { top, height } = htmlOptionRef.current.getBoundingClientRect() || {
        top: 0,
        height: 0,
      }

      return window.innerHeight < top + height
    }, [isOpen])
    const handleOpenState = (open: boolean) => {
      onOpen && onOpen({ open })

      setTimeout(() => {
        setIsOpen(open)
      }, 0)
    }

    return (
      <FormFieldControl
        className={clsx(className, ' flex justify-between')}
        variant={getSelectFieldVariants(props)}
      >
        {label && <FormFieldLabel htmlFor={ariaLabelId} label={label} />}
        <FormFieldBorderBox
          className={clsx(classes.borderBox, borderBoxClassName)}
        >
          <SelectInput
            value={value}
            label={label}
            onChange={onChange}
            disabled={rest.disabled}
            multiple={multiple}
          >
            <SelectInputButton
              {...buttonProps}
              htmlFor={id}
              className={clsx(classes.button, buttonProps?.className)}
              ref={ref}
              disabled={rest.disabled || buttonProps?.disabled}
              data-cy={`dd_btn_${name}`}
            >
              {({ open }) => {
                handleOpenState(open)
                return (
                  <>
                    {startAdornment}
                    <span
                      className={clsx(
                        classes.buttonLabel,
                        buttonLabelClassName,
                      )}
                      data-cy={`dd_value_${value}`}
                    >
                      <Translate
                        label={buttonLabel as string}
                        color={optionValueLabelStyle?.color ?? 'black'}
                        variant={optionValueLabelStyle?.font ?? 'regular-300'}
                      />
                    </span>
                    <ChevronDownIcon
                      style={{
                        transform: `rotate(${open ? 180 : 0}deg)`,
                        transition: 'transform ease 250ms',
                        color: rest.disabled
                          ? 'var(--ex-rgb-outline)'
                          : 'var(--ex-rgb-blue)',
                      }}
                    />
                  </>
                )
              }}
            </SelectInputButton>
            {error && <WarningIcon style={{ color: 'var(--border-color)' }} />}
            {endAdornment}
            <SelectInputOptions
              {...optionsProps}
              className={clsx(
                classes.options,
                isAtBottom && classes.optionsTop,
                optionsProps?.className,
              )}
              menuListClassName={menuListClassName}
              ref={(htmlRef) => {
                htmlOptionRef.current = htmlRef
                if (optionsRef) {
                  if (typeof optionsRef === 'function') {
                    optionsRef(htmlRef)
                  } else {
                    optionsRef.current = htmlRef
                  }
                }
              }}
              disabled={rest.disabled || optionsProps?.disabled}
            >
              {rest.children}
            </SelectInputOptions>
          </SelectInput>
        </FormFieldBorderBox>
        {helperText && <FormFieldHelperText helperText={helperText} />}
      </FormFieldControl>
    )
  },
)
