const base = '/api/pension'

export const PENSION_PLAN = `${base}/insurance-plan`
export const INSTITUTIONS = `${base}/institutions`
export const PKPRO_PURCHASE_PENSION_PLAN = `${base}/pkpro-buy-in`
export const PKFLEX_PURCHASE_PENSION_PLAN = `${base}/pkflex-buy-in`
export const PENSION_CERTIFICATE = `${base}/pension-certificate-information`
export const PENSION_CERTIFICATE_DOCUMENTS = `${base}/get-certificate-documents`
export const PKPRO_CERTIFICATE_DOCUMENTS = `${base}/get-pkpro-certificate-documents`
export const PKFLEX_CERTIFICATE_DOCUMENTS = `${base}/get-pkflex-certificate-documents`
export const PKPRO_FINANCING_MODEL = `${base}/pkpro-financing-model`
export const PKFLEX_FINANCING_MODEL = `${base}/pkflex-financing-model`
export const PKPRO_QR_PAYMENT_SLIP = `${base}/pkpro-payment-slip`
export const PKFLEX_QR_PAYMENT_SLIP = `${base}/pkflex-payment-slip`
export const PKPRO_PAYBACK_PAYMENT_SLIP = `${base}/pkpro-payback-payment-slip`
export const PKFLEX_PAYBACK_PAYMENT_SLIP = `${base}/pkflex-payback-payment-slip`
export const PKPRO_COVER_RATIO = `${base}/pkpro-cover-ratio`
export const PKFLEX_COVER_RATIO = `${base}/pkflex-cover-ratio`
export const COVERAGE_RATIO = `${base}/coverage-ratio`
export const PKPRO_CONVERSION_RATES = `${base}/pkpro-conversion-rates`
export const PKFLEX_CONVERSION_RATES = `${base}/pkflex-conversion-rates`
export const PKPRO_INSURANCE = `${base}/get-pkpro-insurance-plan`
export const PKFLEX_INSURANCE = `${base}/get-pkflex-insurance-plan`
