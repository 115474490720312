import {
  AuthenticationRequest,
  ForgotPasswordRequest,
  ForgotPasswordVerifySMSCodeRequest,
  ResetPasswordRequest,
  VerifySMSCodeRequest,
} from '@/api'
import { PasswordInvalidCharacters } from '@/common/enums/PasswordInvalidCharacters'
import { passwordValidator } from '@/common'
import { useFormik } from 'formik'
import { useMemo } from 'react'
import { boolean, object, ObjectSchema, string } from 'yup'

const useLoginSchema = () => {
  const invalidCharacters = Object.values(PasswordInvalidCharacters)

  return useMemo(() => {
    return object().shape({
      username: string()
        .required('USM-Login:LoginSchema.Phone')
        .matches(new RegExp('[0-9]'), 'USM-Login:LoginSchema.MatchesNumbers')
        .min(6, 'USM-Login:LoginSchema.MinLength')
        .max(45, 'USM-Schemas:LoginSchema.MaxLength'),
      password: string()
        .required('USM-Login:LoginSchema.Password')
        .test(
          'password',
          'USM-Schemas:UserAccount.ChangePassword.InvalidCharacters',
          function (value) {
            return !invalidCharacters.some((element) => value.includes(element))
          },
        ),
    })
  }, [invalidCharacters])
}

export const useLoginForm = (
  initialValues: AuthenticationRequest,
  { enableValidation = false },
  onSubmit: (v: AuthenticationRequest) => void | Promise<unknown>,
) => {
  const validationSchema = useLoginSchema()
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: enableValidation,
    validateOnBlur: enableValidation,
    enableReinitialize: true,
  })
}

const useValidateSchema = () => {
  return useMemo(() => {
    const requiredFieldValidationMessage =
      'USM-Schemas:ValidateSMSCodeSchema.FieldRequired'

    const invalidSmsCodeFormat =
      'USM-Schemas:ValidateSMSCodeSchema.InvalidFormat'

    return object().shape({
      smsCode: string()
        .length(6, invalidSmsCodeFormat)
        .required(requiredFieldValidationMessage),
    })
  }, [])
}

type PickSmsCode = Pick<VerifySMSCodeRequest, 'smsCode'>

export const useValidateForm = (
  initialValues: PickSmsCode,
  { enableValidation = false },
  onSubmit: (v: PickSmsCode) => void | Promise<unknown>,
) => {
  const validationSchema = useValidateSchema()
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: enableValidation,
    validateOnBlur: enableValidation,
    enableReinitialize: true,
  })
}

const useConfirmPhoneSchema = (): ObjectSchema<
  Required<ForgotPasswordRequest>
> => {
  return useMemo(() => {
    return object().shape({
      phonenumber: string()
        .required('USM-ForgotPassword:ConfirmPhoneSchema.Required')
        .min(6, 'USM-ForgotPassword:ConfirmPhoneSchema.Minimum'),
    })
  }, [])
}

export const useConfirmPhoneForm = (
  initialValues: ForgotPasswordRequest,
  onSubmit: (v: ForgotPasswordRequest) => void | Promise<unknown>,
) => {
  const validationSchema = useConfirmPhoneSchema()
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  })
}

const useAddNewPasswordSchema = (): ObjectSchema<ResetPassword> => {
  const invalidCharacters = Object.values(PasswordInvalidCharacters)

  return object().shape({
    password: string()
      .required('required')
      .test('password', passwordValidator)
      .test('password', 'invalid_chars', function (value) {
        return !invalidCharacters.some((element) => value.includes(element))
      }),
  })
}

type ResetPassword = Pick<ResetPasswordRequest, 'password'>

export const useAddNewPasswordForm = (
  initialValues: ResetPassword,
  onSubmit: (v: ResetPassword) => void | Promise<unknown>,
) => {
  const validationSchema = useAddNewPasswordSchema()
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: false,
  })
}

const useReSendSmsValidateSchema = () => {
  return useMemo(() => {
    const requiredFieldValidationMessage =
      'USM-Schemas:ValidateSMSCodeSchema.FieldRequired'

    const invalidSMSCodeFormat =
      'USM-Schemas:ValidateSMSCodeSchema.InvalidFormat'

    return object().shape({
      smsCode: string()
        .length(6, invalidSMSCodeFormat)
        .required(requiredFieldValidationMessage),
    })
  }, [])
}

type ForgotPasswordVerifySmsCode = Pick<
  ForgotPasswordVerifySMSCodeRequest,
  'smsCode'
>
export const useReSendSmsValidateForm = (
  initialValues: ForgotPasswordVerifySmsCode,
  { enableValidation = false },
  onSubmit: (v: ForgotPasswordVerifySmsCode) => void | Promise<unknown>,
) => {
  const validationSchema = useReSendSmsValidateSchema()
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: enableValidation,
    validateOnBlur: enableValidation,
    enableReinitialize: true,
  })
}

export interface LegalDocumentsFormModel {
  accepted: boolean
}

const useAcceptLegalDocumentsSchema = () => {
  return useMemo(() => {
    const requiredFieldValidationMessage =
      'USM-Schemas:ValidateSMSCodeSchema.FieldRequired'

    return object().shape({
      accepted: boolean()
        .required(requiredFieldValidationMessage)
        .oneOf([true]),
    })
  }, [])
}

export const useAcceptLegalDocumentsForm = (
  initialValues: LegalDocumentsFormModel,
  { enableValidation = false },
  onSubmit: (v: LegalDocumentsFormModel) => void | Promise<unknown>,
) => {
  const validationSchema = useAcceptLegalDocumentsSchema()
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: enableValidation,
    validateOnBlur: enableValidation,
    enableReinitialize: true,
  })
}
