export const AG = {
  4303: 'Kaiseraugst',
  4305: 'Olsberg',
  4310: 'Rheinfelden',
  4312: 'Magden',
  4313: 'Möhlin',
  4314: 'Zeiningen',
  4315: 'Zuzgen',
  4316: 'Hellikon',
  4317: 'Wegenstetten',
  4322: 'Mumpf',
  4323: 'Wallbach',
  4324: 'Obermumpf',
  4325: 'Schupfart',
  4332: 'Stein AG',
  4333: 'Münchwilen AG',
  4334: 'Sisseln AG',
  4663: 'Aarburg',
  4665: 'Oftringen',
  4800: 'Zofingen',
  4801: 'Zofingen',
  4802: 'Strengelbach',
  4803: 'Vordemwald',
  4805: 'Brittnau',
  4807: 'Zofingen PF',
  4808: 'Zofingen PF',
  4809: 'Zofingen PF',
  4812: 'Mühlethal',
  4813: 'Uerkheim',
  4814: 'Bottenwil',
  4852: 'Rothrist',
  4853: 'Murgenthal',
  4856: 'Glashütten',
  5000: 'Aarau',
  5001: 'Aarau',
  5004: 'Aarau',
  5017: 'Barmelweid',
  5018: 'Erlinsbach',
  5022: 'Rombach',
  5023: 'Biberstein',
  5024: 'Küttigen',
  5025: 'Asp',
  5026: 'Densbüren',
  5027: 'Herznach',
  5028: 'Ueken',
  5032: 'Aarau Rohr',
  5033: 'Buchs AG',
  5034: 'Suhr',
  5035: 'Unterentfelden',
  5036: 'Oberentfelden',
  5037: 'Muhen',
  5040: 'Schöftland',
  5042: 'Hirschthal',
  5043: 'Holziken',
  5044: 'Schlossrued',
  5046: 'Schmiedrued',
  5053: 'Staffelbach',
  5054: 'Kirchleerau-Moosle',
  5056: 'Attelwil',
  5057: 'Reitnau',
  5058: 'Wiliberg',
  5062: 'Oberhof',
  5063: 'Wölflinswil',
  5064: 'Wittnau',
  5070: 'Frick',
  5072: 'Oeschgen',
  5073: 'Gipf-Oberfrick',
  5074: 'Eiken',
  5075: 'Hornussen',
  5076: 'Bözen',
  5077: 'Elfingen',
  5078: 'Effingen',
  5079: 'Zeihen',
  5080: 'Laufenburg',
  5082: 'Kaisten',
  5083: 'Ittenthal',
  5084: 'Rheinsulz',
  5085: 'Sulz AG',
  5102: 'Rupperswil',
  5103: 'Wildegg',
  5105: 'Auenstein',
  5106: 'Veltheim AG',
  5107: 'Schinznach Dorf',
  5108: 'Oberflachs',
  5112: 'Thalheim AG',
  5113: 'Holderbank AG',
  5116: 'Schinznach Bad',
  5200: 'Brugg AG',
  5201: 'Brugg AG',
  5210: 'Windisch',
  5212: 'Hausen AG',
  5213: 'Villnachern',
  5222: 'Umiken',
  5223: 'Riniken',
  5224: 'Unterbözberg',
  5225: 'Oberbözberg',
  5232: 'Villigen PSI',
  5233: 'Stilli',
  5234: 'Villigen',
  5235: 'Rüfenach AG',
  5236: 'Remigen',
  5237: 'Mönthal',
  5242: 'Birr-Lupfig',
  5243: 'Mülligen',
  5244: 'Birrhard',
  5245: 'Habsburg',
  5246: 'Scherz',
  5272: 'Gansingen',
  5273: 'Oberhofen AG',
  5274: 'Mettau',
  5275: 'Etzgen',
  5276: 'Wil AG',
  5277: 'Hottwil',
  5300: 'Turgi',
  5301: 'Siggenthal Station',
  5303: 'Würenlingen',
  5304: 'Endingen',
  5305: 'Unterendingen',
  5306: 'Tegerfelden',
  5312: 'Döttingen',
  5313: 'Klingnau',
  5314: 'Kleindöttingen',
  5315: 'Böttstein',
  5316: 'Leuggern',
  5317: 'Hettenschwil',
  5318: 'Mandach',
  5322: 'Koblenz',
  5323: 'Rietheim',
  5324: 'Full-Reuenthal',
  5325: 'Leibstadt',
  5326: 'Schwaderloch',
  5330: 'Bad Zurzach',
  5332: 'Rekingen AG',
  5333: 'Baldingen',
  5334: 'Böbikon',
  5400: 'Baden',
  5401: 'Baden',
  5402: 'Baden',
  5404: 'Baden',
  5405: 'Baden',
  5406: 'Baden',
  5408: 'Ennetbaden',
  5412: 'Gebenstorf',
  5413: 'Birmenstorf AG',
  5415: 'Nussbaumen AG',
  5416: 'Kirchdorf AG',
  5417: 'Untersiggenthal',
  5420: 'Ehrendingen',
  5423: 'Freienwil',
  5425: 'Schneisingen',
  5426: 'Lengnau AG',
  5430: 'Wettingen',
  5431: 'Wettingen Sonderd',
  5432: 'Neuenhof',
  5436: 'Würenlos',
  5442: 'Fislisbach',
  5443: 'Niederrohrdorf',
  5444: 'Künten',
  5445: 'Eggenwil',
  5452: 'Oberrohrdorf',
  5453: 'Remetschwil',
  5454: 'Bellikon',
  5462: 'Siglistorf',
  5463: 'Wislikofen',
  5464: 'Rümikon AG',
  5465: 'Mellikon',
  5466: 'Kaiserstuhl AG',
  5467: 'Fisibach',
  5502: 'Hunzenschwil',
  5503: 'Schafisheim',
  5504: 'Othmarsingen',
  5505: 'Brunegg',
  5506: 'Mägenwil',
  5507: 'Mellingen',
  5510: 'Hunzenschwil DistB',
  5512: 'Wohlenschwil',
  5522: 'Tägerig',
  5524: 'Nesselnbach',
  5525: 'Fischbach-Göslikon',
  5600: 'Lenzburg',
  5601: 'Lenzburg Sonderdst',
  5603: 'Staufen',
  5604: 'Hendschiken',
  5605: 'Dottikon',
  5606: 'Dintikon',
  5607: 'Hägglingen',
  5608: 'Stetten AG',
  5610: 'Wohlen AG',
  5611: 'Anglikon',
  5612: 'Villmergen',
  5613: 'Hilfikon',
  5614: 'Sarmenstorf',
  5615: 'Fahrwangen',
  5616: 'Meisterschwanden',
  5617: 'Tennwil',
  5618: 'Bettwil',
  5619: 'Büttikon AG',
  5620: 'Bremgarten AG',
  5621: 'Zufikon',
  5622: 'Waltenschwil',
  5623: 'Boswil',
  5624: 'Bünzen',
  5625: 'Kallern',
  5626: 'Hermetschwil-Staff',
  5627: 'Besenbüren',
  5628: 'Aristau',
  5630: 'Muri AG',
  5632: 'Buttwil',
  5634: 'Merenschwand',
  5636: 'Benzenschwil',
  5637: 'Beinwil (Freiamt)',
  5642: 'Mühlau',
  5643: 'Sins',
  5644: 'Auw',
  5645: 'Aettenschwil',
  5646: 'Abtwil AG',
  5647: 'Oberrüti',
  5702: 'Niederlenz',
  5703: 'Seon',
  5704: 'Egliswil',
  5705: 'Hallwil',
  5706: 'Boniswil',
  5707: 'Seengen',
  5708: 'Birrwil',
  5712: 'Beinwil am See',
  5722: 'Gränichen',
  5723: 'Teufenthal AG',
  5724: 'Dürrenäsch',
  5725: 'Leutwil',
  5726: 'Unterkulm',
  5727: 'Oberkulm',
  5728: 'Gontenschwil',
  5732: 'Zetzwil',
  5733: 'Leimbach AG',
  5734: 'Reinach AG',
  5736: 'Burg AG',
  5737: 'Menziken',
  5742: 'Kölliken',
  5745: 'Safenwil',
  6042: 'Dietwil',
  8109: 'Kloster Fahr',
  8905: 'Arni-Islisberg',
  8916: 'Jonen',
  8917: 'Oberlunkhofen',
  8918: 'Unterlunkhofen',
  8919: 'Rottenschwil',
  8956: 'Killwangen',
  8957: 'Spreitenbach',
  8962: 'Bergdietikon',
  8964: 'Rudolfstetten',
  8965: 'Berikon',
  8966: 'Oberwil-Lieli',
  8967: 'Widen',
}
