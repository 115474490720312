import { useCallback, useEffect, useMemo, useState } from 'react'
import { Chip } from '@eplix/ui'
import clsx from 'clsx'
import { TableRowData } from '@/components/application-table/types'

interface YearsProps {
  formattedRowData?: TableRowData[]
  filterCallback?: (filter: (data: TableRowData[]) => TableRowData[]) => void
}

export const TableYearFilter = ({
  formattedRowData,
  filterCallback,
}: YearsProps) => {
  const bookingYears = useMemo(
    () =>
      [
        ...new Set(
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          formattedRowData?.map(({ bookingDate }: any) => {
            return bookingDate?.split('.')[2]
          }),
        ),
      ].sort((a, b) => b - a),
    [formattedRowData],
  )

  const [selectedYears, setSelectedYears] = useState(bookingYears)

  const filter = useCallback(
    (rowData): TableRowData[] => {
      const filteredData = rowData?.filter((row: TableRowData) => {
        const bookingDate = row.bookingDate as string
        const _year = bookingDate?.split('.')[2]
        return selectedYears.includes(_year)
      })

      return filteredData || []
    },
    [selectedYears],
  )

  const yearClickHandler = useCallback(
    (year: number) => {
      const _selectedYears = [...selectedYears]

      _selectedYears.includes(year)
        ? _selectedYears.splice(_selectedYears.indexOf(year), 1)
        : _selectedYears.push(year)

      setSelectedYears(_selectedYears)
    },
    [selectedYears],
  )

  useEffect(() => {
    filterCallback?.(filter)
  }, [filterCallback, filter])

  return (
    <>
      {bookingYears.length > 0 && (
        <div className="flex flex-wrap !gap-4 pt-4 pb-5">
          {bookingYears.map((year, index) => {
            const isSelected = selectedYears.includes(year)
            return (
              <Chip
                as="a"
                key={index}
                variant="outlined"
                name="year"
                selected={isSelected}
                disabled={selectedYears.length === 1 && isSelected}
                onClick={() => yearClickHandler(year)}
                className={clsx(
                  'rounded w-16 h-8 pointer-events-auto',
                  isSelected && 'bg-[#3a85f8]',
                )}
              >
                {year}
              </Chip>
            )
          })}
        </div>
      )}
    </>
  )
}
