export const BS = {
  4000: 'Basel',
  4001: 'Basel',
  4002: 'Basel',
  4003: 'Basel',
  4004: 'Basel',
  4005: 'Basel',
  4007: 'Basel',
  4008: 'Basel',
  4009: 'Basel',
  4010: 'Basel',
  4011: 'Basel',
  4012: 'Basel',
  4013: 'Basel',
  4015: 'Basel',
  4016: 'Basel',
  4017: 'Basel',
  4018: 'Basel',
  4019: 'Basel',
  4020: 'Basel',
  4024: 'Basel',
  4025: 'Basel',
  4030: 'Basel',
  4031: 'Basel UniSpital',
  4032: 'Basel',
  4033: 'Basel',
  4034: 'Basel 34 Breite',
  4035: 'Basel',
  4039: 'Basel',
  4041: 'Basel',
  4051: 'Basel',
  4052: 'Basel',
  4053: 'Basel',
  4054: 'Basel',
  4055: 'Basel',
  4056: 'Basel',
  4057: 'Basel',
  4058: 'Basel',
  4059: 'Basel',
  4065: 'Basel SBB Cargo AG',
  4070: 'Basel',
  4075: 'Basel',
  4078: "Basel Reader's D.",
  4080: 'Basel',
  4081: 'Basel',
  4082: 'Basel',
  4083: 'Basel',
  4084: 'Basel',
  4085: 'Basel',
  4086: 'Basel',
  4087: 'Basel',
  4088: 'Basel',
  4089: 'Basel',
  4091: 'Basel',
  4092: 'Basel SPI AS 22',
  4093: 'Basel SPI AS 23',
  4094: 'Basel SPI AS 24',
  4095: 'Basel SPI AS 25',
  4096: 'Basel SPI AS 26',
  4125: 'Riehen',
  4126: 'Bettingen',
}
