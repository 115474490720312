import { Button, CrossIcon, IconButton, Translate } from '@eplix/ui'
import { DocumentsEnum } from '@/common/enums'
import { MobileHeader } from '@/components/layouts/mobile-header/mobile-header'
import { HTMLProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classes from './drawer-template.module.scss'

export interface DrawerTemplateProps extends HTMLProps<HTMLDivElement> {
  document?: DocumentsEnum
  mobileHeaderStyle?: string
  onCloseClick?: () => void
}

export const DrawerTemplate = ({
  document,
  mobileHeaderStyle,
  onCloseClick,
  ...props
}: DrawerTemplateProps) => {
  const { t } = useTranslation()

  const closeButton = useMemo(
    () => (
      <Button
        variant="text"
        name="registration_drawer_close"
        className={classes.mobileCloseButton}
        onClick={onCloseClick}
        startIcon={<CrossIcon iconSize={19} />}
        text="common:CardStack.Drawer.CloseButton"
      />
    ),
    [onCloseClick],
  )

  const title =
    document === DocumentsEnum.PRIVACY_POLICY
      ? 'USM-UserAccount:DocumentsPanel.privacy.policy.document'
      : 'USM-UserAccount:DocumentsPanel.terms.of.use.document'

  return (
    <>
      <MobileHeader className={mobileHeaderStyle} startAction={closeButton}>
        <Translate label={title || ''} />
      </MobileHeader>
      <div className={classes.header}>
        <div className={classes.title}>
          <Translate label={title || ''} />
        </div>
        <IconButton
          as="a"
          onClick={onCloseClick}
          icon={<CrossIcon iconSize={16} />}
          title={t('CardStack.Drawer.CloseButton', 'Close')}
          className={classes.closeButton}
        />
      </div>
      {props.children}
    </>
  )
}
