const getTextVariantFont = (isMobile: boolean) =>
  isMobile ? 'regular-200' : 'regular-300'

const getClassTextFont = (isMobile: boolean) =>
  `typo-${getTextVariantFont(isMobile)}`

const getTitleVariantFont = (isMobile: boolean) =>
  isMobile ? 'medium-300' : 'medium-400'

const getRegularTitleVariantFont = (isMobile: boolean) =>
  isMobile ? 'regular-200' : 'regular-300'

export const getFontsActions = () => {
  return {
    getTextVariantFont,
    getClassTextFont,
    getTitleVariantFont,
    getRegularTitleVariantFont,
  }
}
