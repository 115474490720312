import { FC } from 'react'
import { IconProps } from './types'

export const SortIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 4.29289C8.31658 3.90237 7.68342 3.90237 7.29289 4.29289L4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711C4.68342 9.09763 5.31658 9.09763 5.70711 8.70711L7 7.41421V15H9V7.41421L10.2929 8.70711C10.6834 9.09763 11.3166 9.09763 11.7071 8.70711C12.0976 8.31658 12.0976 7.68342 11.7071 7.29289L8.70711 4.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 16.5858L18.2929 15.2929C18.6834 14.9024 19.3166 14.9024 19.7071 15.2929C20.0976 15.6834 20.0976 16.3166 19.7071 16.7071L16.7071 19.7071C16.3166 20.0976 15.6834 20.0976 15.2929 19.7071L12.2929 16.7071C11.9024 16.3166 11.9024 15.6834 12.2929 15.2929C12.6834 14.9024 13.3166 14.9024 13.7071 15.2929L15 16.5858V9H17V16.5858Z"
        fill="currentColor"
      />
    </svg>
  )
}
