import { Translate } from '@eplix/ui'
import router from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { EmailConfirmationMessageProps } from './types'

let timerId: NodeJS.Timer
let remainingTime = 5
export const EmailConfirmationMessage = ({
  successfulMessage,
  failedMessage,
  redirectionMessage,
  changeStatus,
}: EmailConfirmationMessageProps) => {
  const [displayRemainingTime, setDisplayRemainingTime] =
    useState(remainingTime)
  const startTimer = useCallback(() => {
    if (!timerId) {
      timerId = setInterval(async () => {
        if (remainingTime > 1) {
          remainingTime--
          setDisplayRemainingTime(remainingTime)
        } else {
          await router.push('/dashboard')
        }
      }, 1000)
    }
  }, [])
  const stopTimer = () => clearInterval(timerId as unknown as number)
  useEffect(() => {
    startTimer()
    return () => stopTimer()
  }, [startTimer])
  const successfulImage = '/img/illustrations/img_290_success.png'
  const failedImage = '/img/illustrations/img_290_forward.png'
  return (
    <div className="flex flex-col mt-20 lg:mt-0 items-center">
      <div className="ml-7">
        <Translate
          variant="regular-300"
          label={changeStatus ? successfulMessage : failedMessage}
        />
        <Translate
          variant="regular-300"
          label={redirectionMessage}
          translationOptions={{ remainingTime: displayRemainingTime }}
        />
      </div>
      <img
        src={changeStatus ? successfulImage : failedImage}
        alt={changeStatus ? 'success' : 'failed'}
        className="mt-8"
      />
    </div>
  )
}
