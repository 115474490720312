import { FC } from 'react'
import { IconProps } from './types'

export const GlobeIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM9.72462 3.29011C6.16826 4.21659 3.46796 7.26362 3.05493 11H7.04998C7.27394 8.23698 8.19841 5.58471 9.72462 3.29011ZM14.2754 3.29011C15.8016 5.58471 16.7261 8.23698 16.95 11H20.9451C20.532 7.26362 17.8317 4.21659 14.2754 3.29011ZM14.9425 11C14.6916 8.28633 13.67 5.70093 12 3.54944C10.33 5.70093 9.30845 8.28633 9.05747 11H14.9425ZM9.05772 13H14.9423C14.6909 15.7127 13.6694 18.2971 12 20.4478C10.3306 18.2971 9.30913 15.7127 9.05772 13ZM7.0502 13H3.05493C3.46805 16.7371 6.16936 19.7847 9.7268 20.7105C8.1998 18.4159 7.27465 15.7634 7.0502 13ZM14.2732 20.7105C15.8002 18.4159 16.7253 15.7634 16.9498 13H20.9451C20.532 16.7371 17.8306 19.7847 14.2732 20.7105Z"
        fill="currentColor"
      />
    </svg>
  )
}
