import { currentYear, getYear, parseRequestDate } from '@/framework'
import { convertBase64ToPdf } from '@/framework/files/display-file'
import { Divider } from '@eframe-ui/react'
import { Translate } from '@eplix/ui'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DisplayRow } from './display-row/display-row'
import { TaxCertificationDocumentsProps } from './types'
import { RequestDateFormat, useDocumentFetcher } from '@/common'
import { fetchTaxCertificate } from '@/features/user-account/lib/user-account-client'
import { YearsChip } from '@/components'

export const TaxCertificateItem = ({
  taxCertificationDocuments,
  neoTaxCertificateDocuments,
  hasPensionAttestation,
}: TaxCertificationDocumentsProps) => {
  const { t } = useTranslation()
  const downloadNeoCertificate = (content: string) => {
    if (neoTaxCertificateDocuments) {
      convertBase64ToPdf(content)
    }
  }

  const { fetchDocument: fetchNewTaxCertificate } = useDocumentFetcher({
    fetcher: fetchTaxCertificate,
  })

  const [selectedYear, setSelectedYear] = useState(currentYear)

  const filterDocumentsByYear = useMemo(() => {
    return taxCertificationDocuments?.documents.filter(
      (document) => getYear(document.file.createdOn) - 1 === selectedYear,
    )
  }, [selectedYear, taxCertificationDocuments?.documents])

  const filterNeoDocumentsByYear = useMemo(() => {
    return neoTaxCertificateDocuments?.filter(
      (document) =>
        document?.creationDate &&
        getYear(document?.creationDate, RequestDateFormat.DD_MM_YYYY) - 1 ===
          selectedYear,
    )
  }, [neoTaxCertificateDocuments, selectedYear])

  const yearButtons = useMemo(() => {
    const years = new Set<number>()

    if (neoTaxCertificateDocuments?.length) {
      neoTaxCertificateDocuments.map((doc) =>
        years.add(getYear(doc.creationDate, RequestDateFormat.DD_MM_YYYY) - 1),
      )
    }
    if (taxCertificationDocuments?.documents?.length) {
      taxCertificationDocuments?.documents.map((doc) =>
        years.add(getYear(doc.file.createdOn) - 1),
      )
    }
    years.add(currentYear)

    return (
      <div className="flex flex-row p-2 mb-2 flex-wrap">
        <YearsChip
          years={[...years].sort().reverse()}
          setSelectedYearHandler={setSelectedYear}
          selectedYear={selectedYear}
        />
      </div>
    )
  }, [neoTaxCertificateDocuments, selectedYear, taxCertificationDocuments])

  const properDocumentName = hasPensionAttestation
    ? 'USM-UserAccount:DocumentsPanel.ConfirmationOfPensions'
    : 'USM-UserAccount:DocumentsPanel.PurchasePlan'

  return (
    <>
      {yearButtons}
      <div className="flex flex-col">
        <Divider className="!my-2" />
        {filterDocumentsByYear?.map((document) => {
          return (
            <DisplayRow
              key={document.file.id}
              downloadFunc={async () => {
                if (document.file.id) {
                  await fetchNewTaxCertificate(document.file.id)
                }
              }}
              creationDate={parseRequestDate(document?.file.createdOn)}
              name={t(
                'USM-UserAccount:DocumentsPanel.TaxCertificateName',
                'Pension 3a Portfolio',
                {
                  name: document.portfolioName,
                  year: getYear(document.file.createdOn) - 1,
                },
              )}
            />
          )
        })}
        {filterNeoDocumentsByYear?.map((document, index) => {
          return (
            neoTaxCertificateDocuments && (
              <DisplayRow
                key={document.employeeId - index}
                creationDate={document?.creationDate}
                downloadFunc={() => downloadNeoCertificate(document.content)}
                name={t(properDocumentName, {
                  category: document.category,
                })}
              />
            )
          )
        })}
        {!filterDocumentsByYear?.length &&
          !filterNeoDocumentsByYear?.length && (
            <DisplayRow
              name={
                <Translate
                  color="text-secondary"
                  label="USM-UserAccount:DocumentsPanel.AvailableNextJanuary"
                />
              }
            />
          )}
      </div>
    </>
  )
}
