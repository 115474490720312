import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes, ReactNode } from 'react'
import classes from './definition-list.module.scss'

export interface DefinitionListTitleProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  className?: string
  children?: ReactNode
}

export const DefinitionListTitle = forwardRef<
  HTMLElement,
  DefinitionListTitleProps
>(({ className, children, ...props }, ref) => {
  return (
    <dt
      className={clsx(classes.definitionListTitle, className)}
      {...props}
      style={{ ...props.style }}
      ref={ref}
    >
      {children}
    </dt>
  )
})
