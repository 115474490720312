import { DataModel, ListType } from './document.model'

import { Constants } from '@/common'

export const getTermsOfUseModel = (language: string): DataModel[] => {
  const en = language === 'en'
  const de = language === 'de'
  const it = language === 'it'

  const generateHref = (document: string) => {
    if (en) {
      return Constants[`${document}EN` as keyof typeof Constants]
    } else if (de) {
      return Constants[`${document}DE` as keyof typeof Constants]
    } else if (it) {
      return Constants[`${document}IT` as keyof typeof Constants]
    } else {
      return Constants[`${document}FR` as keyof typeof Constants]
    }
  }

  return [
    {
      title: 'USM-Registration:TermsOfUse.Scope.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.Scope.FirstParagraph',
        },
        {
          text: 'USM-Registration:TermsOfUse.Scope.SecondParagraph',
        },
        {
          text: 'USM-Registration:TermsOfUse.Scope.ThirdParagraph',
          href: generateHref('EplixProvider'),
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.RightOfUseAndRange.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:TermsOfUse.RightOfUseAndRange.FirstNumbering',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.Title',
                    subItems: [
                      {
                        content: [
                          {
                            list: {
                              type: ListType.ALPHABETICAL,
                              items: [
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.FirstBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.SecondBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.ThirdBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.FourthBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.FifthBullet',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                    text: 'USM-Registration:TermsOfUse.RightOfUseAndRange.SecondNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RightOfUseAndRange.ThirdNumbering',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RightOfUseAndRange.FourthNumbering',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RightOfUseAndRange.FifthNumbering',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title:
        'USM-Registration:TermsOfUse.RecipientsAndAccessRestrictions.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:TermsOfUse.RecipientsAndAccessRestrictions.FirstNumbering',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title:
        'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.FirstNumbering',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.SecondNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.SecondNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.ThirdNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.ThirdNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.FourthNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.RegistrationAndAccessToThePlatform.FourthNumbering.Text',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.DutyOfDueDiligence.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:TermsOfUse.DutyOfDueDiligence.FirstNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.DutyOfDueDiligence.FirstNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.DutyOfDueDiligence.SecondNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.DutyOfDueDiligence.SecondNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.DutyOfDueDiligence.ThirdNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.DutyOfDueDiligence.ThirdNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.DutyOfDueDiligence.FourthNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.DutyOfDueDiligence.FourthNumbering.Text',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.BlockingByTheUser.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.BlockingByTheUser.FirstParagraph',
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.InformationOnSecurity.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:TermsOfUse.InformationOnSecurity.FirstNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.InformationOnSecurity.FirstNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.Title',
                    subItems: [
                      {
                        content: [
                          {
                            list: {
                              type: ListType.ORDERED,
                              items: [
                                {
                                  text: {
                                    text: 'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.FirstSubNumbering',
                                    href: [
                                      `mailto:${Constants.EplixDataProtectionMail}`,
                                      generateHref('EplixProvider'),
                                    ],
                                  },
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.SecondSubNumbering',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.ThirdSubNumbering',
                                },
                              ],
                            },
                          },
                          {
                            list: {
                              type: ListType.UNORDERED,
                              items: [
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.FirstBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.SecondBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.ThirdBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.FourthBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.FifthBullet',
                                },
                                {
                                  title:
                                    'USM-Registration:TermsOfUse.InformationOnSecurity.SecondNumbering.SixthBullet',
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title:
        'USM-Registration:TermsOfUse.DataPrivacyAndProviderAuthorization.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    text: {
                      text: 'USM-Registration:TermsOfUse.DataPrivacyAndProviderAuthorization.FirstNumbering',
                      href: generateHref('EplixPrivacyPolicy'),
                    },
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.DataPrivacyAndProviderAuthorization.SecondNumbering',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.DataPrivacyAndProviderAuthorization.ThirdNumbering',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title:
        'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.Title',
      sections: [
        {
          content: [
            {
              list: {
                type: ListType.ORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.FirstNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.FirstNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.SecondNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.SecondNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.ThirdNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.ThirdNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.FourthNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.FourthNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.FifthNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.FifthNumbering.Text',
                  },
                  {
                    title:
                      'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.SixthNumbering.Title',
                    text: 'USM-Registration:TermsOfUse.LimitationOfWarrantyAndLiability.SixthNumbering.Text',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.ContractDuration.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.ContractDuration.FirstParagraph',
        },
        {
          text: 'USM-Registration:TermsOfUse.ContractDuration.SecondParagraph',
        },
        {
          text: 'USM-Registration:TermsOfUse.ContractDuration.ThirdParagraph',
        },
        {
          text: 'USM-Registration:TermsOfUse.ContractDuration.FourthParagraph',
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.LinksToOtherWebsites.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.LinksToOtherWebsites.FirstParagraph',
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.RightsToProgrammes.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.RightsToProgrammes.FirstParagraph',
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.ConnectionCharges.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.ConnectionCharges.FirstParagraph',
        },
      ],
    },
    {
      title:
        'USM-Registration:TermsOfUse.ApplicableLawAndPlaceOfJurisdiction.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.ApplicableLawAndPlaceOfJurisdiction.FirstParagraph',
        },
      ],
    },
    {
      title: 'USM-Registration:TermsOfUse.ContactPoint.Title',
      text: [
        {
          text: 'USM-Registration:TermsOfUse.ContactPoint.FirstParagraph',
          href: Constants.EplixWebsite,
        },
        {
          text: 'USM-Registration:TermsOfUse.ContactPoint.SecondParagraph',
        },
        {
          text: 'USM-Registration:TermsOfUse.ContactPoint.ThirdParagraph',
          href: `mailto:${Constants.EplixServiceMail}`,
        },
      ],
    },
  ]
}
