import { LocaleEnum } from '@/common/enums/Locale'
import { useId } from '@eframe-ui/react'
import {
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
  FormFieldVariant,
} from '@eplix/ui'
import classes from '@eplix/ui/inputs/text-input.module.scss'
import EventIcon from '@mui/icons-material/Event'
import IconButton from '@mui/material/IconButton'
import { InputProps } from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import clsx from 'clsx'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import frLocale from 'date-fns/locale/fr'
import itLocale from 'date-fns/locale/it'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const localeMap: { [key: string]: Locale } = {
  [LocaleEnum.DE]: deLocale,
  [LocaleEnum.FR]: frLocale,
  [LocaleEnum.EN]: enLocale,
  [LocaleEnum.IT]: itLocale,
}

type CustomInputProps = InputProps & { 'data-cy': string }
export interface DateFieldProps {
  label?: string
  className?: string
  name: string
  onChange: (value: Date) => void
  value: string | Date
  minDate?: Date
  maxDate?: Date
  helperText?: string
  error?: boolean
  disabled?: boolean
  variant?: FormFieldVariant
  helperTextClassName?: string
  fieldControlClassName?: string
}

export const DateField = forwardRef(
  (props: DateFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      label,
      name,
      className,
      value,
      onChange,
      minDate,
      maxDate,
      helperText,
      error,
      disabled,
      variant,
      helperTextClassName,
      fieldControlClassName,
    } = props
    const id = useId()
    const ariaLabelId = `label-${id}`
    const [pickerKey, setPickerKey] = useState(Math.random().toString())
    useEffect(() => {
      setPickerKey(Math.random().toString())
    }, [value])

    const [open, setOpen] = useState(false)
    const handleOpen = () => !disabled && setOpen(true)
    const handleClose = () => setOpen(false)
    const { i18n } = useTranslation()
    const locale = localeMap[i18n.language] ?? enLocale

    const handleDateChange = (date: Date) => {
      if (!date) return
      onChange(date)
    }

    const customInputProps: CustomInputProps = {
      style: {
        fontFamily: 'inherit',
        fontWeight: '300',
        fontSize: '350',
        font: 'var(--ex-typo-regular-300-font)',
        color: 'var(--ex-rgb-text-primary)',
        backgroundColor: 'var(--ex-rgb-surface-2)',
      },
      'data-cy': name,
      endAdornment: (
        <InputAdornment position="end">
          <IconButton edge="end" onClick={handleOpen}>
            <EventIcon />
          </IconButton>
        </InputAdornment>
      ),
    }

    return (
      <FormFieldControl
        variant={variant}
        className={clsx(
          disabled && classes.disabled,
          fieldControlClassName,
          'justify-between',
        )}
      >
        <FormFieldLabel
          label={label}
          htmlFor={ariaLabelId}
          className={className}
        />
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}
        >
          <DatePicker
            key={pickerKey}
            disabled={disabled}
            ref={ref}
            open={open}
            value={new Date(value)}
            minDate={minDate}
            maxDate={maxDate}
            mask="__.__.____"
            showToolbar={false}
            disableMaskedInput={false}
            closeOnSelect={true}
            onClose={handleClose}
            inputFormat="dd.MM.yyyy"
            onChange={(date) => {
              handleDateChange(date ?? new Date())
            }}
            PopperProps={{ placement: 'bottom-start' }}
            className={clsx(classes.textInput, className)}
            renderInput={(params) => (
              <TextField
                disabled={disabled}
                {...params}
                fullWidth
                error={error}
                onClick={handleOpen}
                onKeyDown={(event) => {
                  event.preventDefault()
                }}
                placeholder="dd.mm.yyyy"
                className={clsx('text-text-primary ', className)}
                InputProps={customInputProps}
              />
            )}
          />
        </LocalizationProvider>
        {helperText && (
          <FormFieldHelperText
            helperText={helperText}
            className={helperTextClassName}
            style={{ color: 'var(--ex-rgb-signal-red)' }}
          />
        )}
      </FormFieldControl>
    )
  },
)
