import clsx from 'clsx'
import {
  createElement,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactHTML,
  Children,
  cloneElement,
  ReactElement,
} from 'react'
import classes from './callout.module.scss'
import {
  CalloutContentElement,
  CalloutContentProps,
  CalloutContent,
} from './callout-content'

export type CalloutVariants = 'base' | 'secondary'

export type CalloutElement = 'button' | 'a' | 'span' | 'div' | 'ul'
export interface CalloutProps<
  TElement extends CalloutElement,
  Children = ReactElement<
    CalloutContentProps<CalloutContentElement>,
    typeof CalloutContent
  >,
> extends HTMLProps<ReactHTML[TElement]> {
  variant?: CalloutVariants
  children: Children | Children[]
  /**
   * Controls which DOM element is used to render the callout list.
   * @default "ul"
   */
  as?: TElement
}

const getVariantClass = (variant?: CalloutVariants) => {
  switch (variant) {
    case 'secondary':
      return classes.secondary
    default:
      return classes.base
  }
}

export const Callout = forwardRef(
  <TElement extends CalloutElement = 'ul'>(
    { as, className, variant, children, ...props }: CalloutProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'ul',
      {
        ...props,
        ref,
        className: clsx(classes.callout, className, getVariantClass(variant)),
      },
      Children.map(children, (child, index) =>
        cloneElement(child, {
          key: index,
          as: as === 'ul' || typeof as === 'undefined' ? 'li' : 'div',
        }),
      ),
    )
  },
)
