import { FC } from 'react'
import { IconProps } from './types'

export const ArrowRightUpIcon: FC<IconProps> = ({
  iconSize = 24,
  ...props
}) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4C6.44772 4 6 4.44772 6 5C6 5.55228 6.44772 6 7 6H16.5861L4.58594 18.0002L6.00015 19.4144L18 7.41452V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V5C20 4.44772 19.5523 4 19 4H7Z"
        fill="currentColor"
      />
    </svg>
  )
}
