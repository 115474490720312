export const ZG = {
  6300: 'Zug',
  6301: 'Zug',
  6302: 'Zug',
  6303: 'Zug',
  6304: 'Zug',
  6310: 'Zug',
  6312: 'Steinhausen',
  6313: 'Edlibach',
  6314: 'Unterägeri',
  6315: 'Morgarten',
  6317: 'Oberwil b. Zug',
  6318: 'Walchwil',
  6319: 'Allenwinden',
  6330: 'Cham 2',
  6331: 'Hünenberg',
  6332: 'Hagendorn',
  6333: 'Hünenberg See',
  6340: 'Baar',
  6341: 'Baar',
  6342: 'Baar',
  6343: 'Rotkreuz',
  6345: 'Neuheim',
  6346: 'Baar 3',
  6349: 'Baar',
}
