import { LANGUAGE_COOKIE_NAME } from '@/features/user-account/components/settings/application-language-panel/config'
import { LanguageEnum } from '@/common/enums'
import { LocaleEnum } from '@/common/enums/Locale'
import { LocaleToLanguageMap } from '@/common/mappers/language'
import {
  ChevronDownIcon,
  SelectButton,
  SelectList,
  SelectOption,
  SelectOptions,
} from '@eframe-ui/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { setCookies } from '@/framework/cookies/set-cookies'
import { TranslateSimple } from '@eplix/ui'
import { asyncCall } from '@/common/helpers/async-wrapper'

const languages = [
  { language: LanguageEnum.GERMAN, languageCode: LocaleEnum.DE },
  { language: LanguageEnum.FRENCH, languageCode: LocaleEnum.FR },
  { language: LanguageEnum.ITALIAN, languageCode: LocaleEnum.IT },
  { language: LanguageEnum.ENGLISH, languageCode: LocaleEnum.EN },
]

interface LanguageDropdownProps {
  name: string
  disabled?: boolean
}

export const LanguageDropdown = ({
  disabled = false,
}: LanguageDropdownProps) => {
  const router = useRouter()
  const { asPath, locale = LocaleEnum.DE, replace } = router
  const changeInviteRoute = asPath
    .split(`language=${router.query?.language}`)
    .join('')
  const defaultValueForInvitationLink = `${router.query?.language}_${
    router.query?.language !== 'en' ? 'CH' : 'GB'
  }`
  const [selectedLanguage, setSelectedLanguage] = useState(
    LocaleToLanguageMap[defaultValueForInvitationLink] ??
      LocaleToLanguageMap[locale],
  )
  const replaceLocalCodeRoute = useCallback(async () => {
    const localCode = languages.find(
      (lang) => lang.language === selectedLanguage,
    )?.languageCode
    if (localCode && locale !== localCode) {
      setCookies(LANGUAGE_COOKIE_NAME, localCode)
      await replace(changeInviteRoute, undefined, { locale: localCode })
    }
  }, [changeInviteRoute, locale, replace, selectedLanguage])

  useEffect(() => {
    asyncCall(replaceLocalCodeRoute)
  }, [replaceLocalCodeRoute])

  return (
    <SelectList
      value={selectedLanguage}
      onChange={setSelectedLanguage}
      className={clsx('relative w-fit inline-flex text-left')}
      disabled={disabled}
    >
      <SelectButton
        className={clsx(
          'relative inline-flex justify-end h-8',
          'text-blue hover:text-darkblue',
          'before:absolute before:-top-0.5 before:-left-0.5 before:-bottom-0.5 before:-right-0.5',
          'before:rounded-sm',
          'focus-visible:before:focus-ring',
          disabled && '!text-text-disabled',
        )}
        name="language"
      >
        {({ open }) => {
          return (
            <>
              <span data-cy={`dd_value_${selectedLanguage}`}>
                <TranslateSimple label={selectedLanguage} />
              </span>
              <ChevronDownIcon
                size={24}
                className={clsx(
                  'transition-transform',
                  open ? 'rotate-180' : 'rotate-0',
                )}
              />
            </>
          )
        }}
      </SelectButton>
      <SelectOptions className="z-above min-w-fit">
        {languages.map(({ language }, index) => (
          <SelectOption value={language} key={index}>
            <TranslateSimple label={language} />
          </SelectOption>
        ))}
      </SelectOptions>
    </SelectList>
  )
}
