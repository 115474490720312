import { Translate, TranslateSimple, Typo } from '@eplix/ui'
import { ErrorResponse } from '@/api'
import { useCallback, useEffect, useState } from 'react'
import { SmsCodeErrorsEnum } from '@/common/enums/SmsCodeErrors'
import { InlineButton } from '@eframe-ui/react'
import { ResendSmsLinkProps } from '@/components/resend-sms-link/types'
import { useResendSmsTimer } from '@/components/resend-sms-link/hooks/use-resend-sms-timer'

export const ResendSmsLink = ({
  form,
  resendSMSCode,
  callback,
  isRequestInProgress,
}: ResendSmsLinkProps) => {
  const [isResendBlocked, setIsResendBlocked] = useState(false)
  const [isTimerActive, setIsTimerActive] = useState(false)
  const { startTimer, timer } = useResendSmsTimer()

  useEffect(() => {
    if (timer) {
      setIsTimerActive(true)
    }
  }, [timer])

  const askForNewSMSCode = useCallback(() => {
    if (callback) {
      callback()
    }
    if (!timer) {
      startTimer()
      setIsTimerActive(true)
      resendSMSCode().catch((e: ErrorResponse) => {
        if (e.message === SmsCodeErrorsEnum.SMS_NOT_SENT) {
          form.setErrors({
            smsCode: 'USM-Registration:ResponseError.SMS_NOT_SENT',
          })
        }
        if (e.message === SmsCodeErrorsEnum.MAXIMUM_RESEND_REACHED) {
          form.setErrors({
            smsCode: 'common:SMSCodeResponseError.TEMPORARILY_BLOCKED',
          })
          setIsResendBlocked(true)
        }
      })
    } else {
      setIsTimerActive(false)
    }
    form.resetForm()
  }, [callback, timer, form, resendSMSCode, startTimer])

  const showTimerMessage = !!timer && isTimerActive

  return (
    <Typo
      variant="regular-200"
      className="flex w-full cursor-pointer justify-between text-right no-underline flex-row md:flex-col"
    >
      <span className="text-left self-start justify-end">
        {form?.errors?.smsCode && (
          <Translate
            label={form?.errors?.smsCode}
            color="signal-red"
            className="cursor-default mb-1"
          />
        )}
      </span>
      {!isResendBlocked && !showTimerMessage && (
        <InlineButton
          type="button"
          className="typo-regular-200 max-h-[20px] !justify-end"
          disabled={isRequestInProgress}
          onClick={askForNewSMSCode}
          data-cy="btn_resend_smsCode"
        >
          <TranslateSimple label="common:ResendSMSCodeHelperText" />
        </InlineButton>
      )}
      {showTimerMessage && !isResendBlocked && (
        <Translate
          color="outline"
          className="pointer-none"
          label="common:ResendSMSCodeTimerMessage"
          translationOptions={{ time: timer }}
        />
      )}
    </Typo>
  )
}
