export const MONTHS = [
  'common:Months.January',
  'common:Months.February',
  'common:Months.March',
  'common:Months.April',
  'common:Months.May',
  'common:Months.June',
  'common:Months.July',
  'common:Months.August',
  'common:Months.September',
  'common:Months.October',
  'common:Months.November',
  'common:Months.December',
]

export const MONTHS_ABBREVIATION = [
  'common:MonthsAbbreviation.Jan',
  'common:MonthsAbbreviation.Feb',
  'common:MonthsAbbreviation.March',
  'common:MonthsAbbreviation.April',
  'common:MonthsAbbreviation.May',
  'common:MonthsAbbreviation.June',
  'common:MonthsAbbreviation.July',
  'common:MonthsAbbreviation.Aug',
  'common:MonthsAbbreviation.Sept',
  'common:MonthsAbbreviation.Oct',
  'common:MonthsAbbreviation.Nov',
  'common:MonthsAbbreviation.Dec',
]
