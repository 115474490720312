import { FC } from 'react'
import { IconProps } from './types'

export const EditBigIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 ${iconSize} ${iconSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1079 2.81091C19.0267 1.72974 17.2738 1.72969 16.1926 2.81081L14.0765 4.92671L19.0731 9.92333L21.189 7.80763C22.2703 6.72643 22.2703 4.97337 21.1891 3.89212L20.1079 2.81091ZM17.4976 4.11601C17.8581 3.75564 18.4424 3.75565 18.8027 4.11604L19.884 5.19726C20.2444 5.55767 20.2444 6.14203 19.8839 6.50243L19.0731 7.31312L16.6868 4.92677L17.4976 4.11601Z"
        fill={'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0745 5.92906L2.27092 16.7326C2.27087 16.7326 2.2703 16.7332 2.92287 17.3858L2.27092 16.7326C2.09785 16.9057 2 17.141 2 17.3858V20.6158C2 21.3804 2.61978 22.0001 3.38431 22.0001H6.61436C6.85912 22.0001 7.09385 21.9029 7.26692 21.7298L6.61436 21.0773C7.26692 21.7298 7.26688 21.7299 7.26692 21.7298L18.0711 10.9257L13.0745 5.92906ZM3.84574 20.1544V17.768L13.0745 8.53933L15.4608 10.9257L6.23209 20.1544H3.84574Z"
        fill={'currentColor'}
      />
    </svg>
  )
}
