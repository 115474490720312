import {
  completeResendSmsRequest,
  logout,
  resetAuthCurrentUser,
} from '@/framework/auth'
import { useMutation } from '@/framework/common'
import { GlobalValueKey } from '@/framework'

export const useResendSmsRequest = (url?: string) => {
  return () => completeResendSmsRequest(url)
}

export const useLogout = () => {
  return useMutation(logout, GlobalValueKey.CURRENT_USER, {
    emitLoadingAfter: 100,
  })
}

export const useResetCurrentUser = () => {
  return () => resetAuthCurrentUser()
}
