export const VS = {
  1868: 'Collombey',
  1869: 'Massongex',
  1870: 'Monthey',
  1871: 'Choëx',
  1872: 'Troistorrents',
  1873: "Val-d'Illiez",
  1874: 'Champéry',
  1875: 'Morgins',
  1890: 'St-Maurice',
  1891: 'Vérossaz',
  1893: 'Muraz (Collombey)',
  1895: 'Vionnaz',
  1896: 'Vouvry',
  1897: 'Les Evouettes',
  1898: 'St-Gingolph',
  1899: 'Torgon',
  1902: 'Evionnaz',
  1903: 'Collonges',
  1904: 'Vernayaz',
  1905: 'Dorénaz',
  1906: 'Charrat',
  1907: 'Saxon',
  1908: 'Riddes',
  1911: 'Ovronnaz',
  1912: 'Leytron',
  1913: 'Saillon',
  1914: 'Isérables',
  1918: 'La Tzoumaz',
  1919: 'Martigny Mutuel',
  1920: 'Martigny',
  1921: 'Martigny-Croix',
  1922: 'Salvan',
  1923: 'Le Trétien',
  1925: 'Le Châtelard VS',
  1926: 'Fully',
  1927: 'Chemin',
  1928: 'Ravoire',
  1929: 'Trient',
  1932: 'Bovernier',
  1933: 'Sembrancher',
  1934: 'Le Châble VS',
  1936: 'Verbier',
  1937: 'Orsières',
  1938: 'Champex-Lac',
  1941: 'Vollèges',
  1942: 'Levron',
  1943: 'Praz-de-Fort',
  1944: 'La Fouly VS',
  1945: 'Liddes',
  1946: 'Bourg-St-Pierre',
  1947: 'Versegères',
  1948: 'Lourtier',
  1950: 'Sion',
  1951: 'Sion',
  1955: 'Chamoson',
  1957: 'Ardon',
  1958: 'St-Léonard',
  1961: 'Vernamiège',
  1962: 'Pont-de-la-Morge',
  1963: 'Vétroz',
  1964: 'Conthey',
  1965: 'Savièse',
  1966: 'Ayent',
  1967: 'Bramois',
  1968: 'Mase',
  1969: 'St-Martin VS',
  1971: 'Grimisuat',
  1972: 'Anzère',
  1973: 'Nax',
  1974: 'Arbaz',
  1975: 'St-Séverin',
  1976: 'Erde',
  1977: 'Icogne',
  1978: 'Lens',
  1981: 'Vex',
  1982: 'Euseigne',
  1983: 'Evolène',
  1984: 'Les Haudères',
  1985: 'La Sage',
  1986: 'Arolla',
  1987: 'Hérémence',
  1988: 'Les Collons',
  1991: 'Salins',
  1992: 'Les Agettes',
  1993: 'Veysonnaz',
  1994: 'Aproz (Nendaz)',
  1996: 'Fey (Nendaz)',
  1997: 'Siviez (Nendaz)',
  3801: 'Jungfraujoch',
  3900: 'Brig',
  3901: 'Rothwald',
  3902: 'Glis',
  3903: 'Birgisch',
  3904: 'Naters',
  3905: 'Saas-Almagell',
  3906: 'Saas-Fee',
  3907: 'Simplon Hospiz',
  3908: 'Saas-Balen',
  3910: 'Saas-Grund',
  3911: 'Ried-Brig',
  3912: 'Termen',
  3913: 'Rosswald',
  3914: 'Blatten b. Naters',
  3916: 'Ferden',
  3917: 'Goppenstein',
  3918: 'Wiler (Lötschen)',
  3919: 'Blatten(Lötschen)',
  3920: 'Zermatt',
  3922: 'Eisten',
  3923: 'Törbel',
  3924: 'St. Niklaus VS',
  3925: 'Grächen',
  3926: 'Embd',
  3927: 'Herbriggen',
  3928: 'Randa',
  3929: 'Täsch',
  3930: 'Visp',
  3931: 'Lalden',
  3932: 'Visperterminen',
  3933: 'Staldenried',
  3934: 'Zeneggen',
  3935: 'Bürchen',
  3937: 'Baltschieder',
  3938: 'Ausserberg',
  3939: 'Eggerberg',
  3940: 'Steg VS',
  3942: 'St. German',
  3943: 'Eischoll',
  3944: 'Unterbäch VS',
  3945: 'Gampel',
  3946: 'Gruben',
  3947: 'Ergisch',
  3948: 'Unterems',
  3949: 'Hohtenn',
  3951: 'Agarn',
  3952: 'Susten',
  3953: 'Varen',
  3954: 'Leukerbad',
  3955: 'Albinen',
  3956: 'Guttet-Feschel',
  3957: 'Erschmatt',
  3960: 'Sierre',
  3961: 'Vissoie',
  3963: 'Montana',
  3965: 'Chippis',
  3966: 'Chalais',
  3967: 'Vercorin',
  3968: 'Veyras',
  3970: 'Salgesch',
  3971: 'Chermignon',
  3972: 'Miège',
  3973: 'Venthône',
  3974: 'Mollens VS',
  3975: 'Randogne',
  3976: 'Noës',
  3977: 'Granges VS',
  3978: 'Flanthey',
  3979: 'Grône',
  3982: 'Bitsch',
  3983: 'Mörel',
  3984: 'Fiesch',
  3985: 'Geschinen',
  3986: 'Ried-Mörel',
  3987: 'Riederalp',
  3988: 'Obergesteln',
  3989: 'Niederwald',
  3991: 'Betten',
  3992: 'Bettmeralp',
  3993: 'Grengiols',
  3994: 'Lax',
  3995: 'Ernen',
  3996: 'Binn',
  3997: 'Bellwald',
  3998: 'Gluringen',
  3999: 'Oberwald',
}
