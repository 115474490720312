import clsx from 'clsx'
import { forwardRef } from 'react'
import { HTMLProps, ForwardedRef } from 'react'

import classes from './menu-list.module.scss'

export type MenuItemProps = HTMLProps<HTMLLIElement> & { active: boolean }

export const MenuItem = forwardRef(
  (
    {
      active,
      selected,
      disabled,
      className,
      children,
      ...props
    }: MenuItemProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    return (
      <li
        role="menuitem"
        className={clsx(
          active && classes.active,
          selected && classes.selected,
          disabled && classes.disabled,
          classes.menuItem,
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </li>
    )
  },
)
