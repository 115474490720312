import { CrossIcon, SearchIcon, TextField } from '@eplix/ui'
import { AutocompleteRenderInputParams } from '@mui/material'

interface FilterDropdownProps {
  value: string
  label?: string
  placeholder?: string
  error: string | undefined
  formFieldClassName?: string
  resetFieldValue: () => void
  params: AutocompleteRenderInputParams
}

export const FilterDropdownInput = ({
  value,
  label,
  error,
  params,
  resetFieldValue,
  formFieldClassName,
  placeholder = 'common:Field.Search',
}: FilterDropdownProps) => {
  return (
    <div ref={params.InputProps.ref}>
      <TextField
        {...params.inputProps}
        value={value}
        formFieldClassName={formFieldClassName}
        label={label}
        placeholder={placeholder}
        error={!!error}
        helperText={error}
        endAdornment={
          value ? (
            <CrossIcon className="cursor-pointer" onClick={resetFieldValue} />
          ) : (
            <SearchIcon />
          )
        }
      ></TextField>
    </div>
  )
}
