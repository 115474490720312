import { Ref } from 'react'

const SliderPointerValue = ({
  value,
  color,
  innerRef,
}: {
  value: number
  color: string
  innerRef: Ref<HTMLDivElement>
}) => {
  if (!value) {
    return null
  }

  return (
    <div
      ref={innerRef}
      className={`absolute pointer-events-none`}
      style={{
        left: `${value}%`,
        zIndex: 1,
        top: '18px',
        width: 0,
        height: 0,
        marginLeft: '-6px',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: `6px solid ${color}`,
      }}
    ></div>
  )
}

export default SliderPointerValue
