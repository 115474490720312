import { WizardProps, WizardStep } from '@/components/wizard/hook/use-wizard'

export enum PortfolioEditScreensWizardEnum {
  EDIT_STRATEGY = 0,
  NEW_STRATEGY = 1,
  RECOMMENDED_STRATEGY = 2,
  PENDING_ORDERS = 3,
  TERMS_AND_CONDITION = 4,
}

export enum PortfolioWizardFormsEnum {
  EDIT_STRATEGY = 'START_SCREEN',
  NEW_STRATEGY = 'BLANK_STRATEGY',
  PENDING_ORDERS_EDIT = 'PENDING_ORDERS_EDIT',
  RECOMMENDED_STRATEGY = 'RECOMMENDED_STRATEGY',
  PENDING_ORDERS_OPENING = 'PENDING_ORDERS_OPENING',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  FINISH_PORTFOLIO_EDIT = 'FINISH_PORTFOLIO_EDIT',
  RISK_PROFILE_FORM = 'RISK_PROFILE_FORM',
  DEFINE_STRATEGY_FORM = 'DEFINE_STRATEGY_FORM',
  START_PORTFOLIO_OPENING = 'START_PORTFOLIO_OPENING',
}

export enum PortfolioFinishOpeningWizardEnum {
  FINISH_SCREEN = 0,
  TRANSFER_ASSETS = 1,
  DEPOSIT_NOW = 2,
}

interface Title {
  label: string
  translationOptions?: Record<string, string>
}

export interface WizardInputProps {
  steps?: Array<WizardStep>
  children: Array<JSX.Element | null | undefined>
  skipDisplayIndex?: Array<number>
  showEPlixLogo?: boolean
  showSteps?: boolean
  onWizardStepChange?: (step: PortfolioWizardFormsEnum) => void
  title?: Title
  label?: Title
  wizardWrapClassName?: string
  formWrapperClassName?: string
  logoWrapperClassName?: string
  logoContainerClassName?: string
  headerWrapperClass?: string
  currentStepCallback?: (currentStep: number) => number
  totalStepsCallback?: (totalSteps: number) => number
  mainLogo?: JSX.Element | null
  hideHeaderIndex?: Array<number>
  showBrandedPage?: boolean
  startFromIndex?: number
}

export type WizardHandler = {
  getWizardProps: () => WizardProps
}
