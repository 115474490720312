import { ActionResponse, ErrorResponse, PendingOrder } from '@/api'
import { useSWRConfig } from 'swr'
import { useMutation } from '@/framework/common'
import { CancelPendingOrderFormRequest } from '../../lib/types'
import { cancelPendingOrder } from '../../lib/portfolio-api-client'

export const pendingOrdersKey = 'pendingOrders'

export const useSetPendingOrders = () => {
  const { mutate } = useSWRConfig()

  return (pendingOrders: Array<PendingOrder>) =>
    mutate(pendingOrdersKey, pendingOrders, true)
}

export const useCancelPendingOrder = () => {
  return useMutation<
    ActionResponse | ErrorResponse,
    CancelPendingOrderFormRequest
  >(cancelPendingOrder)
}
