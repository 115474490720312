/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CustomerPersonalData,
  DocumentGroup,
  DocumentsToBeAccepted,
} from '@/api'
import { useDataFetcher } from '@/common'
import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'
import { AuthLayout, LanguageDropdown, ZendeskButton } from '@/components'
import { AddressPage } from '@/features/address-page/address-page'
import { getPersonalDocumentsOverview } from '@/features/user-account/lib/user-account-client'
import { UserPersonalData } from '@/features/user-personal-data/user-personal-data'
import { EplixLogo } from '@eplix/ui/logos/eplix-logo'
import { useEffect, useState } from 'react'
import { ExpiredRefreshTokenError } from '../../types'
import { LegalDocuments } from '../legal-documents/legal-documents'
import { LoginForm } from '../login-form/login-form'
import { ValidateForm } from '../validate-form/validate-form'

export const LoginScreen = ({
  error,
  userStatus,
  personalData,
  personalDocuments,
  documentsToBeAccepted,
}: {
  error: ExpiredRefreshTokenError
  userStatus: UserSessionStatusEnum
  personalData: CustomerPersonalData
  personalDocuments: DocumentGroup[]
  documentsToBeAccepted: DocumentsToBeAccepted
}) => {
  const [enabled, setEnabled] = useState(false)
  const [isLoginInProgress, setIsLoginInProgress] = useState(false)
  const [userPersonalData, setUserPersonalData] = useState(personalData)

  const [userStatusState, setUserStatusState] = useState(
    userStatus ?? UserSessionStatusEnum.ANONYMOUS,
  )

  const [documentsToAccept, setDocumentsToAccept] = useState(
    documentsToBeAccepted,
  )

  const { result: personalDocsInfo, isLoading } = useDataFetcher({
    fetcher: getPersonalDocumentsOverview,
    enabled: enabled,
  })

  useEffect(() => {
    if (personalDocsInfo) {
      setEnabled(false)
    }
  }, [personalDocsInfo])

  return (
    <>
      <AuthLayout>
        <div className="w-full">
          <EplixLogo className="block mb-8 w-[45%] xl:w-1/2" />
          {(userStatusState === UserSessionStatusEnum.ANONYMOUS ||
            userStatusState === UserSessionStatusEnum.REGISTERED) && (
            <LoginForm
              error={error}
              setUserStatusState={setUserStatusState}
              setIsLoginInProgress={setIsLoginInProgress}
            />
          )}

          {userStatusState === UserSessionStatusEnum.AUTHENTICATING && (
            <ValidateForm
              isLoading={isLoading}
              setEnabled={setEnabled}
              personalDocuments={personalDocsInfo}
              setPersonalData={setUserPersonalData}
              setUserStatusState={setUserStatusState}
              setIsLoginInProgress={setIsLoginInProgress}
              setDocumentsToAccept={setDocumentsToAccept}
            />
          )}

          {userStatusState ===
            UserSessionStatusEnum.AUTHENTICATED_WITHOUT_ACCEPTED_DOCUMENTS && (
            <LegalDocuments
              setEnabled={setEnabled}
              personalData={userPersonalData}
              userStatusState={userStatusState}
              documentsToAccept={documentsToAccept}
              setUserStatusState={setUserStatusState}
              setIsLoginInProgress={setIsLoginInProgress}
              personalDocuments={personalDocsInfo ?? personalDocuments}
            />
          )}

          {userStatusState === UserSessionStatusEnum.OFFLINE && (
            <UserPersonalData
              personalData={userPersonalData}
              setIsLoginInProgress={setIsLoginInProgress}
            />
          )}

          {(userStatusState === UserSessionStatusEnum.WITHOUT_ADDRESS ||
            userStatusState === UserSessionStatusEnum.WITHOUT_PERMIT) && (
            <AddressPage
              isLoginFlow
              information={userPersonalData?.profile}
              setIsLoginInProgress={setIsLoginInProgress}
            />
          )}

          <div className="flex justify-between mt-4">
            <ZendeskButton isCollapsed={false} />
            <LanguageDropdown disabled={isLoginInProgress} name="language" />
          </div>
        </div>
      </AuthLayout>
    </>
  )
}
