import classes from '../tooltip.module.scss'
import { Placement } from '../tooltip-content'

export const getPlacementClass = (placement: Placement | null) => {
  switch (placement) {
    case 'top':
      return classes.tooltipSpikeTop
    case 'bottom':
      return classes.tooltipSpikeBottom
    case 'left':
      return classes.tooltipSpikeLeft
    case 'right':
      return classes.tooltipSpikeRight
    default:
      return classes.tooltipSpikeTop
  }
}
