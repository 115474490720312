import { SelectInputOption, Typo } from '@eplix/ui'
import classes from '@/components/phone-number/phone-number-field.module.scss'
import { AreaCodeProps } from '@/components/phone-number/phone-number-field'
import { useTranslation } from 'react-i18next'
import { PhoneFieldAreaCodesProps } from '@/components/phone-number/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
const alphabeticallySortedAreaCodes = (
  unsortedAreaCodes: AreaCodeProps[],
  t: any,
) => {
  const unsortedAreaCodesCopy = [...unsortedAreaCodes]
  const neighbourAreaCodes = unsortedAreaCodesCopy.splice(0, 6)

  const sortedOtherAreaCodes = unsortedAreaCodesCopy.sort((a, b) =>
    t(`country:${a.name}`).localeCompare(t(`country:${b.name}`)),
  )

  return neighbourAreaCodes.concat(sortedOtherAreaCodes)
}

export const PhoneFieldAreaCodes = ({ areaCode }: PhoneFieldAreaCodesProps) => {
  const { t } = useTranslation()
  const sortedAreaCode = alphabeticallySortedAreaCodes(areaCode, t)
  return (
    <>
      {sortedAreaCode.map((country, index) => (
        <SelectInputOption
          key={index}
          optionValue={country.name}
          dataCy={country.name}
        >
          {country.flag != undefined ? country.flag : null}
          <span className={classes.phoneOptionsText}>
            <Typo as="p" variant="regular-300" color="text-primary">
              {t(`country:${country.name}`, country.name)}
            </Typo>
            <Typo as="p" variant="regular-300" color="text-secondary">
              {country.code}
            </Typo>
          </span>
        </SelectInputOption>
      ))}
    </>
  )
}
