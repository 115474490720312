import { WizardProps } from '../hook/use-wizard'
import { memo, useRef } from 'react'
import clsx from 'clsx'
import { useHandleEnterSubmit } from '@/common'
import { Button, LoadingButton } from '@eplix/ui'

type Action = 'submit' | 'next'

interface WizardNavigationProps {
  disableNext?: boolean
  wizardProps: WizardProps
  type?: Action
  isBackVisible?: boolean
  isNextVisible?: boolean
  children?: Array<JSX.Element> | JSX.Element
  wrapperCustomStyle?: string
  isLoading?: boolean
  backCallback?: () => void
  submitCallback?: () => void
}

export const WizardNavigation = memo(
  ({
    disableNext = false,
    wizardProps,
    type,
    wrapperCustomStyle,
    isBackVisible = true,
    isNextVisible = true,
    children,
    isLoading = false,
    backCallback,
    submitCallback,
  }: WizardNavigationProps) => {
    const buttonRef = useRef(null)
    useHandleEnterSubmit(buttonRef)

    const wizardNavigationClass = `flex flex-row gap-4 bottom-0 right-0 border-t border-t-outline bg-surface-1 xs:left-0 pt-3 pr-6 pb-5 pl-6 gap-4 lg:justify-end pt-4 pr-6 pb-4 pl-6`
    const onClickHandler = () => {
      type === 'next'
        ? wizardProps.handleNext()
        : submitCallback
        ? submitCallback()
        : undefined
    }
    return (
      <div className={clsx(wizardNavigationClass, wrapperCustomStyle)}>
        {isBackVisible && (
          <Button
            variant="text"
            name="wizard_navigation_back"
            onClick={backCallback ?? wizardProps.handleBack}
            disabled={wizardProps.state.index == 0 || isLoading}
            className={
              'min-w-fit w-full h-12 px-8 xs:flex justify-center lg:w-fit max-w-fit m-0 self-end'
            }
            text="USM-Wizard:WizardNavigation.BackButton"
          />
        )}
        {children}
        {isNextVisible && (
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            disabled={disableNext}
            type={type === 'submit' ? 'submit' : 'button'}
            onClick={onClickHandler}
            ref={buttonRef}
            className={
              'w-full xs:flex justify-center lg:w-fit max-w-fit m-0 self-end'
            }
            text="USM-Wizard:WizardNavigation.NextButton"
            name="wizard_navigation_next"
          />
        )}
      </div>
    )
  },
)
