import { FC } from 'react'
import { IconProps } from './types'

export const CheckListIcon: FC<IconProps> = ({ iconSize = 16, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 16 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#C1C4C7" />
      {/* --ex-rgb-light-outline */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3139 5.01909C12.4875 5.19266 12.4875 5.47406 12.3139 5.64763L6.98056 10.981C6.80699 11.1545 6.52558 11.1545 6.35202 10.981L3.68535 8.3143C3.51178 8.14073 3.51178 7.85932 3.68535 7.68576C3.85892 7.51219 4.14032 7.51219 4.31389 7.68576L6.66629 10.0382L11.6854 5.01909C11.8589 4.84552 12.1403 4.84552 12.3139 5.01909Z"
        fill="white"
      />
    </svg>
  )
}
