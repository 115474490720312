import { AreaCodeProps } from '@/components'
import { CountryFlag } from '@eplix/ui'

export const areaCodeData: AreaCodeProps[] = [
  { flag: <CountryFlag country="ch" />, name: 'SWITZERLAND', code: '+41' },
  { flag: <CountryFlag country="de" />, name: 'GERMANY', code: '+49' },
  { flag: <CountryFlag country="fr" />, name: 'FRANCE', code: '+33' },
  { flag: <CountryFlag country="it" />, name: 'ITALY', code: '+39' },
  { flag: <CountryFlag country="at" />, name: 'AUSTRIA', code: '+43' },
  { flag: <CountryFlag country="li" />, name: 'LIECHTENSTEIN', code: '+423' },
  { flag: <CountryFlag country="af" />, name: 'AFGHANISTAN', code: '+91' },
  { flag: <CountryFlag country="al" />, name: 'ALBANIA', code: '+355' },
  { flag: <CountryFlag country="dz" />, name: 'ALGERIA', code: '+213' },
  {
    flag: <CountryFlag country="as" />,
    name: 'AMERICAN_SAMOA',
    code: '+1-684',
  },
  { flag: <CountryFlag country="ad" />, name: 'ANDORRA', code: '+376' },
  { flag: <CountryFlag country="ao" />, name: 'ANGOLA', code: '+244' },
  { flag: <CountryFlag country="ai" />, name: 'ANGUILLA', code: '+1-264' },
  // { flag:	<CountryFlag country="aq"/>	, name:	"ANTARCTICA"	,	code:	"+672"	},
  {
    flag: <CountryFlag country="ag" />,
    name: 'ANTIGUA_AND_BARBUDA',
    code: '+1-268',
  },
  { flag: <CountryFlag country="ar" />, name: 'ARGENTINA', code: '+54' },
  { flag: <CountryFlag country="am" />, name: 'ARMENIA', code: '+374' },
  { flag: <CountryFlag country="aw" />, name: 'ARUBA', code: '+297' },
  { flag: <CountryFlag country="au" />, name: 'AUSTRALIA', code: '+61' },
  { flag: <CountryFlag country="az" />, name: 'AZERBAIJAN', code: '+994' },
  { flag: <CountryFlag country="bs" />, name: 'BAHAMAS', code: '+1-242' },
  { flag: <CountryFlag country="bh" />, name: 'BAHRAIN', code: '+973' },
  { flag: <CountryFlag country="bd" />, name: 'BANGLADESH', code: '+880' },
  { flag: <CountryFlag country="bb" />, name: 'BARBADOS', code: '+1-246' },
  { flag: <CountryFlag country="by" />, name: 'BELARUS', code: '+375' },
  { flag: <CountryFlag country="be" />, name: 'BELGIUM', code: '+32' },
  { flag: <CountryFlag country="bz" />, name: 'BELIZE', code: '+501' },
  { flag: <CountryFlag country="bj" />, name: 'BENIN', code: '+229' },
  { flag: <CountryFlag country="bm" />, name: 'BERMUDA', code: '+1-441' },
  { flag: <CountryFlag country="bt" />, name: 'BHUTAN', code: '+975' },
  { flag: <CountryFlag country="bo" />, name: 'BOLIVIA', code: '+591' },
  {
    flag: <CountryFlag country="ba" />,
    name: 'BOSNIA_AND_HERZEGOVINA',
    code: '+387',
  },
  { flag: <CountryFlag country="bw" />, name: 'BOTSWANA', code: '+267' },
  // { flag:	<CountryFlag country="bv"/>	, name:	"BOUVET_ISLAND"	,	code:	"+47"	},
  { flag: <CountryFlag country="br" />, name: 'BRAZIL', code: '+55' },
  // { flag:	<CountryFlag country="io"/>	, name:	"BRITISH_INDIAN_OCEAN_TERRITORY"	,	code:	"+246"	},
  {
    flag: <CountryFlag country="bn" />,
    name: 'BRUNEI_DARUSSALAM',
    code: '+673',
  },
  { flag: <CountryFlag country="bg" />, name: 'BULGARIA', code: '+359' },
  { flag: <CountryFlag country="bf" />, name: 'BURKINA_FASO', code: '+226' },
  { flag: <CountryFlag country="bi" />, name: 'BURUNDI', code: '+257' },
  { flag: <CountryFlag country="kh" />, name: 'CAMBODIA', code: '+855' },
  { flag: <CountryFlag country="cm" />, name: 'CAMEROON', code: '+237' },
  { flag: <CountryFlag country="ca" />, name: 'CANADA', code: '+1' },
  { flag: <CountryFlag country="cv" />, name: 'CAPE_VERDE', code: '+238' },
  {
    flag: <CountryFlag country="ky" />,
    name: 'CAYMAN_ISLANDS',
    code: '+1-345',
  },
  {
    flag: <CountryFlag country="cf" />,
    name: 'CENTRAL_AFRICAN_REPUBLIC',
    code: '+236',
  },
  { flag: <CountryFlag country="td" />, name: 'CHAD', code: '+235' },
  { flag: <CountryFlag country="cl" />, name: 'CHILE', code: '+56' },
  { flag: <CountryFlag country="cn" />, name: 'CHINA', code: '+86' },
  { flag: <CountryFlag country="cx" />, name: 'CHRISTMAS_ISLAND', code: '+61' },
  {
    flag: <CountryFlag country="cc" />,
    name: 'COCOS_KEELING_ISLANDS',
    code: '+61',
  },
  { flag: <CountryFlag country="co" />, name: 'COLOMBIA', code: '+57' },
  { flag: <CountryFlag country="km" />, name: 'COMOROS', code: '+269' },
  { flag: <CountryFlag country="cg" />, name: 'CONGO', code: '+242' },
  {
    flag: <CountryFlag country="cd" />,
    name: 'CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE',
    code: '+243',
  },
  { flag: <CountryFlag country="ck" />, name: 'COOK_ISLANDS', code: '+682' },
  { flag: <CountryFlag country="cr" />, name: 'COSTA_RICA', code: '+506' },
  { flag: <CountryFlag country="ci" />, name: 'COTE_D_IVOIRE', code: '+225' },
  { flag: <CountryFlag country="hr" />, name: 'CROATIA', code: '+385' },
  { flag: <CountryFlag country="cu" />, name: 'CUBA', code: '+53' },
  { flag: <CountryFlag country="cy" />, name: 'CYPRUS', code: '+357' },
  { flag: <CountryFlag country="cz" />, name: 'CZECH_REPUBLIC', code: '+420' },
  { flag: <CountryFlag country="dk" />, name: 'DENMARK', code: '+45' },
  { flag: <CountryFlag country="dj" />, name: 'DJIBOUTI', code: '+253' },
  { flag: <CountryFlag country="dm" />, name: 'DOMINICA', code: '+1-767' },
  {
    flag: <CountryFlag country="do" />,
    name: 'DOMINICAN_REPUBLIC',
    code: '+1-809',
  },
  {
    flag: <CountryFlag country="do1" />,
    name: 'DOMINICAN_REPUBLIC1',
    code: '+1-829',
  },
  {
    flag: <CountryFlag country="do2" />,
    name: 'DOMINICAN_REPUBLIC2',
    code: '+1-849',
  },
  { flag: <CountryFlag country="ec" />, name: 'ECUADOR', code: '+593' },
  { flag: <CountryFlag country="eg" />, name: 'EGYPT', code: '+20' },
  { flag: <CountryFlag country="sv" />, name: 'EL_SALVADOR', code: '+503' },
  {
    flag: <CountryFlag country="gq" />,
    name: 'EQUATORIAL_GUINEA',
    code: '+240',
  },
  { flag: <CountryFlag country="er" />, name: 'ERITREA', code: '+291' },
  { flag: <CountryFlag country="ee" />, name: 'ESTONIA', code: '+372' },
  { flag: <CountryFlag country="et" />, name: 'ETHIOPIA', code: '+251' },
  {
    flag: <CountryFlag country="fk" />,
    name: 'FALKLAND_ISLANDS_MALVINAS',
    code: '+500',
  },
  { flag: <CountryFlag country="fo" />, name: 'FAROE_ISLANDS', code: '+298' },
  { flag: <CountryFlag country="fj" />, name: 'FIJI', code: '+679' },
  { flag: <CountryFlag country="fi" />, name: 'FINLAND', code: '+358' },
  { flag: <CountryFlag country="gf" />, name: 'FRENCH_GUIANA', code: '+594' },
  {
    flag: <CountryFlag country="pf" />,
    name: 'FRENCH_POLYNESIA',
    code: '+689',
  },
  // { flag:	<CountryFlag country="tf"/>	, name:	"FRENCH_SOUTHERN_TERRITORIES"	,	code:	"+262"	},
  { flag: <CountryFlag country="ga" />, name: 'GABON', code: '+241' },
  { flag: <CountryFlag country="gm" />, name: 'GAMBIA', code: '+220' },
  { flag: <CountryFlag country="ge" />, name: 'GEORGIA', code: '+995' },
  { flag: <CountryFlag country="gh" />, name: 'GHANA', code: '+233' },
  { flag: <CountryFlag country="gi" />, name: 'GIBRALTAR', code: '+350' },
  { flag: <CountryFlag country="gr" />, name: 'GREECE', code: '+30' },
  { flag: <CountryFlag country="gl" />, name: 'GREENLAND', code: '+299' },
  { flag: <CountryFlag country="gd" />, name: 'GRENADA', code: '+1-473' },
  { flag: <CountryFlag country="gp" />, name: 'GUADELOUPE', code: '+590' },
  { flag: <CountryFlag country="gu" />, name: 'GUAM', code: '+1-671' },
  { flag: <CountryFlag country="gt" />, name: 'GUATEMALA', code: '+502' },
  { flag: <CountryFlag country="gn" />, name: 'GUINEA', code: '+224' },
  { flag: <CountryFlag country="gw" />, name: 'GUINEA_BISSAU', code: '+245' },
  { flag: <CountryFlag country="gy" />, name: 'GUYANA', code: '+592' },
  { flag: <CountryFlag country="ht" />, name: 'HAITI', code: '+509' },
  // { flag:	<CountryFlag country="hm"/>	, name:	"HEARD_ISLAND_AND_MCDONALD_ISLANDS"	,	code:	"+672"	},
  {
    flag: <CountryFlag country="va" />,
    name: 'HOLY_SEE_VATICAN_CITY_STATE',
    code: '+39',
  },
  { flag: <CountryFlag country="hn" />, name: 'HONDURAS', code: '+504' },
  { flag: <CountryFlag country="hk" />, name: 'HONG_KONG', code: '+852' },
  { flag: <CountryFlag country="hu" />, name: 'HUNGARY', code: '+36' },
  { flag: <CountryFlag country="is" />, name: 'ICELAND', code: '+354' },
  { flag: <CountryFlag country="in" />, name: 'INDIA', code: '+91' },
  { flag: <CountryFlag country="id" />, name: 'INDONESIA', code: '+62' },
  {
    flag: <CountryFlag country="ir" />,
    name: 'IRAN_ISLAMIC_REPUBLIC_OF',
    code: '+98',
  },
  { flag: <CountryFlag country="iq" />, name: 'IRAQ', code: '+964' },
  { flag: <CountryFlag country="ie" />, name: 'IRELAND', code: '+353' },
  { flag: <CountryFlag country="il" />, name: 'ISRAEL', code: '+972' },
  { flag: <CountryFlag country="jm" />, name: 'JAMAICA', code: '+1-876' },
  { flag: <CountryFlag country="jp" />, name: 'JAPAN', code: '+81' },
  { flag: <CountryFlag country="jo" />, name: 'JORDAN', code: '+962' },
  { flag: <CountryFlag country="kz" />, name: 'KAZAKHSTAN', code: '+7' },
  { flag: <CountryFlag country="ke" />, name: 'KENYA', code: '+254' },
  { flag: <CountryFlag country="ki" />, name: 'KIRIBATI', code: '+686' },
  { flag: <CountryFlag country="kp" />, name: 'NORTH_KOREA', code: '+850' },
  { flag: <CountryFlag country="kr" />, name: 'SOUTH_KOREA', code: '+82' },
  { flag: <CountryFlag country="kw" />, name: 'KUWAIT', code: '+965' },
  { flag: <CountryFlag country="kg" />, name: 'KYRGYZSTAN', code: '+996' },
  {
    flag: <CountryFlag country="la" />,
    name: 'LAO_PEOPLES_DEMOCRATIC_REPUBLIC',
    code: '+856',
  },
  { flag: <CountryFlag country="lv" />, name: 'LATVIA', code: '+371' },
  { flag: <CountryFlag country="lb" />, name: 'LEBANON', code: '+961' },
  { flag: <CountryFlag country="ls" />, name: 'LESOTHO', code: '+266' },
  { flag: <CountryFlag country="lr" />, name: 'LIBERIA', code: '+231' },
  { flag: <CountryFlag country="ly" />, name: 'LIBYA', code: '+218' },
  { flag: <CountryFlag country="lt" />, name: 'LITHUANIA', code: '+370' },
  { flag: <CountryFlag country="lu" />, name: 'LUXEMBOURG', code: '+352' },
  { flag: <CountryFlag country="mo" />, name: 'MACAO', code: '+853' },
  { flag: <CountryFlag country="mg" />, name: 'MADAGASCAR', code: '+261' },
  { flag: <CountryFlag country="mw" />, name: 'MALAWI', code: '+265' },
  { flag: <CountryFlag country="my" />, name: 'MALAYSIA', code: '+60' },
  { flag: <CountryFlag country="mv" />, name: 'MALDIVES', code: '+960' },
  { flag: <CountryFlag country="ml" />, name: 'MALI', code: '+223' },
  { flag: <CountryFlag country="mt" />, name: 'MALTA', code: '+356' },
  {
    flag: <CountryFlag country="mh" />,
    name: 'MARSHALL_ISLANDS',
    code: '+692',
  },
  { flag: <CountryFlag country="mq" />, name: 'MARTINIQUE', code: '+596' },
  { flag: <CountryFlag country="mr" />, name: 'MAURITANIA', code: '+222' },
  { flag: <CountryFlag country="mu" />, name: 'MAURITIUS', code: '+230' },
  { flag: <CountryFlag country="yt" />, name: 'MAYOTTE', code: '+262' },
  { flag: <CountryFlag country="mx" />, name: 'MEXICO', code: '+52' },
  {
    flag: <CountryFlag country="fm" />,
    name: 'MICRONESIA_FEDERATED_STATES_OF',
    code: '+691',
  },
  {
    flag: <CountryFlag country="md" />,
    name: 'MOLDOVA_REPUBLIC_OF',
    code: '+373',
  },
  { flag: <CountryFlag country="mc" />, name: 'MONACO', code: '+377' },
  { flag: <CountryFlag country="mn" />, name: 'MONGOLIA', code: '+976' },
  { flag: <CountryFlag country="ms" />, name: 'MONTSERRAT', code: '+1-664' },
  { flag: <CountryFlag country="ma" />, name: 'MOROCCO', code: '+212' },
  { flag: <CountryFlag country="mz" />, name: 'MOZAMBIQUE', code: '+258' },
  { flag: <CountryFlag country="mm" />, name: 'MYANMAR', code: '+95' },
  { flag: <CountryFlag country="na" />, name: 'NAMIBIA', code: '+264' },
  { flag: <CountryFlag country="nr" />, name: 'NAURU', code: '+674' },
  { flag: <CountryFlag country="np" />, name: 'NEPAL', code: '+977' },
  { flag: <CountryFlag country="nl" />, name: 'NETHERLANDS', code: '+31' },
  { flag: <CountryFlag country="nc" />, name: 'NEW_CALEDONIA', code: '+687' },
  { flag: <CountryFlag country="nz" />, name: 'NEW_ZEALAND', code: '+64' },
  { flag: <CountryFlag country="ni" />, name: 'NICARAGUA', code: '+505' },
  { flag: <CountryFlag country="ne" />, name: 'NIGER', code: '+227' },
  { flag: <CountryFlag country="ng" />, name: 'NIGERIA', code: '+234' },
  { flag: <CountryFlag country="nu" />, name: 'NIUE', code: '+683' },
  { flag: <CountryFlag country="nf" />, name: 'NORFOLK_ISLAND', code: '+672' },
  {
    flag: <CountryFlag country="mk" />,
    name: 'NORTH_MACEDONIA_REPUBLIC_OF',
    code: '+389',
  },
  {
    flag: <CountryFlag country="mp" />,
    name: 'NORTHERN_MARIANA_ISLANDS',
    code: '+1-670',
  },
  { flag: <CountryFlag country="no" />, name: 'NORWAY', code: '+47' },
  { flag: <CountryFlag country="om" />, name: 'OMAN', code: '+968' },
  { flag: <CountryFlag country="pk" />, name: 'PAKISTAN', code: '+92' },
  { flag: <CountryFlag country="pw" />, name: 'PALAU', code: '+680' },
  {
    flag: <CountryFlag country="ps" />,
    name: 'PALESTINIAN_TERRITORY_OCCUPIED',
    code: '+960',
  },
  { flag: <CountryFlag country="pa" />, name: 'PANAMA', code: '+507' },
  {
    flag: <CountryFlag country="pg" />,
    name: 'PAPUA_NEW_GUINEA',
    code: '+675',
  },
  { flag: <CountryFlag country="py" />, name: 'PARAGUAY', code: '+595' },
  { flag: <CountryFlag country="pe" />, name: 'PERU', code: '+51' },
  { flag: <CountryFlag country="ph" />, name: 'PHILIPPINES', code: '+63' },
  // { flag:	<CountryFlag country="pn"/>	, name:	"PITCAIRN"	,	code:	"+64"	},
  { flag: <CountryFlag country="pl" />, name: 'POLAND', code: '+48' },
  { flag: <CountryFlag country="pt" />, name: 'PORTUGAL', code: '+351' },
  { flag: <CountryFlag country="pr" />, name: 'PUERTO_RICO', code: '+1-787' },
  { flag: <CountryFlag country="qa" />, name: 'QATAR', code: '+974' },
  { flag: <CountryFlag country="re" />, name: 'REUNION', code: '+262' },
  { flag: <CountryFlag country="ro" />, name: 'ROMANIA', code: '+40' },
  {
    flag: <CountryFlag country="ru" />,
    name: 'RUSSIAN_FEDERATION',
    code: '+7',
  },
  { flag: <CountryFlag country="rw" />, name: 'RWANDA', code: '+250' },
  { flag: <CountryFlag country="sh" />, name: 'SAINT_HELENA', code: '+290' },
  {
    flag: <CountryFlag country="kn" />,
    name: 'SAINT_KITTS_AND_NEVIS',
    code: '+1-869',
  },
  { flag: <CountryFlag country="lc" />, name: 'SAINT_LUCIA', code: '+1-758' },
  {
    flag: <CountryFlag country="pm" />,
    name: 'SAINT_PIERRE_AND_MIQUELON',
    code: '+508',
  },
  {
    flag: <CountryFlag country="vc" />,
    name: 'SAINT_VINCENT_AND_THE_GRENADINES',
    code: '+1-784',
  },
  { flag: <CountryFlag country="ws" />, name: 'SAMOA', code: '+685' },
  { flag: <CountryFlag country="sm" />, name: 'SAN_MARINO', code: '+378' },
  {
    flag: <CountryFlag country="st" />,
    name: 'SAO_TOME_AND_PRINCIPE',
    code: '+239',
  },
  { flag: <CountryFlag country="sa" />, name: 'SAUDI_ARABIA', code: '+966' },
  { flag: <CountryFlag country="sn" />, name: 'SENEGAL', code: '+221' },
  { flag: <CountryFlag country="sc" />, name: 'SEYCHELLES', code: '+248' },
  { flag: <CountryFlag country="sl" />, name: 'SIERRA_LEONE', code: '+232' },
  { flag: <CountryFlag country="sg" />, name: 'SINGAPORE', code: '+65' },
  { flag: <CountryFlag country="sk" />, name: 'SLOVAKIA', code: '+421' },
  { flag: <CountryFlag country="si" />, name: 'SLOVENIA', code: '+386' },
  { flag: <CountryFlag country="sb" />, name: 'SOLOMON_ISLANDS', code: '+677' },
  { flag: <CountryFlag country="so" />, name: 'SOMALIA', code: '+252' },
  { flag: <CountryFlag country="za" />, name: 'SOUTH_AFRICA', code: '+27' },
  // { flag:	<CountryFlag country="gs"/>	, name:	"SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"	,	code:	"+500"	},
  { flag: <CountryFlag country="es" />, name: 'SPAIN', code: '+34' },
  { flag: <CountryFlag country="lk" />, name: 'SRI_LANKA', code: '+94' },
  { flag: <CountryFlag country="sd" />, name: 'SUDAN', code: '+249' },
  { flag: <CountryFlag country="sr" />, name: 'SURINAME', code: '+597' },
  // { flag:	<CountryFlag country="sj"/>	, name:	"SVALBARD_AND_JAN_MAYEN"	,	code:	"+47"	},
  { flag: <CountryFlag country="sz" />, name: 'SWAZILAND', code: '+268' },
  { flag: <CountryFlag country="se" />, name: 'SWEDEN', code: '+46' },
  {
    flag: <CountryFlag country="sy" />,
    name: 'SYRIAN_ARAB_REPUBLIC',
    code: '+963',
  },
  { flag: <CountryFlag country="tw" />, name: 'TAIWAN', code: '+886' },
  { flag: <CountryFlag country="tj" />, name: 'TAJIKISTAN', code: '+992' },
  {
    flag: <CountryFlag country="tz" />,
    name: 'TANZANIA_UNITED_REPUBLIC_OF',
    code: '+255',
  },
  { flag: <CountryFlag country="th" />, name: 'THAILAND', code: '+66' },
  { flag: <CountryFlag country="tl" />, name: 'TIMOR_LESTE', code: '+670' },
  { flag: <CountryFlag country="tg" />, name: 'TOGO', code: '+228' },
  { flag: <CountryFlag country="tk" />, name: 'TOKELAU', code: '+690' },
  { flag: <CountryFlag country="to" />, name: 'TONGA', code: '+676' },
  {
    flag: <CountryFlag country="tt" />,
    name: 'TRINIDAD_AND_TOBAGO',
    code: '+1-868',
  },
  { flag: <CountryFlag country="tn" />, name: 'TUNISIA', code: '+216' },
  { flag: <CountryFlag country="tr" />, name: 'TURKEY', code: '+90' },
  { flag: <CountryFlag country="tm" />, name: 'TURKMENISTAN', code: '+993' },
  {
    flag: <CountryFlag country="tc" />,
    name: 'TURKS_AND_CAICOS_ISLANDS',
    code: '+1-649',
  },
  { flag: <CountryFlag country="tv" />, name: 'TUVALU', code: '+688' },
  { flag: <CountryFlag country="ug" />, name: 'UGANDA', code: '+256' },
  { flag: <CountryFlag country="ua" />, name: 'UKRAINE', code: '+380' },
  {
    flag: <CountryFlag country="ae" />,
    name: 'UNITED_ARAB_EMIRATES',
    code: '+971',
  },
  { flag: <CountryFlag country="gb" />, name: 'UNITED_KINGDOM', code: '+44' },
  {
    flag: <CountryFlag country="us" />,
    name: 'UNITED_STATES_OF_AMERICA',
    code: '+1',
  },
  // { flag:	<CountryFlag country="um"/>	, name:	"UNITED_STATES_MINOR_OUTLYING_ISLANDS"	,	code:	"+1"	},
  { flag: <CountryFlag country="uy" />, name: 'URUGUAY', code: '+598' },
  { flag: <CountryFlag country="uz" />, name: 'UZBEKISTAN', code: '+998' },
  { flag: <CountryFlag country="vu" />, name: 'VANUATU', code: '+678' },
  { flag: <CountryFlag country="ve" />, name: 'VENEZUELA', code: '+58' },
  { flag: <CountryFlag country="vn" />, name: 'VIETNAM', code: '+84' },
  {
    flag: <CountryFlag country="vg" />,
    name: 'VIRGIN_ISLANDS_BRITISH',
    code: '+1-284',
  },
  { flag: <CountryFlag country="vi" />, name: 'VIRGIN_ISLANDS_US', code: '+1' },
  {
    flag: <CountryFlag country="wf" />,
    name: 'WALLIS_AND_FUTUNA',
    code: '+681',
  },
  { flag: <CountryFlag country="eh" />, name: 'WESTERN_SAHARA', code: '+212' },
  { flag: <CountryFlag country="ye" />, name: 'YEMEN', code: '+967' },
  { flag: <CountryFlag country="zm" />, name: 'ZAMBIA', code: '+260' },
  { flag: <CountryFlag country="zw" />, name: 'ZIMBABWE', code: '+263' },
  // { flag:	<CountryFlag country="ax"/>	, name:	"ALAND_ISLANDS"	,	code:	"+358"	},
  {
    flag: <CountryFlag country="bq" />,
    name: 'BONAIRE_SINT_EUSTATIUS_AND_SABA',
    code: '+599',
  },
  { flag: <CountryFlag country="cw" />, name: 'CURACAO', code: '+599' },
  // { flag:	<CountryFlag country="gg"/>	, name:	"GUERNSEY"	,	code:	"+44"	},
  { flag: <CountryFlag country="im" />, name: 'ISLE_OF_MAN', code: '+44' },
  { flag: <CountryFlag country="je" />, name: 'JERSEY', code: '+44' },
  { flag: <CountryFlag country="me" />, name: 'MONTENEGRO', code: '+382' },
  {
    flag: <CountryFlag country="bl" />,
    name: 'SAINT_BARTHELEMY',
    code: '+590',
  },
  // { flag:	<CountryFlag country="mf"/>	, name:	"SAINT_MARTIN_FRENCH_PART"	,	code:	"+590"	},
  { flag: <CountryFlag country="rs" />, name: 'SERBIA', code: '+381' },
  {
    flag: <CountryFlag country="sx" />,
    name: 'SINT_MAARTEN_DUTCH_PART',
    code: '+1721',
  },
  { flag: <CountryFlag country="ss" />, name: 'SOUTH_SUDAN', code: '+211' },
  { flag: <CountryFlag country="xk" />, name: 'KOSOVO', code: '+383' },
]
