import { FC } from 'react'
import { IconProps } from './types'

export const CrossArrowsIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 3C14 2.44772 14.4477 2 15 2H20.5C21.3284 2 22 2.67157 22 3.5V9C22 9.55228 21.5523 10 21 10C20.4477 10 20 9.55228 20 9V5.41421L3.70718 21.707L2.29297 20.2928L18.5858 4H15C14.4477 4 14 3.55228 14 3Z"
        fill="currentColor"
      />
      <path
        d="M18.5858 20L13.293 14.7072L14.7072 13.293L20 18.5858V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V20.5C22 21.3284 21.3284 22 20.5 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H18.5858Z"
        fill="currentColor"
      />
      <path
        d="M2.29297 3.70718L9.29297 10.7072L10.7072 9.29297L3.70718 2.29297L2.29297 3.70718Z"
        fill="currentColor"
      />
    </svg>
  )
}
