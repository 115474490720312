import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, HTMLProps } from 'react'
import classes from './cta-list.module.scss'

export const CTAListItem = forwardRef(
  (
    { ...props }: HTMLProps<HTMLLIElement>,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    return createElement(
      'li',
      {
        ...props,
        ref,
        className: clsx(props.className, classes.ctaListItem),
      },
      <>{props.children}</>,
    )
  },
)
