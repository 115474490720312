import { Constants } from '../constants'

export const extractStreetName = (value: string) => {
  if (value.length === 0) return Constants.EmptyString
  if (value.includes(Constants.Space)) {
    let stringArray = value.split(Constants.Space)
    stringArray = stringArray.slice(0, -1)
    return stringArray.join(Constants.Space)
  }
  return value
}

export const extractStreetNumber = (value: string) => {
  if (value.length === 0) return Constants.EmptyString
  if (value.includes(Constants.Space)) {
    const stringArray = value.split(Constants.Space)
    return stringArray.pop()
  }
  return Constants.EmptyString
}
