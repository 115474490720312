import { useState } from 'react'
import _ from 'lodash'

type InitialStateType = Record<string, unknown>

/*
  Example usage
  let test = useObserver({ firstName: 'Test', lastName: '' })
  console.log(test.firstName)

  setTimeout(() => {
    test.firstName = 'Ivan'
  }, 2000)
 */
const isEqual = (source: unknown, target: unknown) => _.isEqual(source, target)

export const useObserver = <T>(initialState: InitialStateType): T => {
  const [state, setState] = useState<InitialStateType>(initialState)

  const handler: ProxyHandler<InitialStateType> = {
    set(_obj: InitialStateType, prop: string | symbol, value: unknown) {
      if (typeof prop === 'string') {
        if (!isEqual(state[prop], value)) {
          setState((prevState) => ({ ...prevState, [prop]: value }))
        }
      }

      return true
    },
  }

  return new Proxy(state, handler) as T
}
