import { DataModel, ListType } from './document.model'

import { Constants } from '@/common'

export const getPrivacyPolicyModel = (language: string): DataModel[] => {
  const en = language === 'en'
  const de = language === 'de'
  const it = language === 'it'

  const generateHref = (document: string) => {
    if (en) {
      return Constants[`${document}EN` as keyof typeof Constants]
    } else if (de) {
      return Constants[`${document}DE` as keyof typeof Constants]
    } else if (it) {
      return Constants[`${document}IT` as keyof typeof Constants]
    } else {
      return Constants[`${document}FR` as keyof typeof Constants]
    }
  }

  return [
    {
      title: 'USM-Registration:PrivacyPolicy.Scope.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphOne.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphTwo.Text',
          href: generateHref('EplixWebsite'),
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphThree.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphFour.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphFive.Text',
          href: generateHref('EplixWebsite'),
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphSix.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphSeven.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphEight.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Scope.ParagraphNine.Text',
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.CompetentBody.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.CompetentBody.ParagraphOne.Text',
          href: `mailto:${Constants.EplixDataProtectionMail}`,
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.ApplicabilityGDPR.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.ApplicabilityGDPR.ParagraphOne.Text',
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.PersonalDataCollected.Title',
      sections: [
        {
          title:
            'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletTwo',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletThree',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletFour',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletFive',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletSix',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletSeven',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletEight',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletNine',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletTen',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletEleven',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletTwelve',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletThirteen',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletFourteen',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletFifteen',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletSixteen',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphOne.BulletList.BulletSeventeen',
                  },
                ],
              },
            },
          ],
        },
        {
          title:
            'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphTwo.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphTwo.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphTwo.BulletList.BulletTwo',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphTwo.BulletList.BulletThree',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphTwo.BulletList.BulletFour',
                  },
                ],
              },
            },
          ],
          paragraph: [
            {
              text: 'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphThree.Text',
            },
            {
              text: 'USM-Registration:PrivacyPolicy.PersonalDataCollected.ParagraphFour.Text',
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.DataProcessingPurposes.Title',
      sections: [
        {
          title:
            'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletTwo',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletThree',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletFour',
                  },
                  {
                    text: {
                      text: 'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletFive',
                      href: generateHref('EplixTermsOfUse'),
                    },
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletSix',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletSeven',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletEight',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletNine',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.DataProcessingPurposes.ParagraphOne.BulletList.BulletTen',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.LegalBasisProcessing.Title',
      sections: [
        {
          title:
            'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphOne.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphOne.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphOne.BulletList.BulletTwo',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphOne.BulletList.BulletThree',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphOne.BulletList.BulletFour',
                  },
                ],
              },
            },
          ],
        },
        {
          title:
            'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphTwo.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphTwo.BulletList.BulletOne',
                  },
                ],
              },
            },
          ],
        },
        {
          title:
            'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphThree.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphThree.BulletList.BulletOne',
                  },
                ],
              },
            },
          ],
        },
        {
          title:
            'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphFour.Text',
        },
        {
          title:
            'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphFive.Text',
        },
        {
          title:
            'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphSix.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphSix.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphSix.BulletList.BulletTwo',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphSix.BulletList.BulletThree',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.LegalBasisProcessing.ParagraphSix.BulletList.BulletFour',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.RecipientsPersonalData.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphOne.Text',
        },
      ],
      sections: [
        {
          title:
            'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletTwo',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletThree',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletFour',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletFive',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletSix',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphTwo.BulletList.BulletSeven',
                  },
                ],
              },
            },
          ],
          paragraph: [
            {
              text: 'USM-Registration:PrivacyPolicy.RecipientsPersonalData.ParagraphThree.Text',
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.Subtitle',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphOne.Text',
        },
      ],
      sections: [
        {
          title:
            'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphTwo.Text',
          content: [
            {
              list: {
                type: ListType.UNORDERED,
                items: [
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphTwo.BulletList.BulletOne',
                  },
                  {
                    title:
                      'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphTwo.BulletList.BulletTwo',
                  },
                  {
                    text: {
                      text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphTwo.BulletList.BulletThree',
                      href: Constants.DatadogCookies,
                    },
                  },
                ],
              },
            },
          ],
          paragraph: [
            {
              text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphThree.Text',
            },
            {
              text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphFour.Text',
            },
            {
              text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphFive.Text',
            },
            {
              text: 'USM-Registration:PrivacyPolicy.ThirdPartyProviderTools.ParagraphSix.Text',
              href: Constants.DatadogPrivacy,
            },
          ],
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.CookiesTracking.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphOne.Text',
          href: generateHref('EplixWebsite'),
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.Subtitle',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphTwo.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.SubtitleTwo',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphThree.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphFour.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphFive.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphSix.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphSeven.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphEight.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphNine.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphTen.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphEleven.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.SubtitleThree',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphTwelve.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CookiesTracking.ParagraphThirteen.Text',
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.CrossBorderProcessing.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.CrossBorderProcessing.ParagraphOne.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.CrossBorderProcessing.ParagraphTwo.Text',
          href: generateHref('EurLex'),
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.Profiling.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.Profiling.ParagraphOne.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Profiling.ParagraphTwo.Text',
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.ParagraphOne.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.ParagraphTwo.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.ParagraphThree.Text',
          href: `mailto:${Constants.EplixDataProtectionMail}`,
        },
        {
          text: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.ParagraphFour.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.ParagraphFive.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.RightsOfDataSubject.ParagraphSix.Text',
          href: generateHref('EdoebAdmin'),
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.DataSecurity.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.DataSecurity.ParagraphOne.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.DataSecurity.ParagraphTwo.Text',
        },
        {
          text: 'USM-Registration:PrivacyPolicy.DataSecurity.ParagraphThree.Text',
        },
      ],
    },
    {
      title: 'USM-Registration:PrivacyPolicy.Changes.Title',
      text: [
        {
          text: 'USM-Registration:PrivacyPolicy.Changes.ParagraphOne.Text',
          href: generateHref('EplixPrivacyPolicy'),
        },
        {
          text: 'USM-Registration:PrivacyPolicy.Changes.ParagraphTwo.Text',
        },
      ],
    },
  ]
}
