export const GE = {
  1200: 'Genève',
  1201: 'Genève',
  1202: 'Genève',
  1203: 'Genève',
  1204: 'Genève',
  1205: 'Genève',
  1206: 'Genève',
  1207: 'Genève',
  1208: 'Genève',
  1209: 'Genève',
  1211: 'Genève 1',
  1212: 'Grand-Lancy',
  1213: 'Petit-Lancy',
  1214: 'Vernier',
  1215: 'Genève',
  1216: 'Cointrin',
  1217: 'Meyrin',
  1218: 'Le Grand-Saconnex',
  1219: 'Le Lignon',
  1220: 'Les Avanchets',
  1222: 'Vésenaz',
  1223: 'Cologny',
  1224: 'Chêne-Bougeries',
  1225: 'Chêne-Bourg',
  1226: 'Thônex',
  1227: 'Carouge GE',
  1228: 'Plan-les-Ouates',
  1231: 'Conches',
  1232: 'Confignon',
  1233: 'Bernex',
  1234: 'Vessy',
  1236: 'Cartigny',
  1237: 'Avully',
  1239: 'Collex',
  1240: 'Genève',
  1241: 'Puplinge',
  1242: 'Satigny',
  1243: 'Presinge',
  1244: 'Choulex',
  1245: 'Collonge-Bellerive',
  1246: 'Corsier GE',
  1247: 'Anières',
  1248: 'Hermance',
  1251: 'Gy',
  1252: 'Meinier',
  1253: 'Vandoeuvres',
  1254: 'Jussy',
  1255: 'Veyrier',
  1256: 'Troinex',
  1257: 'La Croix-de-Rozon',
  1258: 'Perly',
  1281: 'Russin',
  1283: 'Dardagny',
  1284: 'Chancy',
  1285: 'Athenaz (Avusy)',
  1286: 'Soral',
  1287: 'Laconnex',
  1288: 'Aire-la-Ville',
  1289: 'Genève Serv. Spéc.',
  1290: 'Versoix',
  1292: 'Chambésy',
  1293: 'Bellevue',
  1294: 'Genthod',
  1298: 'Céligny',
}
