import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import classes from './navigation-drawer.module.scss'
import { TranslateSimple } from '@eplix/ui'

export interface NavigationDrawerLinkProps extends HTMLProps<HTMLLIElement> {
  icon?: JSX.Element
  /**
   * The displayed Text.
   */
  text: string

  disabled?: boolean
  isActive?: boolean
  href: string
}

export const NavigationDrawerLink = forwardRef(
  (
    {
      disabled,
      text,
      icon,
      isActive,
      href,
      ...props
    }: NavigationDrawerLinkProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    return (
      <li
        {...props}
        ref={ref}
        className={clsx(classes.listItem, props.className)}
      >
        <Link
          href={href}
          passHref
          tabIndex={disabled ? -1 : undefined}
          className={clsx(classes.link, isActive && classes.linkActive)}
          aria-disabled={disabled}
          target={props.target}
        >
          <span className={clsx(classes.iconWrapper)}> {icon} </span>
          <span className={clsx(classes.linkText)}>
            <span className={clsx(classes.linkTextWrapper)}>
              <TranslateSimple label={text} />
            </span>
          </span>
        </Link>
      </li>
    )
  },
)
