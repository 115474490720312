import { useFormik } from 'formik'
import { useMemo } from 'react'
import { boolean, object, string } from 'yup'
import { ProfileFormRequestWithoutStreet } from '../../components/personal/information-panel/edit-information-panel/types'
import { UtilsEnum } from '@/common/enums/UtilsEnum'
import { REGEX_EXPRESSION } from '@/common'

const useEditInformationSchema = (
  enableMarriageDateValidation: boolean,
  enableCrossBorderValidation: boolean,
  enableWorkingStatusValidation: boolean,
  enableAlphanumericZipValidation: boolean,
  enableDocumentCategoryValidation: boolean,
) => {
  return useMemo(() => {
    const requiredFieldValidationMessage =
      'USM-Schemas:EditInformationSchema.FieldRequired'
    const objectSchema = {
      street: string().required(requiredFieldValidationMessage),
      city: string().required(requiredFieldValidationMessage),
      country: string().required(requiredFieldValidationMessage),
      alias: string()
        .trim()
        .required(requiredFieldValidationMessage)
        .min(3, 'USM-Schemas:EditInformationSchema.MINAliasLength')
        .max(45, 'USM-Schemas:EditInformationSchema.MAXAliasLength')
        .matches(
          new RegExp('[a-zA-Z]+'),
          'USM-Schemas:EditInformationSchema.matches',
        ),
      maritalStatus: string().required(requiredFieldValidationMessage),
      gender: string().required(requiredFieldValidationMessage),
      isUsCitizen: boolean().oneOf(
        [true, false],
        requiredFieldValidationMessage,
      ),
      contributionTo2ndPillar: boolean().oneOf(
        [true, false, undefined],
        requiredFieldValidationMessage,
      ),
    }
    if (enableAlphanumericZipValidation) {
      Object.assign(objectSchema, {
        zip: string()
          .required(requiredFieldValidationMessage)
          .max(10, 'USM-Schemas:EditInformationSchema.ZIPLength'),
      })
    } else {
      Object.assign(objectSchema, {
        zip: string()
          .required(requiredFieldValidationMessage)
          .max(10, 'USM-Schemas:EditInformationSchema.ZIPLength')
          .matches(
            REGEX_EXPRESSION.JUST_NUMBERS,
            'USM-Schemas:EditInformationSchema.NumericalOnlyZip',
          ),
      })
    }

    if (enableMarriageDateValidation) {
      Object.assign(objectSchema, {
        marriageDate: string().required(requiredFieldValidationMessage),
      })
    }

    if (enableWorkingStatusValidation) {
      Object.assign(objectSchema, {
        employerStatus: string()
          .required(requiredFieldValidationMessage)
          .notOneOf([UtilsEnum.UNKNOWN], requiredFieldValidationMessage),
      })
    }

    if (enableCrossBorderValidation) {
      Object.assign(objectSchema, {
        isCrossBorder: boolean().oneOf([true, false], {
          label: 'USM-Schemas:AddCredentialsSchema.TermsOfUse',
          optional: 'This field must be checked',
        }),
      })
    }

    if (enableDocumentCategoryValidation) {
      Object.assign(objectSchema, {
        documentCategory: string().required(requiredFieldValidationMessage),
      })
    }

    return object(objectSchema)
  }, [
    enableAlphanumericZipValidation,
    enableMarriageDateValidation,
    enableWorkingStatusValidation,
    enableCrossBorderValidation,
    enableDocumentCategoryValidation,
  ])
}

export const useEditInformationForm = (
  initialValues: ProfileFormRequestWithoutStreet,
  onSubmit: (v: ProfileFormRequestWithoutStreet) => void | Promise<unknown>,
  {
    enableValidation = false,
    enableMarriageDateValidation = false,
    enableCrossBorderValidation = false,
    enableWorkingStatusValidation = false,
    enableAlphanumericZipValidation = false,
    enableDocumentCategoryValidation = false,
  },
) => {
  const validationSchema = useEditInformationSchema(
    enableMarriageDateValidation,
    enableCrossBorderValidation,
    enableWorkingStatusValidation,
    enableAlphanumericZipValidation,
    enableDocumentCategoryValidation,
  )
  return useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: enableValidation,
    validateOnBlur: false,
    enableReinitialize: true,
  })
}
