import { useEffect, useState } from 'react'

const getItemWithExpiry = (key: string) => {
  const itemString = localStorage.getItem(key)
  if (!itemString) {
    return null
  }
  const item = JSON.parse(itemString)
  const now = new Date().getTime()
  const remainingTime = item.expiry - now
  if (now > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return { value: (remainingTime / 1000).toFixed(0), expiry: item.expiry }
}

export const useSetItemWithExpiry = <T>(
  key: string,
  fallbackValue: T,
  ttl: number,
) => {
  const [value, setValue] = useState(fallbackValue)

  useEffect(() => {
    const stored = getItemWithExpiry(key)
    setValue(stored?.value ? JSON.parse(stored?.value) : fallbackValue)
  }, [fallbackValue, key])

  useEffect(() => {
    const expiry = new Date().getTime() + ttl
    const stored = getItemWithExpiry(key)
    if (!stored?.value)
      localStorage.setItem(key, JSON.stringify({ value, expiry }))
    else
      localStorage.setItem(
        key,
        JSON.stringify({ value, expiry: stored.expiry }),
      )
  }, [fallbackValue, key, ttl, value])

  return [value, setValue] as const
}
