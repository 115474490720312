/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 11:51:59.

export enum CivilStatusEnum {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  REGISTERED_PARTNERSHIP = 'REGISTERED_PARTNERSHIP',
  DISSOLVED_PARTNERSHIP = 'DISSOLVED_PARTNERSHIP',
}
