export const TI = {
  6500: 'Bellinzona',
  6501: 'Bellinzona',
  6503: 'Bellinzona',
  6506: 'Bellinzona 6 Autop',
  6511: 'Cadenazzo SC',
  6512: 'Giubiasco',
  6513: 'Monte Carasso',
  6514: 'Sementina',
  6515: 'Gudo',
  6516: 'Cugnasco',
  6517: 'Arbedo',
  6518: 'Gorduno',
  6523: 'Preonzo',
  6524: 'Moleno',
  6525: 'Gnosca',
  6526: 'Prosito',
  6527: 'Lodrino',
  6528: 'Camorino',
  6532: 'Castione',
  6533: 'Lumino',
  6571: 'Indemini',
  6572: 'Quartino',
  6573: 'Magadino',
  6574: 'Vira (Gambarogno)',
  6575: 'S. Nazzaro',
  6576: 'Gerra (Gambarogno)',
  6577: 'Ranzo',
  6578: 'Caviano',
  6579: 'Piazzogna',
  6582: 'Pianezzo',
  6583: 'S. Antonio',
  6584: 'Carena',
  6590: 'Cadenazzo (CLL)',
  6592: 'S. Antonino',
  6593: 'Cadenazzo',
  6594: 'Contone',
  6595: 'Riazzino',
  6596: 'Gordola',
  6597: 'Agarone',
  6598: 'Tenero',
  6599: 'Robasacco',
  6600: 'Locarno',
  6601: 'Locarno',
  6602: 'Muralto',
  6604: 'Locarno',
  6605: 'Locarno',
  6611: 'Mosogno',
  6612: 'Ascona',
  6613: 'Porto Ronco',
  6614: 'Brissago',
  6616: 'Losone',
  6618: 'Arcegno',
  6622: 'Ronco sopra Ascona',
  6631: 'Corippo',
  6632: 'Vogorno',
  6633: 'Lavertezzo',
  6634: 'Brione (Verzasca)',
  6635: 'Gerra (Verzasca)',
  6636: 'Frasco',
  6637: 'Sonogno',
  6644: 'Orselina',
  6645: 'Brione s. Minusio',
  6646: 'Contra',
  6647: 'Mergoscia',
  6648: 'Minusio',
  6652: 'Tegna',
  6653: 'Verscio',
  6654: 'Cavigliano',
  6655: 'Verdasio',
  6656: 'Golino',
  6657: 'Palagnedra',
  6658: 'Borgnone',
  6659: 'Camedo',
  6661: 'Auressio',
  6662: 'Russo',
  6663: 'Comologno',
  6664: 'Vergeletto',
  6670: 'Avegno',
  6672: 'Gordevio',
  6673: 'Maggia',
  6674: 'Riveo',
  6675: 'Cevio',
  6676: 'Bignasco',
  6677: 'Moghegno',
  6678: 'Coglio',
  6682: 'Linescio',
  6683: 'Cerentino',
  6684: 'Campo (VM)',
  6685: 'Bosco/Gurin',
  6690: 'Cavergno',
  6692: 'Brontallo',
  6693: 'Broglio',
  6694: 'Prato-Sornico',
  6695: 'Peccia',
  6696: 'Fusio',
  6702: 'Claro',
  6703: 'Osogna',
  6705: 'Cresciano',
  6707: 'Iragna',
  6710: 'Biasca',
  6713: 'Malvaglia',
  6714: 'Semione',
  6715: 'Dongio',
  6716: 'Leontica',
  6717: 'Dangio-Torre',
  6718: 'Olivone',
  6719: 'Aquila',
  6720: 'Campo (Blenio)',
  6721: 'Ludiano',
  6722: 'Corzoneso',
  6723: 'Prugiasco',
  6724: 'Ponto Valentino',
  6742: 'Pollegio',
  6743: 'Bodio TI',
  6744: 'Personico',
  6745: 'Giornico',
  6746: 'Lavorgo',
  6747: 'Chironico',
  6748: 'Anzonico',
  6749: 'Sobrio',
  6760: 'Faido',
  6763: 'Mairengo',
  6764: 'Chiggiogna',
  6772: 'Rodi-Fiesso',
  6773: 'Prato (Leventina)',
  6774: 'Dalpe',
  6775: 'Ambrì',
  6776: 'Piotta',
  6777: 'Quinto',
  6780: 'Airolo',
  6781: 'Villa Bedretto',
  6802: 'Rivera',
  6803: 'Camignolo',
  6804: 'Bironico',
  6805: 'Mezzovico',
  6806: 'Sigirino',
  6807: 'Taverne',
  6808: 'Torricella',
  6809: 'Medeglia',
  6810: 'Isone',
  6814: 'Lamone-Cadempino',
  6815: 'Melide',
  6816: 'Bissone',
  6817: 'Maroggia',
  6818: 'Melano',
  6821: 'Rovio',
  6822: 'Arogno',
  6823: 'Pugerna',
  6825: 'Capolago',
  6826: 'Riva San Vitale',
  6827: 'Brusino Arsizio',
  6828: 'Balerna',
  6830: 'Chiasso',
  6832: 'Pedrinate',
  6833: 'Vacallo',
  6834: 'Morbio Inferiore',
  6835: 'Morbio Superiore',
  6836: 'Serfontana',
  6837: 'Caneggio',
  6838: 'Cabbio',
  6839: 'Sagno',
  6850: 'Mendrisio',
  6852: 'Genestrerio',
  6853: 'Ligornetto',
  6854: 'S. Pietro',
  6855: 'Stabio',
  6862: 'Rancate',
  6863: 'Besazio',
  6864: 'Arzo',
  6865: 'Tremona',
  6866: 'Meride',
  6867: 'Serpiano',
  6872: 'Salorino',
  6873: 'Corteglia',
  6874: 'Castel San Pietro',
  6875: 'Monte',
  6877: 'Coldrerio',
  6883: 'Novazzano',
  6900: 'Lugano',
  6901: 'Lugano',
  6902: 'Lugano 2 Caselle',
  6903: 'Lugano',
  6904: 'Lugano 4 Caselle',
  6905: 'Lugano 5 Autoposta',
  6906: 'Lugano 6 Caselle',
  6907: 'Lugano 7 Caselle',
  6908: 'Massagno Caselle',
  6910: 'Lugano',
  6912: 'Pazzallo',
  6913: 'Carabbia',
  6914: 'Carona',
  6915: 'Pambio-Noranco',
  6916: 'Grancia',
  6917: 'Barbengo',
  6918: 'Figino',
  6919: 'Carabietta',
  6921: 'Vico Morcote',
  6922: 'Morcote',
  6924: 'Sorengo',
  6925: 'Gentilino',
  6926: 'Montagnola',
  6927: 'Agra',
  6928: 'Manno',
  6929: 'Gravesano',
  6930: 'Bedano',
  6932: 'Breganzona',
  6933: 'Muzzano',
  6934: 'Bioggio',
  6935: 'Bosco Luganese',
  6936: 'Cademario',
  6937: 'Breno',
  6938: 'Vezio',
  6939: 'Arosio',
  6942: 'Savosa',
  6943: 'Vezia',
  6944: 'Cureglia',
  6945: 'Origlio',
  6946: 'Ponte Capriasca',
  6947: 'Vaglio',
  6948: 'Porza',
  6949: 'Comano',
  6950: 'Tesserete',
  6951: 'Insone',
  6952: 'Canobbio',
  6953: 'Lugaggia',
  6954: 'Sala Capriasca',
  6955: 'Cagiallo',
  6956: 'Lopagno',
  6957: 'Roveredo TI',
  6958: 'Bidogno',
  6959: 'Cimadera',
  6962: 'Viganello',
  6963: 'Pregassona',
  6964: 'Davesco-Soragno',
  6965: 'Cadro',
  6966: 'Villa Luganese',
  6967: 'Dino',
  6968: 'Sonvico',
  6974: 'Aldesago',
  6976: 'Castagnola',
  6977: 'Ruvigliana',
  6978: 'Gandria',
  6979: 'Brè sopra Lugano',
  6980: 'Castelrotto',
  6981: 'Bedigliora',
  6982: 'Agno',
  6983: 'Magliaso',
  6984: 'Pura',
  6986: 'Miglieglia',
  6987: 'Caslano',
  6988: 'Ponte Tresa',
  6989: 'Purasca',
  6990: "Cassina d'Agno",
  6991: 'Neggio',
  6992: 'Vernate',
  6993: 'Iseo',
  6994: 'Aranno',
  6995: 'Molinazzo',
  6996: 'Ponte Cremenaga',
  6997: 'Sessa',
  6998: 'Termine',
  6999: 'Astano',
}
