/* eslint-disable @typescript-eslint/no-explicit-any */
import { DisplayDateFormat } from '@/common/constants'
import { SortTypeEnum } from '@/common/enums/SortType'
import { getTableActions } from '@/components/application-table/actions'
import {
  ApplicationTableProps,
  ColumnType,
  TableColumnData,
  TableRowData,
} from '@/components/application-table/types'
import { convertToDate, getDifferenceBetweenDates } from '@/framework/date-time'
import { IconButton } from '@eframe-ui/react'
import {
  AscendingIcon,
  DescendingIcon,
  SearchIcon,
  SortResetIcon,
  TextField,
  Translate,
} from '@eplix/ui'
import clsx from 'clsx'
import {
  ChangeEvent,
  cloneElement,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import classes from './application-table.module.scss'
import { CssStyleByColumnTypeMap } from './config'
import { SelectableItem } from './selectable-item'
import { TableElement } from './table-element'

type FilterCallbackType = (row: TableRowData[]) => TableRowData[]

const {
  sort,
  filterRows,
  getIsOrderAscending,
  getOrderDirection,
  getFormatterByType,
} = getTableActions()

const { sortText, sortDate } = sort

export const ApplicationTable = memo(
  ({
    columnData,
    rowData,
    className,
    searchDisabled,
    rowsStyle,
    rowsContentStyle,
    isSelectable = false,
    theadClass,
    theadRowClass,
    buttonStyle,
    trowClass = '',
    headerTranslateClassname = '',
    onSelected,
    children,
    preselectedRows = [],
    rowIdAccessor = 'id',
    initialSort,
    onRowClick,
  }: ApplicationTableProps) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [columnConfig, setColumnConfig] = useState({
      order: initialSort?.order || SortTypeEnum.ASCENDING,
      accessor: initialSort?.column || '',
      type: initialSort?.type,
    })

    const [externalFilter, setExternalFilter] = useState<FilterCallbackType>()

    const updateSortState = useCallback(
      ({ accessor, type }: TableColumnData) => {
        const orderDirection = getOrderDirection(columnConfig.order)

        setColumnConfig((prev) => ({
          ...prev,
          accessor,
          order: orderDirection,
          type,
        }))
      },
      [columnConfig],
    )

    // Initially format rows
    const formattedRowData = useMemo(
      () =>
        rowData.map((row) => {
          const newRow = { ...row }
          Object.keys(newRow).forEach((key) => {
            const config = columnData.find((column) => column.accessor === key)
            const additionalConfig = columnData.find(
              (column) => column.additional?.accessor === key,
            )

            if (config) {
              const formatter = getFormatterByType(config.type)
              newRow[key] = formatter(
                newRow[key] as never,
                config.format ?? '',
                config.inputDateFormat,
              )
            }

            if (additionalConfig) {
              const formatter = getFormatterByType(additionalConfig.type)
              newRow[key] = formatter(
                newRow[key] as never,
                additionalConfig.format ?? '',
                additionalConfig.inputDateFormat,
              )
            }
          })

          return newRow
        }),
      [rowData, columnData],
    )

    const filteredRowData = useMemo(() => {
      const filteredWithSearchTerm = filterRows(searchTerm, formattedRowData)
      return externalFilter?.(filteredWithSearchTerm) ?? filteredWithSearchTerm
    }, [externalFilter, formattedRowData, searchTerm])

    const sortedRowData = useMemo(() => {
      const sortFunctions = {
        date: sortDate,
        duration: (rows, order, accessor) => {
          return rows.sort((a, b) => {
            const dateA = convertToDate(
              a[accessor][0]?.props?.translationOptions?.start,
              DisplayDateFormat.DD_MM_YYYY,
            )
            const dateB = convertToDate(
              b[accessor][0]?.props?.translationOptions?.start,
              DisplayDateFormat.DD_MM_YYYY,
            )
            return order === SortTypeEnum.ASCENDING
              ? getDifferenceBetweenDates(
                  dateA,
                  dateB,
                  DisplayDateFormat.DD_MM_YYYY,
                )
              : getDifferenceBetweenDates(
                  dateB,
                  dateA,
                  DisplayDateFormat.DD_MM_YYYY,
                )
          })
        },
        default: sortText,
      }

      const sortCallback =
        sortFunctions[columnConfig?.type] || sortFunctions['default']

      const sorted = sortCallback(
        filteredRowData,
        columnConfig.order,
        columnConfig.accessor,
      )

      return sorted.length ? sorted : filteredRowData
    }, [columnConfig, filteredRowData])

    const filterCallback = useCallback((filter: FilterCallbackType) => {
      setExternalFilter(() => filter)
    }, [])

    const childrenElement = useMemo(() => {
      if (children) {
        return cloneElement(children as JSX.Element, {
          formattedRowData,
          filterCallback,
        })
      }

      return null
    }, [children, formattedRowData, filterCallback])

    const searchTermChangeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
      },
      [],
    )
    return (
      <div>
        <div className="relative w-full" hidden={searchDisabled}>
          <TextField
            value={searchTerm}
            label=""
            onChange={searchTermChangeHandler}
            placeholder="USM-PortfolioOverview:Edit.AssetBookingTable.SearchPlaceholder"
            className={classes.filterInput}
            formFieldClassName={classes.formField}
            endAdornment={<SearchIcon color="#3A85F8" />}
          />
        </div>
        <div>{childrenElement}</div>
        <div className={clsx(searchDisabled && 'mt-0', className)}>
          <TableElement as="table" className={clsx('table-auto', className)}>
            <TableElement as="thead">
              <TableElement as="tr" className={theadRowClass}>
                {isSelectable && (
                  <TableElement
                    as="th"
                    className={clsx(
                      'text-text-secondary typo-regular-200 pr-8',
                      theadClass,
                    )}
                  />
                )}
                {columnData &&
                  columnData.map(
                    (
                      {
                        accessor,
                        type,
                        shouldShow = true,
                        header,
                        isSortable,
                        inputDateFormat,
                        cellStyle,
                      },
                      key,
                    ) => {
                      const isSelectedColumn =
                        columnConfig.accessor === accessor
                      const isAscending = getIsOrderAscending(
                        columnConfig.order,
                      )

                      const isAscendingOrder = isSelectedColumn && isAscending
                      const isDescendingOrder = isSelectedColumn && !isAscending

                      return (
                        <TableElement
                          key={key}
                          as="th"
                          className={clsx(
                            'text-text-secondary typo-regular-200 pr-4',
                            CssStyleByColumnTypeMap[type] || '',
                            theadClass,
                            cellStyle,
                            shouldShow ? 'table-cell' : 'hidden',
                          )}
                        >
                          <div className="flex items-center gap-x-2">
                            {header && (
                              <Translate
                                className={headerTranslateClassname}
                                label={header}
                              />
                            )}
                            {isSortable && (
                              <IconButton
                                icon={
                                  isAscendingOrder ? (
                                    <AscendingIcon />
                                  ) : isDescendingOrder ? (
                                    <DescendingIcon />
                                  ) : (
                                    <SortResetIcon />
                                  )
                                }
                                onClick={() =>
                                  updateSortState({
                                    accessor,
                                    type,
                                    inputDateFormat,
                                  })
                                }
                              />
                            )}
                          </div>
                        </TableElement>
                      )
                    },
                  )}
              </TableElement>
            </TableElement>
            <TableElement as="tbody" className="overflow-y-scroll	">
              {sortedRowData?.map((row) => {
                const isPreselected = preselectedRows.includes(row.rowId)
                return (
                  <TableElement
                    key={row.rowId}
                    as="tr"
                    onClick={() => onRowClick?.(row)}
                    className={clsx(
                      rowsStyle ?? 'border-t border-[#C1C4C7] max-h-16',
                      isPreselected && 'bg-outline',
                      trowClass,
                    )}
                  >
                    {isSelectable ? (
                      <SelectableItem
                        rowsContentStyle={rowsContentStyle}
                        item={rowData.find(
                          (x) => x[rowIdAccessor] === row.rowId,
                        )}
                        isPreselected={isPreselected}
                        onSelected={
                          onSelected ?? (() => 'onSelected not provided')
                        }
                      />
                    ) : null}
                    {columnData.map(
                      (
                        {
                          type,
                          accessor,
                          additional,
                          shouldShow = true,
                          showActionIcon = () => true,
                          callback,
                          icon,
                          style,
                          cellStyle,
                        },
                        columnKey,
                      ) => {
                        const rowValue = row[accessor]
                        const additionalRowValue =
                          additional?.shouldShow && row[additional?.accessor]

                        const additionalText =
                          additional?.shouldShow && additional?.text

                        const additionalStyle = additional?.additionalRowStyle

                        const isTypeAction = type === ColumnType.ACTION

                        return (
                          <TableElement
                            key={`${row.rowId}-${columnKey}`}
                            as="td"
                            className={clsx(
                              rowsContentStyle ?? 'pr-4 py-3 typo-regular-200',
                              style,
                              CssStyleByColumnTypeMap[type] || '',
                              shouldShow ? 'table-cell' : 'hidden',
                            )}
                          >
                            <div
                              className={clsx(
                                cellStyle,
                                'flex flex-row items-center justify-between',
                              )}
                            >
                              {!isTypeAction &&
                                (type === ColumnType.HEADER ? (
                                  <Translate label={rowValue as string} />
                                ) : (
                                  rowValue
                                ))}
                              {additionalRowValue && (
                                <div
                                  className={clsx(
                                    additionalStyle,
                                    'typo-regular-200 text-text-secondary mb-3',
                                  )}
                                >
                                  {additionalRowValue}
                                </div>
                              )}
                              {additionalText && (
                                <Translate
                                  className="mt-4"
                                  label={additionalText}
                                />
                              )}
                              {isTypeAction && icon && showActionIcon(row) && (
                                <IconButton
                                  icon={icon}
                                  onClick={() => {
                                    if (callback) {
                                      callback(row)
                                    }
                                  }}
                                  className={buttonStyle}
                                />
                              )}
                            </div>
                          </TableElement>
                        )
                      },
                    )}
                  </TableElement>
                )
              })}
            </TableElement>
          </TableElement>
        </div>
      </div>
    )
  },
)
