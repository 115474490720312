import { ForwardedRef, forwardRef } from 'react'
import clsx from 'clsx'
import { Button } from '@eplix/ui'
import classes from './navigation-drawer.module.scss'
import { NavigationDrawerButtonProps } from './types'

export const NavigationDrawerButton = forwardRef(
  (
    { disabled, text, icon, content, ...props }: NavigationDrawerButtonProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const buttonProps = {
      name: 'navigation-drawer-button',
      tabIndex: disabled ? -1 : undefined,
      'aria-disabled': disabled,
    }

    return (
      <li
        {...props}
        ref={ref}
        className={clsx(classes.listItem, props.className)}
      >
        {content ? (
          content
        ) : (
          <Button {...buttonProps}>
            <span className={clsx(classes.iconWrapper)}> {icon} </span>
            <span className={clsx(classes.linkText)}>
              <span className={clsx(classes.linkTextWrapper)}>{text}</span>
            </span>
          </Button>
        )}
      </li>
    )
  },
)
