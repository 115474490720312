import clsx from 'clsx'
import { FC, HTMLProps, UIEventHandler } from 'react'
import classes from './dashboard-template.module.scss'

interface DashboardTemplateProps extends HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  onScroll?: UIEventHandler<HTMLDivElement>
  bannerHeight: number
}

export const DashboardTemplate: FC<DashboardTemplateProps> = ({
  children,
  onScroll,
  bannerHeight,
  ...props
}) => {
  const containerStyle = {
    height: bannerHeight > 0 ? `calc(100vh - ${bannerHeight}px)` : '100vh',
  }

  return (
    <div
      className={classes.dashboardContainer}
      style={containerStyle}
      onScroll={onScroll}
    >
      <div {...props} className={clsx(classes.dashboard, props.className)}>
        {children}
      </div>
    </div>
  )
}
