/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 11:53:18.

export enum RegistrationErrorEnum {
  INVALID_URL = 'INVALID_URL',
  MISSING_TOKEN = 'MISSING_TOKEN',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  UNKNOWN_EXTERNAL_SYSTEM = 'UNKNOWN_EXTERNAL_SYSTEM',
}
