import { useCallback } from 'react'
import { useSetItemWithExpiry } from './use-set-item-with-expiry'

let timerId: NodeJS.Timer
const timerStorageId = '15251800395091642'

export const useResendSmsTimer = () => {
  const [timer, setTimer] = useSetItemWithExpiry(timerStorageId, 0, 30000)
  clearInterval(timerId as unknown as number)
  if (timer) {
    timerId = setInterval(() => {
      setTimer((prevState) => prevState - 1)
    }, 1000)
  }

  const startTimer = useCallback(() => {
    if (!timerId || !timer) {
      setTimer(30)
      timerId = setInterval(() => {
        setTimer((prevState) => prevState - 1)
      }, 1000)
    }
  }, [setTimer, timer])
  return { startTimer, timer }
}
