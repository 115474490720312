import { FC } from 'react'
import { IconProps } from './types'

export const DarkModeIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.7072 18.2928L18.2928 7.70717L18.9999 8.41421L20.707 6.70711C21.0975 6.3166 21.0975 5.68349 20.707 5.29296L22.2929 3.70711C22.9229 3.07714 22.4792 2 21.5883 2L2.99926 2C2.44698 2 2 2.44771 2 3L2 21.5858C2 22.4767 3.07714 22.9229 3.70711 22.2929L5.29298 20.707C5.29301 20.707 5.29304 20.7071 5.29307 20.7071C5.6836 21.0976 6.31676 21.0976 6.70728 20.7071L8.41439 19L7.7072 18.2928ZM8.75729 17.2426C6.41414 14.8995 6.41415 11.1005 8.75729 8.75736C11.1004 6.41422 14.8994 6.41422 17.2426 8.75736L8.75729 17.2426ZM17.2427 17.2426C19.5859 14.8995 19.5859 11.1005 17.2427 8.75736L8.75746 17.2426C11.1006 19.5858 14.8996 19.5858 17.2427 17.2426ZM14 23V21H12V23C12 23.5523 12.4477 24 13 24C13.5523 24 14 23.5523 14 23ZM21 12H23C23.5523 12 24 12.4477 24 13C24 13.5523 23.5523 14 23 14H21V12ZM20.7071 19.2929L19 17.5858L17.5858 19L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0977 20.3166 21.0977 19.6834 20.7071 19.2929Z"
        fill="black"
      />
    </svg>
  )
}
