import { Checkbox as MuiCheckbox } from '@mui/material'
import classes from './checkbox.module.scss'
import { CheckboxProps } from './types'
import { CheckIcon } from '../../icons'
import clsx from 'clsx'

export const Checkbox = ({
  className,
  checkBoxClassname,
  checked,
  onChange,
  disabled,
  readonly,
  onClick,
}: CheckboxProps) => (
  <MuiCheckbox
    className={className}
    checked={checked}
    icon={<span className={clsx(classes.eplixCheckbox, checkBoxClassname)} />}
    checkedIcon={
      <span className={clsx(classes.eplixCheckboxChecked, checkBoxClassname)}>
        <CheckIcon />
      </span>
    }
    onChange={onChange}
    onClick={onClick}
    disabled={disabled}
    readOnly={readonly}
    disableRipple
  />
)
