import { Constants } from '@/common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type PasswordValidationState = {
  helperPasswordText: string
  showError: boolean
  showHelperText: boolean
}

export const useValidatePasswordField = (
  submitOccurred: boolean,
  passwordErrors: string | undefined,
): PasswordValidationState => {
  const { t } = useTranslation()
  const [passwordValidationState, setPasswordValidationState] =
    useState<PasswordValidationState>({
      helperPasswordText: '',
      showError: false,
      showHelperText: false,
    })
  useEffect(() => {
    const getPasswordErrorMessage = () => {
      if (passwordErrors?.includes('insecure')) {
        return t(
          'USM-Schemas:UserAccount.ChangePassword.InsecurePassword',
          'Insecure password.',
        )
      } else if (passwordErrors?.includes('required')) {
        return t(
          'USM-Schemas:UserAccount.ChangePassword.FieldRequired',
          'This field is required.',
        )
      } else if (passwordErrors?.includes('invalid_chars')) {
        return t(
          'USM-Schemas:UserAccount.ChangePassword.InvalidCharacters',
          'Invalid characters combination.',
        )
      } else {
        return Constants.EmptyString
      }
    }

    const helperPasswordText = getPasswordErrorMessage()

    const showError = submitOccurred ? !!passwordErrors : false
    const showHelperText = submitOccurred ? !!helperPasswordText : false
    setPasswordValidationState({
      helperPasswordText,
      showError,
      showHelperText,
    })
  }, [submitOccurred, passwordErrors, t])

  return passwordValidationState
}
