import clsx from 'clsx'
import { createElement, ForwardedRef, forwardRef, ReactHTML } from 'react'

import classes from './floating-action-button.module.scss'
import { ButtonElement, FloatingActionButtonProps } from './types'

export const FloatingActionButton = forwardRef(
  <TElement extends ButtonElement = 'button'>(
    {
      icon,
      title,
      as,
      className,
      // eslint-disable-next-line
      children,
      ...props
    }: FloatingActionButtonProps<TElement>,
    ref: ForwardedRef<ReactHTML[TElement]>,
  ) => {
    return createElement(
      as || 'button',
      {
        ...props,
        ref,
        className: clsx(classes.floatingActionButton, className),
        title,
      },
      icon,
    )
  },
)
