import clsx from 'clsx'
import { HTMLAttributes } from 'react'

export interface SliderMinMaxDisplayProps
  extends HTMLAttributes<HTMLDivElement> {
  isDisabled: boolean
}

export const SliderMinMaxDisplay = ({
  isDisabled,
  children,
}: SliderMinMaxDisplayProps) => {
  return (
    <div
      className={clsx(
        'w-full h-8 flex items-center font-200 typo-regular-200 justify-between',
        isDisabled ? 'text-outline' : 'text-text-tertiary',
      )}
    >
      {children}
    </div>
  )
}
