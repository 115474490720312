import clsx from 'clsx'
import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react'
import classes from './cta-list.module.scss'

export const CTAList = forwardRef<
  HTMLUListElement,
  DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>
>(({ ...props }, ref) => {
  return (
    <ul
      className={clsx(props.className, classes.baseList)}
      {...props}
      ref={ref}
    >
      {props.children}
    </ul>
  )
})
