import clsx from 'clsx'
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'

import classes from './text-input.module.scss'

export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  rows?: number
}

export const TextArea = forwardRef(
  (
    { className, ...props }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    return (
      <textarea
        {...props}
        ref={ref}
        className={clsx(className, classes['text-input'])}
      />
    )
  },
)
