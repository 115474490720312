import { FC } from 'react'
import { IconProps } from './types'

export const LightModeIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1V3H13V1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1ZM17.2928 5.7072L6.70719 16.2928L6.00015 15.5858L4.29305 17.2929C3.90254 17.6834 3.90252 18.3165 4.29298 18.707L2.70713 20.2929C2.07717 20.9229 2.52085 22 3.41175 22L22.0008 22C22.5531 22 23 21.5523 23 21L23 2.41421C23 1.52331 21.9229 1.07714 21.2929 1.70711L19.707 3.29298C19.707 3.29295 19.707 3.29292 19.707 3.29289C19.3164 2.90237 18.6833 2.90237 18.2927 3.29289L16.5856 5L17.2928 5.7072ZM16.2427 6.75736C18.5859 9.1005 18.5859 12.8995 16.2427 15.2426C13.8996 17.5858 10.1006 17.5858 7.75745 15.2426L16.2427 6.75736ZM7.75729 6.75736C5.41414 9.1005 5.41414 12.8995 7.75729 15.2426L16.2426 6.75736C13.8994 4.41421 10.1004 4.41421 7.75729 6.75736ZM4 12H2C1.44772 12 1 11.5523 1 11C1 10.4477 1.44772 10 2 10H4V12ZM4.29289 4.70711L6 6.41421L7.41421 5L5.70711 3.29289C5.31658 2.90237 4.68342 2.90237 4.29289 3.29289C3.90237 3.68342 3.90237 4.31658 4.29289 4.70711Z"
        fill="black"
      />
    </svg>
  )
}
