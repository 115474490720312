import { BASE_URL } from '@/framework/common'
import i18next from 'i18next'
import HTTPBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { i18nSettings } from './i18n-settings'

i18next
  .use(HTTPBackend)
  .use(initReactI18next)
  .init({
    ...i18nSettings,
    backend: {
      loadPath: `${BASE_URL}/locales/{{lng}}/{{ns}}.json`,
    },
    initImmediate: false,
    react: {
      useSuspense: false,
    },
  })

export const createI18nHTTPClient = (locale: string) => {
  return i18next.cloneInstance({ lng: locale || 'en_GB' })
}
