import { FC } from 'react'
import { IconProps } from './types'

export const VisaIcon: FC<IconProps> = ({ ...props }) => {
  return (
    <svg
      width={48}
      height={16}
      viewBox={`0 0 48 16`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2331 0.0306396C27.8285 0.0306396 24.7858 1.76553 24.7858 4.97016C24.7858 8.64566 30.1819 8.8995 30.1819 10.7462C30.1819 11.5238 29.2757 12.2197 27.7272 12.2197C25.5302 12.2197 23.8882 11.2473 23.8882 11.2473L23.1854 14.4816C23.1854 14.4816 25.0771 15.3031 27.5885 15.3031C31.3109 15.3031 34.2403 13.4833 34.2403 10.2234C34.2403 6.33939 28.8216 6.09309 28.8216 4.37943C28.8216 3.77031 29.5656 3.10315 31.1093 3.10315C32.8512 3.10315 34.272 3.81041 34.272 3.81041L34.9598 0.686945C34.9598 0.686945 33.4133 0.0306396 31.2326 0.0306396H31.2331ZM0.08256 0.266551L0 0.737902C0 0.737902 1.43232 0.995518 2.72256 1.5098C4.38384 2.09911 4.50192 2.4426 4.78176 3.50798L7.83024 15.0596H11.917L18.2126 0.266551H14.1355L10.0901 10.3244L8.43936 1.79856C8.28816 0.822831 7.52112 0.266079 6.58272 0.266079H0.08304L0.08256 0.266551ZM19.8523 0.266551L16.6541 15.0596H20.5421L23.7288 0.266551H19.8523ZM41.5368 0.266551C40.5994 0.266551 40.1026 0.760078 39.7382 1.6221L34.0421 15.0596H38.1192L38.9078 12.8199H43.8749L44.3544 15.0596H47.952L44.8138 0.266551H41.5368ZM42.0672 4.26336L43.2758 9.81436H40.0382L42.0677 4.26336H42.0672Z"
        fill="#1434CB"
      />
    </svg>
  )
}
