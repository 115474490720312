export enum PermitTypeEnum {
  B = 'B',
  C = 'C',
  Ci = 'Ci',
  G = 'G',
  L = 'L',
  F = 'F',
  N = 'N',
  S = 'S',
}
