import { PutAddressRequest } from '@/api'
import { Constants, getCommonUserDataActions, useDataSubmit } from '@/common'
import { CountryCodeEnum, CountryEnum } from '@/common/enums'
import { asyncCall } from '@/common/helpers/async-wrapper'
import {
  AddressForm,
  PortfolioWizardFormsEnum,
  WizardFooterNavigation,
  useAddressForm,
} from '@/components'
import { ExtendedAddress } from '@/components/address/types'
import { useResetCurrentUser } from '@/framework/auth'
import { GlobalValueKey, useGlobalFetchTrigger } from '@/framework/state'
import { Button, LoadingButton, Translate } from '@eplix/ui'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { putUserAddress } from '../user-account/lib/user-account-client'
import { AddressPageProps } from './types'

const {
  getHasAddress,
  getIsAlphanumericZipCountry,
  getShouldShowDocumentCategory,
  getIsNotCrossBorderAndIsNotSwissUser,
} = getCommonUserDataActions()

export const AddressPage = ({
  wizardProps,
  isLoginFlow,
  information,
  isPortfolioEdit,
  setIsLoginInProgress,
}: AddressPageProps) => {
  const router = useRouter()
  const hasAddress = getHasAddress(information)
  const resetCurrentUser = useResetCurrentUser()
  const [payload, setPayload] =
    useState<Omit<PutAddressRequest, 'isMyTellcoCustomer'>>()
  const [enableAddressValidation, setEnableAddressValidation] = useState(false)
  const [enableAlphanumericZipValidation, setEnableAlphanumericZipValidation] =
    useState(false)
  const [
    enableDocumentCategoryValidation,
    setEnableDocumentCategoryValidation,
  ] = useState(false)

  const { result, submit, isLoading } = useDataSubmit({
    fetcher: putUserAddress,
  })

  const triggerFetchUserInformation = useGlobalFetchTrigger(
    GlobalValueKey.FETCH_TRIGGER_USER_INFORMATION,
  )

  const triggerFetchDataCombination = useGlobalFetchTrigger(
    GlobalValueKey.FETCH_TRIGGER_DATA_COMBINATION,
  )

  useEffect(() => {
    setIsLoginInProgress?.(false)
  }, [setIsLoginInProgress])

  const form = useAddressForm(
    {
      street: information?.street ?? Constants.EmptyString,
      streetNumber: information?.streetNumber ?? Constants.EmptyString,
      zip: information?.zip ?? Constants.EmptyString,
      city: information?.city ?? Constants.EmptyString,
      country: information?.country ?? CountryEnum.SWITZERLAND,
      additionalAddress:
        information?.additionalAddress ?? Constants.EmptyString,
      isCrossBorder: information?.isCrossBorder ?? false,
      documentCategory: information?.documentCategory ?? Constants.EmptyString,
    } as ExtendedAddress,
    {
      enableAddressValidation,
      enableAlphanumericZipValidation,
      enableDocumentCategoryValidation,
    },
    () => submit(payload as PutAddressRequest),
  )

  const { handleSubmit, errors, values } = form
  const isNotCrossBorderAndIsNotSwissUser =
    getIsNotCrossBorderAndIsNotSwissUser(values.country)

  useEffect(() => {
    const isAlphanumericZipCountry = getIsAlphanumericZipCountry(values.country)
    setEnableAlphanumericZipValidation(isAlphanumericZipCountry)
  }, [values.country])

  const replaceToDashboard = useCallback(async () => {
    if (result && isEmpty(errors)) {
      await router.replace('/dashboard')
    }
  }, [result, router, errors])

  useEffect(() => {
    if (isLoginFlow) {
      asyncCall(replaceToDashboard)
    }
  }, [isLoginFlow, replaceToDashboard])

  const shouldShowDocumentCategory = getShouldShowDocumentCategory(
    values.country,
    values.isCrossBorder,
    information?.nationality,
    information?.documentCategory,
  )

  const setEnableValidation = useCallback(() => {
    if (!hasAddress || !isLoginFlow) {
      setEnableAddressValidation(true)
    }

    if (shouldShowDocumentCategory) {
      setEnableDocumentCategoryValidation(true)
    }
  }, [hasAddress, isLoginFlow, shouldShowDocumentCategory])

  useEffect(() => {
    if (isNotCrossBorderAndIsNotSwissUser) {
      setEnableDocumentCategoryValidation(false)
      form.setErrors({})
    }
  }, [form, isNotCrossBorderAndIsNotSwissUser, values.country])

  const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setPayload({
      isCrossBorder: values.isCrossBorder,
      documentCategory: values.documentCategory,
      address: {
        street: values.street,
        streetNumber: values.streetNumber,
        city: values.city,
        zip: values.zip,
        canton: values.canton,
        additionalAddress: values.additionalAddress,
        country:
          Object.values(CountryCodeEnum)[
            Object.keys(CountryCodeEnum).findIndex(
              (country) => values.country === country,
            )
          ],
      },
    })
    await setEnableValidation()
    handleSubmit()
  }

  useEffect(() => {
    if (result?.success && isEmpty(errors)) {
      if (isPortfolioEdit) {
        triggerFetchUserInformation()
        wizardProps?.setStateByFormName(PortfolioWizardFormsEnum.EDIT_STRATEGY)
      } else {
        wizardProps?.handleNext()
      }
    }
  }, [
    errors,
    isPortfolioEdit,
    result?.success,
    triggerFetchDataCombination,
    triggerFetchUserInformation,
    wizardProps,
  ])

  const backToLogin = () => {
    resetCurrentUser().then(() => window.location.reload())
  }

  return (
    <div className="flex flex-col h-full w-full">
      {!hasAddress && <Translate label="common:AddressPage" />}
      <form
        onSubmit={formSubmitHandler}
        className="flex flex-col h-full justify-between"
      >
        <AddressForm
          form={form}
          isLoginFlow={isLoginFlow}
          information={information}
        />

        {wizardProps && (
          <WizardFooterNavigation
            type="submit"
            isBackVisible={false}
            isNextVisible={false}
            wizardProps={wizardProps}
          >
            <LoadingButton
              variant="outlined"
              name="address_confirm"
              disabled={!isEmpty(errors)}
              text="common:Confirm"
              loading={isLoading}
            />
          </WizardFooterNavigation>
        )}

        {isLoginFlow && (
          <div className="flex flex-row justify-between mt-5">
            <Button
              type="button"
              variant="outlined"
              onClick={backToLogin}
              name="address_page_back"
              className="my-4 lg:w-fit"
              text="USM-Login:Documents.BackButton"
            />
            <LoadingButton
              type="submit"
              variant="elevated"
              loading={isLoading}
              name="address_page_next"
              className="mt-4 lg:w-fit"
              disabled={!isEmpty(errors)}
              text="USM-Login:Documents.NextButton"
            />
          </div>
        )}
      </form>
    </div>
  )
}
