import { FC } from 'react'
import { IconProps } from './types'

export const SunIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4V1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1V4H13ZM7.05029 5.63721L4.92871 3.51562C4.53823 3.12514 3.90513 3.12514 3.51465 3.51562C3.12417 3.90611 3.12417 4.53921 3.51465 4.92969L5.63623 7.05127L7.05029 5.63721ZM1 11H4V13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11ZM23 11H20V13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11ZM16.9492 5.63672L19.0708 3.51514C19.4613 3.12465 20.0944 3.12465 20.4849 3.51514C20.8753 3.90562 20.8753 4.53872 20.4849 4.9292L18.3633 7.05078L16.9492 5.63672Z"
        fill="currentColor"
      />
      <path
        d="M13 19.9487V22.9487C13 23.501 12.5523 23.9487 12 23.9487C11.4477 23.9487 11 23.501 11 22.9487V19.9487H13Z"
        fill="currentColor"
      />
      <path
        d="M7.05029 18.3935L4.92871 20.4842C4.53823 20.869 3.90513 20.869 3.51465 20.4842C3.12417 20.0994 3.12417 19.4755 3.51465 19.0907L5.63623 17L7.05029 18.3935Z"
        fill="currentColor"
      />
      <path
        d="M19.0708 20.4847L16.9492 18.394L18.3633 17.0005L20.4849 19.0912C20.8753 19.476 20.8753 20.0999 20.4849 20.4847C20.0944 20.8695 19.4613 20.8695 19.0708 20.4847Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        fill="currentColor"
      />
    </svg>
  )
}
