import { useId } from '@eframe-ui/react'
import { ForwardedRef, forwardRef } from 'react'
import { WarningIcon } from '../icons/warning-icon'
import { TextInput, TextInputProps } from '../inputs'
import {
  FormFieldBorderBox,
  FormFieldControl,
  FormFieldHelperText,
  FormFieldLabel,
} from './form-field'
import { TranslateSimpleProps } from '../translate/types'
import { useTranslate } from '../translate/hooks/use-translate'

export interface TextFieldProps extends TextInputProps {
  label?: string
  placeholder?: string
  error?: boolean
  helperText?: string
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
  formFieldClassName?: string
  inputClassName?: string
  renderSpan?: boolean
}

const getTextFieldVariant = ({ disabled, error }: TextFieldProps) => {
  if (disabled) {
    return 'disabled'
  } else if (error) {
    return 'error'
  }
}

export const TextField = forwardRef(
  (props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      label,
      placeholder,
      error,
      helperText,
      startAdornment,
      endAdornment,
      className,
      formFieldClassName,
      inputClassName,
      renderSpan,
      ...rest
    } = props
    const id = useId()
    const ariaDescriptionId = `description-${id}`
    const ariaLabelId = `label-${id}`

    return (
      <FormFieldControl
        className={className}
        variant={getTextFieldVariant(props)}
      >
        <FormFieldLabel htmlFor={ariaLabelId} label={label} />
        <FormFieldBorderBox className={formFieldClassName}>
          <>{startAdornment}</>
          <TextInput
            className={inputClassName}
            name="form_text_field"
            aria-labelledby={ariaLabelId}
            aria-describedby={ariaDescriptionId}
            aria-invalid={!!error}
            id={ariaLabelId}
            placeholder={useTranslate({ placeholder } as TranslateSimpleProps)}
            {...rest}
            ref={ref}
          />
          <>
            {error && <WarningIcon style={{ color: 'var(--border-color)' }} />}
            {endAdornment}
          </>
        </FormFieldBorderBox>
        {renderSpan && <span></span>}
        {helperText && <FormFieldHelperText helperText={helperText} />}
      </FormFieldControl>
    )
  },
)
