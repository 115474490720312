import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@eframe-ui/react'
import { Button } from '@eplix/ui'
import React, {
  createContext,
  FC,
  HTMLAttributes,
  ReactNode,
  useContext,
  useState,
} from 'react'
import clsx from 'clsx'
import classes from './dialog-context.module.scss'
import { DialogContentType, DialogContextValue, DisplayInfo } from './types'
import { DisplayApiError } from '@/framework/error-handling/display-api-error'

const DialogContext = createContext<DialogContextValue>({
  setContent: () => {},
  setConfirmCallback: () => {},
  setCancelCallback: () => {},
  onClose: () => {},
  setDisplayInfo: () => {},
  setStyle: () => {},
  setError: () => {},
})

export interface EplixDialogProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
}

export const EplixDialog: FC<EplixDialogProps> = ({
  children,
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [displayClose, setDisplayClose] = useState(true)
  const [displayCancel, setDisplayCancel] = useState(true)
  const [displayConfirm, setDisplayConfirm] = useState(true)
  const [dialogClassName, setDialogClassName] = useState('')
  const [error, setError] = useState('')
  const [contentElement, setContentElement] = useState<ReactNode>()
  const [confirmCallback, setConfirmCallback] = useState<CallableFunction>(
    () => {},
  )
  const [cancelCallback, setCancelCallback] = useState<CallableFunction>(
    () => {},
  )

  const setDisplayInfo = (displayInfo: DisplayInfo) => {
    setDisplayClose(displayInfo.displayClose ?? true)
    setDisplayCancel(displayInfo.displayCancel ?? true)
    setDisplayConfirm(displayInfo.displayConfirm ?? true)
  }

  const onClose = () => {
    setIsOpen(false)
    setError('')
    setStyle('')
  }

  const onConfirm = () => {
    if (confirmCallback) {
      const hasErrors = confirmCallback()
      if (hasErrors instanceof Promise) {
        hasErrors.then((value) => {
          !value && onClose()
        })
      } else if (!hasErrors) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  const onCancel = () => {
    if (cancelCallback) {
      cancelCallback()
    }
    onClose()
  }

  const setContent = (dialogContent: DialogContentType) => {
    setContentElement(dialogContent.contentElement)
    setTitle((dialogContent.title as string) ?? '')
    setIsOpen(true)
  }

  const setStyle = (style: string) => setDialogClassName(style)

  return (
    <DialogContext.Provider
      value={{
        setContent: setContent,
        setConfirmCallback: setConfirmCallback,
        setCancelCallback: setCancelCallback,
        onClose: onClose,
        setDisplayInfo: setDisplayInfo,
        setStyle: setStyle,
        setError: setError,
      }}
    >
      {isOpen && (
        <div className={clsx(classes.container, className)} {...props}>
          <Dialog open={isOpen} onClose={onClose} className={dialogClassName}>
            <DialogHeader onClose={onClose}>
              <p className={'typo-regular-600 text-text-primary py-3'}>
                {title}
              </p>
            </DialogHeader>
            <DialogContent>{contentElement}</DialogContent>
            <DisplayApiError
              apiErrorMessage={error}
              translationKey={'common:Error'}
              className={'ml-6'}
            />
            <DialogFooter>
              <Button
                variant="outlined"
                name="dialog_footer_close"
                onClick={onClose}
                className={clsx(!displayClose && 'hidden')}
                text="common:Close"
              />
              <Button
                variant="outlined"
                name="dialog_footer_cancel"
                onClick={onCancel}
                className={clsx(!displayCancel && 'hidden')}
                text="common:Cancel"
              />
              <Button
                variant="outlined"
                name="dialog_footer_confirm"
                onClick={onConfirm}
                className={clsx(!displayConfirm && 'hidden')}
                text="common:Confirm"
              />
            </DialogFooter>
          </Dialog>
        </div>
      )}
      {children}
    </DialogContext.Provider>
  )
}

export const useDialog = () => {
  return useContext(DialogContext)
}
