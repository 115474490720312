import { ColorProp, TypographyProp } from '@eplix/ui/utils/css-helpers/types'
import { HTMLProps } from 'react'

export enum SnackbarType {
  ERROR = 0,
  SUCCESS = 1,
  INFO = 2,
  WARNING = 3,
}

export interface SnackbarProps extends HTMLProps<HTMLDivElement> {
  text?: string
  isPersistent?: boolean
  link?: string
  color?: ColorProp
  duration?: number
  className?: string
  cardStyle?: string
  textStyle?: string
  snackbarType?: SnackbarType
  iconSize?: 16 | 24
  hideCloseIcon?: boolean
  callbackHide?: () => void
  iconClassName?: string
  textVariant?: TypographyProp
  textWrapperStyle?: string
  linkClassName?: string
  isExternalLink?: boolean
}
