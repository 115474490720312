export const LU = {
  4806: 'Wikon',
  4915: 'St. Urban',
  5735: 'Pfeffikon LU',
  6000: 'Luzern',
  6002: 'Luzern',
  6003: 'Luzern',
  6004: 'Luzern',
  6005: 'Luzern',
  6006: 'Luzern',
  6007: 'Luzern',
  6008: 'Luzern',
  6009: 'Luzern',
  6010: 'Kriens',
  6011: 'Kriens',
  6012: 'Obernau',
  6013: 'Eigenthal',
  6014: 'Luzern',
  6015: 'Luzern',
  6016: 'Hellbühl',
  6017: 'Ruswil',
  6018: 'Buttisholz',
  6019: 'Sigigen',
  6020: 'Emmenbrücke',
  6021: 'Emmenbrücke 1',
  6022: 'Grosswangen',
  6023: 'Rothenburg',
  6024: 'Hildisrieden',
  6025: 'Neudorf',
  6026: 'Rain',
  6027: 'Römerswil LU',
  6028: 'Herlisberg',
  6030: 'Ebikon',
  6031: 'Ebikon',
  6032: 'Emmen',
  6033: 'Buchrain',
  6034: 'Inwil',
  6035: 'Perlen',
  6036: 'Dierikon',
  6037: 'Root',
  6038: 'Gisikon',
  6039: 'Root Längenbold',
  6043: 'Adligenswil',
  6044: 'Udligenswil',
  6045: 'Meggen',
  6047: 'Kastanienbaum',
  6048: 'Horw',
  6102: 'Malters',
  6103: 'Schwarzenberg LU',
  6105: 'Schachen LU',
  6106: 'Werthenstein',
  6110: 'Wolhusen',
  6112: 'Doppleschwand',
  6113: 'Romoos',
  6114: 'Steinhuserberg',
  6122: 'Menznau',
  6123: 'Geiss',
  6125: 'Menzberg',
  6126: 'Daiwil',
  6130: 'Willisau',
  6132: 'Rohrmatt',
  6133: 'Hergiswil LU',
  6142: 'Gettnau',
  6143: 'Ohmstal',
  6144: 'Zell LU',
  6145: 'Fischbach LU',
  6146: 'Grossdietwil',
  6147: 'Altbüron',
  6152: 'Hüswil',
  6153: 'Ufhusen',
  6154: 'Hofstatt',
  6156: 'Luthern',
  6160: 'Entlebuch',
  6161: 'Entlebuch',
  6162: 'Entlebuch',
  6163: 'Ebnet',
  6166: 'Hasle LU',
  6167: 'Bramboden',
  6170: 'Schüpfheim',
  6173: 'Flühli LU',
  6174: 'Sörenberg',
  6182: 'Escholzmatt',
  6192: 'Wiggen',
  6196: 'Marbach LU',
  6203: 'Sempach Station',
  6204: 'Sempach',
  6205: 'Eich',
  6206: 'Neuenkirch',
  6207: 'Nottwil',
  6208: 'Oberkirch LU',
  6210: 'Sursee',
  6211: 'Buchs LU',
  6212: 'St. Erhard',
  6213: 'Knutwil',
  6214: 'Schenkon',
  6215: 'Beromünster',
  6216: 'Mauensee',
  6217: 'Kottwil',
  6218: 'Ettiswil',
  6221: 'Rickenbach LU',
  6222: 'Gunzwil',
  6231: 'Schlierbach',
  6232: 'Geuensee',
  6233: 'Büron',
  6234: 'Triengen',
  6235: 'Winikon',
  6236: 'Wilihof',
  6242: 'Wauwil',
  6243: 'Egolzwil',
  6244: 'Nebikon',
  6245: 'Ebersecken',
  6246: 'Altishofen',
  6247: 'Schötz',
  6248: 'Alberswil',
  6252: 'Dagmersellen',
  6253: 'Uffikon',
  6260: 'Reiden',
  6262: 'Langnau b. Reiden',
  6263: 'Richenthal',
  6264: 'Pfaffnau',
  6265: 'Roggliswil',
  6274: 'Eschenbach LU',
  6275: 'Ballwil',
  6276: 'Hohenrain',
  6277: 'Kleinwangen',
  6280: 'Hochdorf',
  6281: 'Hochdorf',
  6283: 'Baldegg',
  6284: 'Gelfingen',
  6285: 'Hitzkirch',
  6286: 'Altwis',
  6287: 'Aesch LU',
  6288: 'Schongau',
  6289: 'Müswangen',
  6294: 'Ermensee',
  6295: 'Mosen',
  6344: 'Meierskappel',
  6353: 'Weggis',
  6354: 'Vitznau',
  6356: 'Rigi Kaltbad',
  6404: 'Greppen',
}
