import { getPlannedMaintenance } from '@/features/maintenance/lib/planned-maintenance-client'
import { useDataFetcher } from '@/common'
import {
  GlobalValueKey,
  isBeforeCurrentDateTime,
  parseRequestDateAndTime,
  useGlobalValue,
  useMaintenanceModeBannerHeight,
} from '@/framework'
import { DateTime } from '@/framework/date-time/types'
import { Translate, Typo } from '@eplix/ui'
import { debounce } from 'lodash'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import {
  displayDateFormat,
  fifteenMinutes,
  requestDateFormat,
} from './constants'
import { useRouter } from 'next/router'
import { getManageMaintenanceActions } from '@/features/user-account/components/settings/eplix-general-settings/actions'
import { LocaleEnum } from '@/common/enums/Locale'
import { PlannedMaintenance } from '@/api'

export const MaintenanceBanner = memo(() => {
  const [, setBannerHeight] = useMaintenanceModeBannerHeight()
  const [maintenanceUpdateTrigger] = useGlobalValue(
    GlobalValueKey.FETCH_TRIGGER_plannedMaintenances,
  )
  const [maintenance, setMaintenance] = useState({
    mode: false,
    from: '',
    to: '',
  })

  const { getLocaleForCustomMessage } = getManageMaintenanceActions()

  const router = useRouter()
  const localeLanguage = router.locale as LocaleEnum

  const bannerRef = useRef<HTMLDivElement | null>(null)

  const { result: plannedMaintenance, isLoading } = useDataFetcher({
    fetcher: getPlannedMaintenance,
    interval: fifteenMinutes,
    fetchTriggerKey: GlobalValueKey.FETCH_TRIGGER_plannedMaintenances,
  })

  const notifyAuthUser =
    plannedMaintenance?.plannedMaintenances[0]?.shouldNotifyAuthUser

  const notifyAnonymousUser =
    plannedMaintenance?.plannedMaintenances[0]?.shouldNotifyAnonymousUser

  const isCustomMessage =
    plannedMaintenance?.plannedMaintenances[0].messageEn &&
    plannedMaintenance?.plannedMaintenances[0].messageDe &&
    plannedMaintenance?.plannedMaintenances[0].messageFr &&
    plannedMaintenance?.plannedMaintenances[0].messageIt

  const handleResize = useCallback(
    () =>
      debounce(() => {
        const height = bannerRef.current?.clientHeight ?? 0
        setBannerHeight(height)
      }, 100)(),
    [bannerRef, setBannerHeight],
  )

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  const updateMaintenance = useCallback(
    (latestDateTime: PlannedMaintenance) => {
      const from = latestDateTime?.from as DateTime
      const to = latestDateTime?.to as DateTime

      if (!isBeforeCurrentDateTime(to)) {
        const formattedFrom = parseRequestDateAndTime(
          from,
          requestDateFormat,
          displayDateFormat,
        )
        const formattedTo = parseRequestDateAndTime(
          to,
          requestDateFormat,
          displayDateFormat,
        )
        setMaintenance({
          mode:
            latestDateTime?.shouldNotifyAuthUser ||
            latestDateTime?.shouldNotifyAnonymousUser ||
            false,
          from: formattedFrom,
          to: formattedTo,
        })
      }
      const height = bannerRef.current?.clientHeight ?? 0
      setBannerHeight(height)
    },
    [setBannerHeight],
  )

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      const result = await getPlannedMaintenance()

      if (
        'plannedMaintenances' in result &&
        result.plannedMaintenances.length > 0
      ) {
        updateMaintenance(result.plannedMaintenances[0])
      }
    }

    fetchMaintenanceData()
  }, [maintenanceUpdateTrigger, updateMaintenance])

  useEffect(() => {
    if (!plannedMaintenance) {
      return
    }
    if (
      isBeforeCurrentDateTime(
        plannedMaintenance.plannedMaintenances[0].to as DateTime,
      )
    ) {
      setMaintenance((prevState) => ({ ...prevState, mode: false }))
    }

    updateMaintenance(plannedMaintenance.plannedMaintenances[0])
    const height = bannerRef.current?.clientHeight ?? 0
    setBannerHeight(height)
  }, [
    bannerRef,
    maintenance.mode,
    plannedMaintenance,
    setBannerHeight,
    updateMaintenance,
  ])

  return (
    <>
      {maintenance.mode &&
        (notifyAuthUser || notifyAnonymousUser) &&
        !isLoading && (
          <div
            className="flex fixed w-full justify-center text-center align-center bg-[#F8CE3A] px-6 py-[3.5px] z-[10]"
            ref={bannerRef}
          >
            {isCustomMessage ? (
              <Typo variant="regular-100" color="text-primary">
                {getLocaleForCustomMessage(
                  localeLanguage,
                  plannedMaintenance?.plannedMaintenances[0],
                )}
              </Typo>
            ) : (
              <Translate
                variant="regular-100"
                color="text-primary"
                label="common:PlannedMaintenanceMessage"
                translationOptions={{
                  maintenanceTimeFrom: maintenance.from,
                  maintenanceTimeTo: maintenance.to,
                }}
              />
            )}
          </div>
        )}
    </>
  )
})
