import { UserSessionStatusEnum } from '@/common/enums/UserSessionStatus'

const getShowAuthUser = (
  notifyAuthUser: boolean | undefined,
  userStatus: UserSessionStatusEnum | undefined,
) => {
  return (
    notifyAuthUser &&
    userStatus === UserSessionStatusEnum.AUTHENTICATED &&
    UserSessionStatusEnum.AUTHENTICATED_WITHOUT_ACCEPTED_DOCUMENTS
  )
}

const getShowAnonymusUser = (
  notifyAnonymousUser: boolean | undefined,
  userStatus: UserSessionStatusEnum | undefined,
) => {
  return (
    notifyAnonymousUser &&
    userStatus === UserSessionStatusEnum.ANONYMOUS &&
    UserSessionStatusEnum.AUTHENTICATING &&
    UserSessionStatusEnum.PASSWORD_CHANGE &&
    UserSessionStatusEnum.REGISTERING &&
    UserSessionStatusEnum.REGISTERED &&
    UserSessionStatusEnum.OFFLINE
  )
}

export const getApplicationWrapperActions = () => {
  return {
    getShowAuthUser,
    getShowAnonymusUser,
  }
}
