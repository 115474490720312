import { FileTypeEnum } from '@/common/enums/FileType'
import { saveAs } from 'file-saver'
import { fileTypeMap } from './constants'

export const displayPdf = (blobPart: BlobPart) => {
  if (blobPart) {
    const blob = new Blob([blobPart], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL)
  }
}

export const displayBase64File = (data: string) => {
  const newTab = window.open('about:blank')

  setTimeout(function () {
    newTab?.document.write(
      `<iframe width='100%' height='100%' src='${data}'></iframe>`,
    )
  }, 0)
}

export const displayFile = (
  blobPart: BlobPart,
  extension: FileTypeEnum,
  name?: string,
) => {
  const blob = new Blob([blobPart], { type: fileTypeMap[extension] })
  saveAs(blob, name)
}

const getArrayBufferFromByteString = (byteString: string) => {
  return new ArrayBuffer(byteString.length)
}

export const getInt8ArrayFromBase64 = (data: string) => {
  if (typeof window !== 'undefined') {
    const byteString = window.atob(data)
    const arrayBuffer = getArrayBufferFromByteString(byteString)
    const int8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }
    return int8Array
  }
}

export const getBlobFromBase64 = (data: string, extension: FileTypeEnum) => {
  const int8Array = getInt8ArrayFromBase64(data)
  if (int8Array) return new Blob([int8Array], { type: fileTypeMap[extension] })
}

export const getFileUrlFromBase64 = (data: string, extension: FileTypeEnum) => {
  if (data) {
    const blob = getBlobFromBase64(data, extension)
    if (blob) return URL.createObjectURL(blob)
  }
}

export const getPdfUrlFromBase64 = (data: string) => {
  return getFileUrlFromBase64(data, FileTypeEnum.PDF)
}

export const convertBase64ToPdf = (data: string) => {
  const fileURL = getPdfUrlFromBase64(data)
  window.open(fileURL)
}

export const downloadFile = (
  data: string,
  extension: FileTypeEnum,
  fileName?: string,
) => {
  const int8Array = getInt8ArrayFromBase64(data)
  if (int8Array) displayFile(int8Array, extension, fileName)
}

export const displayAndDownloadPdf = (data: string, fileName?: string) => {
  convertBase64ToPdf(data)
  downloadFile(data, FileTypeEnum.PDF, fileName)
}
