import { MouseEvent, useCallback } from 'react'
import useMediaQuery from '@eplix/ui/utils/use-media-query'
import screenSize from '@eplix/ui/foundation/media-queries.json'
import { DataValues } from '@/components'
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart as PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { roundFixed } from '@/common'
import { COLORS } from './config'
import { PieChartLegend } from './pie-chart-legend'
import { formatPercentage } from '@/features/portfolio/components/portfolio-info-overview/util'
import classes from './asset-allocation-chart.module.scss'
import clsx from 'clsx'

interface PieChartProps {
  size: number
  data: DataValues
  outerRadius: number
  innerRadius: number
  paddingAngle: number
  value: string
  valueStyle?: string
  isAnimationActive: boolean
  dataKey?: string
  showTooltip?: boolean
  showLegend?: boolean
  toggleCharts?: () => void
}

export const AssetAllocationChart = ({
  size,
  data,
  outerRadius,
  innerRadius,
  paddingAngle,
  value,
  isAnimationActive,
  dataKey = 'percent',
  showTooltip = false,
  showLegend = false,
  toggleCharts,
}: PieChartProps) => {
  const matchesSmallScreen = useMediaQuery(`(max-width:${screenSize.lg}px)`)

  const toggleChartsOnMobile = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (matchesSmallScreen) {
        e.stopPropagation()
        toggleCharts && toggleCharts()
      }
    },
    [matchesSmallScreen, toggleCharts],
  )

  const percentages = data?.map((obj) => obj.percent as number)

  const percentagesSum = percentages.reduce(
    (prevValue, currentValue): number => {
      return prevValue + currentValue
    },
    0,
  )

  const cellFill = (index: number, name?: string) =>
    percentagesSum === 0 || name === 'Account'
      ? '#999999'
      : `url(#gradient${index})`

  const pieChartWithoutLegend = (
    <div className="flex justify-center mt-auto items-baseline">
      <div
        className={clsx('flex flex-col items-center', classes.zIndex)}
        onClick={(e) => toggleChartsOnMobile(e)}
      >
        <PieChart width={size} height={size} id={value}>
          <text
            x="50%"
            y="50%"
            className="recharts-text recharts-label"
            textAnchor="middle"
            dominantBaseline="central"
            alignmentBaseline="middle"
            fontSize="15"
          >
            {value}
          </text>
          <defs>
            {data.map((entry, colorIndex) => (
              <linearGradient
                key={`gradient${colorIndex}`}
                id={`gradient${colorIndex}`}
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0%"
                  stopColor={COLORS[colorIndex % COLORS.length].start}
                />
                <stop
                  offset="100%"
                  stopColor={COLORS[colorIndex % COLORS.length].end}
                />
              </linearGradient>
            ))}
          </defs>
          <Pie
            isAnimationActive={isAnimationActive}
            data={data}
            dataKey={dataKey}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            paddingAngle={paddingAngle}
            className="cursor-pointer"
            cx="50%"
            cy="50%"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={cellFill(index, entry.name as string)}
              />
            ))}
          </Pie>
          {showTooltip && (
            <Tooltip
              contentStyle={{ borderRadius: 10 }}
              formatter={(item: number) => formatPercentage(item, 2) as number}
            />
          )}
        </PieChart>
      </div>
    </div>
  )

  const pieChartWithLegend = (
    <div className="flex w-full h-72 min-h-fit">
      <ResponsiveContainer>
        <PieChart width={size} height={size} id={value}>
          <Pie
            isAnimationActive={isAnimationActive}
            data={data}
            dataKey={dataKey}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            paddingAngle={paddingAngle}
            cx="50%"
            cy="50%"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#gradient${index})`} />
            ))}
            <Label value={value} position="center" />
          </Pie>
          {showTooltip && (
            <Tooltip
              contentStyle={{ borderRadius: 10 }}
              formatter={(item: number) => +roundFixed(item, 2)}
            />
          )}
          <Legend content={<PieChartLegend colors={COLORS} />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )

  return showLegend ? pieChartWithLegend : pieChartWithoutLegend
}
