import { SVGProps } from 'react'

export const EplixLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="120"
      id="eplix_logo"
      data-name="eplix_logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 284 60"
      {...props}
    >
      <defs>
        <style>
          {`.eplix_logo-1, .eplix_logo-2, .eplix_logo-3, .eplix_logo-4, .eplix_logo-5 {
            stroke - width: 0px;
        }
          .eplix_logo-2 {
          fill: #88bf20;
        }
          .eplix_logo-3 {
          fill: url(#eplix_logo_radial-gradient-2);
        }
          .eplix_logo-4 {
          fill: url(#eplix_logo_radial-gradient);
        }
          .eplix_logo-5 {
          fill: #3a85f8;
        }`}
        </style>
        <radialGradient
          id="eplix_logo_radial-gradient"
          cx="22.2"
          cy="27.62"
          fx="22.2"
          fy="27.62"
          r="23.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#c8de8d" />
          <stop offset=".92" stopColor="#88bf20" />
        </radialGradient>
        <radialGradient
          id="eplix_logo_radial-gradient-2"
          cx="269.53"
          cy="374.44"
          fx="269.53"
          fy="374.44"
          r="23.11"
          gradientTransform="translate(315.36 402.57) rotate(-180)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#1f4c76" />
          <stop offset=".92" stopColor="#3a85f8" />
        </radialGradient>
      </defs>
      <circle className="eplix_logo-5" cx="22.68" cy="27.87" r="22.68" />
      <path
        className="eplix_logo-2"
        d="m45.35,5.19c-4.13,0-8,1.11-11.34,3.04,6.78,3.92,11.34,11.24,11.34,19.64h-22.68c0,12.52,10.15,22.68,22.68,22.68s22.68-10.15,22.68-22.68S57.88,5.19,45.35,5.19Z"
      />
      <path
        className="eplix_logo-4"
        d="m45.35,27.87h-22.68c0,8.39,4.56,15.71,11.34,19.64,6.78-3.92,11.34-11.24,11.34-19.64h0Z"
      />
      <path
        className="eplix_logo-3"
        d="m22.68,27.87h22.68c0-8.39-4.56-15.71-11.34-19.64-6.78,3.92-11.34,11.24-11.34,19.64h0Z"
      />
      <g>
        <rect
          className="eplix_logo-1"
          x="225.41"
          y="5.19"
          width="7.93"
          height="45.35"
          rx="3.97"
          ry="3.97"
        />
        <path
          className="eplix_logo-1"
          d="m150.18,59.95h.02c1.18,0,2.17-.39,2.86-1.12.69-.74,1.05-1.76,1.05-3.04v-11.45c3.55,4,8.87,6.18,15.28,6.21h.1c12.67,0,21.21-9.07,21.26-22.59.05-13.56-8.46-22.71-21.19-22.76h-.11c-6.36,0-11.66,2.14-15.21,6.09v-1.89c.01-1.23-.34-2.27-1.02-3.01-.7-.77-1.69-1.18-2.88-1.18h-.02c-2.35,0-3.82,1.59-3.83,4.16l-.14,46.4c0,1.28.32,2.3.97,3.03.67.75,1.65,1.15,2.84,1.16Zm18.36-47.44h.07c8.6.03,14.35,6.23,14.31,15.42-.04,9.16-5.81,15.31-14.37,15.31h-.07c-8.46-.03-14.35-6.37-14.31-15.42.02-4.51,1.42-8.36,4.07-11.13,2.6-2.73,6.16-4.17,10.29-4.17Z"
        />
        <path
          className="eplix_logo-5"
          d="m117.14,5.19c-13.24-.05-22.3,9.13-22.35,22.59-.05,13.55,8.94,22.71,22.61,22.77,7.45.03,13.69-2.93,17.65-7.79.53-.72.8-1.53.8-2.35,0-2.17-1.48-3.71-3.67-3.72-1.23,0-2.19.53-2.99,1.43-2.55,3.06-6.59,5.12-11.77,5.1-7.98-.03-13.39-4.93-14.59-12.34l30.77.12c3.07.01,4.92-1.79,4.93-4.95v-.18c.05-11.56-9.03-20.63-21.39-20.68Zm-14.1,18.64c1.52-6.86,6.8-11.36,14.07-11.33,7.28.03,12.52,4.57,13.98,11.44l-28.05-.11Z"
        />
        <path
          className="eplix_logo-1"
          d="m281.83,43l-13.97-15.16,13.97-15.16c2.24-2.22,2.57-4.67.87-6.38,0,0-.01-.01-.01-.01-1.7-1.7-4.21-1.39-6.41.79l-13.77,14.95-13.74-14.91c-2.19-2.18-4.7-2.49-6.4-.79h-.01c-1.7,1.73-1.37,4.17.87,6.39l13.93,15.12-14,15.2c-1.07,1.06-1.72,2.22-1.88,3.35-.16,1.16.2,2.23,1.05,3.08h.01c.84.86,1.9,1.22,3.04,1.05,1.1-.16,2.25-.81,3.32-1.87l13.81-14.99,13.77,14.95c1.07,1.06,2.21,1.71,3.31,1.87,1.15.17,2.2-.19,3.04-1.03,0,0,.01-.01.01-.01.85-.85,1.21-1.92,1.05-3.08-.15-1.13-.8-2.28-1.87-3.34Z"
        />
        <path
          className="eplix_logo-1"
          d="m214.33,50.55c-8.99,0-16.31-7.32-16.31-16.31V3.9c0-2.15,1.75-3.9,3.9-3.9s3.9,1.75,3.9,3.9v30.34c0,4.69,3.82,8.51,8.51,8.51,2.15,0,3.9,1.75,3.9,3.9s-1.75,3.9-3.9,3.9Z"
        />
      </g>
    </svg>
  )
}
