import { Listbox } from '@headlessui/react'
import {
  Children,
  ElementType,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  isValidElement,
  useMemo,
} from 'react'
import { Menu, MenuList } from '../menu-list'
import clsx from 'clsx'

import classes from './select-input.module.scss'
import { SelectInputOptionGroup } from './select-input-option-group'

export type SelectInputOptionsProps = HTMLProps<HTMLUListElement> & {
  open?: boolean
  as?: ElementType
  menuListClassName?: string
}

export const SelectInputOptions = forwardRef(
  (
    {
      as,
      open,
      children,
      menuListClassName,
      ...props
    }: SelectInputOptionsProps,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    // split the SelectItemOptions into subgroups
    const itemGroups = useMemo(() => {
      const childrenArr = Children.toArray(children) as Pick<
        SelectInputOptionsProps,
        'children'
      >[]

      // each SelectInputOptionGroup element adds a new subgroup
      return childrenArr.reduce<Pick<SelectInputOptionsProps, 'children'>[][]>(
        (acc, child) => {
          if (
            (isValidElement(child) && child.type === SelectInputOptionGroup) ||
            acc.length === 0
          ) {
            // add a subgroup
            acc.push([])
          }
          // push the current child always into the latest subgroup
          acc[acc.length - 1].push(child)
          return acc
        },
        [],
      )
    }, [children])

    return open ? (
      <Listbox.Options
        static
        {...props}
        className={clsx(classes.options, props.className)}
        ref={ref}
        variant={'select'}
        as={as || Menu}
      >
        {itemGroups.map((options, index) => (
          <MenuList
            className={clsx(classes.optionGroupList, menuListClassName)}
            key={index}
            role={'group'}
          >
            {options}
          </MenuList>
        ))}
      </Listbox.Options>
    ) : null
  },
)
