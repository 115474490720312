import {
  AcceptLegalDocumentsRequest,
  ActionResponse,
  AuthenticationRequest,
  ErrorResponse,
  ForgotPasswordRequest,
  ForgotPasswordVerifySMSCodeRequest,
  ResendSMSCodeResponse,
  ResetPasswordRequest,
  VerifySMSCodeRequest,
} from '@/api'
import { getJSON, postJSON } from '@/framework/common'

import { CurrentUser, PublicCurrentUser, UserSession } from './auth-types'

export const startLoginRequest = async (
  loginValues: AuthenticationRequest,
): Promise<Pick<UserSession, 'status'>> => {
  return await postJSON<Pick<UserSession, 'status'>, AuthenticationRequest>(
    '/api/auth/login',
    loginValues,
  )
}

export const sendNumberRequest = async (loginValues: ForgotPasswordRequest) => {
  return await postJSON<PublicCurrentUser, ForgotPasswordRequest>(
    '/api/auth/forgot-password',
    loginValues,
  )
}

export const addNewPasswordRequest = async (
  payload: Pick<ResetPasswordRequest, 'password'>,
) => {
  return postJSON<PublicCurrentUser, Pick<ResetPasswordRequest, 'password'>>(
    '/api/auth/new-password',
    payload,
  )
}

export const validatePasswordSmsRequest = async (
  validationValues: Pick<ForgotPasswordVerifySMSCodeRequest, 'smsCode'>,
) => {
  return postJSON<
    PublicCurrentUser,
    Pick<ForgotPasswordVerifySMSCodeRequest, 'smsCode'>
  >('/api/auth/forgot-password-validate', validationValues)
}

export const completeLoginRequest = async (
  validationValues: Pick<VerifySMSCodeRequest, 'smsCode'>,
) => {
  return postJSON<CurrentUser, Pick<VerifySMSCodeRequest, 'smsCode'>>(
    '/api/auth/validate',
    validationValues,
  )
}

export const completeResendSmsRequest = async (
  url = '/api/auth/resend-sms',
) => {
  return getJSON<ResendSMSCodeResponse>(url)
}

export const resetAuthCurrentUser = async () => {
  return getJSON<PublicCurrentUser>('/api/auth/reset-current-user')
}

export const logout = async () => {
  return getJSON<PublicCurrentUser>('/api/auth/logout')
}

export const submitAcceptedLegalDocuments = async (
  req: AcceptLegalDocumentsRequest,
) => {
  return postJSON<ActionResponse | ErrorResponse, AcceptLegalDocumentsRequest>(
    '/api/auth/legal-documents/accepted',
    req,
  )
}
