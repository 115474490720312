import { Placement } from '../tooltip-content'

export type DomRect = Pick<DOMRect, 'left' | 'top' | 'height' | 'width'>

export const calcDomRectPosition: (
  targetRect: DOMRect,
  popperRect: DOMRect,
  placement: Placement | null,
  screenWidth: number,
  screenHeight: number,
) => DomRect = (
  targetRect,
  popperRect,
  placement,
  screenWidth,
  screenHeight,
) => {
  const top = targetRect.top

  const bottom = targetRect.top + targetRect.height
  const left = targetRect.left
  const right = targetRect.left + targetRect.width
  const halfWidth = popperRect.width / 2
  const halfHeight = popperRect.height / 2

  const spacer = 18

  const spikeWidth = 24

  const position = {
    left: 0,
    top: 0,
    height: popperRect.height,
    width: popperRect.width,
  }

  if (placement === 'top' || !placement) {
    position.top = top - popperRect.height - spacer
    position.left = left - halfWidth + targetRect.width / 2
  }
  if (placement === 'bottom') {
    position.top = bottom + spacer
    position.left = left - halfWidth + targetRect.width / 2
  }
  if (placement === 'right') {
    position.left = right + spacer
    position.top = top - halfHeight + targetRect.height / 2
  }
  if (placement === 'left') {
    position.left = left - popperRect.width - spacer
    position.top = top - halfHeight + targetRect.height / 2
  }

  if (placement === 'top' || placement === 'bottom') {
    // ------------ HANDLE LEFT SIDE EDGE ------------ //
    if (position.left <= 0) {
      position.left = 0

      if (targetRect.left + (targetRect.width - spikeWidth) < 0) {
        position.left = targetRect.left + (targetRect.width - spikeWidth)
      }
    }

    // ---------- HANDLE RIGHT SIDE EDGE -------- //
    if (position.left + popperRect.width >= screenWidth) {
      position.left = screenWidth - popperRect.width

      if (targetRect.left + spikeWidth >= screenWidth) {
        position.left = targetRect.left + spikeWidth - popperRect.width
      }
    }
  }

  if (placement === 'left' || placement === 'right') {
    // ---------- HANDLE TOP SIDE EDGE -------- //
    if (position.top <= 0) {
      position.top = 0

      if (targetRect.top + (targetRect.height - spikeWidth) <= 0) {
        position.top = targetRect.top + (targetRect.height - spikeWidth)
      }
    }

    // ---------- HANDLE BOTTOM SIDE EDGE -------- //
    if (position.top + popperRect.height >= screenHeight) {
      position.top = screenHeight - popperRect.height

      if (targetRect.top + spikeWidth >= screenHeight) {
        position.top = targetRect.top + spikeWidth - popperRect.height
      }
    }
  }

  return position
}
