// TODO: Refactor all related files and remove/replace those that came from eframe-ui during next cleanup stage
import { useSlider } from '@react-aria/slider'
import { useSliderState } from '@react-stately/slider'
import { useNumberFormatter } from '@react-aria/i18n'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { SliderMinMaxDisplay } from './slider-min-max-display'
import { SliderBar } from './slider-bar'
import { SliderThumb } from './slider-thumb'
import SliderSections, { RawSectionData } from './slider-sections'
import { normalizeValueToPercentage } from './utils'
import ThumbTooltip, { TooltipTemplateFunction } from './thumb-tooltip'
import SliderDotValue from './slider-dot-value'
import PointerTooltip from './pointer-tooltip'
import SliderPointerValue from './slider-pointer-value'
import debounce from 'lodash.debounce'
import { asyncCall } from '@eplix/frontend/common/helpers/async-wrapper'

type OnValueChangeCallback = (value: number) => void
const currentValue_THUMB_ID = 0

export type SliderRangedProps = {
  id?: number
  label: string
  isDisabled?: boolean
  initialValue: number
  initialValueColor: string
  maxThumbValue: number
  step: number
  formatOptions: { numberingSystem: string }
  sections: RawSectionData[]
  sectionBorderColor: string
  calculateValueTooltip: TooltipTemplateFunction
  pointer?: { pointerValue: number; pointerLabel: string; pointerColor: string }
  showMinMax: boolean
  onValueChange?: OnValueChangeCallback
  onDragValueChangeTimeout?: number
  changeWhileDragging?: boolean
}

export const SliderRanged = ({
  label,
  formatOptions,
  isDisabled = false,
  initialValue,
  initialValueColor,
  maxThumbValue,
  sections,
  sectionBorderColor,
  calculateValueTooltip,
  pointer,
  step,
  showMinMax,
  onValueChange = () => {},
  onDragValueChangeTimeout = 200,
  changeWhileDragging = false,
}: SliderRangedProps) => {
  const hasChangedValue = useRef(false)
  const minValue = useMemo(() => sections[0].startValue ?? 0, [sections])
  const maxValue = useMemo(
    () => sections[sections.length - 1].endValue,
    [sections],
  )

  const trackRef = useRef<HTMLDivElement>(null)

  const numberFormatter = useNumberFormatter(formatOptions)
  const state = useSliderState({
    maxValue,
    minValue,
    defaultValue: [initialValue, maxThumbValue],
    numberFormatter,
  })

  const { groupProps, trackProps, labelProps, outputProps } = useSlider(
    {
      label,
      // @ts-ignore
      formatOptions,
      maxValue,
      defaultValue: [initialValue, maxThumbValue],
      step,
    },
    state,
    trackRef,
  )

  const currentValue = state.getThumbValue(currentValue_THUMB_ID)
  if (currentValue !== initialValue) {
    hasChangedValue.current = true
  }

  const currentValueSectionColor = sections.find(
    (section) =>
      currentValue >= section.startValue && currentValue <= section.endValue,
  )?.color

  // enable/disable primary thumb
  useEffect(() => {
    state.setThumbEditable(currentValue_THUMB_ID, !isDisabled)
  }, [state, isDisabled])

  // disable the secondary thumb (the one used to block values after maxThumbValue)
  useEffect(() => {
    state.setThumbEditable(1, false)
  }, [state, maxThumbValue])

  const debouncedValueChangeHandler = useCallback(
    (value) => debounce(() => onValueChange(value), onDragValueChangeTimeout),
    [onValueChange, onDragValueChangeTimeout],
  )

  useEffect(() => {
    if (
      changeWhileDragging &&
      debouncedValueChangeHandler &&
      hasChangedValue.current
    ) {
      debouncedValueChangeHandler(currentValue)
    }
  }, [debouncedValueChangeHandler, currentValue, changeWhileDragging])

  const isThumbDragging = state.isThumbDragging(currentValue_THUMB_ID)
  useEffect(() => {
    !isThumbDragging && !changeWhileDragging && onValueChange(currentValue)
  }, [currentValue, isThumbDragging, onValueChange, changeWhileDragging])

  const thumbReferenceElement = useRef(null)
  const thumbPopperElement = useRef(null)
  const [thumbArrowElement, setThumbArrowElement] = useState(null)
  const {
    styles: thumbStyles,
    attributes: thumbAttributes,
    update: thumbUpdate,
  } = usePopper(thumbReferenceElement.current, thumbPopperElement.current, {
    placement: 'top',
    modifiers: [
      { name: 'arrow', options: { element: thumbArrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  const pointerReferenceElement = useRef(null)
  const pointerPopperElement = useRef(null)
  const {
    styles: pointerStyles,
    attributes: pointerAttributes,
    update: pointerUpdate,
  } = usePopper(pointerReferenceElement.current, pointerPopperElement.current, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 2],
        },
      },
    ],
  })
  const updateRange = useCallback(async () => {
    if (typeof thumbUpdate === 'function') {
      hasChangedValue.current = true
      await thumbUpdate()
    }
  }, [thumbUpdate])

  useEffect(() => {
    asyncCall(updateRange)
  }, [updateRange])

  //Fix pointer label location
  useEffect(() => {
    if (pointerPopperElement?.current) {
      ;(pointerPopperElement.current as HTMLElement).style.visibility = 'hidden'
    }
  }, [pointerPopperElement])

  //show tooltip and update it's location
  useEffect(() => {
    if (typeof pointerUpdate === 'function') {
      setTimeout(async () => {
        if (pointerPopperElement?.current) {
          ;(pointerPopperElement.current as HTMLElement).style.visibility =
            'visible'
        }
        await pointerUpdate()
      }, 50)
    }
  }, [pointerUpdate, pointerPopperElement])

  useEffect(() => {
    thumbUpdate?.()
  }, [currentValue, thumbUpdate])

  return (
    <div
      {...groupProps}
      className="relative touch-none text-text-primary flex flex-col min-h-13 pointer-events-none cursor-none"
      aria-disabled={isDisabled || undefined}
    >
      {label && (
        <label className={'sr-only'} {...labelProps}>
          {label}
        </label>
      )}
      <output className={'sr-only'} {...outputProps}>
        {currentValue}
      </output>

      <SliderBar
        {...trackProps}
        ref={trackRef}
        isDisabled={isDisabled}
        className={'rounded-l-lg rounded-r-lg'}
      >
        <SliderThumb
          index={currentValue_THUMB_ID}
          state={state}
          trackRef={trackRef}
          handleRef={thumbReferenceElement}
          setIsElevated={() => {}}
          isElevated={false}
          showValue={false}
          isDisabled={isDisabled}
          dividerIndex={0}
        />
        <ThumbTooltip
          role={'tooltip'}
          innerRef={thumbPopperElement}
          style={thumbStyles.popper}
          arrowStyle={thumbStyles.arrow}
          arrowRef={setThumbArrowElement}
          className={'pointer-events-none cursor-none'}
          text={calculateValueTooltip(currentValue)}
          borderColor={currentValueSectionColor ?? '#9FC5E8'}
          {...thumbAttributes.popper}
        />
        <SliderDotValue
          value={normalizeValueToPercentage(initialValue, minValue, maxValue)}
          color={initialValueColor}
        />
        {pointer && (
          <>
            <SliderPointerValue
              innerRef={pointerReferenceElement}
              value={normalizeValueToPercentage(
                pointer.pointerValue,
                minValue,
                maxValue,
              )}
              color={pointer.pointerColor}
            />
            <PointerTooltip
              role={'tooltip'}
              innerRef={pointerPopperElement}
              style={pointerStyles.popper}
              className={'pointer-events-none cursor-none'}
              text={pointer.pointerLabel}
              {...pointerAttributes.popper}
            />
          </>
        )}
        <SliderSections
          sectionBorderColor={sectionBorderColor}
          sections={sections}
          minValue={minValue}
          maxValue={maxValue}
        />
      </SliderBar>
      {showMinMax && (
        <SliderMinMaxDisplay isDisabled={isDisabled}>
          <div className={'flex gap-1'}>
            <span>{minValue}</span>
            <span>{label}</span>
          </div>
          <div className={'flex gap-1'}>
            <span>{maxValue}</span>
            <span>{label}</span>
          </div>
        </SliderMinMaxDisplay>
      )}
    </div>
  )
}
