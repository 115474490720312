import React, { Dispatch, RefObject, SetStateAction, useRef } from 'react'
import { useSliderThumb } from '@react-aria/slider'
import { SliderState } from '@react-stately/slider'
import clsx from 'clsx'

type SliderThumbProps = {
  state: SliderState
  isDisabled?: boolean
  isElevated?: boolean
  setIsElevated: Dispatch<SetStateAction<boolean>>
  index: number
  showValue?: boolean
  trackRef: RefObject<HTMLDivElement>
  handleRef?: RefObject<HTMLDivElement>
  dividerIndex?: number
}

const Handle = ({
  isDisabled,
  isDragging,
  innerRef,
  leftOffset,
  style,
  ...otherProps
}: {
  isDisabled: boolean | undefined
  isDragging: boolean | undefined
  leftOffset: number
  style: object | undefined
  innerRef: React.Ref<HTMLDivElement> | undefined
}) => {
  const className = clsx(
    `w-6 h-6 rounded-full top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-auto`,
    isDragging
      ? 'outline outline-offset-2 outline-2 outline-pink-500 shadow-2xl'
      : '',
    isDisabled
      ? 'cursor-default'
      : 'border-solid border-2 border-indigo-600 cursor-pointer',
  )

  return (
    <div
      {...otherProps}
      className={className}
      style={{
        ...style,
        position: 'absolute',
        left: `${leftOffset}%`,
        zIndex: 3,
        backgroundColor: `${isDisabled ? 'transparent' : 'white'}`,
        border: `${isDragging ? 2 : 1}px ${isDisabled ? 'none' : 'solid'} ${
          isDragging ? '#61a0d9' : 'gray'
        }`,
        minWidth: '24px',
        minHeight: '24px',
      }}
      ref={innerRef}
    ></div>
  )
}

export const SliderThumb = ({
  state,
  index,
  trackRef,
  handleRef,
  isElevated,
  isDisabled,
}: SliderThumbProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { thumbProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state,
  )

  return (
    // @ts-ignore
    <Handle
      {...thumbProps}
      isDragging={state.isThumbDragging(0) || isElevated}
      isDisabled={isDisabled}
      leftOffset={state.getThumbPercent(index) * 100}
      innerRef={handleRef}
    />
  )
}
