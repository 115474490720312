import Link, { LinkProps } from 'next/link'
import { ReactElement } from 'react'
import { useClientRoute } from '../hooks/use-client-route'
import { useClientRouter } from '@/framework/client-router'
import { RouteExp } from '../lib/route-exp'

export interface RouteLinkProps<TParams extends Record<string, unknown>>
  extends Omit<LinkProps, 'href' | 'shallow'> {
  route: string | RouteExp<TParams>
  params?: TParams
  children: ReactElement
}

export function ClientLink<TParams extends Record<string, unknown>>({
  route,
  params,
  children,
  ...nextLinkProps
}: RouteLinkProps<TParams>) {
  const routeExp = useClientRoute(route)
  const [, basePath] = useClientRouter()
  return (
    <Link
      {...nextLinkProps}
      href={`${basePath}${routeExp.resolve(params)}`}
      passHref
      shallow
      legacyBehavior
    >
      {children}
    </Link>
  )
}
