export const flagPositions = {
  dz: 'center 0.2287%',
  ao: 'center 0.4524%',
  bj: 'center 0.6721%',
  bw: 'center 0.8958%',
  bf: 'center 1.1162%',
  bi: 'center 1.3379%',
  cm: 'center 1.5589%',
  cv: 'center 1.7805%',
  cf: 'center 2.0047%',
  td: 'center 2.2247%',
  cd: 'left 2.4467%',
  dj: 'left 2.6674%',
  eg: 'center 2.8931%',
  gq: 'center 3.1125%',
  er: 'left 3.3325%',
  et: 'center 3.5542%',
  ga: 'center 3.7759%',
  gm: 'center 4.0015%',
  gh: 'center 4.2229%',
  gn: 'center 4.441%',
  gw: 'left 4.66663%',
  ci: 'center 4.8844%',
  ke: 'center 5.1061%',
  ls: 'center 5.3298%',
  lr: 'left 5.5495%',
  ly: 'center 5.7712%',
  mg: 'center 5.994%',
  mw: 'center 6.2156%',
  ml: 'center 6.4363%',
  mr: 'center 6.658%',
  mu: 'center 6.8805%',
  yt: 'center 7.1038%',
  ma: 'center 7.3231%',
  mz: 'left 7.5448%',
  na: 'left 7.7661%',
  ne: 'center 7.98937%',
  ng: 'center 8.2099%',
  cg: 'center 8.4316%',
  re: 'center 8.6533%',
  rw: 'right 8.875%',
  sh: 'center 9.0967%',
  st: 'center 9.32237%',
  sn: 'center 9.5426%',
  sc: 'left 9.7628%',
  sl: 'center 9.9845%',
  so: 'center 10.2052%',
  za: 'left 10.4269%',
  ss: 'left 10.6486%',
  sd: 'center 10.8703%',
  sr: 'center 11.0945%',
  sz: 'center 11.3135%',
  tg: 'left 11.5354%',
  tn: 'center 11.7593%',
  ug: 'center 11.9799%',
  tz: 'center 12.2005%',
  eh: 'center 12.4222%',
  ye: 'center 12.644%',
  zm: 'center 12.8664%',
  zw: 'left 13.0873%',
  ai: 'center 13.309%',
  ag: 'center 13.5307%',
  ar: 'center 13.7524%',
  aw: 'left 13.9741%',
  bs: 'left 14.1958%',
  bb: 'center 14.4175%',
  bq: 'center 14.6415%',
  bz: 'center 14.8609%',
  bm: 'center 15.0826%',
  bo: 'center 15.306%',
  vg: 'center 15.528%',
  br: 'center 15.7496%',
  ca: 'center 15.9694%',
  ky: 'center 16.1911%',
  cl: 'left 16.4128%',
  co: 'left 16.6345%',
  km: 'center 16.8562%',
  cr: 'center 17.0779%',
  cu: 'left 17.2996%',
  cw: 'center 17.5213%',
  dm: 'center 17.743%',
  do: 'center 17.968%',
  do1: 'center 17.968%',
  do2: 'center 17.968%',
  ec: 'center 18.1864%',
  sv: 'center 18.4081%',
  fk: 'center 18.6298%',
  gf: 'center 18.8515%',
  gl: 'left 19.0732%',
  gd: 'center 19.2987%',
  gp: 'center 19.518%',
  gt: 'center 19.7383%',
  gy: 'center 19.96%',
  ht: 'center 20.1817%',
  hn: 'center 20.4034%',
  jm: 'center 20.6241%',
  mq: 'center 20.8468%',
  mx: 'center 21.0685%',
  ms: 'center 21.2902%',
  ni: 'center 21.5119%',
  pa: 'center 21.7336%',
  py: 'center 21.9553%',
  pe: 'center 22.177%',
  pr: 'left 22.4002%',
  bl: 'center 22.6204%',
  kn: 'center 22.8421%',
  lc: 'center 23.0638%',
  pm: 'center 23.2855%',
  vc: 'center 23.5072%',
  sx: 'left 23.732%',
  tt: 'center 23.9506%',
  tc: 'center 24.1723%',
  us: 'center 24.394%',
  vi: 'center 24.6157%',
  uy: 'left 24.8374%',
  ve: 'center 25.0591%',
  ab: 'center 25.279%',
  af: 'center 25.5025%',
  az: 'center 25.7242%',
  bd: 'center 25.9459%',
  bt: 'center 26.1676%',
  bn: 'center 26.3885%',
  kh: 'center 26.611%',
  cn: 'left 26.8327%',
  ge: 'center 27.0544%',
  hk: 'center 27.2761%',
  in: 'center 27.4978%',
  id: 'center 27.7195%',
  jp: 'center 27.9412%',
  kz: 'center 28.1615%',
  la: 'center 28.3846%',
  mo: 'center 28.6063%',
  my: 'center 28.829%',
  mv: 'center 29.0497%',
  mn: 'left 29.2714%',
  mm: 'center 29.4931%',
  np: 'left 29.7148%',
  kp: 'left 29.9365%',
  mp: 'center 30.1582%',
  pw: 'center 30.3799%',
  pg: 'center 30.6016%',
  ph: 'left 30.8233%',
  sg: 'left 31.045%',
  kr: 'center 31.2667%',
  lk: 'right 31.4884%',
  tw: 'left 31.7101%',
  tj: 'center 31.9318%',
  th: 'center 32.1535%',
  tl: 'left 32.3752%',
  tm: 'center 32.5969%',
  vn: 'center 32.8186%',
  al: 'center 33.0403%',
  ad: 'center 33.25975%',
  am: 'center 33.4837%',
  at: 'center 33.7054%',
  by: 'left 33.9271%',
  be: 'center 34.1488%',
  ba: 'center 34.3705%',
  bg: 'center 34.5922%',
  hr: 'center 34.8139%',
  cy: 'center 35.0356%',
  cz: 'left 35.2555%',
  dk: 'center 35.479%',
  ee: 'center 35.7007%',
  fo: 'center 35.9224%',
  fi: 'center 36.1441%',
  fr: 'center 36.3658%',
  de: 'center 36.5875%',
  gi: 'center 36.8092%',
  gr: 'left 37.0309%',
  hu: 'center 37.2526%',
  is: 'center 37.4743%',
  ie: 'center 37.696%',
  im: 'center 37.9177%',
  it: 'center 38.1394%',
  je: 'center 38.3611%',
  xk: 'center 38.5828%',
  lv: 'center 38.8045%',
  li: 'left 39.0262%',
  lt: 'center 39.2479%',
  lu: 'center 39.4696%',
  mt: 'left 39.6913%',
  md: 'center 39.913%',
  mc: 'center 40.1347%',
  me: 'center 40.3564%',
  nl: 'center 40.5781%',
  mk: 'center 40.7998%',
  no: 'center 41.0215%',
  pl: 'center 41.2432%',
  pt: 'center 41.4649%',
  ro: 'center 41.6866%',
  ru: 'center 41.9083%',
  sm: 'center 42.13%',
  rs: 'center 42.3517%',
  sk: 'center 42.5734%',
  si: 'center 42.7951%',
  es: 'left 43.0168%',
  se: 'center 43.2385%',
  ch: 'center 43.4602%',
  tr: 'center 43.6819%',
  ua: 'center 43.9036%',
  gb: 'center 44.1253%',
  va: 'right 44.347%',
  bh: 'center 44.5687%',
  ir: 'center 44.7904%',
  iq: 'center 45.0121%',
  il: 'center 45.2338%',
  kw: 'left 45.4555%',
  jo: 'left 45.6772%',
  kg: 'center 45.897%',
  lb: 'center 46.1206%',
  om: 'left 46.3423%',
  pk: 'center 46.561%',
  ps: 'center 46.7857%',
  qa: 'center 47.0074%',
  sa: 'center 47.2291%',
  sy: 'center 47.4508%',
  ae: 'center 47.6725%',
  uz: 'left 47.8942%',
  as: 'right 48.1159%',
  au: 'center 48.3376%',
  cx: 'center 48.5593%',
  cc: 'center 48.781%',
  ck: 'center 49.002%',
  fj: 'center 49.2244%',
  pf: 'center 49.4445%',
  gu: 'center 49.6678%',
  ki: 'center 49.8895%',
  mh: 'left 50.1112%',
  fm: 'center 50.3329%',
  nc: 'center 50.5546%',
  nz: 'center 50.7763%',
  nr: 'left 50.998%',
  nu: 'center 51.2197%',
  nf: 'center 51.4414%',
  ws: 'left 51.6631%',
  sb: 'left 51.8848%',
  tk: 'center 52.1065%',
  to: 'left 52.3282%',
  tv: 'center 52.5499%',
  vu: 'left 52.7716%',
  wf: 'center 52.9933%',
}
