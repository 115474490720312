import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import { IconButton } from '../buttons'
import { Typo } from '../typography'
import { CrossIcon } from '../icons'
import classes from './dialog.module.scss'
import { getFontsActions } from '../../frontend/common/actions/fonts-actions'
import { mediaQueries } from '../tests/utils/helpers'
import useMediaQuery from '@mui/material/useMediaQuery'

const { getTitleVariantFont } = getFontsActions()

export interface DialogHeaderProps extends HTMLAttributes<HTMLElement> {
  closeIconText?: string
  onClose(value: boolean): void
}

export const DialogHeader = forwardRef(
  (
    {
      children,
      closeIconText = 'Close dialog',
      onClose,
      ...props
    }: DialogHeaderProps,
    ref: ForwardedRef<HTMLHeadElement>,
  ) => {
    const { xs } = mediaQueries
    const isMobile = useMediaQuery(xs)
    const titleVariantFont = getTitleVariantFont(isMobile)
    return (
      <header ref={ref} className={classes.header} {...props}>
        <HeadlessDialog.Title
          as={Typo}
          variant={titleVariantFont}
          color="text-primary"
        >
          {children}
        </HeadlessDialog.Title>
        <IconButton
          variant={'text'}
          size={'small'}
          title={closeIconText}
          icon={<CrossIcon />}
          onClick={() => onClose(false)}
        />
      </header>
    )
  },
)
