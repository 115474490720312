export const GL = {
  8750: 'Glarus',
  8752: 'Näfels',
  8753: 'Mollis',
  8754: 'Netstal',
  8755: 'Ennenda',
  8756: 'Mitlödi',
  8757: 'Filzbach',
  8758: 'Obstalden',
  8759: 'Netstal',
  8762: 'Schwanden GL',
  8765: 'Engi',
  8766: 'Matt',
  8767: 'Elm',
  8772: 'Nidfurn',
  8773: 'Haslen GL',
  8774: 'Leuggelbach',
  8775: 'Luchsingen',
  8777: 'Diesbach GL',
  8782: 'Rüti GL',
  8783: 'Linthal',
  8784: 'Braunwald',
  8865: 'Bilten',
  8866: 'Ziegelbrücke',
  8867: 'Niederurnen',
  8868: 'Oberurnen',
  8874: 'Mühlehorn',
}
