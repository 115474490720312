import { IconProps } from '@/components/risk-profile-chart/types'

export const HighRisk = ({ x, y }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="36"
      fill="none"
      viewBox="0 0 120 36"
      x={x}
      y={y}
    >
      <rect
        width="119"
        height="35"
        x="0.5"
        y="0.5"
        fill="#F4F7FA"
        rx="7.5"
      ></rect>
      <path
        fill="#232323"
        d="M37.541 13.6h1.356V22h-1.356v-3.672h-4.308V22h-1.356v-8.4h1.356v3.432h4.308V13.6zm4.393-1.176c.516 0 .888.348.888.828 0 .492-.373.852-.889.852-.492 0-.876-.36-.876-.852 0-.48.384-.828.877-.828zM41.262 22v-6.6h1.355V22h-1.355zm8.665-6.6h1.356v6.516c0 1.968-1.236 3.228-3.192 3.228-1.572 0-3-.816-3.288-2.46h1.404c.228.852.96 1.248 1.848 1.248 1.212 0 1.872-.684 1.872-1.968v-1.128c-.492.732-1.284 1.236-2.304 1.236-1.764 0-3.048-1.428-3.048-3.408s1.308-3.408 3.048-3.408c1.02 0 1.824.516 2.304 1.272V15.4zm-2.016 5.376c1.152 0 2.028-.876 2.028-2.112 0-1.224-.876-2.112-2.028-2.112-1.152 0-1.968.888-1.968 2.112 0 1.236.828 2.112 1.968 2.112zm9.178-5.52c1.476 0 2.376.972 2.376 2.58V22H58.11v-3.888c0-1.02-.456-1.56-1.452-1.56-1.152 0-1.776.756-1.776 2.028V22h-1.356v-9.24h1.356v3.72c.468-.768 1.188-1.224 2.208-1.224zm12.238 0c.168 0 .3.012.396.036v1.38c-.12-.012-.228-.024-.408-.024-1.236 0-2.184.696-2.184 2.328V22h-1.356v-6.6h1.356v1.308c.396-.924 1.152-1.452 2.196-1.452zm2.747-2.832c.516 0 .888.348.888.828 0 .492-.372.852-.888.852-.492 0-.876-.36-.876-.852 0-.48.384-.828.876-.828zM71.402 22v-6.6h1.356V22h-1.356zm6.446.144c-1.74 0-2.988-.84-3.24-2.268h1.38c.204.732.744 1.068 1.884 1.068.876 0 1.416-.264 1.416-.756 0-.552-.624-.636-1.728-.9-1.848-.456-2.628-.828-2.628-1.932 0-1.332 1.128-2.1 2.784-2.1 1.692 0 2.64.804 2.88 2.004h-1.368c-.192-.54-.696-.804-1.536-.804-.876 0-1.392.3-1.392.756 0 .42.408.528 1.56.804 1.788.42 2.82.732 2.82 2.016 0 1.416-1.272 2.112-2.832 2.112zM88.816 22h-1.752l-3.108-3.048V22H82.6v-9.24h1.356v5.496l2.856-2.856h1.74l-3.228 3.132L88.816 22z"
      ></path>
      <rect
        width="119"
        height="35"
        x="0.5"
        y="0.5"
        stroke="#C1C4C7"
        rx="7.5"
      ></rect>
    </svg>
  )
}
