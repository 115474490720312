import { ArrayOfSubtitles, IndexButtonVariant, LabelVariant } from './types'
import classes from './index-button.module.scss'

const getIndexVariantClass = (variant?: IndexButtonVariant) => {
  switch (variant) {
    case 'medium':
      return classes.medium
    case 'small':
      return classes.small
    default:
      return classes.big
  }
}

const getLabelClass = (labeled?: LabelVariant) => {
  switch (labeled) {
    case 'blue':
      return classes.blueLabel
    case 'turquoise':
      return classes.turquoiseLabel
    case 'dark-blue':
      return classes.darkBlue
    default:
      return classes.aquaLabel
  }
}

const getIndexIconClass = (variant?: IndexButtonVariant) => {
  switch (variant) {
    case 'big':
      return classes.bigIcon
    case 'medium':
      return classes.mediumIcon
    case 'small':
      return classes.smallIcon
    default:
      return classes.bigIcon
  }
}

const getShouldRenderSubtitleArray = (subtitle?: string | ArrayOfSubtitles) =>
  typeof subtitle === 'object'

export const getButtonActions = () => {
  return {
    getShouldRenderSubtitleArray,
    getIndexVariantClass,
    getLabelClass,
    getIndexIconClass,
  }
}
