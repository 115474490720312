export const getProgressSteps = (
  totalSteps: number,
  currentStep: number,
  width: number,
) => {
  const steps = []
  for (let i = 1; i < totalSteps; i++) {
    i < currentStep
      ? steps.push(
          <div key={i} className="bg-blue" style={{ width: `${width}%` }} />,
        )
      : steps.push(
          <div
            key={i}
            className="bg-background-secondary"
            style={{ width: `${width}%` }}
          />,
        )
  }
  return steps
}
