import { MediaQueryProp } from '../../utils/css-helpers/types'

export const breakpoints: Record<MediaQueryProp, MediaQueryProp> = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl',
  xxxxl: 'xxxxl',
}

export const mediaQueries: Record<MediaQueryProp, string> = {
  xs: '(max-width:576px)',
  sm: '(min-width:576px) and (max-width:768px)',
  md: '(min-width:768px) and (max-width:992px)',
  lg: '(min-width:992px) and (max-width:1200px)',
  xl: '(min-width:1200px) and (max-width:1440px)',
  xxl: '(min-width:1440px) and (max-width:1600px)',
  xxxl: '(min-width:1600px) and (max-width:1900px)',
  xxxxl: '(min-width:1900px)',
}

export const screenWidth: Record<MediaQueryProp, number> = {
  xs: 500,
  sm: 650,
  md: 850,
  lg: 1000,
  xl: 1280,
  xxl: 1500,
  xxxl: 1700,
  xxxxl: 1900,
}

// Update as needed
export const screenHeight: Record<MediaQueryProp, number> = {
  xs: 900,
  sm: 900,
  md: 900,
  lg: 900,
  xl: 900,
  xxl: 900,
  xxxl: 900,
  xxxxl: 900,
}
