import { PortfolioDetails, ErrorResponse } from '@/api'
import { getPortfolio } from '@/features/portfolio/lib/overview-api-client'
import { GetPortfolioRequest } from '@/features/portfolio/lib/types'

import { useMutation } from '@/framework/common'

export const portfolioKey = 'portfolio'
export const portfolioDetailsKey = 'portfolioDetails'

export const useGetPortfolio = () => {
  return useMutation<PortfolioDetails | ErrorResponse, GetPortfolioRequest>(
    getPortfolio,
    portfolioKey,
  )
}
