export const VD = {
  1000: 'Lausanne',
  1001: 'Lausanne',
  1002: 'Lausanne',
  1003: 'Lausanne',
  1004: 'Lausanne',
  1005: 'Lausanne',
  1006: 'Lausanne',
  1007: 'Lausanne',
  1008: 'Prilly',
  1009: 'Pully',
  1010: 'Lausanne',
  1011: 'Lausanne',
  1012: 'Lausanne',
  1014: 'Lausanne Adm cant',
  1015: 'Lausanne',
  1017: 'Lausanne Veillon',
  1018: 'Lausanne',
  1019: 'Lausanne',
  1020: 'Renens VD',
  1022: 'Chavannes-Renens',
  1023: 'Crissier',
  1024: 'Ecublens VD',
  1025: 'St-Sulpice VD',
  1026: 'Echandens-Denges',
  1027: 'Lonay',
  1028: 'Préverenges',
  1029: 'Villars-Ste-Croix',
  1030: 'Bussigny-Lausanne',
  1031: 'Mex VD',
  1032: 'Romanel-s-Lausanne',
  1033: 'Cheseaux-Lausanne',
  1034: 'Boussens',
  1035: 'Bournens',
  1036: 'Sullens',
  1037: 'Etagnières',
  1038: 'Bercher',
  1039: 'Cheseaux Polyval',
  1040: 'Echallens',
  1041: 'Dommartin',
  1042: 'Bettens',
  1043: 'Sugnens',
  1044: 'Fey',
  1045: 'Ogens',
  1046: 'Rueyres',
  1047: 'Oppens',
  1052: 'Mont-sur-Lausanne',
  1053: 'Bretigny-Morrens',
  1054: 'Morrens VD',
  1055: 'Froideville',
  1058: 'Villars-Tiercelin',
  1059: 'Peney-le-Jorat',
  1061: 'Villars-Mendraz',
  1062: 'Sottens',
  1063: 'Peyres-Possens',
  1066: 'Epalinges',
  1068: 'Les Monts-de-Pully',
  1070: 'Puidoux-Gare',
  1071: 'Chexbres',
  1072: 'Forel (Lavaux)',
  1073: 'Savigny',
  1076: 'Ferlens VD',
  1077: 'Servion',
  1078: 'Essertes',
  1080: 'Les Cullayes',
  1081: 'Montpreveyres',
  1082: 'Corcelles-le-Jorat',
  1083: 'Mézières VD',
  1084: 'Carrouge VD',
  1085: 'Vulliens',
  1088: 'Ropraz',
  1090: 'La Croix (Lutry)',
  1091: 'Grandvaux',
  1092: 'Belmont-Lausanne',
  1093: 'La Conversion',
  1094: 'Paudex',
  1095: 'Lutry',
  1096: 'Cully',
  1097: 'Riex',
  1098: 'Epesses',
  1099: 'Montpreveyres Foto',
  1110: 'Morges',
  1112: 'Echichens',
  1113: 'St-Saphorin-Morges',
  1114: 'Colombier VD',
  1115: 'Vullierens',
  1116: 'Cottens VD',
  1117: 'Grancy',
  1121: 'Bremblens',
  1122: 'Romanel-sur-Morges',
  1123: 'Aclens',
  1124: 'Gollion',
  1125: 'Monnaz',
  1126: 'Vaux-sur-Morges',
  1127: 'Clarmont',
  1128: 'Reverolle',
  1131: 'Tolochenaz',
  1132: 'Lully VD',
  1134: 'Vufflens-Château',
  1135: 'Denens',
  1136: 'Bussy-Chardonney',
  1141: 'Sévery',
  1142: 'Pampigny',
  1143: 'Apples',
  1144: 'Ballens',
  1145: 'Bière',
  1146: 'Mollens VD',
  1147: 'Montricher',
  1148: "L'Isle",
  1149: 'Berolle',
  1162: 'St-Prex',
  1163: 'Etoy',
  1164: 'Buchillon',
  1165: 'Allaman',
  1166: 'Perroy',
  1167: 'Lussy-sur-Morges',
  1168: 'Villars-sous-Yens',
  1169: 'Yens',
  1170: 'Aubonne',
  1172: 'Bougy-Villars',
  1173: 'Féchy',
  1174: 'Montherod',
  1175: 'Lavigny',
  1176: 'St-Livres',
  1180: 'Rolle',
  1182: 'Gilly',
  1183: 'Bursins',
  1184: 'Vinzel',
  1185: 'Mont-sur-Rolle',
  1186: 'Essertines-Rolle',
  1187: 'St-Oyens',
  1188: 'Gimel',
  1189: 'Saubraz',
  1195: 'Dully-Bursinel',
  1196: 'Gland',
  1197: 'Prangins',
  1260: 'Nyon',
  1261: 'Marchissy',
  1262: 'Eysins',
  1263: 'Crassier',
  1264: 'St-Cergue',
  1265: 'La Cure',
  1266: 'Duillier',
  1267: 'Vich-Coinsins',
  1268: 'Burtigny',
  1269: 'Bassins',
  1270: 'Trélex',
  1271: 'Givrins',
  1272: 'Genolier',
  1273: 'Le Muids',
  1274: 'Grens',
  1275: 'Chéserex',
  1276: 'Gingins',
  1277: 'Borex',
  1278: 'La Rippe',
  1279: 'Chavannes-de-Bogis',
  1291: 'Commugny',
  1295: 'Mies-Tannay',
  1296: 'Coppet',
  1297: 'Founex',
  1299: 'Crans-près-Céligny',
  1300: 'Eclépens CC',
  1302: 'Vufflens-la-Ville',
  1303: 'Penthaz',
  1304: 'Senarclens',
  1305: 'Penthalaz',
  1306: 'Daillens',
  1307: 'Lussery-Villars',
  1308: 'La Chaux-Cossonay',
  1310: 'Daillens Dist Ba',
  1311: 'Eclépens SC',
  1312: 'Eclépens',
  1313: 'Ferreyres',
  1315: 'La Sarraz',
  1316: 'Chevilly',
  1317: 'Orny',
  1318: 'Pompaples',
  1320: 'Daillens ST PP 1',
  1321: 'Arnex-sur-Orbe',
  1322: 'Croy',
  1323: 'Romainmôtier',
  1324: 'Premier',
  1325: 'Vaulion',
  1326: 'Juriens',
  1329: 'Bretonnières',
  1330: 'Daillens CALL',
  1337: 'Vallorbe',
  1338: 'Ballaigues',
  1341: 'Orient',
  1342: 'Le Pont',
  1343: 'Les Charbonnières',
  1344: "L'Abbaye",
  1345: 'Le Lieu',
  1346: 'Les Bioux',
  1347: 'Le Sentier',
  1348: 'Le Brassus',
  1350: 'Orbe',
  1352: 'Agiez',
  1353: 'Bofflens',
  1354: 'Montcherand',
  1355: "L'Abergement",
  1356: 'Les Clées',
  1357: 'Lignerolle',
  1358: 'Valeyres-Rances',
  1372: 'Bavois',
  1373: 'Chavornay',
  1374: 'Corcelles-Chavorn',
  1375: 'Penthéréaz',
  1376: 'Goumoens-la-Ville',
  1377: 'Oulens-Echallens',
  1400: 'Yverdon-les-Bains',
  1401: 'Yverdon-les-Bains',
  1404: 'Cuarny',
  1405: 'Pomy',
  1406: 'Cronay',
  1407: 'Bioley-Magnoux',
  1408: 'Prahins',
  1409: 'Chanéaz',
  1410: 'Thierrens',
  1412: 'Valeyres-Ursins',
  1413: 'Orzens',
  1415: 'Molondin',
  1416: 'Pailly',
  1417: 'Epautheyres',
  1418: 'Vuarrens',
  1420: 'Fiez',
  1421: 'Fontaines-Grandson',
  1422: 'Grandson',
  1423: 'Villars-Burquin',
  1424: 'Champagne',
  1425: 'Onnens VD',
  1426: 'Concise',
  1427: 'Bonvillars',
  1428: 'Mutrux',
  1429: 'Giez',
  1430: 'Orges',
  1431: 'Vugelles-La Mothe',
  1432: 'Belmont-Yverdon',
  1433: 'Suchy',
  1434: 'Ependes VD',
  1435: 'Essert-Pittet',
  1436: 'Treycovagnes',
  1437: 'Suscévaz',
  1438: 'Mathod',
  1439: 'Rances',
  1440: 'Montagny-Chamard',
  1441: 'Valeyres-Montagny',
  1442: 'Montagny-Yverdon',
  1443: 'Champvent',
  1445: 'Vuiteboeuf',
  1446: 'Baulmes',
  1450: 'Ste-Croix',
  1452: 'Les Rasses',
  1453: 'Mauborget',
  1454: "L'Auberson",
  1462: 'Yvonand',
  1463: 'Rovray',
  1464: 'Chavannes-le-Chêne',
  1509: 'Vucherens',
  1510: 'Syens',
  1512: 'Chavannes-s-Moudon',
  1513: 'Hermenches',
  1514: 'Bussy-sur-Moudon',
  1515: 'Villars-le-Comte',
  1521: 'Curtilles',
  1522: 'Oulens-sur-Lucens',
  1523: 'Granges-Marnand',
  1524: 'Marnand',
  1525: 'Henniez',
  1526: 'Forel-sur-Lucens',
  1530: 'Payerne',
  1534: 'Sassel',
  1535: 'Combremont-Grand',
  1536: 'Combremont-Petit',
  1537: 'Champtauroz',
  1538: 'Treytorrens-Payern',
  1543: 'Grandcour',
  1545: 'Chevroux',
  1551: 'Vers-chez-Perrin',
  1552: 'Trey',
  1554: 'Sédeilles',
  1555: 'Villarzel',
  1562: 'Corcelles-Payerne',
  1565: 'Missy',
  1580: 'Avenches',
  1582: 'Donatyre',
  1584: 'Villars-le-Grand',
  1585: 'Salavaux',
  1586: 'Vallamand',
  1587: 'Montmagny',
  1588: 'Cudrefin',
  1589: 'Chabrey',
  1595: 'Faoug',
  1607: 'Palézieux-Village',
  1608: 'Oron-le-Châtel',
  1610: 'Châtillens',
  1612: 'Ecoteaux',
  1613: 'Maracon',
  1658: 'La Tine',
  1659: 'Flendruz',
  1660: 'Les Moulins',
  1682: 'Villars-Bramard',
  1683: 'Brenles',
  1800: 'Vevey',
  1801: 'Le Mont-Pèlerin',
  1802: 'Corseaux',
  1803: 'Chardonne',
  1804: 'Corsier-sur-Vevey',
  1805: 'Jongny',
  1806: 'St-Légier-Chiésaz',
  1807: 'Blonay',
  1808: 'Monts-de-Corsier',
  1809: 'Fenil-sur-Corsier',
  1811: 'Vevey',
  1814: 'La Tour-de-Peilz',
  1815: 'Clarens',
  1816: 'Chailly-Montreux',
  1817: 'Brent',
  1818: 'Montreux Redoute',
  1820: 'Montreux',
  1822: 'Chernex',
  1823: 'Glion',
  1824: 'Caux',
  1832: 'Villard-sur-Chamby',
  1833: 'Les Avants',
  1844: 'Villeneuve VD',
  1845: 'Noville',
  1846: 'Chessel',
  1847: 'Rennaz',
  1852: 'Roche VD',
  1853: 'Yvorne',
  1854: 'Leysin',
  1856: 'Corbeyrier',
  1860: 'Aigle',
  1862: 'La Comballaz',
  1863: 'Le Sépey',
  1864: "Vers-l'Eglise",
  1865: 'Les Diablerets',
  1866: 'La Forclaz VD',
  1867: 'St-Triphon',
  1880: 'Bex',
  1882: 'Les Posses-sur-Bex',
  1884: 'Huémoz',
  1885: 'Chesières',
  1892: 'Lavey-Village',
}
