/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.35.1025 on 2022-04-04 12:01:17.

export enum AssetProviderEnum {
  NONE = 'NONE',
  TELLCO_CORE_SATELLITE = 'TELLCO_CORE_SATELLITE',
  TELLCO_INDIVIDUAL_STRATEGY = 'TELLCO_INDIVIDUAL_STRATEGY',
  PIGUET_GALLAND = 'PIGUET_GALLAND',
  ALKIMIA = 'ALKIMIA',
}
