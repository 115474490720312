import { IdeaIconOff, IdeaIconOn, TranslateSimple } from '@eplix/ui'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import { HintButtonProps } from './types'

export const Hint = ({
  text,
  orientation = 'text-left',
  activeMessage,
  buttonStyle,
  messageStyle,
  focus,
}: HintButtonProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showHint, toggleHint] = useState(false)
  const showActiveMessage = showHint && activeMessage !== undefined
  const inactiveButtonClass = showActiveMessage ? '' : 'mb-3'

  const customHintText =
    typeof text === 'object' && 'code' in text && 'default' in text ? (
      <TranslateSimple
        label={text.code}
        translationOptions={{ defaultValue: text.default }}
      />
    ) : (
      text
    )
  const moreLessInformation = showHint ? (
    <TranslateSimple label="common:LessInformation" />
  ) : (
    <TranslateSimple label="common:MoreInformation" />
  )

  const showHintMessageHandler = () => {
    focus &&
      setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 0)
    toggleHint(!showHint)
  }

  return (
    <div
      className={clsx(
        'w-fit h-fit cursor-pointer tracking-wide text-blue',
        orientation,
        inactiveButtonClass,
        buttonStyle,
      )}
    >
      <div className="flex flex-col" onClick={showHintMessageHandler}>
        <div
          className={clsx(
            'text-left flex flex-row items-center',
            showHint && 'pb-4',
          )}
        >
          <p>{showHint ? <IdeaIconOn /> : <IdeaIconOff />}</p>
          <p className="pt-1 pl-1">
            {text ? customHintText : moreLessInformation}
          </p>
        </div>
      </div>
      <div ref={ref} className={messageStyle}>
        {showActiveMessage && activeMessage}
      </div>
    </div>
  )
}
