import { FC } from 'react'
import { IconProps } from './types'

export const FolderIcon: FC<IconProps> = ({ iconSize = 24, ...props }) => {
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 14V10H3C2.44772 10 2 9.55228 2 9C2 8.44772 2.44772 8 3 8H4V2.5C4 2.22386 4.22386 2 4.5 2H20.5C20.7761 2 21 2.22386 21 2.5V22H4.5C4.22386 22 4 21.7761 4 21.5V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4ZM6 14V10H7C7.55228 10 8 9.55228 8 9C8 8.44772 7.55228 8 7 8H6V4H19V8H16.5C15.6716 8 15 8.67157 15 9.5V20H6V16H7C7.55228 16 8 15.5523 8 15C8 14.4477 7.55228 14 7 14H6ZM17 20H19V18H17V20ZM19 16V14H17V16H19ZM19 12H17V10H19V12Z"
        fill="currentColor"
      />
    </svg>
  )
}
