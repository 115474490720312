export enum ListType {
  ORDERED,
  UNORDERED,
  ALPHABETICAL,
}

export interface TextWithLink {
  text: string
  href?: string | string[]
}

export interface DataSection {
  title?: string
  paragraph?: TextWithLink[]
  content?: {
    list?: {
      type: ListType
      items: {
        title?: string
        text?: string | TextWithLink
        subItems?: DataSection[]
      }[]
    }
  }[]
}

export interface DataModel {
  title: string
  text?: TextWithLink[]
  sections?: DataSection[]
}
