import { SVGProps } from 'react'

export const EnoveticLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="enovetic_logo"
      data-name="enovetic_logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 438 50"
      {...props}
    >
      <defs>
        <style>
          {`.enovetic_logo-1 {
            fill: #738a53;
          }
            .enovetic_logo-1, .enovetic_logo-2, .enovetic_logo-3, .enovetic_logo-4, .enovetic_logo-5 {
            stroke - width: 0px;
          }
            .enovetic_logo-3 {
            fill: #88bf20;
          }
            .enovetic_logo-4 {
            fill: url(#Unbenannter_Verlauf_20);
          }
            .enovetic_logo-5 {
            fill: url(#Unbenannter_Verlauf_16);
          }`}
        </style>
        <radialGradient
          id="Unbenannter_Verlauf_16"
          data-name="Unbenannter Verlauf 16"
          cx="22.2"
          cy="27.62"
          fx="22.2"
          fy="27.62"
          r="23.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#c4e19d" />
          <stop offset=".92" stopColor="#88bf20" />
        </radialGradient>
        <radialGradient
          id="Unbenannter_Verlauf_20"
          data-name="Unbenannter Verlauf 20"
          cx="268.91"
          cy="372.96"
          fx="268.91"
          fy="372.96"
          r="23.11"
          gradientTransform="translate(314.74 401.08) rotate(-180)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#3e4f2d" />
          <stop offset=".92" stopColor="#738a53" />
        </radialGradient>
      </defs>
      <circle className="enovetic_logo-1" cx="22.68" cy="27.87" r="22.68" />
      <path
        className="enovetic_logo-3"
        d="m45.35,5.19c-4.13,0-8,1.11-11.34,3.04,6.78,3.92,11.34,11.24,11.34,19.64h-22.68c0,12.52,10.15,22.68,22.68,22.68s22.68-10.15,22.68-22.68S57.88,5.19,45.35,5.19Z"
      />
      <path
        className="enovetic_logo-5"
        d="m45.35,27.87h-22.68c0,8.39,4.56,15.71,11.34,19.64,6.78-3.92,11.34-11.24,11.34-19.64h0Z"
      />
      <path
        className="enovetic_logo-4"
        d="m22.68,27.87h22.68c0-8.39-4.56-15.71-11.34-19.64-6.78,3.92-11.34,11.24-11.34,19.64h0Z"
      />
      <rect
        className="enovetic_logo-2"
        x="381.65"
        y="5.19"
        width="7.93"
        height="45.35"
        rx="3.97"
        ry="3.97"
      />
      <path
        className="enovetic_logo-2"
        d="m117.14,5.19c-13.24-.05-22.3,9.13-22.35,22.59-.05,13.55,8.94,22.71,22.61,22.77,7.45.03,13.69-2.93,17.65-7.79.53-.72.8-1.53.8-2.35,0-2.17-1.48-3.71-3.67-3.72-1.23,0-2.19.53-2.99,1.43-2.55,3.06-6.59,5.12-11.77,5.1-7.98-.03-13.39-4.93-14.59-12.34l30.77.12c3.07.01,4.92-1.79,4.93-4.95v-.18c.05-11.56-9.03-20.63-21.39-20.68Zm-14.1,18.64c1.52-6.86,6.8-11.36,14.07-11.33,7.28.03,12.52,4.57,13.98,11.44l-28.05-.11Z"
      />
      <path
        className="enovetic_logo-2"
        d="m308.67,5.19c-13.24-.05-22.3,9.13-22.35,22.59-.05,13.55,8.94,22.71,22.61,22.77,7.45.03,13.69-2.93,17.65-7.79.53-.72.8-1.53.8-2.35,0-2.17-1.48-3.71-3.67-3.72-1.23,0-2.19.53-2.99,1.43-2.55,3.06-6.59,5.12-11.77,5.1-7.98-.03-13.39-4.93-14.59-12.34l30.77.12c3.07.01,4.92-1.79,4.93-4.95v-.18c.05-11.56-9.03-20.63-21.39-20.68Zm-14.1,18.64c1.52-6.86,6.8-11.36,14.07-11.33,7.28.03,12.52,4.57,13.98,11.44l-28.05-.11Z"
      />
      <path
        className="enovetic_logo-2"
        d="m214.45,5.19c-6.45-.04-11.93,2.14-15.97,6.25-4.04,4.11-6.18,9.76-6.21,16.34-.03,6.58,2.08,12.24,6.08,16.39,4.01,4.15,9.51,6.35,15.92,6.38h.11c6.36,0,11.83-2.16,15.83-6.25,4.02-4.11,6.15-9.76,6.18-16.34.03-6.58-2.07-12.25-6.05-16.39-3.99-4.15-9.49-6.35-15.89-6.38Zm-.08,38.02h-.07c-4.17-.02-7.74-1.49-10.32-4.27-2.59-2.79-3.95-6.64-3.93-11.13.02-4.49,1.41-8.33,4.02-11.1,2.58-2.74,6.14-4.19,10.28-4.19h.07c8.53.03,14.23,6.22,14.19,15.4-.02,4.49-1.41,8.33-4.01,11.1-2.58,2.74-6.11,4.19-10.23,4.19Z"
      />
      <path
        className="enovetic_logo-2"
        d="m278.26,5.33h-.02c-1.54,0-2.65.78-3.4,2.4l-13.87,30.82-13.57-30.79c-.74-1.72-1.91-2.55-3.58-2.56-2.26-.01-3.92,1.63-3.93,3.99,0,.67.13,1.25.45,1.99l16.21,36.19c.96,2.17,2.34,3.18,4.31,3.19h.02c1.9,0,3.29-1.03,4.26-3.15l16.48-35.98c.36-.73.53-1.45.54-2.2,0-2.32-1.56-3.89-3.9-3.9Z"
      />
      <path
        className="enovetic_logo-2"
        d="m434.7,35.7h-.02c-1.36,0-2.42.54-3.13,1.59-2.7,3.95-6.66,5.92-11.76,5.93-8.56-.03-14.29-6.22-14.25-15.4.02-4.49,1.41-8.33,4.02-11.1,2.6-2.76,6.16-4.17,10.35-4.19,4.79.02,8.68,1.89,11.25,5.42.75,1.04,1.83,1.59,3.13,1.59h.02c2.12,0,3.73-1.63,3.74-3.81,0-.89-.27-1.73-.8-2.46-3.96-5.18-10.13-8.05-17.37-8.08-6.37,0-11.89,2.14-15.92,6.25-4.03,4.11-6.18,9.76-6.2,16.34-.03,6.57,2.07,12.24,6.07,16.39,4,4.15,9.49,6.35,15.87,6.38h.11c7.68,0,14.23-3.18,17.97-8.74l.02-.03c.39-.63.57-1.34.57-2.24,0-2.18-1.57-3.83-3.66-3.84Z"
      />
      <path
        className="enovetic_logo-2"
        d="m180.98,50.55c-2.16,0-3.92-1.76-3.92-3.92v-23.09c0-5.79-5.32-10.5-11.85-10.5s-11.85,4.71-11.85,10.5v23.09c0,2.16-1.76,3.92-3.92,3.92s-3.92-1.76-3.92-3.92v-23.09c0-10.11,8.83-18.34,19.69-18.34s19.69,8.23,19.69,18.34v23.09c0,2.16-1.76,3.92-3.92,3.92Z"
      />
      <path
        className="enovetic_logo-2"
        d="m371.49,36.62c-1.92-.95-4.24-.15-5.19,1.77-1.37,2.79-4.18,4.53-7.32,4.53h-.74c-4.48,0-8.12-3.6-8.12-8.02V12.49h17.22c2.01,0,3.65-1.63,3.65-3.65s-1.63-3.65-3.65-3.65h-17.22v-1.33c0-2.14-1.74-3.88-3.88-3.88s-3.88,1.73-3.88,3.88v1.33h-6.96c-2.01,0-3.65,1.63-3.65,3.65s1.63,3.65,3.65,3.65h6.96v22.4c0,8.7,7.12,15.77,15.88,15.77h.74c6.12,0,11.58-3.39,14.27-8.86.94-1.92.15-4.24-1.77-5.19Z"
      />
    </svg>
  )
}
