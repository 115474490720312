/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLProps, ReactElement } from 'react'
import { SortTypeEnum } from '@/common/enums/SortType'

export enum ColumnType {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  ACTION = 'action',
  LONG_TEXT = 'longText',
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  ELEMENT = 'element',
  HEADER = 'header',
  INPUT = 'input',
  PHONE = 'phone',
}

export interface TableColumnData {
  header?: string
  accessor: string
  shouldShow?: boolean
  showActionIcon?: (row: TableRowData) => boolean
  additional?: TableColumnData
  type: ColumnType
  format?: string
  inputDateFormat?: string
  isSortable?: boolean
  callback?: (row: TableRowData) => void
  icon?: ReactElement
  element?: ReactElement
  isSelectable?: boolean
  onSelected?: (item: any) => void
  style?: string
  cellStyle?: string
  additionalRowStyle?: string
  text?: string
}

export interface TableRowData {
  rowId: number | string
  shouldShow?: boolean
  [prop: string]: number | string | boolean | undefined | ReactElement
}

export interface ApplicationTableProps extends HTMLProps<HTMLTableElement> {
  columnData: Array<TableColumnData>
  rowData: Array<any>
  wrapperClassName?: string
  className?: string
  searchDisabled?: boolean
  rowsStyle?: string
  rowsContentStyle?: string
  inputDateFormat?: string
  isSelectable?: boolean
  theadClass?: string
  buttonStyle?: string
  theadRowClass?: string
  trowClass?: string
  headerTranslateClassname?: string
  onSelected?: (item: any, isSelected: boolean) => void
  preselectedRows?: (number | string)[]
  rowIdAccessor?: string
  initialSort?: InitialSortConfig
  onRowClick?: (row: TableRowData) => void
}

export interface TableState {
  filterValue: string
  filteredRowData: Array<TableRowData>
  activeCallback?: (
    records: Array<Record<string, any>>,
    order: string,
    property: string,
    dateFormat?: string,
  ) => Array<TableRowData>
  column: string
  order: string
  property: string
}

export interface InitialSortConfig {
  order: SortTypeEnum
  type: string
  column: string
}
