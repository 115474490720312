import classes from './loading-dots.module.scss'
import clsx from 'clsx'

interface LoadingDotsProp {
  style?: string
}

export const LoadingDots = ({ style }: LoadingDotsProp) => {
  return (
    <span className={classes.dotLoadingWrapper}>
      <span className={clsx(classes.dotPulse, style)}></span>
    </span>
  )
}
