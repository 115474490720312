export const BASE_URL =
  typeof window === 'undefined' ? process.env.LOCAL_API_URL : ''

const isHeaders = (headers: HeadersInit): headers is Headers => {
  return !!headers.forEach
}

const encodeHeaders = (headers: HeadersInit) => {
  if (Array.isArray(headers) || !isHeaders(headers)) {
    return headers
  }
  const res: HeadersInit = {}
  headers.forEach((v, k) => {
    res[k] = Array.isArray(v) ? v.join(',') : v
  })
  return res
}

export type IsomorphicRequest = Partial<RequestInit>

export const _fetch = async <TResponse = Record<string, unknown>>(
  url: string,
  { headers = {}, ...options }: IsomorphicRequest = {},
  responseType?: string,
): Promise<TResponse> => {
  return fetch(`${BASE_URL}${url}`, {
    ...options,
    credentials: 'include',
    headers: {
      ...encodeHeaders(headers),
    },
  }).then(async (v) => {
    if (v.status >= 400) {
      const err = await v.json()
      return Promise.reject(err)
    }

    if (responseType === 'blob') {
      return v.blob()
    }

    return v.json()
  })
}
const jsonContentType = { 'Content-Type': 'application/json' }

export const getJSON = async <TResponse = Record<string, unknown>>(
  url: string,
  req?: IsomorphicRequest,
  responseType?: string,
) =>
  _fetch<TResponse>(
    url,
    {
      method: 'get',
      headers: req?.headers || jsonContentType,
    },
    responseType,
  )

export const postJSON = async <
  TResponse = Record<string, unknown>,
  TPayload = Record<string, unknown>,
>(
  url: string,
  payload: TPayload,
  req?: IsomorphicRequest,
  responseType?: string,
) =>
  _fetch<TResponse>(
    url,
    {
      method: 'post',
      headers: req?.headers || jsonContentType,
      body: JSON.stringify(payload),
    },
    responseType,
  )

export const putJSON = async <
  TResponse = Record<string, unknown>,
  TPayload = Record<string, unknown>,
>(
  url: string,
  payload: TPayload,
  req?: IsomorphicRequest,
) =>
  _fetch<TResponse>(url, {
    method: 'put',
    headers: req?.headers || jsonContentType,
    body: JSON.stringify(payload),
  })

export const deleteJSON = async <TResponse = Record<string, unknown>>(
  url: string,
  req?: IsomorphicRequest,
) =>
  _fetch<TResponse>(url, {
    method: 'delete',
    headers: req?.headers || jsonContentType,
  })

export const postFormData = async <TResponse = Record<string, unknown>>(
  url: string,
  payload: FormData,
) =>
  _fetch<TResponse>(url, {
    method: 'post',
    body: payload,
  })
