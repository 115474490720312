export const SG = {
  7310: 'Bad Ragaz',
  7312: 'Pfäfers',
  7313: 'St. Margrethenberg',
  7314: 'Vadura',
  7315: 'Vättis',
  7317: 'Valens',
  7320: 'Sargans',
  7323: 'Wangs',
  7324: 'Vilters',
  7325: 'Schwendi',
  7326: 'Weisstannen',
  8638: 'Goldingen',
  8640: 'Rapperswil SG',
  8645: 'Jona',
  8646: 'Wagen',
  8650: 'Jona Dist Ba',
  8715: 'Bollingen',
  8716: 'Schmerikon',
  8717: 'Benken SG',
  8718: 'Schänis',
  8722: 'Kaltbrunn',
  8723: 'Rufi',
  8725: 'Ernetschwil',
  8726: 'Ricken SG',
  8727: 'Walde SG',
  8730: 'Uznach',
  8732: 'Neuhaus SG',
  8733: 'Eschenbach SG',
  8734: 'Ermenswil',
  8735: 'St. Gallenkappel',
  8737: 'Gommiswald',
  8738: 'Uetliburg SG',
  8739: 'Rieden SG',
  8740: 'Uznach Vögele AG',
  8872: 'Weesen',
  8873: 'Amden',
  8877: 'Murg',
  8878: 'Quinten',
  8879: 'Pizolpark (Mels)',
  8880: 'Walenstadt',
  8881: 'Walenstadtberg',
  8882: 'Unterterzen',
  8883: 'Quarten',
  8884: 'Oberterzen',
  8885: 'Mols',
  8886: 'Mädris-Vermol',
  8887: 'Mels',
  8888: 'Heiligkreuz (Mels)',
  8889: 'Plons',
  8890: 'Flums',
  8892: 'Berschis',
  8893: 'Flums Hochwiese',
  8894: 'Flumserberg Saxli',
  8895: 'Flumserberg Portel',
  8896: 'Flumserberg Bergh',
  8897: 'Flumserberg Theim',
  8898: 'Flumserberg Tboden',
  9000: 'St. Gallen',
  9001: 'St. Gallen',
  9004: 'St. Gallen',
  9006: 'St. Gallen',
  9007: 'St. Gallen',
  9008: 'St. Gallen',
  9009: 'St. Gallen',
  9010: 'St. Gallen',
  9011: 'St. Gallen',
  9012: 'St. Gallen',
  9013: 'St. Gallen',
  9014: 'St. Gallen',
  9015: 'St. Gallen',
  9016: 'St. Gallen',
  9020: 'St. Gallen',
  9022: 'St. Gallen',
  9024: 'St. Gallen',
  9025: 'St. Gallen Quelle',
  9026: 'St. Gallen K AG',
  9027: 'St. Gallen',
  9028: 'St. Gallen CV AG',
  9029: 'St. Gallen',
  9030: 'Abtwil SG',
  9032: 'Engelburg',
  9033: 'Untereggen',
  9034: 'Eggersriet',
  9036: 'Grub SG',
  9113: 'Degersheim',
  9114: 'Hoffeld',
  9115: 'Dicken',
  9116: 'Wolfertswil',
  9122: 'Mogelsberg',
  9123: 'Nassen',
  9125: 'Brunnadern',
  9126: 'Necker',
  9127: 'St. Peterzell',
  9200: 'Gossau SG',
  9201: 'Gossau SG',
  9203: 'Niederwil SG',
  9204: 'Andwil SG',
  9205: 'Waldkirch',
  9212: 'Arnegg',
  9230: 'Flawil',
  9231: 'Egg (Flawil)',
  9240: 'Uzwil',
  9242: 'Oberuzwil',
  9243: 'Jonschwil',
  9244: 'Niederuzwil',
  9245: 'Oberbüren',
  9246: 'Niederbüren',
  9247: 'Henau',
  9248: 'Bichwil',
  9249: 'Algetshausen',
  9300: 'Wittenbach',
  9301: 'Wittenbach',
  9304: 'Bernhardzell',
  9305: 'Berg SG',
  9308: 'Lömmenschwil',
  9312: 'Häggenschwil',
  9313: 'Muolen',
  9323: 'Steinach',
  9327: 'Tübach',
  9400: 'Rorschach',
  9401: 'Rorschach',
  9402: 'Mörschwil',
  9403: 'Goldach',
  9404: 'Rorschacherberg',
  9422: 'Staad SG',
  9423: 'Altenrhein',
  9424: 'Rheineck',
  9425: 'Thal',
  9430: 'St. Margrethen SG',
  9434: 'Au SG',
  9435: 'Heerbrugg',
  9436: 'Balgach',
  9437: 'Marbach SG',
  9442: 'Berneck',
  9443: 'Widnau',
  9444: 'Diepoldsau',
  9445: 'Rebstein',
  9450: 'Lüchingen',
  9451: 'Kriessern',
  9452: 'Hinterforst',
  9453: 'Eichberg',
  9462: 'Montlingen',
  9463: 'Oberriet SG',
  9464: 'Rüthi (Rheintal)',
  9465: 'Salez',
  9466: 'Sennwald',
  9467: 'Frümsen',
  9468: 'Sax',
  9469: 'Haag (Rheintal)',
  9470: 'Buchs SG',
  9471: 'Buchs SG 1',
  9472: 'Grabs',
  9473: 'Gams',
  9475: 'Sevelen',
  9476: 'Weite',
  9477: 'Trübbach',
  9478: 'Azmoos',
  9479: 'Oberschan',
  9500: 'Wil SG',
  9501: 'Wil SG 1',
  9510: 'Wil SG Dist Ba',
  9512: 'Rossrüti',
  9523: 'Züberwangen',
  9524: 'Zuzwil SG',
  9525: 'Lenggenwil',
  9526: 'Zuckenriet',
  9527: 'Niederhelfenschwil',
  9533: 'Kirchberg SG',
  9534: 'Gähwil',
  9536: 'Schwarzenbach SG',
  9552: 'Bronschhofen',
  9601: 'Lütisburg Station',
  9602: 'Bazenheid',
  9604: 'Oberrindal',
  9606: 'Bütschwil',
  9607: 'Mosnang',
  9608: 'Ganterschwil',
  9612: 'Dreien',
  9613: 'Mühlrüti',
  9614: 'Libingen',
  9615: 'Dietfurt',
  9620: 'Lichtensteig',
  9621: 'Oberhelfenschwil',
  9622: 'Krinau',
  9630: 'Wattwil',
  9631: 'Ulisbach',
  9633: 'Bächli (Hemberg)',
  9642: 'Ebnat-Kappel',
  9643: 'Krummenau',
  9650: 'Nesslau',
  9651: 'Ennetbühl',
  9652: 'Neu St. Johann',
  9655: 'Stein SG',
  9656: 'Alt St. Johann',
  9657: 'Unterwasser',
  9658: 'Wildhaus',
}
